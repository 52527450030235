import React, { useState } from 'react';
import {
  useStripe, useElements,
  CardNumberElement, CardExpiryElement, CardCvcElement, CardElement
} from '@stripe/react-stripe-js';
import { stripePaymentMethodHandler } from './script';

import axios from 'axios';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutForm(props) {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [isDisablepaymentButton, setIsDisablepaymentButton] = useState(props.amount=='0.00' ? true:false);

  const stripe = useStripe();
  const elements = useElements();
  
  var hostname = ''
  var onlyHost = window.location.hostname;
  onlyHost = onlyHost.split('.')
  if(onlyHost && onlyHost.length==3){
    onlyHost = onlyHost.slice(1) //remove first element of array
    hostname = onlyHost[0]+'.'+onlyHost[1]
  }else if(onlyHost && onlyHost.length==2){
    hostname = onlyHost[0]+'.'+onlyHost[1]
  }
  
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // // Trigger form validation and wallet collection
    // const {error: submitError} = await elements.submit();
    // if (submitError) {
    //   // Show error to your customer
    //   console.log(submitError.message,'rrrrrrrrrrrrrrrrrrrrr');
    //   return;
    // }


    setErrorMsg('');

    const paymentMethodObj = {
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email
      },
    };
    
    const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);

    if(paymentMethodResult.error){
      setErrorMsg(paymentMethodResult.error.message);
    }else{
      setLoading(true);
      const data = ({
          payment_method_id: paymentMethodResult.paymentMethod.id,
          name: paymentMethodResult.paymentMethod.billing_details.name,
          email: paymentMethodResult.paymentMethod.billing_details.email,
          amount: props.amount,
          extraData:props.extraData,
      })
      stripePayment(data);
    }
  };

  const stripePayment = async (data) => {
    await axios.post("/api/order-pay", data).then(async(response) => {
      if(response.status === 200) {
        if(response.data.data.paymentIntentResponse=='success'){
          setLoading(false);
          props.handlePaymentRes({
              'response':true,
              'message':response.data.message,
          });
        }else{
          storePaymentDetail(response)
        }
      }
    }).then(json => {
    
    }).catch(error => {
      if (error.response) {
        setLoading(false);
        props.handlePaymentRes({
          'response':false,
          'message':error.response.data.message,
        });
      }
    });
    
}

  const storePaymentDetail = async (responseData) => {
    if(responseData.data.data){
      const CardElement = elements.getElement(CardNumberElement);
      if(responseData.data.data.paymentIntentResponse['isSubscription']){//recurring payment
        stripe.confirmCardPayment(responseData.data.data.paymentIntentResponse['clientSecret'], {
          payment_method: { card: CardElement }
        }).then(async (result) => {
            if(result.error) {
                props.handlePaymentRes({
                  'response':false,
                  'message':result.error.message,
                });
            } else {
                // Successful subscription payment
                // Post the transaction info to the server-side script and redirect to the payment status page
                const requestData = {
                  'paymentIntent':result.paymentIntent,
                  'subscriptionId':responseData.data.data.paymentIntentResponse['subscriptionId'],
                  'clientSecret':responseData.data.data.paymentIntentResponse['clientSecret'],
                  'customerId':responseData.data.data.paymentIntentResponse['customerId'],
                  'orderId':responseData.data.data.paymentIntentResponse['orderId'],
                  'isUpdate':responseData.data.data.paymentIntentResponse['isUpdate'],
                  'requestUserCount':responseData.data.data.paymentIntentResponse['requestUserCount']?responseData.data.data.paymentIntentResponse['requestUserCount']:'',
                  'amount':responseData.data.data.paymentIntentResponse['amount'],
                  'subscriptionType':responseData.data.data.paymentIntentResponse['subscriptionType'],
                }

                await axios.post("/api/recurring-payment-confirm", requestData).then(async(response) => {
                  if (response.status === 200) {
                    setLoading(false);
                    props.handlePaymentRes({
                        'response':true,
                        'message':'Payment successfull',
                    });
                  }
                }).then(json => {
                
                }).catch(error => {
                    if (error.response) {
                      setLoading(false);
                      props.handlePaymentRes({
                        'response':false,
                        'message':error.response.data.message,
                      });
                    }
                });
            }
        });
      }else{//normal payment
        stripe.confirmCardPayment(responseData.data.data.paymentIntentResponse['clientSecret'], {
          payment_method: { card: CardElement }
        }).then(async (result) => {
            if(result.error) {
                props.handlePaymentRes({
                  'response':false,
                  'message':result.error.message,
                });
            } else {
                // Successful subscription payment
                // Post the transaction info to the server-side script and redirect to the payment status page
                await axios.post("/api/payment-confirm", result.paymentIntent).then(async(response) => {
                  if (response.status === 200) {
                    setLoading(false);
                    props.handlePaymentRes({
                        'response':true,
                        'message':'Payment successfull',
                    });
                  }
                }).then(json => {
                
                }).catch(error => {
                    if (error.response) {
                      setLoading(false);
                      props.handlePaymentRes({
                        'response':false,
                        'message':error.response.data.message,
                      });
                    }
                });
            }
        });
        
      }
    }else{
      setLoading(false);
      props.handlePaymentRes({
        'response':false,
        'message':'Payment fail !',
      });
    }
    
  }
  
  // callback method to handle the response
  const handleResponse = response => {
    setLoading(false);
    if (response.error) {
      setErrorMsg(response.error.message);
      return;
    }
    props.setPaymentCompleted(response.success ? true : false);
  };

  return (
    <React.Fragment>
      {/* <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Pay with card</span>
      </h4> */}
      <form onSubmit={handleSubmit}>
        <div className='CardElement'>
        {/* <CardElement> */}
        {/* <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-name">Name on card</label>
            <input
              id="cc-name"
              type="text"
              className="form-control"
              placeholder='John Dio'
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-email">Email</label>
            <input
              id="cc-email"
              type="email"
              placeholder='abc@email.com'
              className="form-control"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div> */}
          <div className="row">
            <div className="col-md-12 mb-3">
              <h5>Billed Payment Method</h5>
            </div>
          </div>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="cc-number">Card Number<span style={{color:'red'}}>*</span></label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="expiry">Expiration Date<span style={{color:'red'}}>*</span></label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="cvc">CVC<span style={{color:'red'}}>*</span></label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>
        {/* </CardElement>  */}
        </div>
        <p>This payment method will be used for current and futuure billings</p>
        <button className="btn btnbluecolor btn-primary createbtn btnbluecolor w-100 paymentbtn" type="submit" disabled={isDisablepaymentButton}>
          {loading ? 
            <div className="spinner-border spinner-border-sm text-light" role="status"></div> 
            : 
            hostname ==='solvcrm.co' ? `PAY $${props.amount}`:`PAY ₹${props.amount}`
          }
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>
    </React.Fragment>
  );
}



// import React from "react";

// import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";


// import CardSection from "./CardSection";


// class CheckoutForm extends React.Component {

//     handleSubmit = async event => {

//         event.preventDefault();


//         const { stripe, elements } = this.props;

//         if (!stripe || !elements) {

//             return;

//         }


//         const card = elements.getElement(CardElement);

//         const result = await stripe.createToken(card);

//         if (result.error) {

//             console.log(result.error.message);

//         } else {

//             console.log(result.token);

//         }

//     };


//     render() {

//         return (

//             <div>

//                 <div class="product-info">

//                     <h3 className="product-title">Apple MacBook Pro</h3>

//                     <h4 className="product-price">$1</h4>

//                 </div>

//                 <form onSubmit={this.handleSubmit}>

//                     <CardSection />

//                     <button disabled={!this.props.stripe} className="btn-pay">

//                         Buy Now

//                     </button>

//                 </form>

//             </div>

//         );

//     }

// }


// export default function InjectedCheckoutForm() {

//     return (

//         <ElementsConsumer>

//             {({ stripe, elements }) => (

//                 <CheckoutForm stripe={stripe} elements={elements} />

//             )}

//         </ElementsConsumer>

//     );

// }