import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from './components/ErrorBoundary';

import { Provider } from "react-redux";
import store from "./app/store";
//import { PersistGate } from 'redux-persist/integration/react'

// core styles
//import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";


ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      {/* <PersistGate persistor={persistor} loading={null}> */}
        <BrowserRouter>
          <ScrollToTop />
          <HomePage />
        </BrowserRouter>,
      {/* </PersistGate> */}
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
