// For calendor use
export const displayCalendorDateFormate = (date) => {
    const formatMap = {
        'YYYY-MMM-DD': 'yyyy/MMM/dd',
        'YYYY-MM-DD': 'yyyy/MM/dd',
        'DD-MMM-YYYY': 'dd/MMM/yyyy',
        'DD-MM-YYYY': 'dd/MM/yyyy',
        'YYYY-DD-MMM': 'yyyy/dd/MMM',
        'YYYY-DD-MM': 'yyyy/dd/MM',
        'MMM-DD-YYYY': 'MMM/dd/yyyy',
        'MM-DD-YYYY': 'MM/dd/yyyy',
    };

    const calendorFormate = date?.split(' ') || '';
    return formatMap[calendorFormate[0]] || '';
};

//For listing use
export const displayListDateFormate = (date) => {
    const formatMap = {
        'YYYY-MMM-DD': 'yyyy/MM/DD',
        'DD-MMM-YYYY': 'DD/MM/yyyy',
        'YYYY-DD-MMM': 'yyyy/DD/MM',
        'MMM-DD-YYYY': 'MM/DD/yyyy',
    };

    const calendorFormate = date?.split(' ') || '';
    return formatMap[calendorFormate[0]] || '';
};


// Function to capitalize a string
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};


// Function to display date on list page (half Or Full)
export const displayDate = (date,type=null) => {
    const halfDate = date?.split(' ')
    if(date && type==='half'){
        return halfDate.length>=2 ? halfDate[0]:''
    }else{
        return date;
    }
  };