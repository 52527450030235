import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Card,
  Button,
  Table,
  Dropdown,
  ButtonGroup,
  Modal,
  Spinner,
  Form,
} from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Preloader from "../../components/PreloaderCenter";
import { useDispatch, useSelector } from "react-redux";
import { LeadStageAddPopUp } from "../../features/userSlice";
import AddLeadStage from "../../components/AddLeadStage";
import { DealStageAddPopUp } from "../../features/userSlice";
import AddDealStage from "../../components/AddDealStage";
import ChangeDealStagePosition from "../../components/ChangeDealStagePosition";
import ChangeLeadStagePosition from "../../components/ChangeLeadStagePosition";

import Select from "react-select";
import swal from "sweetalert";
import axios from "axios";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
//var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
//var perPageDeal = process.env.REACT_APP_DEFAULT_PAGINATION;
var perPage = 6;
var perPageDeal = 6;
var currentPage = 1;
var type = null;
var searchData = "";

function StageList(props) {
  const location = props.history.location;
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [listStage, setStage] = useState([]);
  const [listDealStage, setDealStage] = useState([]);

  const showAddLeadStage = useSelector((state) => state.leadStageAddPopUp);
  const showAddDealStage = useSelector((state) => state.dealStageAddPopUp);

  const [showEditLeadStageDefault, setShowEditLeadStageDefault] =
    useState(false);

  const [openDealStagePositionPopup, setOpenDealStagePositionPopup] =
    useState(false);
  const [openLeadStagePositionPopup, setOpenLeadStagePositionPopup] =
    useState(false);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [dealDataCount, setDealDataCount] = useState("");

  const [state, setData] = useState({
    users: "",
  });
  const [dealState, setDealState] = useState({
    users: "",
  });

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  const columns = ["name", "type", "status", "action"];
  const dealColumns = ["name", "type", "status", "action"];

  const SORT_ASC = "asc";
  const SORT_DESC = "desc";
  const [sortColumn, setSortColumn] = useState();
  const [search, setSearch] = useState("");

  const SORT_ASC_DEAL = "asc";
  const SORT_DESC_DEAL = "desc";
  const [sortDealColumn, setSortDealColumn] = useState();

  if (props.location.pathname == "/stage") {
    if (props.location.statess) {
      searchData = props.location.statess.search;
    }
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC;
      type = "lead";
      getData();
    } else {
      type = "lead";
      setSortColumn(column);
      sortOrder = SORT_DESC;
    }
  };

  const handlePerPage = (Page) => {
    setLoading(true);
    currentPage = 1;
    perPage = Page;
    //perPageDeal = process.env.REACT_APP_DEFAULT_PAGINATION;
    type = "lead";
    getData();
  };

  //========DEAL STAGE============//

  const handleDealSort = (columnss) => {
    if (columnss === sortDealColumn) {
      type = "deal";
      sortOrder = sortOrder === SORT_ASC_DEAL ? SORT_DESC_DEAL : SORT_ASC_DEAL;
      getData();
    } else {
      type = "deal";
      setSortDealColumn(columnss);
      sortOrder = SORT_DESC_DEAL;
    }
  };

  const handleDealPerPage = (Page) => {
    console.log(perPageDeal, perPage);
    setLoading(true);
    currentPage = 1;
    perPageDeal = Page;
    //perPage=process.env.REACT_APP_DEFAULT_PAGINATION;
    type = "deal";
    getData();
  };

  //=========END:CODE FOR FOOTER PAGINATION===========//

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [
    sortColumn,
    sortDealColumn,
    showAddLeadStage,
    showAddDealStage,
    location,
    sortOrder,
    search,
    searchData,
    openDealStagePositionPopup,
    openLeadStagePositionPopup,
  ]);

  const getData = async (currentPage) => {
    const params = {
      search: searchData,
      sort_field: type == "deal" ? sortDealColumn : sortColumn,
      sort_order: sortOrder,
      per_page: type == "deal" ? perPageDeal : perPage,
      page: currentPage,
      type: type,
    };

    await axios
      .get(`api/stage?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          if (type == "deal") {
            //perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
            perPage = 6;
          } else {
            //perPageDeal = process.env.REACT_APP_DEFAULT_PAGINATION
            perPageDeal = 6;
          }

          setStage(response.data.data.records.data);
          setDealStage(response.data.data.recordsDeal.data);

          setdataCount(response.data.data.records.data.length);
          setDealDataCount(response.data.data.recordsDeal.data.length);

          setData({
            users: response.data.data.records,
          });
          setDealState({
            users: response.data.data.recordsDeal,
          });

          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error("Oops Something went wrong.");
            history.push("/dashboard");
          }
        }
      });
  };

  function changeStatus(id, value) {
    swal({
      title: "Are you sure you want to change status?",
      //text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (willDelete) => {
      if (willDelete) {
        value = value == 0 ? 1 : 0;
        const data = {
          id: id,
          status: value,
        };

        await axios
          .put("api/stage/mode", data)
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              getData(currentPage);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error("Oops Something went wrong.");
              }
            }
          });
      } else {
        window.location.reload();
      }
    });
  }

  function deleteRecord(id) {
    const data = {
      id: id,
    };

    swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios
          .delete("api/stage/destroy", { data })
          .then((response) => {
            if (response.status === 200) {
              //swal("success", response.data.message, "success");
              // swal("success", response.data.message, "success", {
              //   icon: "success",
              // });
              toast.success(response.data.message);
              getData(currentPage);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                //swal("error", error.response.data.message, "error");
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                //swal("error", error.response.data.message, "error");
                toast.error("Oops Something went wrong.");
              }
            }
          });
      }
    });
  }

  function addLeadStage() {
    dispatch(LeadStageAddPopUp(true));
  }

  function addDealStage() {
    dispatch(DealStageAddPopUp(true));
  }

  function handleLeadStagePosition() {
    openLeadStagePositionPopup
      ? setOpenLeadStagePositionPopup(false)
      : setOpenLeadStagePositionPopup(true);
  }

  function handleDealStagePosition() {
    openDealStagePositionPopup
      ? setOpenDealStagePositionPopup(false)
      : setOpenDealStagePositionPopup(true);
  }

  //=====================START:Write code for edit Lead Stage===========================//

  const handleEditLeadStageClose = () => setShowEditLeadStageDefault(false);
  const [dataDetail, setDataDetail] = useState();
  const [stageTypeArray, setStageTypeArray] = useState([]);

  const [defaultValue, setdefaultValue] = useState({
    id: "",
    name: "",
    hierarchy: "",
    color_code: "",
    is_used: "",
    type: "",
    error_list: [],
  });

  async function editStage(id) {
    //setLoading(true);
    await axios
      .get("api/stage/edit/" + id)
      .then((response) => {
        if (response.status === 200) {
          setDataDetail(response.data.data.record);
          setStageTypeArray(response.data.data.stageTypes);

          setdefaultValue({
            id: id,
            name: response.data.data.record.name,
            hierarchy: response.data.data.record.hierarchy,
            color_code: response.data.data.record.color_code,
            is_used: response.data.data.record.is_used,
            is_popup: response.data.data.record.is_popup,
            type: response.data.data.record.type,
            error_list: [],
          });
          setShowEditLeadStageDefault(true);
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 400 || error.response.status === 401) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            history.push("/stages");
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/stages");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const numberRegExp = /^(0|[1-9][0-9]*)$/;
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim("Name cannot include space")
      .strict(true)
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter stage name"),
    hierarchy: yup
      .string()
      .matches(numberRegExp, "Allow numeric only")
      .required("Enter stage position"),
  });

  const handleSelectTypeSave = (e) => {
    setdefaultValue({ ...defaultValue, ["type"]: e.value });
  };

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      id: values.id,
      name: values.name,
      hierarchy: values.hierarchy,
      type: defaultValue.type,
      is_used: values.is_used,
    };

    await axios
      .post("/api/stage/update", data)
      .then((response) => {
        if (response.status === 200) {
          setShowEditLeadStageDefault(false);
          getData(currentPage);
          toast.success("Stage updated successfully");
          history.push("/stages");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          //setShowEditLeadStageDefault(false)
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400) {
            setdefaultValue({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/stages");
          } else {
            toast.warning(error.response.data.message);
          }
        }
      });
  };

  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  let defaultStageTypeList = [];
  Object.keys(stageTypeArray).map(function (key) {
    if (dataDetail.type == key) {
      defaultStageTypeList.push({ label: stageTypeArray[key], value: key });
    }
  }, this);

  let stageTypeList = [];
  stageTypeList.push({ label: "Please select", value: "" });
  Object.keys(stageTypeArray).map(function (key) {
    stageTypeList.push({ label: stageTypeArray[key], value: key });
  }, this);

  //=====================END:Write code for edit Lead Stage===========================//

  var displayData = "";
  var displayDealData = "";

  const leadStageDropdown = (id, status) => {
    return (
      <select onChange={() => changeStatus(id)} key={id}>
        <option value="1" selected={status == 1 ? "selected" : ""}>
          Active
        </option>
        <option value="0" selected={status == 0 ? "selected" : ""}>
          Inactive
        </option>
      </select>
    );
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayData = listStage.map((item, i) => {
      if (item.id != 1) {
        return (
          <>
            <tr key={i}>
              {userPermission ? (
                userPermission.includes("stage_edit") ? (
                  <td
                    style={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      width: "25%",
                    }}
                    onClick={() => editStage(item.id)}
                  >
                    {item.name}
                  </td>
                ) : (
                  <td style={{ textTransform: "capitalize", width: "25%" }}>
                    {item.name}
                  </td>
                )
              ) : (
                <td style={{ textTransform: "capitalize", width: "25%" }}>
                  {item.name}
                </td>
              )}
              <td style={{ width: "15%" }}>
                {item.selected_type ? item.selected_type : "N/A"}
              </td>
              {userPermission ? (
                userPermission.includes("stage_edit") ? (
                  <td style={{ width: "15%" }} className="position">
                    {leadStageDropdown(item.id, item.status)}
                  </td>
                ) : (
                  <td style={{ width: "15%" }}>
                    {item.status == 1 ? "Active" : "Inactive"}
                  </td>
                )
              ) : (
                <td style={{ width: "15%" }}>
                  {item.status == 1 ? "Active" : "Inactive"}
                </td>
              )}
              <td style={{ width: "5%" }}>
                <div className="display align-items-center">
                  {userPermission ? (
                    userPermission.includes("stage_edit") ? (
                      <div
                        className="editicon"
                        onClick={() => editStage(item.id)}
                      >
                        <Dropdown
                          as={ButtonGroup}
                          className="d-flex justify-content-center"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="editiconhover"
                              onClick={() => editStage(item.id)}
                            >
                              <FontAwesomeIcon className="me-2" />
                              <span className="edit-icon"></span> Edit
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {userPermission ? (
                    userPermission.includes("stage_delete") ? (
                      <div
                        className="deleteicon"
                        onClick={() => {
                          deleteRecord(item.id);
                        }}
                      >
                        <Dropdown
                          as={ButtonGroup}
                          className="d-flex justify-content-center"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item className="deleteiconhover">
                              <FontAwesomeIcon className="me-2" />
                              <span className="delete-icon"></span>Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </td>
            </tr>
          </>
        );
      } else {
        return (
          <>
            <tr key={i}>
              <td style={{ textTransform: "capitalize", width: "25%" }}>
                {item.name}
              </td>
              <td style={{ width: "15%" }}>
                {item.selected_type ? item.selected_type : "N/A"}
              </td>
              <td style={{ width: "15%" }}>
                <span
                  className={item.status ? "listbtn green" : "listbtn yellow"}
                  title="Can not change default stage status"
                >
                  {item.status == 1 ? "Active" : "Inactive"}
                </span>
              </td>
              <td style={{ width: "5%" }}></td>
            </tr>
          </>
        );
      }
    });

    const dealStageDropdown = (id, status) => {
      return (
        <select onChange={() => changeStatus(id)}>
          <option value="1" selected={status == 1 ? "selected" : ""}>
            Active
          </option>
          <option value="0" selected={status == 0 ? "selected" : ""}>
            Inactive
          </option>
        </select>
      );
    };

    displayDealData = listDealStage.map((item, i) => {
      if (item.id != 8 && item.is_popup != 1) {
        return (
          <>
            <tr key={i}>
              {userPermission ? (
                userPermission.includes("stage_edit") ? (
                  <td
                    style={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      width: "25%",
                    }}
                    onClick={() => editStage(item.id)}
                  >
                    {item.name}
                  </td>
                ) : (
                  <td style={{ textTransform: "capitalize", width: "25%" }}>
                    {item.name}
                  </td>
                )
              ) : (
                <td style={{ textTransform: "capitalize", width: "25%" }}>
                  {item.name}
                </td>
              )}
              <td style={{ width: "15%" }}>
                {item.selected_type ? item.selected_type : "N/A"}
              </td>
              {userPermission ? (
                userPermission.includes("stage_edit") ? (
                  <td style={{ width: "15%" }} className="position">
                    {leadStageDropdown(item.id, item.status)}
                  </td>
                ) : (
                  <td style={{ width: "15%" }}>
                    {item.status == 1 ? "Active" : "Inactive"}
                  </td>
                )
              ) : (
                <td style={{ width: "15%" }}>
                  {item.status == 1 ? "Active" : "Inactive"}
                </td>
              )}

              <td style={{ width: "5%" }}>
                <div className="display align-items-center">
                  {userPermission ? (
                    userPermission.includes("stage_edit") ? (
                      <div
                        className="editicon"
                        onClick={() => editStage(item.id)}
                      >
                        <Dropdown
                          as={ButtonGroup}
                          className="d-flex justify-content-center"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="editiconhover"
                              onClick={() => editStage(item.id)}
                            >
                              <FontAwesomeIcon className="me-2" />
                              <span className="edit-icon"></span>Edit
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {userPermission ? (
                    userPermission.includes("stage_delete") ? (
                      <div
                        className="deleteicon"
                        onClick={() => {
                          deleteRecord(item.id);
                        }}
                      >
                        <Dropdown
                          as={ButtonGroup}
                          className="d-flex justify-content-center"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item className="deleteiconhover">
                              <FontAwesomeIcon className="me-2" />
                              <span className="delete-icon"></span>Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </td>
            </tr>
          </>
        );
      } else {
        return (
          <>
            <tr key={i}>
              <td style={{ textTransform: "capitalize", width: "25%" }}>
                {item.name}
              </td>
              <td style={{ width: "15%" }}>
                {item.selected_type ? item.selected_type : "N/A"}
              </td>
              <td style={{ width: "15%", textTransform: "capitalize" }}>
                <span
                  className={item.status ? "listbtn green" : "listbtn yellow"}
                  title="Can not change default stage status"
                >
                  {item.status == 1 ? "Active" : "Inactive"}
                </span>
              </td>
              <td style={{ width: "5%" }}></td>
            </tr>
          </>
        );
      }
    });
  }

  return (
    <>
      <div className="mt-4 align-items-center d-flex justify-content-between">
        <div className="col-md-6 stagelist">
          <h3>Lead Stage List</h3>
        </div>

        <div className="col-md-6 text-right">
          {userPermission ? (
            userPermission.includes("stage_create") ? (
              <Button
                variant="primary"
                className="createbtn btnbluecolor btn btn-primary"
                onClick={() => addLeadStage()}
              >
                Add Lead Stage
              </Button>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <Row>
        <Col xs={12} className="mb-4 leadsTable-cl">
          <Table
            responsive
            className="align-items-center table-flush rolewidth"
            style={{ marginBottom: "0px" }}
          >
            <thead>
              <tr>
                {columns.map((column) => {
                  return (
                    <th key={column}>
                      {column.charAt(0).toUpperCase() +
                        column
                          .slice(1)
                          .toLowerCase()
                          .replace("_", " ")
                          .replace("HIERARCHY", "Position")
                          .replace("IS USED", "USED IN")}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {displayData.length >= 1 ? (
                displayData
              ) : (
                <span className="text-center">No data Available</span>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {displayData.length >= 1 ? (
        <Row className="align-items-center">
          <Col xs={12} xl={12}>
            <Card border="light" className="bg-white border-0">
              <Card.Body className="listcard pe-0 ps-0">
                <Row className="align-items-center">
                  <Col md={6}>
                    <Button
                      variant="primary"
                      className="createbtn btnbluecolor btn btn-primary"
                      onClick={() => handleLeadStagePosition()}
                    >
                      <span className="plus-icon1"></span>Click to Change the
                      Lead Stage Position
                    </Button>
                  </Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <Pagination
                      activePage={
                        state?.users?.current_page
                          ? state?.users?.current_page
                          : 0
                      }
                      itemsCountPerPage={
                        state?.users?.per_page ? state?.users?.per_page : 0
                      }
                      totalItemsCount={
                        state?.users?.total ? state?.users?.total : 0
                      }
                      onChange={(pageNumber) => {
                        currentPage = pageNumber;
                        type = "lead";
                        getData(pageNumber);
                      }}
                      pageRangeDisplayed={5}
                      itemClass="page-item mx-1"
                      linkClass="page-link"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <div className="display my-2">
        <div className="emptyborder"></div>
      </div>

      <div className="align-items-center d-flex justify-content-between mt-40">
        <div className="col-md-6 stagelist">
          <h3>Deal Stage List</h3>
        </div>
        <div className="col-md-6 text-right">
          {userPermission ? (
            userPermission.includes("stage_create") ? (
              <Button
                variant="primary"
                className="createbtn btnbluecolor btn btn-primary"
                onClick={() => addDealStage()}
              >
                <span className="plus-icon1"></span>Add Deal Stage
              </Button>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <Row>
        <Col xs={12} className="mb-4 leadsTable-cl">
          <Table
            responsive
            className="align-items-center table-flush rolewidth"
            style={{ marginBottom: "0px" }}
          >
            <thead>
              <tr>
                {dealColumns.map((columnss) => {
                  return (
                    <th key={columnss}>
                      {columnss.charAt(0).toUpperCase() +
                        columnss
                          .slice(1)
                          .toLowerCase()
                          .replace("_", " ")
                          .replace("HIERARCHY", "Position")}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {displayDealData.length >= 1 ? (
                displayDealData
              ) : (
                <span className="text-center">No data Available</span>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {displayDealData.length >= 1 ? (
        <Row className="align-items-center">
          <Col xs={12} xl={12}>
            <Card border="light" className="bg-white border-0">
              <Card.Body className="listcard pb-0 pe-0 ps-0">
                <Row className="align-items-center">
                  <Col md={6}>
                    <Button
                      variant="primary"
                      className="createbtn btnbluecolor btn btn-primary"
                      onClick={() => handleDealStagePosition()}
                    >
                      <span className="plus-icon1"></span>Click to Change the
                      Deal Stage Position
                    </Button>
                  </Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <Pagination
                      activePage={
                        dealState?.users?.current_page
                          ? dealState?.users?.current_page
                          : 0
                      }
                      itemsCountPerPage={
                        dealState?.users?.per_page
                          ? dealState?.users?.per_page
                          : 0
                      }
                      totalItemsCount={
                        dealState?.users?.total ? dealState?.users?.total : 0
                      }
                      onChange={(pageNumber) => {
                        currentPage = pageNumber;
                        type = "deal";
                        getData(pageNumber);
                      }}
                      pageRangeDisplayed={5}
                      itemClass="page-item mx-1"
                      linkClass="page-link"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {userPermission ? (
        userPermission.includes("stage_create") ? (
          <>
            <AddLeadStage />
            <AddDealStage />
            <ChangeDealStagePosition
              handleDealStagePosition={handleDealStagePosition}
              openDealStagePositionPopup={openDealStagePositionPopup}
            />
            <ChangeLeadStagePosition
              handleLeadStagePosition={handleLeadStagePosition}
              openLeadStagePositionPopup={openLeadStagePositionPopup}
            />
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <Modal
        as={Modal.Dialog}
        centered
        show={showEditLeadStageDefault}
        onHide={handleEditLeadStageClose}
      >
        <Modal.Header>
          <Modal.Title className="h2">Edit Stage</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleEditLeadStageClose}
          />
          <span className="modelclose"></span>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={defaultValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
                setFieldTouched,
              } = props;
              return (
                <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-md-12 col-sm-12">
                      <Row className="align-items-center">
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">
                              Name<span className="text-danger">*</span>
                            </h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="name">
                              <Form.Control
                                type="text"
                                minLength={2}
                                maxLength={50}
                                name="name"
                                disabled={values.is_popup == 1 ? true : false}
                                onChange={handleChange}
                                value={values.name}
                                onBlur={handleBlur}
                                placeholder="Enter Name"
                                className={
                                  errors.name && touched.name
                                    ? "input-error"
                                    : null
                                }
                              />
                              <span className="error error4">
                                {defaultValue.error_list.name}
                              </span>
                              <p className="text-danger error4">
                                <ErrorMessage name="name" />
                              </p>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">
                              Position<span className="text-danger">*</span>
                            </h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="hierarchy">
                              <Form.Control
                                type="text"
                                maxLength={3}
                                name="hierarchy"
                                onChange={handleChange}
                                value={values.hierarchy}
                                onBlur={handleBlur}
                                placeholder="Enter Position"
                                className={
                                  errors.hierarchy && touched.hierarchy
                                    ? "input-error"
                                    : null
                                }
                              />
                              <span className="error error4">
                                {defaultValue.error_list.hierarchy}
                              </span>
                              <p className="text-danger error4">
                                <ErrorMessage name="hierarchy" />
                              </p>
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label column sm="12">
                            <h6 className="fw600">Type</h6>
                          </Form.Label>
                          <Col sm="12" className="position-relative">
                            <Form.Group id="type">
                              <Select
                                defaultValue={defaultStageTypeList}
                                placeholder="Please select"
                                name="type"
                                options={stageTypeList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleSelectTypeSave}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "var(--primary-color-light)",
                                    primary: "var(--primary-color)",
                                  },
                                })}
                                isClearable={false}
                                isSearchable={false}
                              />
                              <span className="error">
                                {defaultValue.error_list.type}
                              </span>
                              <p className="text-danger error4">
                                <ErrorMessage name="type" />
                              </p>
                            </Form.Group>
                          </Col>
                          <Form.Control
                            type="hidden"
                            name="is_used"
                            value={values.is_used}
                          />
                        </Form.Group>
                      </Row>
                    </div>
                  </Row>

                  <div className="text-center mt30">
                    {isSubmitting ? (
                      <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2 text-center">Please wait...</span>
                      </Button>
                    ) : (
                      <div
                        className="btn-toolbar"
                        role="toolbar"
                        aria-label="Toolbar with button groups"
                      >
                        <div
                          className="btn-group mr-2"
                          role="group"
                          aria-label="First group"
                        >
                          <Button
                            type="submit"
                            variant="success"
                            className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                          >
                            Update
                          </Button>
                        </div>
                        <div
                          className="btn-group mr-2"
                          role="group"
                          aria-label="First group"
                        >
                          <Button
                            type="button"
                            variant="success"
                            onClick={handleEditLeadStageClose}
                            className="popupbtn greycolor d-flex align-items-center justify-content-center"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default StageList;
