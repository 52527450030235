import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Card, Form, Spinner, FormCheck } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import { toast } from 'react-toastify';
import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import validator from 'validator'
import { Formik, ErrorMessage, Field } from "formik";
import * as yup from "yup";

import axios from 'axios';

var isSelectPer = 1;

function AddRole() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [userPermissionData, setUserPermissionData] = useState([]);
  const [permissionsArray, setPermissionsArray] = useState([]);

  useEffect(() => {

    getData();

  }, []);


  function getData() {
    axios.get('api/role/create').then(response => {
      if (response.status === 200) {
        setUserPermissionData(response.data.data.permissionArray);
        setLoading(false);
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
          history.push('/roles');
        } else {
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }

  const [defaultValue, setdefaultValue] = useState({
    name: '',
    permissions: [],
    error_list: [],
  })

  const validationSchema = yup.object().shape({
    name: yup.string().trim('Role name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter role name').matches(/^[a-z0-9\s]+$/i, "Only alphanumric are allowed"),
    //permissions: yup.bool().oneOf([true], 'Please check atleast one Permission'),
  });
  
  const handleCheckbox = (e) => {
    e.persist();
    const isChecked = e.target.checked;
    if (isChecked) {
      setPermissionsArray(permissionsArray => [...permissionsArray, e.target.value]);
    } else {
      setPermissionsArray(permissionsArray.filter(x => x !== e.target.value));
    }
  }
  
  const handleSubmit = (values,onSubmitProps) => {
    const formData = new FormData();
    
    formData.append('name', values.name)
    for (var i = 0; i < permissionsArray.length; i++) {
      formData.append('permissions[]', permissionsArray[i])
    }
    
    axios.post("/api/role/store", formData).then(response => {
      if (response.status === 200) {
        toast.success('Role has been created successfully');
        history.push('/roles');
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 400) {
          toast.warning(error.response.data.message);
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        }  else {
          toast.error('Oops Something went wrong.');
        }
      }

    });
  }
  
  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {

    let permissionList = Object.entries(userPermissionData).map(([key, value]) => {
      return (
        <div key={key}>
          <Form.Label style={{ textTransform: 'capitalize' }} className="mt-2">{key}</Form.Label>
          <div className="d-flex">
            {value.map(function (keys) {
              return (
                <>
                  {/* <input
                    component={Checkbox}
                    name="permissions[]"
                    type="checkbox"
                    className="form-check-input"
                    value={keys.id}
                    onChange={handleCheckbox}
                  />
                {keys.name} */}
                
                <FormCheck type="checkbox" className="d-flex me-3" key={keys.id}>
                  <FormCheck.Input name="permissions[]" onChange={handleCheckbox} value={keys.id} className="me-2" />
                  <FormCheck.Label htmlFor={'checkbox' + keys.id} style={{ fontWeight: 'normal' }}>
                    {keys.name}
                  </FormCheck.Label>
                </FormCheck>
                </>
              )
            })}
          </div>
        </div>
      )
    }, this);

    return (
      <>
        <Row>
          <Col xs={12}>
            <Card border="light" className="bg-white shadow-sm">
              <Row className="mb-3">
                <Col md={6}>
                </Col>
                <Col md={6} className="text-right">
                  <button className="btn1" onClick={() => { history.goBack(); }}><span className="backarrow-icon"></span>Back</button>
                </Col>
              </Row>
              <Card.Body className="borderline">
                <Row className="mb-3">
                  <Col md={6}>
                    <h5>Add Role information</h5>
                  </Col>
                  <Col md={6}>
                  </Col>
                </Row>
                <Formik
                  initialValues={defaultValue}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset
                    } = props;
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <div className="col-md-6 col-sm-6">
                            <Row className="align-items-center">
                              <Form.Group className="form-group mb-4 position-relative">
                                <Form.Label column sm="1">
                                  Name<span className="text-danger">*</span>
                                </Form.Label>
                                <Col sm="11">
                                  <Form.Group id="name">
                                    <Form.Control type="text" minLength={2} maxLength={50} name="name" onChange={handleChange} onBlur={handleBlur} placeholder="Enter role name" />
                                  </Form.Group>
                                  <span className="error error4">{defaultValue.error_list.name}</span>
                                  <span className="error error4">
                                    <ErrorMessage name="name" />
                                  </span>
                                </Col>
                              </Form.Group>
                            </Row>
                          </div>
                        </Row>
                        <Row>
                          <Col sm={8}>
                            <Form.Group id="permissions">
                              <Form.Label>Select Permissions</Form.Label>
                              {permissionList}
                            </Form.Group>
                            <span className="error">{defaultValue.error_list.permissions}</span>
                            <span className="error">
                              <ErrorMessage name="permissions" />
                            </span>
                          </Col>
                        </Row>
                        <div className="mt-3">
                          {
                            isSubmitting ?

                              <Button variant="primary" className="d-flex align-items-center justify-content-center text-center mb-1">
                                <Spinner animation="border" size="sm" />
                                <span className="ms-2 text-center">Please Wait...</span>
                              </Button>
                              :
                              <Button variant="primary" type="submit" className="d-flex">Submit <span class="rightarrow"></span></Button>
                          }

                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Card.Body>
            </Card>
          </Col>


        </Row>
      </>
    );
  }
}

export default AddRole;
