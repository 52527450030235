import React, { useEffect, useState, memo } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";

import "react-datepicker/dist/react-datepicker.css";
import { Col, Form } from "@themesberg/react-bootstrap";
import Preloader from "../../components/Preloader";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import "../../assets/css/custom.css";
import OrderSubscriptionList from "../../components/OrderSubscriptionList";

import { useHistory } from "react-router-dom";
import axios from "axios";

function ViewOrder(props) {
  const history = useHistory();
  const loginUser = useSelector(selectUser);

  const user = loginUser?loginUser:[];
  
  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
  
  const [dataID, setDataID] = useState(props.match.params.id);
  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
  //=========START:CODE FOR EDIT LEAD DATA==========//

  const [loading, setLoading] = useState(true);
  const [recordData, setRecordData] = useState("");

  //========Const data for Activity (Lead and Deal)
  var calendorFormate = "";

  var hostname = ''
  var onlyHost = window.location.hostname;
  onlyHost = onlyHost.split('.')
  if(onlyHost && onlyHost.length==3){
    onlyHost = onlyHost.slice(1) //remove first element of array
    hostname = onlyHost[0]+'.'+onlyHost[1]
  }else if(onlyHost && onlyHost.length==2){
    hostname = onlyHost[0]+'.'+onlyHost[1]
  }

  useEffect(() => {
    console.log();
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, []);

  async function getData() {
    await axios.get("api/order/view/" + dataID).then((response) => {
        if (response.data.code === 200) {
          setRecordData(response.data.data.record);
          if(hostname ==='solvcrm.co'){
            setPreferredCurrencyData({'currencyCode':'USD','currencySymbol':'$'});
          }else{
            setPreferredCurrencyData(response.data.data.staticData.preCurrency);
          }
          setPreferredDateFormatData(response.data.data.staticData.preDateFormat);

          calendorFormate =
            response.data.data.staticData.preDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
              setCalendorFullDateFormate("yyyy/MM/DD");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
              setCalendorFullDateFormate("DD/MM/yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
              setCalendorFullDateFormate("yyyy/DD/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
              setCalendorFullDateFormate("MM/DD/yyyy");
            }
          }

          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 301) {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error("Oops Something went wrong.");
          }
          history.push("/orders");
        }
      });
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {

    let FFName = recordData && recordData.added_by && recordData.added_by.name ? recordData.added_by.name : "";
    let fullName = "";

    if (FFName) {
      fullName = FFName;
      let expName = FFName.split(" ");
      if (expName.length == 2) {
        FFName = expName[0].charAt(0) + expName[1].charAt(0);
      } else {
        FFName = expName[0].charAt(0);
      }
    }

    let imageUrl = "";
    if (
      recordData && recordData.added_by &&
      recordData.added_by.images &&
      recordData.added_by.images[0] &&
      recordData.added_by.images[0].file_url
    ) {
      imageUrl =
        process.env.REACT_APP_API_URL +
        "storage/" +
        recordData.added_by.images[0].file_url;
    }

    var paymentMethod = "";
    if (recordData && recordData.payment_type == 1) {
      paymentMethod = "Stripe";
    } else if (recordData && recordData.payment_type == 2) {
      paymentMethod = "PayPal";
    } else if (recordData && recordData.payment_type == 3) {
      paymentMethod = "COD";
    } else if (recordData && recordData.payment_type == 4) {
      paymentMethod = "Free";
    } else if (recordData && recordData.payment_type == 5) {
      paymentMethod = "Other";
    }

    var paymentStatus = "";
    if (recordData && recordData.payment_status == 1) {
      paymentStatus = "Pending";
    } else if (recordData && recordData.payment_status == 2) {
      paymentStatus = "Complated";
    } else if (recordData && recordData.payment_status == 3) {
      paymentStatus = "InProcess";
    }

    var orderType = "NULL";
    if (recordData && recordData.payment_method == "normal") {
      orderType = "Normal";
    } else if (recordData && recordData.payment_method == "recurring") {
      orderType = "Recurring / Subscription";
    }

    return (
      <>
        {/* <Row> */}
        <div className="display flex-wrap">
          {/*--- client_information_section ---*/}
          <Col md={12} className="client activity mt30">
            {/*--- client_name_and_convert_lead_section ---*/}
            <div className="client_details display justify-content-between">
              {/*--- client_image_and_deatils_section ---*/}
              <div className="client_image1 display">
                <label className="custom-file-upload">
                  <input
                    type="file"
                    id="file_url"
                    name="file_url"
                    accept="image/jpeg, image/png, image/jpg"
                  />
                  <Stack direction="row" spacing={3}>
                    <Avatar
                      alt={fullName}
                      src={imageUrl}
                      sx={{ width: 50, height: 50 }}
                      onError={addDefaultSrc}
                      className="clientimg1"
                    >
                      {FFName}
                    </Avatar>
                  </Stack>
                </label>
                <div className="client_name ml20">
                  <div className="display">
                    <h5>{fullName}</h5>
                  </div>
                </div>
              </div>
              {/*--- client_update_time_and_date_section ---*/}
              <div className="joint display">
                <div className="client_update1">
                  <div className="client_txt display justify-content-end">
                    <span>Last update</span>
                  </div>
                  <div className="client_date_and_time display justify-content-end">
                    {/* <span>July, 03 2022 | 10:13am</span> */}
                    <span>
                      {moment
                        .tz(recordData.updated_at, defaultTimeZone)
                        .format(preferredDateFormatData)}
                    </span>
                  </div>
                </div>
                <div className="client_action1 text-right ps-3"></div>
              </div>
              {/*--- client_action_section ---*/}
            </div>
          </Col>

          <Col md={12} className="mt30">
            <div className="display w-100">
              <Accordion defaultActiveKey="0" className="w-100">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h4>Order Information</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="display align-items-start flex-wrap">
                      <div className="col-md-6 circletrick">
                        <div className="display align-items-start flex-wrap">
                          <div className="col-md-12 circletrick">
                            <Form.Group className="mb-2 display">
                              <Form.Label className="text-right w-17">
                                Package name
                              </Form.Label>
                              <Form.Text>{recordData.package.name}</Form.Text>
                            </Form.Group>
                          </div>
                          <div className="col-md-12 circletrick ">
                            <Form.Group className="mb-2 display">
                              <Form.Label className="text-right w-17">
                                Package Detail
                              </Form.Label>
                              <Form.Text>
                                {recordData.description} User 
                              </Form.Text>
                            </Form.Group>
                          </div>
                          <div className="col-md-12 circletrick">
                            <Form.Group className="mb-2 display">
                              <Form.Label className="text-right w-17">
                                Paid for
                              </Form.Label>
                              <Form.Text>
                                {recordData.user_count} User
                              </Form.Text>
                            </Form.Group>
                          </div>
                          <div className="col-md-12 circletrick">
                            <Form.Group className="mb-2 display">
                              <Form.Label className="text-right w-17">
                                Order Type
                              </Form.Label>
                              <Form.Text>{orderType}</Form.Text>
                            </Form.Group>
                          </div>
                          <div className="col-md-12 circletrick">
                            <Form.Group className="display align-items-start">
                              <Form.Label className="text-right w-17">
                                Modified by
                              </Form.Label>
                              <div className="fontdisabled display flex-wrap">
                                <p className="disabledrecord">
                                  {recordData.added_by
                                    ? recordData.added_by.name
                                      ? recordData.added_by.name
                                      : "N/A"
                                    : "N/A"}
                                </p>
                                <p className="fontsbreak">
                                  {moment
                                    .tz(recordData.updated_at, defaultTimeZone)
                                    .format(preferredDateFormatData)}
                                </p>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 circletrick">
                        <div className="display align-items-start flex-wrap">
                          <div className="col-md-12 circletrick">
                            <Form.Group className="mb-2 display">
                              <Form.Label className="text-right w-17">
                                Order Total &nbsp;
                                {preferredCurrencyData.currencyCode != ""
                                  ? preferredCurrencyData.currencyCode + " "
                                  : "" + " "}
                                (
                                {preferredCurrencyData.currencySymbol != ""
                                  ? preferredCurrencyData.currencySymbol
                                  : ""}
                                )
                              </Form.Label>
                              <Form.Text>{recordData.final_total}</Form.Text>
                            </Form.Group>
                          </div>
                          <div className="col-md-12 circletrick">
                            <Form.Group className="mb-2 display align-items-start">
                              <Form.Label className="text-right w-17 mt-1">
                                Payment Method
                              </Form.Label>
                              <Form.Text>{paymentMethod?paymentMethod:'N/A'}</Form.Text>
                            </Form.Group>
                          </div>
                          <div className="col-md-12 circletrick">
                            <Form.Group className="mb-2 display align-items-start">
                              <Form.Label className="text-right w-17 mt-1">
                                Payment Status
                              </Form.Label>
                              <Form.Text>{paymentStatus?paymentStatus:'N/A'}</Form.Text>
                            </Form.Group>
                          </div>
                          <div className="col-md-12 circletrick">
                            <Form.Group className="mb-2 display align-items-start">
                              <Form.Label className="text-right w-17 mt-1">
                                Transaction ID
                              </Form.Label>
                              <Form.Text>{recordData.transaction_id ? recordData.transaction_id:'N/A'}</Form.Text>
                            </Form.Group>
                          </div>
                          {recordData.payment_status !== 2 ? (
                            <div className="col-md-12 circletrick">
                              <Form.Group className="mb-2 display align-items-start">
                                <Form.Label className="text-right w-17 mt-1">
                                  Pending Reason
                                </Form.Label>
                                <Form.Text>
                                  {recordData.pending_reason?recordData.pending_reason:'N/A'}
                                </Form.Text>
                              </Form.Group>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="col-md-12 circletrick">
                            <Form.Group className="display align-items-start">
                              <Form.Label className="text-right w-17">
                                Created by
                              </Form.Label>
                              <div className="fontdisabled display flex-wrap">
                                <p className="disabledrecord">
                                  {recordData.added_by
                                    ? recordData.added_by.name
                                      ? recordData.added_by.name
                                      : "N/A"
                                    : "N/A"}
                                </p>
                                <p className="fontsbreak">
                                  {moment
                                    .tz(recordData.created_at, defaultTimeZone)
                                    .format(preferredDateFormatData)}
                                </p>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
          {recordData.payment_method == "recurring" ? (
            <OrderSubscriptionList data={dataID} />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default memo(ViewOrder);
