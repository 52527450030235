import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Card,
  Form,
  Spinner,
  FormCheck,
  Modal,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/PreloaderCenter";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Checkbox from "react-custom-checkbox";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import ExportData from "../../components/ExportData";
import {capitalize} from "../../utils/utils";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var searchData = "";
var searchInputDataName = "";
var searchInputDataEmail = "";
var searchInputDataFromDate = "";
var searchInputDataToDate = "";
var searchInputDataTitle = "";
var searchInputDataStatus = "";
var searchInputDataPhone = "";
var searchInputDataOrganizationName = "";

function RoleList(props) {
  const location = props.history.location;
  const loginUser = useSelector(selectUser);

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);

  const [isCheck, setIsCheck] = useState([]);
  const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
  const [isCheckAllBoxCheck, setIsCheckAllBoxCheck] = useState(false);

  const [mainChecked, setMainChecked] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const user = loginUser?loginUser:[];

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [search, setSearch] = useState("");

  const [showRoleDefault, setShowRoleDefault] = useState(false);
  const handleRoleClose = () => setShowRoleDefault(false);

  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");

  const [userPermissionData, setUserPermissionData] = useState([]);
  const [permissionsArray, setPermissionsArray] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);

  if (props.location.pathname == "/role") {
    if (props.location.statess) {
      searchData = props.location.statess.search;
    }

    if (props.location.searchData) {
      searchInputDataName = props.location.searchData.search_name;
      searchInputDataEmail = props.location.searchData.search_email;
      searchInputDataFromDate = props.location.searchData.search_from_date;
      searchInputDataToDate = props.location.searchData.search_to_date;
      searchInputDataTitle = props.location.searchData.search_title;
      searchInputDataStatus = props.location.searchData.search_status;
      searchInputDataPhone = props.location.searchData.search_phone;
      searchInputDataOrganizationName =
        props.location.searchData.search_organization_name;
    }
  }

  //==============START: Handle new search filter option==================//
  //==============END: Handle new search filter option==================//

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const sortColumn = ''
  
  const handlePerPage = (Page) => {
    setLoading(true);
    currentPage = 1;
    perPage = Page;
    getData();
  };
  //=========END:CODE FOR FOOTER PAGINATION===========//
  var calendorFormate = "";

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [
    sortColumn,
    sortOrder,
    search,
    showRoleDefault,
    location,
    searchInputDataName,
    searchInputDataEmail,
    searchInputDataFromDate,
    searchInputDataToDate,
    searchInputDataTitle,
    searchInputDataStatus,
    searchInputDataPhone,
    searchInputDataOrganizationName,
  ]);

  const getData = async (currentPage) => {
    const params = {
      search: search,
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    };
    setLoading(true);
    await axios
      .get(`api/role?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setListData(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });
          setPreferredDateFormatData(response.data.data.preferredDateFormat);

          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
            }
          }
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning(error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          }
        }
      });
  };

  function deleteData(id) {
    const data = {
      id: id,
    };

    swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios
          .delete("api/role/destroy", { data })
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              getData(currentPage);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              getData(currentPage);
              handleCheckAll(false);
              setIsCheckBoxCheck("");
              setIsCheck([]);
              getData(currentPage);
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error(error.response.data.message);
              }
            }
          });
      }
    });
  }

  function bulkDeleteData(ids) {
    const data = {
      id: ids,
    };

    swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios
          .delete("api/role/bulk-destroy", { data })
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              getData(currentPage);

              handleCheckAll(false);
              setIsCheckBoxCheck("");
              setIsCheck([]);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              getData(currentPage);
              handleCheckAll(false);
              setIsCheckBoxCheck("");
              setIsCheck([]);
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error(error.response.data.message);
              }
            }
          });
      }
    });
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  const addNewRole = async (e) => {
    await axios
      .get("api/role/create")
      .then((response) => {
        if (response.status === 200) {
          setUserPermissionData(response.data.data.permissionArray);
          setPermissionsList(response.data.data.permissions);
          setLoading(false);
          setShowRoleDefault(true);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/roles");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const [defaultValue, setdefaultValue] = useState({
    name: "",
    permissions: [],
    error_list: [],
  });

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter role name")
      .matches(/^[a-z0-9\s]+$/i, "Only alphanumric are allowed"),
    //permissions: yup.bool().oneOf([true], 'Please check atleast one Permission'),
  });

  //Export data file for logic
  const expParams = {
    search: searchData,
    search_name: searchInputDataName,
    search_email: searchInputDataEmail,
    search_from_date: searchInputDataFromDate,
    search_to_date: searchInputDataToDate,
    search_title: searchInputDataTitle,
    search_status: searchInputDataStatus,
    search_phone: searchInputDataPhone,
    search_organization_name: searchInputDataOrganizationName,
    sort_field: sortColumn,
    sort_order: sortOrder,
    per_page: perPage,
    page: currentPage,
    selectedIds: isCheck?isCheck:[],
  };

 
  const handleBulkDelete = () => {
    if (isCheck && isCheck.length > 0) {
      bulkDeleteData(isCheck);
    } else {
      toast.warning("Please select at least one record for delete.");
    }
  };

  //============Start:- Handle check all ================//
  const handleCheckbox = (value, id) => {
    if (value) {
      setIsCheck((isCheck) => [...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((x) => x !== id));
    }
  };

  const handleCheckAll = (value) => {
    if (value) {
      setMainChecked(true);
    } else {
      setMainChecked(false);
    }

    setIsCheckBoxCheck(value);
    setIsCheck([]);

    if (value) {
      setIsCheckBoxCheck(value);
      setIsCheck(listData.map((li) => li.id));
    }
  };
  //============End:- Handle check all ================//

  const selectAll = (e) => {
    setIsSelectAll(e.target.checked ? true : false);
    setIsCheckAllBoxCheck(e.target.checked ? true : false);
    if (isCheckAllBoxCheck) {
      setPermissionsArray([]);
    } else {
      setPermissionsArray(permissionsList.map((li) => li.id));
    }
  };
  //==============START:WRITE CODE FOR ADD ROLE=====================//
  const handlePermissionCheckbox = (e, id) => {
    if (e.target.checked) {
      setPermissionsArray((permissionsArray) => [...permissionsArray, id]);
    } else {
      setPermissionsArray(permissionsArray.filter((x) => x !== id));
      setIsSelectAll(false);
    }
  };

  const handleSubmit = async (values, onSubmitProps) => {
    const formData = new FormData();
    formData.append("name", values.name);
    for (var i = 0; i < permissionsArray.length; i++) {
      formData.append("permissions[]", permissionsArray[i]);
    }

    await axios
      .post("/api/role/store", formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Role has been created successfully");
          setShowRoleDefault(false);
          history.push("/roles");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400 || error.response.status === 401) {
            toast.warning(error.response.data.message);
            setdefaultValue({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else {
            toast.error("Opps.. Something went wrong.");
          }
        }
      });
  };
  //==============END:WRITE CODE FOR ADD ROLE=====================//

  var displayUserData = "";

  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayUserData = listData.map((item, i) => {
      let sortName = item.name;
      let expName = item.name.split(" ");

      if (expName.length === 3) {
        sortName = expName[0].charAt(0) + expName[1].charAt(0);
      }else if (expName.length === 2) {
        sortName = expName[0].charAt(0) + expName[1].charAt(0);
      } else {
        sortName = expName[0].charAt(0);
      }

      return (
        <div className="list_view bordertop pt16 pb18" key={i}>
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="role[]"
                checked={isCheckBoxCheck}
                value={item.id}
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckbox(value, item.id);
                }}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{
                  marginLeft: 5,
                  userSelect: "none",
                  "font-weight": "normal",
                }}
                label=""
              />
            </label>
          </div>

          {/*--- image_and_name_section ---*/}
          <div className="listdetails roledetails">
            <Stack direction="row" spacing={3}>
              <Avatar
                //alt={item.selected_role ? item.selected_role : 'N/A'}
                alt={item.name ? item.name : "N/A"}
                src={`${process.env.REACT_APP_API_URL}storage/${item.profile_pic}`}
                sx={{ width: 76, height: 76 }}
                onError={addDefaultSrc}
                className="listimg"
              >
                {sortName}
              </Avatar>
            </Stack>

            {/*---details_section ---*/}
            <div className="leaddetails">
              {userPermission ? (
                userPermission.includes("role_edit") ? (
                  <h5
                    onClick={() => history.push("/edit-role/" + item.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.name ? capitalize(item.name) : "N/A"}
                  </h5>
                ) : (
                  <h5>{item.name ? capitalize(item.name) : "N/A"}</h5>
                )
              ) : (
                <h5>{item.name ? capitalize(item.name) : "N/A"}</h5>
              )}
              {/*--- lead_inner1_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Added by:</span>
                    <h6>{item.selected_role ? item.selected_role : "N/A"}</h6>
                  </div>
                  {/*--- lead_company_section */}
                </div>
              </div>

              {/*--- lead_inner2_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_date_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Created:</span>
                    <h6>
                      {moment
                        .tz(item.created_at, defaultTimeZone)
                        .format(preferredDateFormatData)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*--- owner_section ---*/}
          <div className="listowner">
            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>{item.selected_role ? item.selected_role : "N/A"}</span>
                </div>
              </div>
            </div>

            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>Last Updated:</span>
                  <h6>
                    {moment
                      .tz(item.updated_at, defaultTimeZone)
                      .format(preferredDateFormatData)}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/*--- action_section ---*/}
          <div className="listaction text-right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="listdot"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {userPermission ? (
                  userPermission.includes("role_edit") ? (
                    <Dropdown.Item
                      onClick={() => history.push("/edit-role/" + item.id)}
                    >
                      Edit
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userPermission ? (
                  userPermission.includes("role_delete") ? (
                    <Dropdown.Item
                      onClick={() => {
                        deleteData(item.id);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    });

    let permissionList = Object.entries(userPermissionData).map(
      ([key, value]) => {
        return (
          <div key={key}>
            <Form.Label
              style={{ textTransform: "capitalize", fontWeight: "bold" }}
              className="mt-3 mb-2 labelcolor"
            >
              {key}
            </Form.Label>
            <div className="d-flex">
              {value.map(function (keys) {
                return (
                  <>
                    <FormCheck
                      type="checkbox"
                      className="d-flex me-3 align-items-center"
                      key={keys.id}
                    >
                      <FormCheck.Input
                        name="permissions[]"
                        checked={permissionsArray.includes(keys.id)}
                        value={keys.id}
                        //onChange={handlePermissionCheckbox}
                        onChange={(e) => {
                          handlePermissionCheckbox(e, keys.id);
                        }}
                        className="me-2"
                      />
                      <FormCheck.Label
                        htmlFor={"checkbox" + keys.id}
                        style={{
                          fontWeight: "normal",
                          textTransform: "capitalize",
                        }}
                        className="popcolor"
                      >
                        {keys.name}
                      </FormCheck.Label>
                    </FormCheck>
                  </>
                );
              })}
            </div>
          </div>
        );
      },
      this
    );

    return (
      <>
        <div className="searchbar mt30">
          <div className="col-md-3"></div>
          <div className="col-md-6 display justify-content-end">
            <div className="dropdown-section display">
              {userPermission ? (
                userPermission.includes("role_create") ? (
                  <Dropdown className="mr15">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="createbtn btnbluecolor"
                      onClick={() => addNewRole()}
                    >
                      Create New<span className="createicon"></span>
                    </Dropdown.Toggle>
                  </Dropdown>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="icon-section">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className=" dropdownborder1 dothover"
                >
                  <span className="dot"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item>
                        <ExportData params={expParams} fileName="Role" api="role" handleCheckAll={handleCheckAll}/>
                    </Dropdown.Item>
                  {userPermission ? (
                    userPermission.includes("role_delete") ? (
                      <Dropdown.Item onClick={() => handleBulkDelete()}>
                        Delete
                      </Dropdown.Item>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {/*--- searchbar_section_end ---*/}

        {/*--- list_view_section_start ---*/}
        <div className="display list_view mt30 pb4">
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="checkAll"
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckAll(value);
                }}
                checked={mainChecked}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                label=""
              />
            </label>
          </div>

          {/*--- details_section ---*/}
          <div className="listdetails">
            <h3>Role Details</h3>
          </div>
          {/*--- owner_section ---*/}
          <div className="listowner">
            <h3>Owner</h3>
          </div>
          {/*--- action_section ---*/}
          <div className="listaction"></div>
        </div>

        {displayUserData.length >= 1 ? (
          displayUserData
        ) : (
          <span>No data Available</span>
        )}
        {displayUserData.length >= 1 ? (
          <Row>
            <Col xs={12} xl={12} className="showing">
              <Card border="light" className="bg-white border-0">
                <Card.Body className="listcard pe-0 pt-0 pb-0">
                  <Row className="align-items-center">
                    <Col md={3} className="ps-2">
                      <h6>
                        Showing{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1)}
                        </b>{" "}
                        -{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1) +
                            ((dataCount ? dataCount : 0) - 1)}
                        </b>{" "}
                        of{" "}
                        <b>{state?.users?.total ? state?.users?.total : 0}</b>
                      </h6>
                    </Col>
                    <Col xl={4} md={4}>
                      <div className="row align-items-center g-0">
                        <div className="col-md-2 col-xl-3"></div>
                        <div className="col-md-3 col-xl-4">
                          <h6 className="text-right me-3">Per page</h6>
                        </div>
                        <div className="col-md-4 col-xl-4">
                          <select
                            className="border-start-0 border-end-0 border-top-0 borderbottom w-50"
                            value={perPage}
                            onChange={(e) => {
                              handlePerPage(e.target.value);
                              handleCheckAll(false);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                        <div className="col-md-3 col-xl-1"></div>
                      </div>
                    </Col>
                    <Col md={5} className="d-flex justify-content-end">
                      <Pagination
                        activePage={
                          state?.users?.current_page
                            ? state?.users?.current_page
                            : 0
                        }
                        itemsCountPerPage={
                          state?.users?.per_page ? state?.users?.per_page : 0
                        }
                        totalItemsCount={
                          state?.users?.total ? state?.users?.total : 0
                        }
                        onChange={(pageNumber) => {
                          currentPage = pageNumber;
                          getData(pageNumber);
                          handleCheckAll(false);
                        }}
                        pageRangeDisplayed={5}
                        itemClass="page-item mx-1"
                        linkClass="page-link"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Modal
          as={Modal.Dialog}
          centered
          show={showRoleDefault}
          onHide={handleRoleClose}
        >
          <Modal.Header>
            <Modal.Title className="h2 modal-title h4">Add Role</Modal.Title>
            {/* <Button variant="close" aria-label="Close" onClick={handleRoleClose} /> */}
            <Button
              variant="close"
              aria-label="Close"
              onClick={handleRoleClose}
            />
            <span className="modelclose"></span>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={defaultValue}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12} className="mb-3 position-relative">
                        <Form.Group id="email">
                          <Form.Label column sm="12">
                            <h6 className="fw600">
                              Name<span className="text-danger"> * </span>
                            </h6>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            minLength={2}
                            maxLength={50}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter role name"
                            className={
                              errors.name && touched.name ? "input-error" : null
                            }
                          />
                        </Form.Group>
                        <span className="error error4">
                          {defaultValue.error_list.name}
                        </span>
                        <span className="error error4">
                          <ErrorMessage name="name" />
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3 position-relative">
                        <Form.Group id="email">
                          <Form.Label column sm="12">
                            <h6 className="fw600 mb-2">Select Permissions</h6>
                            <Form>
                              <Form.Check
                                label="Select All"
                                id="checkbox1"
                                checked={isSelectAll}
                                value={isSelectAll ? false : true}
                                onClick={(e) => selectAll(e)}
                                htmlFor="checkbox1"
                              />
                            </Form>
                          </Form.Label>
                          {permissionList}
                        </Form.Group>
                        <span className="error error4">
                          {defaultValue.error_list.permissions}
                        </span>
                        <span className="error error4">
                          <ErrorMessage name="permissions" />
                        </span>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      {isSubmitting ? (
                        <Button className="d-flex align-items-center justify-content-center text-center popupbtn btnblue mb-1">
                          <Spinner animation="border" size="sm" />
                          <span className="ms-2 text-center">
                            Please wait...
                          </span>
                        </Button>
                      ) : (
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="submit"
                              variant="success"
                              className="popupbtn btnblue mr15"
                            >
                              Submit
                            </Button>
                          </div>
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="button"
                              variant="success"
                              className="popupbtn greycolor"
                              onClick={handleRoleClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default RoleList;
