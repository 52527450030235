/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SourceAddPopUp } from "../features/userSlice";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";

export default (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const showAddSource = useSelector((state) => state.sourceAddPopUp);

  const [defaultValue, setdefaultValue] = useState({
    title: "",
    is_used_in: "",
    error_list: [],
  });

  useEffect(() => {
    async function fetchData() {
      await getCreateData();
    }
    fetchData();
  }, [showAddSource]);

  async function getCreateData() {
    await axios
      .get("api/source/create")
      .then((response) => {
        if (response.status === 200) {
          setdefaultValue({
            title: "",
            is_used_in: "",
            error_list: [],
          });

          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/stages");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const handleLeadStageClose = () => {
    setdefaultValue({ ...defaultValue, error_list: "" });
    dispatch(SourceAddPopUp(false));
  };

  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .trim("Title cannot include space")
      .strict(true)
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter source title"),
  });

  const handleSelectTypeSave = (e) => {
    setdefaultValue({ ...defaultValue, ["is_used_in"]: e.value });
  };

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      title: values.title,
      is_used_in: defaultValue.is_used_in,
    };

    await axios
      .post("/api/source/store", data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(SourceAddPopUp(false));
          toast.success("Source created successfully");
          history.push("/sources");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400) {
            setdefaultValue({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/sources");
          } else {
            toast.warning(error.response.data.message);
          }
        }
      });
  };

  let stageTypeList = [];
  stageTypeList.push({ label: "Lead and Deal", value: "" });
  stageTypeList.push({ label: "Lead", value: 1 });
  stageTypeList.push({ label: "Deal", value: 2 });

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showAddSource}
      onHide={handleLeadStageClose}
    >
      <Modal.Header>
        <Modal.Title className="h2">Add Source</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={handleLeadStageClose}
        />
        <span className="modelclose"></span>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = props;
            return (
              <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-md-12 col-sm-12">
                    <Row className="align-items-center">
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">
                            Title<span className="text-danger">*</span>
                          </h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="title">
                            <Form.Control
                              type="text"
                              minLength={2}
                              maxLength={50}
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter Title"
                              className={
                                errors.title && touched.title
                                  ? "input-error"
                                  : null
                              }
                            />
                            <span className="error error4">
                              {defaultValue.error_list.title}
                            </span>
                            <span className="error error4">
                              <ErrorMessage name="title" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                    </Row>
                  </div>
                </Row>

                <div className="text-center">
                  {isSubmitting ? (
                    <Button className="d-flex align-items-center popupbtn text-center btnblue mb-1">
                      <Spinner animation="border" size="sm" />
                      <span className="ms-2">Please wait...</span>
                    </Button>
                  ) : (
                    <div
                      className="btn-toolbar"
                      role="toolbar"
                      aria-label="Toolbar with button groups"
                    >
                      <div
                        className="btn-group mr-2"
                        role="group"
                        aria-label="First group"
                      >
                        <Button
                          type="submit"
                          variant="success"
                          className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                        >
                          Create
                        </Button>
                      </div>
                      <div
                        className="btn-group mr-2"
                        role="group"
                        aria-label="First group"
                      >
                        <Button
                          type="button"
                          variant="success"
                          onClick={handleLeadStageClose}
                          className="popupbtn greycolor d-flex align-items-center justify-content-center"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
