/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Spinner, Modal} from '@themesberg/react-bootstrap';
import { useDispatch,useSelector } from "react-redux";
import { AccountAddPopUp } from "../features/userSlice"
import {  toast } from 'react-toastify';
import { useHistory, } from 'react-router-dom';
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from 'axios';


export default (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  const showAddAccount = useSelector((state) => state.accountAddPopUp);
  const handleAccountClose = () => dispatch(AccountAddPopUp(false));

  const [defaultValue, setdefaultValue] = useState({
    organization_name: '',
    organization_email: '',
    organization_phone1: '',
    organization_phone2: '',
    organization_website: '',
    error_list: [],
  })


  useEffect(() => {
    async function fetchData() {
      await getCreateData();
    }
    
    if(showAddAccount){
      fetchData();  
    }
    
  }, [showAddAccount]); 


  async function getCreateData() {
    await axios.get('api/account/create').then(response => {
      if (response.status === 200) {
        setdefaultValue({ ...defaultValue, organization_name: '', organization_email: '', organization_phone1: '', organization_phone2: '', organization_website:'', error_list: [] });
      }
    }).then(json => {

    }).catch(error => {
      //setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 300) {
          toast.error(error.response.data.message);
        }else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }
  
  const phoneRegExp = "^(\\+\\d{1,3}( )?)?((\\(\\d{1,3}\\))|\\d{1,3})[- .]?\\d{3,4}[- .]?\\d{4}$";
  
  const validationSchema = yup.object().shape({
    organization_name:yup.string().trim('Company name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter company name'),
    organization_email:yup.string().max(50, 'Must be at most 50 characters').email('Please enter valid email address'),
    organization_phone1:yup.string().max(25,'Phone must be at most 25 characters'),
    organization_website:yup.string().max(50, 'Must be at most 50 characters').url('Please enter valid website url').nullable(),
    
  })
  
  const handleSubmit = async(values,onSubmitProps) =>{
    const data = {
      organization_name: values.organization_name,
      organization_email: values.organization_email,
      organization_phone1: values.organization_phone1,
      organization_website: values.organization_website,
    }

    
    await axios.post("/api/account/store", data).then(response => {
      if (response.status === 200) {
        dispatch(AccountAddPopUp(false))
        toast.success('Account created successfully');
        history.push('/accounts');
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 400) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        } else if(error.response.status === 403){
          toast.warning('Forbidden '+error.response.data.message);
          history.push('/accounts');    
        } else {
          toast.warning(error.response.data.message);
        }
      }

    });

  }
  
    return (
      <Modal as={Modal.Dialog} centered show={showAddAccount} onHide={handleAccountClose}>
              <Modal.Header>
                <Modal.Title className="h2">Add Account</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleAccountClose} /><span className="modelclose"></span>
              </Modal.Header>
              <Modal.Body>
              <Formik 
                    initialValues={defaultValue} 
                    validationSchema={validationSchema} 
                    onSubmit={handleSubmit}
                  >{props => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                      setFieldValue,
                      setFieldTouched,
                    } = props;
                    return (
                <Form encrypted="multipart/form-data" onSubmit={handleSubmit} >
                  <Row>
                    <div className="col-md-12 col-sm-12">
                      <Row className="align-items-center">
                      <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Company name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="organization_name">
                              <Form.Control type="text" name="organization_name" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter company name" className={errors.organization_name && touched.organization_name ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.organization_name}</span>
                              <span className="error error4">
                                <ErrorMessage name="organization_name"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        
                        <Form.Group className="mb-125 position-relativ">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Company email</h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="organization_email">
                              <Form.Control type="text" name="organization_email" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter company email" className={errors.organization_email && touched.organization_email ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.organization_email}</span>
                              <span className="error error4">
                                <ErrorMessage name="organization_email"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Company phone</h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="organization_phone1">
                              <Form.Control type="text" name="organization_phone1" maxLength={25} onChange={handleChange} onBlur={handleBlur} placeholder="99999 12345" className={errors.organization_phone1 && touched.organization_phone1 ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.organization_phone1}</span>
                              <span className="error error4">
                                <ErrorMessage name="organization_phone1"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label column sm="12">
                            <h6 className="fw600">Website</h6>
                          </Form.Label>
                          <Col sm="12" className="position-relative">
                            <Form.Group id="organization_website">
                              <Form.Control type="text" name="organization_website" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter company website" />
                              <span className="error error4">{defaultValue.error_list.organization_website}</span>
                              <span className="error error4">
                                <ErrorMessage name="organization_website"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        
                      </Row>
                    </div>
                  </Row>


                  <div className="text-center mt30">
                  {
                    isSubmitting ? 

                      <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2 text-center">Please wait...</span>
                      </Button>
                      :

                      <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                        </div>
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="button" variant="success" onClick={handleAccountClose} className="popupbtn greycolor d-flex align-items-center justify-content-center">Cancel</Button>
                        </div>
                      </div>
                  }
                </div>
                </Form>
                );
              }}
                </Formik>
              </Modal.Body>

      </Modal>
    );


};
