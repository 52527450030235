import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Dropdown, Card } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/PreloaderCenter";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData,NotificationCount,NotificationList} from "../../features/userSlice";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Checkbox from "react-custom-checkbox";
import ExportData from "../../components/ExportData";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;

var searchData = "";
var searchInputDataName = "";
var searchInputDataEmail = "";
var searchInputDataFromDate = "";
var searchInputDataToDate = "";
var searchInputDataTitle = "";
var searchInputDataStatus = "";
var searchInputDataPhone = "";
var searchInputDataOrganizationName = "";

var currencyCode = "";
var currencySymbol = "";

function QuoteList(props) {
  const searchParams = new URLSearchParams(document.location.search);
  const location = props.history.location;
  const loginUser = useSelector(selectUser);
  const dispatch = useDispatch();

  const [isCheck, setIsCheck] = useState([]);
  const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
  const [mainChecked, setMainChecked] = useState(false);

  const user = loginUser?loginUser:[];

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
  
  const [currentPage, setCurrentPage] = useState(
    searchParams.size != 0
      ? searchParams.get("page")
        ? searchParams.get("page")
        : 1
      : 1
  );

  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  
  const [serchQuoteStage, setSerchQuoteStage] = useState(
    searchParams.get("search_quote_stage")
      ? searchParams.get("search_quote_stage")
      : ""
  );
  const [quoteStage, setQuoteStage] = useState([]);

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
  const [searchString, setSearchString] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );

  //==============START: Handle new search filter option==================//
  const handleSearchInput = (e) => {
    setSearchString(e.target.value);
  };

  const manageSearch = async (e) => {
    e.preventDefault();
    setSearch(searchString);
    setCurrentPage(1);
    if (searchString) {
      await props.history.push(
        "/quotes?page=" +
          1 +
          "&search=" +
          searchString +
          "&search_quote_stage=" +
          serchQuoteStage +
          "&sort_field=" +
          '' +
          "&sort_order=" +
          sortOrder +
          "&per_page=" +
          perPage
      );
    } else {
      await props.history.push("/quotes");
    }
  };

  const handleSelectQuoteStageStatus = async (event) => {
    setSerchQuoteStage(event.value);
    setCurrentPage(1);
    await props.history.push(
      "/quotes?page=" +
        1 +
        "&search=" +
        searchString +
        "&search_quote_stage=" +
        event.value +
        "&sort_field=" +
        '' +
        "&sort_order=" +
        sortOrder +
        "&per_page=" +
        perPage
    );
  };

  //==============END: Handle new search filter option==================//

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [listQuote, setQuote] = useState([]);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const handlePerPage = async (Page) => {
    setLoading(true);
    setCurrentPage(1);
    perPage = Page;
    await props.history.push(
      "/quotes?page=" +
        1 +
        "&search=" +
        searchString +
        "&search_quote_stage=" +
        serchQuoteStage +
        "&sort_field=" +
        '' +
        "&sort_order=" +
        sortOrder +
        "&per_page=" +
        perPage
    );
  };
  //=========END:CODE FOR FOOTER PAGINATION===========//
  var calendorFormate = "";
  useEffect(() => {
    setLoading(true);
    getData(
      searchParams.size != 0
        ? searchParams.get("page")
          ? searchParams.get("page")
          : 1
        : 1
    );
  }, [
    sortOrder,
    search,
    location,
    serchQuoteStage,
    searchInputDataName,
    searchInputDataEmail,
    searchInputDataFromDate,
    searchInputDataToDate,
    searchInputDataTitle,
    searchInputDataStatus,
    searchInputDataPhone,
    searchInputDataOrganizationName,
  ]);

  const getData = async (currentPage) => {
    setSerchQuoteStage(
      searchParams.get("search_quote_stage")
        ? searchParams.get("search_quote_stage")
        : ""
    );
    setSearchString(
      searchParams.get("search") ? searchParams.get("search") : ""
    );

    const params = {
      search: searchParams.size != 0 ? search : "",
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_quote_stage: searchParams.size != 0 ? serchQuoteStage : "",
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      sort_field: '',
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    };

    await axios
      .get(`api/quote?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setQuote(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });

          setQuoteStage(response.data.data.quoteStage);

          setPreferredCurrencyData(response.data.data.preferredCurrency);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);

          if (response.data.data.preferredCurrency.currencyCode) {
            currencyCode = response.data.data.preferredCurrency.currencyCode
              ? response.data.data.preferredCurrency.currencyCode
              : "";
            currencySymbol = response.data.data.preferredCurrency.currencySymbol
              ? response.data.data.preferredCurrency.currencySymbol
              : "";
          }

          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
            }
          }

          dispatch(
            NotificationList(response.data.data.notifications),
          );
          
          dispatch(
            NotificationCount(response.data.data.notifications.length),
          );
          
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning(error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          }
        }
      });
  };

  async function deleteData(id) {
    const data = {
      id: id,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/quote/destroy", { data });

          if (response.status === 200) {
            toast.success(response.data.message);
            getData(currentPage);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  async function bulkDeleteData(ids) {
    const data = {
      id: ids,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/quote/bulk-destroy", {
            data,
          });

          if (response.status === 200) {
            toast.success(response.data.message);
            getData(currentPage);
            handleCheckAll(false);
            setIsCheckBoxCheck("");
            setIsCheck([]);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  //==== select_mate
  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  //Export data file for logic
  const expParams = {
    search: searchData,
    search_name: searchInputDataName,
    search_email: searchInputDataEmail,
    search_from_date: searchInputDataFromDate,
    search_to_date: searchInputDataToDate,
    search_title: searchInputDataTitle,
    search_status: searchInputDataStatus,
    search_phone: searchInputDataPhone,
    search_organization_name: searchInputDataOrganizationName,
    search_quote_stage: serchQuoteStage,
    sort_field: '',
    sort_order: sortOrder,
    per_page: perPage,
    page: currentPage,
    selectedIds: isCheck?isCheck:[],
  };
  
  const handleBulkDelete = () => {
    if (isCheck && isCheck.length > 0) {
      bulkDeleteData(isCheck);
    } else {
      toast.warning("Please select at least one record for delete.");
    }
  };

  //============Start:- Handle check all ================//
  const handleCheckbox = (value, id) => {
    if (value) {
      setIsCheck((isCheck) => [...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((x) => x !== id));
    }
  };

  const handleCheckAll = async (value) => {
    if (value) {
      setMainChecked(true);
    } else {
      setMainChecked(false);
    }

    setIsCheckBoxCheck(value);
    setIsCheck([]);

    if (value) {
      setIsCheckBoxCheck(value);
      setIsCheck(listQuote.map((li) => li.id));
    }
  };
  //============End:- Handle check all ================//

  //==============For Task Source Filter================
  let defaultQuoteStage = [];
  if (serchQuoteStage.length < 1) {
    defaultQuoteStage = [{ label: "All Quote Stage", value: "" }];
  }

  Object.keys(quoteStage).map(function (key) {
    if (serchQuoteStage === key) {
      defaultQuoteStage.push({ label: quoteStage[key], value: key });
    }
  }, this);

  let quoteStageListArray = [{ label: "All Quote Stage", value: "" }];
  Object.keys(quoteStage).map(function (key) {
    quoteStageListArray.push({ label: quoteStage[key], value: key });
  }, this);

  var displayUserData = "";

  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayUserData = listQuote.map((item, i) => {
      let fullName = item.subject ? item.subject : "";
      let sortName = "";
      let expName = "";

      if (fullName) {
        expName = fullName.split(" ");
        if (expName.length == 2) {
          sortName = expName[0].charAt(0) + expName[1].charAt(0);
        } else if (expName.length > 2) {
          sortName = expName[0].charAt(0) + expName[1].charAt(0);
        } else {
          sortName = expName[0].charAt(0);
        }
      }

      let num = parseInt(item.total);
      const myObj = {
        style: "currency",
        currency: currencyCode,
      };

      return (
        <div className="list_view bordertop pt16 pb18" key={i}>
          {/*--- checkmark_section ---*/}
          <div className="chbox taskcheckbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="task[]"
                checked={isCheckBoxCheck}
                value={item.id}
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckbox(value, item.id);
                }}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{
                  marginLeft: 5,
                  userSelect: "none",
                  "font-weight": "normal",
                }}
                label=""
              />
              <span className="checkmark-----"></span>
            </label>
          </div>

          {/*--- image_and_name_section ---*/}
          <div className="listdetails dealdetails">
            <Stack direction="row" spacing={3}>
              <Avatar
                alt={fullName}
                src={``}
                sx={{ width: 76, height: 76 }}
                onError={addDefaultSrc}
                className="listimg"
              >
                {sortName}
              </Avatar>
            </Stack>

            {/*---details_section ---*/}
            <div className="dealdetails">
              {userPermission ? (
                userPermission.includes("quote_edit") ? (
                  <h5
                    onClick={() =>
                      history.push(
                        "/edit-quote/" +
                          item.id +
                          "?page=" +
                          currentPage +
                          "&search=" +
                          search +
                          "&search_quote_stage=" +
                          serchQuoteStage +
                          "&sort_field=" +
                          '' +
                          "&sort_order=" +
                          sortOrder +
                          "&per_page=" +
                          perPage
                      )
                    }
                    style={{ cursor: "pointer", textTransform: "capitalize" }}
                  >
                    {item.subject ? item.subject : "N/A"}
                  </h5>
                ) : (
                  <h5>{item.subject ? item.subject : "N/A"}</h5>
                )
              ) : (
                <h5>{item.subject ? item.subject : "N/A"}</h5>
              )}

              {/*--- lead_inner1_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Deal Name:</span>
                    <h6>
                      {item.selected_deal_name
                        ? item.selected_deal_name
                        : "N/A"}
                    </h6>
                  </div>
                  <div className="leadtitle display align-items-start">
                    <span>Company Name:</span>
                    <h6>
                      {item.selected_account_name
                        ? item.selected_account_name
                        : "N/A"}
                    </h6>
                  </div>

                  {/*--- lead_company_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Grand Total:</span>
                    <h6>
                      {num
                        ? preferredCurrencyData.currencyStringValue
                          ? num.toLocaleString(
                              preferredCurrencyData.currencyStringValue,
                              myObj
                            )
                          : currencySymbol + num.toLocaleString(myObj)
                        : "N/A"}
                    </h6>
                  </div>
                </div>
              </div>

              {/*--- lead_inner2_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_date_section */}

                  {/*--- lead_phone_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Contact Name:</span>
                    <h6>
                      {item.selected_contact_name
                        ? item.selected_contact_name
                        : "N/A"}
                    </h6>
                  </div>
                  <div className="leadtitle display align-items-start">
                    <span>Valid Until:</span>
                    <h6>
                      {item.valid_until
                        ? moment
                            .tz(item.valid_until, defaultTimeZone)
                            .format(preferredDateFormatData)
                        : "N/A"}
                    </h6>
                  </div>
                  <div className="leadtitle display align-items-start">
                    <span>Created Date:</span>
                    <h6>
                      {item.created_at
                        ? moment
                            .tz(item.created_at, defaultTimeZone)
                            .format(preferredDateFormatData)
                        : "N/A"}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*--- owner_section ---*/}
          <div className="listowner tasklistowner">
            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>
                    {item.added_by != null ? item.added_by.name : "N/A"}
                  </span>
                </div>
              </div>
            </div>

            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>Last Updated:</span>
                  <h6>
                    {moment
                      .tz(item.updated_at, defaultTimeZone)
                      .format(preferredDateFormatData)}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/*--- action_section ---*/}
          <div className="listaction tasklistaction text-right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="listdot"></span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {userPermission ? (
                  userPermission.includes("quote_edit") ? (
                    <Dropdown.Item
                      onClick={() =>
                        history.push(
                          "/edit-quote/" +
                            item.id +
                            "?page=" +
                            currentPage +
                            "&search=" +
                            search +
                            "&search_quote_stage=" +
                            serchQuoteStage +
                            "&sort_field=" +
                            '' +
                            "&sort_order=" +
                            sortOrder +
                            "&per_page=" +
                            perPage
                        )
                      }
                    >
                      Edit
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userPermission ? (
                  userPermission.includes("quote_delete") ? (
                    <Dropdown.Item
                      onClick={() => {
                        deleteData(item.id);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="searchbar mt30">
          <div className="col-md-4">
            <form onSubmit={manageSearch}>
              <input
                type="text"
                name="searchData"
                className="form-control search"
                value={searchString}
                placeholder="Search this list...."
                onChange={handleSearchInput}
              ></input>
              <span className="listicon" onClick={manageSearch}></span>
            </form>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-6 display justify-content-end">
            <div className="dropdown-section display">
              {userPermission ? (
                userPermission.includes("quote_create") ? (
                  <Dropdown className="mr15">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="createbtn btnbluecolor"
                      onClick={() => history.push("/create-quote")}
                    >
                      Create New<span className="createicon"></span>
                    </Dropdown.Toggle>
                  </Dropdown>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <Select
                defaultValue={defaultQuoteStage}
                name="quote_status"
                options={quoteStageListArray}
                styles={customStyles}
                className="basic-multi-select mr15 br10"
                classNamePrefix="select"
                onChange={handleSelectQuoteStageStatus}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--primary-color-light)",
                    primary: "var(--primary-color)",
                  },
                })}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            <div className="icon-section">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className=" dropdownborder1 dothover"
                >
                  <span className="dot"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item>
                        <ExportData params={expParams} fileName="Quote" api="quote" handleCheckAll={handleCheckAll}/>
                    </Dropdown.Item>
                  {userPermission ? (
                    userPermission.includes("quote_delete") ? (
                      <Dropdown.Item onClick={() => handleBulkDelete()}>
                        Delete
                      </Dropdown.Item>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {/*--- searchbar_section_end ---*/}

        {/*--- list_view_section_start ---*/}
        <div className="display list_view mt30 pb4">
          {/*--- checkmark_section ---*/}
          <div className="chbox quotecheckbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="checkAll"
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckAll(value);
                }}
                checked={mainChecked}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                label=""
              />
            </label>
          </div>

          {/*--- details_section ---*/}
          <div className="listdetails">
            <h3>Quote Details</h3>
          </div>
          <div className="listowner">
            <h3>Owner</h3>
          </div>
          <div className="listaction"></div>
        </div>

        {displayUserData.length >= 1 ? (
          displayUserData
        ) : (
          <span>No data Available</span>
        )}
        {displayUserData.length >= 1 ? (
          <Row>
            <Col xs={12} xl={12} className="showing">
              <Card border="light" className="bg-white border-0">
                <Card.Body className="listcard pe-0">
                  <Row className="align-items-center">
                    <Col md={3} className="ps-2">
                      <h6>
                        Showing{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1)}
                        </b>{" "}
                        -{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1) +
                            ((dataCount ? dataCount : 0) - 1)}
                        </b>{" "}
                        Out Of{" "}
                        <b>{state?.users?.total ? state?.users?.total : 0}</b>
                      </h6>
                    </Col>
                    <Col xl={4} md={4}>
                      <div className="row align-items-center g-0">
                        <div className="col-md-3 col-xl-4"></div>
                        <div className="col-md-2 col-xl-3">
                          <h6 className="text-right me-3">Per Page</h6>
                        </div>
                        <div className="col-md-4 col-xl-4">
                          <select
                            className="border-start-0 border-end-0 border-top-0 borderbottom w-50"
                            value={perPage}
                            onChange={(e) => {
                              handlePerPage(e.target.value);
                              handleCheckAll(false);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                        <div className="col-md-2 col-xl-1"></div>
                      </div>
                    </Col>
                    <Col md={5} className="d-flex justify-content-end">
                      <Pagination
                        activePage={
                          state?.users?.current_page
                            ? state?.users?.current_page
                            : 0
                        }
                        itemsCountPerPage={
                          state?.users?.per_page ? state?.users?.per_page : 0
                        }
                        totalItemsCount={
                          state?.users?.total ? state?.users?.total : 0
                        }
                        onChange={(pageNumber) => {
                          props.history.push(
                            "/quotes?page=" +
                              pageNumber +
                              "&search=" +
                              searchString +
                              "&search_quote_stage=" +
                              serchQuoteStage +
                              "&sort_field=" +
                              '' +
                              "&sort_order=" +
                              sortOrder +
                              "&per_page=" +
                              perPage
                          );
                          setCurrentPage(pageNumber);
                          getData(pageNumber);
                          handleCheckAll(false);
                        }}
                        pageRangeDisplayed={5}
                        itemClass="page-item mx-1"
                        linkClass="page-link"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default QuoteList;
