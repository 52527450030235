import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";

import "react-datepicker/dist/react-datepicker.css";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Col, Dropdown, Form } from "@themesberg/react-bootstrap";
import Preloader from "../../components/Preloader";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ContactDealList from "../../components/ContactDealList";
import "react-edit-text/dist/index.css";
import "../../assets/css/custom.css";

import { useHistory } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import axios from "axios";
import EasyEdit, { Types } from "react-easy-edit";


function EditContact(props) {
  const history = useHistory();
  const loginUser = useSelector(selectUser);
  const [isEditable, setIsEditable] = useState(true);
  const [dealId, setDealId] = useState();
  const [accountList, setAccountList] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [isEditableAccount, setIsEditableAccount] = useState(false);

  const user = loginUser?loginUser:[];

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [dataID, setDataID] = useState(props.match.params.id);
  const [updateLeadData, setUpdateLeadData] = useState(false);

  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState();
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState();
  const [titleErrorMsg, setTitleErrorMsg] = useState();
  const [departmentErrorMsg, setDepartmentErrorMsg] = useState();
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState();
  const [Phone1ErrorMsg, setPhone1ErrorMsg] = useState();
  const [phone2ErrorMsg, setPhone2ErrorMsg] = useState();
  const [primaryEmailErrorMsg, setPrimaryEmailErrorMsg] = useState();
  const [secondryEmailErrorMsg, setSecondryEmailErrorMsg] = useState();
  const [facebookErrorMsg, setFacebookErrorMsg] = useState();
  const [twitterErrorMsg, setTwitterErrorMsg] = useState();
  const [linkedinErrorMsg, setLinkedinErrorMsg] = useState();
  const [instagramErrorMsg, setInstagramErrorMsg] = useState();
  const [address1ErrorMsg, setAddress1ErrorMsg] = useState();
  const [address2ErrorMsg, setAddress2ErrorMsg] = useState();
  const [cityErrorMsg, setCityErrorMsg] = useState();
  const [stateErrorMsg, setStateErrorMsg] = useState();
  const [zipcodeErrorMsg, setZipcodeErrorMsg] = useState();

  //=========START:CODE FOR EDIT LEAD DATA==========//

  const [loading, setLoading] = useState(true);
  const [dataRecord, setDataRecord] = useState("");

  const [ErrorInput, setErrorInput] = useState({ error_list: [] });

  const [countries, setCountry] = useState([]);
  const [salutation, setSalutation] = useState([]);

  const [getIpResponse, setGetIpResponse] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  //========Const data for Activity (Lead and Deal)
  var calendorFormate = "";
  useEffect(() => {
    // fetch(`https://ipapi.co/json/`)
    //      .then(response => response.json())
    //      .then(data =>
    //           setGetIpResponse(data)
    //      )
    //      .catch(error => '');

    // if ("geolocation" in navigator) {
    //      navigator.geolocation.getCurrentPosition(function (position) {
    //           setLatitude(position.coords.latitude);
    //           setLongitude(position.coords.longitude);
    //      });
    // } else {
    //      //console.log("Not Available");
    // }
    async function fetchData() {
      await getContact();
    }
    fetchData();
  }, [updateLeadData]);

  async function getContact() {
    await axios
      .get("api/contact/edit/" + dataID)
      .then((response) => {
        if (response.data.code === 200) {
          //console.log(response.data.data.record)
          setDataRecord(response.data.data.record);
          setCountry(response.data.data.countries);
          setSalutation(response.data.data.salutationArray);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);
          setAccountList(response.data.data.accountLists);
          setSelectedAccounts(response.data.data.selectedAccount);
          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
              setCalendorFullDateFormate("yyyy/MM/DD");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
              setCalendorFullDateFormate("DD/MM/yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
              setCalendorFullDateFormate("yyyy/DD/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
              setCalendorFullDateFormate("MM/DD/yyyy");
            }
          }

          if (response.data.data.record && response.data.data.record.deal_id) {
            setDealId(response.data.data.record.deal_id);
          }

          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/contacts");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
        history.push("/contacts");
      });
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  const handleSaveData = async (value, name) => {
    const data = {
      id: dataID,
      salutation: dataRecord.salutation,
      first_name: dataRecord.first_name,
      last_name: dataRecord.last_name,
      country_id: dataRecord.country_id,
      salutation: dataRecord.salutation,
      account_id: selectedAccounts,
      [name]: value,
    };

    if (value) {
      await axios
        .post("api/contact/update", data)
        .then((response) => {
          if (response.status === 200) {
            updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
            setErrorInput({ ...ErrorInput, error_list: "" });
            setIsEditable(true);
            setIsEditableAccount(false);
            toast.success("Data updated successfully");
          }
        })
        .then((json) => {})
        .catch((error) => {
          if (error.response) {
            setIsEditable(true);
            setIsEditableAccount(false);
            if (error.response.status === 400) {
              setErrorInput({
                ...ErrorInput,
                error_list: error.response.data.validation_errors,
              });
            } else if (error.response.status === 403) {
              //swal("error", error.response.data.message, "error");
              toast.warning("Forbidden " + error.response.data.message);
            } else if (error.response.status === 300) {
              toast.warning(error.response.data.message);
            } else {
              toast.error("Oops Something went wrong.");
            }
          }
        });
    } else {
      updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
      setErrorInput({ ...ErrorInput, error_list: "" });
      setIsEditable(true);
      setIsEditableAccount(false);
    }
  };

  const cancel = () => {
    setIsEditable(true);
    setIsEditableAccount(false);

    setFirstNameErrorMsg("");
    setLastNameErrorMsg("");
    setTitleErrorMsg("");
    setDepartmentErrorMsg("");
    setDescriptionErrorMsg("");
    setPhone1ErrorMsg("");
    setPhone2ErrorMsg("");
    setPrimaryEmailErrorMsg("");
    setSecondryEmailErrorMsg("");
    setFacebookErrorMsg("");
    setTwitterErrorMsg("");
    setLinkedinErrorMsg("");
    setInstagramErrorMsg("");
    setAddress1ErrorMsg("");
    setAddress2ErrorMsg("");
    setCityErrorMsg("");
    setStateErrorMsg("");
    setZipcodeErrorMsg("");
  };

  const handleClick = (e) => {
    setIsEditable(false);

    setIsEditableAccount(true);
    if (e && e == "account") {
      setIsEditableAccount(false);
    }
  };

  const handleAccountBlurClick = (e) => {
    if (e && e == "account") {
      setIsEditable(true);
      setIsEditableAccount(false);
    }
  };

  async function deleteRecord(id) {
    const data = {
      id: id,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/contact/destroy", { data });

          if (response.status === 200) {
            toast.success(response.data.message);
            history.push("/contacts");
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  const CustomSelectDisplay = (props) => {
    const handleSelectCountrySave = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const countrysLists = props.data.countrysLists;
    const selectedCountry = props.data.selectedCountry;

    return (
      <Select
        defaultValue={selectedCountry}
        name="country_id"
        options={countrysLists}
        //className="basic-multi-select leadselect"
        classNamePrefix="select"
        onChange={handleSelectCountrySave}
        // styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--primary-color-light)",
            primary: "var(--primary-color)",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayCountry = (props) => {
    const val = props;
    return (
      <div>
        {/* {val.value ? val.value : 'Select...'} */}
        {val.value
          ? !isNaN(val.value)
            ? "Please wait..."
            : val.value
          : "Select..."}
      </div>
    );
  };

  const CustomSalutationSelectDisplay = (props) => {
    const handleSelectSalutationSave = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const salutationList = props.data.salutationList;
    const selectedSalutation = props.data.selectedSalutation;

    return (
      <Select
        defaultValue={selectedSalutation}
        name="salutation"
        options={salutationList}
        //className="basic-multi-select leadselect"
        classNamePrefix="select"
        onChange={handleSelectSalutationSave}
        // styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--primary-color-light)",
            primary: "var(--primary-color)",
          },
        })}
      />
    );
  };

  const CustomSelectDisplaySalutation = (props) => {
    const val = props;
    return <div>{val.value ? val.value : "Select..."}</div>;
  };

  let newSelectAccount = [];
  const handleSelectAccountSave = async (e) => {
    newSelectAccount = Array.isArray(e) ? e.map((x) => x.value) : [];

    const data = {
      account_id: e.value, //newSelectAccount,
      id: dataID,
      salutation: dataRecord.salutation,
      first_name: dataRecord.first_name,
      last_name: dataRecord.last_name,
      country_id: dataRecord.country_id,
      salutation: dataRecord.salutation,
    };

    await axios
      .post("api/contact/update", data)
      .then((response) => {
        if (response.status === 200) {
          updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
          setErrorInput({ ...ErrorInput, error_list: "" });
          setIsEditable(true);
          setIsEditableAccount(false);
          toast.success("Data updated successfully");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setIsEditable(true);
          setIsEditableAccount(false);
          if (error.response.status === 400) {
            setErrorInput({
              ...ErrorInput,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const customStyles1 = {
    menuList: (styles) => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {
    let countrysLists = [];
    for (var i = 0, l = countries.length; i < l; i++) {
      countrysLists.push({
        label: countries[i].country_name,
        value: countries[i].id,
      });
    }

    let selectedCountry = [];
    for (var i = 0, l = countries.length; i < l; i++) {
      if (dataRecord.country_id == countries[i].id) {
        selectedCountry.push({
          label: countries[i].country_name,
          value: countries[i].id,
        });
      }
    }

    let selectedSalutation = [];
    Object.keys(salutation).map(function (key) {
      if (dataRecord.salutation == key) {
        selectedSalutation.push({ label: salutation[key], value: key });
      }
    }, this);

    let salutationList = [];
    Object.keys(salutation).map(function (key) {
      salutationList.push({ label: salutation[key], value: key });
    }, this);

    let accountLists = [];
    for (var i = 0, l = accountList.length; i < l; i++) {
      accountLists.push({
        label: accountList[i].organization_name,
        value: accountList[i].id,
      });
    }

    let selectedAccount = [];
    for (var i = 0, l = accountList.length; i < l; i++) {
      if (selectedAccounts.includes(accountList[i].id)) {
        selectedAccount.push({
          label: accountList[i].organization_name,
          value: accountList[i].id,
        });
      }
    }

    let FFName = "";
    if (dataRecord && dataRecord.first_name && dataRecord.last_name) {
      FFName = dataRecord.first_name.charAt(0) + dataRecord.last_name.charAt(0);
    } else if (dataRecord && dataRecord.first_name && !dataRecord.last_name) {
      FFName = dataRecord.first_name.charAt(0);
    } else if (dataRecord && !dataRecord && dataRecord.last_name) {
      FFName = dataRecord.last_name.charAt(0);
    }

    return (
      // <Row>
      <div className="display flex-wrap">
        {/*--- client_information_section ---*/}
        <Col md={12} className="client contanctlist activity mt30">
          {/*--- client_name_and_convert_lead_section ---*/}
          <div className="client_details display">
            {/*--- client_image_and_deatils_section ---*/}
            <div className="col-md-6 display">
              <div className="client_image display">
                <Stack direction="row" spacing={3}>
                  <Avatar
                    alt={dataRecord.first_name + " " + dataRecord.last_name}
                    src={`${process.env.REACT_APP_API_URL}`}
                    sx={{ width: 50, height: 50 }}
                    onError={addDefaultSrc}
                    className="clientimg"
                  >
                    {FFName}
                  </Avatar>
                </Stack>

                <div className="client_name ml20">
                  <div className="display">
                    <h5>
                      {dataRecord.first_name + " " + dataRecord.last_name}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 display justify-content-end">
              {/*--- client_update_time_and_date_section ---*/}
              <div className="client_update">
                <div className="client_txt display justify-content-end">
                  <span>Last update</span>
                </div>
                <div className="client_date_and_time display justify-content-end">
                  {/* <span>July, 03 2022 | 10:13am</span> */}
                  <span>
                    {moment
                      .tz(dataRecord.updated_at, defaultTimeZone)
                      .format(preferredDateFormatData)}
                  </span>
                </div>
              </div>
              {/*--- converted_button ---*/}

              {/*--- client_action_section ---*/}
              <div className="client_action text-right">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className=" dropdownborder1 dothover"
                  >
                    <span className="dot"></span>
                  </Dropdown.Toggle>
                  {userPermission ? (
                    userPermission.includes("contact_delete") ? (
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            deleteRecord(dataID);
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>

        {/*--- Lead_information_section ---*/}
        <Col md={12} className="mt30">
          <div className="display w-100">
            <Accordion defaultActiveKey="0" className="w-100">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Contact Information</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="display align-items-start flex-wrap">
                    <div className="col-md-6 circletrick">
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-12 circletrick leadheight">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Salutation
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              onSave={(value) => {
                                handleSaveData(value, "salutation");
                              }}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              editComponent={
                                <CustomSalutationSelectDisplay
                                  data={{
                                    selectedSalutation: selectedSalutation,
                                    salutationList: salutationList,
                                  }}
                                />
                              }
                              value={
                                selectedSalutation.length > 0
                                  ? selectedSalutation[0].label
                                  : ""
                              }
                              displayComponent={
                                <CustomSelectDisplaySalutation />
                              }
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                              placeholder="----"
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.salutation}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              First name
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.first_name}
                              onSave={(value) => {
                                handleSaveData(value, "first_name");
                              }}
                              onValidate={(value) => {
                                if (value) {
                                  var splString = value.split(" ");
                                  if (value && value.length > 50) {
                                    setFirstNameErrorMsg(
                                      "Only 50 characters are allowed"
                                    );
                                    return false;
                                  } else if (value && value.length < 2) {
                                    setFirstNameErrorMsg(
                                      "Enter at least 2 characters."
                                    );
                                    return false;
                                  } else if (
                                    value &&
                                    value.length == 2 &&
                                    value &&
                                    splString.length >= 2
                                  ) {
                                    setFirstNameErrorMsg(
                                      "Enter at least 2 characters, space not allow."
                                    );
                                    return false;
                                  }
                                  return true;
                                } else {
                                  setFirstNameErrorMsg("Enter first name");
                                  return false;
                                }
                              }}
                              validationMessage={firstNameErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 50 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.first_name}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Last name
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.last_name}
                              onSave={(value) => {
                                handleSaveData(value, "last_name");
                              }}
                              onValidate={(value) => {
                                if (value) {
                                  var splString = value.split(" ");
                                  if (value && value.length > 50) {
                                    setLastNameErrorMsg(
                                      "Only 50 characters are allowed"
                                    );
                                    return false;
                                  } else if (value && value.length < 2) {
                                    setLastNameErrorMsg(
                                      "Enter at least 2 characters."
                                    );
                                    return false;
                                  } else if (
                                    value &&
                                    value.length == 2 &&
                                    value &&
                                    splString.length >= 2
                                  ) {
                                    setLastNameErrorMsg(
                                      "Enter at least 2 characters, space not allow."
                                    );
                                    return false;
                                  }
                                  return true;
                                } else {
                                  setLastNameErrorMsg("Enter first name");
                                  return false;
                                }
                              }}
                              validationMessage={lastNameErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 50 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.last_name}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Primary email
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.primary_email}
                              onSave={(value) => {
                                handleSaveData(value, "primary_email");
                              }}
                              onValidate={(value) => {
                                if (
                                  value &&
                                  !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                                    value
                                  )
                                ) {
                                  setPrimaryEmailErrorMsg(
                                    "Please enter valid email address."
                                  );
                                  return false;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={primaryEmailErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 50 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.primary_email}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Secondary email
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.secondry_email}
                              onSave={(value) => {
                                handleSaveData(value, "secondry_email");
                              }}
                              onValidate={(value) => {
                                if (
                                  value &&
                                  !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                                    value
                                  )
                                ) {
                                  setSecondryEmailErrorMsg(
                                    "Enter valid email address."
                                  );
                                  return false;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={secondryEmailErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 50 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.secondry_email}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Account{" "}
                            </Form.Label>
                            <Select
                              defaultValue={selectedAccount}
                              //isMulti
                              name="account_id"
                              options={accountLists}
                              className="basic-multi-select producttxt"
                              classNamePrefix="select"
                              onChange={handleSelectAccountSave}
                              styles={customStyles1}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "var(--primary-color-light)",
                                  primary: "var(--primary-color)",
                                },
                              })}
                              isDisabled={isEditableAccount}
                              onFocus={() => handleClick("account")}
                              onBlur={() => handleAccountBlurClick("account")}
                              placeholder="----"
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.account_id}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 circletrick">
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Title
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.title}
                              onSave={(value) => {
                                handleSaveData(value, "title");
                              }}
                              onValidate={(value) => {
                                if (value) {
                                  var splString = value.split(" ");
                                  if (value && value.length > 50) {
                                    setTitleErrorMsg(
                                      "Only 50 characters are allowed"
                                    );
                                    return false;
                                  } else if (value && value.length < 2) {
                                    setTitleErrorMsg(
                                      "Enter at least 2 characters."
                                    );
                                    return false;
                                  } else if (
                                    value &&
                                    value.length == 2 &&
                                    value &&
                                    splString.length >= 2
                                  ) {
                                    setTitleErrorMsg(
                                      "Enter at least 2 characters, space not allow."
                                    );
                                    return false;
                                  }
                                  return true;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={titleErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 50 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.title}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Department
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.department}
                              onSave={(value) => {
                                handleSaveData(value, "department");
                              }}
                              onValidate={(value) => {
                                if (value) {
                                  var splString = value.split(" ");
                                  if (value && value.length > 50) {
                                    setDepartmentErrorMsg(
                                      "Only 50 characters are allowed"
                                    );
                                    return false;
                                  } else if (value && value.length < 2) {
                                    setDepartmentErrorMsg(
                                      "Enter at least 2 characters."
                                    );
                                    return false;
                                  } else if (
                                    value &&
                                    value.length == 2 &&
                                    value &&
                                    splString.length >= 2
                                  ) {
                                    setDepartmentErrorMsg(
                                      "Enter at least 2 characters, space not allow."
                                    );
                                    return false;
                                  }
                                  return true;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={departmentErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 50 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.department}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick description-input position-relative">
                          <Form.Group className="mb-2 display align-items-start">
                            <Form.Label className="text-right w-17 mt-1">
                              Description
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXTAREA}
                              value={dataRecord.description}
                              onSave={(value) => {
                                handleSaveData(value, "description");
                              }}
                              onValidate={(value) => {
                                if (value) {
                                  var splString = value.split(" ");
                                  if (value && value.length > 150) {
                                    setDescriptionErrorMsg(
                                      "Allow 150 characters only."
                                    );
                                    return false;
                                  } else if (value && value.length < 2) {
                                    setDescriptionErrorMsg(
                                      "Enter at least 2 characters."
                                    );
                                    return false;
                                  } else if (
                                    value &&
                                    value.length == 2 &&
                                    value &&
                                    splString.length >= 2
                                  ) {
                                    setDescriptionErrorMsg(
                                      "Enter at least 2 characters, space not allow."
                                    );
                                    return false;
                                  } else if (
                                    value.replace(/\s+/g, "").length < 2
                                  ) {
                                    setDescriptionErrorMsg(
                                      "Enter at least 2 characters, space not allow."
                                    );
                                    return false;
                                  }
                                  return true;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={descriptionErrorMsg}
                              cssClassPrefix="custom-textarea-"
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 150 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.description}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Phone1{" "}
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.phone1}
                              onSave={(value) => {
                                handleSaveData(value, "phone1");
                              }}
                              onValidate={(value) => {
                                if (value && value.length > 25) {
                                  setPhone1ErrorMsg(
                                    "Allow 25 characters only."
                                  );
                                  return false;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={Phone1ErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 25 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.phone1}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Phone2
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.phone2}
                              onSave={(value) => {
                                handleSaveData(value, "phone2");
                              }}
                              onValidate={(value) => {
                                if (value && value.length > 25) {
                                  setPhone2ErrorMsg(
                                    "Allow 25 characters only."
                                  );
                                  return false;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={phone2ErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 25 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.phone2}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="display align-items-start flex-wrap mb25">
                    <div className="col-md-12 circletrick">
                      <h4>Social Media Information</h4>
                    </div>
                  </div>

                  <div className="display align-items-start flex-wrap">
                    <div className="col-md-6 circletrick">
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Facebook
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.facebook_url}
                              onSave={(value) => {
                                handleSaveData(value, "facebook_url");
                              }}
                              onValidate={(value) => {
                                const regex =
                                  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                if (value) {
                                  if (value.length > 255) {
                                    setFacebookErrorMsg(
                                      "Allow 255 characters only."
                                    );
                                    return false;
                                  } else if (value && !regex.test(value)) {
                                    setFacebookErrorMsg("Enter valid url");
                                    return false;
                                  }
                                  return true;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={facebookErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 255 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.facebook_url}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Linkedin
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.linkedin_url}
                              onSave={(value) => {
                                handleSaveData(value, "linkedin_url");
                              }}
                              onValidate={(value) => {
                                const regex =
                                  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                if (value) {
                                  if (value.length > 255) {
                                    setLinkedinErrorMsg(
                                      "Allow 255 characters only."
                                    );
                                    return false;
                                  } else if (value && !regex.test(value)) {
                                    setLinkedinErrorMsg("Enter valid url");
                                    return false;
                                  }
                                  return true;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={linkedinErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 255 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.linkedin_url}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 circletrick">
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Twitter
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.twitter_url}
                              onSave={(value) => {
                                handleSaveData(value, "twitter_url");
                              }}
                              onValidate={(value) => {
                                const regex =
                                  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                if (value) {
                                  if (value.length > 255) {
                                    setTwitterErrorMsg(
                                      "Allow 255 characters only."
                                    );
                                    return false;
                                  } else if (value && !regex.test(value)) {
                                    setTwitterErrorMsg("Enter valid url");
                                    return false;
                                  }
                                  return true;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={twitterErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 255 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.twitter_url}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Instagram
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.instagram_url}
                              onSave={(value) => {
                                handleSaveData(value, "instagram_url");
                              }}
                              onValidate={(value) => {
                                const regex =
                                  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                if (value) {
                                  if (value.length > 255) {
                                    setInstagramErrorMsg(
                                      "Allow 255 characters only."
                                    );
                                    return false;
                                  } else if (value && !regex.test(value)) {
                                    setInstagramErrorMsg("Enter valid url");
                                    return false;
                                  }
                                  return true;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={instagramErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 255 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.instagram_url}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="display align-items-start flex-wrap mb25">
                    <div className="col-md-12 circletrick">
                      <h4>Address Information</h4>
                    </div>
                  </div>

                  <div className="display align-items-start flex-wrap">
                    <div className="col-md-6 circletrick">
                      <div className="col-md-12 circletrick description-input position-relative">
                        <Form.Group className="mb-2 display align-items-start">
                          <Form.Label className="text-right w-17 mt-1">
                            Address
                          </Form.Label>
                          <EasyEdit
                            type={Types.TEXTAREA}
                            value={dataRecord.address1}
                            onSave={(value) => {
                              handleSaveData(value, "address1");
                            }}
                            onValidate={(value) => {
                              if (value) {
                                var splString = value.split(" ");
                                if (value && value.length > 100) {
                                  setAddress1ErrorMsg(
                                    "Allow 100 characters only."
                                  );
                                  return false;
                                } else if (value && value.length < 2) {
                                  setAddress1ErrorMsg(
                                    "Enter at least 2 characters."
                                  );
                                  return false;
                                } else if (
                                  value &&
                                  value.length == 2 &&
                                  value &&
                                  splString.length >= 2
                                ) {
                                  setAddress1ErrorMsg(
                                    "Enter at least 2 characters, space not allow."
                                  );
                                  return false;
                                }
                                return true;
                              } else {
                                return true;
                              }
                            }}
                            validationMessage={address1ErrorMsg}
                            onCancel={cancel}
                            cssClassPrefix="custom-textarea-"
                            saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                            cancelButtonLabel={
                              <FontAwesomeIcon icon={faTimes} />
                            }
                            attributes={{ maxLength: 100 }}
                            placeholder="----"
                            instructions=""
                            allowEdit={isEditable}
                            onFocus={() => handleClick("")}
                          />
                        </Form.Group>
                        <span className="error">
                          {ErrorInput.error_list.address1}
                        </span>
                      </div>
                      <div className="col-md-12 circletrick">
                        <Form.Group className="mb-2 display">
                          <Form.Label className="text-right w-17">
                            City
                          </Form.Label>
                          <EasyEdit
                            type={Types.TEXT}
                            value={dataRecord.city}
                            onSave={(value) => {
                              handleSaveData(value, "city");
                            }}
                            onValidate={(value) => {
                              if (value) {
                                var splString = value.split(" ");
                                if (value && value.length > 50) {
                                  setCityErrorMsg(
                                    "Only 50 characters are allowed"
                                  );
                                  return false;
                                } else if (value && value.length < 2) {
                                  setCityErrorMsg(
                                    "Enter at least 2 characters."
                                  );
                                  return false;
                                } else if (
                                  value &&
                                  value.length == 2 &&
                                  value &&
                                  splString.length >= 2
                                ) {
                                  setCityErrorMsg(
                                    "Enter at least 2 characters, space not allow."
                                  );
                                  return false;
                                }
                                return true;
                              } else {
                                return true;
                              }
                            }}
                            validationMessage={cityErrorMsg}
                            onCancel={cancel}
                            saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                            cancelButtonLabel={
                              <FontAwesomeIcon icon={faTimes} />
                            }
                            attributes={{ maxLength: 50 }}
                            placeholder="----"
                            instructions=""
                            allowEdit={isEditable}
                            onFocus={() => handleClick("")}
                          />
                        </Form.Group>
                        <span className="error">
                          {ErrorInput.error_list.city}
                        </span>
                      </div>
                      <div className="col-md-12 circletrick">
                        <Form.Group className="display mb-2">
                          <Form.Label className="text-right w-17">
                            Zip code
                          </Form.Label>
                          <EasyEdit
                            type={Types.TEXT}
                            value={dataRecord.zipcode}
                            onSave={(value) => {
                              handleSaveData(value, "zipcode");
                            }}
                            onValidate={(value) => {
                              if (value && value.length > 10) {
                                setZipcodeErrorMsg("Allow 10 characters only.");
                                return false;
                              } else {
                                return true;
                              }
                            }}
                            validationMessage={zipcodeErrorMsg}
                            onCancel={cancel}
                            saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                            cancelButtonLabel={
                              <FontAwesomeIcon icon={faTimes} />
                            }
                            attributes={{ maxLength: 10 }}
                            placeholder="----"
                            instructions=""
                            allowEdit={isEditable}
                            onFocus={() => handleClick("")}
                          />
                        </Form.Group>
                        <span className="error">
                          {ErrorInput.error_list.zipcode}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 circletrick">
                      <div className="col-md-12 circletrick description-input position-relative">
                        <Form.Group className="mb-2 display align-items-start">
                          <Form.Label className="text-right w-17 mt-1">
                            Address2
                          </Form.Label>
                          <EasyEdit
                            type={Types.TEXTAREA}
                            value={dataRecord.address2}
                            onSave={(value) => {
                              handleSaveData(value, "address2");
                            }}
                            onValidate={(value) => {
                              if (value) {
                                var splString = value.split(" ");
                                if (value && value.length > 100) {
                                  setAddress2ErrorMsg(
                                    "Allow 100 characters only."
                                  );
                                  return false;
                                } else if (value && value.length < 2) {
                                  setAddress2ErrorMsg(
                                    "Enter at least 2 characters."
                                  );
                                  return false;
                                } else if (
                                  value &&
                                  value.length == 2 &&
                                  value &&
                                  splString.length >= 2
                                ) {
                                  setAddress2ErrorMsg(
                                    "Enter at least 2 characters, space not allow."
                                  );
                                  return false;
                                }
                                return true;
                              } else {
                                return true;
                              }
                            }}
                            validationMessage={address2ErrorMsg}
                            onCancel={cancel}
                            cssClassPrefix="custom-textarea-"
                            saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                            cancelButtonLabel={
                              <FontAwesomeIcon icon={faTimes} />
                            }
                            attributes={{ maxLength: 100 }}
                            placeholder="----"
                            instructions=""
                            allowEdit={isEditable}
                            onFocus={() => handleClick("")}
                          />
                        </Form.Group>
                        <span className="error">
                          {ErrorInput.error_list.address2}
                        </span>
                      </div>
                      <div className="col-md-12 circletrick">
                        <Form.Group className="mb-2 display">
                          <Form.Label className="text-right w-17">
                            State
                          </Form.Label>
                          <EasyEdit
                            type={Types.TEXT}
                            value={dataRecord.state}
                            onSave={(value) => {
                              handleSaveData(value, "state");
                            }}
                            onValidate={(value) => {
                              if (value) {
                                var splString = value.split(" ");
                                if (value && value.length > 50) {
                                  setStateErrorMsg(
                                    "Only 50 characters are allowed"
                                  );
                                  return false;
                                } else if (value && value.length < 2) {
                                  setStateErrorMsg(
                                    "Enter at least 2 characters."
                                  );
                                  return false;
                                } else if (
                                  value &&
                                  value.length == 2 &&
                                  value &&
                                  splString.length >= 2
                                ) {
                                  setStateErrorMsg(
                                    "Enter at least 2 characters, space not allow."
                                  );
                                  return false;
                                }
                                return true;
                              } else {
                                return true;
                              }
                            }}
                            validationMessage={stateErrorMsg}
                            onCancel={cancel}
                            saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                            cancelButtonLabel={
                              <FontAwesomeIcon icon={faTimes} />
                            }
                            attributes={{ maxLength: 50 }}
                            placeholder="----"
                            instructions=""
                            allowEdit={isEditable}
                            onFocus={() => handleClick("")}
                          />
                        </Form.Group>
                        <span className="error">
                          {ErrorInput.error_list.state}
                        </span>
                      </div>

                      <div className="col-md-12 circletrick leadheight">
                        <Form.Group className="display mb-2">
                          <Form.Label className="text-right w-17">
                            Country
                          </Form.Label>
                          <EasyEdit
                            type={Types.TEXT}
                            onSave={(value) => {
                              handleSaveData(value, "country_id");
                            }}
                            onCancel={cancel}
                            saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                            cancelButtonLabel={
                              <FontAwesomeIcon icon={faTimes} />
                            }
                            editComponent={
                              <CustomSelectDisplay
                                data={{
                                  selectedCountry: selectedCountry,
                                  countrysLists: countrysLists,
                                }}
                              />
                            }
                            value={
                              selectedCountry.length > 0
                                ? selectedCountry[0].label
                                : ""
                            }
                            displayComponent={<CustomSelectDisplayCountry />}
                            allowEdit={isEditable}
                            onFocus={() => handleClick("")}
                            placeholder="----"
                          />
                        </Form.Group>
                        <span className="error">
                          {ErrorInput.error_list.country_id}
                        </span>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>

        {dataID ? <ContactDealList data={{ contactId: dataID }} /> : null}

        {/* </Row> */}
      </div>
    );
  }
}

export default EditContact;
