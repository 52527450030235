import React, { useState, memo } from "react";
import Preloader from "./PreloaderCenter";
import ReactECharts from 'echarts-for-react';

function DashboardStacked(props) {
  const { stackChartData } = props;
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false) 
  }, 200);

  var monthLeadCount = [];
  var monthDealCount = [];

  if(stackChartData.leadCount){
      Object.entries(stackChartData.leadCount).forEach(([key, value]) => {
        monthLeadCount[key] = value; 
      });
      monthLeadCount.shift(); 
  }

  if(stackChartData.dealCount){
    Object.entries(stackChartData.dealCount).forEach(([key, value]) => {
      monthDealCount[key] = value; 
    });
    monthDealCount.shift();
  }
  
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        //dataView: { readOnly: false },
        //restore: {},
        saveAsImage: {}
      }
    },
    xAxis: [
      {
        type: 'category',
        data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec']
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Leads',
        type: 'bar',
        stack: 'Ad',
        emphasis: {
          focus: 'series'
        },
        data: monthLeadCount
      },
      {
        name: 'Deals',
        type: 'bar',
        stack: 'Ad',
        emphasis: {
          focus: 'series'
        },
        data: monthDealCount
      },
      // {
      //   name: 'Union Ads',
      //   type: 'bar',
      //   stack: 'Ad',
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [220, 182, 191, 234, 290, 330, 310]
      // },
      // {
      //   name: 'Video Ads',
      //   type: 'bar',
      //   stack: 'Ad',
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [150, 232, 201, 154, 190, 330, 410]
      // },
      // {
      //   name: 'Search Engine',
      //   type: 'bar',
      //   data: [862, 1018, 964, 1026, 1679, 1600, 1570],
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   // markLine: {
      //   //   lineStyle: {
      //   //     type: 'dashed'
      //   //   },
      //   //   data: [[{ type: 'min' }, { type: 'max' }]]
      //   // }
      // },
      // {
      //   name: 'Baidu',
      //   type: 'bar',
      //   barWidth: 5,
      //   stack: 'Search Engine',
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [620, 732, 701, 734, 1090, 1130, 1120]
      // },
      // {
      //   name: 'Google',
      //   type: 'bar',
      //   stack: 'Search Engine',
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [120, 132, 101, 134, 290, 230, 220]
      // },
      // {
      //   name: 'Bing',
      //   type: 'bar',
      //   stack: 'Search Engine',
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [60, 72, 71, 74, 190, 130, 110]
      // },
      // {
      //   name: 'Others',
      //   type: 'bar',
      //   stack: 'Search Engine',
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [62, 82, 91, 84, 109, 110, 120]
      // }
    ]
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    return (

<div className="display flex-wrap w-100 reportborder mt30">
<div className="col-md-12">
  <div className="flex-wrap task align-items-start justify-content-end">
  <div className="innerdealtable w-100">
 <div className="display">
  <div className="col-md-12">
  <h4>Monthly Number of Leads and Deals</h4>
  </div>
 </div>
</div>
    <ReactECharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
      onChartReady={''}
      onEvents={''}
      opts={''}
    />
    </div>
  </div>
  </div>

    )
  }
}

export default memo(DashboardStacked);


