import React, { useEffect, useState, useRef, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from 'react-currency-input-field';

import { faImage, faFileAudio, faPaperclip, faCheck, faFileAlt, faTimes, faFilePdf, faFileCsv, faFileExcel, faFileImage, faFileWord, faFileDownload, faUpload, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Card, Form, Image, FormCheck, Spinner, InputGroup, ButtonGroup, Modal } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import { Link, useHistory, } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';

let selectProduct = [];
var calendorFormate = '';

function CloneDeal(props) {
     const [dealID, setDealID] = useState(props.match.params.id);
     const history = useHistory();
     const loginUser = useSelector(selectUser);

     const user = loginUser?loginUser:[];
     
     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

     const [dealAmount, setDealAmount] = useState([]);
     const [leadSourceArray, setLeadSourceArray] = useState([]);
     const [productArray, setProductArray] = useState([]);
     const [countryArray, setCountryArray] = useState([]);
     const [selectProductArray, setSelectProductArray] = useState([]);
     const [expectedClosingDate, setExpectedClosingDate] = useState(new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone })));

     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
     const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');
     const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

     //=========START:CODE FOR EDIT LEAD DATA==========//
     const [loading, setLoading] = useState(true);
     const [dataRecord, setDataRecord] = useState('');
     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     const [defaultValue, setdefaultValue] = useState({
          deal_name: '',
          organization_name: '',
          organization_email: '',
          first_name: '',
          last_name: '',
          deal_source: '',
          product_id: '',

          deal_amount: '',
          closing_date: '',
          deal_probability: '',
          deal_description: '',

          error_list: [],
     })

     const validationSchema = yup.object().shape({
          deal_name: yup.string().required('Enter deal name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          organization_name: yup.string().required('Enter company name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          organization_email: yup.string().nullable().required('Enter company email').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').email('Please enter valid email address'),
          first_name: yup.string().required('Enter first name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          last_name: yup.string().required('Enter last name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),

          deal_amount: yup.string().required('Enter deal amount'),
          deal_description: yup.string().required('Enter deal description').min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters'),
          deal_probability: yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter deal probability').positive().max('100', "Please enter less than 100%")
     })

     //========Const data for Activity (Lead and Deal)
     var calendorFormate = '';

     useEffect(() => {
          async function fetchData() {
               await getDealData();
          }
          fetchData();
     }, []);


     function getDealData() {
          axios.get('api/deal/edit/' + dealID).then(response => {
               if (response.data.code === 200) {
                    //console.log(response,'response')
                    setDataRecord(response.data.data.record)
                    setLeadSourceArray(response.data.data.LeadSource);
                    setProductArray(response.data.data.products);
                    setSelectProductArray(response.data.data.selected_product)
                    if (response.data.data.record.dealData.expected_closing_date) {
                         setExpectedClosingDate(new Date(response.data.data.record.dealData.expected_closing_date))
                    }

                    setDealAmount(response.data.data.record.dealData.deal_amount)
                    setdefaultValue({
                         deal_name: response.data.data.record.dealData.deal_name,
                         organization_name: response.data.data.record.dealData.AccountData.organization_name,
                         organization_email: response.data.data.record.dealData.AccountData.organization_email,
                         deal_source: response.data.data.record.dealData.deal_source,
                         first_name: response.data.data.record.dealData.ContactData.first_name,
                         last_name: response.data.data.record.dealData.ContactData.last_name,
                         product_id: response.data.data.selected_product,

                         deal_amount: response.data.data.record.dealData.deal_amount,
                         closing_date: response.data.data.record.dealData.closing_date,
                         deal_probability: response.data.data.record.dealData.deal_probability,
                         deal_description: response.data.data.record.dealData.deal_description,

                         error_list: [],
                    })

                    setPreferredCurrencyData(response.data.data.preferredCurrency);
                    setPreferredDateFormatData(response.data.data.preferredDateFormat);

                    calendorFormate = response.data.data.preferredDateFormat.split(' ');
                    if (calendorFormate) {
                         if (calendorFormate[0] == 'YYYY-MMM-DD') {
                              setCalendorDateFormate('yyyy/MM/dd');
                              setCalendorFullDateFormate('yyyy/MM/DD');
                         } else if (calendorFormate[0] == 'DD-MMM-YYYY') {
                              setCalendorDateFormate('dd/MM/yyyy');
                              setCalendorFullDateFormate('DD/MM/yyyy');
                         } else if (calendorFormate[0] == 'YYYY-DD-MMM') {
                              setCalendorDateFormate('yyyy/dd/MM');
                              setCalendorFullDateFormate('yyyy/DD/MM');
                         } else if (calendorFormate[0] == 'MMM-DD-YYYY') {
                              setCalendorDateFormate('MM/dd/yyyy');
                              setCalendorFullDateFormate('MM/DD/yyyy');
                         }
                    }

                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/leads');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/leads');
          });
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }



     const handleSelectProductSave = (e) => {
          selectProduct = Array.isArray(e) ? e.map(x => x.value) : [];
     };



     const handleSubmit = (values, onSubmitProps) => {
          const data = {
               title: values.deal_name,
               first_name: values.first_name,
               last_name: values.last_name,
               organization_name: values.organization_name,
               organization_email: values.organization_email,
               lead_source: values.deal_source,
               product_id: selectProduct.length > 0 ? selectProduct : selectProductArray,
               deal_amount: values.deal_amount,
               deal_probability: values.deal_probability,
               expected_closing_date: expectedClosingDate ? moment(expectedClosingDate).format("YYYY-MM-DD"):'',
               deal_description: values.deal_description,
          }
          //console.log(data)
          axios.post("/api/deal/store", data).then(response => {
               if (response.status === 200) {
                    toast.success('Deal has been created successfully');
                    history.push('/deals');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    onSubmitProps.setSubmitting(false)
                    if (error.response.status === 400) {
                         setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/leads');
                    } else {
                         toast.warning(error.response.data.message);
                    }
               }

          });

     }


     const customStyles1 = {
          menuList: styles => ({
               ...styles,
               // color: 'black',
               // backgroundcolor: '$blue',
               paddingTop: 0,
               paddingBottom: 0,
          }),
     }

     if (loading) {
          return <Preloader show={loading ? false : true} />;
     } else {

          //-----------------For Country list-----------------------------------

          let selectedLeadSource = [];
          leadSourceArray.map((item) => {
               if (dataRecord.dealData.deal_source === item.title) {
                    selectedLeadSource.push({ label: item.title, value: item.title });
               }
          }, this);
          // Object.keys(leadSourceArray).map(function (key) {
          //      if (dataRecord.dealData.deal_source == key) {
          //           selectedLeadSource.push({ label: leadSourceArray[key], value: key });
          //      }
          // }, this);

          let leadSourceList = [];
          leadSourceArray.map((item) => {
               leadSourceList.push({ label: item.title, value: item.title });
          }, this);
          // Object.keys(leadSourceArray).map(function (key) {
          //      leadSourceList.push({ label: leadSourceArray[key], value: key });
          // }, this);

          let productLists = [];
          for (var i = 0, l = productArray.length; i < l; i++) {
               productLists.push({ label: productArray[i].name, value: productArray[i].id });
          }

          let selectedProduct = [];
          for (var i = 0, l = productArray.length; i < l; i++) {
               if (selectProductArray.includes(productArray[i].id)) {
                    selectedProduct.push({ label: productArray[i].name, value: productArray[i].id });
               }

          }

          return (

               // <Row>
               <div className="display flex-wrap">
                    <Col md={12} className="mt30">
                         <div className="display w-100">
                              <Accordion defaultActiveKey="0" className="w-100">
                                   <Accordion.Item eventKey="0">
                                        <Accordion.Header><h4>Create Deal Information</h4></Accordion.Header>
                                        <Accordion.Body>
                                             <Formik
                                                  enableReinitialize={true}
                                                  initialValues={defaultValue}
                                                  validationSchema={validationSchema}
                                                  onSubmit={handleSubmit}
                                             >{props => {
                                                  const {
                                                       values,
                                                       touched,
                                                       errors,
                                                       dirty,
                                                       isSubmitting,
                                                       handleChange,
                                                       handleBlur,
                                                       handleSubmit,
                                                       handleReset,
                                                       setFieldValue,
                                                       setFieldTouched,
                                                  } = props;
                                                  return (
                                                       <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                                                            <div className="display align-items-start flex-wrap">
                                                                 <div className="col-md-6 circletrick">
                                                                      <div className="display align-items-start flex-wrap">
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Deal Name<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="deal_name">
                                                                                               <Form.Control type="text" name="deal_name" value={values.deal_name} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter deal name" className={errors.deal_name && touched.deal_name ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.deal_name}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="deal_name" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Company Name<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="organization_name">
                                                                                               <Form.Control type="text" name="organization_name" value={values.organization_name} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter organization name" className={errors.organization_name && touched.organization_name ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.organization_name}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="organization_name" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Company Email<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="organization_email">
                                                                                               <Form.Control type="text" name="organization_email" value={values.organization_email ? values.organization_email : ''} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter company email" className={errors.organization_email && touched.organization_email ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.organization_email}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="organization_email" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick position-relative leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Deal Source</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="deal_source">
                                                                                               <Select
                                                                                                    defaultValue={selectedLeadSource}
                                                                                                    name="deal_source"
                                                                                                    options={leadSourceList}
                                                                                                    className="basic-multi-select"
                                                                                                    classNamePrefix="select"
                                                                                                    menuPosition="fixed"
                                                                                                    onChange={selectedOption => {
                                                                                                         values.deal_source = selectedOption.value;
                                                                                                         handleChange("deal_source");
                                                                                                    }}
                                                                                                    onBlur={setFieldTouched}
                                                                                                    styles={customStyles1}
                                                                                                    isClearable={false}
                                                                                                    isSearchable={false}
                                                                                                    theme={(theme) => ({
                                                                                                         ...theme,
                                                                                                         borderRadius: 0,
                                                                                                         colors: {
                                                                                                              ...theme.colors,
                                                                                                              primary25: 'var(--primary-color-light)',
                                                                                                              primary: 'var(--primary-color)',
                                                                                                         },
                                                                                                    })}

                                                                                               />
                                                                                               <span className="error">{defaultValue.error_list.deal_source}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="deal_source" />
                                                                                               </span>
                                                                                          </Form.Group>
                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Contact First Name<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="first_name">
                                                                                               <Form.Control type="text" name="first_name" value={values.first_name} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter first name" className={errors.first_name && touched.first_name ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.first_name}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="first_name" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Contact Last Name<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="last_name">
                                                                                               <Form.Control type="text" name="last_name" value={values.last_name} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter last name" className={errors.last_name && touched.last_name ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.last_name}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="last_name" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>

                                                                           <div className="col-md-12 circletrick quoteform circletrick1">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-24 mt8">Product Interested</Form.Label>

                                                                                     {/* <Col sm="6">
                                                                                     <Form.Group id="product_id"> */}
                                                                                     <Select
                                                                                          defaultValue={selectedProduct}
                                                                                          isMulti
                                                                                          name="product_id"
                                                                                          options={productLists}
                                                                                          className="basic-multi-select"
                                                                                          classNamePrefix="select"
                                                                                          // onChange={selectedOption => {
                                                                                          //      values.product_id = selectedOption.value;
                                                                                          //      handleChange("product_id");
                                                                                          // }}
                                                                                          onChange={handleSelectProductSave}
                                                                                          onBlur={setFieldTouched}
                                                                                          styles={customStyles1}
                                                                                          theme={(theme) => ({
                                                                                               ...theme,
                                                                                               borderRadius: 0,
                                                                                               colors: {
                                                                                                    ...theme.colors,
                                                                                                    primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                                                                               },
                                                                                          })}
                                                                                     />
                                                                                     <span className="error">{defaultValue.error_list.product_id}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="product_id" />
                                                                                     </span>
                                                                                     {/* </Form.Group> */}
                                                                                     {/* </Col> */}
                                                                                </Form.Group>

                                                                           </div>




                                                                      </div>
                                                                 </div>
                                                                 <div className="col-md-6 circletrick">
                                                                      <div className="display align-items-start flex-wrap">
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Amount {preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode + ' ' : ' '}
                                                                                          ({preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : ''})<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="deal_amount">
                                                                                               <CurrencyInput
                                                                                                    id="deal_amount"
                                                                                                    name="deal_amount"
                                                                                                    intlConfig={{
                                                                                                         locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                                                                                                         currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                                                                                                    }}
                                                                                                    placeholder="Enter deal amount"
                                                                                                    className={errors.deal_amount && touched.deal_amount ?
                                                                                                         "form-control input-error" : 'form-control'}
                                                                                                    onValueChange={(value, name) => {
                                                                                                         setDealAmount(value);
                                                                                                         values.deal_amount = value;
                                                                                                         handleChange('deal_amount')
                                                                                                    }}
                                                                                                    decimalsLimit={6}
                                                                                                    value={dealAmount}
                                                                                                    step={1}
                                                                                               />
                                                                                               <span className="error">{defaultValue.error_list.deal_amount}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="deal_amount" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Expected Closing Date</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="closing_date">
                                                                                               <DatePicker
                                                                                                    autocomplete="off"
                                                                                                    //minDate={moment().toDate()}
                                                                                                    selected={expectedClosingDate == "" ? new Date() : expectedClosingDate}
                                                                                                    onChange={(date) => setExpectedClosingDate(date)}
                                                                                                    //showTimeSelect
                                                                                                    //timeFormat="HH:mm"
                                                                                                    //timeIntervals={5}
                                                                                                    //timeCaption="time"
                                                                                                    dateFormat={calendorDateFormate}
                                                                                                    className="form-control"
                                                                                               />
                                                                                               <span className="error">{defaultValue.error_list.expected_closing_date}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="expected_closing_date" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Probability(%)<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="deal_probability">
                                                                                               <Form.Control type="number" max={100} step={0.01} value={values.deal_probability} name="deal_probability" onChange={handleChange} onBlur={handleBlur} placeholder="Enter deal probability" className={errors.deal_probability && touched.deal_probability ?
                                                                                                    "proability errormessage input-error" : 'proability errormessage'} />
                                                                                               <span className="error">{defaultValue.error_list.deal_probability}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="deal_probability" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>

                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Description<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="deal_description">
                                                                                               <Form.Control as="textarea" name="deal_description" value={values.deal_description} minLength={2} maxLength={150} onChange={handleChange} onBlur={handleBlur} placeholder="Enter deal description" className={errors.deal_description && touched.deal_description ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.deal_description}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="deal_description" />
                                                                                               </span>
                                                                                          </Form.Group>
                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>

                                                                      </div>
                                                                 </div>



                                                            </div>



                                                            <div className="display align-items-center">
                                                                 <div className="text-center mt30">
                                                                      {
                                                                           isSubmitting ?

                                                                                <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                                                                                     <Spinner animation="border" size="sm" />
                                                                                     <span className="ms-2 text-center">Please wait...</span>
                                                                                </Button>
                                                                                :

                                                                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                                                     <div className="btn-group mr-2" role="group" aria-label="First group">
                                                                                          <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                                                                                     </div>
                                                                                </div>
                                                                      }
                                                                 </div>
                                                            </div>


                                                       </Form>
                                                  );
                                             }}
                                             </Formik>
                                        </Accordion.Body>
                                   </Accordion.Item>
                              </Accordion>
                         </div>
                    </Col>
               </div>

          );

     }


};

export default CloneDeal;