/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import Preloader from "../components/Preloader";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import Pagination from "react-js-pagination";
import "react-edit-text/dist/index.css";
import "../assets/css/custom.css";

import { useHistory } from "react-router-dom";
import axios from "axios";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var currencyCode = "";
var currencySymbol = "";
var contactId = "";

export default (props) => {
  contactId = props.data.contactId;
  const history = useHistory();
  const loginUser = useSelector(selectUser);
  const [isEditable, setIsEditable] = useState(true);

  var user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [loadingDeal, setLoadingDeal] = useState(true);
  const [listDeal, setDeal] = useState([]);

  const [calendorDealDateFormate, setCalendorDealDateFormate] =
    useState("yyyy/MM/dd");

  const [preferredDealCurrencyData, setPreferredDealCurrencyData] = useState(
    []
  );
  const [preferredDealDateFormatData, setPreferredDealDateFormatData] =
    useState("");

  const [calendorDealFullDateFormate, setCalendorDealFullDateFormate] =
    useState();

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const SORT_ASC = "asc";
  const SORT_DESC = "desc";
  const [sortColumn, setSortColumn] = useState();
  sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC;

  //=========END:CODE FOR FOOTER PAGINATION===========//

  var dealCalendorFormate = "";

  useEffect(() => {
    async function fetchData() {
      await getDealData();
    }
    fetchData();
  }, []);

  const getDealData = async (currentPage) => {
    const params = {
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
      contactId: contactId,
    };
    setLoadingDeal(true);
    await axios
      .get(`api/deal?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setDeal(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });

          setPreferredDealCurrencyData(
            response.data.data.preferredCurrencyData
          );
          setPreferredDealDateFormatData(response.data.data.preDateFormat);

          if (response.data.data.preferredCurrencyData.currencyCode) {
            currencyCode = response.data.data.preferredCurrencyData.currencyCode
              ? response.data.data.preferredCurrencyData.currencyCode
              : "";
            currencySymbol = response.data.data.preferredCurrencyData
              .currencySymbol
              ? response.data.data.preferredCurrencyData.currencySymbol
              : "";
          }

          dealCalendorFormate = response.data.data.preDateFormat.split(" ");
          if (dealCalendorFormate) {
            if (dealCalendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDealDateFormate("yyyy/MM/dd");
              setCalendorDealFullDateFormate("yyyy-MMM-DD");
            } else if (dealCalendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDealDateFormate("dd/MM/yyyy");
              setCalendorDealFullDateFormate("DD-MMM-yyyy");
            } else if (dealCalendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDealDateFormate("yyyy/dd/MM");
              setCalendorDealFullDateFormate("yyyy-DD-MMM");
            } else if (dealCalendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDealDateFormate("MM/dd/yyyy");
              setCalendorDealFullDateFormate("MMM-DD-yyyy");
            }
            setCalendorDealFullDateFormate(dealCalendorFormate[0]);
          }
          setLoadingDeal(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoadingDeal(false);
          if (error.response.status === 400 || error.response.status === 401) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden" + error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          }
        }
      });
  };

  var displayUserData = "";
  var ScrollbarsHeight = 150;
  if (loadingDeal) {
    return <Preloader show={loadingDeal ? false : true} />;
  } else {
    displayUserData = listDeal.map((item, i) => {
      let fNameDeal = item.get_contact ? item.get_contact.first_name : "N/A";
      let lNameDeal = item.get_contact ? item.get_contact.last_name : "N/A";

      let FFNameDeal = fNameDeal.charAt(0) + lNameDeal.charAt(0);

      let displayProductName = "";
      if (item.selected_product) {
        if (item.selected_product.length > 3) {
          for (var i = 0; i < 3; i++) {
            displayProductName += item.selected_product[i].name + ", ";
          }
        } else {
          for (var i = 0, l = item.selected_product.length; i < l; i++) {
            displayProductName += item.selected_product[i].name + ", ";
          }
        }
      }

      let num = parseInt(item.deal_amount);
      const myObj = {
        style: "currency",
        currency: currencyCode,
      };

      let dealName = "N/A";
      if (item.deal_name) {
        dealName = item.deal_name;
      } else {
        if (item.get_contact && item.get_contact.title) {
          dealName = item.get_contact.title;
        }
      }

      return (
        <tr key={i}>
          <td className="dealsname">
            <span
              onClick={() => history.push("/edit-deal/" + item.id)}
              style={{ cursor: "pointer" }}
            >
              {dealName}
            </span>
          </td>
          <td>
            {num
              ? preferredDealCurrencyData.currencyStringValue
                ? num.toLocaleString(
                    preferredDealCurrencyData.currencyStringValue,
                    myObj
                  )
                : currencySymbol + num.toLocaleString(myObj)
              : "N/A"}
          </td>
          <td>
            {item.selected_stage_name
              ? item.selected_stage_name.name
                ? item.selected_stage_name.name
                : "N/A"
              : "N/A"}
          </td>
          <td>{item.deal_probability ? item.deal_probability : "N/A"}</td>
          <td>
            {item.expected_closing_date
              ? moment
                  .tz(item.expected_closing_date, defaultTimeZone)
                  .format(calendorDealFullDateFormate)
              : "N/A"}
          </td>
          <td>
            {item.closing_date
              ? moment
                  .tz(item.closing_date, defaultTimeZone)
                  .format(calendorDealFullDateFormate)
              : "N/A"}
          </td>
          <td>
            {moment
              .tz(item.created_at, defaultTimeZone)
              .format(preferredDealDateFormatData)}
          </td>
        </tr>
      );
    });

    if (listDeal && listDeal.length > 2) {
      ScrollbarsHeight = 300;
    }

    return (
      <Col md={12} className="mt30">
        <div className="display flex-wrap w-100 history">
          <div className="innerdealtable w-100">
            <div className="col-md-12">
              <h5 className="tables_title">Deals</h5>
            </div>
          </div>
          {displayUserData.length ? (
            <>
              {/* <Scrollbars style={{ height: ScrollbarsHeight }} className="scrollbar-bx"> */}
              <div className="contanctlistnig">
                <table>
                  <tbody>
                    <tr>
                      <th style={{ width: " 20%" }}>Deal Name</th>
                      <th style={{ width: " 15%" }}>Amount</th>
                      <th style={{ width: " 15%" }}>Stage</th>
                      <th style={{ width: " 5%" }}>Probability (%)</th>
                      <th style={{ width: " 10%" }}>Expected Closing Date</th>
                      <th style={{ width: " 10%" }}>Closing Date</th>
                      <th style={{ width: " 15%" }}>Created Date</th>
                    </tr>
                    {displayUserData}
                  </tbody>
                </table>
              </div>
              {/* </Scrollbars> */}

              {displayUserData.length >= 1 ? (
                <Col xs={12} xl={12} className="showing">
                  <Card border="light" className="bg-white border-0">
                    <Card.Body className="listcard">
                      <Row className="align-items-center">
                        <Col md={6} className="ps-2">
                          <h6>
                            Showing{" "}
                            <b>
                              {(state?.users?.per_page
                                ? state?.users?.per_page
                                : 0) *
                                (state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0) -
                                ((state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) -
                                  1)}
                            </b>{" "}
                            -{" "}
                            <b>
                              {(state?.users?.per_page
                                ? state?.users?.per_page
                                : 0) *
                                (state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0) -
                                ((state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) -
                                  1) +
                                ((dataCount ? dataCount : 0) - 1)}
                            </b>{" "}
                            of{" "}
                            <b>
                              {state?.users?.total ? state?.users?.total : 0}
                            </b>
                          </h6>
                        </Col>

                        <Col md={6} className="d-flex justify-content-end">
                          <Pagination
                            activePage={
                              state?.users?.current_page
                                ? state?.users?.current_page
                                : 0
                            }
                            itemsCountPerPage={
                              state?.users?.per_page
                                ? state?.users?.per_page
                                : 0
                            }
                            totalItemsCount={
                              state?.users?.total ? state?.users?.total : 0
                            }
                            onChange={(pageNumber) => {
                              currentPage = pageNumber;
                              getDealData(pageNumber);
                            }}
                            pageRangeDisplayed={5}
                            itemClass="page-item mx-1"
                            linkClass="page-link"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </>
          ) : (
            <span>No data Available</span>
          )}
        </div>
      </Col>
    );
  }
};
