import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button, Container, Spinner, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import PreloaderCenter from "../../components/PreloaderCenter";
import Cookies from 'universal-cookie';
import {  toast } from 'react-toastify';
import { login, LoginPermission, Workspace, DisplayHostName } from "../../features/userSlice";
import { useDispatch,useSelector } from "react-redux";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { Routes } from "../../routes";
import axios from 'axios';

function Signin() {

  let logoUrl = ''
  const cookies = new Cookies();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [message, setMessage] = useState('');
  const [backMessage, setBackMessage] = useState('');
  const [backPassMessage, setBackPassMessage] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState();

  //get default host data from redux using url
  var defaultHostData = useSelector(DisplayHostName);
  var hostname = defaultHostData?.hostname||''
  var displayHost = defaultHostData?.displayHost||''
  
  if(displayHost){
    logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/`+displayHost+`.png`;
  }
  
  const checkFileExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if(response.status === 200){
       logoUrl = logoUrl;
      }
    } catch (error) {
      logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/app.png`; 
    }
  }
  
  useEffect(() => {
    async function fetchData() {
      await checkFileExistence(logoUrl);
    }
    fetchData();

  },[displayHost]);

  
  
  
  
  
  //======START::Temp login set for buzhub domain=======//
  useEffect(() => {
    //https://bizhub.xl.financial/sign-in?from=bizhub
    const queryParams = new URLSearchParams(window.location.search);
    const fromParam = queryParams.get('from');
  
    if (fromParam && fromParam === 'bizhub') {
      const data = {
        email: 'dilan.patel@xl.financial',
        password: 'Dilan@1234',
      };
  
      setVerificationEmail('dilan.patel@xl.financial');
      setBackMessage();
      setBackPassMessage();
      setLoaded(true)
      axios.post("/api/login", data)
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.user.email_verified_at == null) {
              setMessage('');
              toast.warning('Please verify your email.');
              history.push('/resend-email-password');
            } else {
              cookies.set('auth_token', response.data.data.access_token, { domain: hostname });
              cookies.set('auth_name', response.data.data.user.name, { domain: hostname });
              cookies.set('auth_tenant', response.data.data.user.tenant, { domain: hostname });
    
              let subdomain = response.data.data.currentTenant.domain;
              if (subdomain) {
                let host = window.location.host;
                let protocol = window.location.protocol;
                let parts = host.split(".");
    
                if (parts.length >= 3) {
                  parts.splice(0, 1);
                  window.location = protocol + "//" + subdomain + '.' + parts.join(".") + "/dashboard";
                }
              } else {
                // Encrypt
                if (response.data.data.user) {
                  dispatch(
                    login(response.data.data.user),
                  );
                } else {
                  dispatch(
                    login([]),
                  );
                }
    
                if (response.data.data.currentTenant) {
                  dispatch(
                    Workspace(response.data.data.currentTenant),
                  );
                } else {
                  dispatch(
                    Workspace([]),
                  );
                }
    
                if (response.data.data.permissionArray) {
                  dispatch(
                    LoginPermission(response.data.data.permissionArray)
                  );
                } else {
                  dispatch(
                    LoginPermission([])
                  );
                }
                history.push('/dashboard')
              }
    
              }
          }
          
        })
        .then(json => {
          toast.success(json.data.data.message);
          history.push('/dashboard');
        })
        .catch(error => {
          setMessage('');
          if (error.response) {
            if ([401, 400].includes(error.response.status)) {
              setBackPassMessage(error.response.data.message);
            } else if (error.response.status === 300) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 422) {
              setdefaultValue({ ...defaultValue, error_list: error.response.data.message });
            } else if (error.response.status === 500) {
              toast.error('Internal Server Error');
            } else {
              toast.error(error.response.data.message);
            }
          }
        });
    }
  }, [displayHost]);
  
  //======END::Temp login set for buzhub domain=======//

  






  
  const [defaultValue, setdefaultValue] = useState({
    email: '',
    password: '',
    error_list: [],
  })

  const validationSchema = yup.object().shape({
    email:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter your email address').email('Please enter a valid email address'),
    password:yup.string().required('Enter your password')
  })

  const handleSubmit = async (values,onSubmitProps) =>{
    const data = {
      email: values.email,
      password: values.password,
    }
    setVerificationEmail(values.email)
    setBackMessage()
    setBackPassMessage()
    await axios.post("/api/login", data).then(response => {
      if (response.status === 200) {
        if (response.data.data.user.email_verified_at == null) {
          setMessage('');
          toast.warning('Please verify your email.');
          history.push('/resend-email-password');
        } else {
          cookies.set('auth_token', response.data.data.access_token, { domain: hostname });
          cookies.set('auth_name', response.data.data.user.name, { domain: hostname });
          cookies.set('auth_tenant', response.data.data.user.tenant, { domain: hostname });

          let subdomain = response.data.data.currentTenant.domain;
          if (subdomain) {
            let host = window.location.host;
            let protocol = window.location.protocol;
            let parts = host.split(".");

            if (parts.length >= 3) {
              parts.splice(0, 1);
              window.location = protocol + "//" + subdomain + '.' + parts.join(".") + "/dashboard";
            }
          } else {
            // Encrypt
            if (response.data.data.user) {
              dispatch(
                login(response.data.data.user),
              );
            } else {
              dispatch(
                login([]),
              );
            }

            if (response.data.data.currentTenant) {
              dispatch(
                Workspace(response.data.data.currentTenant),
              );
            } else {
              dispatch(
                Workspace([]),
              );
            }

            if (response.data.data.permissionArray) {
              dispatch(
                LoginPermission(response.data.data.permissionArray)
              );
            } else {
              dispatch(
                LoginPermission([])
              );
            }
            //window.location.href = "/dashboard"
            history.push('/dashboard')
          }

          }
      }

    }).then(json => {
      toast.success(json.data.data.message);
      history.push('/dashboard');
    }).catch(error => {
      setMessage('');
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 401 || error.response.status === 400) {
          if(error.response.data.message === 'Your password does not match, please try with another.'){
            setBackPassMessage(error.response.data.message)
          }else{
            setBackMessage(error.response.data.message)
          }
        } else if(error.response.status === 300) {
          toast.warning(error.response.data.message);
        }else if(error.response.status === 422) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.message });
        }else if(error.response.status === 500) {
          toast.error('Internal Server Error');
        }else {
          toast.error(error.response.data.message);
        }
      }
    });
  }
  
  async function reSendVerificationEmail(email){
    setMessage('Please wait we are sending verification email.')
    setBackMessage()
    setBackPassMessage()
    const data = {
      email: email,
    }
    setLoaded(true)
    await axios.post("/api/resend-email-verification", data).then(response => {
      if (response.status === 200) {
        toast.success(response.data.message); 
      }
      setLoaded(false)
      setMessage('')
      setdefaultValue({ email:'', password:'',error_list: [] });
      //window.location.reload(true);
      setTimeout(()=>{
        window.location.reload(true);
      }, 1000);
    }).then(json => {
      toast.success(json.data.data.message);
    }).catch(error => {
      if (error.response) {
        setLoaded(false)
        setBackMessage()
        setBackPassMessage()
        setMessage('')
        if (error.response.status === 401 || error.response.status === 400 || error.response.status === 300) {
          toast.warning(error.response.data.message);
        }else if(error.response.status === 422) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.errors });
        }else {
          toast.error(error.response.data.message);
        }
      }
    });

  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };


  return (
      <>
        <PreloaderCenter show={loaded ? true : false} message={message} />
        <main>
          <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 common-form">
            <Container>
              <div className="title text-center mb40">
                <a href={process.env.REACT_APP_MARKETING_WEB_URL}><img src={logoUrl} alt="signlogo" className="signlogo" style={{ cursor: 'pointer' }}/></a>
              </div>
              <Row className="justify-content-center form-bg-image" style={{display:loaded?'none':'block'}}>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white w-100 box-form">
                    <div className="text-center text-md-center mt-md-0">
                      <h3 className="mb-0">Sign In</h3>
                    </div>
  
                    <Formik 
                      initialValues={defaultValue} 
                      validationSchema={validationSchema} 
                      onSubmit={handleSubmit}
                    >
                    {props => {
                          const {
                            //values,
                            touched,
                            errors,
                            //dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                           // handleReset
                          } = props;
                          return (
                    <Form className="position-relative"  onSubmit={handleSubmit}>
                      <Form.Group id="email" className="mt40 even">
                        <InputGroup>
                        <Form.Control 
                            type="email" 
                            name="email" 
                            minLength={2} 
                            maxLength={50}
                            onChange={e => {
                                handleChange(e);
                                setdefaultValue({ ...defaultValue, error_list: ''});
                                setBackMessage('');
                                setBackPassMessage('')
                              }
                            } 
                            onBlur={handleBlur} 
                            placeholder="Email" 
                            className={errors.email && touched.email ? "input-error" : null} 
                        />
                        </InputGroup>
                        {
                          defaultValue.error_list.email ? 
                          <p className="text-danger error3">{defaultValue.error_list.email}</p>
                          :
                          ''
                        }
                        
                        {
                          backMessage ? 
                          <p className="text-danger error error3">{backMessage} 
                            {
                              backMessage === 'Your email is not verified yet, Please verify it and try again.' ?
                              <p class="error error3">Click <b onClick={() => reSendVerificationEmail(verificationEmail)} style={{cursor:'pointer'}}>here</b> to resend verification email again.</p>
                              :
                              ''
                            }
                          </p>
                          :
                          ''
                        }
                        <p className="text-danger error3">
                          <ErrorMessage name="email"/>
                        </p>
                      </Form.Group>

                      <Form.Group>
                        <Form.Group id="password" className="mt40 mb30 even">
                          <InputGroup>
                          <Form.Control 
                            type={passwordShown ? "text" : "password"} 
                            name="password" 
                            onChange={e => {
                              handleChange(e);
                              setdefaultValue({ ...defaultValue, error_list: ''});
                              setBackMessage('');
                              setBackPassMessage('')
                              }
                            } 
                            onBlur={handleBlur}  
                            placeholder="Password" 
                            className={errors.password && touched.password ? "input-error" : null} 
                          />
                      <span onClick={togglePassword} className={passwordShown ? "password_icon" : "signin_icon"}></span> 
                          </InputGroup>
                          <p className="error">{defaultValue.error_list.password}</p>
                          
                          {
                            backPassMessage ? 
                            <p className="text-danger error3 dd">{backPassMessage}</p>
                            :
                            ''
                          }
                          <p className="text-danger error3">
                            <ErrorMessage name="password"/>
                          </p>
                        </Form.Group>
                        <div className="text-center align-items-center mb40 w-100">
                          <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end">Forgot Password?</Card.Link>
                        </div>
                      </Form.Group>
                      {
                          isSubmitting ? 
                          <Button variant="primary" className="d-flex align-items-center justify-content-center text-center mb-1 w-100">
                                <Spinner animation="border" size="sm" />
                                <span className="ms-2 text-center">Please Wait...</span>
                          </Button>
                          :
                          <Button variant="primary" type="submit" className="w-100">
                              Sign in
                          </Button>
                      }
                    </Form>
                    );
                  }}
                    </Formik>
                  </div>
                </Col>
                <div className="d-flex justify-content-center align-items-center mt-4 footer-form">
                  <span>
                    New User?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create an Account `}
                    </Card.Link>
                  </span>
                </div>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  //}
};

export default Signin;