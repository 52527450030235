import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Card,
  Form,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/PreloaderCenter";
import { toast } from "react-toastify";
import validator from "validator";
import { Routes } from "../../routes";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Checkbox from "react-custom-checkbox";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import ExportData from "../../components/ExportData";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var searchData = "";
var searchInputDataName = "";
var searchInputDataEmail = "";
var searchInputDataFromDate = "";
var searchInputDataToDate = "";
var searchInputDataTitle = "";
var searchInputDataStatus = "";
var searchInputDataPhone = "";
var searchInputDataOrganizationName = "";

function UserList(props) {
  const location = props.history.location;
  const loginUser = useSelector(selectUser);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);

  const [isCheck, setIsCheck] = useState([]);
  const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
  const [mainChecked, setMainChecked] = useState(false);
  const [memberShipDetail, setMemberShipDetail] = useState(false);

  const user = loginUser?loginUser:[];

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [search, setSearch] = useState("");
  const [verifiedArray, setVerifiedArray] = useState("");

  const [showUserDefault, setShowUserDefault] = useState(false);
  const handleUserClose = () => setShowUserDefault(false);
  const [roleData, setRoleData] = useState([]);

  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
  const [searchString, setSearchString] = useState();

  if (props.location.pathname == "/lead") {
    if (props.location.statess) {
      searchData = props.location.statess.search;
    }

    if (props.location.searchData) {
      searchInputDataName = props.location.searchData.search_name;
      searchInputDataEmail = props.location.searchData.search_email;
      searchInputDataFromDate = props.location.searchData.search_from_date;
      searchInputDataToDate = props.location.searchData.search_to_date;
      searchInputDataTitle = props.location.searchData.search_title;
      searchInputDataStatus = props.location.searchData.search_status;
      searchInputDataPhone = props.location.searchData.search_phone;
      searchInputDataOrganizationName =
        props.location.searchData.search_organization_name;
    }
  }

  //==============START: Handle new search filter option==================//
  const handleSearchInput = (e) => {
    setSearchString(e.target.value);
  };

  const manageSearch = (e) => {
    e.preventDefault();
    setSearch(searchString);
  };

  const handleSelectIsVeriFied = (event) => {
    setVerifiedArray(event.value);
  };

  const [defaultValue, setdefaultValue] = useState({
    email: "",
    role: "",
    error_list: [],
  });

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email address")
      .required("Enter email address"),
    role: yup.string().required("Please select role"),
  });

  //==============END: Handle new search filter option==================//

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const columns = ["name", "email", "phone"];

  const SORT_ASC = "asc";
  const SORT_DESC = "desc";
  const [sortColumn, setSortColumn] = useState();

  const handleSort = (column) => {
    if (column === sortColumn) {
      sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC;
      getData();
    } else {
      setSortColumn(column);
      sortOrder = SORT_DESC;
    }
  };

  const handlePerPage = (Page) => {
    setLoading(true);
    //setCurrentPage(1)
    currentPage = 1;
    perPage = Page;
    getData();
  };
  //=========END:CODE FOR FOOTER PAGINATION===========//

  var calendorFormate = "";

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getData();
    }
    fetchData();
  }, [
    sortColumn,
    sortOrder,
    search,
    verifiedArray,
    location,
    searchInputDataName,
    searchInputDataEmail,
    searchInputDataFromDate,
    searchInputDataToDate,
    searchInputDataTitle,
    searchInputDataStatus,
    searchInputDataPhone,
    searchInputDataOrganizationName,
  ]);

  const getData = async (currentPage) => {
    const params = {
      search: search,
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_email_verified_at: verifiedArray,
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    };

    await axios
      .get(`api/user?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setListData(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });
          setRoleData(response.data.data.roles);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);
          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
            }
          }

          //check user member detail
          calculateSubUserData(
            response.data.data.memberShipDetail.membershipType,
            response.data.data.memberShipDetail.addedUserCount,
            response.data.data.memberShipDetail.planUserCount
          );
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/dashboard");
          } else if (
            error.response.status === 400 ||
            error.response.status === 401
          ) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
            history.push("/dashboard");
          }
        }
      });
  };

  function calculateSubUserData(type, addedUser, limitUser) {
    if (type == "free") {
      setMemberShipDetail(true);
    } else {
      if (addedUser < limitUser) {
        setMemberShipDetail(true);
      } else {
        setMemberShipDetail(false);
      }
    }
  }

  function deleteData(id, type) {
    swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios
          .delete("api/user/destroy/" + id + "?type=" + type)
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              calculateSubUserData(
                response.data.data.memberShipDetail.membershipType,
                response.data.data.memberShipDetail.addedUserCount,
                response.data.data.memberShipDetail.planUserCount
              );
              getData(currentPage);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error(error.response.data.message);
              }
            }
          });
      }
    });
  }

  function bulkDeleteData(ids) {
    const data = {
      id: ids,
    };

    swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios
          .delete("api/user/bulk-destroy", { data })
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              calculateSubUserData(
                response.data.data.memberShipDetail.membershipType,
                response.data.data.memberShipDetail.addedUserCount,
                response.data.data.memberShipDetail.planUserCount
              );
              getData(currentPage);

              handleCheckAll(false);
              setIsCheckBoxCheck("");
              setIsCheck([]);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error(error.response.data.message);
              }
            }
          });
      }
    });
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  //=============START:WRITE CODE FOR INVITE NEW USER=================//
  const [message, setMessage] = useState("");
  const [submitEvent, setSubmitEvent] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const [inputData, setInputData] = useState({
    email: "",
    role: "",
    error_list: [],
  });

  const emailRef = useRef();
  const roleRef = useRef();

  const [emailError, setEmailError] = useState(false);
  const [formEmailError, setFormEmailError] = useState(false);

  const [roleError, setRoleError] = useState(true);
  const [formRoleError, setFormRoleError] = useState(false);

  const handleInput = (e) => {
    e.persist();

    let names = e.target.name;
    let values = e.target.value;

    validateInvitationFormData(names, values);
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSelectSave = (e) => {
    validateInvitationFormData("role", e.value);
    setInputData({ ...inputData, ["role"]: e.value });
  };

  function validateInvitationFormData(type, value) {
    if (type == "email" && validator.isEmail(value)) {
      setFormEmailError(false);
      setEmailError(false);
      setInputData((inputData.error_list.email = ""));
    } else {
      setFormEmailError(false);
      if (type == "email" && value == "") {
        setEmailError(true);
        setInputData((inputData.error_list.email = "This field is required"));
      } else if (type == "email") {
        setEmailError(true);
        setInputData(
          (inputData.error_list.email = "Enter a valid e-mail address")
        );
      }
    }

    if (type == "role" && value == "") {
      setFormRoleError(false);
      setRoleError(true);
      setInputData((inputData.error_list.role = "This field is required"));
    } else {
      setFormRoleError(false);
      if (type == "role" && value != "") {
        setRoleError(false);
        setInputData((inputData.error_list.role = ""));
      }
    }
  }

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      email: values.email,
      role_id: values.role,
      type: "send",
    };

    setLoading(true);

    await axios
      .post("/api/user/invitation/store", data)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setShowUserDefault(false);
          toast.success("Invitation has been send successfully");
          setSubmitEvent(false);
          getData(currentPage);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          onSubmitProps.setSubmitting(false);
          setSubmitEvent(false);
          setLoading(false);
          setMessage("");
          if (error.response.status === 400) {
            setInputData({
              ...inputData,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.warning(error.response.data.message);
            setShowUserDefault(false);
          }
        }
      });
  };

  const reSendInvitation = async (id, email, roleId) => {
    const data = {
      email: email,
      role_id: roleId,
      type: "resend",
      id: id,
    };

    setLoading(true);
    await axios
      .post("/api/user/invitation/store", data)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success("Invitation has been send successfully");
          getData(currentPage);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };

  function openInviteUserModel() {
    setInputData({ email: "", role: "", error_list: [] });
    setShowUserDefault(true);
  }

  //=============START:WRITE CODE FOR INVITE NEW USER=================//

  //==== select_mate
  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  //Export data file for logic
  const expParams = {
    search: searchData,
    search_name: searchInputDataName,
    search_email: searchInputDataEmail,
    search_from_date: searchInputDataFromDate,
    search_to_date: searchInputDataToDate,
    search_title: searchInputDataTitle,
    search_status: searchInputDataStatus,
    search_phone: searchInputDataPhone,
    search_organization_name: searchInputDataOrganizationName,
    sort_field: sortColumn,
    sort_order: sortOrder,
    per_page: perPage,
    page: currentPage,
    selectedIds: isCheck?isCheck:[],
  };


 

  const handleBulkDelete = () => {
    if (isCheck && isCheck.length > 0) {
      bulkDeleteData(isCheck);
    } else {
      toast.warning("Please select at least one record for delete.");
    }
  };

  //============Start:- Handle check all ================//
  const handleCheckbox = (value, id) => {
    if (value) {
      setIsCheck((isCheck) => [...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((x) => x !== id));
    }
  };

  const handleCheckAll = (value) => {
    if (value) {
      setMainChecked(true);
    } else {
      setMainChecked(false);
    }

    setIsCheckBoxCheck(value);
    setIsCheck([]);

    if (value) {
      setIsCheckBoxCheck(value);
      setIsCheck(listData.map((li) => li.id));
    }
  };
  //============End:- Handle check all ================//


  //==============For Lead Status Filter
  let isVerifiedList = [
    { label: "Email Verified All", value: "" },
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];

  let defaultVerified = [];
  isVerifiedList.map((Verified) => {
    if (verifiedArray == Verified.value) {
      defaultVerified.push({ label: Verified.label, value: Verified.value });
    }
  }, this);

  let roleList = [];
  for (var i = 0, l = roleData.length; i < l; i++) {
    roleList.push({ label: roleData[i].name, value: roleData[i].id });
  }

  //==============For Lead Source Filter

  var displayUserData = "";
  
  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayUserData = listData.map((item, i) => {
      let sortName = "";
      let expName = "";

      if (item.type == "user") {
        sortName = item.name;
        expName = item.name.split(" ");
      } else {
        sortName = item.email;
        expName = item.email.split("@");
      }

      if (expName.length == 2) {
        sortName = expName[0].charAt(0) + expName[1].charAt(0);
      } else {
        sortName = expName[0].charAt(0);
      }

      return (
        <div className="list_view bordertop pt16 pb18" key={i}>
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="user[]"
                checked={isCheckBoxCheck}
                value={item.id}
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckbox(value, item.id);
                }}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{
                  marginLeft: 5,
                  userSelect: "none",
                  "font-weight": "normal",
                }}
                label=""
              />
            </label>
          </div>

          {/*--- image_and_name_section ---*/}
          <div className="listdetails">
            <Stack direction="row" spacing={3}>
              <Avatar
                alt={item.name ? item.name : "N/A"}
                src={`${process.env.REACT_APP_API_URL}storage/${item.profile_pic}`}
                sx={{ width: 76, height: 76 }}
                onError={addDefaultSrc}
                className="listimg"
              >
                {sortName}
              </Avatar>
            </Stack>

            {/*---details_section ---*/}
            <div className="leaddetails">
              {item.type == "user" ? (
                userPermission ? (
                  userPermission.includes("user_edit") ? (
                    <h5
                      onClick={() => history.push("/edit-user/" + item.id)}
                      style={{ cursor: "pointer", textTransform: "capitalize" }}
                    >
                      {" "}
                      {item.name ? item.name : "N/A"}
                    </h5>
                  ) : (
                    <h5 style={{ textTransform: "capitalize" }}>
                      {" "}
                      {item.name ? item.name : "N/A"}
                    </h5>
                  )
                ) : (
                  <h5 style={{ textTransform: "capitalize" }}>
                    {" "}
                    {item.name ? item.name : "N/A"}
                  </h5>
                )
              ) : (
                <>
                  <h5 title="Invitation sent, pending acceptance">
                    <span style={{ textTransform: "capitalize" }}>
                      {expName.length >= 2 ? expName[0] : "Invited user"}
                    </span>
                    <span
                      style={{
                        color: "#FA6C6C",
                        paddingLeft: "10px",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      - Invitation sent, pending acceptance.
                    </span>
                  </h5>
                </>
              )}
              {/*--- lead_inner1_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Email:</span>
                    <h6>{item.email ? item.email : "N/A"}</h6>
                  </div>
                  {/*--- lead_company_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Phone:</span>
                    <h6>{item.phone1 ? item.phone1 : "N/A"}</h6>
                  </div>
                </div>
              </div>

              {/*--- lead_inner2_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_date_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Created:</span>
                    <h6>
                      {moment
                        .tz(item.created_at, defaultTimeZone)
                        .format(preferredDateFormatData)}
                    </h6>
                  </div>
                  {/*--- lead_phone_section */}
                  {/*--- lead_cold_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Email Verified:</span>
                    <h6>{item.email_verified_at ? "Yes" : "No"}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*--- owner_section ---*/}
          <div className="listowner">
            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>{item.selected_role ? item.selected_role : "N/A"}</span>
                </div>
              </div>
            </div>

            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>Last Updated:</span>
                  <h6>
                    {moment
                      .tz(item.updated_at, defaultTimeZone)
                      .format(preferredDateFormatData)}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/*--- action_section ---*/}
          <div className="listaction text-right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="listdot"></span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {userPermission ? (
                  userPermission.includes("user_edit") ? (
                    item.type == "user" ? (
                      <Dropdown.Item
                        onClick={() => history.push("/edit-user/" + item.id)}
                      >
                        Edit
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() =>
                          reSendInvitation(item.id, item.email, item.role_id)
                        }
                      >
                        Resend invitation
                      </Dropdown.Item>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userPermission ? (
                  userPermission.includes("user_delete") ? (
                    item.type == "user" ? (
                      <Dropdown.Item
                        onClick={() => {
                          deleteData(item.user_tenant_relation.id, "user");
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          deleteData(item.id, "invited");
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="searchbar mt30">
          <div className="col-md-4">
            {/* <form>
              <input type="text" className="form-control" placeholder="Search this list...." value={searchText} onChange={handleSearch} ></input><span className="listicon"></span>
            </form> */}
            <form onSubmit={manageSearch}>
              <input
                type="text"
                name="searchData"
                className="form-control search"
                value={searchString}
                placeholder="Search this list...."
                onChange={handleSearchInput}
              ></input>
              <span className="listicon" onClick={manageSearch}></span>
            </form>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-7 display justify-content-end">
            <div className="dropdown-section display">
              {userPermission ? (
                userPermission.includes("user_create") ? (
                  memberShipDetail ? (
                    <Dropdown className="mr15">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="createbtn btnbluecolor"
                        onClick={() => openInviteUserModel()}
                      >
                        Create New<span className="createicon"></span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  ) : (
                    <Dropdown className="mr15" style={{ color: "red" }}>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="createbtn btnbluecolor"
                        onClick={() => history.push(Routes.Checkout.path)}
                        title="You have reach out your user adding limit, Upgrade your plan to add more user"
                      >
                        <span>Limit over</span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <Select
                defaultValue={defaultVerified}
                name="is_verified"
                options={isVerifiedList}
                styles={customStyles}
                className="basic-multi-select mr15 br10"
                classNamePrefix="select"
                onChange={handleSelectIsVeriFied}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--primary-color-light)",
                    primary: "var(--primary-color)",
                  },
                })}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            <div className="icon-section">
              <Dropdown className="mr15">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdownborder filterhover"
                >
                  <span className="filter"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {columns.map((column) => {
                    return (
                      <Dropdown.Item
                        key={column}
                        onClick={(e) => handleSort(column)}
                        style={{ cursor: "pointer" }}
                      >
                        {column.charAt(0).toUpperCase() +
                          column.slice(1).replace("_", " ")}
                        {["name", "email", "phone"].includes(column) ? (
                          <span className="arrowupdown">
                            {column === sortColumn ? (
                              sortOrder === SORT_ASC ? (
                                <i
                                  className="ms-1 fa fa-angle-up"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="ms-1 fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              )
                            ) : (
                              <i
                                className="ms-1 fa fa-angle-up"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                        ) : null}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className=" dropdownborder1 dothover"
                >
                  <span className="dot"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                        <ExportData params={expParams} fileName="User" api="user" handleCheckAll={handleCheckAll}/>
                    </Dropdown.Item>
                  {userPermission ? (
                    userPermission.includes("user_delete") ? (
                      <Dropdown.Item onClick={() => handleBulkDelete()}>
                        Delete
                      </Dropdown.Item>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {/*--- searchbar_section_end ---*/}

        {/*--- list_view_section_start ---*/}
        <div className="display list_view mt30 pb4">
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="checkAll"
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckAll(value);
                }}
                checked={mainChecked}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                label=""
              />
            </label>
          </div>

          {/*--- details_section ---*/}
          <div className="listdetails">
            <h3>User Details</h3>
          </div>
          {/*--- owner_section ---*/}
          <div className="listowner">
            <h3>Role</h3>
          </div>
          {/*--- action_section ---*/}
          <div className="listaction"></div>
        </div>

        {displayUserData.length >= 1 ? (
          displayUserData
        ) : (
          <span>No data Available</span>
        )}
        {displayUserData.length >= 1 ? (
          <Row>
            <Col xs={12} xl={12} className="showing">
              <Card border="light" className="bg-white border-0">
                <Card.Body className="listcard pe-0 pt-0 pb-0">
                  <Row className="align-items-center">
                    <Col md={3} className="ps-2">
                      <h6>
                        Showing{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1)}
                        </b>{" "}
                        -{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1) +
                            ((dataCount ? dataCount : 0) - 1)}
                        </b>{" "}
                        of{" "}
                        <b>{state?.users?.total ? state?.users?.total : 0}</b>
                      </h6>
                    </Col>
                    <Col xl={4} md={4}>
                      <div className="row align-items-center g-0">
                        <div className="col-md-2 col-xl-3"></div>
                        <div className="col-md-3 col-xl-4">
                          <h6 className="text-right me-3">Per page</h6>
                        </div>
                        <div className="col-md-4 col-xl-4">
                          <select
                            className="border-start-0 border-end-0 border-top-0 borderbottom w-50"
                            value={perPage}
                            onChange={(e) => {
                              handlePerPage(e.target.value);
                              handleCheckAll(false);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                        <div className="col-md-3 col-xl-1"></div>
                      </div>
                    </Col>
                    <Col md={5} className="d-flex justify-content-end">
                      <Pagination
                        activePage={
                          state?.users?.current_page
                            ? state?.users?.current_page
                            : 0
                        }
                        itemsCountPerPage={
                          state?.users?.per_page ? state?.users?.per_page : 0
                        }
                        totalItemsCount={
                          state?.users?.total ? state?.users?.total : 0
                        }
                        onChange={(pageNumber) => {
                          currentPage = pageNumber;
                          getData(pageNumber);
                          handleCheckAll(false);
                        }}
                        pageRangeDisplayed={5}
                        itemClass="page-item mx-1"
                        linkClass="page-link"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Modal
          as={Modal.Dialog}
          centered
          show={showUserDefault}
          onHide={handleUserClose}
        >
          <Modal.Header>
            <Modal.Title className="h2 modal-title h4">
              Send User Invitation
            </Modal.Title>
            {/* <Button variant="close" aria-label="Close" type="button" class="btn btn-close" onClick={handleUserClose} /> */}
            <Button
              variant="close"
              aria-label="Close"
              onClick={handleUserClose}
            />
            <span className="modelclose"></span>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={defaultValue}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  setFieldTouched,
                } = props;
                return (
                  <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                    <Row>
                      <div className="col-md-12 col-sm-12">
                        <Row className="align-items-center">
                          <Form.Group className="mb-125 firstrow position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Email<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="email">
                                <Form.Control
                                  type="email"
                                  minLength={2}
                                  maxLength={50}
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter email"
                                  className={
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.email}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="email" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group>
                            <Col sm="12" className="Display position-relative">
                              <Form.Label column sm="12">
                                <h6 className="fw600">
                                  Role<span className="text-danger">*</span>
                                </h6>
                              </Form.Label>
                              <Form.Group id="role">
                                <Select
                                  defaultValue=""
                                  placeholder="Please select"
                                  name="role"
                                  options={roleList}
                                  className={
                                    errors.role && touched.role
                                      ? "input-select-error basic-multi-select"
                                      : "basic-multi-select"
                                  }
                                  classNamePrefix="select"
                                  //onChange={handleSelectSave}
                                  onChange={(selectedOption) => {
                                    values.role = selectedOption.value;
                                    handleChange("role");
                                  }}
                                  styles={customStyles}
                                  isClearable={false}
                                  isSearchable={false}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#0074E1",
                                      primary: "#0074E1",
                                    },
                                  })}
                                  ref={roleRef}
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.role}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="role" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                        </Row>
                      </div>
                    </Row>

                    <div className="text-center mt30">
                      {isSubmitting ? (
                        <Button className="d-flex align-items-center popupbtn btnblue justify-content-center text-center btnblue mb-1">
                          <Spinner animation="border" size="sm" />
                          <span className="ms-2 text-center">
                            Please wait...
                          </span>
                        </Button>
                      ) : (
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="submit"
                              variant="success"
                              className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                            >
                              Send
                            </Button>
                          </div>
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="button"
                              variant="success"
                              onClick={handleUserClose}
                              className="popupbtn greycolor d-flex align-items-center justify-content-center"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default UserList;
