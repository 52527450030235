/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Row, Col, Nav, Image, Dropdown, ListGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { login, logout, selectUser, LoginPermission, Workspace, NotificationList, DisplayHostName } from "../features/userSlice";
import axios from "axios";
import Cookies from 'universal-cookie';
import Offcanvas from 'react-bootstrap/Offcanvas';
import WriteUsModel from './WriteUs';
import { Routes } from "../routes";

var isClickable = false;
var clickLink = '';

export default (props) => {
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const history = useHistory();
    const [writeUsModalIsOpen, setWriteUsModalIsOpen] = useState(false);
    const [workspaceList, setWorkspaceList] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const loginUser = useSelector(selectUser);
    const notifications = useSelector(state => state.notificationList)
    const notificationCnt = useSelector(state => state.notificationCount)
    const user = loginUser ? loginUser : [];

    //get default host data from redux using url
    var defaultHostData = useSelector(DisplayHostName);
    var hostname = defaultHostData?.hostname || ''
    var displayHost = defaultHostData?.displayHost || ''

    const splitLocation = pathname.split("/");
    let firstName = '';
    let expsec = '';
    let menuTitle = '';

    if (splitLocation.length >= 2) {
        expsec = splitLocation[1].split("-");
        if (expsec.length == 1) {
            firstName = expsec[0];
            menuTitle = expsec[0].charAt(0).toUpperCase() + expsec[0].slice(1);
        } else if (expsec.length == 2) {
            firstName = expsec[1] + 's'
            menuTitle = expsec[0].charAt(0).toUpperCase() + expsec[0].slice(1) + ' ' + expsec[1].charAt(0).toUpperCase() + expsec[1].slice(1);
        }
    }

    if (firstName === 'activitys') {
        firstName = 'activities';
    }

    useEffect(() => {
        async function fetchData() {
            await getUserWorkspace();
        }
        fetchData();
    }, []);

    async function getUserWorkspace() {
        await axios.get("api/user/getLoginUserWorkspace").then(response => {
            if (response.status === 200) {
                setWorkspaceList(response.data.data.workspaces)
                dispatch(
                    NotificationList(response.data.data.notifications),
                );
            }
        }).then(json => {

        }).catch(error => {
            //setLoading(false);
            if (error.response) {
                if (error.response.status === 400) {
                    toast.warning(error.response.data.message);
                } else if (error.response.status === 300) {
                    toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    toast.error('Forbidden ' + error.response.data.message);
                } else if (error.response.status === 500) {
                    toast.error('Oops Something went wrong.');
                } else if (error.response.status === 401) {
                    toast.error('Unauthorized.');
                    history.push('/sign-in')
                } else {
                    toast.error('Oops Something went wrong.');
                }
            }
        });
    }

    const workspaceLists = workspaceList.map(workspace => {
        return { label: workspace.name, value: workspace.tenant_id };
    });

    let defaultWorkspace = []
    for (var i = 0, l = workspaceList.length; i < l; i++) {
        if (user ? user.currentWorkspaceId == workspaceList[i].tenant_id : false) {
            defaultWorkspace.push({ label: workspaceList[i].name, value: workspaceList[i].tenant_id });
        }
    }

    if (user) {
        const roleName = user?.role?.toLowerCase() || '';
        if (['admin', 'superadmin', 'super admin'].includes(roleName)) {
            isClickable = true;
            clickLink = 'setting';
        }
    }

    const logoutSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('api/logout');
            if (response.status === 200) {
                dispatch(logout());
                toast.success('You have successfully logged out');
            }
        } catch (error) {
            // Handle the error appropriately
            toast.error('Logout failed. Please try again.');
        }
    }

    const handleWorkspace = async (event) => {
        const data = {
            workspace_id: event.value,
        }

        await axios.post("api/change-workspace", data).then(response => {
            if (response.status === 200) {
                const workspaceData = response.data.data.workspace;
                const currentTenantDomain = response.data.data.currentTenant.domain;
                cookies.set('tenant_workspace', event.value, { domain: hostname });

                dispatch(login());
                dispatch(Workspace());
                dispatch(LoginPermission());

                cookies.remove('auth_tenant', { domain: hostname });
                cookies.set('auth_tenant', workspaceData, { domain: hostname });

                const { protocol, host } = window.location;
                let subdomain = host === 'solvcrm.asaya.in' ? 'solvcrm' : 'app';
                const parts = host.split(".");

                if (parts.length >= 3) {
                    subdomain = currentTenantDomain || subdomain;
                    parts.splice(0, 1);
                    window.location = `${protocol}//${subdomain}.${parts.join(".")}/dashboard`;
                } else {
                    window.location = `${protocol}//${host}`;
                }
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                const { status, data } = error.response;
                const messages = {
                    400: data.message,
                    300: data.message,
                    403: `Forbidden ${data.message}`,
                    401: data.message,
                };
                toast[status >= 400 ? 'error' : 'warning'](messages[status] || 'Oops Something went wrong.');
            }
        });

    }

    function redirectAccountFunction() {
        handleClose()
        history.push(Routes.Profile.path)
    }

    function redirectCompanyFunction() {
        handleClose()
        history.push(Routes.CompanySetting.path)
    }

    function redirectUserFunction() {
        handleClose()
        history.push(Routes.UserList.path)
    }

    const addDefaultProfileSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/default-profile-image.png`;
    }

    const openWriteToUsModal = () => {
        handleClose()
        setWriteUsModalIsOpen(true);
    };

    const closeWriteToUsModal = () => {
        handleClose()
        setWriteUsModalIsOpen(false);
    };

    const displayDays = () => {
        const days = user?.subRemainingDays;

        if (days === 0) {
            return 'Your subscription will end on today.'
        } else if (days === -1) {
            return 'Your subscription has expired.'
        } else if (days) {
            return `Your subscription will end in ${days} day${days === 1 ? '' : 's'}.`;
        } else if (days === undefined) {
            return ''
        } else if (days == null) {
            return 'You have a free subscription.';
        }
    }

    const markNotificationsAsRead = (isOpen) => {
        setShowDropdown(isOpen);
        if (isOpen) {
            //markNotificationsAsRead();
        }

    };

    const Notification = (props) => {
        const { link, sender, user_image, title, body, status, id } = props;
        const readClassName = status ? "text-danger" : "text-normal";
        var image = '';
        if (props?.user_image?.profile_image) {
            image = process.env.REACT_APP_API_URL + 'storage/' + props.user_image.profile_image;
        }
        return (
            <ListGroup.Item className="border-bottom border-light" key={id}>
                <Row className="align-items-center">
                    <Col className="col-auto">
                        <Image onError={addDefaultProfileSrc} src={image} className="user-avatar lg-avatar rounded-circle" />
                    </Col>
                    <Col className="ps-0 ms--2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="text-start">
                                <small className={readClassName}>{title ? title.charAt(0).toUpperCase() + title.slice(1) : ''}</small>
                            </div>
                            {/* <div>
                <h4 className="h6 mb-0 text-small">{body}</h4>
              </div> */}
                        </div>
                        <p className="font-small mb-0">{body}</p>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    };

    const userImage = user?.profile_image ? process.env.REACT_APP_API_URL + 'storage/' + user?.profile_image : ''

    return (
        <>
            <div className="header-top">
                <div className="col-md-6 d-flex">
                    {expsec.length > 1 ? (
                        menuTitle == "Activity Logs" || menuTitle == "Track Users" ? (
                            ""
                        ) : (
                            <span
                                className="backarrowicon"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    props.history.push({
                                        pathname: "/" + firstName,
                                        search: props.location.search,
                                    })
                                }
                            ></span>
                        )
                    ) : (
                        ""
                    )}

                    <h2 className="txtblue m-0">
                        {firstName == "settings" ? "Settings" : menuTitle}
                    </h2>
                </div>
                <div className="col-md-6 innersection">
                    {/* {user?.packageType !== "offline" ? (
            <div
              className="innersection updatebtn"
              style={{ paddingRight: "10px" }}
            >
              {displayDays()}{" "}
              <span
                onClick={() => history.push(Routes.Checkout.path)}
                style={{ cursor: "pointer", paddingLeft: "5px" }}
              >
                Upgrade Now
              </span>
            </div>
          ) : (
            ""
          )} */}

                    <div className="innersection">
                        <a href="#" onClick={openWriteToUsModal}>
                            <span className="qusetionicon"></span>
                        </a>
                        <Dropdown
                            as={Nav.Item}
                            show={showDropdown}
                            onToggle={markNotificationsAsRead}
                        >
                            <Dropdown.Toggle
                                as={Nav.Link}
                                className="text-dark icon-notifications me-lg-3 ms-3"
                            >
                                <span className="icon icon-sm">
                                    <FontAwesomeIcon icon={faBell} className="bell-shake" />
                                    {notificationCnt ? (
                                        <span className="icon-badge rounded-circle unread-notifications" />
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                                <ListGroup className="list-group-flush">
                                    <div className="display justify-conetnt-between border-bottom border-light p20">
                                        <div className="col-md-6">
                                            <Nav.Link
                                                href="#"
                                                className="text-left text-primary fw-bold py-3"
                                            >
                                                Notifications
                                            </Nav.Link>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                            <span className="rounded-pill badge bluebg">
                                                {notificationCnt} new
                                            </span>
                                        </div>
                                    </div>

                                    <div className="scrollmaxheight">
                                        {
                                            notifications?.length
                                                ? notifications.map((n) => (
                                                    <Notification key={`notification-${n.id}`} {...n} />
                                                ))
                                                : ""}
                                    </div>

                                    <Dropdown.Item
                                        className="text-center text-primary fw-bold py-3"
                                        onClick={() => {
                                            setShowDropdown(false);
                                            history.push("notification");
                                        }}
                                    >
                                        View all
                                    </Dropdown.Item>
                                </ListGroup>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Offcanvas show={show} onHide={handleClose} placement="end">
                            <Offcanvas.Header closeButton className="p3525">
                                <Offcanvas.Title>
                                    <div className="offcanvasprofile text-center">
                                        <Image
                                            onError={addDefaultProfileSrc}
                                            src={userImage}
                                            className="user-avatar md-avatar rounded-circle"
                                        />
                                        <h5>{user ? user.name : ""}</h5>
                                        <p>
                                            <span>User Id:</span>
                                            {user?.uuid || "60016162792"}
                                        </p>
                                        <Select
                                            defaultValue={defaultWorkspace}
                                            name="workspace_id"
                                            options={workspaceLists}
                                            styles={""}
                                            className="basic-multi-select br10"
                                            classNamePrefix="select"
                                            onChange={handleWorkspace}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "var(--primary-color-light)",
                                                    primary: "var(--primary-color)",
                                                },
                                            })}
                                            isClearable={false}
                                            isSearchable={false}
                                        />
                                        {/* <h6>solvios</h6> */}
                                    </div>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <div className="logoutbtn">
                                <div className="display justify-content-start">
                                    <ul>
                                        <li>
                                            <a onClick={redirectAccountFunction}>
                                                <span className="righticon"></span>My Account
                                            </a>
                                        </li>
                                        {user ? (
                                            isClickable ? (
                                                <li>
                                                    <a
                                                        onClick={
                                                            clickLink == "setting"
                                                                ? redirectCompanyFunction
                                                                : redirectUserFunction
                                                        }
                                                    >
                                                        <span className="righticon"></span>Settings
                                                    </a>
                                                </li>
                                            ) : null
                                        ) : null}
                                        <li>
                                            <a onClick={() => openWriteToUsModal}>
                                                <span className="righticon"></span>Help
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="offcanvasfooter display plr3020 ">
                                <div className="col-md-9 display">
                                    <h4 onClick={logoutSubmit} style={{ cursor: "pointer" }}>
                                        <span className="righticon"></span>Logout
                                    </h4>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </Offcanvas>

                        <Nav className="align-items-center ml20" onClick={handleShow}>
                            <Dropdown as={Nav.Item}>
                                <Dropdown.Toggle as={Nav.Link} className="px-0 py-0">
                                    <div className="media d-flex align-items-center">
                                        <Image
                                            onError={addDefaultProfileSrc}
                                            src={userImage}
                                            className="user-avatar md-avatar rounded-circle"
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Nav>
                    </div>
                </div>
            </div>
            {writeUsModalIsOpen && (
                <WriteUsModel
                    isOpen={writeUsModalIsOpen}
                    onRequestClose={closeWriteToUsModal}
                />
            )}
        </>
    );
};