import React, { useState, useEffect, memo } from "react";
import { Form } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select'
import { ErrorMessage } from "formik";
import axios from 'axios';

let subTotal = 0;
let grandTotal = 0;
let finalDiscountDisplay = 0;
var currencyCode = '';
var currencySymbol = '';

function QuoteItems(props) {
  const [perSignDisplay, setPerSignDisplay] = useState(true);
  const [perSignDisplayTax, setPerSignDisplayTax] = useState(true);
  const [finalTax, setFinalTax] = useState();
  let selecteProduct = [{ label: 'Select', value: '' }];
  const [rows, setRows] = useState([{ sNo: 1, productId: '', quantity: '',  amount: '', listPrice: '', tax: '', total: '',hasError:false }]);
  const [isEditable, setIsEditable] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [taxInputValue, setTaxInputValue] = useState('');
  const [adjustInputValue, setAdjustInputValue] = useState('');

  const handleAddRow = () => {
    const newRow = { sNo: rows.length + 1, productId: '', quantity: '', amount: '', listPrice: '', tax: '', total: '',hasError:false };
    setRows([...rows, newRow]);
  };

  useEffect(()=>{
    if(props.data.productIds){
      const newState = rows.map((x,i) => {
        return i == props.data.productIds ? { ...x, hasError: true } : { ...x, hasError: false};
      });
      setRows(newState);
    }
  },[props.data.productIds]);

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    
    handleCalculation(newRows)
    setRows(newRows);
    props.data.setFieldValue('QuoteItemArray', newRows)
  };

  
  const [finalTotalData, setFinalTotalData] = useState({ finalSubTotal: '', finalDiscount: '', finalDiscountPer: '', finalDiscountType:'percentage', finalTaxDiscount:'', finalTax: '', finalTaxDiscountPer: '', finalTaxDiscountType:'percentage', finalAdjustment: '', finalGrandTotal: '' });

  const handleNumericChange = (e,i) =>{
    // var splAmount = e.target.value.split('.')
    // e.target.value = splAmount.length==2?splAmount[0]==0?1:splAmount[0]:e.target.value
    var splAmount = e.target.value.split('.')
    e.target.value = splAmount.length==2?splAmount[0]==0?1:splAmount[0]:e.target.value==0?1:e.target.value
  }

  const handleChange = async(event, index, isSelect) => {
    const eventValue = event.value;
    const newRows = [...rows];

    if (isSelect) {
      newRows[index]['productId'] = event.value;
      
      const data = {
        product_id: event.value,
      }
      
      if(eventValue){
        //call API to stor temp data for quote item
        await axios.post("/api/quote/add-temp", data).then(response => {
          if (response.status === 200) {
            newRows[index]['productId'] = response.data.data.product.id;
            newRows[index]['quantity'] = 1
            newRows[index]['amount'] = response.data.data.product.price ? response.data.data.product.price : ''
            newRows[index]['listPrice'] = response.data.data.product.price ? response.data.data.product.price : ''
            newRows[index]['discount'] = 0
            newRows[index]['tax'] = response.data.data.product.tax ? response.data.data.product.tax : 0
            newRows[index]['total'] = Number(response.data.data.product.price ? response.data.data.product.price : 0) + Number(response.data.data.product.tax ? response.data.data.product.tax : 0)
            newRows[index]['hasError'] = false;
            handleCalculation(newRows)
            setRows(newRows);
            setIsEditable(false)
          }
        }).then(json => {

        }).catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              //setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
            } else if (error.response.status === 403) {
              toast.warning('Forbidden ' + error.response.data.message);
            } else if (error.response.status === 300) {
              toast.warning(error.response.data.message);
            }else {
              toast.warning(error.response.data.message);
            }
          }
        });
      }else{
          newRows[index]['productId'] ='';
          newRows[index]['quantity'] = ''
          newRows[index]['amount'] = ''
          newRows[index]['listPrice'] = ''
          newRows[index]['discount'] = ''
          newRows[index]['tax'] = ''
          newRows[index]['total'] = '0.00'
          newRows[index]['hasError'] = false;
          handleCalculation(newRows) 
          setRows(newRows);
          if(newRows.length==1){
            setIsEditable(true)
          }
      }
      

    } else {
      const { name, value } = event.target;
      newRows[index][name] = value;
      if(newRows[index]['productId']){
        if (name == 'quantity') {
          var splAmount = value.split('.')
          newRows[index][name]  = splAmount.length==2?splAmount[0]==0?1:splAmount[0]:value
          if(/^[0-9\b]+$/.test(value)){
            if(parseFloat(value)){
              newRows[index]['total'] = (parseFloat(value * newRows[index]['amount']) + parseFloat(newRows[index]['tax'])).toFixed(2);
            }else{
              newRows[index]['total'] = (parseFloat(1 * newRows[index]['amount']) + parseFloat(newRows[index]['tax'])).toFixed(2);
            }
          }else if (value === '' || isNaN(parseInt(value))) {
            newRows[index]['total'] = (parseFloat(splAmount.length==2?splAmount[0]=='0'?1:splAmount[0]:value * newRows[index]['amount']) + parseFloat(newRows[index]['tax'])).toFixed(2);
          }
        } else if (name == 'tax') {
          if (value) {
            newRows[index]['total'] = parseFloat(value) + parseFloat(newRows[index]['quantity'] * newRows[index]['amount']);
          } else {
            newRows[index]['total'] = parseFloat(newRows[index]['quantity'] * newRows[index]['amount']);
          }
        } else if (name == 'amount' && value) {
          if (value) {
            //newRows[index]['amount'] = parseInt(value * newRows[index]['amount']);
  
            newRows[index]['total'] = parseFloat(value * newRows[index]['quantity']) + parseFloat(newRows[index]['tax']);
          } else {
            newRows[index]['amount'] = 0;
            newRows[index]['listPrice'] = 0;
            newRows[index]['total'] = 0;
          }
        }
        handleCalculation(newRows)
        setRows(newRows);
      }
      
    }

    props.data.setFieldValue('QuoteItemArray', newRows)
  };

  if(props.data.preferredCurrencyData.currencyCode){
      currencyCode = props.data.preferredCurrencyData.currencyCode ? props.data.preferredCurrencyData.currencyCode: '';
      currencySymbol = props.data.preferredCurrencyData.currencySymbol ? props.data.preferredCurrencyData.currencySymbol: '';
  }

  const myObj = {
      style: "currency",
      currency: currencyCode
  }

  const handleNumericInput = (e) => {
    const { value,name } = e.target;
    const regex = /^[-+]?\d*\.?\d*$/;

    if (regex.test(value) && name==='finalDiscountPer') {
      setInputValue(value);
      handleFinalChanges(e);
    }else if(regex.test(value) && name==='finalTaxDiscountPer'){
      setTaxInputValue(value);
      handleFinalChanges(e);
    }
  };

  const handleKeyDown = (e) => {
    // Prevent the input of non-numeric characters, including + and -
    if (!/[\d.]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
      e.preventDefault();
    }
  };


  const handleAdjustmentNumericInput = (e) =>{
    const { value } = e.target;
    const regex = /^(-)?\d*\.?\d*$/;
    
    if (regex.test(value) || value === '' || value === '-') {
      setAdjustInputValue(value);
      handleFinalChanges(e);
    }
  }

  const handleAdjustKeyDown = (e) =>{
    const { selectionStart, value } = e.target;
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', // Navigation and deletion keys
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.' // Numeric keys and dot
    ];

    // Allow + or - only as the first character
    if (selectionStart === null && (e.key === '-') && (!value)) {
      return;
    }

    // Prevent the input of non-numeric characters
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }

    // Prevent multiple dots
    if (e.key === '.' && value.includes('.')) {
      e.preventDefault();
    }
  }

  

  const handleFinalChanges = (e) => {
    const { name, value } = e.target;
    //console.log(name, value,'-----handleFinalChanges---------',finalTotalData['finalSubTotal'])
    if(finalTotalData['finalSubTotal']){
      //================START:WRITE LOGIC FOR CLICK ON PERCENTAGE RADIO BUTTON=================//
      if(name==='discount_type' && value==='flat'){
        finalTotalData['finalDiscountType'] = 'flat';
        finalTotalData['finalDiscount'] = finalTotalData['finalDiscountPer']
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0  + finalTotalData['finalAdjustment']);
        setPerSignDisplay(false)
      }else if(name==='discount_type' && value==='percentage'){
        finalTotalData['finalDiscountType'] = 'percentage';
        finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount'])  + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        setPerSignDisplay(true)
      }else if(name!='discount_type' && finalTotalData['finalDiscountType']){
        finalTotalData['finalDiscountType'] = finalTotalData['finalDiscountType'];
      }else {
        finalTotalData['finalDiscountType'] = 'percentage';
        finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount'])  + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        setPerSignDisplay(true) 
      }

      //================START:WRITE LOGIC FOR CLICK ON TAX PERCENTAGE RADIO BUTTON=================//
      if (name === 'tax_discount_type' && value === 'flat') {
        finalTotalData['finalTaxDiscountType'] = 'flat';
        finalTotalData['finalTaxDiscount'] = finalTotalData['finalTaxDiscountPer']
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        setPerSignDisplayTax(false)
      } else if (name === 'tax_discount_type' && value === 'percentage') {
        //console.log(finalTotalData['finalSubTotal'],finalTotalData['finalTaxDiscount'],'kkkkk')
        finalTotalData['finalTaxDiscountType'] = 'percentage';
        finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalTaxDiscount']) / 100);
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        setPerSignDisplayTax(true)
      } else if (name != 'tax_discount_type' && finalTotalData['finalTaxDiscountType']) {
        finalTotalData['finalTaxDiscountType'] = finalTotalData['finalTaxDiscountType'];
      } else {
        finalTotalData['finalTaxDiscountType'] = 'percentage';
        finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscount']) / 100);
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        setPerSignDisplayTax(true)
      }
      //================END:WRITE LOGIC FOR CLICK ON TAX PERCENTAGE RADIO BUTTON=================//



      
      //console.log(perSignDisplay,'ggggg',finalTotalData['finalDiscountType'])
      //================END:WRITE LOGIC FOR CLICK ON PERCENTAGE RADIO BUTTON=================//
      if (name === 'finalDiscountPer') {
        finalTotalData['finalDiscountPer'] = value ? parseFloat(value):0;
        //console.log(finalTotalData['finalDiscountPer'], value,'--------------')
        if (value) {
          if(finalTotalData['finalDiscountType']==='percentage'){
            finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
            //finalDiscountDisplay = parseFloat(finalTotalData['finalDiscount']);
          }else{
            finalTotalData['finalDiscount'] = value ? parseFloat(value):0;
            //finalDiscountDisplay = parseFloat(finalTotalData['finalDiscount']);
          }
          //arrange new array for final data
          finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);

        }else{
          if(finalTotalData['finalDiscountType']==='percentage'){
            finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
          }else{
            finalTotalData['finalDiscount'] = value ? parseFloat(value):0;
          }
          //arrange new array for final data
          finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        }
        //====================== Calculation for Final tax percentage =====================================//  
      } else if (name === 'finalTaxDiscountPer') {

        finalTotalData['finalTaxDiscountPer'] = value ? parseFloat(value):0;
        if (value) {
          if(finalTotalData['finalTaxDiscountType']==='percentage'){
            finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalTaxDiscountPer']) / 100);
          }else{
            finalTotalData['finalTaxDiscount'] = value ? parseFloat(value):0;
          }
          //arrange new array for final data
          finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        }else{
          if(finalTotalData['finalTaxDiscountType']==='percentage'){
            finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalTaxDiscountPer']) / 100);
          }else{
            finalTotalData['finalTaxDiscount'] = value ? parseFloat(value):0;
          }
          finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        }


      } else if (name === 'finalTaxDiscount') {
        finalTotalData['finalTaxDiscount'] = value ? parseFloat(value):0;
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] : 0 ) + parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalAdjustment'] ? finalTotalData['finalAdjustment'] : 0 );
      } else if (name === 'finalAdjustment') {
        finalTotalData['finalAdjustment'] = value ? parseFloat(value):0;
        if (finalTotalData['finalAdjustment'] > 0) {
          finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
        } else {
          var slpitdata = value.split('-');
          if (slpitdata.length >= 2) {
            slpitdata = slpitdata[1]
          }
          finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] - slpitdata : 0 - slpitdata);
        }
      }

      setFinalTotalData(finalTotalData)

      props.data.setFieldValue('finalData', finalTotalData)
    }
  }
  

  const handleCalculation = (newRows) => {
    var itemSubTotal = 0;
    for (var i = 0, l = newRows.length; i < l; i++) {
      itemSubTotal += parseFloat(newRows[i].total);
    }
    subTotal = itemSubTotal;
    grandTotal = itemSubTotal;
    
    finalTotalData['finalSubTotal'] = subTotal
    finalTotalData['finalGrandTotal'] = grandTotal
    
    if (finalTotalData['finalDiscountPer']) {
      if(finalTotalData['finalDiscountType']==='percentage'){
        finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
      }else{
        finalTotalData['finalDiscount'] = finalTotalData['finalDiscountPer'];
      }
    }

    if (finalTotalData['finalTaxDiscountPer']) {
      if(finalTotalData['finalTaxDiscountType']==='percentage'){
        finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalTaxDiscountPer']) / 100);
      }else{
        finalTotalData['finalTaxDiscount'] = finalTotalData['finalTaxDiscountPer'];
      }
    }

    if(finalTotalData['finalAdjustment']) {
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount']) + parseFloat(finalTotalData['finalAdjustment']);
    }else{
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] - finalTotalData['finalAdjustment']);
    }
    //console.log(finalTotalData,'finalTotalData')
    setFinalTotalData(finalTotalData)
    props.data.setFieldValue('finalData', finalTotalData)
  }

  //-----------------For Country list-----------------------------------
  const customStyles1 = {
    menuList: styles => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  }

  let productLists = [{ label: 'Select', value: '' }];
  for (var i = 0, l = props.data.productList.length; i < l; i++) {
    productLists.push({ label: props.data.productList[i].name, value: props.data.productList[i].id });
  }

  return (

    <div className="mt-4 history quotetable">
      <table>
        <thead>
          <tr>
            <th key='S.No' style={{ width: "5%" }}>S.No</th>
            <th key='Product Name' style={{ width: "20%" }}>Product Name</th>
            <th key='Quantity' style={{ width: "15%" }}>Quantity</th>
            <th key='Amount' style={{ width: "15%" }}>Amount ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</th>
            <th key='List Price' style={{ width: "15%" }}>List Price ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</th>
            <th key='Tax' style={{ width: "10%" }}>Tax ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</th>
            <th key='Total' style={{ width: "10%" }}>Total ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</th>
            <th key='Action' style={{ width: "10%" }}>Action</th>
            
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) => {
              //if (index == index - 1) {
                for (var i = 0, l = props.data.productList.length; i < l; i++) {
                  if (row.productId == props.data.productList[i].id) {
                    selecteProduct.push({ label: props.data.productList[i].name, value: props.data.productList[i].id });
                  }
                }
             // }

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Select
                      value={productLists.filter(({ value }) => value === row.productId)} 
                      //defaultValue={selecteProduct}
                      //name={`productId[${index}]name`}
                      options={productLists}
                      className={row.hasError ? 'basic-multi-select quoteinput input-select-error':'basic-multi-select quoteinput'}
                      //className='basic-multi-select quoteinput'
                      classNamePrefix="select"
                      menuPosition="fixed"
                      //value={row.productId}
                      // onChange={selectedOption => {
                      //      values.product_id = selectedOption.value;
                      //      handleChange("product_id",index);
                      // }}
                      onChange={(event) => {handleChange(event, index, true)}}
                      styles={customStyles1}
                      isClearable={false}
                      isSearchable={true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                        },
                      })}
                    />
                    {
                      row.hasError ?
                      <span className="error error4">
                        <span>Please select product</span>
                      </span>
                      :
                      null
                    }
                    {/* <input
                        type="textarea"
                        name="productDescription"
                        value={row.productId}
                        placeholder="Product Description"
                        onChange={(event) => handleChange(event, index)}
                    /> */}
                      {/* {
                        errors.productId?.[index]?.name ?
                          <span className="error error4">
                                <ErrorMessage name="productId" />
                          </span>
                        :
                          ''
                      } */}
                        <span className="error error4">
                                <ErrorMessage name="productId" />
                          </span>
                  </td>
                  <td>
                    <input
                      type="number"
                      name="quantity"
                      value={row.quantity}
                      onChange={(event) => {handleChange(event, index)}}
                      className="form-control"
                      onBlur={handleNumericChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="amount"
                      value={row.amount}
                      className="form-control"
                      onChange={(event) => handleChange(event, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="listPrice"
                      value={row.listPrice}
                      
                      className="form-control"
                      disabled
                    />
                  </td>      
                  <td>
                    <input
                      type="number"
                      name="tax"
                      value={row.tax}
                      className="form-control"
                      onChange={(event) => handleChange(event, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="total"
                      value={row.total}
                      className="form-control"
                      disabled
                    />
                  </td>
                  <td>
                    {
                      index + 1 != 1 ?
                        <div onClick={() => handleRemoveRow(index)}><span className="deleteicon1"></span></div>
                        :
                        ''
                    }
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      <div className="display mt-3">
      <div className="col-md-9 display justify-content-start mt-3">
      <div onClick={() => handleAddRow()}  className="createbtn btnbluecolor">Add New <span className="createicon"></span></div>
      </div>
      <div className="col-md-3">
      <div className="display justify-content-end quotesubtotal">
        <table>
        <tbody>
          <tr key='subtotal'>
            <td>Sub Total ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
              <input
                type="number"
                name="finalSubTotal"
                value={finalTotalData.finalSubTotal}
                disabled
                className="form-control"
              />
            </td>
          </tr>
          
          <tr key='discount'>
            <td>Discount ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
            <div className="display mb-2">
              <Form.Check
                defaultChecked
                type="radio"
                //defaultValue="option1"
                label="Percentage"
                name="discount_type"
                value='percentage'
                onChange={(e)=> handleFinalChanges(e)}
                //id="radio1"
                htmlFor="radio1"
                disabled={isEditable}
              />
              <Form.Check
                type="radio"
                //defaultValue="option1"
                label="Flat"
                name="discount_type"
                className="flatmargin"
                //id="radio1"
                value='flat'
                onChange={(e) => handleFinalChanges(e)}
                htmlFor="radio1"
                disabled={isEditable}
              />
            </div>
            <div className="discountinline">
              <input
                type="number"
                name="finalDiscountPer"
                max={finalTotalData.finalDiscountType === 'percentage' ? 100:1000000000000000} 
                step={0.01}
                value={inputValue}
                //onChange={(e) => handleFinalChanges(e)}
                onChange={handleNumericInput}
                onKeyDown={handleKeyDown}
                className="form-control discountfinal"
                disabled={isEditable}
              />
                {perSignDisplay ?
                  <span className="percetangeicon"></span>
                  :
                  ''
                }
                
                <input
                type="number"
                name="finalDiscount"
                value={finalTotalData.finalDiscountType === 'percentage' ? Math.round((finalTotalData.finalDiscount + Number.EPSILON) * 100) / 100 : finalTotalData.finalDiscount}
                disabled
                className="form-control discountfinal"
              />
            </div>
            </td>
          </tr>
          {/* <tr key='tax'>
            <td>Tax ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
              <input
                type="number"
                name="finalTax"
                value={finalTax}
                step="any"
                onChange={(e) => handleFinalChanges(e)}
                className="form-control"
                disabled={isEditable}
              />
            </td>
          </tr> */}
          <tr key='tax'>
            <td>Tax ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
            <div className="display mb-2">
            <Form.Check
                defaultChecked
                type="radio"
                //defaultValue="option1"
                label="Percentage"
                name="tax_discount_type"
                value='percentage'
                onChange={(e)=> handleFinalChanges(e)}
                //id="radio1"
                htmlFor="radio1"
                disabled={isEditable}
              />
              <Form.Check
                type="radio"
                //defaultValue="option1"
                label="Flat"
                name="tax_discount_type"
                className="flatmargin"
                //id="radio1"
                value='flat'
                onChange={(e) => handleFinalChanges(e)}
                htmlFor="radio1"
                disabled={isEditable}
              />
            </div>
            <div className="discountinline">
              <input
                type="number"
                name="finalTaxDiscountPer"
                max={finalTotalData.finalTaxDiscountType === 'percentage' ? 100:1000000000000000} 
                step={0.01}
                //onChange={(e) => handleFinalChanges(e)}
                value={taxInputValue}
                onChange={handleNumericInput}
                onKeyDown={handleKeyDown}
                className="form-control discountfinal"
                disabled={isEditable}
              />
                {perSignDisplayTax ?
                  <span className="percetangeicon"></span>
                  :
                  ''
                }
                
                <input
                type="number"
                name="finalTaxDiscount"
                value={finalTotalData.finalTaxDiscountType === 'percentage' ? Math.round((finalTotalData.finalTaxDiscount + Number.EPSILON) * 100) / 100 : finalTotalData.finalTaxDiscount}
                disabled
                className="form-control discountfinal"
              />
            </div>
            </td>
          </tr>
          <tr key='adjustment'>
            <td>Adjustment ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
              <input
                type="number"
                name="finalAdjustment"
                step="any"
                //onChange={(e) => handleFinalChanges(e)}
                value={adjustInputValue}
                onChange={handleAdjustmentNumericInput}
                onKeyDown={handleAdjustKeyDown}
                className="form-control"
                disabled={isEditable}
              />
            </td>
          </tr>
          <tr key='grandtotal'>
            <td>Grand Total ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
              <input
                type="number"
                name="finalGrandTotal "
                value={Math.round((finalTotalData.finalGrandTotal + Number.EPSILON) * 100) / 100
              }
                className="form-control"
                disabled
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      </div>
      </div>
    </div>

  )
}

export default memo(QuoteItems);
