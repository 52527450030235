import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Card, Form, Spinner } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import {  toast } from 'react-toastify';
import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import Select from 'react-select'
import validator from 'validator'

import axios from 'axios';
import { blue } from "@mui/material/colors";

var getIpResponse = [];
function AddContact() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [contactData, setcontactData] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [salutationData, setSalutationData] = useState([]);
  const [submitEvent, setSubmitEvent] = useState(false);


  const [ContactInput, setContactInput] = useState({
    salutation: '',
    first_name: '',
    last_name: '',
    title: '',
    department: '',
    description: '',
    profile_pic: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country_id: '',
    zipcode: '',
    phone1: '',
    phone2: '',
    primary_email: '',
    secondry_email: '',
    facebook_url: '',
    twitter_url: '',
    linkedin_url: '',
    instagram_url: '',
    error_list: [],
  });

  const salutationRef = useRef();
  const first_nameRef = useRef();
  const last_nameRef = useRef();
  const primary_emailRef = useRef();
  const secondry_emaildRef = useRef();
  const titleRef = useRef();
  const profile_picRef = useRef();
  const departmentRef = useRef();
  const descriptionRef = useRef();
  const address1Ref = useRef();
  const address2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const country_idRef = useRef();
  const zipcodeRef = useRef();
  const phone1Ref = useRef();
  const phone2Ref = useRef();
  const facebook_urlRef = useRef();
  const twitter_urlRef = useRef();
  const linkedin_urlRef = useRef();
  const instagram_urlRef = useRef();


  const [formsalutationError, setFormSalutationError] = useState(false);
  const [formfirst_nameError, setFormFirst_nameError] = useState(false);
  const [formlast_nameError, setFormLast_nameError] = useState(false);
  const [formtitleError, setFormTitleError] = useState(false);
  const [formprimary_emailError, setFormPrimary_emailError] = useState(false);
  const [formsecondry_emailError, setFormSecondry_emailError] = useState(false);
  const [formdepartmentError, setFormDepartmentError] = useState(false);
  const [formdescriptionError, setFormDescriptionError] = useState(false);
  const [formprofile_picError, setFormProfile_picError] = useState(false);
  const [formaddress1Error, setFormAddress1Error] = useState(false);
  const [formaddress2Error, setFormAddress2Error] = useState(false);
  const [formcityError, setFormCityError] = useState(false);
  const [formstateError, setFormStateError] = useState(false);
  const [formcountry_idError, setFormCountry_idError] = useState(false);
  const [formzipcodeError, setFormzipcodeError] = useState(false);
  const [formphone1Error, setFormPhone1Error] = useState(false);
  const [formphone2Error, setFormPhone2Error] = useState(false);
  const [formfacebook_urlError, setFormFacebook_urlError] = useState(false);
  const [formtwitter_urlError, setFormTwitter_urlError] = useState(false);
  const [formlinkedin_urlError, setFormLinkedin_urlError] = useState(false);
  const [forminstagram_urlError, setFormInstagram_urlError] = useState(false);



  const [salutationError, setSalutationError] = useState(false);
  const [first_nameError, setFirst_nameError] = useState(false);
  const [last_nameError, setLast_nameError] = useState(false);
  const [primary_emailError, setPrimary_emailError] = useState(false);
  const [secondry_emailError, setSecondry_emailError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [profile_picError, setProfile_picError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [address2Error, setAddress2Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [country_idError, setCountry_idError] = useState(false);
  const [zipcodeError, setzipcodeError] = useState(false);
  const [phone1Error, setPhone1Error] = useState(false);
  const [phone2Error, setPhone2Error] = useState(false);
  const [facebook_urlError, setFacebook_urlError] = useState(false);
  const [twitter_urlError, setTwitter_urlError] = useState(false);
  const [linkedin_urlError, setLinkedin_urlError] = useState(false);
  const [instagram_urlError, setInstagram_urlError] = useState(false);
  const [profileImageArray, setProfileImageArray] = useState([]);

  const [getIpResCountryCode, setGetIpResCountryCode] = useState('');
  const [getIpResCountryName, setGetIpResCountryName] = useState('');
  const [getCountryIDFromIP, setGetCountryIDFromIP] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  useEffect(() => {

    getData();

  }, []);


  function getData() {
    var countryIPId = '';
    getLocation();
    axios.get('api/contact/create').then(response => {

      if (response.status === 200) {
        setcontactData(response.data.data);
        setCountryData(response.data.data.countries);
        setSalutationData(response.data.data.salutationArray);

        setFormSalutationError(false);
        setSalutationError(true);

        setFormCountry_idError(false);
        setCountry_idError(true);

        //set country from IP API response
        for (var i = 0, l = response.data.data.countries.length; i < l; i++) {
          if (getIpResponse.country_code == response.data.data.countries[i].country_code || getIpResponse.country_name == response.data.data.countries[i].country_name) {
            countryIPId =  response.data.data.countries[i].id;
          }
        }
        
        setContactInput({ ...ContactInput, 'city': getIpResponse.city,  'state': getIpResponse.region, 'zipcode': getIpResponse.postal, 'country_id':countryIPId});
        setCountry_idError(false);
        setLoading(false);
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
          //swal("Forbidden", error.response.data.message, "error");
          toast.warning('Forbidden '+error.response.data.message);
          history.push('/contacts');
        } else {
          //swal("error", error.response.data.message, "error");
          toast.error(error.response.data.message);
        }
      }
    });
  }

  function getLocation(){
    
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } else {
      //console.log("Not Available");
    }

    fetch(`https://ipapi.co/json/`)
    .then(response => response.json())
    .then(data =>{
        //setGetIpResponse(data);
        getIpResponse = data;
        setGetIpResCountryName(data.country_name);
        setGetIpResCountryCode(data.country_code);
      }
    )
  }

  const handleInput = (e) => {
    e.persist();

    let names = e.target.name;
    let values = e.target.value;

    validateFormData(names, values);
    setContactInput({ ...ContactInput, [e.target.name]: e.target.value });
  }

  const handleSalutationInput = (e) =>{
    validateFormData('salutation', e.value);
    setContactInput({ ...ContactInput, ['salutation']: e.value });
  }

  const handleCountryInput = (e) =>{
    validateFormData('country_id', e.value);
    setContactInput({ ...ContactInput, ['country_id']: e.value });
  }

  const handleImageInput = (e) => {
    e.persist();

    let name = e.target.name;
    let value = e.target.value;

    const imageFile = e.target.files[0];
    setProfileImageArray(e.target.files[0]);
    setContactInput.profile_pic = imageFile;
    validateFormData(name, imageFile);

    setContactInput({ ...ContactInput, [e.target.name]: e.target.value });

  }


  function validateFormData(type, value) {
    if (type == 'salutation' && value == '') {
      setFormSalutationError(false);
      setSalutationError(true);
      setContactInput(ContactInput.error_list.salutation = 'This field is required');
    } else {
      setFormSalutationError(false);
      if (type == 'salutation' && value != '') {
        setSalutationError(false);
        setContactInput(ContactInput.error_list.salutation = '');
      }
    }

    if (type == 'first_name' && value == '') {
      setFormFirst_nameError(false);
      setFirst_nameError(true);
      setContactInput(ContactInput.error_list.first_name = 'This field is required');
    } else {
      setFormFirst_nameError(false);
      if (type == 'first_name' && value != '') {
        setFirst_nameError(false);
        setContactInput(ContactInput.error_list.first_name = '');
      }
    }

    if (type == 'last_name' && value == '') {
      setFormLast_nameError(false);
      setLast_nameError(true);
      setContactInput(ContactInput.error_list.last_name = 'This field is required');
    } else {
      setFormLast_nameError(false);
      if (type == 'last_name' && value != '') {
        setLast_nameError(false);
        setContactInput(ContactInput.error_list.last_name = '');
      }
    }

    if (type == 'primary_email' && validator.isEmail(value)) {
      setFormPrimary_emailError(false);
      setPrimary_emailError(false);
      setContactInput(ContactInput.error_list.primary_email = '');
    } else {
      setFormPrimary_emailError(false);
      if (type == 'primary_email' && value == '') {
        setPrimary_emailError(true);
        setContactInput(ContactInput.error_list.primary_email = 'This field is required');
      } else if (type == 'primary_email') {
        setPrimary_emailError(true);
        setContactInput(ContactInput.error_list.primary_email = 'Enter a valid e-mail address');
      }
    }

    if (type == 'secondry_email' && !validator.isEmail(value) && value != '') {
      setFormSecondry_emailError(false)
      setSecondry_emailError(false);
      setContactInput(ContactInput.error_list.secondry_email = 'Enter a valid e-mail address');
    } else if (type == 'secondry_email') {
      setFormSecondry_emailError(false)
      setSecondry_emailError(true);
      setContactInput(ContactInput.error_list.secondry_email = '');
    }

    if (type == 'title' && value == '') {
      setFormTitleError(false);
      setTitleError(true);
      setContactInput(ContactInput.error_list.title = 'This field is required');
    } else {
      setFormTitleError(false);
      if (type == 'title' && value != '') {
        setTitleError(false);
        setContactInput(ContactInput.error_list.title = '');
      }
    }
    // if (type=='secondry_email' && validator.isEmail(value)) {
    //   setFormSecondryEmailError(false);
    //   setSecondryEmailError(false);
    //   setContactInput(ContactInput.error_list.secondry_email = '');
    // }else{
    //   setFormSecondryEmailError(false);
    //   if(type=='secondry_email' && value!=''){
    //     setSecondryEmailError(true);
    //     setContactInput(ContactInput.error_list.secondry_email =  'Enter a valid e-mail address');
    //   }
    // }

    if (type == 'profile_pic' && !value.name.match(/\.(jpg|jpeg|png|gif)$/) && value != '') {
      setFormProfile_picError(false);
      setProfile_picError(true);
      setContactInput(ContactInput.error_list.profile_pic = 'Please select valid image.');
    } else if (type == 'profile_pic' && value.size / 1024 > 2048) {
      setFormProfile_picError(false);
      setProfile_picError(true);
      setContactInput(ContactInput.error_list.profile_pic = 'Please select image size less then 2MB.');
    } else {
      setFormProfile_picError(false);
      if (type == 'profile_pic' && profileImageArray) {
        setProfile_picError(false);
        setContactInput(ContactInput.error_list.profile_pic = '');
      }
    }

    if (type == 'address1' && value == '') {
      setFormAddress1Error(false);
      setAddress1Error(true);
      setContactInput(ContactInput.error_list.address1 = 'This field is required');
    } else {
      setFormAddress1Error(false);
      if (type == 'address1' && value != '') {
        setAddress1Error(false);
        setContactInput(ContactInput.error_list.address1 = '');
      }
    }

    var phonePattern = new RegExp(/^[0-9\b]+$/);
    if (type == 'phone1' && value == '') {
      setFormPhone1Error(false);
      setPhone1Error(true);
      setContactInput(ContactInput.error_list.phone1 = 'This field is required');
    } else if (type == 'phone1' && !phonePattern.test(value)) {
      setFormPhone1Error(false);
      setPhone1Error(true);
      setContactInput(ContactInput.error_list.phone1 = 'Please enter only number');
    } else if (type == 'phone1' && value.length != 10) {
      setFormPhone1Error(false);
      setPhone1Error(true);
      setContactInput(ContactInput.error_list.phone1 = 'Please enter valid phone number');
    } else {
      setFormPhone1Error(false);
      if (type == 'phone1' && value != '') {
        setPhone1Error(false);
        setContactInput(ContactInput.error_list.phone1 = '');
      }
    }

    if (type == 'phone2' && !phonePattern.test(value) && value != '') {
      setFormPhone2Error(false);
      setPhone2Error(true);
      setContactInput(ContactInput.error_list.phone2 = 'Please enter only number');
    } else if (type == 'phone2' && value.length != 10 && value != '') {
      setFormPhone2Error(false);
      setPhone2Error(true);
      setContactInput(ContactInput.error_list.phone2 = 'Please enter 10 digit phone number');
    } else {
      setFormPhone2Error(false);
      if (type == 'phone2' && value != '') {
        setPhone2Error(false);
        setContactInput(ContactInput.error_list.phone2 = '');
      }
    }

    if (type == 'city' && value == '') {
      setFormCityError(false);
      setCityError(true);
      setContactInput(ContactInput.error_list.city = 'This field is required');
    } else {
      setFormCityError(false);
      if (type == 'city' && value != '') {
        setCityError(false);
        setContactInput(ContactInput.error_list.city = '');
      }
    }

    if (type == 'state' && value == '') {
      setFormStateError(false);
      setStateError(true);
      setContactInput(ContactInput.error_list.state = 'This field is required');
    } else {
      setFormStateError(false);
      if (type == 'state' && value != '') {
        setStateError(false);
        setContactInput(ContactInput.error_list.state = '');
      }
    }

    if (type == 'country_id' && value == '') {
      setFormCountry_idError(false);
      setCountry_idError(true);
      setContactInput(ContactInput.error_list.country_id = 'This field is required');
    } else {
      setFormCountry_idError(false);
      if (type == 'country_id' && value != '') {
        setCountry_idError(false);
        setContactInput(ContactInput.error_list.country_id = '');
      }
    }

    if (type == 'zipcode' && value == '') {
      setFormzipcodeError(false);
      setzipcodeError(true);
      setContactInput(ContactInput.error_list.zipcode = 'This field is required');
    } else if (type == 'zipcode' && !phonePattern.test(value) && value != '') {
      setFormzipcodeError(false);
      setzipcodeError(true);
      setContactInput(ContactInput.error_list.zipcode = 'Please enter only number');
    } else if (type == 'zipcode' && value.length != 6) {
      setFormzipcodeError(false);
      setzipcodeError(true);
      setContactInput(ContactInput.error_list.zipcode = 'Please enter at least 6 number');
    } else {
      setFormzipcodeError(false);
      if (type == 'zipcode' && value != '') {
        setzipcodeError(false);
        setContactInput(ContactInput.error_list.zipcode = '');
      }
    }

  }

  const contactSubmit = (e) => {
    e.preventDefault();

    if (ContactInput.salutation == '') {
      setSalutationError(true);
      setFormSalutationError(true);
      salutationRef.current.focus();
    }

    if (ContactInput.first_name == '') {
      setFirst_nameError(true);
      setFormFirst_nameError(true);
      first_nameRef.current.focus();
    }

    if (ContactInput.last_name == '') {
      setLast_nameError(true);
      setFormLast_nameError(true);
      last_nameRef.current.focus();
    }

    if (ContactInput.primary_email == '') {
      setPrimary_emailError(true);
      setFormPrimary_emailError(true);
      primary_emailRef.current.focus();
    }


    if (ContactInput.address1 == '') {
      setAddress1Error(true);
      setFormAddress1Error(true);
      address1Ref.current.focus();
    }

    if (ContactInput.phone1 == '') {
      setPhone1Error(true);
      setFormPhone1Error(true);
      phone1Ref.current.focus();
    }

    if (ContactInput.state == '') {
      setStateError(true);
      setFormStateError(true);
      stateRef.current.focus();
    }

    if (ContactInput.city == '') {
      setCityError(true);
      setFormCityError(true);
      cityRef.current.focus();
    }

    if (ContactInput.country_id == '') {
      setCountry_idError(true);
      setFormCountry_idError(true);
      country_idRef.current.focus();
    }

    if (ContactInput.zipcode == '') {
      setzipcodeError(true);
      setFormzipcodeError(true);
      zipcodeRef.current.focus();
    }
    
    const formData = new FormData();
    formData.append('salutation', ContactInput.salutation)
    formData.append('first_name', ContactInput.first_name)
    formData.append('last_name', ContactInput.last_name)
    formData.append('title', ContactInput.title)
    formData.append('department', ContactInput.department)
    formData.append('description', ContactInput.description)
    formData.append('primary_email', ContactInput.primary_email)
    formData.append('secondry_email', ContactInput.secondry_email)
    formData.append('profile_pic', profileImageArray)
    formData.append('address1', ContactInput.address1)
    formData.append('address2', ContactInput.address2)
    formData.append('city', ContactInput.city)
    formData.append('state', ContactInput.state)
    formData.append('country_id', ContactInput.country_id)
    formData.append('zipcode', ContactInput.zipcode)
    formData.append('phone1', ContactInput.phone1)
    formData.append('phone2', ContactInput.phone2)
    formData.append('facebook_url', ContactInput.facebook_url)
    formData.append('twitter_url', ContactInput.twitter_url)
    formData.append('linkedin_url', ContactInput.linkedin_url)
    formData.append('instagram_url', ContactInput.instagram_url)
    
    if (!salutationError && !first_nameError && !last_nameError && !primary_emailError && !titleError && !address1Error
       && !cityError && !stateError && !country_idError && !zipcodeError && !phone1Error) {
      setSubmitEvent(true)
      axios.post("/api/contact/store", formData).then(response => {
        if (response.status === 200) {
          //swal("success", 'Contact has been added successfully', "success");
          toast.success('Contact has been created successfully');
          history.push('/contacts');
        }
      }).then(json => {

      }).catch(error => {
        if (error.response) {
          setSubmitEvent(false)
          if (error.response.status === 400) {
            setContactInput({ ...ContactInput, error_list: error.response.data.validation_errors });
          } else if(error.response.status === 403){
            toast.warning('Forbidden '+error.response.data.message);
            history.push('/contacts'); 
          }else {
            //swal("warning", error.response.data.message, "warning");
            toast.warning(error.response.data.message);
          }
        }

      });
    }


  }

  const customStyles = {
    menuList: styles => ({
      ...styles,
      color:'black',
      backgroundcolor:'$blue',
      paddingTop: 0,
      paddingBottom: 0,
  }),
   option: provided => ({
      ...provided,
      borderwidth:"0 0 1px 0px",
      bordercolor:"black",
      borderstyle:"solid",
      '&:hover':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:active':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:visited':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:selected':
      {
        outline: 'none',
        backgroundcolor:'none',
        color: 'white',
      },
      '&:default':
      {
        outline: 'none',
        backgroundcolor:'none',
        color: 'white',
      }
    }),
    control: provided => ({
      ...provided,
      border: "0px solid black",  
      '&:hover':
      {
         outline: 'none',
         backgroundcolor:'none',
      }
    }),
    singleValue: provided => ({
         ...provided,
        border: "0px solid black",   
        color:"black",
         '&:hover':
         {
            outline: 'none',
            backgroundcolor:'none',
         }
    }), 
  }

  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {

    // let salutationList = Object.keys(salutationData).map(function (key) {
    //   return (
    //     <option value={key} >{salutationData[key]}</option>
    //   )
    // }, this);

    let salutationList = [];
    salutationList.push({ label: 'Please select', value: '' });
    let salutationVarList = Object.keys(salutationData).map(function (key) {
      salutationList.push({label:salutationData[key],value:key});
    }, this);

    // let countriesList = countryData.length > 0 && countryData.map((item, i) => {
    //   return (
    //     <option key={i} value={item.id}>{item.country_name}</option>
    //   )
    // }, this);

    let countryList = [];
    countryList.push({ label: 'Please select', value: '' });
    for (var i = 0, l = countryData.length; i < l; i++) {
      countryList.push({ label: countryData[i].country_name, value: countryData[i].id });
    }

    let selectedCountry = [];
    for (var i = 0, l = countryData.length; i < l; i++) {
         if (getIpResCountryCode == countryData[i].country_code || getIpResCountryName == countryData[i].country_name) {
              selectedCountry.push({ label: countryData[i].country_name, value: countryData[i].id });
         }
    }

    return (
      <>
        <Row>
          <Col xs={12} xl={12}>
          <Row className="mb-3">
                  <Col md={6}>
                  </Col>
                  <Col md={6} className="text-right">
                    <button className="btn1 me-1" onClick={() => { history.goBack(); }}><span className="backarrow-icon"></span>Back</button>
                  </Col>
                </Row>
            <Card border="light" className="bg-white shadow-sm mb-2">
              <Card.Body className="borderline mb-5">
                <Row>
                <h5 className="mb-4">General information</h5>
                </Row>
                <Form onSubmit={contactSubmit} Encrypted="multipart/form-data">
                  <Row>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Salutation<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="salutation">
                          <Select
                          defaultValue=""
                          placeholder="Please select"
                          required
                          name="salutation"
                          options={salutationList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleSalutationInput}
                          ref={salutationRef}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                            },
                          })}
                          styles={customStyles}
                        />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.salutation}</span>
                      {formsalutationError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Last Name<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="last_name">
                          <Form.Control required type="text" name="last_name" onChange={handleInput} ref={last_nameRef} onBlur={handleInput} value={ContactInput.last_name} placeholder="Enter your last name" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.last_name}</span>
                      {formlast_nameError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Phone 1<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="phone1">
                          <Form.Control required type="text" name="phone1" value={ContactInput.phone1} ref={phone1Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter your phone 1" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.phone1}</span>
                      {formphone1Error ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Department
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="department">
                          <Form.Control type="text" name="department" value={ContactInput.department} ref={departmentRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter department" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.department}</span>
                      {formdepartmentError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                            <Form.Label column sm="3">
                            Description
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="description">
                          <Form.Control type="text" name="description" value={ContactInput.description} ref={descriptionRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter description" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.description}</span>
                      {formdescriptionError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  </div>
                  <div className="col-md-6 col-sm-6 right-form">
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            First Name<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="first_name">
                          <Form.Control required type="text" name="first_name" onChange={handleInput} ref={first_nameRef} onBlur={handleInput} value={ContactInput.first_name} placeholder="Enter your first name" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.first_name}</span>
                              {formfirst_nameError ? <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback> : <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Primary Email<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="primary_email">
                          <Form.Control required type="email" name="primary_email" value={ContactInput.primary_email} ref={primary_emailRef} onBlur={handleInput} onChange={handleInput} placeholder="Enter primary email" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.primary_email}</span>
                      {formprimary_emailError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Title<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="title">
                          <Form.Control required type="text" name="title" value={ContactInput.title} ref={titleRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter title" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.title}</span>
                      {formtitleError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                            <Form.Label column sm="3">
                            Image
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="profile-image">
                          <Form.Control type="file" name="profile_pic" ref={profile_picRef} onChange={handleImageInput} />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.profile_pic}</span>
                          </Col>
                  </Form.Group>
                  </div>
                  </Row>
                </Form>
                </Card.Body>

                <Card.Body className="borderline mb-5">
                <Form onSubmit={contactSubmit} Encrypted="multipart/form-data">
                  <Row>
                  <h5 className="mb-4">Address</h5>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Address 1<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="address1">
                          <Form.Control required type="text" name="address1" value={ContactInput.address1} ref={address1Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter your address 1" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.address1}</span>
                      {formaddress1Error ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Secondry Email
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="secondry_email">
                          <Form.Control type="email" name="secondry_email" value={ContactInput.secondry_email} ref={secondry_emaildRef} onBlur={handleInput} onChange={handleInput} placeholder="Enter secondry email" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.secondry_email}</span>
                      {formsecondry_emailError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            City<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="city">
                          <Form.Control required type="text" name="city" value={ContactInput.city} ref={cityRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your city" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.city}</span>
                      {formcityError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                            <Form.Label column sm="3">
                            ZIPcode<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="zip">
                          <Form.Control required type="text" name="zipcode" value={ContactInput.zipcode} ref={zipcodeRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your zipcode" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.zipcode}</span>
                      {formzipcodeError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  <div className="col-md-6 col-sm-6 right-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Address 2
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="address2">
                          <Form.Control type="text" name="address2" value={ContactInput.address2} ref={address2Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter your address 2" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.address2}</span>
                      {formaddress2Error ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Phone 2
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="phone2">
                          <Form.Control type="text" name="phone2" value={ContactInput.phone2} ref={phone2Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter your phone 2" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.phone2}</span>
                      {formphone2Error ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            State<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="state">
                          <Form.Control required type="text" name="state" value={ContactInput.state} ref={stateRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your state" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.state}</span>
                      {formstateError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                            <Form.Label column sm="3">
                            Select Country<span className="text-danger"> * </span>
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="country">
                          <Select
                            defaultValue={selectedCountry}
                            placeholder="Please select"
                            name="country_id"
                            options={countryList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleCountryInput}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                              },
                            })}
                            ref={country_idRef}
                        />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.country_id}</span>
                      {formcountry_idError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  </Row>
                </Form>
                </Card.Body>

                <Card.Body className="borderline mb-5">
                <Form onSubmit={contactSubmit} Encrypted="multipart/form-data">
                <Row>
                  <h5 className="mb-4">Social Media Information</h5>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Facebook URL
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="facebook_url">
                          <Form.Control type="text" name="facebook_url" value={ContactInput.facebook_url} ref={facebook_urlRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your facebook url" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.facebook_url}</span>
                      {formfacebook_urlError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                            <Form.Label column sm="3">
                            Linkedin URL
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="linkedin_url">
                          <Form.Control type="text" name="linkedin_url" value={ContactInput.linkedin_url} ref={linkedin_urlRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your linkedin url" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.linkedin_url}</span>
                      {formlinkedin_urlError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  <div className="col-md-6 col-sm-6 right-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                            <Form.Label column sm="3">
                            Twitter URL
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="twitter_url">
                          <Form.Control type="text" name="twitter_url" value={ContactInput.twitter_url} ref={twitter_urlRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your twitter url" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.twitter_url}</span>
                      {formtwitter_urlError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                            <Form.Label column sm="3">
                            Instagram URL
                           </Form.Label>
                          <Col sm="9">
                          <Form.Group id="instagram_url">
                          <Form.Control type="text" name="instagram_url" value={ContactInput.instagram_url} ref={instagram_urlRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your instagram url" />
                          </Form.Group>
                          <span className="error">{ContactInput.error_list.instagram_url}</span>
                      {forminstagram_urlError ? <span className="error">This field is required</span> : ''}
                          </Col>
                  </Form.Group>
                  </Row>
                  </div>
                </Row>
                </Form>
                </Card.Body>

                <Card.Body className="listcard1">
                <Form onSubmit={contactSubmit} Encrypted="multipart/form-data">
                  <div>
                    {
                        submitEvent ? 

                        <Button variant="primary" className="d-flex align-items-center mb-1">
                              <Spinner animation="border" size="sm" />
                              <span className="ms-2">Please Wait...</span>
                        </Button>
                        :
                        <Button variant="primary" type="submit" className="d-flex">Submit <span className="rightarrow"></span></Button>
                    }
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>


        </Row>
      </>
    );
  }
}

export default AddContact;


