
import React, { lazy, useState, Suspense  } from "react";
import MyThisMonthLead from "../../components/MyThisMonthLeads"
import MyThisMonthClosingDeal from "../../components/MyThisMonthClosingDeals"
import MyDashboardTask from "../../components/MyDashboardTask"

const MyDashboardActitivy = lazy(()=>import('../../components/MyDashboardActitivy'))

function DashboardList(props) {
  const [dealCount, setDealCount] = useState('');
  const [leadCount, setLeadCount] = useState('');
  const [stateTaskCount, setStateTaskCount] = useState('');
  const [stateActivity, setStateActivity] = useState('');
  
  const handleDealCount = (value) =>{
    setDealCount(value)
  }

  const handleLeadCount = (value) =>{
    setLeadCount(value)
  }

  const handleTaskCount = (value) =>{
    setStateTaskCount(value)
  }

  const handleActivityCount = (value) =>{
    setStateActivity(value)
  }

  return (
    <>
      {/*--- dashboard_four_rectangle ---*/}
      <div className="display row justify-content-between mt25">
        <div className="col-md-3">
          <div className="myreactangle">
            <div className="display align-items-start justify-content-between">
              <div className="col-md-6">
                <h3>My Deals</h3>
                <div className="dashboard_count mt-2">
                  <h2>{dealCount ? dealCount : 0}</h2>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="dashboardicon">
                  <span className="details_dollar_icon"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="myreactangle">
            <div className="display align-items-start justify-content-between">
              <div className="col-md-6">
                <h3>My Leads</h3>
                <div className="dashboard_count mt-2">
                  <h2>{leadCount ? leadCount : 0}</h2>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="dashboardicon">
                  <span className="leads-icon"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="myreactangle">
            <div className="display align-items-start justify-content-between">
              <div className="col-md-6">
                <h3>My Tasks</h3>
                <div className="dashboard_count mt-2">
                  <h2>{stateTaskCount? stateTaskCount : 0}</h2>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="dashboardicon">
                  <span className="calender-icon"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="myreactangle">
            <div className="display align-items-start justify-content-between">
              <div className="col-md-6">
                <h3>My Activities</h3>
                <div className="dashboard_count mt-2">
                  <h2>{stateActivity ? stateActivity : 0}</h2>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="dashboardicon">
                  <span className="activity-icon"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*--- table_section_start ---*/}
      <div className="row">
        <MyDashboardTask handleTaskCount={handleTaskCount}/>
        <Suspense fallback={<div></div>}>
          <MyDashboardActitivy handleActivityCount={handleActivityCount}/>
        </Suspense>
      </div>
      <div className="row">
        <MyThisMonthClosingDeal handleDealCount={handleDealCount}/>
        <MyThisMonthLead handleLeadCount={handleLeadCount}/>
      </div>
    </>
  );


};
export default DashboardList;
