import React, { useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { useHistory, } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/PreloaderCenter";
import { toast } from 'react-toastify';
import axios from 'axios';
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { selectUser,selectDefaultData  } from "../../features/userSlice";
import moment from "moment-timezone";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Checkbox from "react-custom-checkbox";


var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var searchData = '';
var searchInputDataName = '';
var searchInputDataEmail = '';
var searchInputDataFromDate = '';
var searchInputDataToDate = '';
var searchInputDataTitle = '';
var searchInputDataStatus = '';
var searchInputDataPhone = '';
var searchInputDataOrganizationName = '';

function ActivityList(props) {

  const loginUser = useSelector(selectUser);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
  const [mainChecked, setMainChecked] = useState(false);

  const user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [search, setSearch] = useState('');
  const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
  const [searchString, setSearchString] = useState();
  
  if (props.location.pathname == '/activity-logs') {
    if (props.location.statess) {
      searchData = props.location.statess.search;
    }

    if (props.location.searchData) {
      searchInputDataName = props.location.searchData.search_name;
      searchInputDataEmail = props.location.searchData.search_email;
      searchInputDataFromDate = props.location.searchData.search_from_date;
      searchInputDataToDate = props.location.searchData.search_to_date;
      searchInputDataTitle = props.location.searchData.search_title;
      searchInputDataStatus = props.location.searchData.search_status;
      searchInputDataPhone = props.location.searchData.search_phone;
      searchInputDataOrganizationName = props.location.searchData.search_organization_name;
    }
  }

  //==============START: Handle new search filter option==================//


  const handleSearchInput = (e) => {
    setSearchString(e.target.value);
  }

  const manageSearch = (e) =>{
    e.preventDefault();
    setSearch(searchString)
  }

  //==============END: Handle new search filter option==================//

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState('');
  const [state, setData] = useState({
    users: ''
  });

  const sortColumn = ''

  const handlePerPage = (Page) => {
    setLoading(true);
    currentPage = 1;
    perPage = Page;
    getData();
  }
  //=========END:CODE FOR FOOTER PAGINATION===========//

  var calendorFormate = '';
  useEffect(() => {
    setLoading(true);
    getData();

  }, [sortColumn, sortOrder, search, searchInputDataName, searchInputDataEmail, searchInputDataFromDate, searchInputDataToDate, searchInputDataTitle, searchInputDataStatus, searchInputDataPhone, searchInputDataOrganizationName]);

  const getData = async (currentPage) => {

    const params = {
      search: search,
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,

    }

    await axios.get(`api/activity-log?page=${currentPage}`, { params }).then(response => {
      if (response.status === 200) {
        setListData(response.data.data.records.data);
        setdataCount(response.data.data.records.data.length);
        setData({
          users: response.data.data.records
        });
        setPreferredDateFormatData(response.data.data.preferredDateFormat);
        setLoading(false);
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
          toast.warning('Forbidden '+ error.response.data.message);
          history.push('/dashboard');
        } else {
          toast.error(error.response.data.message);
          history.push('/dashboard');
        }
      }
    });
  }

 
  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  }
  
  //============Start:- Handle check all ================//
  const handleCheckbox = (value, id) => {
    if (value) {
      setIsCheck(isCheck => [...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter(x => x !== id));
    }
  }

  const handleCheckAll = (value) => {
    if(value){
      setMainChecked(true)
    }else{
      setMainChecked(false)
    }

    setIsCheckBoxCheck(value);
    setIsCheck([]);

    if (value) {
      setIsCheckBoxCheck(value);
      setIsCheck(listData.map(li => li.id));
    }
  }
  //============End:- Handle check all ================//

  var displayUserData = '';

  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayUserData = listData.map((item, i) => {
      // if(item.oldValue=='' && item.newValue==''){

      // }else{
        let sortName = '';
        let FullName = '';

        if (item.added_by) {
          if (item.added_by.name) {
            FullName = item.added_by.name;
            let expName = item.added_by.name.split(' ');

            if (expName.length == 2) {
              sortName = expName[0].charAt(0) + expName[1].charAt(0);
            } else {
              sortName = expName[0].charAt(0);
            }

          }
        }

        let displayModelName = ''
        if(item.modelName=='ProductInterestDir'){
          let UrlForExp = item.url.split('lead')
          let modelname = 'Deal'
          let actionType = 'Added'
          if(UrlForExp.length==2){
            modelname = 'Lead'
          }
          if(item.event=='updated' || item.event=='deleted'){
            actionType = 'Remove'
          }
          displayModelName =  'Product '+actionType+' on '+modelname
        }


        let oldValueList = Object.entries(item.oldValue?item.oldValue:[]).map(([key, value]) => {
          if(value){
            return (
              <div key={key}>
                <div className="d-flex">
                  
                  <b style={{textTransform: 'capitalize'}}>{key.replace("_", " ")} : </b>
                  { 
                    key=='permission_name' ?
                    value.map(function (keys) {
                      return (
                        <>  
                            <p>{keys}, </p>
                        </>
                      )
                    })
                    :
                    <>
                      <p>{value}</p>
                    </>
                  }
                </div>
              </div>
            )
          }
        }, this);
       
        let newValueList = Object.entries(item.newValue?item.newValue:[]).map(([key, value]) => {
          if(value){
            return (
              <div key={key}>
                <div className="d-flex">
                  
                <b style={{textTransform: 'capitalize'}}>{key.replace("_", " ")} : </b>
                  { 
                    key=='permission_name' ?
                    value.map(function (keys) {
                      return (
                        <>  
                            <p>{keys}, </p>
                        </>
                      )
                    })
                    :
                    <>
                      <p>dsfs</p>
                    </>
                  }
                </div>
              </div>
            )
          }
        }, this);

        
        return (
          <div className="list_view bordertop pt16 pb18" key={i}>
            {/*--- checkmark_section ---*/}
            <div className="chbox">
              <label className="container">
                <Checkbox
                  icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                  name="activity[]"
                  checked={isCheckBoxCheck}
                  value={item.id}
                  onChange={(value) => {
                    let p = {
                      isTrue: value,
                    };
                    handleCheckbox(value, item.id)
                  }}
                  borderColor="#cccccc"
                  className="checkmark"
                  style={{ cursor: "pointer" }}
                  labelStyle={{ marginLeft: 5, userSelect: "none", 'font-weight': 'normal' }}
                  label=""
                />
                <span className="checkmark-----"></span>
              </label>
            </div>

            {/*--- image_and_name_section ---*/}
            <div className="listdetails">
              <Stack direction="row" spacing={3}>
                <Avatar
                  alt={FullName}
                  src={`${process.env.REACT_APP_API_URL}storage/${item.contact_name ? item.contact_name.profile_pic ? item.contact_name.profile_pic : '' : ''}`}
                  sx={{ width: 76, height: 76 }}
                  onError={addDefaultSrc}
                  className="listimg"
                >
                  {sortName}
                </Avatar>
              </Stack>

              {/*---details_section ---*/}
              <div className="leaddetails">
                <h5 style={{ cursor: 'pointer' }}>{FullName ? FullName : 'N/A'}</h5>

                {/*--- lead_inner1_section */}
                <div className="leadinner">
                  <div className="display flex-wrap align-items-start">
                    {/*--- lead_title_section */}
                    <div className="leadtitle display align-items-start">
                      <span>Type:</span>
                      <h6>{item.event ? item.event : ''}</h6>
                    </div>
                    {/*--- lead_company_section */}
                    <div className="leadtitle display align-items-start">
                      <span>Ip Address:</span>
                      <h6>{item.ip_address ? item.ip_address : 'N/A'}</h6>
                    </div>
                    <div className="leadtitle display align-items-start">
                      <span>Action:</span>
                      <h6>{displayModelName ? displayModelName : item.modelName ? item.modelName+' '+item.event : 'N/A'}</h6>
                    </div>
                  </div>
                </div>

                {/*--- lead_inner2_section */}
                <div className="leadinner">
                  <div className="display flex-wrap align-items-start">
                    <div className="leadtitle display align-items-start">
                        <span>Old value:</span>
                        <p>
                          {oldValueList}
                        </p>
                      </div>
                  </div>
                </div>
                <div className="leadinner">
                  <div className="display flex-wrap align-items-start">
                    <div className="leadtitle display align-items-start">
                        <span>New value:</span>
                        <p>{newValueList}</p>
                      </div>
                  </div>
                </div>
              </div>

            </div>

            {/*--- owner_section ---*/}
            <div className="listowner">

              <div className="leadinner">
                <div className="display flex-wrap">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display">
                    <span>{item.added_by != null ? item.added_by.name : 'N/A'}</span>
                  </div>
                </div>
              </div>

              <div className="leadinner">
                <div className="display flex-wrap">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display">
                    <span>Created Date:</span>
                    <h6>{moment.tz(item.created_at, defaultTimeZone).format(preferredDateFormatData)}</h6>
                  </div>
                </div>
              </div>
            </div>

            {/*--- action_section ---*/}
            <div className="listaction text-right">
              
            </div>
          </div>
        );

      //}

    });

    return (
      <>
        <div className="searchbar mt30">
          <div className="col-md-4">
            <form onSubmit={manageSearch}>
                <input type="text" name="searchData" className="form-control search" value={searchString} placeholder="Search this list...." onChange={handleSearchInput} ></input><span className="listicon" onClick={manageSearch}></span>
              </form>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-6 display justify-content-end">
          </div>
        </div>
        {/*--- searchbar_section_end ---*/}

        {/*--- list_view_section_start ---*/}
        <div className="display list_view mt30 pb4">
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="checkAll"
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckAll(value)
                }}
                checked={mainChecked}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                label=""
              />
              {/* <span className="checkmark------"></span> */}
            </label>
          </div>

          {/*--- details_section ---*/}
          <div className="listdetails">
            <h3>Activity Details</h3>
          </div>
          {/*--- owner_section ---*/}
          <div className="listowner">
            <h3>Owner</h3>
          </div>
          {/*--- action_section ---*/}
          <div className="listaction">
            
          </div>
        </div>


        {
          displayUserData.length >= 1 ?

            displayUserData

            :

            <span>
              No data Available
            </span>
        }
        {
          displayUserData.length >= 1 ?

            <Row>
              <Col xs={12} xl={12} className="showing">
                <Card border="light" className="bg-white border-0">
                  <Card.Body className="listcard pe-0">
                    <Row className="align-items-center">
                      <Col md={3} className="ps-2">
                        <h6>
                          Showing <b>{(((state?.users?.per_page ? state?.users?.per_page : 0) * (state?.users?.current_page ? state?.users?.current_page : 0)) - ((state?.users?.per_page ? state?.users?.per_page : 0) - 1))}</b> - <b>{(((state?.users?.per_page ? state?.users?.per_page : 0) * (state?.users?.current_page ? state?.users?.current_page : 0)) - ((state?.users?.per_page ? state?.users?.per_page : 0) - 1) + ((dataCount ? dataCount : 0) - 1))}</b> of <b>{state?.users?.total ? state?.users?.total : 0}</b>
                        </h6>
                      </Col>
                      <Col xl={4} md={4}>
                        <div className="row align-items-center g-0">
                          <div className="col-md-3 col-xl-4"></div>
                          <div className="col-md-2 col-xl-3">
                            <h6 className="text-right me-3">Per page</h6>
                          </div>
                          <div className="col-md-4 col-xl-4">
                            <select className="border-start-0 border-end-0 border-top-0 borderbottom w-50" value={perPage} onChange={(e) => {handlePerPage(e.target.value);handleCheckAll(false)}}>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                          <div className="col-md-2 col-xl-1"></div>
                        </div>
                      </Col>
                      <Col md={5} className="d-flex justify-content-end">
                        <Pagination
                          activePage={state?.users?.current_page ? state?.users?.current_page : 0}
                          itemsCountPerPage={state?.users?.per_page ? state?.users?.per_page : 0}
                          totalItemsCount={state?.users?.total ? state?.users?.total : 0}
                          onChange={(pageNumber) => {
                            currentPage = (pageNumber);
                            getData(pageNumber)
                            handleCheckAll(false)
                          }}
                          pageRangeDisplayed={5}
                          itemClass="page-item mx-1"
                          linkClass="page-link"
                        // firstPageText="First Page"
                        // lastPageText="Last Page"
                        />
                      </Col>
                    </Row>

                  </Card.Body>
                </Card>
              </Col>
            </Row>

            :
            ''
        }
      </>
    );

  }



};


export default ActivityList;