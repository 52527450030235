import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';

import "react-datepicker/dist/react-datepicker.css";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Dropdown, Form } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AccountDealList from "../../components/AccountDealList"
import AccountContactList from "../../components/AccountContactList"
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';

import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import EasyEdit, { Types } from "react-easy-edit";


function EditAccount(props) {
     const history = useHistory();
     const loginUser = useSelector(selectUser);
     const [isEditable, setIsEditable] = useState(true)
     const [setDealId, setSetDealId] = useState()
     const [allData, setAllData] = useState([])

     const [orgNameErrorMsg, setOrgNameErrorMsg] = useState();
     const [orgEmailErrorMsg, setOrgEmailErrorMsg] = useState();
     const [orgWebsiteErrorMsg, setOrgWebsiteErrorMsg] = useState();
     const [orgPhone1ErrorMsg, setOrgPhone1ErrorMsg] = useState();
     const [orgPhone2ErrorMsg, setOrgPhone2ErrorMsg] = useState();

     const user = loginUser?loginUser:[];

     const userPermissions = useSelector(state =>
          state.permission
     )
     
     const userPermission = userPermissions?userPermissions:[];
     
     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

     const [dataID, setDataID] = useState(props.match.params.id);
     const [updateLeadData, setUpdateLeadData] = useState(false);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState('');

     //=========START:CODE FOR EDIT LEAD DATA==========//

     const [loading, setLoading] = useState(true);
     const [dataRecord, setDataRecord] = useState('');

     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     //========Const data for Activity (Lead and Deal)
     var calendorFormate = '';
     useEffect(() => {
          async function fetchData() {
               await getAccount();
          }
          fetchData();
     }, [updateLeadData]);


     async function getAccount() {
          await axios.get('api/account/edit/' + dataID).then(response => {
               if (response.data.code === 200) {
                    setAllData(response.data.data)
                    setDataRecord(response.data.data.record);
                    setPreferredDateFormatData(response.data.data.preferredDateFormat);
                    if (response.data.data.record && response.data.data.record.deal_id) {
                         setSetDealId(response.data.data.record.deal_id)
                    }
                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setLoading(false);
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/accounts');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/accounts');
          });
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }

     const handleSaveData = async(value, name) => {
          const data = {
               id: dataID,
               organization_name: dataRecord.organization_name,
               organization_email: dataRecord.organization_email,
               organization_phone1: dataRecord.organization_phone1,
               organization_phone2: dataRecord.organization_phone2,
               organization_website: dataRecord.organization_website,
               [name]: value,
          }
          //setLoading(true);
          if(value){
               await axios.post("api/account/update", data).then(response => {
                    if (response.status === 200) {
                         updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         setIsEditable(true)
                         toast.success('Data updated successfully');
                    }
                    setLoading(false);
               }).then(json => {
     
               }).catch(error => {
                    if (error.response) {
                         setLoading(false);
                         setIsEditable(true)
                         if (error.response.status === 400) {
                              setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         } else if (error.response.status === 403) {
                              //swal("error", error.response.data.message, "error");
                              toast.error('Forbidden ' + error.response.data.message);
                         } else if (error.response.status === 300) {
                              toast.warning(error.response.data.message);
                         } else {
                              toast.error('Oops Something went wrong.');
                         }
                    }
     
               });
          }else{
               updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
               setErrorInput({ ...ErrorInput, error_list: '' });
               setIsEditable(true)
          }
     };

     const cancel = () => {
          setIsEditable(true)

          setOrgNameErrorMsg('');
          setOrgEmailErrorMsg('');
          setOrgWebsiteErrorMsg('');
          setOrgPhone1ErrorMsg('');
          setOrgPhone2ErrorMsg('');
     };


     const handleClick = (e) => {
          setIsEditable(false)
     }


     async function deleteRecord(id) {
          const data = {
            id: id,
          };
        
          try {
            const willDelete = await swal({
              title: "Are you sure you want to delete?",
              text: "Once deleted, you will not be able to recover this data!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
        
            if (willDelete) {
              try {
                const response = await axios.delete('api/account/destroy', { data });
        
                if (response.status === 200) {
                    toast.success(response.data.message);
                    history.push('/accounts')
                }
              } catch (error) {
                if (error.response) {
                  if (error.response.status === 400) {
                    toast.warning(error.response.data.message);
                  } else if (error.response.status === 403) {
                    toast.warning('Forbidden ' + error.response.data.message);
                  } else {
                    toast.error(error.response.data.message);
                  }
                } else {
                  toast.error("Oops Something went wrong.");
                }
              }
            }
          } catch (error) {
            toast.error("Error in swal: ", error);
          }
        }
     
     if (loading) {
          return <Preloader show={loading ? false : true} />;
     } else {
          let FFName = ''
          let Fullname = ''
          let expName = ''

          if (allData) {
               if (allData.userData) {
                    FFName = allData.userData.name ? allData.userData.name : '';
                    if (FFName) {
                         Fullname = FFName
                         expName = FFName.split(' ');
                         if (expName.length == 2) {
                              FFName = expName[0].charAt(0) + expName[1].charAt(0);
                         } else {
                              FFName = expName[0].charAt(0);
                         }
                    }
               }
          }

          let fullTitleName = dataRecord.organization_name
          let sortName = dataRecord.organization_name;
          let expOrName = dataRecord ? dataRecord.organization_name ? dataRecord.organization_name.split(' '):'':''
          
          if (expOrName.length == 2 || expOrName.length > 2) {
               sortName = expOrName[0].charAt(0) + expOrName[1].charAt(0);
          } else {
               if(expOrName.length){
                    sortName = expOrName[0].charAt(0);
               }
          }

          return (

               // <Row>
               <div className="display flex-wrap">

                    {/*--- client_information_section ---*/}
                    <Col md={12} className="client contanctlist activity mt30">
                         {/*--- client_name_and_convert_lead_section ---*/}
                         <div className="client_details display">
                              {/*--- client_image_and_deatils_section ---*/}
                              <div className="col-md-6 display">
                                   <div className="client_image display">
                                        <Stack direction="row" spacing={3}>
                                             <Avatar
                                                  alt={fullTitleName}
                                                  src={`${process.env.REACT_APP_API_URL}`}
                                                  sx={{ width: 50, height: 50 }}
                                                  onError={addDefaultSrc}
                                                  className="clientimg"
                                             >
                                                  {sortName}
                                             </Avatar>
                                        </Stack>

                                        <div className="client_name ml20">
                                             <div className="display">
                                                  <h5>{fullTitleName}</h5>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="col-md-6 display justify-content-end">
                                   {/*--- client_update_time_and_date_section ---*/}
                                   <div className="client_update">
                                        <div className="client_txt display justify-content-end">
                                             <span>Last update</span>
                                        </div>
                                        <div className="client_date_and_time display justify-content-end">
                                             <span>{moment.tz(dataRecord.updated_at, defaultTimeZone).format(preferredDateFormatData)}</span>
                                        </div>
                                   </div>
                                   {/*--- client_action_section ---*/}
                                   <div className="client_action text-right">

                                        <Dropdown>
                                             <Dropdown.Toggle id="dropdown-basic" className="dropdownborder1 dothover">
                                                  <span className="dot"></span>
                                             </Dropdown.Toggle>
                                             {
                                                  userPermission ?
                                                  (userPermission.includes('account_delete')) ?
                                                  <Dropdown.Menu>
                                                       <Dropdown.Item onClick={() => { deleteRecord(dataID) }}>Delete</Dropdown.Item>
                                                  </Dropdown.Menu>
                                                       :
                                                       ''
                                                  :
                                                  ''
                                                  }
                                             
                                        </Dropdown>
                                   </div>
                              </div>
                         </div>
                    </Col>



                    {/*--- Lead_information_section ---*/}
                    <Col md={12} className="mt30">
                         <div className="display w-100">
                              <Accordion defaultActiveKey="0" className="w-100">
                                   <Accordion.Item eventKey="0">
                                        <Accordion.Header><h4>Account Information</h4></Accordion.Header>
                                        <Accordion.Body>
                                             <div className="display align-items-start flex-wrap">
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Company name</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.organization_name}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_name') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 50) {
                                                                                          setOrgNameErrorMsg('Only 50 characters are allowed')
                                                                                          return false
                                                                                     } else if (value && value.length < 2) {
                                                                                          setOrgNameErrorMsg('Enter at least 2 characters.');
                                                                                          return false
                                                                                     } else if ((value && value.length == 2) && (value && splString.length >= 2)) {
                                                                                          setOrgNameErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     setOrgNameErrorMsg('Enter company name.');
                                                                                     return false
                                                                                }

                                                                           }}
                                                                           validationMessage={orgNameErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.organization_name}</span>
                                                            </div>


                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Company phone1 </Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.organization_phone1}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_phone1') }}
                                                                           onValidate={value => {
                                                                                if (value && value.length > 25) {
                                                                                     setOrgPhone1ErrorMsg('Allow 25 characters only.')
                                                                                     return false
                                                                                } else {
                                                                                     return true
                                                                                }

                                                                           }}
                                                                           validationMessage={orgPhone1ErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 25 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.organization_phone1}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Company website </Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.organization_website}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_website') }}
                                                                           onValidate={value => {
                                                                                const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                                                                if (value) {
                                                                                     if (value.length > 50) {
                                                                                          setOrgWebsiteErrorMsg('Only 50 characters are allowed');
                                                                                          return false
                                                                                     } else if (value && !(regex.test(value))) {
                                                                                          setOrgWebsiteErrorMsg('Enter valid website');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }

                                                                           }}
                                                                           validationMessage={orgWebsiteErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.organization_website}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Company email</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.organization_email}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_email') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     if (value.length > 50) {
                                                                                          setOrgEmailErrorMsg('Only 50 characters are allowed');
                                                                                          return false
                                                                                     } else if (value && !(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value))) {
                                                                                          setOrgEmailErrorMsg('Enter valid email address');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     setOrgEmailErrorMsg('Enter company email.');
                                                                                     return false
                                                                                }
                                                                           }}
                                                                           validationMessage={orgEmailErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.organization_email}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Company phone2 </Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.organization_phone2}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_phone2') }}
                                                                           onValidate={value => {
                                                                                if (value && value.length > 25) {
                                                                                     setOrgPhone2ErrorMsg('Allow 25 characters only.')
                                                                                     return false
                                                                                } else {
                                                                                     return true
                                                                                }

                                                                           }}
                                                                           validationMessage={orgPhone2ErrorMsg}

                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 25 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.organization_phone2}</span>
                                                            </div>

                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display align-items-start">
                                                                      <Form.Label className="text-right w-17">Created by</Form.Label>
                                                                      <div className="fontdisabled display flex-wrap">
                                                                           <p className="disabledrecord">{Fullname}</p>
                                                                           <p className="fontsbreak">{moment.tz(dataRecord.created_at, defaultTimeZone).format(preferredDateFormatData)}</p>
                                                                      </div>
                                                                 </Form.Group>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </Accordion.Body>
                                   </Accordion.Item>
                              </Accordion>
                         </div>
                    </Col>

                    {
                         dataID ?
                              userPermission ?
                                   (userPermission.includes('deal_list')) ?
                                        <AccountDealList data={{ 'accountId': dataID }} />
                                   :
                                   ''
                              :
                              ''
                         :
                         'null'
                    }

                    {
                         dataID ?
                              userPermission ?
                                   (userPermission.includes('contact_list')) ?
                                        <AccountContactList data={{ 'accountId': dataID }} />
                                   :
                                   ''
                              :
                              ''
                         :
                         'null'
                    }



                    {/* </Row> */}
               </div>

          );
     }
};

export default EditAccount;
