import React, { useState, memo } from "react";
import Preloader from "./PreloaderCenter";
import ReactECharts from 'echarts-for-react';

function DashboardFunnel(props) {
  const { funnelChartData } = props;
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false) 
  }, 100);

  const option = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'item',
      //formatter: '{a} <br/>{b} : {c}%'
      formatter: ''
    },
    toolbox: {
      feature: {
        //dataView: { readOnly: false },
        //restore: {},
        saveAsImage: {}
      }
    },
    legend: {
      data: ['Leads', 'Deals', 'Won Deals']
    },
    series: [
      {
        name: 'Total',
        type: 'funnel',
        left: '10%',
        top: 60,
        bottom: 60,
        width: '80%',
        min: 0,
        max: 100,
        minSize: '0%',
        maxSize: '100%',
        sort: 'descending',
        gap: 2,
        label: {
          show: true,
          position: 'inside'
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: 'solid'
          }
        },
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1
        },
        emphasis: {
          label: {
            fontSize: 20
          }
        },
        data: [
          { value: funnelChartData.leadCount?funnelChartData.leadCount:0, name: 'Leads' },
          { value: funnelChartData.dealCount?funnelChartData.dealCount:0, name: 'Deals' },
          { value: funnelChartData.wondeal?funnelChartData.wondeal:0, name: 'Won Deals' },
        ]
      }
    ]
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {

    return (

      <div className="display flex-wrap w-100 reportborder mt30">
      <div className="col-md-12">
        <div className="flex-wrap task align-items-start justify-content-end">
        <div className="innerdealtable w-100">
       <div className="display">
        <div className="col-md-12">
        <h4>Number of Leads and Deals</h4>
        </div>
       </div>
      </div>
          <ReactECharts
            option={option}
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
            onChartReady={''}
            onEvents={''}
            opts={''}
          />
          </div>
        </div>
        </div>
    )
  }
}

export default memo(DashboardFunnel);


