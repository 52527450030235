export const Routes = {
    // pages
    Presentation: { path: "/", exact:true, name: 'Home' },
    //Presentation: { path: "/", exact:true, name: 'Signin' },
    DashboardOverview: { path: "/dashboard",exact:true, name: 'Dashboard' },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    
    Signin: { path: "/sign-in", exact:true, name: 'Signin' },
    Signup: { path: "/sign-up", exact:true, name: 'Signup' },
    ForgotPassword: { path: "/forgot-password", exact:true, name: 'ForgotPassword' },
    ResetPassword: { path: "/reset-password/:token", exact:true, name: 'ResetPassword' },
    ResendEmailPassword: { path: "/resend-email-password", exact:true, name: 'ResendEmailPassword' },
    VerifyEmail: { path: "/verify-email/:id/:token", exact:true, name: 'VerifyEmail' },

    TenantInvitation: {path: "/invitation/:id/:email", exact:true, name: 'TenantInvitation'},
    Workspace: { path: "/workspace", exact:true, name: 'Workspace' },

    Lock: { path: "/lock" },
    NotFound: { path: "/404", exact:true, name: 'NotFound' },
    ServerError: { path: "/500", exact:true, name: 'ServerError' },

    UserList: {path:"/users", exact:true, name:'Users'},
    Profile: {path:"/profile", exact:true, name:'Profile'},
    AddUser: { path: "/add-user", exact:true, name:'AddUser' },
    EditUser: { path: "/edit-user/:id", exact:true, name:'EditUser' },
    CompanySetting: {path:"/settings", exact:true, name:'CompanySetting'},
    Checkout: {path:"/checkout", exact:true, name:'Checkout'},
    
    Order: {path:"/orders", exact:true, name:'Orders'},
    ViewOrder: {path:"/view-order/:id", exact:true, name:'ViewOrder'},
    

    RoleList: {path:"/roles", exact:true, name:'Roles'},
    AddRole: { path: "/add-role", exact:true, name:'AddRole' },
    EditRole: { path: "/edit-role/:id", exact:true, name:'EditRole' },

    StageList: {path:"/stages", exact:true, name:'Stages'},
    AddStage: { path: "/add-stage", exact:true, name:'AddStage' },
    EditStage: { path: "/edit-stage/:id", exact:true, name:'EditStage' },

    ProductList: {path:"/products", exact:true, name:'Products'},
    AddProduct: { path: "/add-product", exact:true, name:'AddProduct' },
    EditProduct: { path: "/edit-product/:id", exact:true, name:'EditProduct' },

    ContactList: {path:"/contacts", exact:true, name:'Contacts'},
    AddContact: { path: "/add-contact", exact:true, name:'AddContact' },
    EditContact: { path: "/edit-contact/:id", exact:true, name:'EditContact' },

    AccountList: {path:"/accounts", exact:true, name:'Accounts'},
    AddAccount: { path: "/add-account", exact:true, name:'AddAccount' },
    EditAccount: { path: "/edit-account/:id", exact:true, name:'EditAccount' },

    LeadList: {path:"/leads", exact:true, name:'Leads'},
    AddLead: { path: "/add-lead", exact:true, name:'AddLead' },
    EditLead: { path: "/edit-lead/:id", exact:true, name:'EditLead' },
    CloneLead: {path: "/clone-lead/:id", exact:true, name:'CloneLead'},

    DealList: {path:"/deals", exact:true, name:'Deals'},
    AddDeal: { path: "/add-deal", exact:true, name:'AddDeal' },
    EditDeal: { path: "/edit-deal/:id", exact:true, name:'EditDeal' },
    CloneDeal: {path: "/clone-deal/:id", exact:true, name:'CloneDeal'},

    TaskList: {path:"/tasks", exact:true, name:'Tasks'},
    EditTask: { path: "/edit-task/:id", exact:true, name:'EditTask' },

    ActivityList: {path:"/activities", exact:true, name:'Activity'},
    EditActivity: { path: "/edit-activity/:id", exact:true, name:'EditActivity'},

    // OrderList: {path:"/order", exact:true, name:'Order'},
    // AddOrder: { path: "/add-subscription", exact:true, name:'AddOrder' },
    // ViewOrder: { path: "/view-subscription/:id", exact:true, name:'ViewOrder'},
    // EditOrder: { path: "/renew-subscription", exact:true, name:'EditOrder'},

    QuoteList: {path:"/quotes", exact:true, name:'Quotes'},
    EditQuote: {path: "/edit-quote/:id", exact:true, name:'EditQuote'},
    CreateQuote: {path: "/create-quote", exact:true, name:'CreateQuote'},
    CreateCloneQuote: {path: "/create-clone-quote/:id", exact:true, name:'CreateCloneQuote'},

    ReportList: {path:"/reports", exact:true, name:'Reports'},

    SalesQuotaList: {path:"/sales-quota", exact:true, name:'SalesQuota'},

    LogActivityList: {path:"/activity-logs", exact:true, name:'LogActivity'},

    ActivityReport: {path:"/activities-report", exact:true, name:'ActivityReport'},

    NotificationList: {path:"/notification", exact:true, name:'Notification'},

    SourceList: {path:"/sources", exact:true, name:'Sources'},
    AddSource: { path: "/add-source", exact:true, name:'AddSource' },
    EditSource: { path: "/edit-source/:id", exact:true, name:'EditSource' },

    UserTrackingList: {path:"/track-users", exact:true, name:'UserTracking'},

};