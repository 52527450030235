import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Preloader1 from "../../components/Preloader1";
import { toast } from 'react-toastify';
import logo from "../../assets/img/color_main_logo.png"
import axios from 'axios';


function VerifyEmail() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const pathname = window.location.pathname;
  const getArray = pathname.split('/');

  const id = getArray[2];
  const token = getArray[3];

  const search = useLocation().search;
  const expires = new URLSearchParams(search).get('expires');
  const signature = new URLSearchParams(search).get('signature');

  var messages = 'Please wait we are creating your tenant panel....';

  const data = {
    expires: expires,
    signature: signature,
  }

  useEffect(() => {
    verifyEmailData();
  }, []);

  function verifyEmailData() {
    axios.get("/api/verify-email/" + id + "/" + token, data).then(response => {
      if (response.status === 200) {
        toast.success(response.data.message);
        history.push('/sign-in');
        setTimeout(() => {
          window.location.reload(true);
        }, 100);
        setLoading(false);
      }

    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
          history.push(window.location.href = process.env.REACT_APP_MARKETING_WEB_URL);
        } else if (error.response.status === 500) {
          toast.error('Oops Something went wrong.');
        } else if (error.response.status === 403) {
          toast.success(error.response.data.message);
          history.push('/sign-in');
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          history.push(window.location.href = process.env.REACT_APP_MARKETING_WEB_URL);
        }

      }
    });
  }


  if (loading) {
    return <Preloader1 show={true} message={messages} />;
  } else {
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 common-form">
          <Container>
            <Row className="justify-content-center">
              <div className="title text-center mb40">
                <a href={process.env.REACT_APP_MARKETING_WEB_URL}><img src={logo} alt="signlogo" className="signlogo" style={{ cursor: 'pointer' }} /></a>
              </div>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white w-100 box-form text-center">
                  <h3>Verify Email</h3>
                  <p className="mb-5"></p>
                  <Form>
                    <Button variant="primary" type="submit" className="w-100">
                      Click to verify Email
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }


};
export default VerifyEmail;
