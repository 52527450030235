/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row, Dropdown, Card } from "@themesberg/react-bootstrap";
import Preloader from "../components/PreloaderCenter";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import Pagination from "react-js-pagination";
import "react-edit-text/dist/index.css";
import "../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { displayDate } from '../utils/utils';

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;
var dealId = "";
var currencyCode = "";
var currencySymbol = "";
var calendorFormate = "";

export default (props) => {
     dealId = props.data.dealid;
     const history = useHistory();
     const loginUser = useSelector(selectUser);

     const [addContactData, setAddContactData] = useState(false);
     const [updateContactData, setUpdateContactData] = useState(false);

     var user = loginUser ? loginUser : [];

     const userPermissions = useSelector((state) => state.permission);

     var userPermission = userPermissions ? userPermissions : [];

     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone || defaultData.defaultTimeZone;

     const [loadingContact, setLoadingContact] = useState(true);
     const [listContactData, setListContactData] = useState([]);

     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState("");

     //=========START:CODE FOR FOOTER PAGINATION===========//
     const [dataCount, setdataCount] = useState("");
     const [state, setData] = useState({
          users: "",
     });

     const SORT_ASC = "asc";
     const SORT_DESC = "desc";
     const [sortColumn, setSortColumn] = useState();
     //sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC

     //=========END:CODE FOR FOOTER PAGINATION===========//

     var dealCalendorFormate = "";

     useEffect(() => {
          async function fetchData() {
               await getContactData();
          }
          fetchData();
     }, [updateContactData, addContactData]);

     const getContactData = async (currentPage) => {
          const params = {
               sort_field: sortColumn,
               sort_order: SORT_DESC,
               per_page: perPage,
               page: currentPage,
               deal_id: dealId,
          };

          setLoadingContact(true);
          await axios
               .get(`api/quote?page=${currentPage}`, { params })
               .then((response) => {
                    if (response.status === 200) {
                         setListContactData(response.data.data.records.data);
                         setdataCount(response.data.data.records.data.length);
                         setData({
                              users: response.data.data.records,
                         });

                         setPreferredCurrencyData(response.data.data.preferredCurrency);
                         const calendorFormate = displayDate(response?.data?.data?.preferredDateFormat,'half');
                         setPreferredDateFormatData(calendorFormate?calendorFormate:'');

                         if (response.data.data.preferredCurrency.currencyCode) {
                              currencyCode = response.data.data.preferredCurrency.currencyCode
                                   ? response.data.data.preferredCurrency.currencyCode
                                   : "";
                              currencySymbol = response.data.data.preferredCurrency.currencySymbol
                                   ? response.data.data.preferredCurrency.currencySymbol
                                   : "";
                         }

                         setLoadingContact(false);
                    }
               })
               .then((json) => { })
               .catch((error) => {
                    if (error.response) {
                         setLoadingContact(false);
                         if (error.response.status === 400 || error.response.status === 401) {
                              toast.warning(error.response.data.message);
                         } else if (error.response.status === 403) {
                              //swal("Forbidden", error.response.data.message, "error");
                              toast.warning("Forbidden" + error.response.data.message);
                              history.push("/dashboard");
                         } else {
                              //swal("error", error.response.data.message, "error");
                              toast.error(error.response.data.message);
                              history.push("/dashboard");
                         }
                    }
               });
     };

     //============END: CODE FOR EDIT CONTACT=================//

     var displayUserData = "";

     if (loadingContact) {
          return <Preloader show={loadingContact ? true : false} />;
     } else {
          displayUserData = listContactData.map((item, i) => {
               let num = parseInt(item.total);
               const myObj = {
                    style: "currency",
                    currency: currencyCode,
               };

               return (
                    <tr key={i}>
                         <td
                              className="dealsname"
                              onClick={() => history.push("/edit-quote/" + item.id)}
                              style={{ cursor: "pointer" }}
                         >
                              <span style={{ textTransform: "capitalize" }}>
                                   {item.subject ? item.subject : ""}
                              </span>
                         </td>
                         <td>{item.quote_number ? item.quote_number : "N/A"}</td>
                         <td>{item.quote_stage ? item.quote_stage : "N/A"}</td>
                         <td>
                              {num
                                   ? preferredCurrencyData.currencyStringValue
                                        ? num.toLocaleString(
                                             preferredCurrencyData.currencyStringValue,
                                             myObj
                                        )
                                        : currencySymbol + num.toLocaleString(myObj)
                                   : "N/A"}
                         </td>
                         <td>
                              {item.valid_until
                                   ? moment
                                        .tz(item.valid_until, defaultTimeZone)
                                        .format(preferredDateFormatData)
                                   : "N/A"}
                         </td>
                         <td>
                              <Dropdown>
                                   <Dropdown.Toggle id="dropdown-basic">
                                        <span className="listdot"></span>
                                   </Dropdown.Toggle>
                                   <Dropdown.Menu>
                                        {userPermission ? (
                                             userPermission.includes("quote_delete") ? (
                                                  <Dropdown.Item
                                                       onClick={() => history.push("/edit-quote/" + item.id)}
                                                  >
                                                       Edit
                                                  </Dropdown.Item>
                                             ) : (
                                                  ""
                                             )
                                        ) : (
                                             ""
                                        )}

                                        {/* <Dropdown.Item onClick={() => { deleteRecord(item.id)}}>Delete</Dropdown.Item> */}
                                   </Dropdown.Menu>
                              </Dropdown>
                         </td>
                    </tr>
               );
          });

          return (
               <Col md={12} className="mt30">
                    <div className="display flex-wrap w-100 history">
                         <div className="innerdealtable w-100">
                              <div className="col-md-8">
                                   <h5>Quotes</h5>
                              </div>
                              <div className="col-md-4 text-right">
                                   {/* <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className="transparentbg p-0">
                                        <span className="editplusicon"  onClick={() => addContact()}></span>
                                        </Dropdown.Toggle>
                                   </Dropdown> */}
                              </div>
                         </div>

                         {displayUserData.length ? (
                              <>
                                   <div className="contanctlistnig">
                                        <table>
                                             <tbody>
                                                  <tr>
                                                       <th style={{ width: "25%" }}>Name</th>
                                                       <th style={{ width: "10%" }}>Number</th>
                                                       <th style={{ width: "15%" }}>Stage</th>
                                                       <th style={{ width: "15%" }}>Total</th>
                                                       <th style={{ width: "20%" }}>Valid Until</th>
                                                       <th style={{ width: "5%" }}>Action</th>
                                                  </tr>
                                                  {displayUserData}
                                             </tbody>
                                        </table>
                                   </div>

                                   {displayUserData.length >= 1 ? (
                                        <Col xs={12} xl={12} className="showing">
                                             <Card border="light" className="bg-white border-0">
                                                  <Card.Body className="listcard">
                                                       <Row className="align-items-center">
                                                            <Col md={6} className="ps-2">
                                                                 <h6>
                                                                      Showing{" "}
                                                                      <b>
                                                                           {(state?.users?.per_page
                                                                                ? state?.users?.per_page
                                                                                : 0) *
                                                                                (state?.users?.current_page
                                                                                     ? state?.users?.current_page
                                                                                     : 0) -
                                                                                ((state?.users?.per_page
                                                                                     ? state?.users?.per_page
                                                                                     : 0) -
                                                                                     1)}
                                                                      </b>{" "}
                                                                      -{" "}
                                                                      <b>
                                                                           {(state?.users?.per_page
                                                                                ? state?.users?.per_page
                                                                                : 0) *
                                                                                (state?.users?.current_page
                                                                                     ? state?.users?.current_page
                                                                                     : 0) -
                                                                                ((state?.users?.per_page
                                                                                     ? state?.users?.per_page
                                                                                     : 0) -
                                                                                     1) +
                                                                                ((dataCount ? dataCount : 0) - 1)}
                                                                      </b>{" "}
                                                                      of{" "}
                                                                      <b>
                                                                           {state?.users?.total ? state?.users?.total : 0}
                                                                      </b>
                                                                 </h6>
                                                            </Col>
                                                            <Col md={6} className="d-flex justify-content-end">
                                                                 <Pagination
                                                                      activePage={
                                                                           state?.users?.current_page
                                                                                ? state?.users?.current_page
                                                                                : 0
                                                                      }
                                                                      itemsCountPerPage={
                                                                           state?.users?.per_page
                                                                                ? state?.users?.per_page
                                                                                : 0
                                                                      }
                                                                      totalItemsCount={
                                                                           state?.users?.total ? state?.users?.total : 0
                                                                      }
                                                                      onChange={(pageNumber) => {
                                                                           currentPage = pageNumber;
                                                                           getContactData(pageNumber);
                                                                      }}
                                                                      pageRangeDisplayed={5}
                                                                      itemClass="page-item mx-1"
                                                                      linkClass="page-link"
                                                                 />
                                                            </Col>
                                                       </Row>
                                                  </Card.Body>
                                             </Card>
                                        </Col>
                                   ) : (
                                        ""
                                   )}
                              </>
                         ) : (
                              <span>No data Available</span>
                         )}
                    </div>
               </Col>
          );
     }
};
