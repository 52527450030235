/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Spinner, Modal } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { DealExistAccountAddPopUp } from "../features/userSlice"
import { toast } from 'react-toastify';
import Select from 'react-select'
import CurrencyInput from 'react-currency-input-field';
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from 'axios';


var selectedAccount = [];
var accountList = [];
var contactList = [];

export default (props) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch(); 

  const [preferredCurrencyData, setPreferredCurrencyData] = useState(props.data[0].preferredCurrencyData);
  const [leadSourceData, setleadSourceData] = useState([]);

  const [recordData, setRecordData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [contactData, setContactData] = useState([]);

  const showAddExistDeal = useSelector((state) => state.dealExistAccountAddPopUp);
  const handleLeadClose = () => dispatch(DealExistAccountAddPopUp(false));

  const [defaultValue, setdefaultValue] = useState({
    account_id: '',
    contact_id: '',
    deal_name: '',
    lead_source: '',
    phone: '',
    deal_amount: '',
    deal_description: '',
    deal_probability: '',
    error_list: [],
  })

  const lengthValidation = (fieldLength) => {
    var valid = true;
    if(fieldLength){
      var str = fieldLength.replace(/\s/g,'')
      if(str.length<2){
          valid = false;
      }
    }
    
    return valid ? {
      isValid: true,
    } : {
      isValid: false,
      errorMessage: 'Must be at least 2 characters, no space allow',
    }
  }

  const validationSchema = yup.object().shape({
    account_id: yup.string().required('Please select Account'),
    contact_id: yup.string().required('Please select Contact'),
    phone: yup.string().max(25, 'Phone must be at most 25 characters'),
    deal_name: yup.string().trim('Deal name cannot include space').strict(true).required('Enter deal name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
    deal_amount: yup.string().required('Enter deal amount'),
    deal_description: yup.string().required('Enter deal description').min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters')
    .test('validator-custom-length', function (value) {
      const validation = lengthValidation(value);
      if (!validation.isValid) {
        return this.createError({
          path: this.path,
          message: validation.errorMessage,
        });
      }
      else {
        return true;
      }
    }),
    deal_probability: yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter deal probability').positive().max('100', "Please enter less than 100%")
  })

  useEffect(() => {
    if(showAddExistDeal){
      async function fetchData() {
        await getDealCreate();
      }
      fetchData();
    }
  }, []);

  async function getDealCreate() {
    await axios.get('api/deal/create').then(response => {
      if (response.status === 200) {
        setRecordData(response.data.data)
        setAccountData(response.data.data.selectAccountData)
        setContactData(response.data.data.selectContactData)
        setleadSourceData(response.data.data.leadSources);
        setPreferredCurrencyData(response.data.data.preferredCurrencyData)
        setdefaultValue({ ...defaultValue, salutation: '', firstname: '', lastname: '', title: '', organization_name: '', lead_source: '', phone: '', organization_email: '', deal_amount: '', deal_description: '', deal_probability: '', error_list: [] });
        accountList=[];
        for (var i = 0, l = response.data.data.selectAccountData.length; i < l; i++) {
          accountList.push({ label: response.data.data.selectAccountData[i].organization_name, value: response.data.data.selectAccountData[i].id });
        }
        contactList = [];
        setLoading(false)
      }
    }).then(() => {

    }).catch(error => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 300) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }


  async function getContactFromAccount(account_id) {
    setContactData('')
    await axios.get('api/deal/create?account_id='+account_id+'&getContact=yes').then(response => {
      if (response.status === 200) {
        var displayname = ''
        contactList = [];
        for (var i = 0, l = response.data.data.accountContacts.length; i < l; i++) {
          if (response.data.data.accountContacts[i].first_name && response.data.data.accountContacts[i].last_name) {
            displayname = response.data.data.accountContacts[i].first_name + ' ' + response.data.data.accountContacts[i].last_name
          } else if (response.data.data.accountContacts[i].first_name && !response.data.data.accountContacts[i].last_name) {
            displayname = response.data.data.accountContacts[i].first_name
          } else if (!response.data.data.accountContacts[i].first_name && response.data.data.accountContacts[i].last_name) {
            displayname = response.data.data.accountContacts[i].last_name
          }

          if (response.data.data.accountContacts[i].salutation && displayname) {
            var sal = response.data.data.accountContacts[i].salutation;
            displayname = sal.charAt(0).toUpperCase() + sal.slice(1) + '. ' + displayname.charAt(0).toUpperCase() + displayname.slice(1);
          }

          contactList.push({ label: displayname, value: response.data.data.accountContacts[i].id });
        }
        setLoading(false)
      }
    }).then(() => {

    }).catch(error => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 300) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }


  let leadSourceList = [];
  leadSourceData.map((item) => {
    leadSourceList.push({ label: item.title, value: item.title });
  }, this);
  
  const customStyles = {
    menuList: styles => ({
      ...styles,
      //color:'black',
      // backgroundcolor:'$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),

  }

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      account_id: values.account_id,
      contact_id: values.contact_id,
      deal_amount: values.deal_amount,
      deal_description: values.deal_description,
      deal_name: values.deal_name,
      deal_probability: values.deal_probability,
      lead_source: values.lead_source,
      phone1: values.phone,
      added_from: 'account',
    }

    await axios.post("/api/deal/store", data).then(response => {
      if (response.status === 200) {
        dispatch(DealExistAccountAddPopUp(false));
        toast.success('Deal has been created successfully');
      }
    }).then(() => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 400) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.warning(error.response.data.message);
        }
      }

    });

  }

  return (
    <Modal as={Modal.Dialog} centered show={showAddExistDeal} onHide={handleLeadClose}>
      <Modal.Header>
        <Modal.Title className="h2">Add Exist Account Deal</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleLeadClose} /><span className="modelclose"></span>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting(false));
          }}
        >{props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
          } = props;
          return (
            <Form encrypted="multipart/form-data" onSubmit={handleSubmit} >
              <Row>
                <div className="col-md-12 col-sm-12">
                  <Row className="align-items-center">
                    <Form.Group className="mb-125 firstrow position-relative">

                      <Col sm="12" className="Display">
                        <Form.Label column sm="12">
                          <h6 className="fw600">Account<span className="text-danger">*</span></h6>
                        </Form.Label>
                        <Form.Group id="account_id">
                          <Select
                            defaultValue={selectedAccount}
                            name="account_id"
                            options={accountList}
                            className={errors.account_id && touched.account_id ?
                              "input-select-error basic-multi-select" : 'basic-multi-select'}
                            classNamePrefix="select"
                            styles={customStyles}
                            onChange={selectedOption => {
                              values.account_id = selectedOption.value;
                              handleChange("account_id");
                              getContactFromAccount(selectedOption.value)
                            }}
                            isClearable={false}
                            isSearchable={true}
                            onBlur={setFieldTouched}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                              },
                            })}
                          />
                          <span className="error error4">{defaultValue.error_list.account_id}</span>
                          <span className="error error4">
                            <ErrorMessage name="account_id" />
                          </span>
                        </Form.Group>

                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-125 firstrow position-relative">

                      <Col sm="12" className="Display">
                        <Form.Label column sm="12">
                          <h6 className="fw600">Contact<span className="text-danger">*</span></h6>
                        </Form.Label>
                        <Form.Group id="contact_id">
                          <Select
                            //defaultValue={selectedContact}
                            value={contactList.filter(({ value }) => value === contactData)} 
                            name="contact_id"
                            options={contactList}
                            className={errors.contact_id && touched.contact_id ?
                              "input-select-error basic-multi-select" : 'basic-multi-select'}
                            classNamePrefix="select"
                            styles={customStyles}
                            onChange={selectedOption => {
                              values.contact_id = selectedOption.value;
                              handleChange("contact_id");
                              setContactData(selectedOption.value)
                            }}
                            isClearable={false}
                            isSearchable={true}
                            onBlur={setFieldTouched}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                              },
                            })}
                          />
                          <span className="error error4">{defaultValue.error_list.contact_id}</span>
                          <span className="error error4">
                            <ErrorMessage name="contact_id" />
                          </span>
                        </Form.Group>

                      </Col>
                    </Form.Group>


                    <Form.Group className="mb-125 position-relative">
                      <Form.Label column sm="12">
                        <h6 className="fw600">Deal name<span className="text-danger">*</span></h6>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Group id="title">
                          <Form.Control type="text" name="deal_name" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter deal name" className={errors.deal_name && touched.deal_name ?
                            "input-error" : null} />
                          <span className="error error4">{defaultValue.error_list.deal_name}</span>
                          <span className="error error4">
                            <ErrorMessage name="deal_name" />
                          </span>
                        </Form.Group>
                      </Col>
                    </Form.Group>

                    <Form.Group className="mb-125 position-relative">
                      <Form.Label column sm="12">
                        <h6 className="fw600">Source</h6>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Group id="lead_source">
                          <Select
                            //defaultValue={selectedStage}
                            name="lead_source"
                            options={leadSourceList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            //onChange={handleLeadSourceInput}
                            onChange={selectedOption => {
                              values.lead_source = selectedOption.value;
                              handleChange("lead_source");
                            }}
                            onBlur={handleBlur}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                              },
                            })}
                          />
                          <span className="error error4">{defaultValue.error_list.lead_source}</span>
                          <span className="error error4">
                            <ErrorMessage name="lead_source" />
                          </span>
                        </Form.Group>
                      </Col>
                    </Form.Group>

                    <Form.Group className="mb-125 position-relative">
                      <Form.Label column sm="12">
                        <h6 className="fw600">Phone</h6>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Group id="phone">
                          <Form.Control type="text" name="phone" maxLength={25} onChange={handleChange} onBlur={handleBlur} placeholder="99999 12345" />
                          <span className="error error4">{defaultValue.error_list.phone1}</span>
                          <span className="error error4">
                            <ErrorMessage name="phone" />
                          </span>
                        </Form.Group>
                      </Col>
                    </Form.Group>

                    <Form.Group className="mb-125 position-relative ">
                      <Form.Label column sm="12">
                        <h6 className="fw600">Amount {preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode + ' ' : ' '}
                          ({preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : ''})<span className="text-danger">*</span></h6>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Group id="deal_amount">
                          {/* <Form.Control type="text" name="deal_amount" onChange={handleChange} onBlur={handleBlur} placeholder="Enter amount" /> */}
                          <CurrencyInput
                            id="deal_amount"
                            name="deal_amount"
                            intlConfig={{
                              locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                              currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                            }}
                            placeholder="Enter deal amount"
                            className={errors.deal_amount && touched.deal_amount ?
                              "form-control input-error" : 'form-control'}
                            //onValueChange={handleOnValueChange}
                            onValueChange={(value, name) => {
                              //removeStyleButtonOption()
                              setdefaultValue({ ...defaultValue, ['deal_amount']: value });
                              values.deal_amount = value;
                              handleChange(name)
                            }}
                            decimalsLimit={6}
                            value={defaultValue.deal_amount}
                            step={1}
                          />
                          <span className="error error4">{defaultValue.error_list.deal_amount}</span>
                          <span className="error error4">
                            <ErrorMessage name="deal_amount" />
                          </span>
                        </Form.Group>
                      </Col>
                    </Form.Group>

                    <Form.Group className="mb-125 position-relative">
                      <Form.Label column sm="12">
                        <h6 className="fw600">Probability (%)<span className="text-danger">*</span></h6>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Group id="deal_probability">
                          <Form.Control type="number" max={100} step={0.01} name="deal_probability" onChange={handleChange} onBlur={handleBlur} placeholder="Enter probability" className={errors.deal_probability && touched.deal_probability ?
                            "proability errormessage input-error" : 'proability errormessage'} />
                          <span className="error error4">{defaultValue.error_list.deal_probability}</span>
                          <span class="percetangeicon"></span>
                          <span className="error error4">
                            <ErrorMessage name="deal_probability" />
                          </span>
                        </Form.Group>

                      </Col>
                    </Form.Group>
                    <Form.Group className="position-relative">
                      <Form.Label column sm="12">
                        <h6 className="fw600">Description<span className="text-danger">*</span></h6>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Group id="deal_description">
                          <Form.Control as="textarea" minLength={2} maxLength={150} name="deal_description" onChange={handleChange} onBlur={handleBlur} placeholder="Enter description" className={errors.deal_description && touched.deal_description ?
                            "input-error" : null} />
                          <span className="error error4">{defaultValue.error_list.deal_description}</span>
                          <span className="error error4">
                            <ErrorMessage name="deal_description" />
                          </span>
                        </Form.Group>

                      </Col>
                    </Form.Group>
                  </Row>
                </div>
              </Row>


              <div className="text-center mt30">
                {
                  isSubmitting ?

                    <Button className="d-flex align-items-center popupbtn btnblue justify-content-center text-center btnblue mb-1">
                      <Spinner animation="border" size="sm" />
                      <span className="ms-2 text-center">Please wait...</span>
                    </Button>
                    :

                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                      <div className="btn-group mr-2" role="group" aria-label="First group">
                        <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                      </div>
                      <div className="btn-group mr-2" role="group" aria-label="First group">
                        <Button type="button" variant="success" onClick={handleLeadClose} className="popupbtn greycolor d-flex align-items-center justify-content-center">Cancel</Button>
                      </div>
                    </div>
                }
              </div>
            </Form>
          );
        }}
        </Formik>
      </Modal.Body>

    </Modal>
  );


};
