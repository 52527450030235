/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
//import { Image } from '@themesberg/react-bootstrap';

//import ReactLogo from "../assets/img/technologies/react-logo-transparent.svg";

import { MutatingDots } from  'react-loader-spinner'

export default (props) => {

  const { show, message } = props;

  return (
    <div className={`preloader bg-softlight flex-column position-relative d-flex justify-content-center align-items-center ${show ? "" : "show"}`}>
      {/* <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />*/}
      <MutatingDots 
        height="100"
        width="100"
        // color="#4fa94d"
        color="#0A8EA0"
        // secondaryColor= '#4fa94d'
        secondaryColor= '#0A8EA0'
        radius='12.5'
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={show ? true : false }
        position="relative"
        className="loadercenter"
      />
      {message ? <h3>{message}</h3> : ''}
    </div>

    

  );
};
