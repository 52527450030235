import React, { useEffect, useState, memo } from "react";
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import Preloader from "./PreloaderCenter";
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { login, selectUser, LoginPermission, Workspace, LoginAccessMsg, selectDefaultData } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';


var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = 6;
var currentPage = 1;
var calendorFormate = '';
var searchData = '';


function MyThisMonthLeads(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [leadList, setLeadList] = useState([]);

  const loginUser = useSelector(selectUser);
  const user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  //check msg for nonAuthentication Action pages
  const withOutLoginMsg = useSelector((state) => state.loginAccessMsg);
  if (withOutLoginMsg) {
    toast.warning(withOutLoginMsg);
    dispatch(LoginAccessMsg());
  }

  const [stateLead, setStateLead] = useState({
    users: ''
  });

  useEffect(() => {
    setTimeout(() => {
      getData()
    }, 1000);
    
  }, []);


  const getData = async (currentPage) => {
    const params = {
      search: searchData,
      sort_field: '',
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    }
    axios.get(`api/dashboard-current-month-lead?page=${currentPage}`, { params }).then(response => {
      if (response.status === 200) {
        //console.log('dash',response) 
        setLeadList(response.data.data.leadRecords.data)
        setStateLead({
          users: response.data.data.leadRecords
        });

        props.handleLeadCount(response.data.data.leadCount)
      }
      setLoading(false);
    }).then(json => {
      toast.success(json.data.data.message);
    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        }else if (error.response.status === 500) {
          //toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      }
    });
  }

  var leadDataList = '';

  if (loading) {
    return <Preloader show={true} />;
  } else {

    leadDataList = leadList.map((item, i) => {
      return (
        <tr key={item.id} onClick={() => history.push('edit-lead/' + item.id)}>
          <td className="leadpar"><span>{item.organization_name ? item.organization_name : 'N/A'}</span></td>
          <td className="leadtitle"><span>{item.salutation ? item.salutation+'. ' : ''} {item.first_name + ' ' + item.last_name}</span></td>
          <td>{item.lead_source ? item.lead_source : 'N/A'}</td>
        </tr>
      )
    });

    return (
      <div className="width1">
        <div className="display flex-wrap task align-items-start justify-content-end">
          <div className="col-md-12">
            <h5 className="tables_title">My This Month’s Leads</h5>
          </div>
          <table>
            <tbody>
            {
              leadDataList.length >= 1 ?

                <tr>
                  <th style={{ width: "35%" }}>Company</th>
                  <th style={{ width: "35%" }}>Name</th>
                  <th style={{ width: "30%" }}>Source</th>
                </tr>
                :
                ''
            }
            {
              leadDataList.length >= 1 ?

                leadDataList
                :
                <tr>
                  <td colSpan={4}><span className="text-center d-flex align-items-center justify-content-center">No Data Available</span></td>
                </tr>
            }
            </tbody>
          </table>
          {
            leadDataList.length >= 1 ?

              <div className="justify-content-end">
                <Pagination
                  activePage={stateLead?.users?.current_page ? stateLead?.users?.current_page : 0}
                  itemsCountPerPage={stateLead?.users?.per_page ? stateLead?.users?.per_page : 0}
                  totalItemsCount={stateLead?.users?.total ? stateLead?.users?.total : 0}
                  onChange={(pageNumber) => {
                    currentPage = (pageNumber);
                    getData(pageNumber)
                  }}
                  pageRangeDisplayed={5}
                  itemClass="page-item"
                  linkClass="page-link"
                // firstPageText="First Page"
                // lastPageText="Last Page"
                />
              </div>


              :
              ''
          }
        </div>
      </div>
    )
  }
}

export default memo(MyThisMonthLeads);
