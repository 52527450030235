import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';

import "react-datepicker/dist/react-datepicker.css";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Col, Button, Form, Spinner } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import QuoteItems from "../../components/QuoteItems";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Checkbox from "react-custom-checkbox";
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import { useHistory, } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';

let subTotal=0;
let grandTotal=0;
let finalDiscountDisplay=0;

function CreateQuote(props) {
     const history = useHistory();
     const loginUser = useSelector(selectUser);
     const [productIds, setProductIds] = useState();

     const [productList, setProductList] = useState([]);

     const user = loginUser?loginUser:[];

     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

     const [defaultValue, setdefaultValue] = useState({
          subject: '',
          user_id: '',
          account_id: '',
          contact_id: '',
          deal_id: '',
          quote_stage: '',
          owner_id: '',

          address: '',
          city: '',
          state: '',
          zipcode: '',
          country_id: '',

          is_same_billing:'',
          s_address: '',
          s_city: '',
          s_state: '',
          s_zipcode: '',
          s_country_id: '',

          terms_and_condition: '',
          description: '',

          productId: '',

          quote_item_array: '',
          quote_final_total: '',

          error_list: [],
     })

     const validationSchema = yup.object().shape({
          subject:yup.string().trim('Subject cannot include space').strict(true).required('Enter subject').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          account_id:yup.string().required('Select Account'),
          deal_id:yup.string().required('Select Deal'),

          address:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          city:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          state:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          zipcode:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          
          s_address:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          s_city:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          s_state:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          s_zipcode:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          
          terms_and_condition:yup.string().min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters'),
          description:yup.string().min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters'),

          //productId:yup.string().required('Select Product'),
     })

     const [updateLeadData, setUpdateLeadData] = useState(false);

     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
     const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');
     const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

     //=========START:CODE FOR EDIT LEAD DATA==========//
     const [loading, setLoading] = useState(true);
     const [dataRecord, setDataRecord] = useState('');
     const [userList, setUserList] = useState([]);
     const [dealList, setDealList] = useState([]);
     const [accountList, setAccountList] = useState([]);
     const [contactList, setContactList] = useState([]);
     const [record, setRecord] = useState('');
     const [countries, setCountries] = useState([]);
     //const [productList, setProductList] = useState([]);
     const [quoteStageArrayList, setQuoteStageArrayList] = useState([]);


     const [accountLists, setaccountLists] = useState([{ label: 'Select', value: '' }]);
     const [contactLists, setContactLists] = useState([{ label: 'Select', value: '' }]);

     const [billingCountryId, setBillingCountryId] = useState('');
     const [shippingCountryId, setShippingCountryId] = useState('');

     const [selectAccountId, setSelectAccountId] = useState('');
     const [selectContactId, setSelectContactId] = useState('');

     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     let selecteAccount = [{ label: 'Select', value: '' }];

     let selecteContact = [{ label: 'Select', value: '' }];
     
     //========Const data for Activity (Lead and Deal)
     var calendorFormate = '';

     useEffect(() => {
          async function fetchData() {
               await getQuoteData();
          }
          fetchData();
     }, []);


     async function getQuoteData() {
          await axios.get('api/quote/create').then(response => {
               if (response.data.code === 200) {
                   // console.log(response,'response-response')
                    setDataRecord(response.data.data.record);
                    setRecord(response.data.data);
                    
                    setUserList(response.data.data.users);
                    setDealList(response.data.data.deals);
                    setAccountList(response.data.data.accounts);
                    setContactList(response.data.data.contacts);
                    setCountries(response.data.data.countries);
                    setProductList(response.data.data.products);
                    setQuoteStageArrayList(response.data.data.quoteStageLists);

                    setPreferredCurrencyData(response.data.data.preferredCurrency);
                    setPreferredDateFormatData(response.data.data.preferredDateFormat);

                    calendorFormate = response.data.data.preferredDateFormat.split(' ');
                    if (calendorFormate) {
                         if (calendorFormate[0] == 'YYYY-MMM-DD') {
                              setCalendorDateFormate('yyyy/MM/dd');
                              setCalendorFullDateFormate('yyyy/MM/DD');
                         } else if (calendorFormate[0] == 'DD-MMM-YYYY') {
                              setCalendorDateFormate('dd/MM/yyyy');
                              setCalendorFullDateFormate('DD/MM/yyyy');
                         } else if (calendorFormate[0] == 'YYYY-DD-MMM') {
                              setCalendorDateFormate('yyyy/dd/MM');
                              setCalendorFullDateFormate('yyyy/DD/MM');
                         } else if (calendorFormate[0] == 'MMM-DD-YYYY') {
                              setCalendorDateFormate('MM/dd/yyyy');
                              setCalendorFullDateFormate('MM/DD/yyyy');
                         }
                    }
                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/quotes');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/quotes');
          });
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }

    

     const getContactData = async (id,setFieldValue,type) =>{
          if(id){
               if(type == 'deal'){
                    setaccountLists([{ label: 'Select', value: '' }]);
                    setContactLists([{ label: 'Select', value: '' }]);
               }
               
               await axios.get('api/quote/getAddress/'+ id+'?type='+type).then(response => {
                    if (response.data.code === 200) {
                         //------------------For Contact list-------------------------------
                         if(type=='deal'){
                              setFieldValue('account_id','')
                              setFieldValue('contact_id','')

                              var displayname = ''
                              let _accountLists=[];
                              let _contactLists=[];

                              for (var i = 0, l = response.data.data.getContactLists.length; i < l; i++) {
                                   if (response.data.data.getContactLists[i].first_name && response.data.data.getContactLists[i].last_name) {
                                   displayname = response.data.data.getContactLists[i].first_name + ' ' + response.data.data.getContactLists[i].last_name
                                   } else if (response.data.data.getContactLists[i].first_name && !response.data.data.getContactLists[i].last_name) {
                                   displayname = response.data.data.getContactLists[i].first_name
                                   } else if (!response.data.data.getContactLists[i].first_name && response.data.data.getContactLists[i].last_name) {
                                   displayname = response.data.data.getContactLists[i].last_name
                                   }

                                   if (response.data.data.getContactLists[i].salutation && displayname) {
                                   var sal = response.data.data.getContactLists[i].salutation;
                                   displayname = sal.charAt(0).toUpperCase() + sal.slice(1) + '. ' + displayname.charAt(0).toUpperCase() + displayname.slice(1);
                                   }
                                   _contactLists.push({ label: displayname, value: response.data.data.getContactLists[i].id });
                              }

                              //-----------------For Account list-----------------------------------
                              for (var i = 0, l = response.data.data.getAccountLists.length; i < l; i++) {
                                   _accountLists.push({ label: response.data.data.getAccountLists[i].organization_name, value: response.data.data.getAccountLists[i].id });
                              }

                              //console.log(accountLists,'responseresponse====',contactLists,'pppp',_contactLists)

                              if(_contactLists.length==0){
                                   setFieldValue('address','')
                                   setFieldValue('city','')
                                   setFieldValue('state','')
                                   setFieldValue('zipcode','')
                                   setFieldValue('country_id','') 
                                   setBillingCountryId('')
                              }
                              setaccountLists(_accountLists);
                              setContactLists(_contactLists);

                         }else{
                              setFieldValue('address',response.data.data.getBillingAddress.address1 ? response.data.data.getBillingAddress.address1 : response.data.data.getBillingAddress.address2 ? response.data.data.getBillingAddress.address2 : '')
                              setFieldValue('city',response.data.data.getBillingAddress.city ? response.data.data.getBillingAddress.city :'')
                              setFieldValue('state',response.data.data.getBillingAddress.state ? response.data.data.getBillingAddress.state : '')
                              setFieldValue('zipcode',response.data.data.getBillingAddress.zipcode ? response.data.data.getBillingAddress.zipcode : '')
                              setFieldValue('country_id',response.data.data.getBillingAddress.country_id ? response.data.data.getBillingAddress.country_id:'')
          
                              setBillingCountryId(response.data.data.getBillingAddress.country_id)
                         }

                    }
               }).then(json => {
     
               }).catch(error => {
                    if (error.response) {
                         setLoading(true)
                         if (error.response.status === 401 || error.response.status === 400) {
                              toast.warning(error.response.data.message);
                         } else if (error.response.status === 300) {
                              toast.error(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden ' + error.response.data.message);
                              history.push('/quotes');
                         } else {
                              toast.error('Oops Something went wrong.');
                         }
                    }
                    history.push('/quotes');
               }); 
          }else{
               setaccountLists([{ label: 'Select', value: '' }]);
               setContactLists([{ label: 'Select', value: '' }]);

               setFieldValue('address','')
               setFieldValue('city','')
               setFieldValue('state','')
               setFieldValue('zipcode','')
               setFieldValue('country_id','')     
          }
     }

     const handleCheckbox = (value,setFieldValue,props) =>{
          setFieldValue('is_same_billing',value)
          if(value){
               setFieldValue('s_address',props.values.address?props.values.address:'')
               setFieldValue('s_city',props.values.city?props.values.city:'')
               setFieldValue('s_state',props.values.state?props.values.state:'')
               setFieldValue('s_zipcode',props.values.zipcode?props.values.zipcode:'')
               setFieldValue('s_country_id',props.values.country_id?props.values.country_id:'') 
               setShippingCountryId(props.values.country_id?props.values.country_id:'')     
          }else{
               setFieldValue('s_address','')
               setFieldValue('s_city','')
               setFieldValue('s_state','')
               setFieldValue('s_zipcode','')
               setFieldValue('s_country_id','')   
               setShippingCountryId('')  
          }
     }

     const handleSubmit = async (values,onSubmitProps) =>{

          const data = {
               subject: values.subject,
               contact_id: values.contact_id,
               account_id: values.account_id,
               deal_id: values.deal_id,
               quote_stage: values.quote_stage,
               owner_id: values.owner_id,

               address:values.address,
               city:values.city,
               state:values.state,
               zipcode:values.zipcode,
               country_id:values.country_id,

               is_same_billing:values.is_same_billing,
               s_address:values.s_address,
               s_city:values.s_city,
               s_state:values.s_state,
               s_zipcode:values.s_zipcode,
               s_country_id:values.s_country_id,
               
               terms_and_condition: values.terms_and_condition,
               description: values.description,

               quote_item_array: values.QuoteItemArray,
               quote_final_total: values.finalData,
          }

          //console.log(data,'datadatadatadata');

          await axios.post("/api/quote/store", data).then(response => {
            if (response.status === 200) {
              toast.success('Quote has been created successfully');
              history.push('/quotes');
            }
          }).then(json => {
      
          }).catch(error => {
            if (error.response) {
              onSubmitProps.setSubmitting(false)
              if (error.response.status === 400) {
                setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
              } else if(error.response.status === 403){
                toast.warning('Forbidden '+error.response.data.message);
                history.push('/quotes');    
              } else if(error.response.status === 300){
                    var text = error.response.data.message.split('S.No ')
                    if(text && text.length==2) {
                         setProductIds(text[1]);
                    }else{
                         toast.warning(error.response.data.message);    
                    }
              }else {
                toast.warning(error.response.data.message);
              }
            }
      
          });
      
     }

     
     const customStyles1 = {
          menuList: styles => ({
               ...styles,
               // color: 'black',
               // backgroundcolor: '$blue',
               paddingTop: 0,
               paddingBottom: 0,
          }),
     }
     
     if (loading) {
          return <Preloader show={loading ? false : true} />;
     } else {

          //-----------------For Country list-----------------------------------
          let selectedCountry = [{ label: 'Select', value: '' }];
          for (var i = 0, l = countries.length; i < l; i++) {
               if (billingCountryId == countries[i].id) {
                    selectedCountry.push({ label: countries[i].country_name, value: countries[i].id });
               }
          }

          let countrysLists = [{ label: 'Select', value: '' }];
          for (var i = 0, l = countries.length; i < l; i++) {
               countrysLists.push({ label: countries[i].country_name, value: countries[i].id });
          }

          //-----------------For Shipping Country list-----------------------------------
          let selectedShippingCountry = [{ label: 'Select', value: '' }];
          for (var i = 0, l = countries.length; i < l; i++) {
               if (shippingCountryId == countries[i].id) {
                    selectedShippingCountry.push({ label: countries[i].country_name, value: countries[i].id });
               }
          }

          let shippingCountrysLists = [{ label: 'Select', value: '' }];
          for (var i = 0, l = countries.length; i < l; i++) {
               shippingCountrysLists.push({ label: countries[i].country_name, value: countries[i].id });
          }
          
          //------------------For Quote stage list-------------------------------
          let selecteQuotedStage = [{ label: 'Select', value: '' }];

          let quoteStageList = [{ label: 'Select', value: '' }];
          Object.keys(quoteStageArrayList).map(function (key) {
               quoteStageList.push({ label: quoteStageArrayList[key], value: key });
          }, this);
          
          //-----------------For Deal list-----------------------------------
          let selecteDeal = [{ label: 'Select', value: '' }];

          let dealLists = [{ label: 'Select', value: '' }];
          for (var i = 0, l = dealList.length; i < l; i++) {
               dealLists.push({ label: dealList[i].deal_name, value: dealList[i].id });
          }

          //-----------------For Owner list-----------------------------------
          let selecteOwner = [{ label: 'Select', value: '' }];

          let ownerLists = [{ label: 'Select', value: '' }];
          for (var i = 0, l = userList.length; i < l; i++) {
               ownerLists.push({ label: userList[i].name, value: userList[i].id });
          }
          

          return (
               // <Row>
               <div className="display flex-wrap">
                    <Col md={12} className="mt30">
                         <div className="display w-100">
                              <Accordion defaultActiveKey="0" className="w-100">
                                   <Accordion.Item eventKey="0">
                                        <Accordion.Header><h4>Create Quote Information</h4></Accordion.Header>
                                        <Accordion.Body>
                                        <Formik 
                                             enableReinitialize={true}
                                             initialValues={defaultValue} 
                                             validationSchema={validationSchema} 
                                             onSubmit={handleSubmit}
                                        >{props => {
                                             const {
                                             values,
                                             touched,
                                             errors,
                                             dirty,
                                             isSubmitting,
                                             handleChange,
                                             handleBlur,
                                             handleSubmit,
                                             handleReset,
                                             setFieldValue,
                                             setFieldTouched,
                                             } = props;
                                             return (
                                             <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                                                  <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-6 circletrick">
                                                                 <div className="display align-items-start flex-wrap">
                                                                      <div className="col-md-12 circletrick quoteform">
                                                                           <Form.Group className="mb-125 display">
                                                                                <Form.Label className="text-right w-17">Subject<span className="text-danger">*</span></Form.Label>
                                                                                <Col sm="6">
                                                                                     <Form.Group id="subject">
                                                                                          <Form.Control type="text" name="subject" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter subject" className={errors.subject && touched.subject ?
                                                                                               "input-error" : null} />
                                                                                          <span className="error">{defaultValue.error_list.subject}</span>
                                                                                          <span className="error error4">
                                                                                               <ErrorMessage name="subject" />
                                                                                          </span>
                                                                                     </Form.Group>

                                                                                </Col>
                                                                           </Form.Group>
                                                                      </div>
                                                                      <div className="col-md-12 circletrick position-relative leadlist">
                                                                           <Form.Group className="mb-125 display">
                                                                                <Form.Label className="text-right w-17">Quote Stage</Form.Label>
                                                                                <Col sm="6">
                                                                                     <Form.Group id="quote_stage">
                                                                                          <Select
                                                                                               defaultValue={selecteQuotedStage}
                                                                                               name="quote_stage"
                                                                                               options={quoteStageList}
                                                                                               className="basic-multi-select"
                                                                                               classNamePrefix="select"
                                                                                               menuPosition="fixed"
                                                                                               onChange={selectedOption => {
                                                                                                    values.quote_stage = selectedOption.value;
                                                                                                    handleChange("quote_stage");
                                                                                               }}
                                                                                               onBlur={setFieldTouched}
                                                                                               styles={customStyles1}
                                                                                               isClearable={false}
                                                                                               isSearchable={false}
                                                                                               theme={(theme) => ({
                                                                                                    ...theme,
                                                                                                    borderRadius: 0,
                                                                                                    colors: {
                                                                                                         ...theme.colors,
                                                                                                         primary25: 'var(--primary-color-light)',
                                                                                                         primary: 'var(--primary-color)',
                                                                                                    },
                                                                                               })}

                                                                                          />
                                                                                          <span className="error">{defaultValue.error_list.quote_stage}</span>
                                                                                          <span className="error error4">
                                                                                               <ErrorMessage name="quote_stage" />
                                                                                          </span>
                                                                                     </Form.Group>
                                                                                </Col>
                                                                           </Form.Group>
                                                                      </div>
                                                                      <div className="col-md-12 circletrick quoteform leadlist">
                                                                           <Form.Group className="mb-125 display">
                                                                                <Form.Label className="text-right w-17">Quote Owner</Form.Label>

                                                                                <Col sm="6">
                                                                                     <Form.Group id="owner_id">
                                                                                          <Select
                                                                                               defaultValue={selecteOwner}
                                                                                               name="owner_id"
                                                                                               options={ownerLists}
                                                                                               className="basic-multi-select"
                                                                                               classNamePrefix="select"
                                                                                               menuPosition="fixed"
                                                                                               onChange={selectedOption => {
                                                                                                    values.owner_id = selectedOption.value;
                                                                                                    handleChange("owner_id");
                                                                                               }}
                                                                                               onBlur={setFieldTouched}
                                                                                               styles={customStyles1}
                                                                                               //isClearable={false}
                                                                                               //isSearchable={false}
                                                                                               theme={(theme) => ({
                                                                                                    ...theme,
                                                                                                    borderRadius: 0,
                                                                                                    colors: {
                                                                                                         ...theme.colors,
                                                                                                         primary25: 'var(--primary-color-light)',
                                                                                                         primary: 'var(--primary-color)',
                                                                                                    },
                                                                                               })}

                                                                                          />
                                                                                          <span className="error">{defaultValue.error_list.owner_id}</span>
                                                                                          <span className="error error4">
                                                                                               <ErrorMessage name="owner_id" />
                                                                                          </span>
                                                                                     </Form.Group>
                                                                                </Col>
                                                                           </Form.Group>

                                                                      </div>
                                                                      

                                                                 </div>
                                                            </div>
                                                            <div className="col-md-6 circletrick">
                                                                 <div className="display align-items-start flex-wrap">
                                                                      <div className="col-md-12 circletrick quoteform leadlist">
                                                                           <Form.Group className="mb-125 display">
                                                                                <Form.Label className="text-right w-17">Deal Name<span className="text-danger">*</span></Form.Label>
                                                                                <Col sm="6">
                                                                                     <Form.Group id="deal_id">
                                                                                          <Select
                                                                                               defaultValue={selecteDeal}
                                                                                               name="deal_id"
                                                                                               options={dealLists}
                                                                                               className={errors.deal_id && touched.deal_id ?
                                                                                                    "input-select-error basic-multi-select" : 'basic-multi-select'}
                                                                                               classNamePrefix="select"
                                                                                               menuPosition="fixed"
                                                                                               onChange={selectedOption => {
                                                                                                    values.deal_id = selectedOption.value;
                                                                                                    handleChange("deal_id");
                                                                                                    getContactData(selectedOption.value,setFieldValue,'deal')
                                                                                               }}
                                                                                               onBlur={setFieldTouched}
                                                                                               styles={customStyles1}
                                                                                               //isClearable={false}
                                                                                               //isSearchable={false}
                                                                                               theme={(theme) => ({
                                                                                                    ...theme,
                                                                                                    borderRadius: 0,
                                                                                                    colors: {
                                                                                                         ...theme.colors,
                                                                                                         primary25: 'var(--primary-color-light)',
                                                                                                         primary: 'var(--primary-color)',
                                                                                                    },
                                                                                               })}
                                                                                          />
                                                                                          <span className="error">{defaultValue.error_list.deal_id}</span>
                                                                                          <span className="error error4">
                                                                                               <ErrorMessage name="deal_id" />
                                                                                          </span>
                                                                                     </Form.Group> 
                                                                                </Col>
                                                                           </Form.Group>
                                                                      </div>
                                                                      
                                                                      <div className="col-md-12 circletrick quoteform leadlist">
                                                                           <Form.Group className="display align-items-start mb-125">
                                                                                <Form.Label className="text-right w-17 mt-1">Account Name<span className="text-danger">*</span></Form.Label>

                                                                                <Col sm="6">
                                                                                     <Form.Group id="account_id">
                                                                                          <Select
                                                                                               //defaultValue={selecteAccount}
                                                                                               value={accountLists.filter(({ value }) => value === selectAccountId)} 
                                                                                               name="account_id"
                                                                                               options={accountLists}
                                                                                               //className="basic-multi-select"
                                                                                               className={errors.account_id && touched.account_id ?
                                                                                                    "input-select-error basic-multi-select" : 'basic-multi-select'}
                                                                                               classNamePrefix="select"
                                                                                               menuPosition="fixed"
                                                                                               onChange={selectedOption => {
                                                                                                    values.account_id = selectedOption.value;
                                                                                                    handleChange("account_id");
                                                                                                    //getContactData(selectedOption.value,setFieldValue,'account')
                                                                                                    setSelectAccountId(selectedOption.value)
                                                                                               }}
                                                                                               onBlur={setFieldTouched}
                                                                                               styles={customStyles1}
                                                                                               //isClearable={false}
                                                                                               //isSearchable={false}
                                                                                               theme={(theme) => ({
                                                                                                    ...theme,
                                                                                                    borderRadius: 0,
                                                                                                    colors: {
                                                                                                         ...theme.colors,
                                                                                                         primary25: 'var(--primary-color-light)',
                                                                                                         primary: 'var(--primary-color)',
                                                                                                    },
                                                                                               })}

                                                                                          />
                                                                                          <span className="error">{defaultValue.error_list.account_id}</span>
                                                                                          <span className="error error4">
                                                                                               <ErrorMessage name="account_id" />
                                                                                          </span>
                                                                                     </Form.Group>
                                                                                </Col>
                                                                           </Form.Group>
                                                                      </div>
                                                                      <div className="col-md-12 circletrick quoteform leadlist">
                                                                           <Form.Group className="display align-items-start mb-125">
                                                                                <Form.Label className="text-right w-17 mt-1">Contact Name</Form.Label>

                                                                                <Col sm="6">
                                                                                     <Form.Group id="contact_id">
                                                                                          <Select
                                                                                               //defaultValue={selecteContact}
                                                                                               value={contactLists.filter(({ value }) => value === selectContactId)} 
                                                                                               name="contact_id"
                                                                                               options={contactLists}
                                                                                               className="basic-multi-select"
                                                                                               classNamePrefix="select"
                                                                                               menuPosition="fixed"
                                                                                               onChange={selectedOption => {
                                                                                                    values.contact_id = selectedOption.value;
                                                                                                    handleChange("contact_id");
                                                                                                    getContactData(selectedOption.value,setFieldValue,'contact')
                                                                                                    setSelectContactId(selectedOption.value)
                                                                                                    //getCountryOnContact(selectedOption.value);
                                                                                               }}
                                                                                               onBlur={setFieldTouched}
                                                                                               styles={customStyles1}
                                                                                               //isClearable={false}
                                                                                               //isSearchable={false}
                                                                                               theme={(theme) => ({
                                                                                                    ...theme,
                                                                                                    borderRadius: 0,
                                                                                                    colors: {
                                                                                                         ...theme.colors,
                                                                                                         primary25: 'var(--primary-color-light)',
                                                                                                         primary: 'var(--primary-color)',
                                                                                                    },
                                                                                               })}

                                                                                          />
                                                                                          <span className="error">{defaultValue.error_list.contact_id}</span>
                                                                                          <span className="error error4">
                                                                                               <ErrorMessage name="contact_id" />
                                                                                          </span>
                                                                                     </Form.Group>
                                                                                </Col>
                                                                           </Form.Group>
                                                                      </div>

                                                                 </div>
                                                            </div>
                                                       {/*--- first-row ---*/}
                                                  </div>
                                                  <div className="display align-items-center flex-wrap mb25">
                                                       <div className="col-md-6 circletrick">
                                                            <h4>Address Information</h4>
                                                       </div>
                                                       <div className="col-md-6 circletrick">
                                                            <div className="col-md-12 circletrick  clicktxt">
                                                                 <div className="converted_btn display align-items-center">
                                                                 <Checkbox
                                                                 icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                                                                 name="is_shipping_same"
                                                                 checked={''}
                                                                 value={''}
                                                                 onChange={(value) => {
                                                                      let p = {
                                                                      isTrue: value,
                                                                      };
                                                                      handleCheckbox(value,setFieldValue,props)
                                                                 }}
                                                                 borderColor="#cccccc"
                                                                 className="checkmark me-2"
                                                                 style={{ cursor: "pointer" }}
                                                                 labelStyle={{ marginLeft: 5, userSelect: "none", 'font-weight': 'normal' }}
                                                                 label=""
                                                                 /> 
                                                                 <h6>Click to Billing as Shipping</h6>
                                                                 </div>
                                                            </div>   
                                                       </div>
                                                  </div>
                                             
                                                  <div className="display align-items-start flex-wrap">
                                                       <div className="col-md-6 circletrick">
                                                            <div className="display align-items-start flex-wrap">
                                                                 <div className="col-md-12 circletrick quoteform">
                                                                      <Form.Group className="mb-125 display">
                                                                           <Form.Label className="text-right w-17">billing street</Form.Label>
                                                                           
                                                                           <Col sm="6">
                                                                                <Form.Group id="address">
                                                                                     <Form.Control type="text" name="address" value={values.address} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter address" className={errors.address && touched.address ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.address}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="address" />
                                                                                     </span>
                                                                                </Form.Group>

                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick position-relative quoteform">
                                                                      <Form.Group className="mb-125 display">
                                                                           <Form.Label className="text-right w-17">billing city</Form.Label>
                                                                           <Col sm="6">
                                                                                <Form.Group id="city">
                                                                                     <Form.Control type="text" name="city" value={values.city} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter city" className={errors.city && touched.city ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.city}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="city" />
                                                                                     </span>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick position-relative quoteform">
                                                                      <Form.Group className="display align-items-start mb-125">
                                                                           <Form.Label className="text-right w-17 mt-1">billing state</Form.Label>
                                                                           
                                                                           <Col sm="6">
                                                                                <Form.Group id="state">
                                                                                     <Form.Control type="text" name="state" value={values.state} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter state" className={errors.state && touched.state ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.state}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="state" />
                                                                                     </span>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick position-relative quoteform">
                                                                      <Form.Group className="display align-items-start mb-125">
                                                                           <Form.Label className="text-right w-17 mt-1">billing code</Form.Label>
                                                                           
                                                                           <Col sm="6">
                                                                                <Form.Group id="zipcode">
                                                                                     <Form.Control type="text" name="zipcode" value={values.zipcode} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter zipcode" className={errors.zipcode && touched.zipcode ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.zipcode}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="zipcode" />
                                                                                     </span>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick position-relative quoteform leadlist">
                                                                      <Form.Group className="display align-items-start mb-125">
                                                                           <Form.Label className="text-right w-17 mt-1">billing country</Form.Label>       
                                                                 
                                                                           <Col sm="6">
                                                                                     <Form.Group id="country_id">
                                                                                          <Select
                                                                                               //value={countrysLists.filter(x=>x.value.includes(selectedCountry))}
                                                                                               //value={selectedCountry}
                                                                                               value={countrysLists.filter(({ value }) => value === billingCountryId)} 
                                                                                               name="country_id"
                                                                                               options={countrysLists}
                                                                                               className="basic-multi-select"
                                                                                               classNamePrefix="select"
                                                                                               menuPosition="fixed"
                                                                                               onChange={selectedOption => {
                                                                                                    values.country_id = selectedOption.value;
                                                                                                    handleChange("country_id");
                                                                                                    setBillingCountryId(selectedOption.value)
                                                                                               }}
                                                                                               onBlur={setFieldTouched}
                                                                                               styles={customStyles1}
                                                                                               //isClearable={false}
                                                                                               //isSearchable={false}
                                                                                               theme={(theme) => ({
                                                                                                    ...theme,
                                                                                                    borderRadius: 0,
                                                                                                    colors: {
                                                                                                         ...theme.colors,
                                                                                                         primary25: 'var(--primary-color-light)',
                                                                                                         primary: 'var(--primary-color)',
                                                                                                    },
                                                                                               })}

                                                                                          />
                                                                                          <span className="error">{defaultValue.error_list.country_id}</span>
                                                                                          <span className="error error4">
                                                                                               <ErrorMessage name="country_id" />
                                                                                          </span>
                                                                                     </Form.Group>
                                                                                </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="col-md-6 circletrick">
                                                            <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick quoteform">
                                                                      <Form.Group className="mb-125 display">
                                                                           <Form.Label className="text-right w-17">Shipping street</Form.Label>
                                                                           
                                                                           <Col sm="6">
                                                                                <Form.Group id="s_address">
                                                                                     <Form.Control type="text" name="s_address" value={values.s_address} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter address" className={errors.s_address && touched.s_address ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.s_address}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="s_address" />
                                                                                     </span>
                                                                                </Form.Group>

                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick position-relative quoteform">
                                                                      <Form.Group className="mb-125 display">
                                                                           <Form.Label className="text-right w-17">Shipping city</Form.Label>
                                                                           <Col sm="6">
                                                                                <Form.Group id="s_city">
                                                                                     <Form.Control type="text" name="s_city" value={values.s_city} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter city" className={errors.s_city && touched.s_city ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.s_city}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="s_city" />
                                                                                     </span>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick position-relative quoteform">
                                                                      <Form.Group className="display align-items-start mb-125">
                                                                           <Form.Label className="text-right w-17 mt-1">Shipping state</Form.Label>
                                                                           
                                                                           <Col sm="6">
                                                                                <Form.Group id="s_state">
                                                                                     <Form.Control type="text" name="s_state" value={values.s_state} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter state" className={errors.s_state && touched.s_state ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.s_state}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="s_state" />
                                                                                     </span>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick position-relative quoteform">
                                                                      <Form.Group className="display align-items-start mb-125">
                                                                           <Form.Label className="text-right w-17 mt-1">Shipping code</Form.Label>
                                                                           
                                                                           <Col sm="6">
                                                                                <Form.Group id="s_zipcode">
                                                                                     <Form.Control type="text" name="s_zipcode" value={values.s_zipcode} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter zipcode" className={errors.s_zipcode && touched.s_zipcode ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.s_zipcode}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="s_zipcode" />
                                                                                     </span>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick position-relative quoteform leadlist">
                                                                      <Form.Group className="display align-items-start mb-125">
                                                                           <Form.Label className="text-right w-17 mt-1">Shipping country</Form.Label>       
                                                                 
                                                                           <Col sm="6">
                                                                                     <Form.Group id="s_country_id">
                                                                                          <Select
                                                                                               //defaultValue={selectedShippingCountry}
                                                                                               value={shippingCountrysLists.filter(({ value }) => value === shippingCountryId)} 
                                                                                               name="s_country_id"
                                                                                               options={shippingCountrysLists}
                                                                                               className="basic-multi-select"
                                                                                               classNamePrefix="select"
                                                                                               menuPosition="fixed"
                                                                                               onChange={selectedOption => {
                                                                                                    values.s_country_id = selectedOption.value;
                                                                                                    handleChange("s_country_id");
                                                                                                    setShippingCountryId(selectedOption.value)
                                                                                               }}
                                                                                               onBlur={setFieldTouched}
                                                                                               styles={customStyles1}
                                                                                               //isClearable={false}
                                                                                               //isSearchable={false}
                                                                                               theme={(theme) => ({
                                                                                                    ...theme,
                                                                                                    borderRadius: 0,
                                                                                                    colors: {
                                                                                                         ...theme.colors,
                                                                                                         primary25: 'var(--primary-color-light)',
                                                                                                         primary: 'var(--primary-color)',
                                                                                                    },
                                                                                               })}

                                                                                          />
                                                                                          <span className="error">{defaultValue.error_list.s_country_id}</span>
                                                                                          <span className="error error4">
                                                                                               <ErrorMessage name="s_country_id" />
                                                                                          </span>
                                                                                     </Form.Group>
                                                                                </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       {/*--- first-row ---*/}
                                                  </div>

                                                  {/* <Tableddddd data={{setFieldValue,values,setFieldTouched}}/> */}
                                                  <QuoteItems data={{setFieldValue,values,setFieldTouched,productList,preferredCurrencyData,productIds}}/>
                                                                                               
                                                  <div className="display align-items-start flex-wrap terms mb25">
                                                       <div className="col-md-3 circletrick">
                                                            <h4>Terms and Conditions</h4>
                                                       </div>
                                                       <div className="col-md-9 circletrick">
                                                            {/* <div className="display align-items-start flex-wrap">
                                                                 <div className="col-md-12 circletrick position-relative"> */}
                                                                      <Form.Group className="display align-items-start mb-2 quoteform">
                                                                           {/* <Form.Label className="text-right w-17 mt-1">terms and Conditions</Form.Label> */}
                                                                           
                                                                           <Col sm="8">
                                                                                <Form.Group id="terms_and_condition">
                                                                                     <Form.Control as="textarea" name="terms_and_condition" minLength={2} maxLength={150} onChange={handleChange} onBlur={handleBlur} placeholder="Enter terms and condition" className={errors.terms_and_condition && touched.terms_and_condition ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.terms_and_condition}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="terms_and_condition" />
                                                                                     </span>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                            </div>
                                                       {/* </div> */}
                                                  {/* </div> */}

                                                  <div className="display align-items-start flex-wrap terms mb25">
                                                       <div className="col-md-3 circletrick">
                                                            <h4>Description Information</h4>
                                                       </div>
                                                       <div className="col-md-9 circletrick">
                                                            {/* <div className="display align-items-start flex-wrap">
                                                                 <div className="col-md-12 circletrick position-relative"> */}
                                                                      <Form.Group className="display align-items-start mb-2 quoteform">
                                                                           {/* <Form.Label className="text-right w-17 mt-1">Description</Form.Label> */}
                                                                           
                                                                           <Col sm="8">
                                                                                <Form.Group id="description">
                                                                                     <Form.Control as="textarea" name="description" minLength={2} maxLength={150} onChange={handleChange} onBlur={handleBlur} placeholder="Enter description" className={errors.description && touched.description ?
                                                                                          "input-error" : null} />
                                                                                     <span className="error">{defaultValue.error_list.description}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="description" />
                                                                                     </span>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                            {/* </div>
                                                       </div> */}
                                                  </div>
                                                  
                                                  <div className="display align-items-center">                                         
                                                       <div className="text-center mt30">
                                                            {
                                                                 isSubmitting ? 

                                                                 <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                                                                 <Spinner animation="border" size="sm" />
                                                                 <span className="ms-2 text-center">Please wait...</span>
                                                                 </Button>
                                                                 :

                                                                 <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                                 <div className="btn-group mr-2" role="group" aria-label="First group">
                                                                      <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                                                                 </div>
                                                                 </div>
                                                            }
                                                       </div>  
                                                  </div>                                       


                                             </Form>
                                        );
                                   }}
                                   </Formik>
                                        </Accordion.Body>
                                   </Accordion.Item>
                              </Accordion>
                         </div>
                    </Col>
               </div>

          );

     }


};

export default CreateQuote;