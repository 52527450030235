import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Button, Dropdown, Card, Form, Spinner, Modal } from '@themesberg/react-bootstrap';
import { useHistory, } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/PreloaderCenter";
import ShowPerPagePagination from "../../components/ShowPerPagePagination";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import Pagination from "react-js-pagination";
import CurrencyInput from 'react-currency-input-field';
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData, LeadImportPopUp, LeadAddPopUp, NotificationCount, NotificationList } from "../../features/userSlice";
import DatePicker from 'react-datepicker';
import moment from "moment-timezone";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Checkbox from "react-custom-checkbox";
import { useDropzone } from 'react-dropzone'
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
//========Component for DnD=========//
import DNDExample from "../../components/DNDExample";
import AddLead from "../../components/AddLead";
import ExportData from "../../components/ExportData";
import { displayCalendorDateFormate } from '../../utils/utils';
//=================================//

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var searchData = '';
var searchInputDataName = '';
var searchInputDataEmail = '';
var searchInputDataFromDate = '';
var searchInputDataToDate = '';
var searchInputDataTitle = '';
var searchInputDataStatus = '';
var searchInputDataPhone = '';
var searchInputDataOrganizationName = '';

function LeadList(props) {

    const location = props.history.location;
    const loginUser = useSelector(selectUser);
    const [leadSourceList, setLeadSourceList] = useState([])
    const dispatch = useDispatch();

    const [isCheck, setIsCheck] = useState([]);
    const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
    const [mainChecked, setMainChecked] = useState(false);

    const user = loginUser ? loginUser : [];
    const userPermissions = useSelector((state) => state.permission);
    const userPermission = userPermissions ? userPermissions : [];

    const defaultData = useSelector(selectDefaultData);
    const defaultTimeZone = user?.preferred_timezone || defaultData.defaultTimeZone

    const [listView, setListView] = useState(true);
    const [search, setSearch] = useState('');
    const [statusArray, setStatusArray] = useState('');
    const [lSourceArray, setLSourceArray] = useState('');
    const [searchUser, setSearchUser] = useState('');

    const [getIpResponse, setGetIpResponse] = useState([]);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [userLists, setUserLists] = useState([]);
    const [ownerUserId, setOwnerUserId] = useState('');
    const [ownerUpdate, setOwnerUpdate] = useState(false);
    const [changeOwnerArray, setChangeOwnerArray] = useState([]);

    const [showConvertDeal, setShowConvertDeal] = useState(false);
    const handleCovertClose = () => setShowConvertDeal(false);

    const [showChangeOwner, setShowChangeOwner] = useState(false);

    const showAddLead = useSelector((state) => state.leadAddPopUp);

    const showImportLead = useSelector((state) => state.leadImportPopUp);
    const handleImportLeadClose = () => dispatch(LeadImportPopUp(false));

    const [lodingImport, setLodingImport] = useState(false);
    const [importFile, setImportFile] = useState('');
    const [importFileName, setImportFileName] = useState('');

    const [convertDeal, setConvertDeal] = useState(false);

    const [leadID, setleadID] = useState('');
    const [stageID, setStageID] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [orgaName, setOrgaName] = useState('');
    const [leadSource, setLeadSource] = useState('');

    const [expectedClosingDate, setExpectedClosingDate] = useState(new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone })));

    const [expectedClosingDateError, setExpectedClosingDateError] = useState(false);

    const [dealInput, setDealInput] = useState({
        deal_description: '',
        deal_amount: '',
        deal_probability: '',
        expected_closing_date: '',
        error_list: [],
    });

    const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
    const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
    const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');

    const [searchString, setSearchString] = useState();

    //==============START: Handle new search filter option==================//
    const handleSearchInput = (e) => {
        setSearchString(e.target.value);
    }

    const manageSearch = (e) => {
        e.preventDefault();
        setSearch(searchString)
    }

    const handleSelectLeadSource = (event) => {
        setLSourceArray(event.value)
    }

    const handleSelectUser = (event) => {
        setSearchUser(event.value)
    }

    //==============END: Handle new search filter option==================//
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [listLead, setLead] = useState([]);

    //=========START:CODE FOR FOOTER PAGINATION===========//
    const [dataCount, setdataCount] = useState('');
    const [state, setData] = useState({
        users: ''
    });

    const columns = ["name", "title", "phone", 'organization_name', "added_by"];

    const SORT_ASC = "asc"
    const SORT_DESC = "desc"
    const [sortColumn, setSortColumn] = useState()

    const handleSort = async (column) => {
        // setSortColumn(column)
        // sortOrder = SORT_ASC ? SORT_DESC : SORT_ASC
        // getData(currentPage,column);

        if (column === sortColumn) {
            sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC

        } else {
            setSortColumn(column)
            sortOrder = SORT_DESC
        }
        getData(currentPage, column);
    }

    const handlePerPage = (Page) => {
        setLoading(true);
        currentPage = 1;
        perPage = Page;
        getData();
    }
    //=========END:CODE FOR FOOTER PAGINATION===========//

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            await getData();
        }
        fetchData();
        //}, [sortColumn, sortOrder, search, statusArray, lSourceArray, location, searchUser, listView, showAddLead, searchInputDataName, searchInputDataEmail, searchInputDataFromDate, searchInputDataToDate, searchInputDataTitle, searchInputDataStatus, searchInputDataPhone, searchInputDataOrganizationName]);
    }, [sortOrder, search, lSourceArray, searchUser, listView, showAddLead, location]); // Or [] if effect doesn't need props or state




    const getData = async (currentPage, column) => {
        const params = {
            search: search,
            search_user_id: searchUser,
            search_lead_source: lSourceArray,
            sort_field: column ? column : sortColumn,
            sort_order: sortOrder,
            per_page: perPage,
            page: currentPage,
        }

        await axios.get(`api/lead?page=${currentPage}`, { params }).then(response => {
            if (response.status === 200) {
                setLead(response.data.data.records.data);
                setdataCount(response.data.data.records.data.length);
                setData({
                    users: response.data.data.records
                });

                setUserLists(response.data.data.users)
                setLeadSourceList(response.data.data.leadSources)
                setPreferredCurrencyData(response.data.data.preferredCurrency);
                setPreferredDateFormatData(response.data.data.preferredDateFormat);

                //calendorDateFormate

                const calendorFormate = displayCalendorDateFormate(response.data.data.preferredDateFormat)
                setCalendorDateFormate(calendorFormate ? calendorFormate : '');

                dispatch(
                    NotificationList(response.data.data.notifications),
                );

                dispatch(
                    NotificationCount(response.data.data.notifications.length),
                );


                setLoading(false);
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                setLoading(false);
                if (error.response.status === 403 || error.response.status === 422) {
                    toast.warning('Forbidden ' + error.response.data.message);
                    history.push('/dashboard');
                } else {
                    //swal("error", error.response.data.message, "error");
                    toast.error(error.response.data.message);
                    history.push('/dashboard');
                }
            }
        });

    }

    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            const willDelete = await swal({
                title: "Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });

            if (willDelete) {
                try {
                    const response = await axios.delete('api/lead/destroy', { data });
                    if (response.status === 200) {
                        toast.success(response.data.message);
                        getData(currentPage);  // Assuming currentPage is defined in the scope
                        handleCheckAll(false);
                        setIsCheckBoxCheck('');
                        setIsCheck([]);
                    }
                } catch (error) {
                    if (error.response) {
                        if (error.response.status === 400 || error.response.status === 422) {
                            toast.warning(error.response.data.message);
                        } else if (error.response.status === 403) {
                            toast.warning('Forbidden ' + error.response.data.message);
                        } else {
                            toast.error(error.response.data.message);
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Error in swal: ", error);
        }
    }

    async function bulkDeleteData(ids) {
        const data = {
            id: ids,
        }

        try {
            const willDelete = await swal({
                title: "Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });

            if (willDelete) {
                try {
                    const response = await axios.delete('api/lead/bulk-destroy', { data });
                    if (response.status === 200) {
                        toast.success(response.data.message);
                        getData(currentPage);
                        handleCheckAll(false)
                        setIsCheckBoxCheck('');
                        setIsCheck([])
                    }
                } catch (error) {
                    if (error.response) {
                        if (error.response.status === 400 || error.response.status === 422) {
                            toast.warning(error.response.data.message);
                        } else if (error.response.status === 403) {
                            toast.warning('Forbidden ' + error.response.data.message);
                        } else {
                            toast.error(error.response.data.message);
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Error in swal: ", error);
        }
    }

    const addDefaultSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
    }

    const [defaultValue, setdefaultValue] = useState({
        deal_name: '',
        deal_description: '',
        deal_amount: '',
        deal_probability: '',
        expected_closing_date: '',
        error_list: [],
    })

    //======Convert Lead to Deal Code=========//
    function showConvertModel(id, stage_id, ownerName, OrganizationName, leadSource) {
        setDealInput({
            deal_description: '',
            deal_amount: '',
            deal_probability: '',
            expected_closing_date: '',
            lead_source: '',
            error_list: [],
        });

        setdefaultValue({
            deal_name: OrganizationName + '-deal',
            deal_description: '',
            deal_amount: '',
            deal_probability: '',
            expected_closing_date: '',
            lead_source: leadSource,
            error_list: [],
        })

        setleadID(id)
        setStageID(stage_id)
        setOwnerName(ownerName.replace("null ", ''))
        setOrgaName(OrganizationName)
        setLeadSource(leadSource)
        setShowConvertDeal(true)
        setExpectedClosingDate(new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone ? defaultTimeZone : '', })));
    }

    const lengthValidation = (fieldLength) => {
        var valid = true;
        if (fieldLength) {
            var str = fieldLength.replace(/\s/g, '')
            if (str.length < 2) {
                valid = false;
            }
        }

        return valid ? {
            isValid: true,
        } : {
            isValid: false,
            errorMessage: 'Must be at least 2 characters, no space allow',
        }
    }

    const validationSchema = yup.object().shape({
        deal_name: yup.string().trim('Deal name cannot include space').strict(true).required('Enter deal name').max(55, 'Must be at most 55 characters'),
        deal_amount: yup.string().required('Enter deal amount'),
        deal_description: yup.string().required('Enter deal description').min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters')
            .test('validator-custom-length', function (value) {
                const validation = lengthValidation(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                }
                else {
                    return true;
                }
            }),
        deal_probability: yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter deal probability').positive().max('100', "Please enter less than 100%")

    })

    const handleSubmit = async (values, onSubmitProps) => {
        await fetch(`https://ipapi.co/json/`)
            .then(response => response.json())
            .then(data =>
                setGetIpResponse(data)
            )
            .catch(error => '');

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            });
        } else {
        }

        var DealName = ''
        if (values.deal_name == '-deal') {
            DealName = orgaName + values.deal_name
        } else {
            DealName = values.deal_name
        }
        const data = {
            lead_id: leadID,
            deal_name: DealName,
            deal_description: values.deal_description,
            deal_amount: values.deal_amount,
            deal_probability: values.deal_probability,
            stage_id: stageID,
            lead_source: leadSource,
            expected_closing_date: moment(expectedClosingDate).format("YYYY-MM-DD"),
            latitude: getIpResponse.latitude ? getIpResponse.latitude : latitude,
            longitude: getIpResponse.longitude ? getIpResponse.longitude : longitude,
        }

        if (!expectedClosingDateError) {
            setConvertDeal(true);
            await axios.post("/api/deal/convert", data).then(response => {
                if (response.status === 200) {
                    toast.success('Converted successfully.');
                    setDealInput({ deal_description: '', deal_amount: '', deal_probability: '', expected_closing_date: '', error_list: [] });
                    setdefaultValue({ deal_description: '', deal_amount: '', deal_probability: '', expected_closing_date: '', error_list: [] });
                    setShowConvertDeal(false);
                    setConvertDeal(false);
                }
                history.push('/deals');
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    setConvertDeal(false);
                    onSubmitProps.setSubmitting(false)
                    if (error.response.status === 400 || error.response.status === 422) {
                        setDealInput({ ...dealInput, error_list: error.response.data.validation_errors });
                        setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
                        if (error.response.data.message.stage_id) {
                            toast.warning(error.response.data.message.stage_id[0] ? 'Please select stage before convert in to deal' : 'Please select stage');
                        }
                    } else if (error.response.status === 403) {
                        toast.warning('Forbidden ' + error.response.data.message);
                    } else if (error.response.status === 300) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error('Oops Something went wrong.');
                    }
                }
            });
        }
    }

    //==== select_mate
    const customStyles = {}

    //Export data file for logic
    const expParams = {
        search: searchData,
        search_name: searchInputDataName,
        search_email: searchInputDataEmail,
        search_from_date: searchInputDataFromDate,
        search_to_date: searchInputDataToDate,
        search_title: searchInputDataTitle,
        search_status: searchInputDataStatus,
        search_phone: searchInputDataPhone,
        search_organization_name: searchInputDataOrganizationName,
        sort_field: sortColumn,
        sort_order: sortOrder,
        per_page: perPage,
        page: currentPage,
        selectedIds: isCheck ? isCheck : []
    };


    const handleView = async () => {
        listView ? setListView(false) : setListView(true);
    }


    const handleChangeOwner = () => {
        if (isCheck && isCheck.length > 0) {
            setChangeOwnerArray(isCheck)
            setShowChangeOwner(true);
        } else {
            toast.warning('Please select at least one record to change ownership.');
        }
    }

    const handleBulkDelete = () => {
        if (isCheck && isCheck.length > 0) {
            bulkDeleteData(isCheck)
        } else {
            toast.warning('Please select at least one record to Delete.');
        }
    }

    const handleLeadChangeOwner = (event) => {
        setOwnerUserId(event.value);
    }

    const handleChangeOwnerClose = () => {
        setShowChangeOwner(false);

        handleCheckAll(false)
        setIsCheckBoxCheck('');
        setIsCheck([])
    }

    const handleChangeOwnerSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('owner_id', ownerUserId)
        for (var i = 0; i < changeOwnerArray.length; i++) {
            formData.append('lead_id[]', changeOwnerArray[i])
        }

        setOwnerUpdate(true)
        await axios.post("api/lead-change-owner", formData).then(response => {
            setOwnerUpdate(false)
            setShowChangeOwner(false);
            getData(currentPage);

            handleCheckAll(false)
            setIsCheckBoxCheck('');
            setIsCheck([])
            setOwnerUserId()
            toast.success('Owner update successfully');
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                setOwnerUpdate(false)
                setOwnerUserId()
                if (error.response.status === 400 || error.response.status === 422) {
                    toast.warning(error.response.data.message);
                } else if (error.response.status === 403) {
                    toast.warning(error.response.data.message);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        });
    }

    //============Start:- Handle check all ================//
    const handleCheckbox = (value, id) => {
        if (value) {
            setIsCheck(isCheck => [...isCheck, id]);
        } else {
            setIsCheck(isCheck.filter(x => x !== id));
        }
    }

    const handleCheckAll = (value) => {
        if (value) {
            setMainChecked(true)
        } else {
            setMainChecked(false)
        }

        setIsCheckBoxCheck(value);
        setIsCheck([]);

        if (value) {
            setIsCheckBoxCheck(value);
            setIsCheck(listLead.map(li => li.id));
        }
    }
    //============End:- Handle check all ================//



    function Dropzone(props) {

        const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            multiple: false,
            accept: {
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            },
            maxFiles: 1,
            maxSize: 2097152,
        });


        const filesss = acceptedFiles.map(file => (
            setImportFile(file)
        ));

        const files = acceptedFiles.map(file => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
        ));



        const fileRejectionItems = fileRejections.map(({ file, errors }) => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => (
                        <li key={e.code}>{e.message}</li>
                    ))}
                </ul>
            </li>
        ));

        useEffect(() => {
            if (files.length) {
                setImportFileName(files)
            }
        }, [files]);

        return (
            <div className="w-100">
                <div {...getRootProps({ className: 'dropzone' })}>
                    <div className="dropzoneflex">
                        <input {...getInputProps()} />
                        <span className="uploadicon"></span>
                        <h3>Drag a File to Upload</h3>
                        <span className="uploadline mb15"><span className="leftline"><h6>or</h6></span></span>
                        <button type="button" className="popupbtn btnblue" onClick={open}>
                            Browse
                        </button>
                        <span>{importFileName}</span>
                    </div>
                </div>
                <aside>
                    <ul className="text-success">{files}</ul>
                    <ul className="text-danger">{fileRejectionItems}</ul>
                </aside>
            </div>
        );
    }



    const handleImportSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('lead_file', importFile)
        setLodingImport(true)

        await axios.post("api/lead/import", formData).then(response => {
            setLodingImport(false)
            getData();
            toast.success('Lead uploded successfully');
            dispatch(LeadImportPopUp(false));
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                setLodingImport(false)
                if (error.response.status === 400 || error.response.status === 422) {
                    toast.warning(error.response.data.message);
                } else if (error.response.status === 403) {
                    toast.warning('Forbidden ' + error.response.data.message);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        });
    }
    //==========End: Convert lead to deal code===============//


    function addLead() {
        dispatch(LeadAddPopUp(true));
    }

    function showHideImportLead() {
        setImportFileName('')
        dispatch(LeadImportPopUp(true));
    }

    //==============For Lead Status Filter
    let statusList = [
        { label: "All Status", value: "" },
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" }
    ]

    let defaultStatus = [];
    statusList.map((status) => {
        if (statusArray == status.value) {
            defaultStatus.push({ label: status.label, value: status.value });
        }
    }, this);

    //==============For Lead Source Filter

    let defaultAllUser = [];
    if (!searchUser) {
        defaultAllUser = [{ label: 'All Users', value: '' }];
    }

    userLists.map((item) => {
        if (searchUser == item.id) {
            defaultAllUser.push({ label: item.name, value: item.id });
        }
    }, this);

    let ListUserArray = [{ label: 'All Users', value: '' }];
    userLists.map((item) => {
        ListUserArray.push({ label: item.name, value: item.id });
    }, this);

    let defaultLeadSource = [];
    if (!lSourceArray) {
        defaultLeadSource = [{ label: 'All Sources', value: '' }];
    }

    if (lSourceArray === 'lead_source_blank') {
        defaultLeadSource.push({ label: 'No Sources', value: 'lead_source_blank' });
    }

    leadSourceList.map((item) => {
        if (lSourceArray === item.title) {
            defaultLeadSource.push({ label: item.title, value: item.title });
        }
    }, this);


    let leadSourceListArray = [{ label: 'All Sources', value: '' }];
    leadSourceList.map((item) => {
        leadSourceListArray.push({ label: item.title, value: item.title });
    }, this);

    leadSourceListArray.push({ label: 'No Sources', value: 'lead_source_blank' });

    let defaultUser = [];
    userLists.map((item) => {
        defaultUser.push({ label: item.name, value: item.id });
    }, this);

    var displayUserData = '';

    if (loading) {
        return <Preloader show={true} />;
    } else {
        if (listView) {
            displayUserData = listLead.map((item, i) => {
                let FFName = ''
                let Fullname = ''

                if (item.first_name && item.last_name) {
                    FFName = item.first_name.charAt(0) + item.last_name.charAt(0);
                    Fullname = item.first_name + ' ' + item.last_name;
                } else if (item.first_name && !item.last_name) {
                    FFName = item.first_name.charAt(0);
                    Fullname = item.first_name;
                } else if (!item.first_name && item.last_name) {
                    FFName = item.last_name.charAt(0);
                    Fullname = item.last_name;
                }
                return (
                    <div className="list_view bordertop pt16 pb18" key={i}>
                        {/*--- checkmark_section ---*/}
                        <div className="chbox">
                            <label className="container">
                                <Checkbox
                                    icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                                    name="lead[]"
                                    checked={isCheckBoxCheck}
                                    value={item.id}
                                    onChange={(value) => {
                                        let p = {
                                            isTrue: value,
                                        };
                                        handleCheckbox(value, item.id)
                                    }}
                                    borderColor="#cccccc"
                                    className="checkmark"
                                    style={{ cursor: "pointer" }}
                                    labelStyle={{ marginLeft: 5, userSelect: "none", 'font-weight': 'normal' }}
                                    label=""
                                />
                                <span className="checkmark-----"></span>
                            </label>
                        </div>

                        {/*--- image_and_name_section ---*/}
                        <div className="listdetails">
                            <Stack direction="row" spacing={3}>
                                <Avatar
                                    alt={Fullname}
                                    src={item.profile_pic ? `${process.env.REACT_APP_API_URL}storage/${item.profile_pic}` : ''}
                                    sx={{ width: 76, height: 76 }}
                                    onError={addDefaultSrc}
                                    className="listimg"
                                >
                                    {FFName}
                                </Avatar>
                            </Stack>

                            {/*---details_section ---*/}
                            <div className="leaddetails">
                                <h5>
                                    {
                                        userPermission ?
                                            (userPermission.includes('lead_edit')) ?
                                                <a href={'edit-lead/' + item.id} target="_blank" style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.selected_salutation != '' ? item.selected_salutation : ''} {item.first_name + ' ' + item.last_name}</a>
                                                :
                                                item.selected_salutation ? item.selected_salutation + item.first_name + ' ' + item.last_name : item.first_name + ' ' + item.last_name
                                            :
                                            item.selected_salutation ? item.selected_salutation + item.first_name + ' ' + item.last_name : item.first_name + ' ' + item.last_name
                                    }
                                </h5>
                                {/*--- lead_inner1_section */}
                                <div className="leadinner">
                                    <div className="display flex-wrap align-items-start">
                                        {/*--- lead_title_section */}
                                        <div className="leadtitle display align-items-start">
                                            <span>Title:</span>
                                            <h6>{item.title ? item.title : 'N/A'}</h6>
                                        </div>
                                        {/*--- lead_company_section */}
                                        <div className="leadtitle display align-items-start">
                                            <span>Company:</span>
                                            <h6>{item.organization_name ? item.organization_name : 'N/A'}</h6>
                                        </div>
                                    </div>
                                </div>

                                {/*--- lead_inner2_section */}
                                <div className="leadinner">
                                    <div className="display flex-wrap align-items-start">
                                        {/*--- lead_date_section */}
                                        <div className="leadtitle display align-items-start">
                                            <span>Created:</span>
                                            <h6>{moment.tz(item.created_at, defaultTimeZone).format(preferredDateFormatData)}</h6>
                                        </div>
                                        {/*--- lead_phone_section */}
                                        <div className="leadtitle display align-items-start">
                                            <span>Phone:</span>
                                            <h6>{item.phone1 ? item.phone1 : 'N/A'}</h6>
                                        </div>
                                        {/*--- lead_cold_section */}
                                        <div className="leadtitle display align-items-start">
                                            <span>Source:</span>
                                            <h6>{item.lead_source_name != null ? item.lead_source_name : 'N/A'}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/*--- owner_section ---*/}
                        <div className="listowner">

                            <div className="leadinner">
                                <div className="display flex-wrap">
                                    {/*--- lead_title_section */}
                                    <div className="leadtitle display">
                                        <span>{item.added_by != null ? item.added_by.name : 'N/A'}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="leadinner">
                                <div className="display flex-wrap">
                                    {/*--- lead_title_section */}
                                    <div className="leadtitle display">
                                        <span>Last Updated:</span>
                                        <h6>{moment.utc(item.updated_at).tz(defaultTimeZone).format(preferredDateFormatData)}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*--- action_section ---*/}
                        <div className="listaction text-right">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <span className="listdot"></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        userPermission ?
                                            (userPermission.includes('lead_edit')) ?
                                                <Dropdown.Item onClick={() => { showConvertModel(item.id, item.stage_id, item.selected_salutation + ' ' + item.first_name + ' ' + item.last_name, item.organization_name, item.lead_source) }}>Convert</Dropdown.Item>
                                                :
                                                ''
                                            :
                                            ''
                                    }

                                    {
                                        userPermission ?
                                            (userPermission.includes('lead_edit')) ?
                                                <Dropdown.Item href={'edit-lead/' + item.id} target="_blank">Edit</Dropdown.Item>
                                                :
                                                ''
                                            :
                                            ''
                                    }

                                    {
                                        userPermission ?
                                            (userPermission.includes('lead_delete')) ?
                                                <Dropdown.Item onClick={() => { deleteData(item.id) }}>Delete</Dropdown.Item>
                                                :
                                                ''
                                            :
                                            ''
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                );
            });

            return (
                <>
                    <div className="searchbar mt30">
                        <div className="col-md-4">
                            <form onSubmit={manageSearch}>
                                <input type="text" name="searchData" className="form-control search" value={searchString} placeholder="Search this list...." onChange={handleSearchInput} ></input><span className="listicon" onClick={manageSearch}></span>
                            </form>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-6 display justify-content-end">
                            <div className="dropdown-section display">
                                {
                                    userPermission ?
                                        (userPermission.includes('lead_create')) ?
                                            <Dropdown className="mr15">
                                                <Dropdown.Toggle id="dropdown-basic" className="createbtn btnbluecolor">
                                                    Create New<span className="createicon"></span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => addLead()}>Add Lead</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => showHideImportLead()}>Import Leads</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            :
                                            ''
                                        :
                                        ''
                                }
                                <Select
                                    defaultValue={defaultLeadSource}
                                    name="leadSource"
                                    options={leadSourceListArray}
                                    styles={customStyles}
                                    className="basic-multi-select mr15 br10"
                                    classNamePrefix="select"
                                    onChange={handleSelectLeadSource}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'var(--primary-color-light)',
                                            primary: 'var(--primary-color)',
                                        },
                                    })}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                                <Select
                                    defaultValue={defaultAllUser}
                                    name="user_id"
                                    options={ListUserArray}
                                    styles={customStyles}
                                    className="basic-multi-select mr15 br10"
                                    classNamePrefix="select"
                                    onChange={handleSelectUser}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'var(--primary-color-light)',
                                            primary: 'var(--primary-color)',
                                        },
                                    })}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="icon-section">
                                <Dropdown className="mr15">
                                    <Dropdown.Toggle id="dropdown-basic" className="dropdownborder filterhover">
                                        <span className="filter"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {columns.map((column) => {
                                            return (
                                                <Dropdown.Item key={column} onClick={(e) => handleSort(column)} style={{ cursor: 'pointer' }} >
                                                    {column.charAt(0).toUpperCase() + column.slice(1).replace("_", " ")}
                                                    {["name", "title", "phone", 'organization_name', 'added_by'].includes(column) ? (
                                                        <span className="arrowupdown">
                                                            {column === sortColumn ?
                                                                sortOrder === SORT_ASC ? (
                                                                    <i className="ms-1 fa fa-angle-up" aria-hidden="true"></i>
                                                                ) : (
                                                                    <i className="ms-1 fa fa-angle-down" aria-hidden="true"></i>
                                                                )
                                                                :
                                                                <i className="ms-1 fa fa-angle-up" aria-hidden="true"></i>
                                                            }
                                                        </span>
                                                    ) : null}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <button type="button" className="btn dropdownborder changehover mr15" onClick={() => handleView()}><span className="icon_change"></span></button>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className=" dropdownborder1 dothover">
                                        <span className="dot"></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* <Dropdown.Item onClick={() => exportData()} >Export Leads</Dropdown.Item> */}
                                        <Dropdown.Item>
                                            <ExportData params={expParams} fileName="Lead" api="lead" handleCheckAll={handleCheckAll} />
                                        </Dropdown.Item>
                                        {
                                            userPermission ?
                                                (userPermission.includes('lead_edit')) ?
                                                    <Dropdown.Item onClick={() => handleChangeOwner()}>Change Owner</Dropdown.Item>
                                                    :
                                                    ''
                                                :
                                                ''
                                        }

                                        {
                                            userPermission ?
                                                (userPermission.includes('lead_delete')) ?
                                                    <Dropdown.Item onClick={() => handleBulkDelete()}>Delete</Dropdown.Item>
                                                    :
                                                    ''
                                                :
                                                ''
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                    </div>
                    {/*--- searchbar_section_end ---*/}

                    {/*--- list_view_section_start ---*/}
                    <div className="display list_view mt30 pb4">
                        {/*--- checkmark_section ---*/}
                        <div className="chbox">
                            <label className="container">
                                <Checkbox
                                    icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                                    name="checkAll"
                                    onChange={(value) => {
                                        let p = {
                                            isTrue: value,
                                        };
                                        handleCheckAll(value)
                                    }}
                                    checked={mainChecked}
                                    borderColor="#cccccc"
                                    className="checkmark"
                                    style={{ cursor: "pointer" }}
                                    labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                    label=""
                                />
                            </label>
                        </div>

                        {/*--- details_section ---*/}
                        <div className="listdetails">
                            <h3>Lead Details</h3>
                        </div>
                        {/*--- owner_section ---*/}
                        <div className="listowner">
                            <h3>Owner</h3>
                        </div>
                        {/*--- action_section ---*/}
                        <div className="listaction"></div>
                    </div>


                    {
                        displayUserData.length >= 1 ?
                            displayUserData
                            :
                            <span>
                                No data Available
                            </span>
                    }
                    {
                        displayUserData.length >= 1 ?
                            <Row>
                                <Col xs={12} xl={12} className="showing">
                                    <Card border="light" className="bg-white border-0">
                                        <Card.Body className="listcard pe-0">
                                            <Row className="align-items-center">
                                                <ShowPerPagePagination
                                                    state={state}
                                                    perPage={perPage}
                                                    dataCount={dataCount}
                                                    handlePerPage={handlePerPage}
                                                    handleCheckAll={handleCheckAll}
                                                />
                                                <Col md={5} className="d-flex justify-content-end">
                                                    <Pagination
                                                        activePage={state?.users?.current_page ? state?.users?.current_page : 0}
                                                        itemsCountPerPage={state?.users?.per_page ? state?.users?.per_page : 0}
                                                        totalItemsCount={state?.users?.total ? state?.users?.total : 0}
                                                        onChange={(pageNumber) => {
                                                            currentPage = (pageNumber);
                                                            getData(pageNumber)
                                                            handleCheckAll(false)
                                                        }}
                                                        pageRangeDisplayed={5}
                                                        itemClass="page-item mx-1"
                                                        linkClass="page-link"
                                                    />
                                                </Col>
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            :
                            ''
                    }

                    <Modal as={Modal.Dialog} centered show={showChangeOwner} onHide={handleChangeOwnerClose}>
                        <Modal.Header>
                            <Modal.Title className="h2">Change Lead Owner</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={handleChangeOwnerClose} /><span className="modelclose"></span>
                        </Modal.Header>
                        <Modal.Body>
                            <Form encrypted="multipart/form-data" onSubmit={handleChangeOwnerSubmit} >
                                {/* <Row> */}
                                <div className="col-md-12 col-sm-12">
                                    <Row className="align-items-center">

                                        <Form.Group className="mb-125">
                                            <Form.Label column sm="12">
                                                <h6 className="fw600">Select User</h6>
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Group id="owner_id">
                                                    <Select
                                                        name="owner_id"
                                                        options={defaultUser}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        onChange={handleLeadChangeOwner}
                                                        styles={customStyles}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: 'var(--primary-color-light)',
                                                                primary: 'var(--primary-color)',
                                                            },
                                                        })}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form.Group>
                                    </Row>
                                </div>


                                <div className="text-left">
                                    {
                                        ownerUpdate ?
                                            <Button variant="primary" className="d-flex popupbtn btnblue align-items-center mb-1 text-center">
                                                <Spinner animation="border" size="sm" />
                                                <span className="ms-2">Please Wait...</span>
                                            </Button>
                                            :
                                            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                                    <Button type="submit" variant="success" className="popupbtn btnblue mr15">Submit</Button>
                                                </div>
                                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                                    <Button type="button" variant="success" className="popupbtn greycolor" onClick={handleChangeOwnerClose}>Cancel</Button>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>

                    <Modal as={Modal.Dialog} centered show={showConvertDeal} onHide={handleCovertClose}>
                        <Modal.Header>
                            <Modal.Title className="h2">Convert your lead to deal</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={handleCovertClose} /><span className="modelclose"></span>
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                initialValues={defaultValue}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            // onSubmit={(values, { setSubmitting }) => {
                            //   handleSubmit(values, setSubmitting(false));
                            // }}
                            >{props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    handleReset,
                                    setFieldValue,
                                    setFieldTouched,
                                } = props;
                                return (
                                    <Form encrypted="multipart/form-data" onSubmit={handleSubmit} >
                                        <Row>
                                            <div className="col-md-12 col-sm-12">
                                                <Row className="align-items-center">

                                                    <Form.Group className="mb-125 position-relative">
                                                        <Form.Label column sm="12">
                                                            <h6 className="fw600">Contact person</h6>
                                                        </Form.Label>
                                                        <Col sm="12">
                                                            <Form.Group id="name">
                                                                <Form.Control type="text" readOnly disabled value={ownerName} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-125 position-relative">
                                                        <Form.Label column sm="12">
                                                            <h6 className="fw600">Org. name</h6>
                                                        </Form.Label>
                                                        <Col sm="12">
                                                            <Form.Group id="company_name">
                                                                <Form.Control type="text" readOnly disabled value={orgaName} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-125 position-relative">
                                                        <Form.Label column sm="12">
                                                            <h6 className="fw600">Deal name<span className="text-danger">*</span></h6>
                                                        </Form.Label>
                                                        <Col sm="12">
                                                            <Form.Group id="deal_name">
                                                                <Form.Control type="text" name="deal_name" value={values.deal_name} minLength={2} maxLength={55} onChange={handleChange} onBlur={handleBlur} placeholder="Enter deal name" className={errors.deal_name && touched.deal_name ?
                                                                    "input-error" : null} />
                                                                <span className="error error4">{defaultValue.error_list.deal_name}</span>
                                                                <span className="error error4">
                                                                    <ErrorMessage name="deal_name" />
                                                                </span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-125 position-relative ">
                                                        <Form.Label column sm="12">
                                                            <h6 className="fw600">Amount {preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode + ' ' : ' '}
                                                                ({preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : ''})&nbsp;<span className="text-danger">*</span></h6>
                                                        </Form.Label>
                                                        <Col sm="12">
                                                            <Form.Group id="deal_amount">
                                                                <CurrencyInput
                                                                    id="deal_amount"
                                                                    name="deal_amount"
                                                                    intlConfig={{
                                                                        locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                                                                        currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                                                                    }}
                                                                    placeholder="Enter deal amount"
                                                                    className={errors.deal_amount && touched.deal_amount ?
                                                                        "form-control input-error" : 'form-control'}
                                                                    onValueChange={(value, name) => {
                                                                        setdefaultValue({ ...defaultValue, ['deal_amount']: value });
                                                                        values.deal_amount = value;
                                                                        handleChange(name)
                                                                    }}
                                                                    decimalsLimit={6}
                                                                    value={defaultValue.deal_amount}
                                                                    step={1}
                                                                />
                                                                <span className="error error4">{defaultValue.error_list.deal_amount}</span>
                                                                <span className="error error4">
                                                                    <ErrorMessage name="deal_amount" />
                                                                </span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-125 position-relative">
                                                        <Form.Label column sm="12">
                                                            <h6 className="fw600">Probability(%)&nbsp;<span className="text-danger">*</span></h6>
                                                        </Form.Label>
                                                        <Col sm="12">
                                                            <Form.Group id="deal_probability">
                                                                <Form.Control type="number" max={100} step={0.01} name="deal_probability" onChange={handleChange} onBlur={handleBlur} placeholder="Enter probability" className={errors.deal_probability && touched.deal_probability ?
                                                                    "proability errormessage input-error" : 'proability errormessage'} />
                                                                <span className="error error4">{defaultValue.error_list.deal_probability}</span>
                                                                <span className="percetangeicon"></span>
                                                                <span className="error error4">
                                                                    <ErrorMessage name="deal_probability" />
                                                                </span>
                                                            </Form.Group>

                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-125 position-relative">
                                                        <Form.Label column sm="12">
                                                            <h6 className="fw600">Expected closing date</h6>
                                                        </Form.Label>
                                                        <Col sm="12">
                                                            <Form.Group id="expected_closing_date">
                                                                <DatePicker
                                                                    autocomplete="off"
                                                                    selected={expectedClosingDate == "" ? new Date() : !expectedClosingDateError ? expectedClosingDate : ''}
                                                                    onChange={(date) => {
                                                                        if (date) {
                                                                            setExpectedClosingDate(date)
                                                                            setExpectedClosingDateError(false)
                                                                        } else {
                                                                            setExpectedClosingDateError(true)
                                                                        }
                                                                    }}
                                                                    dateFormat={calendorDateFormate}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                            <span className="calenderinput"></span>
                                                            <span className="error error4">{defaultValue.error_list.expected_closing_date}</span>
                                                            <span className="error error4">
                                                                {expectedClosingDateError ? 'Enter expected closing date' : ''}
                                                            </span>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="position-relative">
                                                        <Form.Label column sm="12">
                                                            <h6 className="fw600">Description<span className="text-danger">*</span></h6>
                                                        </Form.Label>
                                                        <Col sm="12">
                                                            <Form.Group id="deal_description">
                                                                <Form.Control as="textarea" minLength={2} maxLength={150} name="deal_description" onChange={handleChange} onBlur={handleBlur} placeholder="Enter description" className={errors.deal_description && touched.deal_description ?
                                                                    "input-error" : null} />
                                                                <span className="error error4">{defaultValue.error_list.deal_description}</span>
                                                                <span className="error error4">
                                                                    <ErrorMessage name="deal_description" />
                                                                </span>
                                                            </Form.Group>

                                                        </Col>
                                                    </Form.Group>
                                                </Row>
                                            </div>
                                        </Row>
                                        <div className="text-center mt30">
                                            {
                                                isSubmitting ?

                                                    <Button className="d-flex align-items-center popupbtn btnblue justify-content-center text-center btnblue mb-1">
                                                        <Spinner animation="border" size="sm" />
                                                        <span className="ms-2 text-center">Please wait...</span>
                                                    </Button>
                                                    :

                                                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                        <div className="btn-group mr-2" role="group" aria-label="First group">
                                                            <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                                                        </div>
                                                        <div className="btn-group mr-2" role="group" aria-label="First group">
                                                            <Button type="button" variant="success" onClick={handleCovertClose} className="popupbtn greycolor d-flex align-items-center justify-content-center">Cancel</Button>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Form>
                                );
                            }}
                            </Formik>
                        </Modal.Body>

                    </Modal>


                    <Modal as={Modal.Dialog} centered show={showImportLead} onHide={handleImportLeadClose}>
                        <Modal.Header>
                            <Modal.Title className="h2">Import Leads</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={handleImportLeadClose} /><span className="modelclose"></span>
                        </Modal.Header>
                        <Modal.Body>
                            <Form encrypted="multipart/form-data" onSubmit={handleImportSubmit} >
                                <Row>
                                    <div className="col-md-12 col-sm-12">
                                        <Row className="align-items-center">
                                            <Form.Group>
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">Upload doc<span className="error">*</span></h6>
                                                </Form.Label>
                                                <Col sm="12">
                                                    <Dropzone />
                                                </Col>
                                            </Form.Group>
                                            <p className="note pb27">Please upload a file only in 'xls' and 'xlsx' format. File size should not be more than 2 MB</p>
                                            <div className="downloadfile mb39">
                                                <a href={`${process.env.REACT_APP_API_URL}storage/Sample-LeadData.xlsx`} className="display"><span className="download_icon"></span><h6>Download a sample file</h6></a>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                                <div className="text-left">
                                    {
                                        lodingImport ?

                                            <Button className="d-flex align-items-center popupbtn btnblue mb-1">
                                                <Spinner animation="border" size="sm" />
                                                <span className="ms-2">Upload in progress, please wait</span>
                                            </Button>
                                            :

                                            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                                    <Button type="submit" variant="success" className="popupbtn btnblue mr15">Import</Button>
                                                </div>
                                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                                    <Button type="button" variant="success" onClick={handleImportLeadClose} className="popupbtn greycolor d-flex align-items-center justify-content-center">Cancel</Button>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    {
                        userPermission ?
                            (userPermission.includes('lead_create')) ?
                                <AddLead userData={user.preferred_timezone} />
                                :
                                ''
                            :
                            ''
                    }


                </>
            );
        } else {
            return (
                <>
                    <DNDExample data={[{ 'stage': [], 'list': [] }]} handleView={handleView} />
                </>
            );
        }

    }
};


export default LeadList;