import React from "react";
import { Image, Container,  Navbar, Nav, } from '@themesberg/react-bootstrap';
import { Link,useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {  toast } from 'react-toastify';
import { Routes } from "../routes";
import { useDispatch,useSelector } from "react-redux";
import ReactHero from "../assets/img/color_main_logo.png";
import { logout,DisplayHostName } from "../features/userSlice";
import axios from 'axios';
import Cookies from 'universal-cookie';


function Presentation() {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const history = useHistory();

  //get default host data from redux using url
  var defaultHostData = useSelector(DisplayHostName);
  var hostname = defaultHostData?.hostname||''
  var displayHost = defaultHostData?.displayHost||''
  
  const userWorkspaces = useSelector(state =>
    state.workspace
  )
  
  const userWorkspace = userWorkspaces?userWorkspaces:[];
  
  if(cookies.get('auth_token') ){
    //window.location = protocol + "//" + host+'/dashboard';
    history.push('/dashboard')
  }else{
    //window.location = protocol + "//" + host+'/sign-in';
    history.push('/sign-in')
  }
   

  async function redirectToDomain(){
    const { host, protocol } = window.location;
    const parts = host.split(".");
    let subdomain = '';
    
    if (userWorkspace.length) {
      subdomain = userWorkspace.domain || (host === 'solvcrm.asaya.in' ? 'solvcrm' : 'app');
      window.location = `${protocol}//${subdomain}.${host}`;
    } else {
        try {
            const response = await axios.get("/api/user/getLoginUser");
            if (response.status === 200) {
                subdomain = response.data.data.currentTenant.domain || (host === 'solvcrm.asaya.in' ? 'solvcrm' : 'app');

                if (parts.length >= 3) {
                    parts.splice(0, 1);
                    window.location = `${protocol}//${subdomain}.${parts.join(".")}/dashboard`;
                } else {
                    parts.splice(0, 1); //may be its required 
                    window.location = `${protocol}//${subdomain}.${host}/dashboard`;
                }
            }
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 401) {
                    toast.warning('Your session has expired.');
                    dispatch(logout());
                } else if (status === 403) {
                    toast.warning(`Forbidden ${data.message}`);
                } else {
                    toast.error('Oops...! There was an issue with your request.');
                }
            }
        }
    }


    // let host = window.location.host;
    // let protocol = window.location.protocol;
    // let parts = host.split(".");
    
    // if(userWorkspace.length){
    //   if(userWorkspace.domain){
    //     let subdomain = userWorkspace.domain;
    //     window.location = protocol + "//" +'.'+subdomain+'.'+ host; 
    //   }else{
    //     let subdomain =''
    //     if(host === 'solvcrm.asaya.in'){
    //       subdomain = 'solvcrm';
    //     }else{
    //       subdomain = 'app';
    //     }
    //     window.location = protocol + "//" +'.'+subdomain+'.'+ host; 
    //   }
    // }else{
    //   await axios.get("/api/user/getLoginUser").then(response => {
    //     if(response.status===200){
    //       var subdomain = response.data.data.currentTenant.domain;
    //       if(subdomain){
    //         window.location = protocol + "//" +subdomain+'.'+ host+'/dashboard';
    //       }else{
    //         if(host === 'solvcrm.asaya.in'){
    //           subdomain = 'solvcrm';
    //         }else{
    //           subdomain = 'app';
    //         }
    //         if (parts.length >= 3) {
    //             parts.splice(0, 1);
    //             window.location = protocol + "//" + subdomain+'.'+parts.join(".") + "/dashboard";
    //         }else{
    //           window.location = protocol + "//" +subdomain+'.'+ host+'/dashboard';
    //         }
            
    //       }
    //     }
    //     }).then(json => {
           
    //     }).catch(error => {
    //        if(error.response){
    //           if(error.response.status===401){
    //             toast.warning('Your session has been expired.');
    //             dispatch(logout());
    //           }else if (error.response.status === 403) {
    //             toast.warning('Forbidden '+ error.response.data.message);
    //           }else{
    //             toast.error('Opps...! There was a some issue on your request.');
    //           }
    //       }
    //   });
    // }
  }
  
  

  return (
    <>
      {hostname!='solvcrm.co' ? 
        <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
          <Container className="position-relative justify-content-between px-3">
            <Navbar.Brand as={HashLink} to="" className="me-lg-3 d-flex align-items-center">
              <Image src={ReactHero} />
              <span className="ms-2 brand-text d-none d-md-inline">SolvCRM</span>
            </Navbar.Brand>

            <div className="d-flex align-items-center">
              <Navbar.Collapse id="navbar-default-primary">
                {cookies.get('auth_token') ? 
                  <Nav className="navbar-nav-hover align-items-lg-center">
                    { userWorkspace.length != 0 ?
                      <Nav.Link as={Link} to={Routes.DashboardOverview.path}>Welcome <small>{cookies.get('auth_name')}</small></Nav.Link>
                    :
                      <Nav.Link onClick={()=>redirectToDomain()}>Welcome <small>{cookies.get('auth_name')}</small></Nav.Link>
                    }
                  </Nav>
                  :
                  <Nav className="navbar-nav-hover align-items-lg-center">
                    <Nav.Link as={Link} to={Routes.Signin.path}>Login</Nav.Link>
                    <Nav.Link as={Link} to={Routes.Signup.path}>Register</Nav.Link>
                    {/* <Nav.Link href={protocol + "//" + host +'/sign-in'}>Login</Nav.Link>
                    <Nav.Link href={protocol + "//" + host +'/sign-up'}>Register</Nav.Link> */}
                  </Nav>
                }
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>
        :
        ''
      }

       
    </>
  );
};
export default Presentation;
