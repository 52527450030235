import { GoogleMap, Marker, useLoadScript, InfoWindow } from "@react-google-maps/api";

import React, { useState, useMemo } from "react";
import "../assets/css/google-map.css";

const LeadStoreChart2 = (props) => {
  const googles = window.google;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  //const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [isfillData, setIsfillData] = useState(false);
  
  //console.log(props.leadByLocationData,'props')

  const markers = [
    // { address: "Address1", lat: 18.5204, lng: 73.8567 },
    // { address: "Address2", lat: 18.5314, lng: 73.8446 },
    // { address: "Address3", lat: 18.5642, lng: 73.7769 },
    
    // {address: 'Aabheer B', lat: 18.5642, lng: 73.7769},
    // {address: 'Aadarsh C', lat: 18.5314, lng: 73.8446},
    // {address: 'tyutyutyutyu', lat: 18.5204, lng: 73.8567},
    // {address: '567567567', lat: 23.0276, lng: 72.5871},
    // {
  

  //   {
  //     address: "Delhi",
  //     lat: 28.6100,
  //     lng: 77.2300,
  //     country: "India",

  //   },
  //   {
  //     address: "Mumbai",
  //     lat: 19.0761,
  //     lng: 72.8775,
  //     country: "India",

  //   },
  //   {
  //     address: "Kolkāta",
  //     lat: 22.5675,
  //     lng: 88.3700,
  //     country: "India",

  //   },
  //   {
  //     address: "Bangalore",
  //     lat: 12.9789,
  //     lng: 77.5917,
  //     country: "India",

  //   },
  //   {
  //     address: "Chennai",
  //     lat: 13.0825,
  //     lng: 80.2750,
  //     country: "India",

  //   },
  //   {
  //     address: "Hyderābād",
  //     lat: 17.3850,
  //     lng: 78.4867,
  //     country: "India",

  //   },
  //   {
  //     address: "Pune",
  //     lat: 18.5203,
  //     lng: 73.8567,
  //     country: "India",

  //   },
  //   {
  //     address: "Ahmedabad",
  //     lat: 23.0300,
  //     lng: 72.5800,
  //     country: "India",

  //   },
  //   {
  //     address: "Sūrat",
  //     lat: 21.1702,
  //     lng: 72.8311,
  //     country: "India",

  //   },
  //   {
  //     address: "Prayagraj",
  //     lat: 25.4358,
  //     lng: 81.8464,
  //     country: "India",

  //   },
  //   {
  //     address: "Lucknow",
  //     lat: 26.8500,
  //     lng: 80.9500,
  //     country: "India",

  //   },
  //   {
  //     address: "Jaipur",
  //     lat: 26.9000,
  //     lng: 75.8000,
  //     country: "India",

  //   },
  //   {
  //     address: "Cawnpore",
  //     lat: 26.4499,
  //     lng: 80.3319,
  //     country: "India",

  //   },
  //   {
  //     address: "Mirzāpur",
  //     lat: 25.1460,
  //     lng: 82.5690,
  //     country: "India",

  //   },
  //   {
  //     address: "Nāgpur",
  //     lat: 21.1497,
  //     lng: 79.0806,
  //     country: "India",

  //   },
  //   {
  //     address: "Ghāziābād",
  //     lat: 28.6700,
  //     lng: 77.4200,
  //     country: "India",

  //   },
  //   {
  //     address: "Vadodara",
  //     lat: 22.3000,
  //     lng: 73.2000,
  //     country: "India",

  //   },
  //   {
  //     address: "Vishākhapatnam",
  //     lat: 17.7042,
  //     lng: 83.2978,
  //     country: "India",

  //   },
  //   {
  //     address: "Indore",
  //     lat: 22.7167,
  //     lng: 75.8472,
  //     country: "India",

  //   },
  //   {
  //     address: "Thāne",
  //     lat: 19.1972,
  //     lng: 72.9722,
  //     country: "India",

  //   },
  //   {
  //     address: "Bhopāl",
  //     lat: 23.2500,
  //     lng: 77.4167,
  //     country: "India",

  //   },
  //   {
  //     address: "Chinchvad",
  //     lat: 18.6186,
  //     lng: 73.8037,
  //     country: "India",

  //   },
  //   {
  //     address: "Patna",
  //     lat: 25.6000,
  //     lng: 85.1000,
  //     country: "India",

  //   },
  //   {
  //     address: "Bilāspur",
  //     lat: 22.0900,
  //     lng: 82.1500,
  //     country: "India",

  //   },
  //   {
  //     address: "Ludhiāna",
  //     lat: 30.9100,
  //     lng: 75.8500,
  //     country: "India",

  //   },
  //   {
  //     address: "Agwār",
  //     lat: 27.1800,
  //     lng: 78.0200,
  //     country: "India",

  //   },
  //   {
  //     address: "Āgra",
  //     lat: 27.1800,
  //     lng: 78.0200,
  //     country: "India",

  //   },
  //   {
  //     address: "Madurai",
  //     lat: 9.9252,
  //     lng: 78.1198,
  //     country: "India",

  //   },
  //   {
  //     address: "Jamshedpur",
  //     lat: 22.7925,
  //     lng: 86.1842,
  //     country: "India",

  //   },
  //   {
  //     address: "Nāsik",
  //     lat: 20.0000,
  //     lng: 73.7800,
  //     country: "India",

  //   },
  //   {
  //     address: "Farīdābād",
  //     lat: 28.4167,
  //     lng: 77.3000,
  //     country: "India",

  //   },
  //   {
  //     address: "Aurangābād",
  //     lat: 19.8800,
  //     lng: 75.3200,
  //     country: "India",

  //   },
  //   {
  //     address: "Rājkot",
  //     lat: 22.3000,
  //     lng: 70.7833,
  //     country: "India",

  //   },
  //   {
  //     address: "Meerut",
  //     lat: 28.9800,
  //     lng: 77.7100,
  //     country: "India",

  //   },
  //   {
  //     address: "Jabalpur",
  //     lat: 23.1667,
  //     lng: 79.9333,
  //     country: "India",

  //   },
  //   {
  //     address: "Kalamboli",
  //     lat: 19.2333,
  //     lng: 73.1333,
  //     country: "India",

  //   },
  //   {
  //     address: "Vasai",
  //     lat: 19.4700,
  //     lng: 72.8000,
  //     country: "India",

  //   },
  //   {
  //     address: "Najafgarh",
  //     lat: 28.6092,
  //     lng: 76.9798,
  //     country: "India",

  //   },
  //   {
  //     address: "Vārānasi",
  //     lat: 25.3189,
  //     lng: 83.0128,
  //     country: "India",

  //   },
  //   {
  //     address: "Srīnagar",
  //     lat: 34.0900,
  //     lng: 74.7900,
  //     country: "India",

  //   },
  //   {
  //     address: "Dhanbād",
  //     lat: 23.7998,
  //     lng: 86.4305,
  //     country: "India",

  //   },
  //   {
  //     address: "Amritsar",
  //     lat: 31.6400,
  //     lng: 74.8600,
  //     country: "India",

  //   },
  //   {
  //     address: "Alīgarh",
  //     lat: 27.8800,
  //     lng: 78.0800,
  //     country: "India",

  //   },
  //   {
  //     address: "Guwāhāti",
  //     lat: 26.1722,
  //     lng: 91.7458,
  //     country: "India",

  //   },
  //   {
  //     address: "Hāora",
  //     lat: 22.5800,
  //     lng: 88.3294,
  //     country: "India",

  //   },
  //   {
  //     address: "Rānchi",
  //     lat: 23.3600,
  //     lng: 85.3300,
  //     country: "India",


  //   },
  //   {
  //     address: "Gwalior",
  //     lat: 26.2215,
  //     lng: 78.1780,
  //     country: "India",


  //   },
  //   {
  //     address: "Chandīgarh",
  //     lat: 30.7500,
  //     lng: 76.7800,
  //     country: "India",

  //   },
  //   {
  //     address: "Vijayavāda",
  //     lat: 16.5193,
  //     lng: 80.6305,
  //     country: "India",


  //   },
  //   {
  //     address: "Jodhpur",
  //     lat: 26.2800,
  //     lng: 73.0200,
  //     country: "India",

  //   },
  //   {
  //     address: "Raipur",
  //     lat: 21.2500,
  //     lng: 81.6300,
  //     country: "India",

  //   },
  //   {
  //     address: "Kota",
  //     lat: 25.1800,
  //     lng: 75.8300,
  //     country: "India",

  //   },
  //   {
  //     address: "Kālkāji Devi",
  //     lat: 28.5485,
  //     lng: 77.2513,
  //     country: "India",

  //   },
  //   {
  //     address: "Bhayandar",
  //     lat: 19.2900,
  //     lng: 72.8500,
  //     country: "India",

  //   },
  //   {
  //     address: "Ambattūr",
  //     lat: 13.1143,
  //     lng: 80.1548,
  //     country: "India",

  //   },
  //   {
  //     address: "Salt Lake City",
  //     lat: 22.6100,
  //     lng: 88.4000,
  //     country: "India",


  //   },
  //   {
  //     address: "Bhātpāra",
  //     lat: 22.8700,
  //     lng: 88.4100,
  //     country: "India",

  //   },
  //   {
  //     address: "Kūkatpalli",
  //     lat: 17.4849,
  //     lng: 78.4138,
  //     country: "India",

  //   },
  //   {
  //     address: "Darbhanga",
  //     lat: 26.1700,
  //     lng: 85.9000,
  //     country: "India",

  //   },
  //   {
  //     address: "Dāsarhalli",
  //     lat: 13.0465,
  //     lng: 77.5130,
  //     country: "India",

  //   },
  //   {
  //     address: "Muzaffarpur",
  //     lat: 26.1225,
  //     lng: 85.3906,
  //     country: "India",

  //   },
  //   {
  //     address: "Oulgaret",
  //     lat: 11.9570,
  //     lng: 79.7737,
  //     country: "India",

  //   },
  //   {
  //     address: "New Delhi",
  //     lat: 28.6139,
  //     lng: 77.2090,
  //     country: "India",

  //   },
  //   {
  //     address: "Tiruvottiyūr",
  //     lat: 13.1600,
  //     lng: 80.3000,
  //     country: "India",

  //   },
  //   {
  //     address: "Puducherry",
  //     lat: 11.9167,
  //     lng: 79.8167,
  //     country: "India",

  //   },
  //   {
  //     address: "Byatarayanpur",
  //     lat: 13.0659,
  //     lng: 77.5922,
  //     country: "India",

  //   },
  //   {
  //     address: "Pallāvaram",
  //     lat: 12.9675,
  //     lng: 80.1491,
  //     country: "India",

  //   },
  //   {
  //     address: "Secunderābād",
  //     lat: 17.4399,
  //     lng: 78.4983,
  //     country: "India",

  //   },
  //   {
  //     address: "Shimla",
  //     lat: 31.1033,
  //     lng: 77.1722,
  //     country: "India",

  //   },
  //   {
  //     address: "Puri",
  //     lat: 19.8106,
  //     lng: 85.8314,
  //     country: "India",

  //   },
  //   {
  //     address: "Shrīrāmpur",
  //     lat: 22.7500,
  //     lng: 88.3400,
  //     country: "India",

  //   },
  //   {
  //     address: "Hugli",
  //     lat: 22.9089,
  //     lng: 88.3967,
  //     country: "India",

  //   },
  //   {
  //     address: "Chandannagar",
  //     lat: 22.8671,
  //     lng: 88.3674,
  //     country: "India",

  //   },
  //   {
  //     address: "Sultānpur Mazra",
  //     lat: 28.6981,
  //     lng: 77.0689,
  //     country: "India",

  //   },
  //   {
  //     address: "Krishnanagar",
  //     lat: 23.4000,
  //     lng: 88.5000,
  //     country: "India",

  //   },
  //   {
  //     address: "Bārākpur",
  //     lat: 22.7642,
  //     lng: 88.3776,
  //     country: "India",

  //   },
  //   {
  //     address: "Bhālswa Jahangirpur",
  //     lat: 28.7354,
  //     lng: 77.1638,
  //     country: "India",

  //   },
  //   {
  //     address: "Nāngloi Jāt",
  //     lat: 28.6833,
  //     lng: 77.0667,
  //     country: "India",

  //   },
  //   {
  //     address: "Yelahanka",
  //     lat: 13.1007,
  //     lng: 77.5963,
  //     country: "India",

  //   },

  //   {
  //     address: "Titāgarh",
  //     lat: 22.7400,
  //     lng: 88.3700,
  //     country: "India",


  //   },
  //   {
  //     address: "Dam Dam",
  //     lat: 22.6200,
  //     lng: 88.4200,
  //     country: "India",

  //   },
  //   {
  //     address: "Bānsbāria",
  //     lat: 22.9700,
  //     lng: 88.4000,
  //     country: "India",


  //   },
  //   {
  //     address: "Madhavaram",
  //     lat: 13.1482,
  //     lng: 80.2314,
  //     country: "India",


  //   },
  //   {
  //     address: "Baj Baj",
  //     lat: 22.4828,
  //     lng: 88.1818,
  //     country: "India",


  //   },
  //   {
  //     address: "Nerkunram",
  //     lat: 13.0667,
  //     lng: 80.2833,
  //     country: "India",


  //   },
  //   {
  //     address: "Kendrāparha",
  //     lat: 20.5000,
  //     lng: 86.4200,
  //     country: "India",

  //   },
  //   {
  //     address: "Sijua",
  //     lat: 23.7692,
  //     lng: 86.1673,
  //     country: "India",

  //   },
  //   {
  //     address: "Manali",
  //     lat: 13.1667,
  //     lng: 80.2667,
  //     country: "India",


  //   },
  //   {
  //     address: "Chakapara",
  //     lat: 22.6300,
  //     lng: 88.3500,
  //     country: "India",

  //   },
  //   {
  //     address: "Pāppākurichchi",
  //     lat: 10.8137,
  //     lng: 78.7481,
  //     country: "India",


  //   },
  //   {
  //     address: "Herohalli",
  //     lat: 12.9911,
  //     lng: 77.4873,
  //     country: "India",


  //   },
  //   {
  //     address: "Madipakkam",
  //     lat: 12.9623,
  //     lng: 80.1986,
  //     country: "India",


  //   },
  //   {
  //     address: "Sabalpur",
  //     lat: 25.6053,
  //     lng: 85.1835,
  //     country: "India",


  //   },
  //   {
  //     address: "Salua",
  //     lat: 22.6100,
  //     lng: 88.2700,
  //     country: "India",


  //   },
  //   {
  //     address: "Balasore",
  //     lat: 21.4942,
  //     lng: 86.9317,
  //     country: "India",


  //   },
  //   {
  //     address: "Jālhalli",
  //     lat: 13.0333,
  //     lng: 77.5500,
  //     country: "India",


  //   },
  //   {
  //     address: "Chinnasekkadu",
  //     lat: 13.1609,
  //     lng: 80.2573,
  //     country: "India",


  //   },
  //   {
  //     address: "Jethuli",
  //     lat: 25.5378,
  //     lng: 85.2841,
  //     country: "India",


  //   },
  //   {
  //     address: "Nagtala",
  //     lat: 22.3693,
  //     lng: 88.6071,
  //     country: "India",


  //   },
  //   {
  //     address: "Bāgalūr",
  //     lat: 13.1328,
  //     lng: 77.6685,
  //     country: "India",


  //   },
  //   {
  //     address: "Pakri",
  //     lat: 25.5876,
  //     lng: 85.1580,
  //     country: "India",


  //   },
  //   {
  //     address: "Hunasamaranhalli",
  //     lat: 13.1435,
  //     lng: 77.6200,
  //     country: "India",


  //   },
  //   {
  //     address: "Hesarghatta",
  //     lat: 13.1391,
  //     lng: 77.4783,
  //     country: "India",


  //   },
  //   {
  //     address: "Bommayapālaiyam",
  //     lat: 11.9922,
  //     lng: 79.8499,
  //     country: "India",


  //   },
  //   {
  //     address: "Gundūr",
  //     lat: 10.7339,
  //     lng: 78.7184,
  //     country: "India",


  //   },
  //   {
  //     address: "Punādih",
  //     lat: 25.5484,
  //     lng: 85.2649,
  //     country: "India",

  //   },
  //   {
  //     address: "Harilādih",
  //     lat: 23.6300,
  //     lng: 86.3500,
  //     country: "India",


  //   },
  //   {
  //     address: "Alāwalpur",
  //     lat: 25.4958,
  //     lng: 85.2021,
  //     country: "India",


  //   },
  //   {
  //     address: "Mādnāikanhalli",
  //     lat: 13.0626,
  //     lng: 77.4642,
  //     country: "India",


  //   },
  //   {
  //     address: "Kādiganahalli",
  //     lat: 13.1687,
  //     lng: 77.6283,
  //     country: "India",


  //   },
  //   {
  //     address: "Mahuli",
  //     lat: 25.5430,
  //     lng: 85.2268,
  //     country: "India",


  //   },
  //   {
  //     address: "Zeyādah Kot",
  //     lat: 22.3210,
  //     lng: 88.2457,
  //     country: "India",


  //   },
  //   {
  //     address: "Arshakunti",
  //     lat: 13.0785,
  //     lng: 77.4225,
  //     country: "India",


  //   },
  //   {
  //     address: "Hīrāpur",
  //     lat: 23.6834,
  //     lng: 87.1973,
  //     country: "India",


  //   },
  //   {
  //     address: "Mirchi",
  //     lat: 25.5554,
  //     lng: 85.2139,
  //     country: "India",

  //   },
  //   {
  //     address: "Sonudih",
  //     lat: 25.1155,
  //     lng: 87.0214,
  //     country: "India",


  //   },
  //   {
  //     address: "Sondekoppa",
  //     lat: 13.0000,
  //     lng: 77.3667,
  //     country: "India",


  //   },
  //   {
  //     address: "Babura",
  //     lat: 25.0851,
  //     lng: 87.1092,
  //     country: "India",


  //   },
  //   {
  //     address: "Mādavar",
  //     lat: 13.0525,
  //     lng: 77.4732,
  //     country: "India",

  //   },
  //   {
  //     address: "Kadabgeri",
  //     lat: 12.9965,
  //     lng: 77.4331,
  //     country: "India",

  //   },
  //   {
  //     address: "Nanmangalam",
  //     lat: 12.9381,
  //     lng: 80.1753,
  //     country: "India",


  //   },
  //   {
  //     address: "Taliganja",
  //     lat: 22.5876,
  //     lng: 88.4439,
  //     country: "India",

  //   },
  //   {
  //     address: "Tarchha",
  //     lat: 25.1116,
  //     lng: 87.0964,
  //     country: "India",


  //   },
  //   {
  //     address: "Belgharia",
  //     lat: 22.5692,
  //     lng: 88.4846,
  //     country: "India",

  //   },

  //   {
  //     address: "Kammanhalli",
  //     lat: 13.0155,
  //     lng: 77.6381,
  //     country: "India",

  //   },
  //   {
  //     address: "Sonnappanhalli",
  //     lat: 13.1557,
  //     lng: 77.6179,
  //     country: "India",

  //   },
  //   {
  //     address: "Kedihāti",
  //     lat: 22.6543,
  //     lng: 88.5304,
  //     country: "India",

  //   },
  //   {
  //     address: "Doddajīvanhalli",
  //     lat: 13.0086,
  //     lng: 77.6143,
  //     country: "India",


  //   },
  //   {
  //     address: "Simli Murārpur",
  //     lat: 25.5792,
  //     lng: 85.2401,
  //     country: "India",

  //   },
  //   {
  //     address: "Sonāwān",
  //     lat: 25.5445,
  //     lng: 85.2387,
  //     country: "India",

  //   },
  //   {
  //     address: "Devanandapur",
  //     lat: 22.9378,
  //     lng: 88.3698,
  //     country: "India",


  //   },
  //   {
  //     address: "Tribeni",
  //     lat: 23.0269,
  //     lng: 88.4565,
  //     country: "India",


  //   },
  //   {
  //     address: "Huttanhalli",
  //     lat: 13.1651,
  //     lng: 77.6512,
  //     country: "India",

  //   },
   
  // {
  //   address: "Nathupur", 
  //   lat: 25.5163, 
  //   lng: 85.2544, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Bāli", 
  //   lat: 25.4810, 
  //   lng: 85.2227, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Vājarhalli", 
  //   lat: 13.1022, 
  //   lng: 77.4111, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Saino", 
  //   lat: 25.1134, 
  //   lng: 87.0108, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Shekhpura", 
  //   lat: 25.5725, 
  //   lng: 85.1428, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Cāchohalli", 
  //   lat: 13.0010, 
  //   lng: 77.4717, 
  //   country: "India", 
    
  // }, 
  // {
  //   address: "Nārāyanpur Kola", 
  //   lat: 25.1293, 
  //   lng: 87.0076, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Gyan Chak", 
  //   lat: 25.5496, 
  //   lng: 85.2423, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Kasgatpur", 
  //   lat: 13.1101, 
  //   lng: 77.5045, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Kitanelli", 
  //   lat: 13.0095, 
  //   lng: 77.4191, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Harchandi", 
  //   lat: 25.1000, 
  //   lng: 87.0442, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Santoshpur", 
  //   lat: 22.4650, 
  //   lng: 88.2193, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Bendravādi", 
  //   lat: 12.3636, 
  //   lng: 76.9137, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Kodagihalli", 
  //   lat: 12.9771, 
  //   lng: 77.4651, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Harna Buzurg", 
  //   lat: 25.0981, 
  //   lng: 87.0148, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Mailanhalli", 
  //   lat: 13.1863, 
  //   lng: 77.6963, 
  //   country: "India", 
     
    
  // }, 
  // {
  //   address: "Sultānpur", 
  //   lat: 25.5248, 
  //   lng: 85.2507, 
  //   country: "India", 
  // }
];

  var locationList = props.leadByLocationData

  if(locationList){
    locationList.map((location) => {
      if((location.latitude && location.latitude!=0) && (location.longitude && location.longitude!=0)){
        markers.push({ address: location.organization_name +' - '+ location.title ? location.title:location.first_name+' '+location.last_name, lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) });
      }
    }, this);
  }
  
  setTimeout(() => {
    setIsfillData(true)
  }, 1000);

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new googles.maps.LatLngBounds();
    map.fitBounds(bounds);
    return markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    setInfoWindowData({ id, address });
    setIsOpen(true);
    return mapRef?.panTo({ lat, lng });
  };
  

  return (

    <div className="display flex-wrap w-100 reportborder mt30">
        <div className="col-md-12">
          <div className="flex-wrap task align-items-start justify-content-end">
            <div className="innerdealtable w-100">
              <div className="display">
                <div className="col-md-12">
                  <h4>Location-wise Lead List</h4>
                </div>
              </div>
            </div>
            <div style={{ height: '100vh', width: '100%' }}>
              {!isLoaded ? 
                <h1>Loading...</h1>
               : 

               !isfillData ? 
                <h1>Please wait we are finding your lead location</h1>
               :
                <GoogleMap
                  mapContainerClassName="map-container"
                  onLoad={onMapLoad}
                  onClick={() => setIsOpen(false)}
                >
                  {markers.map(({ address, lat, lng }, ind) => (
                    <Marker
                      key={ind}
                      position={{ lat, lng }}
                      onClick={() => {
                        handleMarkerClick(ind, lat, lng, address);
                      }}
                    >
                      {isOpen && infoWindowData?.id === ind && (
                        <InfoWindow
                          onCloseClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          <h3>{infoWindowData.address}</h3>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                </GoogleMap>
              }
    </div>
          </div>
        </div>
      </div>


   
  );
};

export default LeadStoreChart2;