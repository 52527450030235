import React from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const ExportData = ({ params, fileName = 'data', api, handleCheckAll }) => {
    const history = useHistory();

    const exportData = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        try {
            const response = await axios.get('api/'+api+'?export=yes', {params});
            const data = response.data.data.records;
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(blob, `${fileName}-${moment().format("YYYY-MM-DD HH:mm:ss")}${fileExtension}`);
            handleCheckAll()
        } catch (error) {
            if (error.response) {
                if (error.response.status === 403) {
                    toast.warning(`Forbidden ${error.response.data.message}`);
                    history.push('/'+api);
                } else {
                    toast.error(error.response.data.message);
                    history.push('/'+api);
                }
            }
        }
    };

    return (
        <span onClick={exportData}>Export {fileName}</span>
    );
};

export default ExportData;