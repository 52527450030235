import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import {  toast } from 'react-toastify';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';
import { SketchPicker } from 'react-color';
import Select from 'react-select'
import reactCSS from 'reactcss'
import { useHistory, } from 'react-router-dom';

import swal from 'sweetalert';

import axios from 'axios';

function EditStage(props) {
     const history = useHistory();
     const [displayColorPicker, setDisplayColorPicker] = useState(false);
     const [color, setColor] = useState({
                                      r: '241',
                                      g: '112',
                                      b: '19',
                                      a: '1',
                                    });
     const [stageID, setstageID] = useState(props.match.params.id);
     const [stageTypeArray, setStageTypeArray] = useState([]);

     const [loading, setLoading] = useState(true);
     const [StageDataName, setStageDataName] = useState('');
     const [StageDataHierarchy, setStageDataHierarchy] = useState('');
     const [StageDataColorCode, setStageDataColorCode] = useState('');
     const [stageIsUsed, setStageIsUsed] = useState('');
     const [stageType, setStageType] = useState('');

     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     const [nameError, setNameError] = useState(false);
     const [nameErrorMsg, setNameErrorMsg] = useState('');

     const [hierarchyError, sethierarchyError] = useState(false);
     const [hierarchyErrorMsg, sethierarchyErrorMsg] = useState('');

     const [colorCodeError, setColorCodeError] = useState(false);
     const [colorCodeErrorMsg, setColorCodeErrorMsg] = useState('');

     const [isUsedError, setIsUsedError] = useState(false);
     const [isUsedErrorMsg, setIsUsedErrorMsg] = useState('');

     const [typeError, setTypeError] = useState(false);
     const [typeErrorMsg, setTypeErrorMsg] = useState('');

     useEffect(() => {

          axios.get('api/stage/edit/' + stageID).then(response => {

               if (response.data.code === 200) {
                    setStageDataName(response.data.data.record.name);
                    setStageDataHierarchy(response.data.data.record.hierarchy);
                    setStageDataColorCode(response.data.data.record.color_code);
                    setStageIsUsed(response.data.data.record.is_used);
                    setStageType(response.data.data.record.type);
                    setStageTypeArray(response.data.data.stageTypes)
                    
                    if(response.data.data.record.color_code){
                         var repCode = response.data.data.record.color_code.replace(/[^0-9, ]/g, "");
                         var expCode = repCode.split(',');
                         if(expCode.length==4){
                              setColor({
                                   r: expCode[0],
                                   g: expCode[1],
                                   b: expCode[2],
                                   a: expCode[3],
                              })
                         }
                         
                    }
                    
                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    if (error.response.status === 400 || error.response.status === 401) {
                         if (error.response.data.validation_errors) {
                              setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         } else {
                              //swal("warning", error.response.data.message, "warning");
                              toast.warning(error.response.data.message);
                         }
                    } else if (error.response.status === 300) {
                         //swal("warning", error.response.data.message, "warning");
                         history.push('/stages');
                         toast.warning(error.response.data.message);
                         
                    } else if(error.response.status === 403){
                         toast.warning('Forbidden '+error.response.data.message);
                         history.push('/stages');    
                    } else {
                        // swal("warning", 'Oops Something went wrong.', "warning");
                         toast.error('Oops Something went wrong.');
                    }

               }
          });

     }, []);


     const validateFormData = (type, value) => {
          if (type == 'name' && value == '') {
               setNameError(true);
               setNameErrorMsg('This field is required');
          } else {
               setNameError(false);
               setNameErrorMsg('');
          }
          
          var numericPattern = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
          if (type == 'hierarchy' && value != '' && !numericPattern.test(value)) {
               sethierarchyError(true);
               sethierarchyErrorMsg('Please enter numeric value');
          } else {
               if (type == 'hierarchy' && value == '') {
                    sethierarchyError(false);
                    sethierarchyErrorMsg('');
               }else if(type == 'hierarchy' && value != '' && numericPattern.test(value)){
                    sethierarchyError(false);
                    sethierarchyErrorMsg('');
                  }
          }
          
     }

     const styles = reactCSS({
          'default': {
            color: {
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
            },
            swatch: {
              padding: '5px',
              background: '#fff',
              borderRadius: '1px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              display: 'inline-block',
              cursor: 'pointer',
            },
            popover: {
              position: 'absolute',
              zIndex: '2',
            },
            cover: {
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            },
          },
        });
      
        const handleClick = () => {
          setDisplayColorPicker({ displayColorPicker: !displayColorPicker })
        };
      
        const handleClose = () => {
          setDisplayColorPicker(false)
        };
      
        const handleChange = (color) => {
             console.log(color)
          setColor(color.rgb)
          
          const data = {
               id: stageID,
               color_code: 'rgba('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+','+color.rgb.a+')',
               name: StageDataName,
               is_used: stageIsUsed,
               type: stageType,
          }
          
          setTimeout(() => updateColorCode(data), 1000);

          };

        function updateColorCode(data){

          axios.post("api/stage/update", data).then(response => {
               if (response.data.code === 200) {
                   // swal("success", response.data.message, "success");
               }
          }).then(json => {

          }).catch(error => {
               // if (error.response) {
               //      if (error.response.status === 400 || error.response.status === 401) {
               //           if (error.response.data.validation_errors) {
               //                setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
               //           } else {
               //                //swal("warning", error.response.data.message, "warning");
               //                toast.warning(error.response.data.message);
               //           }
               //      } else if (error.response.status === 300) {
               //           //swal("warning", error.response.data.message, "warning");
               //           toast.warning(error.response.data.message);
               //      } else {
               //           //swal("warning", 'Oops Something went wrong.', "warning");
               //           toast.error('Opps.. Something went wrong.');
               //      }
               // }

          });
        }

     const handleSave = ({ name, value, previousValue }) => {
          const data = {
               id: stageID,
               color_code: 'rgba('+color.r+','+color.g+','+color.b+','+color.a+')',
               name: StageDataName,
               is_used: stageIsUsed,
               type: stageType,
               [name]: value,
          }
          
          if (!nameError && !colorCodeError) {
               setErrorInput({ ...ErrorInput, error_list: '' });
               axios.post("api/stage/update", data).then(response => {
                    //setLoading(false);
                    if (response.data.code === 200) {
                         //swal("success", response.data.message, "success");
                         toast.success(response.data.message);
                    }
               }).then(json => {

               }).catch(error => {
                    //setLoading(false);
                    if (error.response) {
                         if (error.response.status === 400 || error.response.status === 401) {
                              if (error.response.data.validation_errors) {
                                   setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                                   if (error.response.data.validation_errors['name']) {
                                        setStageDataName(previousValue);
                                   }
                                   if (error.response.data.validation_errors['hierarchy']) {
                                        setStageDataHierarchy(previousValue);
                                   }
                                   if (error.response.data.validation_errors['color_code']) {
                                        setStageDataColorCode(previousValue);
                                   }
                                   if (error.response.data.validation_errors['is_used']) {
                                        setStageIsUsed(previousValue);
                                   }
                                   if (error.response.data.validation_errors['type']) {
                                        setStageType(previousValue);
                                   }
                              } else {
                                   //swal("warning", error.response.data.message, "warning");
                                   toast.warning(error.response.data.message);
                              }
                         } else if (error.response.status === 300) {
                              //swal("warning", error.response.data.message, "warning");
                              toast.warning(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden ' + error.response.data.message);
                         }else {
                              //swal("warning", 'Oops Something went wrong.', "warning");
                              toast.error('Oops Something went wrong.');
                         }
                    }

               });
          }
     };

     const handleSelectTypeSave = (event) => {
          setStageType(event.value);
          const data = {
               id: stageID,
               color_code: 'rgba('+color.r+','+color.g+','+color.b+','+color.a+')',
               name: StageDataName,
               is_used: stageIsUsed,
               type: event.value,
          }
          setErrorInput({ ...ErrorInput, error_list: '' });
          axios.post("api/stage/update", data).then(response => {
               if (response.data.code === 200) {
                    toast.success(response.data.message);
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    if (error.response.status === 400 || error.response.status === 401) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                    }    else {
                         toast.error('Oops Something went wrong.');
                    }
               }
          });
     };


     const handleSelectInUsedSave = (event) => {
          setStageIsUsed(event.value);
          const data = {
               id: stageID,
               color_code: 'rgba('+color.r+','+color.g+','+color.b+','+color.a+')',
               name: StageDataName,
               is_used: event.value,
               type: stageType,
          }
          setErrorInput({ ...ErrorInput, error_list: '' });
          axios.post("api/stage/update", data).then(response => {
               if (response.data.code === 200) {
                    toast.success(response.data.message);
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    if (error.response.status === 400 || error.response.status === 401) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
          });
     };

     const customStyles = {
          menuList: styles => ({
            ...styles,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          // option: provided => ({
          //   ...provided,
          //   bordercolor: "black",
          //   borderstyle: "solid",
          //   '&:hover':
          //   {
          //     outline: 'none',
          //     backgroundcolor: 'none',
          //     color: '#04182C',
          //   },
          //   '&:active':
          //   {
          //     outline: 'none',
          //     backgroundcolor: 'none',
          //     color: '#ffffff',
          //   },
          //   '&:visited':
          //   {
          //     outline: 'none',
          //     backgroundcolor: 'none',
          //     color: '#ffffff',
          //   },
          //   '&:selected':
          //   {
          //     outline: 'none',
          //     backgroundcolor: 'none',
          //     color: '#ffffff',
          //   },
          //   '&:default':
          //   {
          //     outline: 'none',
          //     backgroundcolor: 'none',
          //     color: '#04182C',
          //   }
          // }),
          // control: provided => ({
          //   ...provided,
          //   border: "1px solid #d1d7e0",
          //   borderRadius: "10px",
          //   color: "#04182C",
          //   '&:hover':
          //   {
          //     outline: 'none',
          //     // backgroundcolor: 'none',
          //     // border: "1px solid #04182C",
          //     color: "#d1d7e0",
          //   },
          //   '&:focus':
          //   {
          //     outline: 'none',
          //     // border: "1px solid #04182C",
          //     // backgroundcolor: 'none',
          //     color: "#d1d7e0",
          //   },
          //   '&:active':
          //   {
          //     outline: 'none',
          //     // border: "1px solid #04182C",
          //     // backgroundcolor: 'none',
          //     color: "#d1d7e0",
          //   },
          //   '&:default':
          //   {
          //     outline: 'none',
          //     // border: "1px solid #04182C",
          //     // backgroundcolor: 'none',
          //     color: "#d1d7e0",
          //   }
          // }),
          // singleValue: provided => ({
          //   ...provided,
          //   border: "0px solid #04182C",
          //   color: "#04182C",
          //   '&:hover':
          //   {
          //     outline: 'none',
          //     border: 'none',
          //     // backgroundcolor: 'none',
          //     color: "#04182C",
          //   },
          //   '&:focus':
          //   {
          //     outline: 'none',
          //     border: 'none',
          //     // backgroundcolor: 'none',
          //     color: "#04182C",
          //   },
          //   '&:active':
          //   {
          //     outline: 'none',
          //     border: 'none',
          //     // backgroundcolor: 'none',
          //     color: "#04182C",
          //   }
          // }),
        }
      

     if (loading) {
          return <Preloader show={loading ? false : true} />;
     } else {
          // let stageTypeList = Object.keys(stageTypeArray).map(function (key) {
          // return (
          //      <option value={key} key={key}>{stageTypeArray[key]}</option>
          // )
          // }, this);

          let stageTypeList = [];
               stageTypeList.push({ label: 'Please select', value: '' });
               let typeVarList = Object.keys(stageTypeArray).map(function (key) {
                    stageTypeList.push({label:stageTypeArray[key],value:key});
               }, this);

          let selectedType = [];
          let typeVar = Object.keys(stageTypeArray).map(function (key) {
               if (stageType == key) {
                    selectedType.push({label:stageTypeArray[key],value:key});
               }
          }, this);
          

          let isUsedLists = [];
          isUsedLists.push({ label: 'Please select', value: '' });
          isUsedLists.push({ label: 'Lead', value: 'lead' });
          isUsedLists.push({ label: 'Deal', value: 'deal' });
          
          let selectedUsed = [];
          for (var i = 0, l = isUsedLists.length; i < l; i++) {
               if (stageIsUsed == isUsedLists[i].value) {
                    selectedUsed.push({ label: isUsedLists[i].label, value: isUsedLists[i].value });
               }
          }

          return (
               <Row>
                    <Col xs={12} xl={12}>
                         <Card border="light" className="bg-white shadow-sm mb-4">
                              <Card.Body className="card-body1">
               <div className="producthover">
                                   <Row className="mb-2">
                                        <Col md={6}>
                                             <h5>Edit Stage information</h5>
                                        </Col>
                                        <Col md={6} className="text-right">
                                             <button className="btn1" onClick={() => {
                                                  history.goBack();
                                             }}><span className="backarrow-icon"></span>Back</button>
                                        </Col>
                                   </Row>

                                   <Form>
                                        <Row>

                                             <Col md={4} className="mb-3">
                                                  <Form.Group id="name">
                                                       <Form.Label>Name<span className="text-danger"> * </span></Form.Label>
                                                       <EditText
                                                            name="name"
                                                            placeholder="Enter stage name"
                                                            required="required"
                                                            type="text"
                                                            value={StageDataName}
                                                            //defaultValue={UserData.record.name}
                                                            onChange={(value) => { setStageDataName(value); validateFormData('name', value) }}
                                                            onSave={handleSave}
                                                       />
                                                  </Form.Group>
                                                  {nameError ? (<span className="error">{nameErrorMsg}</span>) : null}
                                                  <span className="error">{ErrorInput.error_list.name}</span>
                                             </Col>
                                             <Col md={4} className="mb-3">
                                                  <Form.Group id="hierarchy">
                                                       <Form.Label>Position</Form.Label>
                                                       <EditText
                                                            name="hierarchy"
                                                            placeholder="Enter position"
                                                            type="numeric"
                                                            value={StageDataHierarchy}
                                                            //defaultValue={UserData.record.name}
                                                            onChange={(value) => { setStageDataHierarchy(value); validateFormData('hierarchy', value) }}
                                                            onSave={handleSave}
                                                       />
                                                  </Form.Group>
                                                  <span className="error">{ErrorInput.error_list.hierarchy}</span>
                                                  {hierarchyError ? (<span className="error">{hierarchyErrorMsg}</span>) : null}
                                             </Col>
                                             <Col sm={4} className="mb-3">
                                                  <Form.Group className="mb-2">
                                                  <Form.Label>Type</Form.Label>
                                                       <Select
                                                            defaultValue={selectedType}
                                                            placeholder="Please select"
                                                            name="type"
                                                            options={stageTypeList}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={handleSelectTypeSave}
                                                            styles={customStyles}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            theme={(theme) => ({
                                                                 ...theme,
                                                                 borderRadius: 0,
                                                                 colors: {
                                                                   ...theme.colors,
                                                                   primary25: '#0074E1',
                                                                   primary: '#0074E1',
                                                                 },
                                                               })}
                                                       />
                                                  </Form.Group>
                                                  <span className="error">{ErrorInput.error_list.type}</span>
                                             </Col>
                                             {/* <Col sm={4} className="mb-3">
                                                  <Form.Group className="mb-2">
                                                  <Form.Label>Lead/Deal</Form.Label>
                                                       <Select
                                                            defaultValue={selectedUsed}
                                                            placeholder="Please select"
                                                            name="is_used"
                                                            options={isUsedLists}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={handleSelectInUsedSave}
                                                            styles={customStyles}
                                                       />
                                                  </Form.Group>
                                                  <span className="error">{ErrorInput.error_list.is_used}</span>
                                             </Col> */}
                                        </Row>
                                        <Row>
                                             
                                             <Col md={8}>
                                                  <Form.Group id="color_code">
                                                       <Form.Label>Color Code<span className="text-danger"> * </span></Form.Label>
                                                       {/* <EditText
                                                            name="color_code"
                                                            placeholder="Enter color code"
                                                            required="required"
                                                            type="text"
                                                            value={StageDataColorCode}
                                                            //defaultValue={UserData.record.name}
                                                            onChange={(value) => { setStageDataColorCode(value); validateFormData('color_code', value) }}
                                                            onSave={handleSave}
                                                       /> */}
                                                       <span style={{paddingLeft:'20px'}}>Selected Color Code are rgba({color.r},{color.g},{color.b},{color.a})</span>
                                                       <div>
                                                            <div style={ styles.swatch } onClick={ handleClick }>
                                                            <div style={ styles.color } />
                                                            </div>
                                                            { displayColorPicker ? <div style={ styles.popover }>
                                                            <div style={ styles.cover } onClick={ handleClose }/>
                                                                 <SketchPicker color={ color } onChangeComplete={ handleChange } />
                                                            </div> : null }
                                                       </div>
                                                  </Form.Group>
                                                  {/* {colorCodeError ? (<span className="error">{colorCodeErrorMsg}</span>) : null}
                                                  <span className="error">{ErrorInput.error_list.color_code}</span> */}
                                             </Col>
                                        </Row>

                                   </Form>
                              </div>
                              </Card.Body>
                         </Card>
                    </Col>
               </Row>
          );
     }

};

export default EditStage;
