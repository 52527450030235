import React, { useState, memo, useEffect } from "react";
import Preloader from "./PreloaderCenter";
import ReactECharts from "echarts-for-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SalesQuoteList from "./SalesQuoteList";

function SalesQuotaChart(props) {
  const { salesChartData } = props;
  const [loading, setLoading] = useState(true);
  const [searchUser, setSearchUser] = useState("");

  setTimeout(() => {
    setLoading(false);
  }, 500);

  const [userID, setUserID] = useState("");

  //const [userIDs, setUserIDs] = useState([]);
  //const [dateRange, setDateRange] = useState([null, null]);
  //const [startDate, endDate] = dateRange;

  var disCurrency = "INR";
  if (
    props?.preferredCurrencyData?.currencySymbol &&
    props?.preferredCurrencyData?.currencyCode
  ) {
    disCurrency =
      props?.preferredCurrencyData?.currencySymbol +
      " (" +
      props?.preferredCurrencyData?.currencyCode +
      ")";
  }

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },
    toolbox: {
      show: true,
      feature: {
        //mark: { show: true },
        //dataView: { show: true, readOnly: false },
        //magicType: { show: true, type: ['line', 'bar'] },
        //restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    calculable: true,
    legend: {
      data: ["Growth", "Budget 2011", "Budget 2012"],
      itemGap: 5,
    },
    grid: {
      top: "12%",
      left: "1%",
      right: "10%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "value",
        name: disCurrency,
        axisLabel: {
          formatter: function (a) {
            a = +a;
            return a ? a : "";
          },
        },
      },
    ],
    yAxis: [
      {
        type: "category",
        data: salesChartData.name,
      },
    ],
    dataZoom: [
      // { //for X-axis zoom
      //   show: true,
      //   start: 1,
      //   end: 100
      // },
      // {
      //   type: 'inside',
      //   start: 1,
      //   end: 100
      // },
      // {
      //   show: false,
      //   yAxisIndex: 0,
      //   filterMode: 'empty',
      //   width: 30,
      //   height: '100%',
      //   showDataShadow: false,
      //   left: '93%'
      // }
      {
        //for Y-axis zoom
        show: true,
        yAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
      {
        type: "inside",
        yAxisIndex: 0,
        filterMode: "empty",
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: "Sales Target",
        type: "bar",
        data: salesChartData.target,
      },
      {
        name: "Achieved Sales Target",
        type: "bar",
        data: salesChartData.achieved,
      },
    ],
  };

  let optionLists = [{ label: "Select", value: "" }];
  optionLists.push({ label: "3 Months", value: "3" });
  optionLists.push({ label: "6 Months", value: "6" });
  optionLists.push({ label: "12 Months", value: "12" });

  let userLists = [];

  if (props.userList) {
    for (var i = 0, l = props.userList.length; i < l; i++) {
      userLists.push({
        label: props.userList[i].name,
        value: props.userList[i].id,
      });
    }
  }

  let defaultAllUser = [];
  if (!searchUser) {
    defaultAllUser = [{ label: "All Users", value: "" }];
  }

  userLists.map((item) => {
    if (searchUser == item.id) {
      defaultAllUser.push({ label: item.name, value: item.id });
    }
  }, this);

  let ListUserArray = [{ label: "All Users", value: "" }];
  if (props.userList) {
    for (var i = 0, l = props.userList.length; i < l; i++) {
      ListUserArray.push({
        label: props.userList[i].name,
        value: props.userList[i].id,
      });
    }
  }

  const handleSelectUser = (event) => {
    setSearchUser(event.value);
  };

  const getYear = (year) => {
    const date = new Date(year);
    const yearName = date.getFullYear();
    return yearName;
  };
  const getMonth = (month) => {
    const date = new Date(month);
    //const options = { month: 'long' };
    //const monthName = new Intl.DateTimeFormat('en-US', options).format(date);
    const monthNumber = date.getMonth();
    return monthNumber;
  };

  const range = (start, end, step = 1) => {
    let array = [];
    for (let i = start; i <= end; i += step) {
      array.push(i);
    }
    return array;
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const years = range(2020, getYear(new Date()));
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const customSelectDate = [
    "Custom Select",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last Month",
    "Last 6 Month",
  ];

  const [selectedOption, setSelectedOption] = useState(customSelectDate[0]);

  const changeCustomDate = (e) => {
    setSelectedOption(customSelectDate[e]);
    if (e == 1) {
      setDateRange([new Date(), new Date()]);
      props.searchDateRangeFilter([new Date(), new Date()]);
    } else if (e == 2) {
      setDateRange([
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ]);
      props.searchDateRangeFilter([
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ]);
    } else if (e == 3) {
      setDateRange([
        new Date(new Date().setDate(new Date().getDate() - 6)),
        new Date(new Date()),
      ]);
      props.searchDateRangeFilter([
        new Date(new Date().setDate(new Date().getDate() - 6)),
        new Date(new Date()),
      ]);
    } else if (e == 4) {
      setDateRange([
        new Date(new Date().setDate(new Date().getDate() - 29)),
        new Date(new Date()),
      ]);
      props.searchDateRangeFilter([
        new Date(new Date().setDate(new Date().getDate() - 29)),
        new Date(new Date()),
      ]);
    } else if (e == 5) {
      setDateRange([
        new Date(new Date().setDate(1)),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      ]);
      props.searchDateRangeFilter([
        new Date(new Date().setDate(1)),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      ]);
    } else if (e == 6) {
      setDateRange([
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      ]);
      props.searchDateRangeFilter([
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      ]);
    } else if (e == 7) {
      setDateRange([
        new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
        new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      ]);
      props.searchDateRangeFilter([
        new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
        new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      ]);
    } else {
      setDateRange([null, null]);
      props.searchDateRangeFilter([null, null]);
    }
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    return (
      <>
        <div className="display flex-wrap w-100 reportborder mt30">
          <div className="col-md-12">
            <div className="flex-wrap task align-items-start justify-content-end">
              <div className="innerdealtable w-100">
                <div className="display">
                  <div className="col-md-12">
                    <h4>Sales Quota</h4>
                  </div>
                </div>
                <div className="searchbar">
                  <div className="col-md-2"></div>
                  <div className="col-md-6 display justify-content-end">
                    <div className="dropdown-section display">
                      <DatePicker
                        className="datepicker"
                        selectsRange={true}
                        selected={startDate}
                        onChange={(update) => {
                          props.searchDateRangeFilter(update);
                          setDateRange(update);
                          setSelectedOption(customSelectDate[0]);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Select Date"
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="customcalnder"
                          >
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(value)
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <div>
                              <select
                                value={selectedOption}
                                onChange={({ target: { value } }) =>
                                  changeCustomDate(
                                    customSelectDate.indexOf(value)
                                  )
                                }
                              >
                                {customSelectDate.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                      />
                      <div style={{ marginLeft: "10px" }}></div>
                      <Select
                        defaultValue={defaultAllUser}
                        name="user_id"
                        options={ListUserArray}
                        styles={""}
                        className="basic-multi-select mr15 br10"
                        classNamePrefix="select"
                        //onChange={handleSelectUser}
                        onChange={(data) => {
                          props.searchUserFilter(data.value);
                          setUserID(data.value);
                          handleSelectUser(data.value);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "var(--primary-color-light)",
                            primary: "var(--primary-color)",
                          },
                        })}
                        isClearable={false}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {props?.userList?.length ? (
                <ReactECharts
                  option={option}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={"theme_name"}
                  onChartReady={""}
                  onEvents={""}
                  opts={""}
                  style={{ height: "500px" }}
                />
              ) : (
                <div className="display">
                  <div className="col-md-12 text-center">
                    <span className="col-mt-10">No data available</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="display flex-wrap w-100 reportborder mt30">
          <div className="col-md-12">
            <div className="flex-wrap task align-items-start justify-content-end">
              <div className="col-md-12">
                <SalesQuoteList
                  userID={userID}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default memo(SalesQuotaChart);
