// // import { configureStore } from "@reduxjs/toolkit";
// // import userReducer from "../features/userSlice";

// // export default configureStore({
// //   reducer: {
// //     user: userReducer,
// //   },
// // },window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


// // import { createStore,applyMiddleware, compose } from "redux"
// // import { configureStore } from "@reduxjs/toolkit"
// // import thunk from "redux-thunk"
// // import rootReducer from "../Reducer/Reducer"
// // const composeEnhancers =
// //   typeof window === "object" &&
// //   process.env.NODE_ENV === "development" &&
// //   (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
// //     ? (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
// //     : compose
// // const enhancer = composeEnhancers(applyMiddleware(thunk))
// // export const store = configureStore({
// //   reducer:rootReducer
// // })


// import { createStore, applyMiddleware } from 'redux'
// import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
// //import { composeWithDevTools } from 'redux-devtools-extension'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import storageSession from 'redux-persist/es/storage/session'
// import createWebStorage from "redux-persist/lib/storage/createWebStorage";

// import rootReducer from '../features/userSlice' // Root reducer

// const persistConfig = { // configuration object for redux-persist
//     key: 'root',
//     storage:storageSession, // define which storage to use
//     //storage:storage, // not seen in local storage
//     blacklist: ["navigation"],
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer) // create a persisted reducer

// const store = createStore(
//   persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
//   applyMiddleware() // add any middlewares here
//   //composeWithDevTools(applyMiddleware()) // show in browser
// )

// const  persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

// export {persistor};

// export default store;


// // import { configureStore } from '@reduxjs/toolkit'
// // import rootReducer from '../features/userSlice'

// // const store = configureStore({
// //   reducer: rootReducer,
// // })

// // export default store


import { applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';
import rootReducer from '../features/userSlice'

const composeEnhancers =
    typeof window === 'object' &&
        // eslint-disable-next-line no-undef
        process.env.NODE_ENV === 'development' &&
        (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

composeEnhancers(applyMiddleware(thunk));

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
