/* eslint-disable no-unused-vars */
import React, { useState, useEffect, memo,useRef} from "react";
import { Col, Row, Button, Form, Spinner, Modal} from '@themesberg/react-bootstrap';
import { useDispatch,useSelector } from "react-redux";
import { LeadAddPopUp } from "../features/userSlice"
import {  toast } from 'react-toastify';
import { useHistory, } from 'react-router-dom';
import Select from 'react-select'
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import AddCustomFields from "./AddCustomFields"
import { displayCalendorDateFormate } from '../utils/utils';


function AddLead(props){
  const history = useHistory();
  const dispatch = useDispatch();
  const [leadSourceData, setleadSourceData] = useState([]);
  const [salutationData, setSalutationData] = useState([]);
  const [customFieldLists, setCustomFieldLists] = useState([]);

  const [getIpResponse, setGetIpResponse] = useState([]);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [fileLists, setFileLists] = useState([]);

  const showAddLead = useSelector((state) => state.leadAddPopUp);
  const handleLeadClose = () => dispatch(LeadAddPopUp(false));

  var defaultTimeZone = ''
  if (props.userData) {
    defaultTimeZone = props.userData.preferred_timezone;
  }
  const [fullStartDate, setFullStartDate] = useState(
    new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone }))
  );
  const [displayDateFormate, setDisplayDateFormate] = useState('yyyy/MM/dd');
  
  const dateRef = useRef();

  useEffect(() => {
    if(showAddLead){
      async function fetchData() {
        await getLeadSource();

        if("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          });
        }
      }
      fetchData();
    }
  }, []);
  
  const [defaultValue, setdefaultValue] = useState({
    salutation: '',
    first_name: '',
    last_name: '',
    title: '',
    organization_name: '',
    lead_source: '',
    phone: '',
    error_list: [],
  }) 
    
  // const getInitialValues = (fixedFields, dynamicFields) => {
  //   const mergedFields  = { ...fixedFields };
  //   dynamicFields && dynamicFields.forEach(field => {
  //     mergedFields[field.field_name] = '';
  //   });
  //   return mergedFields ;
  // };
  
  async function getLeadSource() {
    await axios.get('api/lead/create').then(response => {
      //console.log(response.data.data);
      if (response.status === 200) {
        setleadSourceData(response.data.data.leadSources)
        setSalutationData(response.data.data.salutationArray);
        setCustomFieldLists(response.data.data.customFields)

        const initialValues = { ...defaultValue };
        response.data.data.customFields && response.data.data.customFields.forEach(field => {
          initialValues[field.field_name] = '';
          if(field.display_type==='file'){
            setFileLists([...fileLists, field.field_name])    
          }
        });
        setdefaultValue(initialValues);

        // const mergedDefaultValues = getInitialValues(defaultValue, response.data.data.customFields);
        // setdefaultValue(mergedDefaultValues);
        
        const calendorFormate = displayCalendorDateFormate(response?.data?.data?.preferredDateFormat);
        setDisplayDateFormate(calendorFormate?calendorFormate:'');
      }
    }).then(() => {

    }).catch(error => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 300) {
          toast.error(error.response.data.message);
        }else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }
  const leadSourceList = leadSourceData.map(item => ({
    label: item.title,
    value: item.title
  }));

  const salutationList = [{ label: 'Select...', value: '' }, ...Object.keys(salutationData).map(key => ({
    label: salutationData[key],
    value: key
  }))];

  let SaldefaultValue = [];

  const customStyles = {
    menuList: styles => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const createValidationSchema = () => {
    const schema = {
      first_name: yup.string().trim('First name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter first name'),
      last_name: yup.string().trim('Last name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter last name'),
      title: yup.string().trim('Title cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
      organization_name: yup.string().trim('Company name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter company name'),
      phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, 'Must be enter 10 digit').max(10, 'Not allow more than 10 digit'),
      //salutation:yup.string().required('This field is a required'),
      //lead_source:yup.string().required('Lead source is a required field'),
    };

    customFieldLists && customFieldLists.forEach(field => {
      if (field.display_is_required === "Yes") {
        schema[field.field_name] = yup.string().required(`Enter ${field.display_label}`);
      }
    });

    return yup.object().shape(schema);
  };

  const validationSchema = createValidationSchema();
  
  const handleSubmit = async (values,onSubmitProps) =>{
    // const data = {
    //   ...values,
    //   latitude: getIpResponse.latitude ? getIpResponse.latitude : latitude,
    //   longitude: getIpResponse.longitude ? getIpResponse.longitude : longitude,
    //   geolocation:getIpResponse,
    // }
    
    const formData = new FormData();
    for (const key in values) {
      // Append non-file fields to FormData
      if (values.hasOwnProperty(key) && !fileLists.includes(key)) {
        formData.append(key, values[key]);
      }

      // Append the image file separately
      if (fileLists.includes(key)) {
        formData.append(key+'[]', values[key][0] ? values[key][0] : '');
      }
    }
    
    // Append other data
    formData.append('latitude', getIpResponse.latitude ? getIpResponse.latitude : latitude);
    formData.append('longitude', getIpResponse.longitude ? getIpResponse.longitude : longitude);
    formData.append('geolocation', JSON.stringify(getIpResponse)); // Convert to JSON string if it's an object
  


    await axios.post("/api/lead/store", formData).then(response => {
      if (response.status === 200) {
        dispatch(LeadAddPopUp(false))
        toast.success('Lead has been created successfully');
        history.push('/leads');
      }
    }).then(() => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 400) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        } else if(error.response.status === 403){
          toast.warning('Forbidden '+error.response.data.message);
          history.push('/leads');    
        } else {
          toast.warning(error.response.data.message);
        }
      }

    });

  }
  
    return (
      <Modal as={Modal.Dialog} centered show={showAddLead} onHide={handleLeadClose}>
              <Modal.Header>
                <Modal.Title className="h2">Create a Lead</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleLeadClose} /><span className="modelclose"></span>
              </Modal.Header>
              <Modal.Body>
              <Formik 
                    enableReinitialize
                    initialValues={defaultValue} 
                    validationSchema={validationSchema} 
                    onSubmit={handleSubmit}
                  >{props => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldTouched,
                    } = props;
                    return (
                <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-md-12 col-sm-12">
                      <Row className="align-items-center">
                        <Form.Group className="mb-125 firstrow">
                          {/* <Form.Label column sm="12">
                            <h6 className="fw600">First Name<span className="text-danger"> * </span></h6>
                          </Form.Label> */}
                          <Col sm="12" className="Display">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Salutation</h6>
                          </Form.Label>
                            <Form.Group id="salutation" className="position-relative">
                            <Select
                              defaultValue={SaldefaultValue}
                              name="salutation"
                              options={salutationList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                             onChange={selectedOption => {
                              values.salutation = selectedOption.value;
                              handleChange("salutation");
                            }}
                            isClearable={false}
                            isSearchable={false}
                             onBlur={setFieldTouched}
                              styles={customStyles}
                              theme={(theme) => ({
                                             ...theme,
                                             borderRadius: 0,
                                             colors: {
                                                  ...theme.colors,
                                                  primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                             },
                                        })}
                            />
                              <span className="error error4">{defaultValue.error_list.salutation}</span>
                              <span className="error error4">
                                <ErrorMessage name="salutation"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">First name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="first_name">
                              <Form.Control type="text" name="first_name" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter firstname" className={errors.first_name && touched.first_name ? 
                    "input-error" : null} />
                              <span className="error">{defaultValue.error_list.first_name}</span>
                              <span className="error error4">
                                <ErrorMessage name="first_name"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Last name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="last_name">
                              <Form.Control type="text" name="last_name" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter lastname" className={errors.last_name && touched.last_name ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.last_name}</span>
                              <span className="error error4">
                                <ErrorMessage name="last_name"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Title</h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="title">
                              <Form.Control type="text" name="title" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Title goes here" />
                              <span className="error error4">{defaultValue.error_list.title}</span>
                              <span className="error error4">
                                <ErrorMessage name="title"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Company name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="organization_name">
                              <Form.Control type="text" name="organization_name" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur}  placeholder="XYZ company Ltd" className={errors.organization_name && touched.organization_name ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.organization_name}</span>
                              <span className="error error4">
                                <ErrorMessage name="organization_name"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Source</h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="lead_source">
                            <Select
                              //defaultValue={selectedStage}
                              name="lead_source"
                              options={leadSourceList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              menuPosition="fixed"
                              onChange={selectedOption => {
                                values.lead_source = selectedOption.value;
                                handleChange("lead_source");
                              }}

                              onBlur={handleBlur}
                              styles={customStyles}
                              isClearable={false}
                              isSearchable={false}
                              theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'var(--primary-color-light)',
                                        primary: 'var(--primary-color)',
                                    },
                              })}

                            />
                            <span className="error error4">{defaultValue.error_list.lead_source}</span>
                              <span className="error error4">
                                <ErrorMessage name="lead_source"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                              {
                              customFieldLists?.length > 0 && customFieldLists.map((field) => {
                                const fieldName = field.field_name;
                                
                                var selectValuesArray = [];
                                if(field.display_type==='select'){
                                  var listArray = field?.json_data_value ? JSON.parse(field.json_data_value):[];
                                  selectValuesArray = listArray.map(item => ({
                                    value: item.id,
                                    label: item.name
                                  }))
                                }
                                
                                const isFieldTouched = touched[fieldName];
                                const fieldError = errors[fieldName];

                                return (
                                  <Form.Group className="mb-125 position-relative" key={field.field_name}>
                                    <Form.Label column sm="12">
                                      <h6 className="fw600">
                                        {field.display_label}
                                        {field?.display_is_required === "Yes" ? (
                                          <span className="text-danger">*</span>
                                        ) : (
                                          ""
                                        )}
                                      </h6>
                                    </Form.Label>
                                    <Col sm="12">
                                      <Form.Group id={field?.field_name}>
                                        {field.display_type === "text" ? (
                                          <Form.Control
                                            type="text"
                                            name={field?.field_name}
                                            minLength={
                                              field?.display_min_input || 0
                                            }
                                            maxLength={
                                              field?.display_max_input || 150
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={
                                              field?.placeholder_text || ""
                                            }
                                            className={fieldError && isFieldTouched ? 'input-error' : ''}
                                          />
                                        ) : field.display_type === "textarea" ? (
                                          <Form.Control
                                            as="textarea"
                                            minLength={
                                              field?.display_min_input || 0
                                            }
                                            maxLength={
                                              field?.display_max_input || ""
                                            }
                                            name={field?.field_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={
                                              field?.placeholder_text || ""
                                            }
                                            className={fieldError && isFieldTouched ? 'input-error' : ''}
                                          />
                                        ) : field.display_type === "date" ? (
                                          <DatePicker
                                            autocomplete="off"
                                            minDate={moment().toDate()}
                                            filterTime={''}
                                            selected={fullStartDate}
                                            onChange={(date) => {
                                              setFullStartDate(date)
                                              values[fieldName] = moment(date).format("YYYY-MM-DD HH:mm:ss")
                                              handleChange(fieldName)
                                            }}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="time"
                                            dateFormat={displayDateFormate + " h:mm aa"}
                                            className={fieldError && isFieldTouched ? 'form-control input-error' : 'form-control'}
                                            ref={dateRef}
                                        />
                                        ) : field.display_type === "file" ? (
                                          <Form.Control
                                            style={{ display: "block" }}
                                            type="file"
                                            accept="image/*"
                                            name={field.field_name+'[]'}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                              values[fieldName] = event.target.files;
                                              handleChange(fieldName);
                                            }}
                                            multiple={field.display_is_multiple==='Yes'?true:false}
                                            className={fieldError && isFieldTouched ? 'input-error' : ''}
                                          />
                                        ) : field.display_type === "select" ? (
                                          <Select
                                            defaultValue={{
                                              value: "",
                                              label: "Select",
                                            }}
                                            name={field.field_name}
                                            options={selectValuesArray}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            menuPosition="fixed"
                                            onChange={(selectedOption) => {
                                              values[fieldName] = selectedOption.value;
                                              handleChange(fieldName);
                                            }}
                                            onBlur={handleBlur}
                                            styles={customStyles}
                                            isClearable={false}
                                            isSearchable={true}
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 0,
                                              colors: {
                                                ...theme.colors,
                                                primary25:
                                                  "var(--primary-color-light)",
                                                primary: "var(--primary-color)",
                                              },
                                            })}
                                          />
                                        ) : field.display_type === "number" ? (
                                          <Form.Control
                                            type="number"
                                            name={field.field_name}
                                            minLength={
                                              field?.display_min_input || 0
                                            }
                                            maxLength={
                                              field?.display_max_input || 150
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={
                                              field?.placeholder_text || ""
                                            }
                                            className={fieldError && isFieldTouched ? 'input-error' : ''}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <span className="error error4">
                                          {defaultValue.error_list[fieldName]}
                                        </span>
                                        <span className="error error4">
                                          <ErrorMessage name={fieldName}/>
                                        </span>
                                      </Form.Group>
                                    </Col>
                                  </Form.Group>
                                )
                              })
                            } 


                            {/* <AddCustomFields customFieldLists={customFieldLists}  /> */}


                      </Row>
                    </div>
                  </Row>

                  <div className="text-center mt30">
                  {
                    isSubmitting ? 

                      <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2 text-center">Please wait...</span>
                      </Button>
                      :

                      <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                        </div>
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="button" variant="success" onClick={handleLeadClose} className="popupbtn greycolor d-flex align-items-center justify-content-center">Cancel</Button>
                        </div>
                      </div>
                  }
                </div>
                </Form>
                );
              }}
                </Formik>
              </Modal.Body>
            </Modal>
    );
};

export default memo(AddLead)
