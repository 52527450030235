import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Card, Spinner, Form } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import {  toast } from 'react-toastify';
import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import { SketchPicker } from 'react-color';
import Select from 'react-select'
import reactCSS from 'reactcss'
import axios from 'axios';

function AddStage() {

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isUsed, setIsUsed] = useState();
  const [type, setType] = useState();
  const [stageTypeArray, setStageTypeArray] = useState([]);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [submitEvent, setSubmitEvent] = useState(false);

  const queryParams = new URLSearchParams(window.location.search)
  const stageType = queryParams.get("type")

  const [color, setColor] = useState({
    r: '241',
    g: '112',
    b: '19',
    a: '1',
  });


  const [DataInput, setDataInput] = useState({
    name: '',
    hierarchy: '',
    color_code: '',
    is_used: '',
    type: '',
    error_list: [],
  });

  const nameRef = useRef();
  const hierarchyRef = useRef();
  const colorcodeRef = useRef();
  const isUsedRef = useRef();
  const typeRef = useRef();


  const [formnameError, setFormNameError] = useState(false);
  const [formhierarchyRefError, setFormHierarchyRefError] = useState(false);
  const [formColorCodeError, setFormColorCodeError] = useState(false);
  const [formIsUsedError, setFormIsUsedError] = useState(false);
  const [formTypeError, setFormTypeError] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [colorCodeError, setColorCodeError] = useState(false);
  const [isUsedError, setIsUsedError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  useEffect(() => {

    getData();

  }, []);


  function getData() {
    axios.get('api/stage/create').then(response => {
      if (response.status === 200) {
        setStageTypeArray(response.data.data.stageTypes)
        setLoading(false);
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
         // swal("Forbidden", error.response.data.message, "error");
          toast.warning('Forbidden '+error.response.data.message);
          history.push('/stages');    
        } else {
          //swal("error", error.response.data.message, "error");
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  const handleClick = () => {
    setDisplayColorPicker({ displayColorPicker: !displayColorPicker })
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChange = (color) => {
    setColor(color.rgb)
  };

  const handleInput = (e) => {
    e.persist();

    let names = e.target.name;
    let values = e.target.value;

    validateFormData(names, values);
    setDataInput({ ...DataInput, [e.target.name]: e.target.value });

  }

  const handleSelectUsedSave = (e) =>{
    setDataInput({ ...DataInput, ['is_used']: e.value });
  }
  
  
  const handleSelectTypeSave = (e) =>{
    setDataInput({ ...DataInput, ['type']: e.value });
  }


  function validateFormData(type, value) {
    if (type == 'name' && value == '') {
      setFormNameError(false);
      setNameError(true);
      setDataInput(DataInput.error_list.name = 'This field is required');
    } else {
      setFormNameError(false);
      if (type == 'name' && value != '') {
        setNameError(false);
        setDataInput(DataInput.error_list.name = '');
      }
    }

  }

  const stageSubmit = (e) => {
    e.preventDefault();

    if (DataInput.name == '') {
      setNameError(true);
      setFormNameError(true);
      nameRef.current.focus();
    }

    if (!nameError && !colorCodeError) {
      const data = {
        name: DataInput.name,
        hierarchy: DataInput.hierarchy,
        is_used: stageType,
        type: DataInput.type,
        color_code: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')',
      }
      setSubmitEvent(true)
      axios.post("/api/stage/store", data).then(response => {
        if (response.status === 200) {
          //swal("success", 'Stage has been created successfully', "success");
          toast.success('Stage has been created successfully');
          history.push('/stages');
        }
      }).then(json => {

      }).catch(error => {
        if (error.response) {
          setSubmitEvent(false)
          if (error.response.status === 400) {
            if (error.response.data.validation_errors) {
              setDataInput({ ...DataInput, error_list: error.response.data.validation_errors });
            } else {
              //swal("warning", error.response.data.message, "warning");
              toast.warning(error.response.data.message);
            }
          } else if (error.response.status === 300) {
            //swal("warning", error.response.data.message, "warning");
            toast.warning(error.response.data.message);
          } else if(error.response.status === 403){
            toast.warning('Forbidden '+error.response.data.message);
            history.push('/stages');    
          } else {
            //swal("warning", error.response.data.message, "warning");
            toast.error('Oops Something went wrong.');
          }
        }

      });
    }
  }

  const customStyles = {
    menuList: styles => ({
      ...styles,
      color:'black',
      backgroundcolor:'$blue'
  }),
   option: provided => ({
      ...provided,
      borderwidth:"0 0 1px 0px",
      bordercolor:"black",
      borderstyle:"solid",
      '&:hover':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:active':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:visited':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:selected':
      {
        outline: 'none',
        backgroundcolor:'none',
        color: 'white',
      },
      '&:default':
      {
        outline: 'none',
        backgroundcolor:'none',
        color: 'white',
      }
    }),
    control: provided => ({
      ...provided,
      border: "0px solid black",  
      '&:hover':
      {
         outline: 'none',
         backgroundcolor:'none',
      }
    }),
    singleValue: provided => ({
         ...provided,
        border: "0px solid black",   
        color:"black",
         '&:hover':
         {
            outline: 'none',
            backgroundcolor:'none',
         }
    }), 
  }


  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {

    // let stageTypeList = Object.keys(stageTypeArray).map(function (key) {
    //   return (
    //     <option value={key} key={key}>{stageTypeArray[key]}</option>
    //   )
    // }, this);

    let stageTypeList = [];
    stageTypeList.push({ label: 'Please select', value: '' });
    let typeVarList = Object.keys(stageTypeArray).map(function (key) {
      stageTypeList.push({label:stageTypeArray[key],value:key});
    }, this);

    let isUsedLists = [];
        isUsedLists.push({ label: 'Please select', value: '' });
        isUsedLists.push({ label: 'Lead', value: 'lead' });
        isUsedLists.push({ label: 'Deal', value: 'deal' });
    
    return (
      <>
        <Row>
          <Col xs={12} xl={12}>
            <Card border="light" className="bg-white shadow-sm">

            <Row className="mb-3">
                  <Col md={6}>
                  </Col>
                  <Col md={6} className="text-right">
                    <button className="btn1 me-1" onClick={() => { history.goBack(); }}><span className="backarrow-icon"></span>Back</button>
                  </Col>
            </Row>

            <Card.Body className="borderline mb-5">
                <Row>
                <h5 className="mb-4">Add Stage information</h5>
                </Row>

                <Form onSubmit={stageSubmit} Encrypted="multipart/form-data">
                <Row>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" className="py-0">
                       Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="name">
                        <Form.Control required type="text" name="name" onChange={handleInput} ref={nameRef} onBlur={handleInput} value={DataInput.name} placeholder="Enter stage name" />
                  </Form.Group>
                  <span className="error">{DataInput.error_list.name}</span>
                      {formnameError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" className="py-0">
                       Type
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="type">
                        <Select
                            defaultValue=""
                            placeholder="Please select"
                            name="type"
                            options={stageTypeList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleSelectTypeSave}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#0074E1',
                                primary: '#0074E1',
                              },
                            })}
                            ref={typeRef}
                        />
                  </Form.Group>
                  <span className="error">{DataInput.error_list.type}</span>
                      {formTypeError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                </div>
                <div className="col-md-6 col-sm-6 right-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" className="py-0">
                       Position
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="name">
                        <Form.Control type="number" name="hierarchy" onChange={handleInput} ref={hierarchyRef} onBlur={handleInput} value={DataInput.hierarchy} placeholder="Enter position" />
                  </Form.Group>
                  <span className="error">{DataInput.error_list.hierarchy}</span>
                      {formhierarchyRefError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" className="py-0">
                       Color Code<span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="name" className="d-flex align-items-center form-control">
                        <div>
                          <div style={styles.swatch} onClick={handleClick} className="p1">
                            <div style={styles.color} />
                          </div>
                          {displayColorPicker ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={handleClose} />
                            <SketchPicker color={color} onChange={handleChange} />
                          </div> : null}
                        </div>
                        <span style={{ paddingLeft: '20px' }}>Selected Code are rgba({color.r},{color.g},{color.b},{color.a})</span>
                  </Form.Group>
                  <span className="error">{DataInput.error_list.hierarchy}</span>
                      {formhierarchyRefError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                </div>
                </Row>
                </Form>
              </Card.Body>

              <Card.Body className="listcard1">
              <div>
                   {
                        submitEvent ? 

                        <Button variant="primary" className="d-flex align-items-center mb-1">
                              <Spinner animation="border" size="sm" />
                              <span className="ms-2">Please Wait...</span>
                        </Button>
                        :
                        <Button variant="primary" type="submit" className="d-flex">Submit <span class="rightarrow"></span></Button>
                    }
                    
                  </div>
              </Card.Body>
            </Card>
          </Col>


        </Row>
      </>
    );
  }
}

export default AddStage;


