/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LeadStageAddPopUp } from "../features/userSlice";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";

export default (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [salutationData, setSalutationData] = useState([]);
  const [stageTypeArray, setStageTypeArray] = useState([]);
  const showAddLeadStage = useSelector((state) => state.leadStageAddPopUp);

  const [defaultValue, setdefaultValue] = useState({
    name: "",
    hierarchy: "",
    color_code: "",
    is_used: "",
    type: "",
    error_list: [],
  });

  useEffect(() => {
    async function fetchData() {
      await getCreateData();
    }
    fetchData();
  }, [showAddLeadStage]);

  async function getCreateData() {
    await axios
      .get("api/stage/create")
      .then((response) => {
        if (response.status === 200) {
          setStageTypeArray(response.data.data.stageTypes);

          setdefaultValue({
            name: "",
            hierarchy: response.data.data.latestLeadPosition,
            color_code: "",
            is_used: "",
            type: "",
            error_list: [],
          });

          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/stages");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const handleLeadStageClose = () => {
    setdefaultValue({ ...defaultValue, error_list: "" });
    dispatch(LeadStageAddPopUp(false));
  };

  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const numberRegExp = /^(0|[1-9][0-9]*)$/;
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim("Name cannot include space")
      .strict(true)
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter stage name"),
    hierarchy: yup
      .string()
      .matches(numberRegExp, "Allow numeric only")
      .required("Enter stage position"),
  });

  const handleSelectTypeSave = (e) => {
    setdefaultValue({ ...defaultValue, ["type"]: e.value });
  };

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      name: values.name,
      hierarchy: values.hierarchy,
      type: defaultValue.type,
      is_used: "lead",
    };

    await axios
      .post("/api/stage/store", data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(LeadStageAddPopUp(false));
          toast.success("Stage created successfully");
          history.push("/stages");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400) {
            setdefaultValue({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/stages");
          } else {
            toast.warning(error.response.data.message);
          }
        }
      });
  };

  let stageTypeList = [];
  stageTypeList.push({ label: "Please select", value: "" });
  let typeVarList = Object.keys(stageTypeArray).map(function (key) {
    stageTypeList.push({ label: stageTypeArray[key], value: key });
  }, this);

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showAddLeadStage}
      onHide={handleLeadStageClose}
    >
      <Modal.Header>
        <Modal.Title className="h2">Add Lead Stage</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={handleLeadStageClose}
        />
        <span className="modelclose"></span>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = props;
            return (
              <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-md-12 col-sm-12">
                    <Row className="align-items-center">
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">
                            Name<span className="text-danger">*</span>
                          </h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="name">
                            <Form.Control
                              type="text"
                              minLength={2}
                              maxLength={50}
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter Name"
                              className={
                                errors.name && touched.name
                                  ? "input-error"
                                  : null
                              }
                            />
                            <span className="error error4">
                              {defaultValue.error_list.name}
                            </span>
                            <span className="error error4">
                              <ErrorMessage name="name" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">
                            Position<span className="text-danger">*</span>
                          </h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="hierarchy">
                            <Form.Control
                              type="text"
                              maxLength={3}
                              name="hierarchy"
                              value={values.hierarchy}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter Position"
                              className={
                                errors.hierarchy && touched.hierarchy
                                  ? "input-error"
                                  : null
                              }
                            />
                            {defaultValue ? (
                              <span className="error error4">
                                {defaultValue.error_list.hierarchy}
                              </span>
                            ) : (
                              ""
                            )}

                            <span className="error error4">
                              <ErrorMessage name="hierarchy" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>

                      <Form.Group className="position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">Type</h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="hierarchy">
                            <Select
                              defaultValue=""
                              placeholder="Please select"
                              name="type"
                              options={stageTypeList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={handleSelectTypeSave}
                              menuPosition="fixed"
                              styles={customStyles}
                              isClearable={false}
                              isSearchable={false}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "var(--primary-color-light)",
                                  primary: "var(--primary-color)",
                                },
                              })}
                            />
                            <span className="error error4">
                              {defaultValue.error_list.type}
                            </span>
                            <span className="error error4">
                              <ErrorMessage name="type" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                    </Row>
                  </div>
                </Row>

                <div className="text-center mt30">
                  {isSubmitting ? (
                    <Button className="d-flex align-items-center popupbtn text-center btnblue mb-1">
                      <Spinner animation="border" size="sm" />
                      <span className="ms-2">Please wait...</span>
                    </Button>
                  ) : (
                    <div
                      className="btn-toolbar"
                      role="toolbar"
                      aria-label="Toolbar with button groups"
                    >
                      <div
                        className="btn-group mr-2"
                        role="group"
                        aria-label="First group"
                      >
                        <Button
                          type="submit"
                          variant="success"
                          className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                        >
                          Create
                        </Button>
                      </div>
                      <div
                        className="btn-group mr-2"
                        role="group"
                        aria-label="First group"
                      >
                        <Button
                          type="button"
                          variant="success"
                          onClick={handleLeadStageClose}
                          className="popupbtn greycolor d-flex align-items-center justify-content-center"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
