
import React from 'react';
import { Col } from '@themesberg/react-bootstrap';

export default (props) => {

  const { state, perPage, dataCount, handlePerPage, handleCheckAll } = props;

  return (
    <>
      <Col md={3} className="ps-2">
        <h6>
          Showing <b>{(((state?.users?.per_page ? state?.users?.per_page : 0) * (state?.users?.current_page ? state?.users?.current_page : 0)) - ((state?.users?.per_page ? state?.users?.per_page : 0) - 1))}</b> - <b>{(((state?.users?.per_page ? state?.users?.per_page : 0) * (state?.users?.current_page ? state?.users?.current_page : 0)) - ((state?.users?.per_page ? state?.users?.per_page : 0) - 1) + ((dataCount ? dataCount : 0) - 1))}</b> of <b>{state?.users?.total ? state?.users?.total : 0}</b>
        </h6>
      </Col>
      <Col xl={4} md={4}>
        <div className="row align-items-center g-0">
          <div className="col-md-3 col-xl-4"></div>
          <div className="col-md-2 col-xl-3">
            <h6 className="text-right me-3">Per Page</h6>
          </div>
          <div className="col-md-4 col-xl-4">
            <select className="border-start-0 border-end-0 border-top-0 borderbottom w-50" value={perPage} onChange={(e) => { handlePerPage(e.target.value); handleCheckAll(false) }}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="col-md-2 col-xl-1"></div>
        </div>
      </Col>
    </>
  );
};
