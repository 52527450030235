import React, { useState, memo } from "react";
import Preloader from "./PreloaderCenter";
import ReactECharts from 'echarts-for-react';

function LeadSourceChart(props) {
  const [loading, setLoading] = useState(true);

  var sourceArray = [];
  var i
  for (i = 0; i < props.sourchChartData.length; i++) {
    sourceArray.push(props.sourchChartData[i])
  }

  setTimeout(() => {
    setLoading(false)
  }, 500);

  // var monthLeadCount = [];
  // var monthDealCount = [];

  // if(stackChartData.leadCount){
  //     Object.entries(stackChartData.leadCount).forEach(([key, value]) => {
  //       monthLeadCount[key] = value; 
  //     });
  //     monthLeadCount.shift(); 
  // }

  // if(stackChartData.dealCount){
  //   Object.entries(stackChartData.dealCount).forEach(([key, value]) => {
  //     monthDealCount[key] = value; 
  //   });
  //   monthDealCount.shift();
  // }

  // const option = {
  //   xAxis: {
  //     type: 'category',
  //     data: ['Advertisement', 'Cold Call', 'Employee Referral', 'External Referral', 'Online Store', 'Social Media', 'Trade Show', 'Seminar', 'Web Download', 'Web Research', 'Web Mail', 'Chat']
  //   },
  //   yAxis: {
  //     type: 'value'
  //   },
  //   series: [
  //     {
  //       data: [
  //         120,
  //         {
  //           value: 200,
  //           itemStyle: {
  //             color: '#a90000'
  //           }
  //         },
  //         150,
  //         80,
  //         70,
  //         110,
  //         130,
  //         110,
  //         110,
  //         110,
  //         110,
  //         110,
  //       ],
  //       type: 'bar'
  //     }
  //   ]
  // };

  const option = {
    dataset: [
      {
        dimensions: ['name', 'score'],
        // source: [
        //   ['Advertisement', 41],
        //   ['Cold Call', 20],
        //   ['Employee Referral', 52],
        //   ['External Referral', 37],
        //   ['Online Store', 25],
        //   ['Social Media', 19],
        //   ['Trade Show', 71],
        //   ['Seminar', 36],
        //   ['Web Download', 67],
        //   ['Web Mail', 67],
        //   ['Chat', 67],

        // ]
        source: sourceArray
      },
      {
        transform: {
          type: 'sort',
          config: { dimension: 'score', order: 'desc' }
        }
      }
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    toolbox: {
      feature: {
        //dataView: { readOnly: false },
        //restore: {},
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      axisLabel: { interval: 0, rotate: 30 },
      axisTick: {
        alignWithLabel: true
      }
    },
    yAxis: {},
    series: {
      name: 'Lead',
      itemStyle: {
        color: '#1164B5'
      },
      type: 'bar',
      encode: { x: 'name', y: 'score' },
      datasetIndex: 1
    }
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    return (

      <div className="display flex-wrap w-100 reportborder mt30">
        <div className="col-md-12">
          <div className="flex-wrap task align-items-start justify-content-end">
            <div className="innerdealtable w-100">
              <div className="display">
                <div className="col-md-12">
                  <h4>Source-wise Lead List</h4>
                </div>
              </div>
            </div>
            <ReactECharts
              option={option}
              notMerge={true}
              lazyUpdate={true}
              theme={"theme_name"}
              onChartReady={''}
              onEvents={''}
              opts={''}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default memo(LeadSourceChart);


