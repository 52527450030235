import React, { useEffect, useState, memo } from "react";
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import Preloader from "./PreloaderCenter";
import { useHistory } from 'react-router-dom';
import moment from "moment-timezone";
import Cookies from 'universal-cookie';
import { login, selectUser, LoginPermission, Workspace, LoginAccessMsg, selectDefaultData } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';


var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = 6;
var currentPage = 1;
var calendorFormate = '';
var searchData = '';


function MyDashboardTask(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [taskList, setTaskList] = useState([]);
  const [calendorDateFormate, setCalendorDateFormate] = useState('MMM, DD YYYY');

  const loginUser = useSelector(selectUser);
  const user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  //check msg for nonAuthentication Action pages
  const withOutLoginMsg = useSelector((state) => state.loginAccessMsg);
  if (withOutLoginMsg) {
    toast.warning(withOutLoginMsg);
    dispatch(LoginAccessMsg());
  }

  const [stateTask, setStateTask] = useState({
    users: ''
  });

  useEffect(() => {
    setTimeout(() => {
      getData()
    }, 200);
    
  }, []);


  const getData = async (currentPage) => {

    const params = {
      search: searchData,
      sort_field: '',
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    }
    axios.get(`api/dashboard-my-task?page=${currentPage}`, { params }).then(response => {
      if (response.status === 200) {

        setTaskList(response.data.data.taskRecords.data);
        setStateTask({
          users: response.data.data.taskRecords
        });

        props.handleTaskCount(response.data.data.taskRecords.total)

        calendorFormate = response.data.data.preferredDateFormat.split(' ');
        if (calendorFormate) {
          if (calendorFormate[0] == 'YYYY-MMM-DD') {
            setCalendorDateFormate('YYYY MMM, DD');
          } else if (calendorFormate[0] == 'DD-MMM-YYYY') {
            setCalendorDateFormate('DD, MMM YYYY');
          } else if (calendorFormate[0] == 'YYYY-DD-MMM') {
            setCalendorDateFormate('YYYY DD, MMM');
          } else if (calendorFormate[0] == 'MMM-DD-YYYY') {
            setCalendorDateFormate('MMM, DD YYYY');

          }
        }
      }
      setLoading(false);
    }).then(json => {
      toast.success(json.data.data.message);
    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        }else if (error.response.status === 500) {
          //toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      }
    });
  }

  var taskDataLists = '';

  if (loading) {
    return <Preloader show={true} />;
  } else {

    taskDataLists = taskList.map((item, i) => {
      var classNa = 'lb10 ' + item.priority;
      return (
        <tr key={item.id} onClick={() => history.push('edit-task/' + item.id)}>
          <td className="number"><span>{item.name}</span></td>
          <td className="desellp"><span>{item.description}</span></td>
          <td>{moment.tz(item.end_date, defaultTimeZone).format(calendorDateFormate)}</td>
          <td><h5 className={classNa}>{item.priority ? item.priority.charAt(0).toUpperCase() + item.priority.slice(1).toLowerCase() : 'N/A'}</h5></td>
        </tr>
      )
    });

    return (
      <div className="width1">
        <div className="display flex-wrap task align-items-start justify-content-end">
          <div className="col-md-12">
            <h5 className="tables_title">My Tasks</h5>
          </div>
          
          <table>
            <tbody>
            {
              taskDataLists.length >= 1 ?
                <tr>
                  <th style={{ width: "25%" }}>Subject</th>
                  <th style={{ width: "32%" }}>Description</th>
                  <th style={{ width: "28%" }}>Due Date</th>
                  <th style={{ width: "15%" }}>Priority</th>
                </tr>
                :
                ''
            }

            {
              taskDataLists.length >= 1 ?
                taskDataLists
                :
                <tr>
                  <td colSpan={4}><span className="text-center d-flex align-items-center justify-content-center">No Data Available</span></td>
                </tr>
                
            }
            </tbody>
          </table>
          {
            taskDataLists.length >= 1 ?

              <div className="justify-content-end">
                <Pagination
                  activePage={stateTask?.users?.current_page ? stateTask?.users?.current_page : 0}
                  itemsCountPerPage={stateTask?.users?.per_page ? stateTask?.users?.per_page : 0}
                  totalItemsCount={stateTask?.users?.total ? stateTask?.users?.total : 0}
                  onChange={(pageNumber) => {
                    currentPage = (pageNumber);
                    getData(pageNumber)
                  }}
                  pageRangeDisplayed={5}
                  itemClass="page-item"
                  linkClass="page-link"
                // firstPageText="First Page"
                // lastPageText="Last Page"
                />
              </div>
              :
              ''
          }

        </div>
      </div>
    )
  }
}

export default memo(MyDashboardTask);
