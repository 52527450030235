import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {  faCheck,  faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Dropdown, Form,  FormCheck } from '@themesberg/react-bootstrap';
import Preloader from "../../components/PreloaderCenter";
import { useSelector } from "react-redux";
import { selectUser,selectDefaultData  } from "../../features/userSlice";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';

import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import EasyEdit, { Types } from "react-easy-edit";

var currentPage = 1;

function EditActivity(props) {
     const history = useHistory();
     const loginUser = useSelector(selectUser);
     const userPermissions = useSelector(state =>
          state.permission
     )
     const [isEditable, setIsEditable] = useState(true)

     const user = loginUser?loginUser:[];
     const userPermission = userPermissions?userPermissions:[];
     
     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;


     const [dataID, setDataID] = useState(props.match.params.id);
     const [updateLeadData, setUpdateLeadData] = useState(false);
     
     const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
     const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');

     const [nameErrorMsg, setNameErrorMsg] = useState();
     const [locationErrorMsg, setLocationErrorMsg] = useState();
     const [descriptionErrorMsg, setDescriptionErrorMsg] = useState(); 

     //=========START:CODE FOR EDIT LEAD DATA==========//

     const [loading, setLoading] = useState(true);
     const [dataRecord, setDataRecord] = useState('');
     const [record, setRecord] = useState('');

     const [ErrorInput, setErrorInput] = useState({ error_list: [] });
     const [dueDate, setDueDate] = useState(new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone})));
     
     const [eventStartDate, setEventStartDate] = useState(new Date());
     const [eventEndDate, setEventEndDate] = useState(new Date());
     
     const [eventFullStartDate, setEventFullStartDate] = useState(new Date());
     const [eventFullEndDate, setEventFullEndDate] = useState(new Date());
     
     const [checkPer, setCheckPer] = useState(false);
     const [allEditDaySelect, setAllEditDaySelect] = useState(false);

     //========Const data for Activity (Lead and Deal)
     var calendorFormate = '';

     useEffect(() => {
          async function fetchData() {
               await getData();
          }
          fetchData();
     }, [updateLeadData]);
     
     function getData() {
          axios.get('api/activity/edit/' + dataID).then(response => {
               if (response.data.code === 200) {
                    //console.log(response,'response')
                    setDataRecord(response.data.data.record);
                    setRecord(response.data.data);
                    
                    setPreferredDateFormatData(response.data.data.preferredDateFormat);
                    
                    setEventStartDate(new Date(moment.utc(response.data.data.record.start_date).tz(defaultTimeZone).format(preferredDateFormatData)));
                    setEventEndDate(new Date(moment.utc(response.data.data.record.end_date).tz(defaultTimeZone).format(preferredDateFormatData)));

                    if (response.data.data.record.is_fullday == 1) {
                         setCheckPer(true);
                         var fullDayStartEvent = moment.tz(response.data.data.record.start_date, defaultTimeZone).format(preferredDateFormatData)
                         var fullDayEndEvent = moment.tz(response.data.data.record.end_date, defaultTimeZone).format(preferredDateFormatData)
    
                         setEventFullStartDate(new Date(fullDayStartEvent));
                         setEventFullEndDate(new Date(fullDayEndEvent));
                         setAllEditDaySelect(true);
                    }

                    calendorFormate = response.data.data.preferredDateFormat.split(' ');
                    if (calendorFormate) {
                         if (calendorFormate[0] == 'YYYY-MMM-DD') {
                              setCalendorDateFormate('yyyy/MM/dd');
                         } else if (calendorFormate[0] == 'DD-MMM-YYYY') {
                              setCalendorDateFormate('dd/MM/yyyy');
                         } else if (calendorFormate[0] == 'YYYY-DD-MMM') {
                              setCalendorDateFormate('yyyy/dd/MM');
                         } else if (calendorFormate[0] == 'MMM-DD-YYYY') {
                              setCalendorDateFormate('MM/dd/yyyy');
                         }
                    }
                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/deals');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/activitys');
          });
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }



     const handleSaveData = (value, name) => {
          var startDate = eventStartDate;
          var endDate = eventEndDate;
          var start_dates = ''
          var end_dates = ''

          if(name==='start_date'){
               startDate = value;
          }else if(name==='end_date'){
               endDate = value;
          }

          if(name==='start_date' || name==='end_date'){
               start_dates = allEditDaySelect ? moment(startDate).format("YYYY-MM-DD") : moment(startDate).format("YYYY-MM-DD HH:mm:ss")
               end_dates = allEditDaySelect ? moment(endDate).format("YYYY-MM-DD") : moment(endDate).format("YYYY-MM-DD HH:mm:ss")
          }else{
               start_dates = allEditDaySelect ? moment(eventFullStartDate).format("YYYY-MM-DD") : moment(eventStartDate).format("YYYY-MM-DD HH:mm:ss")
               end_dates = allEditDaySelect ? moment(eventFullEndDate).format("YYYY-MM-DD") : moment(eventEndDate).format("YYYY-MM-DD HH:mm:ss")
          }
          
          const data = {
               id: dataID,
               name: dataRecord.name,
               description: dataRecord.description,
               location: dataRecord.location,
               type: dataRecord.lead_id ? 'lead' : 'deal',
               leadId: dataRecord.lead_id ? dataRecord.lead_id : '',
               dealId: dataRecord.deal_id ? dataRecord.deal_id : '',
               allDay: allEditDaySelect ? 'yes' : 'no',
               start_date: start_dates,
               end_date: end_dates,
               [name]: value,
          }

          if(value){
               axios.post("/api/activity/update", data).then(response => {
                    if (response.status === 200) {
                         setIsEditable(true)
                         updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         toast.success('Data updated successfully');
                    }
               }).then(json => {
     
               }).catch(error => {
                    if (error.response) {
                         setIsEditable(true)
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         if (error.response.status === 400) {
                              setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         }else if (error.response.status === 403) {
                              //swal("error", error.response.data.message, "error");
                              toast.warning('Forbidden '+ error.response.data.message);
                         }else{
                              toast.error('Oops Something went wrong.')
                         }
                    }
               });
          }else{
               setIsEditable(true)
               updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
               setErrorInput({ ...ErrorInput, error_list: '' });
          }
     };


     

     const handleDateChanges = (e,type) => {

          var startDate = eventStartDate;
          var endDate = eventEndDate;

          if(type=='start_date'){
               startDate = e;
          }else if(type=='end_date'){
               endDate = e;
          }
          const data = {
               id: dataID,
               name: dataRecord.name,
               description: dataRecord.description,
               location: dataRecord.location,
               allDay: allEditDaySelect ? 'yes' : 'no',
               start_date: allEditDaySelect ? moment(startDate).format("YYYY-MM-DD") : moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
               end_date: allEditDaySelect ? moment(endDate).format("YYYY-MM-DD") : moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
          }

          axios.post("/api/activity/update", data).then(response => {
               if (response.status === 200) {
                    setIsEditable(true)
                    updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    toast.success('Data updated successfully');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setIsEditable(true)
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    if (error.response.status === 400) {
                         setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                    }else if (error.response.status === 403) {
                         //swal("error", error.response.data.message, "error");
                         toast.warning('Forbidden '+ error.response.data.message);
                    }else{
                         toast.error('Oops Something went wrong.')
                    }
               }
          });
     }

     const cancel = () => {
          setIsEditable(true)

          setEventStartDate(dataRecord.start_date?new Date(dataRecord.start_date):'');
          setEventEndDate(dataRecord.end_date?new Date(dataRecord.end_date):'');

          // if (dataRecord.is_fullday == 1) {
          //      var fullDayStartEvent = moment.tz(dataRecord.start_date, defaultTimeZone).format(preferredDateFormatData)
          //      var fullDayEndEvent = moment.tz(dataRecord.end_date, defaultTimeZone).format(preferredDateFormatData)
     
          //      setEventFullStartDate(new Date(fullDayStartEvent));
          //      setEventFullEndDate(new Date(fullDayEndEvent));
          // }

          setNameErrorMsg('');
          setLocationErrorMsg('');
          setDescriptionErrorMsg(''); 
     };


     const handleClick = (e) => {
          setIsEditable(false)
     }

     function deleteActivity(id) {
          const data = {
               id: id,
          }

          swal({
               title: "Are you sure you want to delete?",
               text: "Once deleted, you will not be able to recover this data!",
               icon: "warning",
               buttons: true,
               dangerMode: true,
          })
               .then((willDelete) => {
                    if (willDelete) {
                         axios.delete('api/activity/destroy', { data }).then(response => {
                              if (response.status === 200) {
                                   toast.success(response.data.message);
                                   history.push('/activitys')
                              }
                         }).then(json => {

                         }).catch(error => {
                              if (error.response) {
                                   if (error.response.status === 400) {
                                        toast.warning(error.response.data.message);
                                   } else if (error.response.status === 403) {
                                        toast.warning('Forbidden ' + error.response.data.message);
                                   } else {
                                        toast.error(error.response.data.message);
                                   }
                              }
                         });
                    }
               });
     }

     const customStyles1 = {
          menuList: styles => ({
               ...styles,
               // color: 'black',
               // backgroundcolor: '$blue',
               paddingTop: 0,
               paddingBottom: 0,
          }),
     }

     const handleEditCheckbox = (e) => {
          e.persist();
          //allEditDaySelect ? setAllEditDaySelect(false) : setAllEditDaySelect(true);
          //console.log(moment(new Date(new Date(new Date()).setMinutes(new Date().getMinutes() + 15))).format("YYYY-MM-DD  HH:mm:ss"))

          if(!allEditDaySelect){
               const data = {
                    id: dataID,
                    name: dataRecord.name,
                    description: dataRecord.description,
                    location: dataRecord.location,
                    type: dataRecord.lead_id ? 'lead' : 'deal',
                    leadId: dataRecord.lead_id ? dataRecord.lead_id : '',
                    dealId: dataRecord.deal_id ? dataRecord.deal_id : '',
                    allDay: allEditDaySelect ? 'no' : 'yes',
                    start_date: moment(new Date()).format("YYYY-MM-DD"),
                    end_date: moment(new Date()).format("YYYY-MM-DD"),
               }
               
               axios.post("/api/activity/update", data).then(response => {
                    if (response.status === 200) {
                         setIsEditable(true)
                         updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         toast.success('Data updated successfully');
                         allEditDaySelect ? setAllEditDaySelect(false) : setAllEditDaySelect(true);
                    }
               }).then(json => {

               }).catch(error => {
                    if (error.response) {
                         setIsEditable(true)
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         if (error.response.status === 400) {
                              setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         }else if (error.response.status === 403) {
                              //swal("error", error.response.data.message, "error");
                              toast.warning('Forbidden '+ error.response.data.message);
                         }else{
                              toast.error('Oops Something went wrong.')
                         }
                    }
               });
          }else{
               const data = {
                    id: dataID,
                    name: dataRecord.name,
                    description: dataRecord.description,
                    location: dataRecord.location,
                    type: dataRecord.lead_id ? 'lead' : 'deal',
                    leadId: dataRecord.lead_id ? dataRecord.lead_id : '',
                    dealId: dataRecord.deal_id ? dataRecord.deal_id : '',
                    allDay: allEditDaySelect ? 'no' : 'yes',
                    start_date: moment(new Date(new Date(new Date()).setMinutes(new Date().getMinutes() + 15))).format("YYYY-MM-DD HH:mm:ss"),
                    end_date: moment(new Date(new Date(new Date()).setHours(new Date().getHours() + 2))).format("YYYY-MM-DD HH:mm:ss"),
               }
               
               axios.post("/api/activity/update", data).then(response => {
                    if (response.status === 200) {
                         setIsEditable(true)
                         updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         toast.success('Data updated successfully');
                         setTimeout(() => allEditDaySelect ? setAllEditDaySelect(false) : setAllEditDaySelect(true), 500);
                    }
               }).then(json => {

               }).catch(error => {
                    if (error.response) {
                         setIsEditable(true)
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         if (error.response.status === 400) {
                              setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         }else if (error.response.status === 403) {
                              //swal("error", error.response.data.message, "error");
                              toast.warning('Forbidden '+ error.response.data.message);
                         }else{
                              toast.error('Oops Something went wrong.')
                         }
                    }
               });    
          }

     }

     const filterPassedTime = (time) => {
          const currentDate = new Date();
          const selectedDate = new Date(time);
      
          return currentDate.getTime() < selectedDate.getTime();
        };
      

     const CustomStartDateDisplay = props => {
          if(props.value && props.value!=null){
               setIsEditable(false)
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         selected={eventFullStartDate}
                         filterTime={filterPassedTime}
                         onChange={(date) => {
                              setEventFullStartDate(date)
                             // handleStartDateSave(date)
                         }
                         }
                         //showTimeSelect
                         //timeFormat="HH:mm"
                         //timeIntervals={5}
                         timeCaption="time"
                         dateFormat={calendorDateFormate}
                         className="amount_txt"
                    />
     
                    
               );
          }else{
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         selected={eventFullStartDate}
                         filterTime={filterPassedTime}
                         onChange={(date) => {
                              setEventFullStartDate(date)
                             // handleStartDateSave(date)
                         }
                         }
                         //showTimeSelect
                         //timeFormat="HH:mm"
                         //timeIntervals={5}
                         timeCaption="time"
                         dateFormat={calendorDateFormate}
                         className="amount_txt"
                    />
     
                    
               );
          }

          // var startDates = ''
          // const handleStartDateSave = (event) => {
          //      if(event && event !='undefined'){
          //           startDates = moment(event).format("YYYY-MM-DD")
          //      }else{
          //           startDates = null;
          //      }
          //      props.setParentValue(startDates);
          // };
               
          // return (
          //      <DatePicker
          //           autocomplete="off"
          //           minDate={moment().toDate()}
          //           selected={eventFullStartDate}
          //           filterTime={filterPassedTime}
          //           onChange={(date) => {
          //                setEventFullStartDate(date)
          //                handleStartDateSave(date)
          //           }
          //           }
          //           //showTimeSelect
          //           //timeFormat="HH:mm"
          //           //timeIntervals={5}
          //           timeCaption="time"
          //           dateFormat={calendorDateFormate}
          //           className="amount_txt"
          //      />

               
          // );
     };


     const DisplayCustStartDate = props => {
          const val = props;
          return (
               <DatePicker
                    autocomplete="off"
                    //minDate={moment().toDate()}
                    filterTime={filterPassedTime}
                    //selected={eventFullStartDate}
                    selected={val.value ? val.value : eventFullStartDate}
                    timeCaption="time"
                    dateFormat={calendorDateFormate}
                    className="amount_txt"
                    disabled={true}
               />
          );
     };
     

     const CustomEndDateDisplay = props => {
          if(props.value && props.value!=null){
               setIsEditable(false)
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         filterTime={filterPassedTime}
                         selected={eventFullEndDate}
                         onChange={(date) => {
                              setEventFullEndDate(date)
                              //handleEndDateSave(date)
                         }
                         }
                         timeCaption="time"
                         dateFormat={calendorDateFormate}
                         className="amount_txt"
                    />
               )
          }else{
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         filterTime={filterPassedTime}
                         selected={eventFullEndDate}
                         onChange={(date) => {
                              setEventFullEndDate(date)
                              //handleEndDateSave(date)
                         }
                         }
                         timeCaption="time"
                         dateFormat={calendorDateFormate}
                         className="amount_txt"
                    />
               )

          }

          // var endDates = ''
          // const handleEndDateSave = (event) => {
          //      if(event && event !='undefined'){
          //           endDates = moment(event).format("YYYY-MM-DD")
          //      }else{
          //           endDates = null;
          //      }
          //      props.setParentValue(endDates);
          // };
               
          // return (
          //      <DatePicker
          //           autocomplete="off"
          //           minDate={moment().toDate()}
          //           filterTime={filterPassedTime}
          //           selected={eventFullEndDate}
          //           onChange={(date) => {
          //                setEventFullEndDate(date)
          //                handleEndDateSave(date)
          //           }
          //           }
          //           timeCaption="time"
          //           dateFormat={calendorDateFormate}
          //           className="amount_txt"
          //      />

               
          // );
     };

     const DisplayCustEndDate = props => {
          const val = props;
          return (
               <DatePicker
                    autocomplete="off"
                    //minDate={moment().toDate()}
                    filterTime={filterPassedTime}
                    selected={eventFullEndDate}
                    timeCaption="time"
                    dateFormat={calendorDateFormate}
                    className="amount_txt"
                    disabled={true}
               />
          );
     };


     const CustomHalfStartDateDisplay = props => {
          
          if(props.value && props.value!=null){
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         filterTime={filterPassedTime}
                         selected={eventStartDate}
                         onChange={(date) => {
                              setEventStartDate(date)
                              //handleHalfStartDateSave(date)
                         }
                         }
                         showTimeSelect
                         timeFormat="HH:mm"
                         timeIntervals={5}
                         timeCaption="time"
                         dateFormat={calendorDateFormate + ' h:mm aa'}
                         className="amount_txt"
                    />
                );
          }else{
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         filterTime={filterPassedTime}
                         selected={eventStartDate}
                         onChange={(date) => {
                              setEventStartDate(date)
                              //handleHalfStartDateSave(date)
                         }
                         }
                         showTimeSelect
                         timeFormat="HH:mm"
                         timeIntervals={5}
                         timeCaption="time"
                         dateFormat={calendorDateFormate + ' h:mm aa'}
                         className="amount_txt"
                    />
                );
          }

          // var startDates = ''
          // const handleHalfStartDateSave = (event) => {
          //      if(event && event !='undefined'){
          //           startDates = moment(event).format("YYYY-MM-DD HH:mm:ss")
          //      }else{
          //           startDates = null;
          //      }
          //      props.setParentValue(startDates);
          // };
               
          // return (
          //           <DatePicker
          //                autocomplete="off"
          //                minDate={moment().toDate()}
          //                filterTime={filterPassedTime}
          //                selected={eventStartDate}
          //                onChange={(date) => {
          //                     setEventStartDate(date)
          //                     handleHalfStartDateSave(date)
          //                }
          //                }
          //                showTimeSelect
          //                timeFormat="HH:mm"
          //                timeIntervals={5}
          //                timeCaption="time"
          //                dateFormat={calendorDateFormate + ' h:mm aa'}
          //                className="amount_txt"
          //           />
          //       );
     };

     const DisplayCustHalfStartDate = props => {
          const val = props.value;
          return (
               <DatePicker
                    autocomplete="off"
                    //minDate={moment().toDate()}
                    filterTime={filterPassedTime}
                    selected={val}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat={calendorDateFormate + ' h:mm aa'}
                    className="amount_txt"
                    disabled={true}
               />
          );
     };


     const CustomHalfEndDateDisplay = props => {
          if(props.value && props.value!=null){
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         filterTime={filterPassedTime}
                         selected={eventEndDate}
                         onChange={(date) => {
                              setEventEndDate(date)
                              //handleEndDateSave(date)
                         }
                         }
                         showTimeSelect
                         timeFormat="HH:mm"
                         timeIntervals={5}
                         timeCaption="time"
                         dateFormat={calendorDateFormate + ' h:mm aa'}
                         className="amount_txt"
                    />
     
                    
               );
          }else{
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         filterTime={filterPassedTime}
                         selected={eventEndDate}
                         onChange={(date) => {
                              setEventEndDate(date)
                              //handleEndDateSave(date)
                         }
                         }
                         showTimeSelect
                         timeFormat="HH:mm"
                         timeIntervals={5}
                         timeCaption="time"
                         dateFormat={calendorDateFormate + ' h:mm aa'}
                         className="amount_txt"
                    />
     
                    
               );
          }

          // var endDates = ''
          // const handleEndDateSave = (event) => {
          //      if(event && event !='undefined'){
          //           endDates = moment(event).format("YYYY-MM-DD HH:mm:ss")
          //      }else{
          //           endDates = null;
          //      }
          //      props.setParentValue(endDates);
          // };
               
          // return (
          //      <DatePicker
          //           autocomplete="off"
          //           minDate={moment().toDate()}
          //           filterTime={filterPassedTime}
          //           selected={eventEndDate}
          //           onChange={(date) => {
          //                setEventEndDate(date)
          //                handleEndDateSave(date)
          //           }
          //           }
          //           showTimeSelect
          //           timeFormat="HH:mm"
          //           timeIntervals={5}
          //           timeCaption="time"
          //           dateFormat={calendorDateFormate + ' h:mm aa'}
          //           className="amount_txt"
          //      />

               
          // );
     };

     const DisplayCustHalfEndDate = props => {
          const val = props;
          return (
               <DatePicker
                    autocomplete="off"
                    //minDate={moment().toDate()}
                    filterTime={filterPassedTime}
                    selected={eventEndDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat={calendorDateFormate + ' h:mm aa'}
                    className="amount_txt"
                    disabled={true}
               />
          );
     };
     


     if (loading) {
          return <Preloader show={true} />;
     } else {

          let FFName = ''
          let fullName = ''
          let expName = []
          let sortName = ''
          let fullTitleName = ''
          
          if(record?.contactName?.first_name && record?.contactName?.last_name){
               FFName = record.contactName.first_name.charAt(0) + record.contactName.last_name.charAt(0)
               fullName = record.contactName.first_name +' '+ record.contactName.last_name
          }else if(record?.contactName?.first_name && !record?.contactName?.last_name){
               FFName = record.contactName.first_name.charAt(0)
               fullName = record.contactName.first_name
          }else if(record?.contactName?.last_name && !record?.contactName?.first_name){
               FFName = record.contactName.last_name.charAt(0)
               fullName = record.contactName.last_name
          }

          if(record?.contactName?.salutation){
               fullName = record.contactName.salutation+'. '+fullName
          }
          
          if(dataRecord?.name){
               fullTitleName = dataRecord.name
               sortName = dataRecord.name
               expName = dataRecord.name.split(' ');

               if (expName.length == 2 || expName.length > 2) {
                    sortName = expName[0].charAt(0) + expName[1].charAt(0);
               } else {
                    sortName = expName[0].charAt(0);
               }
          }

          let link = ''
          if(record?.relatedDisplayName){
               if(record?.relatedDisplayName?.id){
                    if(record?.relatedDisplayName?.type==='Deal'){
                    link = '/edit-deal/' + record.relatedDisplayName.id
                    }else{
                    link = '/edit-lead/' + record.relatedDisplayName.id
                    }
               }
          }
          var srcImage  =''
          if(record?.contactName?.profile_pic){
               srcImage = `${process.env.REACT_APP_API_URL}storage/${record.contactName.profile_pic}`
          }
          return (

               // <Row>
               <div className="display flex-wrap">

                    {/*--- client_information_section ---*/}
                    <Col md={12} className="client contanctlist activity mt30">
                         {/*--- client_name_and_convert_lead_section ---*/}
                         <div className="client_details display">
                              {/*--- client_image_and_deatils_section ---*/}
                              <div className="col-md-6 display">
                              <div className="client_image display">
                                   <Stack direction="row" spacing={3}>
                                        <Avatar
                                             alt={fullTitleName}
                                             src={srcImage}
                                             sx={{ width: 50, height: 50 }}
                                             onError={addDefaultSrc}
                                             className="clientimg"
                                        >
                                              {sortName}
                                        </Avatar>
                                   </Stack>

                                   <div className="client_name ml20">
                                        <div className="display">
                                             <h5>{fullTitleName}</h5>
                                        </div>
                                        <div className="display">
                                             <h6>{dataRecord.deal_id ? 'Deal':'Lead'}</h6>
                                        </div>
                                        {/* <div className="display">
                                             <span>Company :</span>
                                             <h6>{dataRecord.organization_name}</h6>
                                        </div> */}
                                   </div>
                              </div>
                              </div>
                              <div className="col-md-6 display justify-content-end">
                              {/*--- client_update_time_and_date_section ---*/}
                              <div className="client_update">
                                   <div className="client_txt display justify-content-end">
                                        <span>Last update</span>
                                   </div>
                                   <div className="client_date_and_time display justify-content-end">
                                        {/* <span>July, 03 2022 | 10:13am</span> */}
                                        <span>{moment.tz(dataRecord.updated_at, defaultTimeZone).format(preferredDateFormatData)}</span>
                                   </div>
                              </div>
                              {/*--- converted_button ---*/}
                             
                              {/*--- client_action_section ---*/}
                              <div className="client_action text-right">

                                   <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className=" dropdownborder1 dothover">
                                             <span className="dot"></span>
                                        </Dropdown.Toggle>
                                        {
                                             userPermission ?
                                             (userPermission.includes('activity_delete')) ?
                                                  <Dropdown.Menu>
                                                       <Dropdown.Item onClick={() => { deleteActivity(dataID) }}>Delete</Dropdown.Item>
                                                  </Dropdown.Menu>
                                                  :
                                                  ''
                                             :
                                                  ''
                                        }
                                        
                                   </Dropdown>
                              </div>
                              </div>
                         </div>
                    </Col>

                    {/*--- Lead_information_section ---*/}
                    <Col md={12} className="mt30">
                         <div className="display w-100">
                              <Accordion defaultActiveKey="0" className="w-100">
                                   <Accordion.Item eventKey="0">
                                        <Accordion.Header><h4>Activity Information</h4></Accordion.Header>
                                        <Accordion.Body>
                                             <div className="display align-items-start flex-wrap">
                                              <div className="col-md-6 circletrick">
                                              <div className="display align-items-start flex-wrap">
                                              <div className="col-md-12 circletrick">
                                                       <Form.Group className="mb-2 display">
                                                            <Form.Label className="text-right w-17">Subject</Form.Label>
                                                            <EasyEdit
                                                                 type={Types.TEXT}
                                                                 value={dataRecord.name}
                                                                 onSave={(value) => { handleSaveData(value, 'name') }}
                                                                 onValidate={value => {
                                                                      if (value) {
                                                                           var splString = value.split(' ');
                                                                           if (value && value.length>50) {
                                                                                setNameErrorMsg('Only 50 characters are allowed')
                                                                                return false    
                                                                           }else if(value && value.length<2){
                                                                                setNameErrorMsg('Enter at least 2 characters.');
                                                                                return false
                                                                           }else if((value && value.length==2) && (value && splString.length>=2)){
                                                                                setNameErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                return false
                                                                           }
                                                                           return true
                                                                      } else {
                                                                           setNameErrorMsg('Enter event subject.');
                                                                           return false
                                                                      }
                                                                 }}
                                                                 validationMessage={nameErrorMsg}
                                                                 onCancel={cancel}
                                                                 saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                 cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                 attributes={{ maxLength: 50 }}
                                                                 placeholder='----'
                                                                 allowEdit={isEditable}
                                                                 onFocus={() => handleClick('')}
                                                            />
                                                       </Form.Group>
                                                       <span className="error">{ErrorInput.error_list.name}</span>
                                             </div>
                                             <div className="col-md-12 circletrick">
                                                  <Form.Group className="display mb-2">
                                                  <Form.Label className="text-right w-17">All day</Form.Label>
                                                  <FormCheck type="checkbox" className="display justify-content-end align-items-center checkbox-height ml0">
                                                       <FormCheck.Input name="allday" defaultChecked={checkPer} onChange={handleEditCheckbox} value="1" className="me-2" />
                                                       <FormCheck.Label htmlFor={'checkbox' + 1} style={{ fontWeight: 'normal' }}column>
                                                       {/* <h6 className="fw600">All Day</h6> */}
                                                       </FormCheck.Label>
                                                  </FormCheck>
                                                  </Form.Group>
                                             </div>
                                               
                                             {
                                                  allEditDaySelect ? 

                                                  <div className="col-md-12 circletrick">
                                                       <div className="col-md-12 circletrick">
                                                            <Form.Group className="mb-2 display">
                                                                 <Form.Label className="text-right w-17">Start date</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           onSave={(value) => { handleSaveData(value ? moment(value).format("YYYY-MM-DD") : '', 'start_date')}}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           editComponent={
                                                                                <CustomStartDateDisplay/>
                                                                           }
                                                                           value={eventFullStartDate ? eventFullStartDate : ''} 
                                                                           displayComponent={<DisplayCustStartDate/>}
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                           placeholder='----'
                                                                      />
                                                                 {/* <div class="amount-border">
                                                                      <DatePicker
                                                                           autocomplete="off"
                                                                           minDate={moment().toDate()}
                                                                           selected={eventFullStartDate}
                                                                           onChange={(date) => {
                                                                                setEventFullStartDate(date)
                                                                                handleDateChanges(date,'start_date')
                                                                           }
                                                                           }
                                                                           timeCaption="time"
                                                                           dateFormat={calendorDateFormate}
                                                                           className="amount_txt"
                                                                           disabled={!isEditable}
                                                                      />
                                                                      <div class="input-group-append amount-after">
                                                                           <span className="calender_icon_amount"></span>
                                                                      </div>
                                                                 </div> */}
                                                            </Form.Group>
                                                            <span className="error">{ErrorInput.error_list.start_date}</span>
                                                       </div>


                                                       <div className="col-md-12 circletrick description">
                                                            <Form.Group className="mb-2 display">
                                                                 <Form.Label className="text-right w-17">End date</Form.Label>
                                                                 <EasyEdit
                                                                           type={Types.TEXT}
                                                                           onSave={(value) => { handleSaveData(value ? moment(value).format("YYYY-MM-DD") : '', 'end_date')}}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           editComponent={
                                                                                <CustomEndDateDisplay/>
                                                                           }
                                                                           value={eventFullEndDate ? eventFullEndDate : ''} 
                                                                           displayComponent={<DisplayCustEndDate/>}
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                           placeholder='----'
                                                                      />
                                                                 {/* <div class="amount-border">
                                                                      <DatePicker
                                                                           autocomplete="off"
                                                                           minDate={moment().toDate()}
                                                                           selected={eventFullEndDate}
                                                                           onChange={(date) => {
                                                                                setEventFullEndDate(date)
                                                                                handleDateChanges(date,'end_date')
                                                                           }
                                                                           }
                                                                           timeCaption="time"
                                                                           dateFormat={calendorDateFormate}
                                                                           className="amount_txt"
                                                                           disabled={!isEditable}
                                                                      />
                                                                      <div class="input-group-append amount-after">
                                                                           <span className="calender_icon_amount"></span>
                                                                      </div>
                                                                 </div> */}
                                                            </Form.Group>
                                                            <span className="error error6">{ErrorInput.error_list.end_date}</span>
                                                       </div>
                                                  </div>

                                                  :

                                                  <div className="col-md-12 circletrick">
                                                       <div className="col-md-12 circletrick">
                                                            <Form.Group className="mb-2 display">
                                                                 <Form.Label className="text-right w-17">Start date</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           onSave={(value) => { handleSaveData(value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : '', 'start_date')}}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           editComponent={
                                                                                <CustomHalfStartDateDisplay/>
                                                                           }
                                                                           value={eventStartDate ? eventStartDate : ''} 
                                                                           displayComponent={<DisplayCustHalfStartDate/>}
                                                                           //value={moment.tz(dataRecord.updated_at, defaultTimeZone).format(preferredDateFormatData)}
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                           placeholder='----'
                                                                      />
                                                                 {/* <div class="amount-border">
                                                                      <DatePicker
                                                                           autocomplete="off"
                                                                           minDate={moment().toDate()}
                                                                           selected={eventStartDate}
                                                                           onChange={(date) => {
                                                                                setEventStartDate(date)
                                                                                handleDateChanges(date,'start_date')
                                                                           }
                                                                           }
                                                                           showTimeSelect
                                                                           timeFormat="HH:mm"
                                                                           timeIntervals={5}
                                                                           timeCaption="time"
                                                                           dateFormat={calendorDateFormate + ' h:mm aa'}
                                                                           //className="timedate tp-2"
                                                                           className="amount_txt"
                                                                           disabled={!isEditable}
                                                                      />
                                                                      <div class="input-group-append amount-after">
                                                                           <span className="calender_icon_amount"></span>
                                                                      </div>
                                                                 </div> */}
                                                            </Form.Group>
                                                            <span className="error">{ErrorInput.error_list.start_date}</span>
                                                       </div>


                                                       <div className="col-md-12 circletrick">
                                                            <Form.Group className="mb-2 display">
                                                                 <Form.Label className="text-right w-17">End date</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           onSave={(value) => { handleSaveData(value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : '', 'end_date')}}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           editComponent={
                                                                                <CustomHalfEndDateDisplay/>
                                                                           }
                                                                           value={eventEndDate ? eventEndDate : ''} 
                                                                           displayComponent={<DisplayCustHalfEndDate/>}
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                           placeholder='----'
                                                                      />
                                                                 {/* <div class="amount-border">
                                                                      <DatePicker
                                                                           autocomplete="off"
                                                                           minDate={moment().toDate()}
                                                                           selected={eventEndDate}
                                                                           onChange={(date) => {
                                                                                setEventEndDate(date)
                                                                                handleDateChanges(date,'end_date')
                                                                           }
                                                                           }
                                                                           showTimeSelect
                                                                           timeFormat="HH:mm"
                                                                           timeIntervals={5}
                                                                           timeCaption="time"
                                                                           dateFormat={calendorDateFormate + ' h:mm aa'}
                                                                           //className="timedate tp-2"
                                                                           className="amount_txt"
                                                                           disabled={!isEditable}
                                                                      />
                                                                      <div class="input-group-append amount-after">
                                                                           <span className="calender_icon_amount"></span>
                                                                      </div>
                                                                 </div> */}
                                                            </Form.Group>
                                                            <span className="error error6">{ErrorInput.error_list.end_date}</span>
                                                       </div>
                                                                 
                                                  </div>

                                             }
                                              <div className="col-md-12 circletrick">
                                                  <Form.Group className="mb-2 display align-items-start">
                                                       <Form.Label className="text-right w-17 form-label">Created by</Form.Label>
                                                       <div className="fontdisabled display flex-wrap">
                                                            <p className="disabledrecord" style={{textTransform:'capitalize'}}>{fullName}</p>
                                                            <p className="fontsbreak">{moment.tz(dataRecord.created_at, defaultTimeZone).format(preferredDateFormatData)}</p>
                                                       </div>
                                                  </Form.Group>
                                             </div>
                                             </div>
                                              </div>
                                              <div className="col-md-6 circletrick">
                                              <div className="display align-items-start flex-wrap">
                                              <div className="col-md-12 circletrick">
                                                       <Form.Group className="mb-2 display">
                                                            <Form.Label className="text-right w-17">Location</Form.Label>
                                                            <EasyEdit
                                                                 type={Types.TEXT}
                                                                 value={dataRecord.location}
                                                                 onSave={(value) => { handleSaveData(value, 'location') }}
                                                                 onValidate={value => {
                                                                      if (value) {
                                                                           var splString = value.split(' ');
                                                                           if (value && value.length>50) {
                                                                                setLocationErrorMsg('Only 50 characters are allowed')
                                                                                return false    
                                                                           }else if(value && value.length<2){
                                                                                setLocationErrorMsg('Enter at least 2 characters.');
                                                                                return false
                                                                           }else if((value && value.length==2) && (value && splString.length>=2)){
                                                                                setLocationErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                return false
                                                                           }
                                                                           return true
                                                                      } else {
                                                                           return true
                                                                      }
                                                                 }}
                                                                 validationMessage={'Only 50 characters are allowed'}
                                                                 onCancel={cancel}
                                                                 saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                 cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                 attributes={{ maxLength: 50 }}
                                                                 placeholder='----'
                                                                 instructions=""
                                                                 allowEdit={isEditable}
                                                                 onFocus={() => handleClick('')}
                                                            />
                                                       </Form.Group>
                                                       <span className="error">{ErrorInput.error_list.location}</span>
                                             </div>
                                             <div className="col-md-12 circletrick description-input position-relative">
                                                       <Form.Group className="mb-2 display align-items-start">
                                                            <Form.Label className="text-right w-17 mt-1">Description</Form.Label>
                                                            <EasyEdit
                                                                 type={Types.TEXTAREA}
                                                                 value={dataRecord.description}
                                                                 onSave={(value) => { handleSaveData(value, 'description') }}
                                                                 onValidate={value => {
                                                                      if (value) {
                                                                           var splString = value.split(' ');
                                                                           if (value && value.length>150) {
                                                                                setDescriptionErrorMsg('Allow 150 characters only.')
                                                                                return false    
                                                                           }else if(value && value.length<2){
                                                                                setDescriptionErrorMsg('Enter at least 2 characters.');
                                                                                return false
                                                                           }else if((value && value.length==2) && (value && splString.length>=2)){
                                                                                setDescriptionErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                return false
                                                                           }
                                                                           return true
                                                                      } else {
                                                                           return true
                                                                      }
                                                                 }}
                                                                 validationMessage={descriptionErrorMsg}
                                                                 cssClassPrefix="custom-textarea-"
                                                                 onCancel={cancel}
                                                                 saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                 cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                 attributes={{ maxLength: 150 }}
                                                                 placeholder='----'
                                                                 instructions=""
                                                                 allowEdit={isEditable}
                                                                 onFocus={() => handleClick('')}
                                                            />
                                                       </Form.Group>
                                                       <span className="error">{ErrorInput.error_list.description}</span>
                                             </div>
                                             <div className="col-md-12 circletrick description-input position-relative">
                                                  <Form.Group className="display align-items-start">
                                                       <Form.Label className="text-right w-17 form-label">Related To</Form.Label>
                                                       <div className="fontdisabled display flex-wrap">
                                                       <span onClick={() => history.push(link)} style={{ cursor: 'pointer'}}>{record.relatedDisplayName ? record.relatedDisplayName.name ? record.relatedDisplayName.name : 'N/A' : 'N/A'}</span>
                                                        </div>
                                                  </Form.Group>
                                             </div>
                                              </div>
                                              </div>
                                             </div>
                                             {/*--- first-row ---*/}
                                        </Accordion.Body>
                                   </Accordion.Item>
                              </Accordion>
                         </div>
                    </Col>

                    


                    
               {/* </Row> */}
               </div>

          );

     }


};

export default EditActivity;
