import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storageSession from "redux-persist/es/storage/session";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    permission: null,
    workspace: null,
    checkdomain: null,
    leadDealPopUp: false,
    leadImportPopUp: false,
    loginAccessMsg: null,
    leadAddPopUp: false,
    dealAddPopUp: false,
    dealExistAccountAddPopUp: false,
    contactAddPopUp: false,
    accountAddPopUp: false,
    leadStageAddPopUp: false,
    dealStageAddPopUp: false,
    sourceAddPopUp: false,
    productAddPopUp: false,
  },

  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },

    logout: () => {
      let hostname = "";
      const onlyHost = window.location.hostname.split(".");

      if (onlyHost.length === 3) {
        hostname = `${onlyHost[1]}.${onlyHost[2]}`;
      } else if (onlyHost.length === 2) {
        hostname = `${onlyHost[0]}.${onlyHost[1]}`;
      }

      cookies.remove("auth_token", { domain: hostname });
      cookies.remove("auth_name", { domain: hostname });
      cookies.remove("auth_tenant", { domain: hostname });
      cookies.remove("tenant_workspace", { domain: hostname });

      // state.user = null;
      // state.permission = null;
      // state.workspace = null;
      //state.checkdomain=null;
      storage.removeItem("persist:root");
      storageSession.removeItem("persist:root");

      // storageSession.removeItem('user')
      // storageSession.removeItem('permission')
      // storageSession.removeItem('workspace')
      // storageSession.removeItem('checkdomain')

      //swal("success",'You have successfully log out',"success");
      //toast.success('You have successfully log out');

      //window.location.reload(true);
      let host = window.location.host;
      let protocol = window.location.protocol;
      let parts = host.split(".");
      let subdomain = "app";
      if (parts.length === 3) {
        subdomain = parts.splice(0, 1);
      } else if (parts.length === 2) {
        subdomain = parts.splice(0, 0);
      }
      window.location =
        protocol + "//" + subdomain + "." + parts.join(".") + "/sign-in";
    },

    LoginPermission: (state, action) => {
      state.permission = action.payload;
    },

    Workspace: (state, action) => {
      state.workspace = action.payload;
    },

    Checkdomain: (state, action) => {
      state.checkdomain = action.payload;
    },

    LeadDealPopUp: (state, action) => {
      state.leadDealPopUp = action.payload;
    },

    LeadImportPopUp: (state, action) => {
      state.leadImportPopUp = action.payload;
    },

    LoginAccessMsg: (state, action) => {
      state.loginAccessMsg = action.payload;
    },

    LeadAddPopUp: (state, action) => {
      state.leadAddPopUp = action.payload;
    },

    DealAddPopUp: (state, action) => {
      state.dealAddPopUp = action.payload;
    },

    SourceAddPopUp: (state, action) => {
      state.sourceAddPopUp = action.payload;
    },

    DealExistAccountAddPopUp: (state, action) => {
      state.dealExistAccountAddPopUp = action.payload;
    },

    ContactAddPopUp: (state, action) => {
      state.contactAddPopUp = action.payload;
    },

    AccountAddPopUp: (state, action) => {
      state.accountAddPopUp = action.payload;
    },

    LeadStageAddPopUp: (state, action) => {
      state.leadStageAddPopUp = action.payload;
    },

    DealStageAddPopUp: (state, action) => {
      state.dealStageAddPopUp = action.payload;
    },

    DefaultTimeZone: (state, action) => {
      state.defaultTimeZone = "America/New_York";
    },

    ProductAddPopUp: (state, action) => {
      state.productAddPopUp = action.payload;
    },

    NotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },

    NotificationList: (state, action) => {
      state.notificationList = action.payload;
    },
  },
});

export const {
  login,
  logout,
  LoginPermission,
  Workspace,
  Checkdomain,
  LeadDealPopUp,
  LeadImportPopUp,
  LoginAccessMsg,
  LeadAddPopUp,
  DealAddPopUp,
  SourceAddPopUp,
  DealExistAccountAddPopUp,
  ContactAddPopUp,
  AccountAddPopUp,
  DefaultTimeZone,
  LeadStageAddPopUp,
  DealStageAddPopUp,
  ProductAddPopUp,
  NotificationCount,
  NotificationList,
} = userSlice.actions;

export const selectUser = (state) => state.user;

export const selectDefaultData = (state) => ({
  defaultTimeZone: "America/New_York",
  defaultCurrency: "19",
  defaultDateFormat: "ymdhisa",
});

// var displayHost = ''
// var onlyHost = window.location.hostname;
// onlyHost = onlyHost.split('.')
// if(onlyHost && onlyHost.length==3){
//   onlyHost = onlyHost.slice(1) //remove first element of array
//   displayHost = onlyHost[0];
// }else if(onlyHost && onlyHost.length==2){
//   displayHost = onlyHost[0];
// }

let displayHostname = "";
let displayHost = "";
const onlyHostData = window.location.hostname.split(".");

if (onlyHostData.length >= 2) {
  const hostParts = onlyHostData.slice(-2);
  displayHostname = hostParts.join(".");
  displayHost = onlyHostData.length === 3 ? hostParts[0] : onlyHostData[0];
}

export const DisplayHostName = (state) => ({
  hostname: displayHostname,
  displayHost: displayHost,
});

export default userSlice.reducer;
