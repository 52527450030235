/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect,memo } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import EasyEdit, { Types } from "react-easy-edit";
import 'react-edit-text/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";



const EditCustomFields = ({onDynamicFieldChange,dynamicFields,handleClick,cancel,displayAt=null,displayFieldType=null,errorInput}) => {
  
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [dataLists, setDataLists] = useState();
  const [dynamicFieldData, setDynamicFieldData] = useState(dynamicFields);
  const [errorMessages, setErrorMessages] = useState({});
  const [isEditable, setIsEditable] = useState(true)
  const [displayDateFormate, setDisplayDateFormate] = useState('yyyy/MM/dd');
  const [displayDate, setDisplayDate] = useState();
  
  useEffect(() => {
      async function fetchData() {
        await getData();
      }
      fetchData();
  }, []);

  var calendorFormate = '';

  async function getData() {
    await axios.get("api/get-custom-field?model=lead&displayAt="+displayAt+"&displayFieldType="+displayFieldType).then((response) => {
        
        if (response.status === 200) {
          setDataLists(response.data.data.records) 
          setLoading(false);

          calendorFormate = response.data.data.preferredDateFormat.split(' ');
          if (calendorFormate) {
            if (calendorFormate[0] == 'YYYY-MMM-DD' || calendorFormate[0] == 'YYYY-MM-DD') {
                 setDisplayDateFormate('yyyy-MMM-dd')
                 if(calendorFormate[0] == 'YYYY-MM-DD'){
                      setDisplayDateFormate('yyyy-MM-dd')
                 }
            } else if (calendorFormate[0] == 'DD-MMM-YYYY' || calendorFormate[0] == 'DD-MM-YYYY') {
                 setDisplayDateFormate('dd-MMM-yyyy')
                 if(calendorFormate[0] == 'DD-MM-YYYY'){
                      setDisplayDateFormate('dd-MM-yyyy')
                 }
            } else if (calendorFormate[0] == 'YYYY-DD-MMM' || calendorFormate[0] == 'YYYY-DD-MM') {
                 setDisplayDateFormate('yyyy-dd-MMM')
                 if(calendorFormate[0] == 'YYYY-DD-MM'){
                      setDisplayDateFormate('yyyy-dd-MM')
                 }
                 
            } else if (calendorFormate[0] == 'MMM-DD-YYYY' || calendorFormate[0] == 'MM-DD-YYYY') {
                 setDisplayDateFormate('MMM-dd-yyyy')
                 if(calendorFormate[0] == 'MM-DD-YYYY'){
                      setDisplayDateFormate('MM-dd-yyyy')
                 }
            }
          }
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const handleSaveData = (value, fieldName) => {
    setDynamicFieldData({
      ...dynamicFieldData,
      [fieldName]: value,
    });
    
    onDynamicFieldChange({
      //...dynamicFieldData,
      [fieldName]: value
    });
  };

  const handleImageSaveData = (e,fieldName) => {
    e.persist();
    onDynamicFieldChange({
      [fieldName]: e.target.files
    },'file'); 
  }
  
  const CustomSelectDropDown = (props) => {
    const handleSelectSave = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    handleClicks()
    const valuesArray = props.data.optionLists ? JSON.parse(props.data.optionLists):[];

    const selectOptionsLists = valuesArray.map(item => ({
      value: item.id,
      label: item.name
    }));
  
    // Find the selected option object from the list
    const selectedOption = selectOptionsLists.find(option => option.value === props.data.selectedValue) || null;

    return (
         <Select
              defaultValue={selectedOption}
              name={props.data.fieldName}
              options={selectOptionsLists}
              classNamePrefix="select"
              onChange={handleSelectSave}
              theme={(theme) => ({
                   ...theme,
                   borderRadius: 0,
                   colors: {
                        ...theme.colors,
                        primary25: 'var(--primary-color-light)',
                        primary: 'var(--primary-color)',
                   },
              })}
         />
    );
  };

  const CustomSelectDisplay = props => {
      return (
          <div style={{textTransform:'capitalize'}}>
                {props?.value || 'Please select'}
          </div>
      );
  }

  const CustomDateSelect = props => {
      handleClicks()
      return (
        <DatePicker
          autocomplete="off"
          //minDate={moment().toDate()}
          selected={props.value?new Date(props.value):''}
          onChange={
            (date) => { props.setParentValue(moment(date).format("YYYY-MM-DD")); setDisplayDate(new Date(date)) }
          }
          dateFormat={displayDateFormate}
          className="amount_txt"
        />
      );
  };


  const CustomDateDisplay = props => {
    return (
          <DatePicker
                autocomplete="off"
                //minDate={moment().toDate()}
                placeholder='----'
                selected={props?.value ? new Date(props?.value) :''}
                dateFormat={displayDateFormate}
                className="amount_txt"
                disabled={true}
          />
      );
  };

  
  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  const handleCancel = () => {
    cancel();
    setIsEditable(true);
  };

  const handleClicks = () => {
    handleClick()
    setIsEditable(false);
  };

  return (
    <div className="col-md-12 circletrick">
      {loading ? (
        <Spinner animation="border" />
      ) : (
        dataLists?.length ?
        dataLists?.length && dataLists.map((field) => {
          const fieldName = field.field_name;
          return (
          <Form.Group className="mb-2 display" key={field.id}>
            <Form.Label className="text-right w-17">{field.display_label}</Form.Label>
            {
              field.display_type === 'file' ?
                <div>
                  <Stack direction="row" spacing={3}>
                      <Avatar
                        alt={field.field_name ? field.field_name : ''}
                        src={`${process.env.REACT_APP_API_URL}storage/${dynamicFieldData[field.field_name]}`}
                        sx={{ width: 76, height: 76 }}
                        onError={addDefaultSrc}
                        className="listimg"
                      >
                      {''}
                      </Avatar>
                  </Stack>
                  <Form.Control style={{'display':'block'}} type="file" name={field.field_name+'[]'} multiple={field.display_is_multiple==='Yes'?true:false} accept="image/*" onChange={(e) => {
                    handleImageSaveData(e,field.field_name)
                  }} />
                </div>
                
              :
              <EasyEdit
                className={'firstcoloum'}
                type={field.display_type==='textarea' ? Types.TEXTAREA : Types.TEXT} // Adjust the type based on your field type
                value={dynamicFieldData[field.field_name] || ''}
                onSave={(value) => handleSaveData(value, field.field_name)}
                onValidate={(value) => {
                  if(field?.display_is_required==='Yes'){
                    if (value) {
                      if (field.display_max_input && value.length > field.display_max_input) {
                        setErrorMessages({
                          ...errorMessages,
                          [field.field_name]: 'Allow '+field.display_max_input+' characters only',
                        });
                        return false;
                      } else if (field.display_min_input && value.length < field.display_min_input) {
                        setErrorMessages({
                          ...errorMessages,
                          [field.field_name]: 'Enter at least '+field.display_min_input+' characters',
                        });
                        return false;
                      }
                      return true;
                    } else {
                      setErrorMessages({
                        ...errorMessages,
                        [field.field_name]: 'This field is required',
                      });
                      return false;
                    }
                  }else{
                    if (value) {
                      if (field.display_max_input && value.length > field.display_max_input) {
                        setErrorMessages({
                          ...errorMessages,
                          [field.field_name]: 'Allow '+field.display_max_input+' characters only',
                        });
                        return false;
                      } else if (field.display_min_input && value.length < field.display_min_input) {
                        setErrorMessages({
                          ...errorMessages,
                          [field.field_name]: 'Enter at least '+field.display_min_input+' characters',
                        });
                        return false;
                      }
                      return true;
                    } else {
                      return true;
                    }
                  }
                }}
                validationMessage={errorMessages[field.field_name]}
                onCancel={handleCancel}
                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                attributes={{ maxLength: field.display_max_input ? field.display_max_input:50 }}
                placeholder= {field.placeholder_text ? field.placeholder_text:"----"}
                allowEdit={isEditable}
                onFocus={() => {handleClicks(''); setIsEditable(false)}}
                editComponent={
                    field.display_type === 'select' ? 
                      <CustomSelectDropDown data={{ 'selectedValue': dynamicFieldData[field.field_name], 'optionLists': field.json_data_value, 'fieldName':field.field_name}} />
                    :
                    field.display_type === 'date' ? 
                      <CustomDateSelect/>
                    :
                    ''
                }
                displayComponent={
                  field.display_type === 'select' ? 
                    <CustomSelectDisplay/>
                  :
                  field.display_type === 'date' ? 
                    <CustomDateDisplay/>
                  :
                    ''
                }
              />
            }
            <span className="error">{errorInput.error_list[fieldName]}</span>
          </Form.Group>
          )})
        :
        ''  
      )}
    </div>
  );
};

export default memo(EditCustomFields);