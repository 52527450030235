
import React, { useEffect, useState } from "react";
import { Button } from '@themesberg/react-bootstrap';
import DashboardFunnel from "../../components/DashboardFunnel"
import DashboardStacked from "../../components/DashboardStacked"
import DashboardPieChart from "../../components/DashboardPieChart"
import LeadSourceChart from "../../components/LeadSourceChart"
import LeadStoreChart2 from "../../components/LeadStoreChart2"
import moment from "moment-timezone";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';

function ReportList(props) {
  const loginUser = useSelector(selectUser);
  const [loading, setLoading] = useState(true);

  const [funnelChartData, setFunnelChartData] = useState('');
  const [stackChartData, setStackChartData] = useState('');
  const [pieChartData, setPieChartData] = useState('');
  const [sourchChartData, setSourchChartData] = useState('');
  const [leadByLocationData, setLeadByLocationData] = useState('');
  const [selectedUserId, setSelectedUserId] = useState()
  const [userList, setUserList] = useState([])
  const [salesChartDatas, setSalesChartDatas] = useState('');
  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);

  const user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [searchUser, setSearchUser] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [resetFilter, setResetFilter] = useState(true);
  const [selectMonth, setSelectMonth] = useState();


  useEffect(() => {
    getData()
  }, [resetFilter,selectMonth]);

  const params = {
    user: searchUser ? searchUser : '',
    from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
    to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : '',
    month_range: selectMonth ? selectMonth : '',

  }

  const searchFilter = (e) => {
    setResetFilter(resetFilter ? false : true)
  }

  const searchMonthFilter = (e) => {
    setSelectMonth(e)
  }

  const resetFilterButton = () => {
    setResetFilter(resetFilter ? false : true)
    setSearchUser('')
    setFromDate('')
    setToDate('')
    setSelectMonth('')
  }
  
  const getData = async () => {
    await axios.get(`api/dashboard-chart`, { params }).then(response => {
      if (response.status === 200) {
        setFunnelChartData(response.data.data.funnelChartData)
        setStackChartData(response.data.data.stackedChartData)
        setPieChartData(response.data.data.pieChartData)
        setSourchChartData(response.data.data.leadSourceChartData)
        setLeadByLocationData(response.data.data.leadByLocationData)
        setSalesChartDatas(response.data.data.salesQuotaChartData)
        setUserList(response.data.data.users)
        setPreferredCurrencyData(response.data.data.preferredCurrencyData)
        setLoading(false)
      }
    }).then(json => {
    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else if (error.response.status === 500) {
        } else {
          toast.error(error.response.data.message);
        }
      }
    });
  }

  let userLists = [{ label: 'Select', value: '' }];
  for (var i = 0, l = userList.length; i < l; i++) {
    userLists.push({ label: userList[i].name, value: userList[i].id });
  }

  let selectedUser = [];
  for (var i = 0, l = userList.length; i < l; i++) {
    if (selectedUserId == userList[i].id) {
      selectedUser.push({ label: userList[i].name, value: userList[i].id });
    }
  }

  return (
    <>
      {/*--- dashboard_four_rectangle ---*/}
      <div className="display flex-wrap w-100 history mt30">
        <div className="innerdealtable w-100">
          <div className="display">
            <div className="col-md-8">
              <h4>Filters</h4>
            </div>
          </div>
        </div>
        <div className="contanctlistnig pb-4">
          <div className="display align-items-start flex-wrap ">
            <div className="col-xl-3 col-md-4 circletrick">
              <div className="display align-items-start flex-wrap">
                <div className="col-md-11 circletrick leadheight users">
                  <div className="mb-2 display"><label className="text-right w-17 form-label">Users</label>
                    <Select
                      value={userLists.filter(({ value }) => value === searchUser)}
                      name="user_id"
                      options={userLists}
                      className="basic-multi-select leadselect"
                      classNamePrefix="select"
                      onChange={(data) => setSearchUser(data.value)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: 'var(--primary-color-light)',
                          primary: 'var(--primary-color)',
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-md-4 circletrick">
              <div className="display align-items-start flex-wrap">
                <div className="col-md-11 circletrick leadheight">
                  <div className="mb-2 display"><label className="text-right w-17 form-label">From Date</label>
                    <DatePicker
                      autocomplete="off"
                      selected={fromDate}
                      onChange={(date) => setFromDate(date)}
                      dateFormat={'yyyy/MM/dd'}
                      className="form-control"
                      placeholderText="Select from"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 circletrick">
              <div className="display align-items-start flex-wrap">
                <div className="col-md-12 circletrick leadheight">
                  <div className="mb-2 display"><label className="text-right w-17 form-label">To Date</label>
                    <DatePicker
                      autocomplete="off"
                      selected={toDate}
                      onChange={(date) => setToDate(date)}
                      dateFormat={'yyyy/MM/dd'}
                      className="form-control"
                      placeholderText="Select to"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="display align-items-start flex-wrap flex-wrap w-100 twobuttons">
            <div className="col-xxl-7 col-lg-6"></div>
            <div className="col-xxl-5 col-lg-6">
              <div className="display justify-content-end pt16">
                <div className="col-xxl-8 col-lg-7"></div>
                <div className="col-xxl-3 col-lg-3 me3">
                  <div className="display justify-content-end">
                    <Button type="button" variant="success" onClick={() => searchFilter()} className="createbtn btnbluecolor d-flex align-items-center justify-content-center">Search</Button>
                  </div>
                </div>
                <div className="col-xxl col-lg">
                  <div className="display justify-content-end">
                    <Button type="button" variant="success" onClick={() => resetFilterButton()} className="createbtn btnbluecolor d-flex align-items-center justify-content-center">Reset</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*--- table_section_start ---*/}
      <div className="display">
        <div className="col-md-6">
          <div className="display chats">
            <div className="col-md-12">
              <DashboardFunnel funnelChartData={funnelChartData} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="display">
            <div className="col-md-12">
              <DashboardStacked stackChartData={stackChartData} />
            </div>
          </div>
        </div>
      </div>

      <div className="display">
        <div className="col-md-6">
          <div className="display chats">
            <div className="col-md-12">
              <DashboardPieChart pieChartData={pieChartData} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="display">
            <div className="col-md-12">
              <LeadSourceChart sourchChartData={sourchChartData} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="display">
        <div className="col-md-12">
          <div className="display chats">
            <div className="col-md-12">
              <SalesQuotaChart salesChartData={salesChartDatas} searchMonthFilter={searchMonthFilter} selectMonth={selectMonth} preferredCurrencyData={preferredCurrencyData} />
            </div>
          </div>
        </div>
      </div> */}

      {
        user && user.lead_location_traking=='yes' ?
          <div className="display">
            <div className="col-md-12">
              <div className="display chats">
                <div className="col-md-12">
                  {
                    leadByLocationData.length ?
                      <LeadStoreChart2 leadByLocationData={leadByLocationData} />
                    :
                      ''
                  }
                </div>
              </div>
            </div>
          </div>
        :
          ''
      }

    </>
  );


};
export default ReportList;
