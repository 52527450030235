import React, { useState, useCallback, useRef, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { useHistory, } from 'react-router-dom';
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import Preloader from "../components/PreloaderCenter";
import moment from "moment-timezone";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { toast } from 'react-toastify';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import "../assets/css/styles/app.css";
import { displayDate } from '../utils/utils';

const classes = {
    board: {
        display: "flex",
        margin: "0 auto",
        // width: "90vw",
        maxWidth: "90vw",
        fontFamily: '"proxima-nova", sans-serif'
    },
    column: {
        minWidth: 200,
        width: "19vw",
        height: "80vh",
        margin: "30px 35px 0 auto",
        padding: "0 20px",
        background: "rgba(219, 247, 237, 0.7)",
        borderRadius: "15px",
        overflowY: "auto"
    },
    columnHead: {
        textAlign: "left",
        padding: "0px 0px 0px",
        fontSize: "1.375rem",
        fontWeight: "600",
        color: "#222222",
        backgroundColor: "transparent",
        margin: "0px",
        lineHeight: "normal"
    },
    item: {
        padding: "15px",
        margin: "10px 0px 20px",
        fontSize: "0.8em",
        cursor: "pointer",
        backgroundColor: "white",
        borderRadius: "15px"
    }
};

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var searchData = '';
var searchInputDataName = '';
var searchInputDataEmail = '';
var searchInputDataFromDate = '';
var searchInputDataToDate = '';
var searchInputDataTitle = '';
var searchInputDataPhone = '';
var searchInputDataOrganizationName = '';

const Kanban = (props) => {
    const channels = [];
    const tasksList = [];

    const history = useHistory();
    const [statusArray, setStatusArray] = useState('');
    const [sortColumn, setSortColumn] = useState()
    const [lSourceArray, setLSourceArray] = useState('');
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(true);
    const [listLead, setListLead] = useState([]);
    const [stageList, setStageList] = useState([]);
    const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
    const [tasks, setTaskStatus] = useState([]);
    const [isUpdate, setIsUpdate] = useState(true);
    const [search, setSearch] = useState('');
    const [searchString, setSearchString] = useState();

    const loginUser = useSelector(selectUser);

    const user = loginUser ? loginUser : [];

    var defaultData = useSelector(selectDefaultData);
    var defaultTimeZone = defaultData.defaultTimeZone
    if (user) {
        if (user.preferred_timezone) {
            defaultTimeZone = user.preferred_timezone;
        }
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value)
        var searchVal = e.target.value;

        setTimeout(async () => {
            await setSearch(searchVal)
        }, 1000);
    }

    const handleSearchInput = (e) => {
        setSearchString(e.target.value);
    }

    const manageSearch = (e) => {
        e.preventDefault();
        setSearch(searchString)
    }

    const handleView = () => {
        props.handleView()
    }

    var calendorFormate = '';
    useEffect(() => {
        async function fetchData() {
            await getLeadData();
        }
        fetchData();
    }, [isUpdate, search]);


    const getLeadData = async (currentPage) => {
        const params = {
            search: search,
            search_name: searchInputDataName,
            search_email: searchInputDataEmail,
            search_from_date: searchInputDataFromDate,
            search_to_date: searchInputDataToDate,
            search_title: searchInputDataTitle,
            search_status: statusArray,
            search_lead_source: lSourceArray,
            search_phone: searchInputDataPhone,
            search_organization_name: searchInputDataOrganizationName,
            sort_field: sortColumn,
            search_canban_list: 'yes',
            sort_order: sortOrder,
            per_page: perPage,
            page: currentPage,

        }

        await axios.get(`api/lead?page=${currentPage}`, { params }).then(response => {

            if (response.status === 200) {
                setListLead(response.data.data.records);
                setStageList(response.data.data.leadStage);

                const halfDate = displayDate(response?.data?.data?.preferredDateFormat, 'half')
                setCalendorFullDateFormate(halfDate);

                setLoading(false);
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                setLoading(false);
                if (error.response.status === 403) {
                    toast.warning(error.response.data.message);
                    history.push('/leads');
                } else {
                    toast.error(error.response.data.message);
                    history.push('/leads');
                }
            }
        });
    }




    //set Kanban header name (Column name)
    stageList.length && stageList.map((t) => {
        channels.push({ sname: t.name, sid: t.id, scount: t.leadCount })
    })

    //set Kanbar Bord name (Card name)
    listLead.length && listLead.map((l) => {
        let FFName = ''
        if (l.first_name && l.last_name) {
            FFName = l.first_name.charAt(0) + l.last_name.charAt(0)
        } else if (l.first_name) {
            FFName = l.first_name.charAt(0)
        } else if (l.last_name) {
            FFName = l.last_name.charAt(0)
        }

        let displayProductName = ''
        if (l.selected_product) {
            if (l.selected_product.length > 3) {
                var displayStr = '';
                for (var i = 0; i < 3; i++) {
                    if (l.selected_product[i].name.length > 25) {
                        displayStr = l.selected_product[i].name.substring(0, 22) + '...'
                    } else {
                        displayStr = l.selected_product[i].name
                    }
                    displayProductName += displayStr + ', ';
                }
            } else {
                var displayStr = '';
                for (var i = 0, le = l.selected_product.length; i < le; i++) {
                    if (l.selected_product[i].name.length > 25) {
                        displayStr = l.selected_product[i].name.substring(0, 22) + '...'
                    } else {
                        displayStr = l.selected_product[i].name
                    }

                    displayProductName += displayStr + ', ';
                }
            }
        }

        tasksList.push({ _id: l.id, title: l.title, status: l.stage_name, sortName: FFName, profile_pic: l.profile_pic, first_name: l.first_name, last_name: l.last_name, selectedProduct: displayProductName, organization_name: l.organization_name, created_at: l.created_at, organization_phone1: l.organization_phone1, phone1: l.phone1 })
        //setTasksList(tasksList => [...tasksList,{_id: l.id, title: l.title, status: l.stage_name}] );
    })


    //channels
    const changeTaskStatus = useCallback(
        (id, status, sid) => {
            let task = tasksList.find(task => task._id === id);
            const taskIndex = tasksList.indexOf(task);
            task = { ...task, status };
            let newTasks = update(tasksList, {
                [taskIndex]: { $set: task }
            });

            //==============Start:- Call API for update stage status for lead============//
            const data = {
                id: id,
                stage: sid
            }

            axios.put('api/lead-stage/mode', data).then(response => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    isUpdate ? setIsUpdate(false) : setIsUpdate(true);
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                        toast.error(error.response.data.message);
                    }
                }
            });
            //==============End:- Call API for update stage status for lead============//
            setTaskStatus(newTasks);
            tasksList.push(newTasks)


        },
        [isUpdate]
    );

    const addDefaultSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
    }

    // const handleView = async () => {
    //   //listView ? setListView(false) : setListView(true);
    //   //window.location.reload(false);
    //   history.push('/lead')
    // }

    if (loading) {
        return <Preloader show={true} />;
    } else {
        return (
            <>
                <div className="searchbar mt30">
                    <div className="col-md-4">
                        {/* <form>
                <input type="text" className="form-control search" placeholder="Search this list...." value={searchText} onChange={handleSearch} ></input><span className="listicon"></span>
              </form> */}
                        <form onSubmit={manageSearch}>
                            <input type="text" name="searchData" className="form-control search" value={searchString} placeholder="Search this list...." onChange={handleSearchInput} ></input><span className="listicon" onClick={manageSearch}></span>
                        </form>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4 display justify-content-end">

                        <div className="icon-section">
                            <button type="button" className="btn dropdownborder equalhover" onClick={() => handleView()}><span className="equal"></span></button>
                        </div>
                    </div>
                </div>

                <main style={{ overflowX: 'scroll' }}>
                    <header>  </header>
                    <DndProvider backend={HTML5Backend}>
                        <section style={classes.board} className="mt30">
                            {channels.length && channels.map(channel => (
                                <KanbanColumn
                                    key={channel.sname}
                                    status={channel.sname}
                                    sid={channel.sid}
                                    changeTaskStatus={changeTaskStatus}
                                >
                                    <div className="kanbancoloum">
                                        {/* <div style={classes.columnHead}>{channel.sname}</div> */}
                                        <div className="pricehead leadheader">
                                            <div className="priceheadleft">
                                                {/* <h5>$15,000.00</h5> */}
                                                <div className="kanbantitle">{channel.sname}</div>
                                            </div>
                                            <div className="priceheadright">
                                                <span className="badge drakblue mr5"><h6>{channel.scount}</h6></span>
                                                <h6>Lead</h6>
                                            </div>
                                        </div>
                                        <div>
                                            {tasksList.length && tasksList
                                                .filter(item => item.status === channel.sname)
                                                .map(item => (
                                                    <KanbanItem key={item._id} id={item._id}>
                                                        <div style={classes.item}>
                                                            <div className="divtask">
                                                                <div className="imgandtxt">
                                                                    <Stack direction="row" spacing={3} alignItems="center">
                                                                        <Avatar
                                                                            alt={item.first_name + ' ' + item.last_name}
                                                                            src={`${process.env.REACT_APP_API_URL}storage/${item.profile_pic}`}
                                                                            sx={{ width: 40, height: 40 }}
                                                                            onError={addDefaultSrc}
                                                                            className="listimg"
                                                                        >
                                                                            {item.sortName}
                                                                        </Avatar>
                                                                        <h3 className="short" onClick={() => history.push('/edit-lead/' + item._id)}>{item.first_name + ' ' + item.last_name}</h3>
                                                                    </Stack>
                                                                </div>
                                                                <div className="tasktitle">
                                                                    <h6 onClick={() => history.push('/edit-lead/' + item._id)}>{item.title}</h6>
                                                                </div>
                                                                <div className="taskname">
                                                                    <h6>{item.organization_name ? item.organization_name : 'N/A'}</h6>
                                                                </div>
                                                                <div className="taskname">
                                                                    <h6>{item.phone1 ? item.phone1 : item.organization_phone1 ? item.organization_phone1 : 'N/A'}</h6>
                                                                </div>
                                                                {/* {item.selectedProduct ?
                              <div className="taskproduct">
                              <h6>Product Intrusted In:</h6>
                              <span className="ellipsis">{item.selectedProduct ? item.selectedProduct : 'N/A'}</span>
                              </div>
                              :
                              ''
                            } */}
                                                                <div className="taskdate">
                                                                    <h6>{moment.tz(item.created_at, defaultTimeZone).format(calendorFullDateFormate)}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </KanbanItem>
                                                ))}
                                        </div>
                                    </div>
                                </KanbanColumn>
                            ))}
                        </section>
                    </DndProvider>
                </main>
            </>
        );
    }

};


export default Kanban;

const KanbanColumn = ({ status, changeTaskStatus, children, sid }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: "card",
        drop(item) {
            changeTaskStatus(item.id, status, sid);
        }
    });
    drop(ref);
    return <div ref={ref}> {children}</div>;
};

const KanbanItem = ({ id, children }) => {
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => ({ id }),
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })

    });
    const opacity = isDragging ? 0 : 1;
    drag(ref);
    return (
        <div ref={ref} style={{ opacity }}>
            {children}
        </div>
    );
};
