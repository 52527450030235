/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Dropdown, Card } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/PreloaderCenter";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Checkbox from "react-custom-checkbox";
import { Badge } from "react-bootstrap";
import ExportData from "../../components/ExportData";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;

var searchData = "";
var searchInputDataName = "";
var searchInputDataEmail = "";
var searchInputDataFromDate = "";
var searchInputDataToDate = "";
var searchInputDataTitle = "";
var searchInputDataStatus = "";
var searchInputDataPhone = "";
var searchInputDataOrganizationName = "";

function ActivityList(props) {
  const searchParams = new URLSearchParams(document.location.search);

  const location = props.history.location;
  const loginUser = useSelector(selectUser);
  const userPermissions = useSelector((state) => state.permission);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
  const [mainChecked, setMainChecked] = useState(false);

  const user = loginUser?loginUser:[];
  const userPermission = userPermissions?userPermissions:[];

  
  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [currentPage, setCurrentPage] = useState(
    searchParams.size !== 0
      ? searchParams.get("page")
        ? searchParams.get("page")
        : 1
      : 1
  );

  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  
  const [priorityArray, setPriorityArray] = useState(
    searchParams.get("search_priority")
      ? searchParams.get("search_priority")
      : ""
  );
  const [priorityStatusArray, setPriorityStatusArray] = useState(
    searchParams.get("search_activity_status")
      ? searchParams.get("search_activity_status")
      : ""
  );

  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
  const [searchString, setSearchString] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );

  //==============START: Handle new search filter option==================//
 
  const handleSearchInput = (e) => {
    setSearchString(e.target.value);
  };

  const manageSearch = async (e) => {
    e.preventDefault();
    setSearch(searchString);
    setCurrentPage(1);
    if (searchString) {
      await props.history.push(
        "/activities?page=" +
          1 +
          "&search=" +
          searchString +
          "&sort_field=" +
          sortColumn +
          "&sort_order=" +
          sortOrder +
          "&per_page=" +
          perPage
      );
    } else {
      props.history.push("/activities");
    }
  };




  //==============END: Handle new search filter option==================//

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const sortColumn = ''
  
  const handlePerPage = (Page) => {
    setLoading(true);
    setCurrentPage(1);
    perPage = Page;
    props.history.push(
      "/activities?page=" +
        1 +
        "&search=" +
        searchString +
        "&sort_field=" +
        sortColumn +
        "&sort_order=" +
        sortOrder +
        "&per_page=" +
        perPage
    );
  };
  //=========END:CODE FOR FOOTER PAGINATION===========//

  var calendorFormate = "";

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      getData(
        searchParams.size !== 0
          ? searchParams.get("page")
            ? searchParams.get("page")
            : 1
          : 1
      );
    }
    fetchData();
  }, [
    sortColumn,
    sortOrder,
    search,
    priorityArray,
    location,
    perPage,
    priorityStatusArray,
    searchInputDataName,
    searchInputDataEmail,
    searchInputDataFromDate,
    searchInputDataToDate,
    searchInputDataTitle,
    searchInputDataStatus,
    searchInputDataPhone,
    searchInputDataOrganizationName,
  ]);

  const getData = async (currentPage) => {
    setSearchString(
      searchParams.get("search") ? searchParams.get("search") : ""
    );
    const params = {
      search: searchParams.size !== 0 ? search : "",
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_status: searchParams.size !== 0 ? '' : "",
      search_priority: searchParams.size !== 0 ? priorityArray : "",
      search_task_status: searchParams.size !== 0 ? priorityStatusArray : "",
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    };

    await axios
      .get(`api/activity?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setListData(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });

          setPreferredCurrencyData(response.data.data.preferredCurrency);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);

          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
              setCalendorFullDateFormate("yyyy-MMM-DD");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
              setCalendorFullDateFormate("DD-MMM-yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
              setCalendorFullDateFormate("yyyy/DD/MMM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
              setCalendorFullDateFormate("MMM-DD-yyyy");
            }
            setCalendorFullDateFormate(calendorFormate[0]);
          }
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          }
        }
      });
  };

  async function deleteData(id) {
    const data = {
      id: id,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/activity/destroy", { data });

          if (response.status === 200) {
            toast.success(response.data.message);
            getData(currentPage);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  async function bulkDeleteData(ids) {
    const data = {
      id: ids,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/activity-bulk-destroy", {
            data,
          });

          if (response.status === 200) {
            toast.success(response.data.message);
            getData(currentPage);
            handleCheckAll(false);
            setIsCheckBoxCheck("");
            setIsCheck([]);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
          getData(currentPage);
          handleCheckAll(false);
          setIsCheckBoxCheck("");
          setIsCheck([]);
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  //Export data file for logic
  const expParams = {
    search: searchData,
    search_name: searchInputDataName,
    search_email: searchInputDataEmail,
    search_from_date: searchInputDataFromDate,
    search_to_date: searchInputDataToDate,
    search_title: searchInputDataTitle,
    search_status: searchInputDataStatus,
    search_phone: searchInputDataPhone,
    search_organization_name: searchInputDataOrganizationName,
    sort_field: sortColumn,
    sort_order: sortOrder,
    per_page: perPage,
    page: currentPage,
    selectedIds: isCheck?isCheck:[],
  };

 
  const handleBulkDelete = () => {
    if (isCheck && isCheck.length > 0) {
      bulkDeleteData(isCheck);
    } else {
      toast.warning("Please select at least one record for Delete.");
    }
  };

  //============Start:- Handle check all ================//
  const handleCheckbox = (value, id) => {
    if (value) {
      setIsCheck((isCheck) => [...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((x) => x !== id));
    }
  };

  const handleCheckAll = (value) => {
    if (value) {
      setMainChecked(true);
    } else {
      setMainChecked(false);
    }

    setIsCheckBoxCheck(value);
    setIsCheck([]);

    if (value) {
      setIsCheckBoxCheck(value);
      setIsCheck(listData.map((li) => li.id));
    }
  };
  //============End:- Handle check all ================//

  var displayUserData = "";

  if (loading) {
    return <Preloader show={true} />;
  } else {
    var dueColor = "";
    var now = moment.tz(new Date(), defaultTimeZone);
    let profileImage = "";

    displayUserData = listData.map((item, i) => {
      let FFName = "";
      let fullName = "";

      if (item?.contact_name?.first_name && item?.contact_name?.last_name) {
        FFName =
          item.contact_name.first_name.charAt(0) +
          item.contact_name.last_name.charAt(0);
        fullName =
          item.contact_name.first_name + " " + item.contact_name.last_name;
      } else if (
        item?.contact_name?.first_name &&
        !item?.contact_name?.last_name
      ) {
        FFName = item.contact_name.first_name.charAt(0);
        fullName = item.contact_name.first_name;
      } else if (
        item?.contact_name?.last_name &&
        !item?.contact_name?.first_name
      ) {
        FFName = item.contact_name.last_name.charAt(0);
        fullName = item.contact_name.last_name;
      }

      let link = "";
      if (item?.relatedToName?.id) {
        if (item.relatedToName.type === "Deal") {
          link = "/edit-deal/" + item.relatedToName.id;
        } else {
          link = "/edit-lead/" + item.relatedToName.id;
        }
      }

      //var endDate = moment.tz(item.end_date, defaultTimeZone);
      var endDate = moment.utc(item.end_date).tz(defaultTimeZone);

      //if((moment.tz(item.end_date, defaultTimeZone).format(preferredDateFormatData)<=moment.tz(new Date(), defaultTimeZone).format(preferredDateFormatData))){
      if (endDate <= now) {
        dueColor = "red";
      } else {
        dueColor = "";
      }

      if (item?.contact_name?.profile_pic) {
        profileImage =
          process.env.REACT_APP_API_URL +
          "storage/" +
          item.contact_name.profile_pic;
      }

      return (
        <div className="list_view bordertop pt16 pb18" key={i}>
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="activity[]"
                checked={isCheckBoxCheck}
                value={item.id}
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckbox(value, item.id);
                }}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{
                  marginLeft: 5,
                  userSelect: "none",
                  "font-weight": "normal",
                }}
                label=""
              />
              <span className="checkmark-----"></span>
            </label>
          </div>

          {/*--- image_and_name_section ---*/}
          <div className="listdetails">
            <Stack direction="row" spacing={3}>
              <Avatar
                alt={fullName}
                src={profileImage}
                sx={{ width: 76, height: 76 }}
                onError={addDefaultSrc}
                className="listimg"
              >
                {FFName}
              </Avatar>
            </Stack>

            {/*---details_section ---*/}
            <div className="leaddetails">
              {userPermission ? (
                userPermission.includes("activity_edit") ? (
                  <h5
                    onClick={() =>
                      history.push(
                        "/edit-activity/" +
                          item.id +
                          "?page=" +
                          currentPage +
                          "&search=" +
                          search +
                          "&sort_field=" +
                          sortColumn +
                          "&sort_order=" +
                          sortOrder +
                          "&per_page=" +
                          perPage
                      )
                    }
                    style={{ cursor: "pointer", textTransform: "capitalize" }}
                  >
                    {item.name ? item.name : "N/A"}
                  </h5>
                ) : (
                  <h5>{item.name ? item.name : "N/A"}</h5>
                )
              ) : (
                <h5>{item.name ? item.name : "N/A"}</h5>
              )}

              {/*--- lead_inner1_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Name:</span>
                    <a
                      href={link}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        borderBottom: "1px dotted",
                      }}
                    >
                      {item?.selected_salutation || ""}
                      {" " + fullName}
                    </a>
                  </div>
                  {/*--- lead_company_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Location:</span>
                    <h6>{item?.location || "N/A"}</h6>
                  </div>
                  <div className="leadtitle display align-items-start">
                    <span>Related To:</span>
                    <h6>{item?.relatedToName?.name || "N/A"}</h6>
                  </div>
                </div>
              </div>

              {/*--- lead_inner2_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {item.is_fullday ? (
                    <div className="leadtitle display align-items-start">
                      <React.Fragment>
                        {dueColor ? (
                          <Badge
                            bg="dangerss"
                            className="me-1"
                            style={{
                              color: "white",
                              fontWeight: "normal",
                              backgroundColor: "#ed8180",
                            }}
                          >
                            Full day :
                          </Badge>
                        ) : item.start_date === item.end_date ? (
                          <Badge
                            bg="dangerss"
                            className="me-1"
                            style={{
                              color: "white",
                              fontWeight: "normal",
                              backgroundColor: "#ed8180",
                            }}
                          >
                            Full day :
                          </Badge>
                        ) : (
                          <span>Full day :</span>
                        )}
                        {item.start_date === item.end_date
                          ? moment
                              .tz(item.start_date, defaultTimeZone)
                              .format(
                                item.is_fullday
                                  ? calendorFullDateFormate
                                  : preferredDateFormatData
                              )
                          : moment
                              .tz(item.start_date, defaultTimeZone)
                              .format(
                                item.is_fullday
                                  ? calendorFullDateFormate
                                  : preferredDateFormatData
                              ) +
                            " to " +
                            moment
                              .tz(item.end_date, defaultTimeZone)
                              .format(
                                item.is_fullday
                                  ? calendorFullDateFormate
                                  : preferredDateFormatData
                              )}
                      </React.Fragment>
                    </div>
                  ) : (
                    <>
                      <div className="leadtitle display align-items-start">
                        <span>Start date:</span>
                        <h6>
                          {moment
                            .utc(item.start_date)
                            .tz(defaultTimeZone)
                            .format(
                              item.is_fullday
                                ? calendorFullDateFormate
                                : preferredDateFormatData
                            )}
                        </h6>
                      </div>
                      <div className="leadtitle display align-items-start">
                        <React.Fragment>
                          {dueColor ? (
                            <Badge
                              bg="dangerss"
                              className="me-1"
                              style={{
                                color: "white",
                                fontWeight: "normal",
                                backgroundColor: "#ed8180",
                              }}
                            >
                              End date:
                            </Badge>
                          ) : (
                            <span>End date:</span>
                          )}
                          {moment
                            .utc(item.end_date)
                            .tz(defaultTimeZone)
                            .format(
                              item.is_fullday
                                ? calendorFullDateFormate
                                : preferredDateFormatData
                            )}
                        </React.Fragment>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/*--- owner_section ---*/}
          <div className="listowner">
            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>
                    {item.added_by != null ? item.added_by.name : "N/A"}
                  </span>
                </div>
              </div>
            </div>

            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>Last Updated:</span>
                  <h6>
                    {moment
                      .tz(item.updated_at, defaultTimeZone)
                      .format(preferredDateFormatData)}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/*--- action_section ---*/}
          <div className="listaction text-right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="listdot"></span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {userPermission ? (
                  userPermission.includes("activity_edit") ? (
                    <Dropdown.Item
                      onClick={() =>
                        history.push(
                          "/edit-activity/" +
                            item.id +
                            "?page=" +
                            currentPage +
                            "&search=" +
                            search +
                            "&sort_field=" +
                            sortColumn +
                            "&sort_order=" +
                            sortOrder +
                            "&per_page=" +
                            perPage
                        )
                      }
                    >
                      Edit
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userPermission ? (
                  userPermission.includes("activity_delete") ? (
                    <Dropdown.Item
                      onClick={() => {
                        deleteData(item.id);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="searchbar mt30">
          <div className="col-md-4">
            <form onSubmit={manageSearch}>
              <input
                type="text"
                name="searchData"
                className="form-control search"
                value={searchString}
                placeholder="Search this list...."
                onChange={handleSearchInput}
              ></input>
              <span className="listicon" onClick={manageSearch}></span>
            </form>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-6 display justify-content-end">
            <div className="icon-section">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className=" dropdownborder1 dothover"
                >
                  <span className="dot"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item>
                        <ExportData params={expParams} fileName="Activity" api="activity" handleCheckAll={handleCheckAll}/>
                    </Dropdown.Item>
                  {userPermission ? (
                    userPermission.includes("activity_delete") ? (
                      <Dropdown.Item onClick={() => handleBulkDelete()}>
                        Delete
                      </Dropdown.Item>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {/*--- searchbar_section_end ---*/}

        {/*--- list_view_section_start ---*/}
        <div className="display list_view mt30 pb4">
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="checkAll"
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckAll(value);
                }}
                checked={mainChecked}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                label=""
              />
              {/* <span className="checkmark------"></span> */}
            </label>
          </div>

          {/*--- details_section ---*/}
          <div className="listdetails">
            <h3>Activity Details</h3>
          </div>
          {/*--- owner_section ---*/}
          <div className="listowner">
            <h3>Owner</h3>
          </div>
          {/*--- action_section ---*/}
          <div className="listaction"></div>
        </div>

        {displayUserData.length >= 1 ? (
          displayUserData
        ) : (
          <span>No data Available</span>
        )}
        {displayUserData.length >= 1 ? (
          <Row>
            <Col xs={12} xl={12} className="showing">
              <Card border="light" className="bg-white border-0">
                <Card.Body className="listcard pe-0">
                  <Row className="align-items-center">
                    <Col md={3} className="ps-2">
                      <h6>
                        Showing{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1)}
                        </b>{" "}
                        -{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1) +
                            ((dataCount ? dataCount : 0) - 1)}
                        </b>{" "}
                        of{" "}
                        <b>{state?.users?.total ? state?.users?.total : 0}</b>
                      </h6>
                    </Col>
                    <Col xl={4} md={4}>
                      <div className="row align-items-center g-0">
                        <div className="col-md-3 col-xl-4"></div>
                        <div className="col-md-2 col-xl-3">
                          <h6 className="text-right me-3">Per page</h6>
                        </div>
                        <div className="col-md-4 col-xl-4">
                          <select
                            className="border-start-0 border-end-0 border-top-0 borderbottom w-50"
                            value={perPage}
                            onChange={(e) => {
                              handlePerPage(e.target.value);
                              handleCheckAll(false);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                        <div className="col-md-2 col-xl-1"></div>
                      </div>
                    </Col>
                    <Col md={5} className="d-flex justify-content-end">
                      <Pagination
                        activePage={
                          state?.users?.current_page
                            ? state?.users?.current_page
                            : 0
                        }
                        itemsCountPerPage={
                          state?.users?.per_page ? state?.users?.per_page : 0
                        }
                        totalItemsCount={
                          state?.users?.total ? state?.users?.total : 0
                        }
                        onChange={(pageNumber) => {
                          props.history.push(
                            "/activities?page=" +
                              pageNumber +
                              "&search=" +
                              searchString +
                              "&sort_field=" +
                              sortColumn +
                              "&sort_order=" +
                              sortOrder +
                              "&per_page=" +
                              perPage
                          );

                          setCurrentPage(pageNumber);
                          getData(pageNumber);
                          handleCheckAll(false);
                        }}
                        pageRangeDisplayed={5}
                        itemClass="page-item mx-1"
                        linkClass="page-link"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ActivityList;
