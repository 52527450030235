
import React, { useEffect, useState } from "react";
import SalesQuotaChart from "../../components/SalesQuotaChart"
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import { toast } from 'react-toastify';
import axios from 'axios';

function SalesQuotaList(props) {
  const loginUser = useSelector(selectUser);
  const [loading, setLoading] = useState(true);

  const [userList, setUserList] = useState([])
  const [salesChartDatas, setSalesChartDatas] = useState('');
  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);

  const user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [searchUser, setSearchUser] = useState();
  //const [fromDate, setFromDate] = useState();
  //const [toDate, setToDate] = useState();
  const [resetFilter, setResetFilter] = useState(true);
  const [selectMonth, setSelectMonth] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    getData()
  }, [resetFilter,selectMonth,searchUser,endDate]);

  const params = {
    user: searchUser ? searchUser : '',
    //from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
    //to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : '',
    //month_range: dateRange ? dateRange : '',
    start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : '',
    end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : '',

  }

  const searchFilter = (e) => {
    setResetFilter(resetFilter ? false : true)
  }

  const searchMonthFilter = (e) => {
    setSelectMonth(e)
  }

  const searchUserFilter = (e) => {
    setSearchUser(e)
  }

  const searchDateRangeFilter = (e) => {
    setDateRange(e)
  }

  const resetFilterButton = () => {
    setResetFilter(resetFilter ? false : true)
    setSearchUser('')
    //setFromDate('')
    //setToDate('')
    setSelectMonth('')
  }
  
  const getData = async () => {
    await axios.get(`api/dashboard-chart`, { params }).then(response => {
      if (response.status === 200) {
        setSalesChartDatas(response.data.data.salesQuotaChartData)
        setUserList(response.data.data.users)
        setPreferredCurrencyData(response.data.data.preferredCurrencyData)
        setLoading(false)
      }
    }).then(json => {
    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else if (error.response.status === 500) {
        } else {
          toast.error(error.response.data.message);
        }
      }
    });
  }

  return (
    <>
      <div className="display">
        <div className="col-md-12">
          <div className="display chats">
            <div className="col-md-12">
              <SalesQuotaChart salesChartData={salesChartDatas} searchMonthFilter={searchMonthFilter} selectMonth={selectMonth} searchUser={searchUser} userList={userList} searchUserFilter={searchUserFilter} searchDateRangeFilter={searchDateRangeFilter}  preferredCurrencyData={preferredCurrencyData} />
            </div>
          </div>
        </div>
      </div>

      

    </>
  );


};
export default SalesQuotaList;
