import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Col, Dropdown, Form } from "@themesberg/react-bootstrap";
import Preloader from "../../components/Preloader";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import "react-edit-text/dist/index.css";
import "../../assets/css/custom.css";

import { useHistory } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import axios from "axios";
import EasyEdit, { Types } from "react-easy-edit";

var currentPage = 1;

function EditTask(props) {
  const history = useHistory();
  const loginUser = useSelector(selectUser);
  const [isEditable, setIsEditable] = useState(true);

  const user = loginUser?loginUser:[];
  const userPermissions = useSelector((state) => state.permission);
  const userPermission = userPermissions?userPermissions:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [taskID, setTaskID] = useState(props.match.params.id);
  const [updateLeadData, setUpdateLeadData] = useState(false);

  const [priority, setPriority] = useState([]);
  const [priorityStatus, setPriorityStatus] = useState([]);

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

  const [nameErrorMsg, setNameErrorMsg] = useState();
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState();

  //=========START:CODE FOR EDIT LEAD DATA==========//

  const [loading, setLoading] = useState(true);
  const [dataRecord, setDataRecord] = useState("");
  const [record, setRecord] = useState("");
  
  const [ErrorInput, setErrorInput] = useState({ error_list: [] });
  const [dueDate, setDueDate] = useState(
    new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone }))
  );

  //========Const data for Activity (Lead and Deal)
  var calendorFormate = "";

  useEffect(() => {
    async function fetchData() {
      await getTaskData();
    }
    fetchData();
  }, [updateLeadData]);

  async function getTaskData() {
    await axios
      .get("api/task/edit/" + taskID)
      .then((response) => {
        if (response.data.code === 200) {
          setDataRecord(response.data.data.record);
          setRecord(response.data.data);
          setPreferredCurrencyData(response.data.data.preferredCurrency);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);

          setDueDate(
            new Date(
              moment
                .utc(response.data.data.record.end_date)
                .tz(defaultTimeZone)
                .format(preferredDateFormatData)
            )
          );

          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
              setCalendorFullDateFormate("yyyy/MM/DD");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
              setCalendorFullDateFormate("DD/MM/yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
              setCalendorFullDateFormate("yyyy/DD/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
              setCalendorFullDateFormate("MM/DD/yyyy");
            }
          }
          setPriority(response.data.data.priority);
          setPriorityStatus(response.data.data.priorityStatus);
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/deals");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
        history.push("/tasks");
      });
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  const handleSaveData = async (value, name) => {
    const data = {
      id: taskID,
      name: dataRecord.name,
      description: dataRecord.description,
      type: dataRecord.lead_id ? "lead" : "deal",
      leadId: dataRecord.lead_id ? dataRecord.lead_id : "",
      dealId: dataRecord.deal_id ? dataRecord.deal_id : "",
      end_date: moment(dueDate).format("YYYY-MM-DD HH:mm:ss"),
      priority: dataRecord.priority,
      activity_status: dataRecord.activity_status,
      [name]: value,
    };

    if (value) {
      await axios
        .post("api/task/update", data)
        .then((response) => {
          if (response.status === 200) {
            updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
            setErrorInput({ ...ErrorInput, error_list: "" });
            setIsEditable(true);
            toast.success("Data updated successfully");
          }
        })
        .then((json) => {})
        .catch((error) => {
          if (error.response) {
            setIsEditable(true);
            if (error.response.status === 400) {
              setErrorInput({
                ...ErrorInput,
                error_list: error.response.data.validation_errors,
              });
            } else if (error.response.status === 300) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error("Oops Something went wrong.");
            }
          }
        });
    } else {
      updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
      setErrorInput({ ...ErrorInput, error_list: "" });
      setIsEditable(true);
    }
  };

  const cancel = () => {
    setIsEditable(true);
    setDueDate(
      dataRecord.end_date
        ? new Date(
            moment
              .utc(dataRecord.end_date)
              .tz(defaultTimeZone)
              .format(preferredDateFormatData)
          )
        : ""
    );
    setNameErrorMsg("");
    setDescriptionErrorMsg("");
  };

  const handleClick = (e) => {
    setIsEditable(false);
  };

  async function deleteTask(id) {
    const data = {
      id: id,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/task/destroy", { data });

          if (response.status === 200) {
            toast.success(response.data.message);
            history.push("/tasks");
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  const customStyles1 = {
    menuList: (styles) => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    //return currentDate.getTime() < selectedDate.getTime();
    return currentDate.getTime();
  };

  const CustomDateDisplay = (props) => {
    const [startClosingDate, setStartClosingDate] = useState(null);
    setIsEditable(false)
    if (props.value && props.value != null) {
      return (
        <DatePicker
          autocomplete="off"
          //minDate={moment().toDate()}
          selected={dueDate ? dueDate : startClosingDate}
          filterTime={filterPassedTime}
          onChange={(date) => {
            setDueDate(date);
            //handleDueDateSave(date)
          }}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={5}
          timeCaption="time"
          dateFormat={calendorDateFormate + " h:mm aa"}
          className="amount_txt"
        />
      );
    } else {
      return (
        <DatePicker
          autocomplete="off"
          minDate={moment().toDate()}
          selected={dueDate ? dueDate : startClosingDate}
          filterTime={filterPassedTime}
          onChange={(date) => {
            setDueDate(date);
            // handleDueDateSave(date)
          }}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={5}
          timeCaption="time"
          dateFormat={calendorDateFormate + " h:mm aa"}
          className="amount_txt"
        />
      );
    }
  };

  const DisplayCustDate = (props) => {
    const val = props;
    return (
      <DatePicker
        autocomplete="off"
        minDate={moment().toDate()}
        filterTime={filterPassedTime}
        selected={val.value ? val.value : dueDate}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={5}
        timeCaption="time"
        dateFormat={calendorDateFormate + " h:mm aa"}
        className="amount_txt"
        disabled={true}
      />
    );
  };

  const CustomPrioritySelectDisplay = (props) => {
    const handleSelectPrioritySave = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)

    const priorityList = props.data.priorityList;
    const selectedPriority = props.data.selectedPriority;

    return (
      <Select
        defaultValue={selectedPriority}
        name="priority"
        options={priorityList}
        //className="basic-multi-select leadselect"
        classNamePrefix="select"
        onChange={handleSelectPrioritySave}
        styles={customStyles1}
        isClearable={false}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--primary-color-light)",
            primary: "var(--primary-color)",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayPriority = (props) => {
    const val = props;
    return <div>{val.value ? val.value : "Select..."}</div>;
  };

  const CustomPriorityStatusSelectDisplay = (props) => {
    const handleSelectPriorityStatusSave = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const priorityStatusList = props.data.priorityStatusList;
    const selectedPriorityStatus = props.data.selectedPriorityStatus;

    return (
      <Select
        defaultValue={selectedPriorityStatus}
        name="activity_status"
        options={priorityStatusList}
        //className="basic-multi-select leadselect"
        classNamePrefix="select"
        onChange={handleSelectPriorityStatusSave}
        styles={customStyles1}
        isClearable={false}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--primary-color-light)",
            primary: "var(--primary-color)",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayPriorityStatus = (props) => {
    const val = props;
    return <div>{val.value ? val.value : "Select..."}</div>;
  };

  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {
    let selectedPriority = [];
    Object.keys(priority).map(function (key) {
      if (dataRecord.priority == key) {
        selectedPriority.push({ label: priority[key], value: key });
      }
    }, this);

    let priorityList = [];
    Object.keys(priority).map(function (key) {
      priorityList.push({ label: priority[key], value: key });
    }, this);

    let selectedPriorityStatus = [];
    Object.keys(priorityStatus).map(function (key) {
      if (dataRecord.activity_status == key) {
        selectedPriorityStatus.push({ label: priorityStatus[key], value: key });
      }
    }, this);

    let priorityStatusList = [];
    Object.keys(priorityStatus).map(function (key) {
      priorityStatusList.push({ label: priorityStatus[key], value: key });
    }, this);

    let FFName = "";
    let fullName = "";
    let expName = [];
    let sortName = "";
    let fullTitleName = "";

    if (
      record.contactName &&
      record.contactName.first_name &&
      record.contactName.last_name
    ) {
      FFName =
        record.contactName.first_name.charAt(0) +
        record.contactName.last_name.charAt(0);
      fullName = record.contactName.salutation
        ? record.contactName.salutation +
          ". " +
          record.contactName.first_name +
          " " +
          record.contactName.last_name
        : "" +
          record.contactName.first_name +
          " " +
          record.contactName.last_name;
    } else if (
      record.contactName &&
      record.contactName.first_name &&
      !record.contactName.last_name
    ) {
      FFName = record.contactName.first_name.charAt(0);
      fullName = record.contactName.salutation
        ? record.contactName.salutation +
          ". " +
          record.contactName.first_name +
          " " +
          record.contactName.last_name
        : "" + record.contactName.first_name;
    } else if (
      record.contactName &&
      !record.contactName.first_name &&
      record.contactName.last_name
    ) {
      FFName = record.contactName.last_name.charAt(0);
      fullName = record.contactName.salutation
        ? record.contactName.salutation +
          ". " +
          record.contactName.first_name +
          " " +
          record.contactName.last_name
        : "" + record.contactName.last_name;
    }

    if (dataRecord && dataRecord.name) {
      fullTitleName = dataRecord.name;
      sortName = dataRecord.name;
      expName = dataRecord.name.split(" ");

      if (expName.length == 2 || expName.length > 2) {
        sortName = expName[0].charAt(0) + expName[1].charAt(0);
      } else {
        sortName = expName[0].charAt(0);
      }
    }

    let link = "";
    if (record.relatedDisplayName) {
      if (record.relatedDisplayName.id) {
        if (record.relatedDisplayName.type === "Deal") {
          link = "/edit-deal/" + record.relatedDisplayName.id;
        } else {
          link = "/edit-lead/" + record.relatedDisplayName.id;
        }
      }
    }

    return (
      // <Row>
      <div className="display flex-wrap">
        {/*--- client_information_section ---*/}
        <Col md={12} className="client contanctlist activity mt30">
          {/*--- client_name_and_convert_lead_section ---*/}
          <div className="client_details display">
            {/*--- client_image_and_deatils_section ---*/}
            <div className="col-md-6 display">
              <div className="client_image display">
                <Stack direction="row" spacing={3}>
                  <Avatar
                    alt={fullTitleName}
                    src={`${process.env.REACT_APP_API_URL}storage/${
                      record.contactName
                        ? record.contactName.profile_pic
                          ? record.contactName.profile_pic
                          : ""
                        : ""
                    }`}
                    sx={{ width: 50, height: 50 }}
                    onError={addDefaultSrc}
                    className="clientimg"
                  >
                    {sortName}
                  </Avatar>
                </Stack>

                <div className="client_name ml20">
                  <div className="display">
                    <h5>{fullTitleName}</h5>
                  </div>
                  <div className="display">
                    <h6>{dataRecord.deal_id ? "Deal" : "Lead"}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 display justify-content-end">
              {/*--- client_update_time_and_date_section ---*/}
              <div className="client_update">
                <div className="client_txt display justify-content-end">
                  <span>Last update</span>
                </div>
                <div className="client_date_and_time display justify-content-end">
                  {/* <span>July, 03 2022 | 10:13am</span> */}
                  <span>
                    {moment
                      .tz(dataRecord.updated_at, defaultTimeZone)
                      .format(preferredDateFormatData)}
                  </span>
                </div>
              </div>
              {/*--- converted_button ---*/}

              {/*--- client_action_section ---*/}
              <div className="client_action text-right">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className=" dropdownborder1 dothover"
                  >
                    <span className="dot"></span>
                  </Dropdown.Toggle>
                  {userPermission ? (
                    userPermission.includes("task_delete") ? (
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            deleteTask(taskID);
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>

        {/*--- Lead_information_section ---*/}
        <Col md={12} className="mt30">
          <div className="display w-100">
            <Accordion defaultActiveKey="0" className="w-100">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Task Information</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="display align-items-start flex-wrap">
                    <div className="col-md-6 circletrick">
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-12 circletrick taskname">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Subject
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              value={dataRecord.name}
                              onSave={(value) => {
                                handleSaveData(value, "name");
                              }}
                              onValidate={(value) => {
                                if (value) {
                                  var splString = value.split(" ");
                                  if (value.length > 50) {
                                    setErrorInput({
                                      ...ErrorInput,
                                      error_list: [{ name: "" }],
                                    });
                                    setNameErrorMsg(
                                      "Only 50 characters are allowed"
                                    );
                                    return false;
                                  } else if (value && value.length < 2) {
                                    setErrorInput({
                                      ...ErrorInput,
                                      error_list: [{ name: "" }],
                                    });
                                    setNameErrorMsg(
                                      "Enter at least 2 characters."
                                    );
                                    return false;
                                  } else if (
                                    value &&
                                    value.length == 2 &&
                                    value &&
                                    splString.length >= 2
                                  ) {
                                    setErrorInput({
                                      ...ErrorInput,
                                      error_list: [{ name: "" }],
                                    });
                                    setNameErrorMsg(
                                      "Enter at least 2 characters, space not allow."
                                    );
                                    return false;
                                  }
                                  return true;
                                } else {
                                  setErrorInput({
                                    ...ErrorInput,
                                    error_list: [{ name: "" }],
                                  });
                                  setNameErrorMsg("Enter task subject.");
                                  return false;
                                }
                              }}
                              validationMessage={nameErrorMsg}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 50 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                            <span className="error error3">
                              {ErrorInput.error_list.name}
                            </span>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 circletrick position-relative">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Due date
                            </Form.Label>

                            <EasyEdit
                              type={Types.TEXT}
                              onSave={(value) => {
                                handleSaveData(
                                  value
                                    ? moment(value).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : "",
                                  "end_date"
                                );
                              }}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              editComponent={<CustomDateDisplay />}
                              value={dueDate ? dueDate : ""}
                              displayComponent={<DisplayCustDate />}
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                              placeholder="----"
                            />

                            <span className="error error3">
                              {ErrorInput.error_list.end_date}
                            </span>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 circletrick description-input position-relative description">
                          <Form.Group className="display align-items-start mb-2">
                            <Form.Label className="text-right w-17 mt-1">
                              Description
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXTAREA}
                              value={dataRecord.description}
                              onSave={(value) => {
                                handleSaveData(value, "description");
                              }}
                              onValidate={(value) => {
                                var splString = value.split(" ");
                                if (value && value.length > 150) {
                                  setErrorInput({
                                    ...ErrorInput,
                                    error_list: [{ description: "" }],
                                  });
                                  setDescriptionErrorMsg(
                                    "Allow 150 characters only."
                                  );
                                  return false;
                                } else if (value && value.length < 2) {
                                  setErrorInput({
                                    ...ErrorInput,
                                    error_list: [{ description: "" }],
                                  });
                                  setDescriptionErrorMsg(
                                    "Enter at least 2 characters."
                                  );
                                  return false;
                                } else if (
                                  value &&
                                  value.length == 2 &&
                                  value &&
                                  splString.length >= 2
                                ) {
                                  setErrorInput({
                                    ...ErrorInput,
                                    error_list: [{ description: "" }],
                                  });
                                  setDescriptionErrorMsg(
                                    "Enter at least 2 characters, space not allow."
                                  );
                                  return false;
                                } else if (
                                  value &&
                                  value.replace(/\s+/g, "").length < 2
                                ) {
                                  setErrorInput({
                                    ...ErrorInput,
                                    error_list: [{ description: "" }],
                                  });
                                  setDescriptionErrorMsg(
                                    "Enter at least 2 characters, space not allow."
                                  );
                                  return false;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={descriptionErrorMsg}
                              cssClassPrefix="custom-textarea-"
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 150 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                            <span className="error error3">
                              {ErrorInput.error_list.description}
                            </span>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 circletrick position-relative">
                          <Form.Group className="mb-2 display align-items-start">
                            <Form.Label className="text-right w-17">
                              Created by
                            </Form.Label>
                            <div className="fontdisabled display flex-wrap">
                              <p
                                className="disabledrecord"
                                style={{ textTransform: "capitalize" }}
                              >
                                {fullName}
                              </p>
                              <p className="fontsbreak">
                                {moment
                                  .tz(dataRecord.created_at, defaultTimeZone)
                                  .format(preferredDateFormatData)}
                              </p>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 circletrick">
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-12 circletrick position-relative leadlist">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Priority
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              onSave={(value) => {
                                handleSaveData(value, "priority");
                              }}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              editComponent={
                                <CustomPrioritySelectDisplay
                                  data={{
                                    selectedPriority: selectedPriority,
                                    priorityList: priorityList,
                                  }}
                                />
                              }
                              value={
                                selectedPriority.length > 0
                                  ? selectedPriority[0].label
                                  : ""
                              }
                              displayComponent={<CustomSelectDisplayPriority />}
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                              placeholder="----"
                            />

                            <span className="error error3">
                              {ErrorInput.error_list.priority}
                            </span>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 circletrick position-relative leadlist">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-right w-17">
                              Status
                            </Form.Label>
                            <EasyEdit
                              type={Types.TEXT}
                              onSave={(value) => {
                                handleSaveData(value, "activity_status");
                              }}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              editComponent={
                                <CustomPriorityStatusSelectDisplay
                                  data={{
                                    selectedPriorityStatus:
                                      selectedPriorityStatus,
                                    priorityStatusList: priorityStatusList,
                                  }}
                                />
                              }
                              value={
                                selectedPriorityStatus.length > 0
                                  ? selectedPriorityStatus[0].label
                                  : ""
                              }
                              displayComponent={
                                <CustomSelectDisplayPriorityStatus />
                              }
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                              placeholder="----"
                            />
                            <span className="error error3">
                              {ErrorInput.error_list.activity_status}
                            </span>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 circletrick">
                          <Form.Group className="mb-2 display align-items-start">
                            <Form.Label className="text-right w-17">
                              Closed time
                            </Form.Label>
                            <div className="fontdisabled display flex-wrap">
                              <p>
                                {dataRecord.activity_status === "completed"
                                  ? dataRecord.closed_time
                                    ? moment
                                        .utc(dataRecord.closed_time)
                                        .tz(defaultTimeZone)
                                        .format(preferredDateFormatData)
                                    : "---"
                                  : "----"}
                              </p>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 circletrick description-input position-relative">
                          <Form.Group className="display align-items-start">
                            <Form.Label className="text-right w-17 mt-1">
                              Related To
                            </Form.Label>
                            <div className="fontdisabled display flex-wrap">
                              <span
                                onClick={() => history.push(link)}
                                style={{ cursor: "pointer" }}
                              >
                                {record.relatedDisplayName
                                  ? record.relatedDisplayName.name
                                    ? record.relatedDisplayName.name
                                    : "N/A"
                                  : "N/A"}
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    {/*--- first-row ---*/}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>

        {/* </Row> */}
      </div>
    );
  }
}

export default EditTask;
