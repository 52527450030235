import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Nav,
  Badge,
  Image,
  Button,
  Accordion,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
  selectUser,
  LoginPermission,
  Workspace,
} from "../features/userSlice";
import { Routes } from "../routes";
import axios from "axios";

var isClickable = false;

function Sidebar(props) {
  const cookies = new Cookies();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const dispatch = useDispatch();
  const loginUser = useSelector(selectUser);
  const userPermissions = useSelector((state) => state.permission);
  const userWorkspaces = useSelector((state) => state.workspace);

  const user = loginUser?loginUser:[];
  const userPermission = userPermissions?userPermissions:[];
  const userWorkspace = userWorkspaces?userWorkspaces:[];


  //========START:WRITE CODE FOR SHOW HIDE SECOND SIDEBAR MENU===========//
  const splitLocationPath = pathname.split("/");
  let setActiveLink = "/";
  let secondActiveLink = "";
  if (splitLocationPath.length >= 2) {
    secondActiveLink = splitLocationPath[1].split("-");
    if (secondActiveLink.length === 1) {
      setActiveLink = setActiveLink + secondActiveLink[0];
    } else {
      setActiveLink = setActiveLink + secondActiveLink[1] + "s";
    }
  }

  var pageName = [
    "/users",
    "/roles",
    "/products",
    "/stages",
    "/settings",
    "/sources",
    "/checkout",
    "/orders",
    "/logss",
  ];
  var sidebarClassName = "sidebar";
  var sidebarInnerClassName = "inner";
  if (pageName.includes(setActiveLink)) {
    sidebarClassName = "sidebar1";
    sidebarInnerClassName = "inner1";
  }
  //========END:WRITE CODE FOR SHOW HIDE SECOND SIDEBAR MENU===========//

  const logoutSubmit = async () => {
    await axios.post("api/logout").then((response) => {
      if (response.status === 200) {
        dispatch(logout());
        toast.success("You have successfully log out");
      }
    });
  };

  const logOutButton = cookies.get("auth_token") ? (
    <Button
      onClick={logoutSubmit}
      variant="secondary"
      className="upgrade-to-pro sign-out-btn"
    >
      {" "}
      Sign Out
    </Button>
  ) : null;

  if (user) {
    const roleNames = ["admin", "superadmin", "super admin"];
    isClickable = roleNames.includes(user?.role?.toLowerCase() || "");
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    var defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    //setting for custome menu
    if (eventKey === "components/") {
      const componentPaths = [
        "/reports",
        "/track-users",
        "/activities-report",
        "/sales-quota",
      ];
      if (componentPaths.includes(pathname)) {
        defaultKey = "components/";
      }
    }

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    //write code for select active link on all inner pages
    const splitLocation = pathname.split("/");
    let activeLink = "/";
    let secActiveLink = "";

    if (splitLocation.length >= 2) {
      secActiveLink = splitLocation[1].split("-");

      if (secActiveLink.length == 1) {
        activeLink = activeLink + secActiveLink[0];
      } else if (secActiveLink.length == 3) {
        activeLink = activeLink + secActiveLink[2];
        if (activeLink === "/quote") {
          activeLink = "/quotes";
        }
      } else {
        activeLink = activeLink + secActiveLink[1] + "s";
        if (activeLink === "/activitys") {
          //add it for page name was change activity to /activities
          activeLink = "/activities";
        }

        if (activeLink === "/userss") {
          //add it for page name was change activity to /activities
          activeLink = "/track-users";
        }

        if (splitLocation[1] === "activities-report") {
          //add it for page name was change activity to /activities
          activeLink = "/activities-report";
        }

        if (splitLocation[1] === "sales-quota") {
          //add it for page name was change activity to /activities
          activeLink = "/sales-quota";
        }
      }
    }

    const navItemClassName = link === activeLink ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };
    var disClassName = "sidebar-text";
    var addPadding = "";

    if (title === "Log a Visit" || title === "Sales Quota") {
      disClassName = "sidebar-texts";
      addPadding = "50px";
    }

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icons"></span> : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}
            <span className={disClassName} style={{ paddingLeft: addPadding }}>
              {title}
            </span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const companyLogo =
    userWorkspace && userWorkspace?.logo
      ? `${process.env.REACT_APP_API_URL}storage/${userWorkspace.logo}`
      : `${process.env.REACT_APP_API_URL}assets/color_nav-logo.png`;

  return (
    <>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} ${sidebarClassName} d-xl-block bg-primary text-white`}
        >
          {/* <div className="col-md-7"> */}
          <div className={`sidebar-${sidebarInnerClassName} pt-4`}>
            <div className="border-bottom-0 d-flex d-xl-block align-items-center justify-content-between justify-content-xl-center">
              <div className="d-flex align-items-center h-60">
                <Image
                  onClick={() => history.push("/dashboard")}
                  src={companyLogo}
                  className="company-logo"
                />
              </div>
              <Nav className="flex-column justify-content-start d-flex">
                <NavItem
                  title="Dashbord"
                  link={Routes.DashboardOverview.path}
                />
                {userPermission.includes("lead_list") && (
                  <NavItem title="Leads" link={Routes.LeadList.path} />
                )}
                {userPermission.includes("deal_list") && (
                  <NavItem title="Deals" link={Routes.DealList.path} />
                )}
                {userPermission.includes("quote_list") && (
                  <NavItem title="Quotes" link={Routes.QuoteList.path} />
                )}
                {userPermission.includes("account_list") && (
                  <NavItem title="Accounts" link={Routes.AccountList.path} />
                )}
                {userPermission.includes("contact_list") && (
                  <NavItem title="Contacts" link={Routes.ContactList.path} />
                )}
                {userPermission.includes("task_list") && (
                  <NavItem title="Tasks" link={Routes.TaskList.path} />
                )}
                {userPermission.includes("activity_list") && (
                  <NavItem title="Activities" link={Routes.ActivityList.path} />
                )}
                <CollapsableNavItem eventKey="components/" title="Reports">
                  {userPermission.includes("report_list") && (
                    <NavItem title="Reports" link={Routes.ReportList.path} />
                  )}
                  {user &&
                  user.real_time_location_traking === "yes" &&
                  userPermission.some((j) =>
                    ["location_list", "location_edit", "location_add"].includes(
                      j
                    )
                  ) ? (
                    <NavItem
                      title="Track Users"
                      link={Routes.UserTrackingList.path}
                    />
                  ) : (
                    ""
                  )}
                  {userPermission.includes("activity_list") && (
                    <NavItem
                      title="Log a Visit"
                      link={Routes.ActivityReport.path}
                    />
                  )}
                  {userPermission.includes("report_list") && (
                    <NavItem
                      title="Sales Quota"
                      link={Routes.SalesQuotaList.path}
                    />
                  )}
                </CollapsableNavItem>

                {logOutButton}
              </Nav>
            </div>
          </div>
          {pageName.includes(setActiveLink) ? (
            <div className="leftgreen vh-100">
              <h2>Settings</h2>
              <ul>
                {user &&
                  (user?.type === 2 || user?.type === 3) &&
                  isClickable && (
                    <li
                      className={"/settings" === setActiveLink ? "active" : ""}
                    >
                      <a
                        onClick={() => history.push(Routes.CompanySetting.path)}
                      >
                        <span className="righticon1"></span>Tenant
                      </a>
                    </li>
                  )}

                {userPermission && userPermission.includes("user_list") && (
                  <li className={"/users" === setActiveLink ? "active" : ""}>
                    <a onClick={() => history.push(Routes.UserList.path)}>
                      <span className="righticon1"></span>Users
                    </a>
                  </li>
                )}

                {userPermission && userPermission.includes("role_list") && (
                  <li className={"/roles" === setActiveLink ? "active" : ""}>
                    <a onClick={() => history.push(Routes.RoleList.path)}>
                      <span className="righticon1"></span>Roles
                    </a>
                  </li>
                )}

                {userPermission && userPermission.includes("product_list") && (
                  <li className={"/products" === setActiveLink ? "active" : ""}>
                    <a onClick={() => history.push(Routes.ProductList.path)}>
                      <span className="righticon1"></span>Products
                    </a>
                  </li>
                )}

                {userPermission && userPermission.includes("stage_list") && (
                  <li className={"/stages" === setActiveLink ? "active" : ""}>
                    <a onClick={() => history.push(Routes.StageList.path)}>
                      <span className="righticon1"></span>Stages
                    </a>
                  </li>
                )}

                {userPermission && userPermission.includes("stage_list") && (
                  <li className={"/sources" === setActiveLink ? "active" : ""}>
                    <a onClick={() => history.push(Routes.SourceList.path)}>
                      <span className="righticon1"></span>Sources
                    </a>
                  </li>
                )}

                {userWorkspace?.package_payment?.package_type !== "offline" &&
                  user?.type === 2 &&
                  user?.currentWorkspaceId === user?.loginUserWorkSpaceId &&
                  userPermission?.includes("order_list") && (
                    <>
                      <li
                        className={
                          "/checkout" === setActiveLink ? "active" : ""
                        }
                      >
                        <a onClick={() => history.push(Routes.Checkout.path)}>
                          <span className="righticon1"></span>Subscription
                        </a>
                      </li>
                      <li
                        className={"/orders" === setActiveLink ? "active" : ""}
                      >
                        <a onClick={() => history.push(Routes.Order.path)}>
                          <span className="righticon1"></span>Orders
                        </a>
                      </li>
                    </>
                  )}

                {userPermission &&
                  userPermission.includes("activitylog_list") && (
                    <li className={"/logss" === setActiveLink ? "active" : ""}>
                      <a
                        onClick={() =>
                          history.push(Routes.LogActivityList.path)
                        }
                      >
                        <span className="righticon1"></span>Activity Logs
                      </a>
                    </li>
                  )}
              </ul>
            </div>
          ) : (
            ""
          )}
        </SimpleBar>
      </CSSTransition>
    </>
  );
}
export default Sidebar;
