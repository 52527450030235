import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Dropdown, Card } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/PreloaderCenter";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Checkbox from "react-custom-checkbox";
import { AccountAddPopUp } from "../../features/userSlice";
//========Component for DnD=========//
import AddAccount from "../../components/AddAccount";
import ExportData from "../../components/ExportData";
//=================================//

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;

var searchData = "";
var searchInputDataName = "";
var searchInputDataEmail = "";
var searchInputDataFromDate = "";
var searchInputDataToDate = "";
var searchInputDataTitle = "";
var searchInputDataStatus = "";
var searchInputDataPhone = "";
var searchInputDataOrganizationName = "";

function ContactList(props) {
  const searchParams = new URLSearchParams(document.location.search);

  const location = props.history.location;
  const loginUser = useSelector(selectUser);
  const userPermissions = useSelector((state) => state.permission);
  const dispatch = useDispatch();

  const [isCheck, setIsCheck] = useState([]);
  const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
  const [mainChecked, setMainChecked] = useState(false);

  const user = loginUser?loginUser:[];
  const userPermission = userPermissions?userPermissions:[];
  

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
  
  const [currentPage, setCurrentPage] = useState(
    searchParams.size != 0
      ? searchParams.get("page")
        ? searchParams.get("page")
        : 1
      : 1
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [statusArray, setStatusArray] = useState("");
  const [lSourceArray, setLSourceArray] = useState(
    searchParams.get("search_user") ? searchParams.get("search_user") : ""
  );
  const [userLists, setUserLists] = useState([]);

  //used for reloading parent page after model was close
  const showAddAccount = useSelector((state) => state.accountAddPopUp);

  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
  const [searchString, setSearchString] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );

  //==============START: Handle new search filter option==================//

  const handleSearchInput = (e) => {
    setSearchString(e.target.value);
  };

  const manageSearch = (e) => {
    e.preventDefault();
    setSearch(searchString);
    setCurrentPage(1);
    if (searchString) {
      props.history.push(
        "/accounts?page=" +
          1 +
          "&search=" +
          searchString +
          "&search_user=" +
          lSourceArray +
          "&sort_field=" +
          sortColumn +
          "&sort_order=" +
          sortOrder +
          "&per_page=" +
          perPage
      );
    } else {
      props.history.push("/accounts");
    }
  };

  const handleSelectUser = (event) => {
    setLSourceArray(event.value);
    setCurrentPage(1);
    props.history.push(
      "/accounts?page=" +
        1 +
        "&search=" +
        searchString +
        "&search_user=" +
        event.value +
        "&sort_field=" +
        sortColumn +
        "&sort_order=" +
        sortOrder +
        "&per_page=" +
        perPage
    );
  };
  //==============END: Handle new search filter option==================//

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const columns = ["name", "email", "phone"];

  const SORT_ASC = "asc";
  const SORT_DESC = "desc";
  const [sortColumn, setSortColumn] = useState();

  const handleSort = (column) => {
    if (column === sortColumn) {
      sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC;
      props.history.push(
        "/accounts?page=" +
          1 +
          "&search=" +
          searchString +
          "&search_user=" +
          lSourceArray +
          "&sort_field=" +
          column +
          "&sort_order=" +
          sortOrder +
          "&per_page=" +
          perPage
      );
    } else {
      setSortColumn(column);
      sortOrder = SORT_DESC;
      props.history.push(
        "/accounts?page=" +
          1 +
          "&search=" +
          searchString +
          "&search_user=" +
          lSourceArray +
          "&sort_field=" +
          column +
          "&sort_order=" +
          sortOrder +
          "&per_page=" +
          perPage
      );
    }
    getData(currentPage, column);
  };

  const handlePerPage = (Page) => {
    setLoading(true);
    setCurrentPage(1);
    perPage = Page;
    props.history.push(
      "/accounts?page=" +
        1 +
        "&search=" +
        searchString +
        "&search_user=" +
        lSourceArray +
        "&sort_field=" +
        sortColumn +
        "&sort_order=" +
        sortOrder +
        "&per_page=" +
        perPage
    );
  };
  //=========END:CODE FOR FOOTER PAGINATION===========//

  var calendorFormate = "";
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      getData(
        searchParams.size != 0
          ? searchParams.get("page")
            ? searchParams.get("page")
            : 1
          : 1
      );
    }
    fetchData();
  }, [
    sortColumn,
    sortOrder,
    search,
    statusArray,
    lSourceArray,
    location,
    showAddAccount,
    searchInputDataName,
    searchInputDataEmail,
    searchInputDataFromDate,
    searchInputDataToDate,
    searchInputDataTitle,
    searchInputDataStatus,
    searchInputDataPhone,
    searchInputDataOrganizationName,
  ]);

  const getData = async (currentPage, column) => {
    setLSourceArray(
      searchParams.get("search_user") ? searchParams.get("search_user") : ""
    );
    setSearchString(
      searchParams.get("search") ? searchParams.get("search") : ""
    );

    const params = {
      search: searchParams.size != 0 ? search : "",
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_account_used: statusArray,
      search_user: searchParams.size != 0 ? lSourceArray : "",
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      //sort_field: sortColumn,
      sort_field: column ? column : sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    };

    await axios
      .get(`api/account?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setListData(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });

          setUserLists(response.data.data.users);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);

          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
            }
          }
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          }
        }
      });
  };

  async function deleteData(id) {
    const data = {
      id: id,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/account/destroy", { data });

          if (response.status === 200) {
            toast.success(response.data.message);
            getData(currentPage);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  async function bulkDeleteData(ids) {
    const data = {
      id: ids,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/account/bulk-destroy", {
            data,
          });

          if (response.status === 200) {
            toast.success(response.data.message);
            getData(currentPage);
            handleCheckAll(false);
            setIsCheckBoxCheck("");
            setIsCheck([]);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
          getData(currentPage);
          handleCheckAll(false);
          setIsCheckBoxCheck("");
          setIsCheck([]);
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  //======Convert Lead to Deal Code=========//

  //==== select_mate
  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  //Export data file for logic
  const expParams = {
    search: searchData,
    search_name: searchInputDataName,
    search_email: searchInputDataEmail,
    search_from_date: searchInputDataFromDate,
    search_to_date: searchInputDataToDate,
    search_title: searchInputDataTitle,
    search_status: searchInputDataStatus,
    search_phone: searchInputDataPhone,
    search_organization_name: searchInputDataOrganizationName,
    sort_field: sortColumn,
    sort_order: sortOrder,
    per_page: perPage,
    page: currentPage,
    selectedIds: isCheck ? isCheck : [],
  };

  const handleBulkDelete = () => {
    if (isCheck && isCheck.length > 0) {
      bulkDeleteData(isCheck);
    } else {
      toast.warning("Please select at least one record for Delete.");
    }
  };

  //============Start:- Handle check all ================//
  const handleCheckbox = (value, id) => {
    if (value) {
      setIsCheck((isCheck) => [...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((x) => x !== id));
    }
  };

  const handleCheckAll = (value) => {
    if (value) {
      setMainChecked(true);
    } else {
      setMainChecked(false);
    }

    setIsCheckBoxCheck(value);
    setIsCheck([]);

    if (value) {
      setIsCheckBoxCheck(value);
      setIsCheck(listData.map((li) => li.id));
    }
  };
  //============End:- Handle check all ================//

  function addAccountPopup() {
    dispatch(AccountAddPopUp(true));
  }

  //==============For Lead Status Filter
  let statusList = [
    { label: "All", value: "" },
    { label: "Lead", value: "lead" },
    { label: "Deal", value: "deal" },
  ];

  let defaultStatus = [];
  statusList.map((status) => {
    if (statusArray == status.value) {
      defaultStatus.push({ label: status.label, value: status.value });
    }
  }, this);

  //==============For Lead Source Filter
  let defaultAllUser = [];
  if (!lSourceArray) {
    defaultAllUser = [{ label: "All Users", value: "" }];
  }

  userLists.map((item) => {
    if (lSourceArray == item.id) {
      defaultAllUser.push({ label: item.name, value: item.id });
    }
  }, this);

  let ListUserArray = [{ label: "All Users", value: "" }];
  userLists.map((item) => {
    ListUserArray.push({ label: item.name, value: item.id });
  }, this);

  let defaultUser = [];
  userLists.map((item) => {
    defaultUser.push({ label: item.name, value: item.id });
  }, this);

  var displayUserData = "";

  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayUserData = listData.map((item, i) => {
      //let FFName = item.organization_name ? item.organization_name.charAt(0):'';

      let sortName = item.organization_name;

      let expName = item.organization_name.split(" ");

      if (expName.length == 2 || expName.length > 2) {
        sortName = expName[0].charAt(0) + expName[1].charAt(0);
      } else {
        sortName = expName[0].charAt(0);
      }
      return (
        <div className="list_view bordertop pt16 pb18" key={i}>
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="account[]"
                checked={isCheckBoxCheck}
                value={item.id}
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckbox(value, item.id);
                }}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{
                  marginLeft: 5,
                  userSelect: "none",
                  "font-weight": "normal",
                }}
                label=""
              />
            </label>
          </div>

          {/*--- image_and_name_section ---*/}
          <div className="listdetails">
            <Stack direction="row" spacing={3}>
              <Avatar
                alt={item.organization_name ? item.organization_name : ""}
                src={
                  item.added_by.profile_pic
                    ? `${process.env.REACT_APP_API_URL}storage/${item.profile_pic}`
                    : ""
                }
                sx={{ width: 76, height: 76 }}
                onError={addDefaultSrc}
                className="listimg"
              >
                {sortName}
              </Avatar>
            </Stack>

            {/*---details_section ---*/}
            <div className="leaddetails">
              {userPermission ? (
                userPermission.includes("account_edit") ? (
                  <h5
                    onClick={() =>
                      history.push(
                        "/edit-account/" +
                          item.id +
                          "?page=" +
                          currentPage +
                          "&search=" +
                          search +
                          "&search_user=" +
                          lSourceArray +
                          "&sort_field=" +
                          sortColumn +
                          "&sort_order=" +
                          sortOrder +
                          "&per_page=" +
                          perPage
                      )
                    }
                    style={{ cursor: "pointer", textTransform: "capitalize" }}
                  >
                    {item.organization_name ? item.organization_name : ""}
                  </h5>
                ) : (
                  <h5>
                    {item.organization_name ? item.organization_name : "N/A"}
                  </h5>
                )
              ) : (
                <h5>
                  {item.organization_name ? item.organization_name : "N/A"}
                </h5>
              )}

              {/*--- lead_inner1_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Phone:</span>
                    <h6
                      style={{
                        display: "block",
                        whiteSpace: "nowrap",
                        width: "auto",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.organization_phone1
                        ? item.organization_phone1
                        : "N/A"}
                    </h6>
                  </div>
                  {item.organization_phone2 ? (
                    <div className="leadtitle display align-items-start">
                      <span>Phone2:</span>
                      <h6
                        style={{
                          display: "block",
                          whiteSpace: "nowrap",
                          width: "auto",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.organization_phone2
                          ? item.organization_phone2
                          : "N/A"}
                      </h6>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/*--- lead_inner2_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_date_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Created:</span>
                    <h6>
                      {moment
                        .tz(item.created_at, defaultTimeZone)
                        .format(preferredDateFormatData)}
                    </h6>
                  </div>
                  {/*--- lead_phone_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Email:</span>
                    <h6>
                      {item.organization_email
                        ? item.organization_email
                        : "N/A"}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*--- owner_section ---*/}
          <div className="listowner">
            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>
                    {item.added_by != null ? item.added_by.name : "N/A"}
                  </span>
                </div>
              </div>
            </div>

            <div className="leadinner">
              <div className="display flex-wrap ">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>Last Updated:</span>
                  <h6>
                    {moment
                      .tz(item.updated_at, defaultTimeZone)
                      .format(preferredDateFormatData)}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/*--- action_section ---*/}
          <div className="listaction text-right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="listdot"></span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {userPermission ? (
                  userPermission.includes("account_edit") ? (
                    <Dropdown.Item
                      onClick={() =>
                        history.push(
                          "/edit-account/" +
                            item.id +
                            "?page=" +
                            currentPage +
                            "&search=" +
                            search +
                            "&search_user=" +
                            lSourceArray +
                            "&sort_field=" +
                            sortColumn +
                            "&sort_order=" +
                            sortOrder +
                            "&per_page=" +
                            perPage
                        )
                      }
                    >
                      Edit
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userPermission ? (
                  userPermission.includes("account_delete") ? (
                    <Dropdown.Item
                      onClick={() => {
                        deleteData(item.id);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="searchbar mt30">
          <div className="col-md-4">
            <form onSubmit={manageSearch}>
              <input
                type="text"
                name="searchData"
                className="form-control search"
                value={searchString}
                placeholder="Search this list...."
                onChange={handleSearchInput}
              ></input>
              <span className="listicon" onClick={manageSearch}></span>
            </form>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-6 display justify-content-end">
            <div className="dropdown-section">
              {userPermission ? (
                userPermission.includes("account_create") ? (
                  <Dropdown className="mr15">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="createbtn btnbluecolor"
                      onClick={() => addAccountPopup()}
                    >
                      Create New<span className="createicon"></span>
                    </Dropdown.Toggle>
                  </Dropdown>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <Select
                defaultValue={defaultAllUser}
                name="leadSource"
                options={ListUserArray}
                styles={customStyles}
                className="basic-multi-select mr15 br10"
                classNamePrefix="select"
                onChange={handleSelectUser}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--primary-color-light)",
                    primary: "var(--primary-color)",
                  },
                })}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            <div className="icon-section">
              <Dropdown className="mr15">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdownborder filterhover"
                >
                  <span className="filter"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {columns.map((column) => {
                    return (
                      <Dropdown.Item
                        key={column}
                        onClick={(e) => handleSort(column)}
                        style={{ cursor: "pointer" }}
                      >
                        {column.charAt(0).toUpperCase() +
                          column.slice(1).replace("_", " ")}
                        {["name", "email", "phone"].includes(column) ? (
                          <span className="arrowupdown">
                            {column === sortColumn ? (
                              sortOrder === SORT_ASC ? (
                                <i
                                  className="ms-1 fa fa-angle-up"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="ms-1 fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              )
                            ) : (
                              <i
                                className="ms-1 fa fa-angle-up"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                        ) : null}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className=" dropdownborder1 dothover"
                >
                  <span className="dot"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <ExportData
                      params={expParams}
                      fileName="Account"
                      api="account"
                      handleCheckAll={handleCheckAll}
                    />
                  </Dropdown.Item>
                  {userPermission ? (
                    userPermission.includes("account_delete") ? (
                      <Dropdown.Item onClick={() => handleBulkDelete()}>
                        Delete
                      </Dropdown.Item>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {/*--- searchbar_section_end ---*/}

        {/*--- list_view_section_start ---*/}
        <div className="display list_view mt30 pb4">
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="checkAll"
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckAll(value);
                }}
                checked={mainChecked}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                label=""
              />
            </label>
          </div>

          <div className="listdetails">
            <h3>Account Details</h3>
          </div>
          <div className="listowner">
            <h3>Owner</h3>
          </div>
          <div className="listaction"></div>
        </div>

        {displayUserData.length >= 1 ? (
          displayUserData
        ) : (
          <span>No data Available</span>
        )}
        {displayUserData.length >= 1 ? (
          <Row>
            <Col xs={12} xl={12} className="showing">
              <Card border="light" className="bg-white border-0">
                <Card.Body className="listcard pe-0">
                  <Row className="align-items-center">
                    <Col md={3} className="ps-2">
                      <h6>
                        Showing{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1)}
                        </b>{" "}
                        -{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1) +
                            ((dataCount ? dataCount : 0) - 1)}
                        </b>{" "}
                        of{" "}
                        <b>{state?.users?.total ? state?.users?.total : 0}</b>
                      </h6>
                    </Col>
                    <Col xl={4} md={4}>
                      <div className="row align-items-center g-0">
                        <div className="col-md-3 col-xl-4"></div>
                        <div className="col-md-2 col-xl-3">
                          <h6 className="text-right me-3">Per page</h6>
                        </div>
                        <div className="col-md-4 col-xl-4">
                          <select
                            className="border-start-0 border-end-0 border-top-0 borderbottom w-50"
                            value={perPage}
                            onChange={(e) => {
                              handlePerPage(e.target.value);
                              handleCheckAll(false);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                        <div className="col-md-2 col-xl-1"></div>
                      </div>
                    </Col>
                    <Col md={5} className="d-flex justify-content-end">
                      <Pagination
                        activePage={
                          state?.users?.current_page
                            ? state?.users?.current_page
                            : 0
                        }
                        itemsCountPerPage={
                          state?.users?.per_page ? state?.users?.per_page : 0
                        }
                        totalItemsCount={
                          state?.users?.total ? state?.users?.total : 0
                        }
                        onChange={(pageNumber) => {
                          props.history.push(
                            "/accounts?page=" +
                              pageNumber +
                              "&search=" +
                              searchString +
                              "&search_user=" +
                              lSourceArray +
                              "&sort_field=" +
                              sortColumn +
                              "&sort_order=" +
                              sortOrder +
                              "&per_page=" +
                              perPage
                          );
                          setCurrentPage(pageNumber);
                          getData(pageNumber);
                          handleCheckAll(false);
                        }}
                        pageRangeDisplayed={5}
                        itemClass="page-item mx-1"
                        linkClass="page-link"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {userPermission ? (
          userPermission.includes("account_create") ? (
            <AddAccount />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ContactList;
