import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, FormCheck } from "@themesberg/react-bootstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import swal from "sweetalert";
import "react-edit-text/dist/index.css";
import "../../assets/css/custom.css";
import Preloader from "../../components/PreloaderCenter";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import EasyEdit, { Types } from "react-easy-edit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { selectUser, selectDefaultData } from "../../features/userSlice";

var selectedRolePer = [];
var userRoleId = "";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
//var perPageDeal = process.env.REACT_APP_DEFAULT_PAGINATION;
var perPageDeal = 6;
var currentPage = 1;
var type = null;
var searchData = "";

function EditUser(props) {
  const history = useHistory();
  var calendorFormate = "";
  const [userID, setUserID] = useState(props.match.params.id);
  const loginUser = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState("");
  const [UserDataPermissions, setUserDataPermissions] = useState([]);
  const [ErrorInput, setErrorInput] = useState({ error_list: [] });
  const [isSelectAll, setIsSelectAll] = useState(false);

  const [roleList, setRoleList] = useState("");
  const [permissionList, setPermissionsList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [updateData, setUpdateData] = useState(false);
  const [monthSalesTarget, setMonthSalesTarget] = useState("");
  const [salesQuotaDataList, setSalesQuotaDataList] = useState([]);
  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
  const [fullPermission, setFullPermission] = useState([]);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [dataCount, setdataCount] = useState("");
  const [totalPermission, setTotalPermission] = useState();
  const [state, setData] = useState({
    users: "",
  });

  const user = loginUser?loginUser:[];
  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  useEffect(() => {
    async function fetchData() {
      await getUserData();
      await getSalesQuotaData();
    }
    fetchData();
  }, [updateData]);

  async function getUserData() {
    await axios
      .get("api/user/edit/" + userID)
      .then((response) => {
        if (response.data.code === 200) {
          setDataList(response.data.data.record);
          setUserDataPermissions(response.data.data.selectedPermissions);
          setSelectedRole(response.data.data.selectedRole[0]);
          if (response.data.data.record.monthly_target) {
            setMonthSalesTarget(
              response.data.data.record.monthly_target[0].monthly_target
            );
          }
          setRoleList(response.data.data.roles);
          setPermissionsList(response.data.data.permissionArray);
          setLoading(false);
          setTotalPermission(response.data.data.totalPermission)
          setFullPermission(response.data.data.permissions)
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
            history.push("/users");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  async function getSalesQuotaData(currentPage) {
    const params = {
      per_page: perPage,
      page: currentPage,
      user_id: userID,
    };

    //await axios.get('api/sales-quota/edit/' + userID, params).then(response => {
    await axios
      .get(`api/sales-quota?page=${currentPage}`, { params })
      .then((response) => {
        if (response.data.code === 200) {
          setSalesQuotaDataList(response.data.data.records.data);
          setPreferredCurrencyData(response.data.data.preferredCurrency);
          setCurrencyCode(response.data.data.preferredCurrency.currencyCode);
          setCurrencySymbol(
            response.data.data.preferredCurrency.currencySymbol
          );
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });
          calendorFormate = response.data.data.preDateFormat.split(" ");
          if (calendorFormate) {
            setCalendorFullDateFormate(calendorFormate[0]);
          }
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
            history.push("/users");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const handleSelectRoleSave = (event) => {
    setSelectedRole(event.value);
    userRoleId = event.value;
    setLoading(true);
    axios
      .get(`api/user/getPermission?id=` + userRoleId)
      .then((response) => {
        if (response.status === 200) {
          //setRoleSelectedPermissions(response.data.data.recordPermissions);
          setUserDataPermissions(response.data.data.recordPermissions);
          selectedRolePer = response.data.data.recordPermissions;
          //set auto select permission for this role
          const data = {
            id: userID,
            role: userRoleId,
            permissions: selectedRolePer,
          };

          axios
            .post("/api/user/update", data)
            .then((response) => {
              window.location.reload(true);
            })
            .then((json) => {})
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 403) {
                  toast.warning("Forbidden " + error.response.data.message);
                } else if (
                  error.response.status === 400 ||
                  error.response.status === 401
                ) {
                  toast.warning(error.response.data.message);
                } else if (error.response.status === 300) {
                  toast.error(error.response.data.message);
                } else {
                  toast.error("Oops Something went wrong.");
                }
              }
              setTimeout(() => {
                window.location.reload(true);
              }, 1000);
            });
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const handleCheckbox = async (e) => {
    e.persist();
    const isChecked = e.target.checked;
    var permissionData = UserDataPermissions;
    var perId = e.target.value;
    var perActionType = "";

    if (isChecked) {
      UserDataPermissions.push(parseInt(e.target.value));
      setUserDataPermissions(permissionData);
      perActionType = "add";
    } else {
      permissionData = permissionData.filter(
        (x) => x !== parseInt(e.target.value)
      );
      setUserDataPermissions(permissionData);
      perActionType = "remove";
    }

    const data = {
      id: userID,
      role: selectedRole,
      permissions: permissionData,
      updatePerId: perId,
      perActType: perActionType,
    };

    await axios
      .post("/api/user/update", data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Permission updated successfully");
          setUserDataPermissions(response.data.data.permissions);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (
            error.response.status === 400 ||
            error.response.status === 401
          ) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };


  const selectAll = async (e) => {
    const data = {
      id: userID,
      role: selectedRole,
      permissions: fullPermission,
      checkAll: e.target.checked ? 'yes':'no',
    };
    setIsSelectAll(e.target.checked ? true : false);
    swal({
      title: "Are you sure you want to change permission?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios
          .post("/api/user/update", data)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Permission updated successfully");
              setUserDataPermissions(response.data.data.selectedPermissionArray);
              window.location.reload();
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else if (
                error.response.status === 400 ||
                error.response.status === 401
              ) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 300) {
                toast.warning(error.response.data.message);
              } else {
                toast.error("Oops Something went wrong.");
              }
            }
          });
      }
    });



    


  };

  const customStyles1 = {
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const cancel = () => {
    setIsEditable(true);
  };

  const handleClick = (e) => {
    setIsEditable(false);
  };

  const handleSaveSalesData = async (value, name) => {
    const data = {
      id: userID,
      monthly_target: value,
    };

    await axios
      .post("/api/users-tenant/update", data)
      .then((response) => {
        if (response.status === 200) {
          setIsEditable(true);
          toast.success("Data updated successfully");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (
            error.response.status === 400 ||
            error.response.status === 401
          ) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const handleSaveData = async (value, name) => {
    setSelectedRole(value);
    userRoleId = value;
    setLoading(true);

    if (value && !isNaN(value)) {
      selectedRolePer = []
      await axios
        .get(`api/user/getPermission?id=` + userRoleId)
        .then((response) => {
          if (response.status === 200) {
            setUserDataPermissions(response.data.data.recordPermissions);
            selectedRolePer = response.data.data.recordPermissions;
            const data = {
              id: userID,
              role: userRoleId,
              permissions: selectedRolePer,
            };

            axios
              .post("/api/user/update", data)
              .then((response) => {
                window.location.reload(true);
              })
              .then((json) => {})
              .catch((error) => {
                if (error.response) {
                  setLoading(false);
                  if (error.response.status === 403) {
                    toast.warning("Forbidden " + error.response.data.message);
                  } else if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    toast.warning(error.response.data.message);
                  } else if (error.response.status === 300) {
                    toast.error(error.response.data.message);
                  } else {
                    toast.error("Oops Something went wrong.");
                  }
                }
                setTimeout(() => {
                  window.location.reload(true);
                }, 1000);
              });
          }
        })
        .then((json) => {})
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else if (error.response.status === 300) {
              toast.warning(error.response.data.message);
            } else {
              toast.error("Oops Something went wrong.");
            }
          }
        });
    } else {
      updateData ? setUpdateData(false) : setUpdateData(true);
      toast.warning("Please select valid role");
      setIsEditable(true);
      setLoading(false);
    }
  };

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  const CustomRoleSelectDisplay = (props) => {
    const handleSelectRoleSave = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const roleArrayList = props.data.roleArrayList;
    const selectedRoleOnDD = props.data.selectedRoleOnDD;

    return (
      <Select
        defaultValue={selectedRoleOnDD}
        name="role"
        options={roleArrayList}
        classNamePrefix="select"
        onChange={handleSelectRoleSave}
        styles={customStyles1}
        isClearable={false}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--primary-color-light)",
            primary: "var(--primary-color)",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayRole = (props) => {
    const val = props;
    return <div>{val.value ? val.value : "Select..."}</div>;
  };

  const myObj = {
    style: "currency",
    currency: currencyCode ? currencyCode : "INR",
  };

  const CustomAmountDisplay = (props) => {
    const handleDateValueChange = (event) => {
      if (event && event != "undefined") {
        props.setParentValue(event);
      }
    };
    setIsEditable(false)
    const DateLocale = props.data.locale;
    const selectedCurrency = props.data.currency;

    return (
      <CurrencyInput
        id="monthly_target"
        name="monthly_target"
        intlConfig={{
          locale: DateLocale ? DateLocale : "en-IN",
          currency: selectedCurrency ? selectedCurrency : "INR",
        }}
        defaultValue={monthSalesTarget}
        placeholder="Enter monthly target"
        className="amount_txt"
        onValueChange={handleDateValueChange}
        decimalsLimit={6}
        step={1}
      />
    );
  };

  const CustomDisplayAmount = (props) => {
    const val = props;
    const DateLocale = props.data.locale;
    const selectedCurrency = props.data.currency;
    return (
      <div>
        <CurrencyInput
          intlConfig={{
            locale: DateLocale ? DateLocale : "en-IN",
            currency: selectedCurrency ? selectedCurrency : "INR",
          }}
          defaultValue={val.value ? val.value : monthSalesTarget}
          placeholder="Enter monthly target"
          className="amount_txt"
          decimalsLimit={6}
          step={1}
        />
      </div>
    );
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    let roleArrayList = [];
    for (var i = 0, l = roleList.length; i < l; i++) {
      roleArrayList.push({ label: roleList[i].name, value: roleList[i].id });
    }

    let selectedRoleOnDD = [];
    for (var i = 0, l = roleList.length; i < l; i++) {
      if (selectedRole == roleList[i].id) {
        selectedRoleOnDD.push({
          label: roleList[i].name,
          value: roleList[i].id,
        });
      }
    }

    let fullName = dataList ? (dataList.name ? dataList.name : "") : "";
    let sortName = "";
    let expName = "";

    if (fullName) {
      expName = fullName.split(" ");

      if (expName.length == 2) {
        sortName = expName[0].charAt(0) + expName[1].charAt(0);
      } else {
        sortName = expName[0].charAt(0);
      }
    }

    function permissionLists(selectedRolePer) {
      return Object.entries(permissionList).map(([key, value]) => {
        return (
          <tr key={key}>
            <td className="permission" style={{ textTransform: "capitalize" }}>
              {key}
            </td>

            {value.map(function (keys, i) {
              let checkPer =
                (selectedRolePer
                  ? selectedRolePer.indexOf(keys.id)
                  : UserDataPermissions.indexOf(keys.id)) > -1
                  ? true
                  : false;
              return (
                <td className="permission">
                  <FormCheck
                    type="checkbox"
                    className="container d-flex align-items-center justify-content-center"
                    key={keys.id}
                  >
                    <FormCheck.Input
                      name="permissions[]"
                      defaultChecked={checkPer}
                      onChange={handleCheckbox}
                      value={keys.id}
                      className="me-2"
                    />
                    {/* {keys.name} */}
                  </FormCheck>
                </td>
              );
            })}
          </tr>
        );
      }, this);
    }

    //======================================================================================================
    
    return (
      // <Row>

      <div className="display flex-wrap">
        {/*--- profile_information_section ---*/}
        <Col md={12} className="client profile mt30">
          {/*--- client_name_and_convert_lead_section ---*/}
          <div className="client_details display justify-content-between">
            {/*--- client_image_and_deatils_section ---*/}
            <div className="client_image profile_imgae display">
              <label
                className="custom-file-upload"
                style={{ cursor: "default" }}
              >
                {/* <input type="file" /> */}
                <Stack direction="row" spacing={3}>
                  <Avatar
                    alt={fullName}
                    src={
                      dataList.profile_image
                        ? `${process.env.REACT_APP_API_URL}storage/${dataList.profile_image}`
                        : ""
                    }
                    sx={{ width: 50, height: 50 }}
                    onError={addDefaultSrc}
                    className="clientimg1"
                  >
                    {sortName}
                  </Avatar>
                </Stack>
              </label>
              <div className="client_name ml20">
                <div className="display flex-wrap">
                  <h5>{fullName}</h5>
                </div>
                <div className="display flex-wrap">
                  <h6>{dataList.email ? dataList.email : ""}</h6>
                </div>
              </div>
            </div>
            {/*--- client_update_time_and_date_section ---*/}
            <div className="profile_btn">
              <h6>{dataList.selected_role ? dataList.selected_role : "N/A"}</h6>
            </div>
            {/*--- converted_button ---*/}
          </div>
        </Col>

        {/*--- Quat_sales_Information_section ---*/}
        <Col md={12} className="mt30">
          <div className="display w-100">
            <Accordion defaultActiveKey="0" className="w-100">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Sales Quota Target</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="display flex-wrap">
                    <div className="col-md-9 circletrick">
                      <div className="display">
                        <div className="col-md-12 circletrick">
                          <Form.Group className="display leadlist">
                            <Form.Label className="text-left w-17">
                              This Month Sales Target
                            </Form.Label>

                            <EasyEdit
                              type={Types.TEXT}
                              onSave={(value) => {
                                handleSaveSalesData(value);
                              }}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              editComponent={
                                <CustomAmountDisplay
                                  data={{
                                    locale:
                                      preferredCurrencyData.currencyStringValue
                                        ? preferredCurrencyData.currencyStringValue
                                        : "en-IN",
                                    currency: preferredCurrencyData.currencyCode
                                      ? preferredCurrencyData.currencyCode
                                      : "INR",
                                  }}
                                />
                              }
                              value={monthSalesTarget}
                              displayComponent={
                                <CustomDisplayAmount
                                  data={{
                                    locale:
                                      preferredCurrencyData.currencyStringValue
                                        ? preferredCurrencyData.currencyStringValue
                                        : "en-IN",
                                    currency: preferredCurrencyData.currencyCode
                                      ? preferredCurrencyData.currencyCode
                                      : "INR",
                                  }}
                                />
                              }
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                              placeholder="----"
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.role}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>

        <Col md={12} className="mt30">
          {/* <div className="display flex-wrap w-100 history">
            <div className="innerdealtable w-100">
              <div className="col-md-8">
                <h5>Sales Quota List</h5>
              </div>
            </div>
            {salesQuotaDataList.length ? (
              <>
                <div className="contanctlistnig">
                  <table>
                    <tbody>
                      <tr>
                        <th style={{ width: "35%" }}>Quota Subject</th>
                        <th style={{ width: "25%" }}>Targeted Amount</th>
                        <th style={{ width: "25%" }}>Achieved Amount</th>
                        <th style={{ width: "20%" }}>Date</th>
                      </tr>
                      {salesQuotaDataList.map((item, i) => {
                        let num = parseInt(item.target_amount);
                        let achievedAmount = parseInt(item.achieved_amount);

                        return (
                          <tr key={i}>
                            <td className="dealsname">
                              <b>{item.subject}</b>
                            </td>
                            <td>
                              {num
                                ? preferredCurrencyData.currencyStringValue
                                  ? num.toLocaleString(
                                      preferredCurrencyData.currencyStringValue,
                                      myObj
                                    )
                                  : currencySymbol + num.toLocaleString(myObj)
                                : "N/A"}
                            </td>
                            <td>
                              {achievedAmount
                                ? preferredCurrencyData.currencyStringValue
                                  ? achievedAmount.toLocaleString(
                                      preferredCurrencyData.currencyStringValue,
                                      myObj
                                    )
                                  : currencySymbol +
                                    achievedAmount.toLocaleString(myObj)
                                : "N/A"}
                            </td>
                            <td>
                              {moment
                                .tz(item.date, defaultTimeZone)
                                .format(calendorFullDateFormate)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {salesQuotaDataList.length >= 1 ? (
                  <div className="contanctlistnig">
                    <Col xs={12} xl={12}>
                      <Card border="light" className="bg-white border-0">
                        <Card.Body className="listcard pe-0 ps-0">
                          <Row className="align-items-center">
                            <Col md={4}>
                              <h6>
                                Showing{" "}
                                <b>
                                  {(state?.users?.per_page
                                    ? state?.users?.per_page
                                    : 0) *
                                    (state?.users?.current_page
                                      ? state?.users?.current_page
                                      : 0) -
                                    ((state?.users?.per_page
                                      ? state?.users?.per_page
                                      : 0) -
                                      1)}
                                </b>{" "}
                                -{" "}
                                <b>
                                  {(state?.users?.per_page
                                    ? state?.users?.per_page
                                    : 0) *
                                    (state?.users?.current_page
                                      ? state?.users?.current_page
                                      : 0) -
                                    ((state?.users?.per_page
                                      ? state?.users?.per_page
                                      : 0) -
                                      1) +
                                    ((dataCount ? dataCount : 0) - 1)}
                                </b>{" "}
                                of{" "}
                                <b>
                                  {state?.users?.total
                                    ? state?.users?.total
                                    : 0}
                                </b>
                              </h6>
                            </Col>
                            <Col xl={4} md={4}>
                              <div className="row align-items-center g-0">
                                <div className="col-md-3 col-xl-4"></div>

                                <div className="col-md-2 col-xl-1"></div>
                              </div>
                            </Col>
                            <Col md={4} className="d-flex justify-content-end">
                              <Pagination
                                activePage={
                                  state?.users?.current_page
                                    ? state?.users?.current_page
                                    : 0
                                }
                                itemsCountPerPage={
                                  state?.users?.per_page
                                    ? state?.users?.per_page
                                    : 0
                                }
                                totalItemsCount={
                                  state?.users?.total ? state?.users?.total : 0
                                }
                                onChange={(pageNumber) => {
                                  currentPage = pageNumber;
                                  getSalesQuotaData(pageNumber);
                                }}
                                pageRangeDisplayed={5}
                                itemClass="page-item mx-1"
                                linkClass="page-link"
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <span>No data Available</span>
            )}
          </div> */}
          {/* <SalesQuoteList userID={userID} /> */}
        </Col>

        {/*--- Profile_Information_section ---*/}
        <Col md={12} className="mt30">
          <div className="display w-100">
            <Accordion defaultActiveKey="0" className="w-100">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Access Setting</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="display flex-wrap">
                    <div className="col-md-9 circletrick">
                      <div className="display">
                        <div className="col-md-12 circletrick">
                          <Form.Group className="display leadlist">
                            <Form.Label className="text-left w-17">
                              Role
                            </Form.Label>

                            <EasyEdit
                              type={Types.TEXT}
                              onSave={(value) => {
                                handleSaveData(value, "role");
                              }}
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              editComponent={
                                <CustomRoleSelectDisplay
                                  data={{
                                    selectedRoleOnDD: selectedRoleOnDD,
                                    roleArrayList: roleArrayList,
                                  }}
                                />
                              }
                              value={
                                selectedRoleOnDD.length > 0
                                  ? selectedRoleOnDD[0].label
                                  : ""
                              }
                              displayComponent={<CustomSelectDisplayRole />}
                              allowEdit={isEditable}
                              onFocus={() => handleClick("")}
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.role}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>

        <Col md={12} className="mt30">
          <div className="display w-100">
            <div className="col-md-12">
              <div className="notes_section sideborader">
                <div className="notes_header p2022 display acvitybottom">
                  <h4>User Permission</h4>
                </div>
                <div className="notes_body notes_bottom">
                  <div className="display flex-wrap">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <h6>Select Permission</h6> 
                            <Form.Check
                                label="Select All"
                                id="checkbox1"
                                checked={UserDataPermissions?.length === totalPermission ? true:false}
                                value={isSelectAll ? false : true}
                                onClick={(e) => selectAll(e)}
                                htmlFor="checkbox1"
                              />
                          </td>
                          <td className="text-center">
                            <h6>Create</h6>
                          </td>
                          <td className="text-center">
                            <h6>Delete</h6>
                          </td>
                          <td className="text-center">
                            <h6>Edit</h6>
                          </td>
                          <td className="text-center">
                            <h6>List</h6>
                          </td>
                          <td className="text-center">
                            <h6>View</h6>
                          </td>
                        </tr>
                        {permissionLists()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

export default EditUser;
