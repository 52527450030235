import React, { useState, memo } from "react";
import Preloader from "./PreloaderCenter";
import ReactECharts from 'echarts-for-react';

function DashboardPieChart(props) {
  const { pieChartData } = props;
  const [loading, setLoading] = useState(true);
    
  setTimeout(() => {
    setLoading(false) 
  }, 300);

  const option = {
    title: {
      text: '',
      subtext: '',
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      left: 'left'
    },
    toolbox: {
      feature: {
        //dataView: { readOnly: false },
        //restore: {},
        saveAsImage: {}
      }
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: '50%',
        data: [
          { value: pieChartData.wondealCount?pieChartData.wondealCount:0, name: 'Deals Won' },
          { value: pieChartData.lostdealCount?pieChartData.lostdealCount:0, name: 'Deals Lost' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
  if (loading) {
    return <Preloader show={true} />;
  } else {
    return (

            <div className="display flex-wrap w-100 reportborder mt30">
      <div className="col-md-12">
        <div className="flex-wrap task align-items-start justify-content-end">
        <div className="innerdealtable w-100">
       <div className="display">
        <div className="col-md-12">
        <h4>Deals Won And Lost</h4>
        </div>
       </div>
      </div>
          <ReactECharts
            option={option}
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
            onChartReady={''}
            onEvents={''}
            opts={''}
          />
          </div>
        </div>
        </div>
    )
  }
}

export default memo(DashboardPieChart);


