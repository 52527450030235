/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import Preloader from "../../components/PreloaderCenter";
import ShowActivityLocation from "../../components/ShowActivityLocation";
import { toast } from "react-toastify";
import axios from "axios";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import moment from "moment-timezone";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;
var displayUserData = "";

function ActivityList(props) {
  const searchParams = new URLSearchParams(document.location.search);

  const location = props.history.location;
  const loginUser = useSelector(selectUser);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [visitedtime, setVisitedtime] = useState();
  const [searchUser, setSearchUser] = useState("");
  const [userLists, setUserLists] = useState([]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const user = loginUser?loginUser:[];
  
  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [search, setSearch] = useState();
  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [searchString, setSearchString] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );

  //==============START: Handle new search filter option==================//
  const handleSearchInput = (e) => {
    setSearchString(e.target.value);
  };

  const manageSearch = (e) => {
    e.preventDefault();
    setSearch(searchString);
  };

  //==============END: Handle new search filter option==================//

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });
  //=========END:CODE FOR FOOTER PAGINATION===========//

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        await getData();
      } catch (error) {
        toast.warning("Error fetching data " + error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [search, location, searchUser, perPage, endDate]);

  const getData = async (currentPage) => {
    const params = {
      search: search,
      user_id: searchUser,
      sort_order: sortOrder,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      per_page: perPage,
      page: currentPage,
    };

    await axios
      .get(`api/log-visit-activity?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setListData(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });
          setUserLists(response.data.data.users);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/activities-report");
          } else {
            toast.error(error.response.data.message);
            history.push("/activities-report");
          }
        }
      });
  };
  //============End:- Handle check all ================//

  const openModal = (latitude, longitude, time) => {
    setVisitedtime(time);
    setSelectedLocation({ latitude, longitude });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  let defaultAllUser = [];
  if (!searchUser) {
    defaultAllUser = [{ label: "All Users", value: "" }];
  }

  userLists.map((item) => {
    if (searchUser == item.id) {
      defaultAllUser.push({ label: item.name, value: item.id });
    }
  }, this);

  let ListUserArray = [{ label: "All Users", value: "" }];
  userLists.map((item) => {
    ListUserArray.push({ label: item.name, value: item.id });
  }, this);

  const handleSelectUser = (event) => {
    setSearchUser(event.value);
  };
  

  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayUserData = listData.map((item, i) => {
      return (
        <tr key={i}>
          <td>{item?.added_by?.name || "N/A"}</td>
          <td>{item?.description || "N/A"}</td>
          <td>{item?.relatedToName?.type || "N/A"}</td>
          <td>
            {item?.latitude && item?.longitude ? (
              <span
                onClick={() =>
                  openModal(
                    item.latitude,
                    item.longitude,
                    item.end_date
                      ? moment
                          .utc(item.end_date)
                          .tz(defaultTimeZone)
                          .format(preferredDateFormatData)
                      : "N/A"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                Click to Show Location
              </span>
            ) : (
              "N/A"
            )}
          </td>
          <td>
            {item.end_date
              ? moment
                  .utc(item.end_date)
                  .tz(defaultTimeZone)
                  .format(preferredDateFormatData)
              : "N/A"}
          </td>
          <td>
            {
            item?.attachment?.length ?
              item.attachment.map((attach_item, j) => (
                <div className="display mt5" key={j}>
                  <div
                    className="attachment-file"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "end",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_API_URL}storage/${attach_item.file_url}`}
                      download={attach_item.file_url}
                    >
                      {/* <span onClick={()=>handleDownload(`${process.env.REACT_APP_API_URL}storage/${attach_item.file_url}`,attach_item.file_url)}> */}
                      <span>
                        {" "}
                        {attach_item.file_url.substr(
                          attach_item.file_url.length - 30
                        )}{" "}
                      </span>
                    </a>
                  </div>
                </div>
              ))
              :
              'N/A'
            }
          </td>
        </tr>
      );
    });
  }
  return (
    <>
      <div className="searchbar mt30">
        <div className="col-md-4">
          <form onSubmit={manageSearch}>
            <input
              type="text"
              name="searchData"
              className="form-control search"
              value={searchString}
              placeholder="Search this list...."
              onChange={handleSearchInput}
            ></input>
            <span className="listicon" onClick={manageSearch}></span>
          </form>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-6 display justify-content-end">
          <div className="dropdown-section display">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              placeholderText="Select Date"
              isClearable={true}
              className="basic-multi-select mr15 br10 datepicker"
            />
            <Select
              defaultValue={defaultAllUser}
              name="user_id"
              options={ListUserArray}
              styles={""}
              className="basic-multi-select mr15 br10"
              classNamePrefix="select"
              onChange={handleSelectUser}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "var(--primary-color-light)",
                  primary: "var(--primary-color)",
                },
              })}
              isClearable={false}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
      {/*--- searchbar_section_end ---*/}
              
      <>
        <Row>
          <Col md={12} className="mt30">
            <div className="display flex-wrap w-100 history">
              <div className="innerdealtable w-100">
                <div className="col-md-8">
                  <h5>Log a Visit Activities</h5>
                </div>
              </div>
              {displayUserData?.length ? (
              <>
                <div className="contanctlistnig">
                  <table>
                    <tbody>
                      <tr>
                        <th style={{ width: "15%" }}>User</th>
                        <th style={{ width: "25%" }}>Description</th>
                        <th style={{ width: "5%" }}>Type</th>
                        <th style={{ width: "5%" }}>Location</th>
                        <th style={{ width: "15%" }}>Date</th>
                        <th style={{ width: "35%" }}>Attachment</th>
                      </tr>
                      {displayUserData}
                    </tbody>
                  </table>
                </div>

                {displayUserData?.length ? (
                 <div className="contanctlistnig">
                  <Col xs={12} xl={12} className="showing">
                    <Card border="light" className="bg-white border-0">
                      <Card.Body className="listcard">
                        <Row className="align-items-center">
                          <Col md={6} className="ps-2">
                            <h6>
                              Showing{" "}
                              <b>
                                {(state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) *
                                  (state?.users?.current_page
                                    ? state?.users?.current_page
                                    : 0) -
                                  ((state?.users?.per_page
                                    ? state?.users?.per_page
                                    : 0) -
                                    1)}
                              </b>{" "}
                              -{" "}
                              <b>
                                {(state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) *
                                  (state?.users?.current_page
                                    ? state?.users?.current_page
                                    : 0) -
                                  ((state?.users?.per_page
                                    ? state?.users?.per_page
                                    : 0) -
                                    1) +
                                  ((dataCount ? dataCount : 0) - 1)}
                              </b>{" "}
                              of{" "}
                              <b>
                                {state?.users?.total ? state?.users?.total : 0}
                              </b>
                            </h6>
                          </Col>
                          <Col md={6} className="d-flex justify-content-end">
                            <Pagination
                              activePage={
                                state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0
                              }
                              itemsCountPerPage={
                                state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0
                              }
                              totalItemsCount={
                                state?.users?.total ? state?.users?.total : 0
                              }
                              onChange={(pageNumber) => {
                                currentPage = pageNumber;
                                getData(pageNumber);
                              }}
                              pageRangeDisplayed={5}
                              itemClass="page-item mx-1"
                              linkClass="page-link"
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  </div>
                  ) : (
                    ""
                  )}

                {modalIsOpen && (
                  <ShowActivityLocation
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    latitude={selectedLocation.latitude}
                    longitude={selectedLocation.longitude}
                    visitedtime={visitedtime}
                  />
                )}
              </>
              ) : (
                <span>No data Available</span>
              )}
            </div>
          </Col>
        </Row>
      </>
    </>
  );
}

export default ActivityList;
