import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Card,
  Button,
  Table,
  Dropdown,
  ButtonGroup,
  Modal,
  Spinner,
  Form,
} from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Preloader from "../../components/PreloaderCenter";
import { useDispatch, useSelector } from "react-redux";
import { SourceAddPopUp } from "../../features/userSlice";
import AddSource from "../../components/AddSource";

import swal from "sweetalert";
import axios from "axios";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
//var perPageDeal = process.env.REACT_APP_DEFAULT_PAGINATION;
var perPageDeal = 6;
var currentPage = 1;
var type = null;
var searchData = "";

function StageList(props) {
  const location = props.history.location;
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [listStage, setStage] = useState([]);

  const showAddSource = useSelector((state) => state.sourceAddPopUp);
  const [showEditLeadStageDefault, setShowEditLeadStageDefault] =
    useState(false);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");

  const [state, setData] = useState({
    users: "",
  });
  const [dealState, setDealState] = useState({
    users: "",
  });

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  const columns = ["title", "status", "action"];

  const SORT_ASC = "asc";
  const SORT_DESC = "desc";
  const [sortColumn, setSortColumn] = useState();
  const [search, setSearch] = useState("");

  const SORT_ASC_DEAL = "asc";
  const SORT_DESC_DEAL = "desc";
  const [sortDealColumn, setSortDealColumn] = useState();

  if (props.location.pathname == "/stage") {
    if (props.location.statess) {
      searchData = props.location.statess.search;
    }
  }

  const handlePerPage = (Page) => {
    setLoading(true);
    currentPage = 1;
    perPage = Page;
    getData();
  };

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [sortColumn, showAddSource, location, sortOrder, search, searchData]);

  const getData = async (currentPage) => {
    const params = {
      search: searchData,
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
      type: type,
    };

    await axios
      .get(`api/source?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setStage(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error("Oops Something went wrong.");
            history.push("/dashboard");
          }
        }
      });
  };

  function changeStatus(id, value) {
    swal({
      title: "Are you sure you want to change status?",
      //text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (willDelete) => {
      if (willDelete) {
        value = value == 0 ? 1 : 0;
        const data = {
          id: id,
          status: value,
        };

        await axios
          .put("api/source/mode", data)
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              getData(currentPage);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error("Oops Something went wrong.");
              }
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          });
      } else {
        window.location.reload();
      }
    });
  }

  function deleteRecord(id) {
    const data = {
      id: id,
    };

    swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios
          .delete("api/source/destroy", { data })
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              getData(currentPage);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error("Oops Something went wrong.");
              }
            }
          });
      }
    });
  }

  function addNewSource() {
    dispatch(SourceAddPopUp(true));
  }

  //=====================START:Write code for edit Lead Stage===========================//

  const handleEditLeadStageClose = () => setShowEditLeadStageDefault(false);
  const [dataDetail, setDataDetail] = useState();
  const [stageTypeArray, setStageTypeArray] = useState([]);

  const [defaultValue, setdefaultValue] = useState({
    id: "",
    title: "",
    description: "",
    is_used_in: "",
    status: "",
    error_list: [],
  });

  function editData(id) {
    // setLoading(true);
    // axios.get('api/source/edit/' + id).then(response => {
    //     if (response.status === 200) {
    //       setDataDetail(response.data.data.record)
    //       setdefaultValue({
    //         id:id,
    //         title: response.data.data.record.title,
    //         description: response.data.data.record.description,
    //         is_used_in: response.data.data.record.is_used_in,
    //         status: response.data.data.record.status,
    //         error_list: [],
    //       })
    //       setShowEditLeadStageDefault(true)
    //       setLoading(false);
    //     }
    //   }).then(json => {
    //   }).catch(error => {
    //     if (error.response) {
    //       setLoading(false);
    //       if (error.response.status === 400 || error.response.status === 401) {
    //         toast.warning(error.response.data.message);
    //       } else if (error.response.status === 300) {
    //         history.push('/source');
    //         toast.warning(error.response.data.message);
    //       } else if(error.response.status === 403){
    //         toast.warning('Forbidden '+error.response.data.message);
    //         history.push('/source');
    //       } else {
    //         toast.error('Opps.. Something went wrong.');
    //       }
    //     }
    //   });
  }

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .trim("Title cannot include space")
      .strict(true)
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter source name"),
  });

  const handleSelectTypeSave = (e) => {
    setdefaultValue({ ...defaultValue, ["is_used_in"]: e.value });
  };

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      id: values.id,
      title: values.title,
      description: values.description,
      is_used_in: defaultValue.is_used_in,
    };

    await axios
      .post("/api/source/update", data)
      .then((response) => {
        if (response.status === 200) {
          setShowEditLeadStageDefault(false);
          getData(currentPage);
          toast.success("Source updated successfully");
          history.push("/sources");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400) {
            setdefaultValue({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/sources");
          } else {
            toast.warning(error.response.data.message);
          }
        }
      });
  };

  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  let defaultStageTypeList = [];
  if (dataDetail) {
    if (dataDetail.is_used_in == null) {
      defaultStageTypeList.push({ label: "Lead and Deal", value: "" });
    } else if (dataDetail.is_used_in == 1) {
      defaultStageTypeList.push({ label: "Lead", value: 1 });
    } else if (dataDetail.is_used_in == 2) {
      defaultStageTypeList.push({ label: "Deal", value: 2 });
    }
  }

  let stageTypeList = [];
  stageTypeList.push({ label: "Lead and Deal", value: "" });
  stageTypeList.push({ label: "Lead", value: 1 });
  stageTypeList.push({ label: "Deal", value: 2 });

  //=====================END:Write code for edit Lead Stage===========================//

  var displayData = "";

  const leadStageDropdown = (id, status) => {
    return (
      <select onChange={() => changeStatus(id)} key={id}>
        <option value="1" selected={status == 1 ? "selected" : ""}>
          Active
        </option>
        <option value="0" selected={status == 0 ? "selected" : ""}>
          Inactive
        </option>
      </select>
    );
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayData = listStage.map((item, i) => {
      var usedIn = "Lead and Deal";
      if (item.is_used_in == 1) {
        usedIn = "Lead";
      } else if (item.is_used_in == 2) {
        usedIn = "Deal";
      }
      return (
        <>
          <tr key={i}>
            {/* <td style={{width:'5%'}}>{item.id}</td> */}
            {userPermission ? (
              userPermission.includes("stage_edit") ? (
                <td
                  style={{ textTransform: "capitalize", width: "80%" }}
                  onClick={() => editData(item.id)}
                >
                  {item.title}
                </td>
              ) : (
                <td style={{ textTransform: "capitalize", width: "80%" }}>
                  {item.title}
                </td>
              )
            ) : (
              <td style={{ textTransform: "capitalize", width: "80%" }}>
                {item.title}
              </td>
            )}
            {/* <td style={{width:'15%'}}>{usedIn}</td> */}
            {userPermission ? (
              userPermission.includes("stage_edit") ? (
                <td style={{ width: "15%" }} className="position">
                  {leadStageDropdown(item.id, item.status)}
                </td>
              ) : (
                <td style={{ width: "15%" }}>
                  {item.status == 1 ? "Active" : "Inactive"}
                </td>
              )
            ) : (
              <td style={{ width: "15%" }}>
                {item.status == 1 ? "Active" : "Inactive"}
              </td>
            )}
            <td style={{ width: "5%" }}>
              <div className="display align-items-center">
                {userPermission
                  ? userPermission.includes("stage_edit")
                    ? // <div className="editicon" onClick={() => editData(item.id)}>
                      //   <Dropdown as={ButtonGroup} className="d-flex justify-content-center">
                      //       <Dropdown.Menu>
                      //         <Dropdown.Item className="editiconhover" onClick={() => editData(item.id)}>
                      //           <FontAwesomeIcon className="me-2" /><span className="edit-icon"></span> Edit
                      //         </Dropdown.Item>
                      //       </Dropdown.Menu>
                      //     </Dropdown>
                      // </div>
                      ""
                    : ""
                  : ""}
                {userPermission ? (
                  userPermission.includes("stage_delete") ? (
                    <div
                      className="deleteicon"
                      onClick={() => {
                        deleteRecord(item.id);
                      }}
                    >
                      <Dropdown
                        as={ButtonGroup}
                        className="d-flex justify-content-center"
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item className="deleteiconhover">
                            <FontAwesomeIcon className="me-2" />
                            <span className="delete-icon"></span>Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </td>
          </tr>
        </>
      );
    });
  }

  return (
    <>
      <div className="mt-4 align-items-center d-flex justify-content-between">
        <div className="col-md-6 stagelist">
          <h3>Source List</h3>
        </div>

        <div className="col-md-6 text-right">
          {userPermission ? (
            userPermission.includes("stage_create") ? (
              <Button
                variant="primary"
                className="createbtn btnbluecolor btn btn-primary"
                onClick={() => addNewSource()}
              >
                Add Source
              </Button>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <Row>
        <Col xs={12} className="mb-4 leadsTable-cl">
          <Table
            responsive
            className="align-items-center table-flush rolewidth"
            style={{ marginBottom: "0px" }}
          >
            <thead>
              <tr>
                {columns.map((column) => {
                  return (
                    <th key={column}>
                      {column.charAt(0).toUpperCase() +
                        column
                          .slice(1)
                          .toLowerCase()
                          .replace("_", " ")
                          .replace("IS USED_IN", "USED IN")}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {displayData.length >= 1 ? (
                displayData
              ) : (
                <span className="text-center">No data Available</span>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {displayData.length >= 1 ? (
        <Row className="align-items-center">
          <Col xs={12} xl={12}>
            <Card border="light" className="bg-white border-0">
              <Card.Body className="listcard pe-0 ps-0">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h6>
                      Showing{" "}
                      <b>
                        {(state?.users?.per_page ? state?.users?.per_page : 0) *
                          (state?.users?.current_page
                            ? state?.users?.current_page
                            : 0) -
                          ((state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) -
                            1)}
                      </b>{" "}
                      -{" "}
                      <b>
                        {(state?.users?.per_page ? state?.users?.per_page : 0) *
                          (state?.users?.current_page
                            ? state?.users?.current_page
                            : 0) -
                          ((state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) -
                            1) +
                          ((dataCount ? dataCount : 0) - 1)}
                      </b>{" "}
                      of <b>{state?.users?.total ? state?.users?.total : 0}</b>
                    </h6>
                  </Col>
                  <Col xl={4} md={4}>
                    <div className="row align-items-center g-0">
                      <div className="col-md-3 col-xl-4"></div>
                      <div className="col-md-2 col-xl-3">
                        <h6 className="text-right me-3">Per Page</h6>
                      </div>
                      <div className="col-md-4 col-xl-4">
                        <select
                          className="border-start-0 border-end-0 border-top-0 borderbottom w-50"
                          value={perPage}
                          onChange={(e) => handlePerPage(e.target.value)}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                      <div className="col-md-2 col-xl-1"></div>
                    </div>
                  </Col>
                  <Col md={4} className="d-flex justify-content-end">
                    <Pagination
                      activePage={
                        state?.users?.current_page
                          ? state?.users?.current_page
                          : 0
                      }
                      itemsCountPerPage={
                        state?.users?.per_page ? state?.users?.per_page : 0
                      }
                      totalItemsCount={
                        state?.users?.total ? state?.users?.total : 0
                      }
                      onChange={(pageNumber) => {
                        currentPage = pageNumber;
                        getData(pageNumber);
                      }}
                      pageRangeDisplayed={5}
                      itemClass="page-item mx-1"
                      linkClass="page-link"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {userPermission ? (
        userPermission.includes("stage_create") ? (
          <>
            <AddSource />
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <Modal
        as={Modal.Dialog}
        centered
        show={showEditLeadStageDefault}
        onHide={handleEditLeadStageClose}
      >
        <Modal.Header>
          <Modal.Title className="h2">Edit Source</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleEditLeadStageClose}
          />
          <span className="modelclose"></span>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={defaultValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
                setFieldTouched,
              } = props;
              return (
                <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-md-12 col-sm-12">
                      <Row className="align-items-center">
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">
                              Name<span className="text-danger">*</span>
                            </h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="title">
                              <Form.Control
                                type="text"
                                minLength={2}
                                maxLength={50}
                                name="title"
                                onChange={handleChange}
                                value={values.title}
                                onBlur={handleBlur}
                                placeholder="Enter title"
                                className={
                                  errors.title && touched.title
                                    ? "input-error"
                                    : null
                                }
                              />
                              <span className="error error4">
                                {defaultValue.error_list.title}
                              </span>
                              <p className="text-danger error4">
                                <ErrorMessage name="title" />
                              </p>
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        {/* <Form.Group>
                      <Form.Label column sm="12">
                        <h6 className="fw600">Used In</h6>
                      </Form.Label>
                      <Col sm="12" className="position-relative">
                        <Form.Group id="is_used_in">
                          <Select
                            defaultValue={defaultStageTypeList}
                            placeholder="Please select"
                            name="is_used_in"
                            options={stageTypeList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleSelectTypeSave}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                   ...theme.colors,
                                   primary25: 'var(--primary-color-light)',
                                   primary: 'var(--primary-color)',
                              },
                            })}
                            isClearable={false}
                            isSearchable={false}
                          />
                          <span className="error">{defaultValue.error_list.is_used_in}</span>
                          <p className="text-danger error4">
                            <ErrorMessage name="is_used_in" />
                          </p>
                        </Form.Group>

                      </Col>
                    </Form.Group> */}
                      </Row>
                    </div>
                  </Row>

                  <div className="text-center mt30">
                    {isSubmitting ? (
                      <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2 text-center">Please wait...</span>
                      </Button>
                    ) : (
                      <div
                        className="btn-toolbar"
                        role="toolbar"
                        aria-label="Toolbar with button groups"
                      >
                        <div
                          className="btn-group mr-2"
                          role="group"
                          aria-label="First group"
                        >
                          <Button
                            type="submit"
                            variant="success"
                            className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                          >
                            Update
                          </Button>
                        </div>
                        <div
                          className="btn-group mr-2"
                          role="group"
                          aria-label="First group"
                        >
                          <Button
                            type="button"
                            variant="success"
                            onClick={handleEditLeadStageClose}
                            className="popupbtn greycolor d-flex align-items-center justify-content-center"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default StageList;
