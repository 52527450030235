import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from 'react-currency-input-field';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Form, Spinner, Modal } from '@themesberg/react-bootstrap';
import Preloader from "../../components/PreloaderCenter";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ActivityList from "../../components/ActivityList"
import NoteList from "../../components/NoteList"
import DealContactList from "../../components/DealContactList"
import DealQuoteList from "../../components/DealQuoteList"
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';

import { useHistory, } from 'react-router-dom';
import Select from 'react-select';
import swal from 'sweetalert';
import axios from 'axios';
import EasyEdit, { Types } from "react-easy-edit";

const customStyles1 = {
     menuList: styles => ({
          ...styles,
          // color: 'black',
          // backgroundcolor: '$blue',
          paddingTop: 0,
          paddingBottom: 0,
     }),
     
}

function EditDeal(props) {
     const history = useHistory();
     const loginUser = useSelector(selectUser);
     const [isEditable, setIsEditable] = useState(true)
     const [isEditableProduct, setIsEditableProduct] = useState(false)
     const [reloadContactParent, setReloadContactParent] = useState(false)

     const user = loginUser?loginUser:[];

     const userPermissions = useSelector((state) => state.permission);

     const userPermission = userPermissions?userPermissions:[];

     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

     const [dealID, setDealID] = useState(props.match.params.id);
     const [updateDealData, setUpdateDealData] = useState(false);
     const [preDateFormate, setPreDateFormate] = useState('');
     
     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
     const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');
     const [displayDateFormate, setDisplayDateFormate] = useState('yyyy/MM/dd');
     const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
     const [closingDate, setClosingDate] = useState();
     const [expectedClosingDate, setExpectedClosingDate] = useState();
     const [dealAmount, setDealAmount] = useState();

     const [firstNameErrorMsg, setFirstNameErrorMsg] = useState();
     const [lastNameErrorMsg, setLastNameErrorMsg] = useState();

     const [orgNameErrorMsg, setOrgNameErrorMsg] = useState();
     const [priorityErrorMsg, setPriorityErrorMsg] = useState();
     const [dealNameErrorMsg, setdealNameErrorMsg] = useState();
     const [dealDescriptionErrorMsg, setDealDescriptionErrorMsg] = useState();

     //=========START:CODE FOR EDIT LEAD DATA==========//

     const [loading, setLoading] = useState(true);
     const [dealRecord, setDealRecord] = useState('');
     const [dealRecordData, setDealRecordData] = useState('');
     const [dealContactRecord, setDealContactRecord] = useState('');
     const [dealAccountRecord, setDealAccountRecord] = useState('');
     const [ContactDatasalutation, setContactDatasalutation] = useState('');
     const [ContactDataProduct, setContactDataProduct] = useState([]);
     const [ContactDataCountryId, setContactDataCountryId] = useState('');
     const [ContactDatastage_id, setContactDataStage_id] = useState('');
     const [ContactDatalead_source, setContactDatalead_source] = useState('');
     const [contactList, setContactList] = useState([]);

     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     const [countries, setCountry] = useState([]);
     const [salutation, setSalutation] = useState([]);
     const [leadSourceArray, setLeadSourceArray] = useState([]);
     const [productArray, setProductArray] = useState([]);
     const [leadStageArray, setLeadStageArray] = useState([]);
     const [productIdFill, setProductIdFill] = useState([]);
     const [displayProductName, setdisplayProductName] = useState('');
     const [displayStageName, setDisplayStageName] = useState('');

     //===============START:CODE FOR UPDATE STAGE AS WON AND LOST==================//
     const [changeStageStatus, setChangeStageStatus] = useState('');

     const [showWonStatus, setShowWonStatus] = useState(false);
     const handleChangeWonStatusClose = () => {
          //setLoading(true)
          setShowWonStatus(false)
          updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
          window.location.reload();
     }

     const [showLostStatus, setShowLostStatus] = useState(false);
     const handleChangeLostStatusClose = () => {
          setShowLostStatus(false)
          updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
          window.location.reload();
     };

     const [submitWonClose, setSubmitWonClose] = useState(false);
     const [submitLostClose, setSubmitLostClose] = useState(false);


     const [dealWon, setDealWon] = useState({
          note: '',
          deal_amount: '',
          stage_id: '',
          closing_date: new Date(),
          error_list: [],
     });
     const [wonClosingDate, setWonClosingDate] = useState(new Date());

     const wonAmountRef = useRef();
     const [formWonAmountError, setFormWonAmountError] = useState(false);
     const [formWonClosingDateError, setFormWonClosingDateError] = useState(false);
     //===============END:CODE FOR UPDATE STAGE AS WON AND LOST==================//

     const handleChieldResponce = () =>{
          setReloadContactParent(true)
     }

     //========Const data for Activity (Lead and Deal)
     var calendorFormate = '';
     var displayDealAmount = ''

     useEffect(() => {
          async function fetchData() {
               await getDeal();
          }
          fetchData();
     }, [updateDealData, showWonStatus, showLostStatus,reloadContactParent]);
     
     async function getDeal() {
          await axios.get('api/deal/edit/' + dealID).then(response => {
               if (response.data.code === 200) {

                    //console.log(response,'--------=========---------')
                    setDealRecord(response.data.data.record);
                    setDealContactRecord(response.data.data.record.dealData.ContactData)
                    setDealAccountRecord(response.data.data.record.dealData.AccountData)
                    setDealRecordData(response.data.data.record.dealData)
                    setContactList(response.data.data.contactLists)

                    setPreDateFormate(response.data.data.preferredDateFormat);
                    setDealAmount(response.data.data.record.dealData.deal_amount);
                    setContactDatasalutation(response.data.data.record.salutation);
                    if (response.data.data.record.dealData.expected_closing_date) {
                         setExpectedClosingDate(new Date(response.data.data.record.dealData.expected_closing_date))
                    }else{
                         setExpectedClosingDate('')  
                    }

                    setContactDataCountryId(response.data.data.record.country_id);
                    setContactDataProduct(response.data.data.selected_product);
                    setContactDataStage_id(response.data.data.record.dealData.stage_id);

                    setDisplayStageName(response.data.data.record.dealData.DealStage);

                    setContactDatalead_source(response.data.data.record.dealData.deal_source);

                    setPreferredCurrencyData(response.data.data.record.dealData.preferredCurrencyData);
                    setPreferredDateFormatData(response.data.data.record.dealData.preferredDateFormat);

                    setDealWon({
                         note: response.data.data.record.dealData.note,
                         deal_amount: response.data.data.record.dealData.deal_amount,
                         stage_id: response.data.data.record.dealData.stage_id,
                         closing_date: new Date(response.data.data.record.dealData.closing_date),
                         error_list: [],
                    })

                    if (response.data.data.record.dealData.closing_date) {
                         setWonClosingDate(new Date(response.data.data.record.dealData.closing_date))
                    }

                    calendorFormate = response.data.data.record.dealData.preferredDateFormat.split(' ');
                    if (calendorFormate) {
                         if (calendorFormate[0] == 'YYYY-MMM-DD' || calendorFormate[0] == 'YYYY-MM-DD') {
                              setCalendorDateFormate('yyyy/MM/dd');
                              setCalendorFullDateFormate('yyyy-MMM-DD');
                              setDisplayDateFormate('yyyy-MMM-dd')
                              setPreferredDateFormatData('YYYY-MMM-DD h:mm a')
                              if(calendorFormate[0] == 'YYYY-MM-DD'){
                                   setDisplayDateFormate('yyyy-MM-dd')
                                   setPreferredDateFormatData('YYYY-MM-DD h:mm a')
                              }
                              
                         } else if (calendorFormate[0] == 'DD-MMM-YYYY' || calendorFormate[0] == 'DD-MM-YYYY') {
                              setCalendorDateFormate('dd/MM/yyyy');
                              setCalendorFullDateFormate('DD-MMM-yyyy');
                              setDisplayDateFormate('dd-MMM-yyyy')
                              setPreferredDateFormatData('DD-MMM-YYYY h:mm a')
                              if(calendorFormate[0] == 'DD-MM-YYYY'){
                                   setDisplayDateFormate('dd-MM-yyyy')
                                   setPreferredDateFormatData('DD-MM-YYYY h:mm a')
                              }
                              
                         } else if (calendorFormate[0] == 'YYYY-DD-MMM' || calendorFormate[0] == 'YYYY-DD-MM') {
                              setCalendorDateFormate('yyyy/dd/MM');
                              setCalendorFullDateFormate('yyyy-DD-MMM');
                              setDisplayDateFormate('yyyy-dd-MMM')
                              setPreferredDateFormatData('YYYY-DD-MMM h:mm a') 
                              if(calendorFormate[0] == 'YYYY-DD-MM'){
                                   setDisplayDateFormate('yyyy-dd-MM')
                                   setPreferredDateFormatData('YYYY-DD-MM h:mm a') 
                              }
                              
                         } else if (calendorFormate[0] == 'MMM-DD-YYYY' || calendorFormate[0] == 'MM-DD-YYYY') {
                              setCalendorDateFormate('MM/dd/yyyy');
                              setCalendorFullDateFormate('MMM-DD-yyyy');
                              setDisplayDateFormate('MMM-dd-yyyy')
                              setPreferredDateFormatData('MMM-DD-YYYY h:mm a')
                              if(calendorFormate[0] == 'MM-DD-YYYY'){
                                   setDisplayDateFormate('MM-dd-yyyy')
                                   setPreferredDateFormatData('MM-DD-YYYY h:mm a')
                              }
                              
                         }
                         setCalendorFullDateFormate(calendorFormate[0])
                    }
                    setLeadSourceArray(response.data.data.LeadSource);
                    setCountry(response.data.data.countries);
                    setSalutation(response.data.data.salutationArray);
                    setProductArray(response.data.data.products);
                    setLeadStageArray(response.data.data.leadStage);

                    let selectedProducts = [];
                    for (var i = 0, l = response.data.data.selectedData.ProductName.length; i < l; i++) {
                         if (i + 1 < response.data.data.selectedData.ProductName.length) {
                              selectedProducts.push(response.data.data.selectedData.ProductName[i] + ' | ');
                         } else {
                              selectedProducts.push(response.data.data.selectedData.ProductName[i]);
                         }
                    }
                    setdisplayProductName(selectedProducts)
                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setLoading(false);
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/deals');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/deals');
          });
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }

     const handleSaveData = async (value, name) => {

          const data = {
               id: dealID,
               contact_id: dealRecordData.contact_id,
               organization_name: dealAccountRecord.organization_name,
               deal_description: dealRecordData.deal_description,
               deal_amount: dealRecordData.deal_amount,
               deal_probability: dealRecordData.deal_probability,
               closing_date: dealRecordData.closing_date ? moment(dealRecordData.closing_date).format("YYYY-MM-DD") : '',
               expected_closing_date: dealRecordData.expected_closing_date ? moment(dealRecordData.expected_closing_date).format("YYYY-MM-DD") : '',
               lead_source: dealRecordData.lead_source,
               [name]: value,
          }

          if(value!==''){
               await axios.post("api/deal/update", data).then(response => {
                    if (response.status === 200) {
                         updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         setIsEditable(true)
                         setIsEditableProduct(false)
                         toast.success('Data updated successfully');
                    }
               }).then(json => {
     
               }).catch(error => {
                    if (error.response) {
                         setIsEditable(true)
                         setIsEditableProduct(false)
                         if (error.response.status === 400) {
                              setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         } else if (error.response.status === 403) {
                              //swal("error", error.response.data.message, "error");
                              toast.warning('Forbidden ' + error.response.data.message);
                         } else if (error.response.status === 300) {
                              toast.warning(error.response.data.message);
                         } else {
                              toast.error('Oops Something went wrong.');
                         }
                    }
     
               });
          }else{
               updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
               setErrorInput({ ...ErrorInput, error_list: '' });
               setIsEditable(true)
               setIsEditableProduct(false)    
          }
          
     };

   

     let selectProduct = [];
     const handleSelectProductSave = async (e) => {
          setProductIdFill(Array.isArray(e) ? e.map(x => x.value) : []);
          selectProduct = Array.isArray(e) ? e.map(x => x.value) : [];

          const data = {
               id: dealID,
               organization_name: dealAccountRecord.organization_name,
               deal_description: dealRecordData.deal_description,
               deal_amount: dealRecordData.deal_amount,
               deal_probability: dealRecordData.deal_probability,
               closing_date: dealRecordData.closing_date ? moment(dealRecordData.closing_date).format("YYYY-MM-DD") : '',
               expected_closing_date: dealRecordData.expected_closing_date ? moment(dealRecordData.expected_closing_date).format("YYYY-MM-DD") : '',
               product_id: selectProduct,
               contact_id: dealRecordData.contact_id,
          }

          await axios.post("/api/deal/update", data).then(response => {
               if (response.status === 200) {
                    setIsEditable(true)
                    setIsEditableProduct(false)
                    updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    toast.success('Data updated successfully');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setIsEditable(true)
                    setIsEditableProduct(false)
                    if (error.response.status === 400) {
                         setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                    } else if (error.response.status === 403) {
                         //swal("error", error.response.data.message, "error");
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                         toast.error('Oops Something went wrong.')
                    }
               }
          });
     };

     const handleSelectStageSave = (event) => {
          if (event.value) {
               if (event.label == 'Closed Won') {
                    setShowWonStatus(true)
                    setChangeStageStatus(event.value)
               } else if (event.label == 'Closed Lost') {
                    setShowLostStatus(true)
                    setChangeStageStatus(event.value)
               }
               setContactDataStage_id(event.value)
          }
     };

     function handleWonLostStageSave(name) {
          var stageWonLostId = ''
          if (name && name == 'won') {
               name = 'Closed Won'
               for (var i = 0, l = leadStageArray.length; i < l; i++) {
                    if (name == leadStageArray[i].name) {
                         stageWonLostId = leadStageArray[i].id;
                    }
               }

               if (stageWonLostId) {
                    setShowWonStatus(true)
                    setChangeStageStatus(stageWonLostId)
                    setContactDataStage_id(stageWonLostId)
               }
          } else if (name && name == 'lost') {
               name = 'Closed Lost'
               for (var i = 0, l = leadStageArray.length; i < l; i++) {
                    if (name == leadStageArray[i].name) {
                         stageWonLostId = leadStageArray[i].id;
                    }
               }
               if (stageWonLostId) {
                    setShowLostStatus(true)
                    setChangeStageStatus(stageWonLostId)
                    setContactDataStage_id(stageWonLostId)
               }
          }
     };


     async function updateStageData(stage_id) {
          const data = {
               stage_id: stage_id,
               id: dealID,
               contact_id: dealRecordData.contact_id,
               organization_name: dealAccountRecord.organization_name,
               deal_description: dealRecordData.deal_description,
               deal_amount: dealRecordData.deal_amount,
               deal_probability: dealRecordData.deal_probability,
               expected_closing_date: dealRecordData.expected_closing_date,
          }

          await axios.post("/api/deal/update", data).then(response => {
               if (response.status === 200) {
                    setContactDataStage_id(stage_id)
                    updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    toast.success('Data updated successfully');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    if (error.response.status === 400) {
                         setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                    } else if (error.response.status === 403) {
                         //swal("error", error.response.data.message, "error");
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                         toast.error(error.response.data.message);
                    }
               }
          });
     }

     const cancel = () => {
          setIsEditable(true)
          setIsEditableProduct(false)
          
          setFirstNameErrorMsg('')
          setLastNameErrorMsg('')
          setOrgNameErrorMsg('');
          setPriorityErrorMsg('');
          setdealNameErrorMsg('');
          setDealDescriptionErrorMsg('')

          setClosingDate(dealRecord.dealData.closing_date ? new Date(dealRecord.dealData.closing_date):'')
          setExpectedClosingDate(dealRecord.dealData.expected_closing_date ? new Date(dealRecord.dealData.expected_closing_date):'')
     };


     const handleClick = (e) => {
          setIsEditable(false)
          setIsEditableProduct(true)
          if (e && e == 'product') {
               setIsEditableProduct(false)
          }
     }

     const handleProductBlurClick = (e) => {
          if (e && e == 'product') {
               setIsEditable(true)
               setIsEditableProduct(false)
          }
     }


     async function deleteDeal(id) {
          const data = {
            id: id,
          };
        
          try {
            const willDelete = await swal({
              title: "Are you sure you want to delete?",
              text: "Once deleted, you will not be able to recover this data!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
        
            if (willDelete) {
              try {
                const response = await axios.delete('api/deal/destroy', { data });
        
                if (response.status === 200) {
                  toast.success(response.data.message);
                  history.push('/deals');
                }
              } catch (error) {
                if (error.response) {
                  if (error.response.status === 400) {
                    toast.warning(error.response.data.message);
                  } else if (error.response.status === 403) {
                    toast.warning('Forbidden ' + error.response.data.message);
                  } else {
                    toast.error(error.response.data.message);
                  }
                } else {
                  toast.error("Oops Something went wrong.");
                }
              }
            }
          } catch (error) {
            toast.error("Error in swal: ", error);
          }
        }
        

     const handleWonDealInput = (e) => {
          e.persist();
          setDealWon({ ...dealWon, [e.target.name]: e.target.value });
     }

     const handleWonAmountOnValueChange = (value) => {
          if (value) {
               validateDealWonFormData('deal_amount', value);
               setDealWon({ ...dealWon, ['deal_amount']: value });
          }
     };

     function validateDealWonFormData(type, value) {
          if (type == 'deal_amount') {
               if (value == '') {
                    setFormWonAmountError(true);
               } else {
                    setFormWonAmountError(false);
                    if (value != '') {
                         setDealWon(dealWon.error_list.deal_amount = '');
                    }
               }
          }

          if (type == 'closing_date') {
               if (value == '') {
                    setFormWonClosingDateError(true);
               } else {
                    setFormWonClosingDateError(false);
                    if (value != '') {
                         setDealWon(dealWon.error_list.closing_date = '');
                    }
               }
          }
     }

     const dealWonSubmit = async(e) => {
          e.preventDefault();

          if (dealWon.deal_amount == '') {
               setFormWonAmountError(true);
               wonAmountRef.current.focus();
          }

          if (wonClosingDate == null) {
               setFormWonClosingDateError(true);
          }


          if (dealWon.deal_amount != '' && wonClosingDate != null) {
               const data = {
                    id: dealID,
                    contact_id: dealRecordData.contact_id,
                    organization_name: dealAccountRecord.organization_name,
                    deal_description: dealRecordData.deal_description,
                    deal_probability: dealRecordData.deal_probability,
                    deal_amount: dealWon.deal_amount,
                    stage_id: changeStageStatus,
                    closing_date: moment(wonClosingDate).format("YYYY-MM-DD"),
                    expected_closing_date: expectedClosingDate ? moment(expectedClosingDate).format("YYYY-MM-DD"):'',
               }


               setSubmitWonClose(true);
               await axios.post("/api/deal/update", data).then(response => {
                    if (response.status === 200) {
                         toast.success('Stage update successfully.');
                         setContactDataStage_id(changeStageStatus)
                         updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                         setDealWon({ deal_amount: '', closing_date: '', error_list: [] });
                         setShowWonStatus(false);
                         setSubmitWonClose(false);
                         window.location.reload();
                    }
               }).then(json => {

               }).catch(error => {
                    if (error.response) {
                         setSubmitWonClose(false);
                         if (error.response.status === 400) {
                              setDealWon({ ...dealWon, error_list: error.response.data.validation_errors });
                         } else if (error.response.status === 300) {
                              toast.error(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden ' + error.response.data.message);
                         } else {
                              toast.error('Oops Something went wrong.');
                         }
                    }
               });
          }
     }

     const dealLostSubmit = async(e) => {
          e.preventDefault();

          if (dealWon.deal_amount == '') {
               setFormWonAmountError(true);
               wonAmountRef.current.focus();
          }

          if (wonClosingDate == null) {
               setFormWonClosingDateError(true);
          }


          if (dealWon.deal_amount != '' && wonClosingDate != null) {
               const data = {
                    id: dealID,
                    contact_id: dealRecordData.contact_id,
                    organization_name: dealAccountRecord.organization_name,
                    deal_description: dealRecordData.deal_description,
                    deal_probability: dealRecordData.deal_probability,
                    deal_amount: dealWon.deal_amount,
                    stage_id: changeStageStatus,
                    closing_date: moment(wonClosingDate).format("YYYY-MM-DD"),
                    expected_closing_date: expectedClosingDate ? moment(expectedClosingDate).format("YYYY-MM-DD"):'',
                    note: dealWon.note
               }

               setSubmitLostClose(true);
               await axios.post("/api/deal/update", data).then(response => {
                    if (response.status === 200) {
                         toast.success('Stage update successfully.');
                         setContactDataStage_id(changeStageStatus)
                         updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                         setDealWon({ deal_amount: '', closing_date: '', error_list: [] });
                         setShowLostStatus(false);
                         setSubmitLostClose(false);
                         window.location.reload();
                    }
               }).then(json => {

               }).catch(error => {
                    if (error.response) {
                         setSubmitLostClose(false);
                         if (error.response.status === 400) {
                              setDealWon({ ...dealWon, error_list: error.response.data.validation_errors });
                         } else if (error.response.status === 300) {
                              toast.error(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden ' + error.response.data.message);
                         } else {
                              toast.error('Oops Something went wrong.');
                         }
                    }
               });
          }
     }

     const CustomAmountDisplay = props => {

          const handleDateValueChange = (event) => {
               if (event && event != 'undefined') {
                    props.setParentValue(event);
               }
          };
          setIsEditable(false)
          const DateLocale = props.data.locale;
          const selectedCurrency = props.data.currency;

          return (
               <CurrencyInput
                    id="deal_amount"
                    name="deal_amount"
                    intlConfig={{
                         locale: DateLocale ? DateLocale : 'en-IN',
                         currency: selectedCurrency ? selectedCurrency : 'INR',
                    }}
                    defaultValue={dealAmount}
                    placeholder="Enter deal amount"
                    className="amount_txt"
                    onValueChange={handleDateValueChange}
                    decimalsLimit={6}
                    step={1}
               />
          );
     };

     const CustomDisplayDate = props => {
          const val = props;
          const DateLocale = props.data.locale;
          const selectedCurrency = props.data.currency;
          return (
               <div>
                    <CurrencyInput
                         intlConfig={{
                              locale: DateLocale ? DateLocale : 'en-IN',
                              currency: selectedCurrency ? selectedCurrency : 'INR',
                         }}
                         defaultValue={val.value ? val.value : dealAmount}
                         placeholder="Enter deal amount"
                         className="amount_txt"
                         decimalsLimit={6}
                         step={1}
                    />
               </div>
          );
     }

     const CustomDateDisplay = props => {
          const [startClosingDate, setStartClosingDate] = useState(null);
          setIsEditable(false)
          if(props.value && props.value!=null){
               //var closingData = null
               const handleClosingDateSave = (event) => {
                    // console.log(event,'88888888888888888')
                    // if (event && event != 'undefined') {
                    //      setStartClosingDate(moment(event).format("YYYY-MM-DD"))
                    // }
                    // props.setParentValue(startClosingDate);
               };
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         selected={expectedClosingDate ? expectedClosingDate:startClosingDate}
                         //isClearable
                         onChange={(date) => {
                              setExpectedClosingDate(date)
                              //handleClosingDateSave(date)
                         }
                         }
                         dateFormat={displayDateFormate}
                         className="amount_txt"
                    />
               );
          }else{
               //var closingData = null
               const handleClosingDateSave = (event) => {
                    // if (event && event != 'undefined') {
                    //      setStartClosingDate(moment(event).format("YYYY-MM-DD"))
                    // }
                    // props.setParentValue(startClosingDate);
               };
               return (
                    <DatePicker
                         autocomplete="off"
                         //minDate={moment().toDate()}
                         selected={expectedClosingDate ? expectedClosingDate:startClosingDate}
                         //isClearable
                         onChange={(date) => {
                              setExpectedClosingDate(date)
                              //handleClosingDateSave(date)
                         }
                         }
                         dateFormat={displayDateFormate}
                         className="amount_txt"
                    />
               );
          }
          
     };


     const DisplayCustDate = props => {
          const val = props;
          return (
               <DatePicker
                    autocomplete="off"
                    minDate={moment().toDate()}
                    placeholder='----'
                    selected={val.value ? val.value : closingDate}
                    dateFormat={displayDateFormate}
                    className="amount_txt"
                    disabled={true}
               />
          );
     };


     const CustomLeadSelectDisplay = props => {

          const handleSelectLeadSave = (event) => {
               props.setParentValue(event.value);
          };
          setIsEditable(false)
          const leadLists = props.data.leadLists;
          const selectedLead = props.data.selectedLead;

          return (
               <Select
                    defaultValue={selectedLead}
                    name="lead_source"
                    options={leadLists}
                    //className="basic-multi-select leadselect"
                    classNamePrefix="select"
                    onChange={handleSelectLeadSave}
                    // styles={customStyles}
                    theme={(theme) => ({
                         ...theme,
                         borderRadius: 0,
                         colors: {
                              ...theme.colors,
                              primary25: 'var(--primary-color-light)',
                              primary: 'var(--primary-color)',
                         },
                    })}
               />
          );
     };

     const CustomSelectDisplayLead = props => {
          const val = props;
          return (
               <div>
                   {val.value ? !isNaN(val.value) ? 'Please wait...' : val.value : 'Select...'}
               </div>
          );
     }


     const CustomContactSelectDisplay = props => {

          const handleSelectContactSave = (event) => {
               props.setParentValue(event.value);
          };
          setIsEditable(false)
          const ConLists = props.data.conLists;
          const selectedCon = props.data.selectedCon;

          return (
               <Select
                    defaultValue={selectedCon}
                    name="contact_id"
                    options={ConLists}
                    classNamePrefix="select"
                    onChange={handleSelectContactSave}
                    theme={(theme) => ({
                         ...theme,
                         borderRadius: 0,
                         colors: {
                              ...theme.colors,
                              primary25: 'var(--primary-color-light)',
                              primary: 'var(--primary-color)',
                         },
                    })}
               />
          );
     };

     const CustomSelectDisplayContact = props => {
          const val = props;
          return (
               <div>
                    {val.value ? !isNaN(val.value) ? 'Please wait...' : val.value : 'Select...'}
               </div>
          );
     }
     
     if (loading) {
          return <Preloader show={loading ? true : false} />;
     } else {

          let countrysLists = [];
          for (var i = 0, l = countries.length; i < l; i++) {
               countrysLists.push({ label: countries[i].country_name, value: countries[i].id });
          }

          let selectedCountry = [];
          for (var i = 0, l = countries.length; i < l; i++) {
               if (ContactDataCountryId == countries[i].id) {
                    selectedCountry.push({ label: countries[i].country_name, value: countries[i].id });
               }
          }

          let stageLists = [];
          for (var i = 0, l = leadStageArray.length; i < l; i++) {
               stageLists.push({ label: leadStageArray[i].name, value: leadStageArray[i].id });
          }

          let selectedStage = []
          for (var i = 0, l = leadStageArray.length; i < l; i++) {
               if (ContactDatastage_id == leadStageArray[i].id) {
                    selectedStage.push({ label: leadStageArray[i].name, value: leadStageArray[i].id });
               }
          }

          let productLists = [];
          for (var i = 0, l = productArray.length; i < l; i++) {
               productLists.push({ label: productArray[i].name, value: productArray[i].id });
          }

          let selectedProduct = [];
          for (var i = 0, l = productArray.length; i < l; i++) {
               if (ContactDataProduct.includes(productArray[i].id)) {
                    selectedProduct.push({ label: productArray[i].name, value: productArray[i].id });
               }

          }

          let selectedSalutation = [];
          Object.keys(salutation).map(function (key) {
               if (ContactDatasalutation == key) {
                    selectedSalutation.push({ label: salutation[key], value: key });
               }
          }, this);

          let salutationList = [];
          Object.keys(salutation).map(function (key) {
               salutationList.push({ label: salutation[key], value: key });
          }, this);


          let selectedLeadSource = [];
          leadSourceArray.map((item) => {
               if (ContactDatalead_source === item.title) {
                    selectedLeadSource.push({ label: item.title, value: item.title });
               }
          }, this);
          
          let leadSourceList = [];
          leadSourceArray.map((item) => {
               leadSourceList.push({ label: item.title, value: item.title });
          }, this);
          

          let contactLists = [];
          for (var i = 0, l = contactList.length; i < l; i++) {
               contactLists.push({ label: contactList[i].salutation ? contactList[i].salutation+'. ' + contactList[i].first_name+' '+contactList[i].last_name : ' ' + contactList[i].first_name+' '+contactList[i].last_name, value: contactList[i].id });
          }

          let selectedContact = [];
          var contactId =  dealRecordData.contact_id ? dealRecordData.contact_id : ''
          for (var i = 0, l = contactList.length; i < l; i++) {
               if (contactId == contactList[i].id) {
                    selectedContact.push({ label: contactList[i].salutation ? contactList[i].salutation+'. ' + contactList[i].first_name+' '+contactList[i].last_name : ' ' + contactList[i].first_name+' '+contactList[i].last_name, value: contactList[i].id });
               }
          }
          
          let stageColor = 'dealprogress'
          if (displayStageName) {
               if (displayStageName.name == 'Closed Won') {
                    stageColor = 'btngreen dealprogress'
               } else if (displayStageName.name == 'Closed Lost') {
                    stageColor = 'btnred dealprogress'
               } else {
                    stageColor = 'normalclass';
               }
          }

          let FFName = ''
          let Fullname = ''
          let expName = ''
          let profile_pic = ''

          if (dealRecordData && dealRecordData.deal_name) {
               expName = dealRecordData.deal_name.split(' ');
               if (expName.length >= 2) {
                    FFName = expName[0].charAt(0) + expName[1].charAt(0);
                    Fullname = dealRecordData.deal_name
               } else {
                    FFName = expName[0].charAt(0);
                    Fullname = dealRecordData.deal_name
               }
          } else {
               if (dealContactRecord) {
                    if (dealContactRecord.first_name && dealContactRecord.last_name) {
                         FFName = dealContactRecord.first_name.charAt(0) + dealContactRecord.last_name.charAt(0);
                         Fullname = dealContactRecord.first_name + ' ' + dealContactRecord.last_name;
                    } else if (dealContactRecord.first_name && !dealContactRecord.last_name) {
                         FFName = dealContactRecord.first_name.charAt(0);
                         Fullname = dealContactRecord.first_name;
                    } else if (!dealContactRecord.first_name && dealContactRecord.last_name) {
                         FFName = dealContactRecord.last_name.charAt(0);
                         Fullname = dealContactRecord.last_name;
                    }
               }
          }

          if(dealContactRecord){
               profile_pic = dealContactRecord.profile_pic     
          }
          
          return (
               <>
                    {/* <Row> */}
                    <div className="display flex-wrap">

                         <Col md={12} className="client mt30">
                              {/*--- client_name_and_convert_lead_section ---*/}
                              <div className="client_details display">
                                   {/*--- client_image_and_deatils_section ---*/}
                                   <div className="col-md-6 display">
                                        <div className="client_image deal_image display">
                                             <Stack direction="row" spacing={3}>
                                                  <Avatar
                                                       alt={Fullname}
                                                       src={profile_pic ? `${process.env.REACT_APP_API_URL}storage/${profile_pic}`:''}
                                                       sx={{ width: 50, height: 50 }}
                                                       onError={addDefaultSrc}
                                                       className="clientimg"
                                                  >
                                                       {FFName}
                                                  </Avatar>
                                             </Stack>

                                             <div className="client_name ml20">
                                                  <div className="display">
                                                       <h5>{Fullname}</h5>
                                                  </div>
                                                  <div className="display">
                                                       <span>Company:</span>
                                                       <h6>{dealAccountRecord ? dealAccountRecord.organization_name:''}</h6>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-6 display justify-content-end">
                                        {/*--- client_start_date_and_end_date_section ---*/}
                                        <div className="client_twodate client_update display text-center">
                                             <div className="col-md-1"></div>
                                             <div className="col-md-5 display flex-wrap">
                                                  <div className="client_txt display justify-content-end">
                                                       <span>Start Date</span>
                                                  </div>
                                                  <div className="client_date_and_time display justify-content-end">
                                                       {/* <span>July, 03 2022 | 10:13am</span> */}
                                                       <span>{dealRecord && dealRecord.dealData && dealRecord.dealData.created_at ? moment.tz(dealRecord.dealData.created_at, defaultTimeZone).format(calendorFullDateFormate):'N/A'}</span>
                                                  </div>
                                             </div>
                                             <div className="col-md-5 clientborder">
                                                  <div className="client_txt display justify-content-end">
                                                       <span>Closing Date</span>
                                                  </div>
                                                  <div className="client_date_and_time display justify-content-end">
                                                       {/* <span>July, 03 2022 | 10:13am</span> */}
                                                       <span>
                                                            {   
                                                            
                                                            displayStageName && displayStageName.name && (displayStageName.name  === 'Closed Won' || displayStageName.name === 'Closed Lost') ?
                                                                      dealRecordData.closing_date ?
                                                                           moment.tz(dealRecordData.closing_date, defaultTimeZone).format(calendorFullDateFormate)
                                                                           :
                                                                           //oment.tz(dealRecord.updated_at, defaultTimeZone).format(calendorFullDateFormate)
                                                                           'N/A'
                                                                 :
                                                                 'N/A'     
                                                            }
                                                       </span>
                                                  </div>
                                             </div>
                                             <div className="col-md-1"></div>
                                        </div>
                                        {/*--- won_and_lost_button ---*/}
                                        <div className="converted_btn two_btn display">
                                             <Dropdown className="mr15">
                                                  <Dropdown.Toggle id="dropdown-basic" onClick={() => handleWonLostStageSave('won')} className="createbtn btn btn-primary display btngreen">
                                                       Won <span className="wonicon"></span>
                                                  </Dropdown.Toggle>
                                             </Dropdown>

                                             <Dropdown>
                                                  <Dropdown.Toggle id="dropdown-basic" onClick={() => handleWonLostStageSave('lost')} className="createbtn btn btn-primary display btnred" >
                                                       Lost <span className="wonicon losticon"></span>
                                                  </Dropdown.Toggle>
                                             </Dropdown>
                                        </div>
                                        {/*--- client_action_section ---*/}
                                        <div className="client_action deal_action text-right">
                                             <Dropdown>
                                                  <Dropdown.Toggle id="dropdown-basic" className=" dropdownborder1 dothover">
                                                       <span className="dot"></span>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                  {
                                                       userPermission ?
                                                       (userPermission.includes('deal_delete')) ?
                                                       <Dropdown.Item onClick={() => { deleteDeal(dealID) }}>Delete</Dropdown.Item>
                                                       :
                                                       ''
                                                       :
                                                       ''
                                                  }
                                                  {
                                                       userPermission ?
                                                       (userPermission.includes('deal_create')) ?
                                                       <Dropdown.Item onClick={() => history.push('/clone-deal/' + dealID)}>Clone Deal</Dropdown.Item>
                                                       :
                                                       ''
                                                       :
                                                       ''
                                                  }
                                                       
                                                       
                                                  </Dropdown.Menu>
                                             </Dropdown>
                                        </div>
                                   </div>
                              </div>
                         </Col>

                         <Col md={12} className="navigation mt20">
                              <div className="display">
                                   <div className="col-md-6 display pl25">
                                        <h3>Current Deal Status:</h3>
                                        <span><h3><span className={stageColor} style={{ textTransform: 'capitalize' }}>{displayStageName ? displayStageName.name : 'N/A'}</span></h3></span>
                                   </div>
                                   <div className="col-md-6 text-right display justify-content-end">

                                        <Select
                                             defaultValue={selectedStage}
                                             name="stage_id"
                                             options={stageLists}
                                             className="basic-multi-select mr20"
                                             classNamePrefix="select"
                                             onChange={handleSelectStageSave}
                                             styles={customStyles1}
                                             theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 0,
                                                  colors: {
                                                       ...theme.colors,
                                                       primary25: 'var(--primary-color-light)',
                                                       primary: 'var(--primary-color)',
                                                  },
                                             })}
                                             isClearable={false}
                                             isSearchable={false}
                                             placeholder='----'
                                        />
                                        <button type="button" className="btn btn-primary createbtn btnbluecolor" onClick={() => updateStageData(ContactDatastage_id)}>Set as a Current <span className="tickicon"></span></button>

                                   </div>
                              </div>
                         </Col>

                         <Col md={12} className="mt30">
                              <div className="display w-100">
                                   <Accordion defaultActiveKey="0" className="w-100">
                                        <Accordion.Item eventKey="0">
                                             <Accordion.Header><h4>Deal Information</h4></Accordion.Header>
                                             <Accordion.Body>
                                                  <div className="display align-items-start flex-wrap">
                                                       <div className="col-md-6 circletrick">
                                                            <div className="display align-items-start flex-wrap">
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Deal name</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                value={dealRecordData.deal_name}
                                                                                onSave={(value) => { handleSaveData(value, 'deal_name') }}
                                                                                onValidate={value => {
                                                                                     if (value) {
                                                                                          var splString = value.split(' ');
                                                                                          if (value && value.length>50) {
                                                                                               setdealNameErrorMsg('Only 50 characters are allowed')
                                                                                               return false    
                                                                                          }else if(value && value.length<2){
                                                                                               setdealNameErrorMsg('Enter at least 2 characters.');
                                                                                               return false
                                                                                          }else if((value && value.length==2) && (value && splString.length>=2)){
                                                                                               setdealNameErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                               return false
                                                                                          }
                                                                                          return true
                                                                                     } else {
                                                                                          setdealNameErrorMsg('Enter deal name');
                                                                                          return false
                                                                                     }

                                                                                }}
                                                                                validationMessage={dealNameErrorMsg}
                                                                                onCancel={cancel}
                                                                                // saveButtonLabel="Save"
                                                                                // cancelButtonLabel="Cancel"
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                attributes={{ maxLength: 50 }}
                                                                                placeholder='----'
                                                                                instructions=""
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick()}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.deal_name}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Company name</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                value={dealAccountRecord ? dealAccountRecord.organization_name:''}
                                                                                onSave={(value) => { handleSaveData(value, 'organization_name') }}
                                                                                onValidate={value => {
                                                                                     if (value) {
                                                                                          var splString = value.split(' ');
                                                                                          if (value && value.length>50) {
                                                                                               setOrgNameErrorMsg('Only 50 characters are allowed')
                                                                                               return false    
                                                                                          }else if(value && value.length<2){
                                                                                               setOrgNameErrorMsg('Enter at least 2 characters.');
                                                                                               return false
                                                                                          }else if((value && value.length==2) && (value && splString.length>=2)){
                                                                                               setOrgNameErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                               return false
                                                                                          }
                                                                                          return true
                                                                                     } else {
                                                                                          setOrgNameErrorMsg('Enter company name');
                                                                                          return false
                                                                                     }
                                                                                }}
                                                                                validationMessage={orgNameErrorMsg}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                attributes={{ maxLength: 50 }}
                                                                                placeholder='----'
                                                                                instructions=""
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick()}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.organization_name}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick leadlist">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Source</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                onSave={(value) => { handleSaveData(value, 'lead_source') }}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                editComponent={
                                                                                     <CustomLeadSelectDisplay data={{ 'selectedLead': selectedLeadSource, 'leadLists': leadSourceList }} />
                                                                                }
                                                                                value={selectedLeadSource.length > 0 ? selectedLeadSource[0].label : ''}
                                                                                displayComponent={<CustomSelectDisplayLead />}
                                                                                allowEdit={isEditable}
                                                                                placeholder='----'
                                                                                onFocus={() => handleClick()}
                                                                           />

                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.lead_source}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick ">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Contact</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                onSave={(value) => { handleSaveData(value, 'contact_id') }}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                editComponent={
                                                                                     <CustomContactSelectDisplay data={{ 'selectedCon': selectedContact, 'conLists': contactLists }} />
                                                                                }
                                                                                value={selectedContact.length > 0 ? selectedContact[0].label : ''}
                                                                                displayComponent={<CustomSelectDisplayContact />}
                                                                                //displayComponent={<CustomSelectDisplayContact data={{ 'selectedCon': selectedContact.length > 0 ? selectedContact[0].label : '' }}/>}
                                                                                allowEdit={isEditable}
                                                                                placeholder='----'
                                                                                onFocus={() => handleClick()}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.contact_id}</span>
                                                                 </div>
                                                                 
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display align-items-start">
                                                                           <Form.Label className="text-right w-10">Product interested</Form.Label>
                                                                           

                                                                           <Select
                                                                                defaultValue={selectedProduct}
                                                                                isMulti
                                                                                name="product_id"
                                                                                options={productLists}
                                                                                className="basic-multi-select producttxt selected"
                                                                                classNamePrefix="select"
                                                                                onChange={handleSelectProductSave}
                                                                                // styles={customStyles}
                                                                                theme={(theme) => ({
                                                                                     ...theme,
                                                                                     borderRadius: 0,
                                                                                     colors: {
                                                                                          ...theme.colors,
                                                                                          primary25: 'var(--primary-color-light)',
                                                                                          primary: 'var(--primary-color)',
                                                                                     },
                                                                                })}
                                                                                isDisabled={isEditableProduct}
                                                                                onFocus={() => handleClick('product')}
                                                                                onBlur={() => handleProductBlurClick('product')}
                                                                                placeholder='----'
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.product_id}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="display align-items-start mb-2">
                                                                           <Form.Label className="text-right w-17">Modified by</Form.Label>
                                                                           <div className="fontdisabled display flex-wrap">
                                                                                <p className="disabledrecord">{dealRecordData? dealRecordData.updatedUserName? dealRecordData.updatedUserName ? dealRecordData.updatedUserName.name : '' : '':''}</p>
                                                                                <p className="fontsbreak">{moment.tz(dealRecordData.updated_at, defaultTimeZone).format(preferredDateFormatData)}</p>
                                                                           </div>
                                                                      </Form.Group>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="col-md-6 circletrick">
                                                            <div className="display align-items-start flex-wrap">

                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Amount&nbsp;
                                                                                {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                                                ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                                                                           </Form.Label>

                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                onSave={(value) => { handleSaveData(value, 'deal_amount') }}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                editComponent={
                                                                                     <CustomAmountDisplay data={{ 'locale': preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN', 'currency': preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR' }} />
                                                                                }
                                                                                value={dealAmount}
                                                                                displayComponent={<CustomDisplayDate data={{ 'locale': preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN', 'currency': preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR' }} />}
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick()}
                                                                                placeholder='----'
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.deal_amount}</span>
                                                                 </div>

                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Expected Closing date</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                onSave={(value) => { handleSaveData(value ? moment(value).format("YYYY-MM-DD") : '', 'expected_closing_date') }}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                editComponent={
                                                                                     <CustomDateDisplay />
                                                                                }
                                                                                value={expectedClosingDate}
                                                                                displayComponent={<DisplayCustDate />}
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick()}
                                                                                placeholder='----'
                                                                           />

                                                                           
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.expected_closing_date}</span>
                                                                 </div>

                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Probability (%)</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                value={dealRecordData.deal_probability}
                                                                                onSave={(value) => { handleSaveData(value, 'deal_probability') }}
                                                                                onValidate={value => {
                                                                                     if (value) {
                                                                                          var x = parseFloat(value);
                                                                                          if (isNaN(value)) {
                                                                                               setPriorityErrorMsg('Please enter numeric value only.');
                                                                                               return false
                                                                                          } else if (value.length > 6) {
                                                                                               setPriorityErrorMsg('Allow 6 characters only.');
                                                                                               return false
                                                                                          } else if (isNaN(x) || x < 0 || x > 100) {
                                                                                               setPriorityErrorMsg('Enter value is out of range, max allow (100%)');
                                                                                               return false
                                                                                          }
                                                                                          return true
                                                                                     }
                                                                                }}
                                                                                validationMessage={priorityErrorMsg}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                attributes={{ maxLength: 50 }}
                                                                                placeholder='----'
                                                                                instructions=""
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick()}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.deal_probability}</span>
                                                                 </div>

                                                                 <div className="col-md-12 circletrick description-input position-relative">
                                                                      <Form.Group className="mb-2 display align-items-start">
                                                                           <Form.Label className="text-right w-17 mt-1">Description</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXTAREA}
                                                                                value={dealRecordData.deal_description}
                                                                                onSave={(value) => { handleSaveData(value, 'deal_description') }}
                                                                                onValidate={value => {
                                                                                     if (value) {
                                                                                          var splString = value.split(' ');
                                                                                          if (value && value.length>150) {
                                                                                               setDealDescriptionErrorMsg('Allow 150 characters only.')
                                                                                               return false    
                                                                                          }else if(value && value.length<2){
                                                                                               setDealDescriptionErrorMsg('Enter at least 2 characters.');
                                                                                               return false
                                                                                          }else if(((value && value.length==2) && (value && splString.length>=2))){
                                                                                               setDealDescriptionErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                               return false
                                                                                          } else if(value.replace(/\s+/g, '').length <2) {
                                                                                               setDealDescriptionErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                               return false
                                                                                          }
                                                                                          return true
                                                                                     }else{
                                                                                          setDealDescriptionErrorMsg('Enter deal description');
                                                                                          return false
                                                                                     }
                                                                                }}
                                                                                validationMessage={dealDescriptionErrorMsg}
                                                                                cssClassPrefix="custom-textarea-"
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                attributes={{ maxLength: 150 }}
                                                                                placeholder='----'
                                                                                instructions=""
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick()}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.deal_description}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                      {
                                                                           displayStageName.name === 'Closed Lost' ?
                                                                                <Form.Group className="mb-2 display align-items-start">
                                                                                     <Form.Label className="text-right w-17">Lost Reason</Form.Label>
                                                                                     <div className="fontdisabled display flex-wrap">
                                                                                          <p className="disabledrecord">{dealRecordData ? dealRecordData.note ? dealRecordData.note : ''  : ''}</p>
                                                                                     </div>
                                                                                </Form.Group>
                                                                           :
                                                                           ''
                                                                      }
                                                                          
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display align-items-start">
                                                                           <Form.Label className="text-right w-17">Created by</Form.Label>
                                                                           <div className="fontdisabled display flex-wrap">
                                                                                <p className="disabledrecord">{dealRecordData? dealRecordData.UserName? dealRecordData.UserName ? dealRecordData.UserName.name : '' : '':''}</p>
                                                                                <p className="fontsbreak">{moment.tz(dealRecordData.created_at, defaultTimeZone).format(preferredDateFormatData)}</p>
                                                                           </div>
                                                                      </Form.Group>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </Accordion.Body>
                                        </Accordion.Item>
                                   </Accordion>
                              </div>
                         </Col>

                         <Col md={12} className="mt30">
                              <div className="row align-items-start">
                                   <NoteList data={{ 'type': 'deal', 'dealId': dealID, 'preferredDateFormatData': preferredDateFormatData }} />
                                   {
                                        userPermission ?
                                                  (userPermission.includes('event_list','task_list')) ?
                                                  <ActivityList data={{ 'type': 'deal', 'dealId': dealID, 'preferredDateFormatData': preferredDateFormatData, 'preDateFormate': preDateFormate, 'calendorDateFormate': calendorDateFormate, 'calendorFullDateFormate': calendorFullDateFormate }} />
                                                  :
                                                  ''
                                        :
                                        ''
                                   }
                              </div>
                         </Col>
                         {
                              dealID ?
                                   userPermission ?
                                        (userPermission.includes('contact_list')) ?
                                             <DealContactList data={{ 'dealid': dealID }} handleChieldResponce={handleChieldResponce} />
                                        :
                                        ''
                                   :
                                   ''
                              :
                              null
                         }

                         {
                              dealID ?
                                   userPermission ?
                                        (userPermission.includes('quote_list')) ?
                                             <DealQuoteList data={{ 'dealid': dealID }} />
                                        :
                                        ''
                                   :
                                   ''
                              :
                              null
                         }
                    </div>

                    <Modal as={Modal.Dialog} centered show={showWonStatus} onHide={handleChangeWonStatusClose}>
                         <Modal.Header>
                              <Modal.Title className="h2">Closed Won</Modal.Title>
                              <Button variant="close" aria-label="Close" onClick={handleChangeWonStatusClose} /><span className="modelclose"></span>
                         </Modal.Header>
                         <Modal.Body>
                              <Form encrypted="multipart/form-data" onSubmit={dealWonSubmit} >
                                   {/* <Row> */}
                                   <div className="col-md-12 col-sm-12">
                                        <Row className="align-items-center">
                                             <Form.Group className="mb-125">
                                                  <Form.Label column sm="12">
                                                       <h6 className="fw600">Amount -  {preferredCurrencyData.currencyCode != '' ? ' ' + preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                            ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                                                            <span className="text-danger">*</span></h6>
                                                  </Form.Label>
                                                  <Col sm="12">
                                                       <Form.Group id="deal_amount">
                                                            <CurrencyInput
                                                                 id="deal_amount"
                                                                 name="deal_amount"

                                                                 intlConfig={{
                                                                      locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                                                                      currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                                                                 }}
                                                                 placeholder="Enter deal amount"
                                                                 className={`form-control`}
                                                                 onValueChange={handleWonAmountOnValueChange}
                                                                 decimalsLimit={6}
                                                                 value={dealWon.deal_amount}
                                                                 ref={wonAmountRef}
                                                                 step={1}

                                                            />
                                                       </Form.Group>
                                                       <span className="error">{dealWon.error_list.deal_amount}</span>
                                                       {formWonAmountError ? <span className="error">Enter deal amount.</span> : null}
                                                  </Col>
                                             </Form.Group>
                                             {/*--- third_input ---*/}
                                             <Form.Group className="mb-125">
                                                  <Form.Label column sm="12">
                                                       <h6 className="fw600">Closing Date<span className="text-danger">*</span></h6>
                                                  </Form.Label>
                                                  <Col sm="12">
                                                       <Form.Group id="closing_date">
                                                            <DatePicker
                                                                 autocomplete="off"
                                                                 minDate={dealRecord && dealRecord.dealData && dealRecord.dealData.created_at ? new Date(dealRecord && dealRecord.dealData && dealRecord.dealData.created_at):''}
                                                                 selected={wonClosingDate ? wonClosingDate : ''}
                                                                 onChange={(date) => setWonClosingDate(date)}
                                                                 //showTimeSelect
                                                                 //timeFormat="HH:mm"
                                                                 //timeIntervals={5}
                                                                 //timeCaption="time"
                                                                 dateFormat={displayDateFormate}
                                                                 className="form-control"
                                                            />
                                                       </Form.Group>
                                                       <span className="error">{dealWon.error_list.closing_date}</span>
                                                       {formWonClosingDateError ? <span className="error">Enter closing date.</span> : null}
                                                  </Col>
                                             </Form.Group>

                                             <Form.Group>
                                                  <Form.Label column sm="12">
                                                       <h6 className="fw600">Stage</h6>
                                                  </Form.Label>
                                                  <Col sm="12">
                                                       <Form.Group id="closing_date">
                                                            <Select
                                                                 defaultValue={selectedStage}
                                                                 name="stage_id"
                                                                 isDisabled={true}
                                                                 options={stageLists}
                                                                 className="basic-multi-select mr20"
                                                                 classNamePrefix="select"
                                                                 //onChange={handleSelectStageSave}
                                                                 // styles={customStyles1}
                                                                 theme={(theme) => ({
                                                                      ...theme,
                                                                      borderRadius: 0,
                                                                      colors: {
                                                                           ...theme.colors,
                                                                           primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                                                      },
                                                                 })}
                                                                 isClearable={false}
                                                                 isSearchable={false}
                                                            />
                                                       </Form.Group>
                                                       <span className="error">{dealWon.error_list.stage_id}</span>
                                                  </Col>
                                             </Form.Group>
                                        </Row>
                                   </div>


                                   <div className="text-left mt30">
                                        {
                                             submitWonClose ?

                                                  <Button variant="primary" className="d-flex align-items-center mb-1 text-center popupbtn btnblue">
                                                       <Spinner animation="border" size="sm" />
                                                       <span className="ms-2">Please Wait...</span>
                                                  </Button>

                                                  :

                                                  <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                       <div className="btn-group mr-2" role="group" aria-label="First group">
                                                            <Button type="submit" variant="success" className="popupbtn btnblue mr15">Submit</Button>
                                                       </div>
                                                       <div className="btn-group mr-2" role="group" aria-label="First group">
                                                            <Button type="button" variant="success" className="popupbtn greycolor" onClick={handleChangeWonStatusClose}>Cancel</Button>
                                                       </div>
                                                  </div>
                                        }
                                   </div>
                              </Form>
                         </Modal.Body>
                    </Modal>

                    <Modal as={Modal.Dialog} centered show={showLostStatus} onHide={handleChangeLostStatusClose}>
                         <Modal.Header>
                              <Modal.Title className="h2">Closed Lost</Modal.Title>
                              <Button variant="close" aria-label="Close" onClick={handleChangeLostStatusClose} /><span className="modelclose"></span>
                         </Modal.Header>
                         <Modal.Body>
                              <Form encrypted="multipart/form-data" onSubmit={dealLostSubmit} >
                                   {/* <Row> */}
                                   <div className="col-md-12 col-sm-12">
                                        <Row className="align-items-center">
                                             <Form.Group className="mb-125">
                                                  <Form.Label column sm="12">
                                                       <h6 className="fw600">Amount -  {preferredCurrencyData.currencyCode != '' ? ' ' + preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                            ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                                                            <span className="text-danger">*</span></h6>
                                                  </Form.Label>
                                                  <Col sm="12">
                                                       <Form.Group id="deal_amount">
                                                            <CurrencyInput
                                                                 id="deal_amount"
                                                                 name="deal_amount"

                                                                 intlConfig={{
                                                                      locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                                                                      currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                                                                 }}
                                                                 placeholder="Enter deal amount"
                                                                 className={`form-control`}
                                                                 onValueChange={handleWonAmountOnValueChange}
                                                                 decimalsLimit={6}
                                                                 value={dealWon.deal_amount}
                                                                 ref={wonAmountRef}
                                                                 step={1}

                                                            />
                                                       </Form.Group>
                                                       <span className="error">{dealWon.error_list.deal_amount}</span>
                                                       {formWonAmountError ? <span className="error">Enter deal amount.</span> : null}
                                                  </Col>
                                             </Form.Group>
                                             {/*--- third_input ---*/}
                                             <Form.Group className="mb-125">
                                                  <Form.Label column sm="12">
                                                       <h6 className="fw600">Closing Date<span className="text-danger">*</span></h6>
                                                  </Form.Label>
                                                  <Col sm="12">
                                                       <Form.Group id="closing_date">
                                                            <DatePicker
                                                                 autocomplete="off"
                                                                 //minDate={moment().toDate()}
                                                                 minDate={dealRecord && dealRecord.dealData && dealRecord.dealData.created_at ? new Date(dealRecord && dealRecord.dealData && dealRecord.dealData.created_at):''}
                                                                 selected={wonClosingDate == "" ? new Date() : wonClosingDate}
                                                                 onChange={(date) => setWonClosingDate(date)}
                                                                 //showTimeSelect
                                                                 //timeFormat="HH:mm"
                                                                 //timeIntervals={5}
                                                                 //timeCaption="time"
                                                                 dateFormat={displayDateFormate}
                                                                 className="form-control"
                                                            />
                                                       </Form.Group>
                                                       <span className="error">{dealWon.error_list.closing_date}</span>
                                                       {formWonClosingDateError ? <span className="error">Enter deal closing date.</span> : null}
                                                  </Col>
                                             </Form.Group>
                                             {/*--- four_input */}
                                             <Form.Group className="mb-125">
                                                  <Form.Label column sm="12">
                                                       <h6 className="fw600">Stage</h6>
                                                  </Form.Label>
                                                  <Col sm="12">
                                                       <Form.Group id="closing_date">
                                                            <Select
                                                                 defaultValue={selectedStage}
                                                                 name="stage_id"
                                                                 isDisabled={true}
                                                                 options={stageLists}
                                                                 className="basic-multi-select mr20"
                                                                 classNamePrefix="select"
                                                                 //onChange={handleSelectStageSave}
                                                                 // styles={customStyles1}
                                                                 theme={(theme) => ({
                                                                      ...theme,
                                                                      borderRadius: 0,
                                                                      colors: {
                                                                           ...theme.colors,
                                                                           primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                                                      },
                                                                 })}
                                                                 isClearable={false}
                                                                 isSearchable={false}
                                                            />
                                                       </Form.Group>
                                                       <span className="error">{dealWon.error_list.closing_date}</span>
                                                       {formWonClosingDateError ? <span className="error">Select deal stage.</span> : null}
                                                  </Col>
                                             </Form.Group>
                                             <Form.Group>
                                                  <Form.Label column sm="12">
                                                       <h6 className="fw600">Reason for Lost</h6>
                                                  </Form.Label>
                                                  <Col sm="12">
                                                       <Form.Group id="note">
                                                            <Form.Control as="textarea" rows="2" name="note" onChange={handleWonDealInput} value={dealWon.note} placeholder="Write your reason" />
                                                       </Form.Group>
                                                       <span className="error">{dealWon.error_list.note}</span>
                                                  </Col>
                                             </Form.Group>
                                        </Row>
                                   </div>


                                   <div className="text-left mt30">
                                        {
                                             submitLostClose ?

                                                  <Button variant="primary" className="d-flex popupbtn btnblue align-items-center mb-1 text-center">
                                                       <Spinner animation="border" size="sm" />
                                                       <span className="ms-2">Please Wait...</span>
                                                  </Button>

                                                  :

                                                  <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                       <div className="btn-group mr-2" role="group" aria-label="First group">
                                                            <Button type="submit" variant="success" className="popupbtn btnblue mr15">Submit</Button>
                                                       </div>
                                                       <div className="btn-group mr-2" role="group" aria-label="First group">
                                                            <Button type="button" variant="success" className="popupbtn greycolor" onClick={handleChangeLostStatusClose}>Cancel</Button>
                                                       </div>
                                                  </div>
                                        }
                                   </div>
                              </Form>
                         </Modal.Body>
                    </Modal>
               </>
          );



     }


};

export default EditDeal;
