/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Spinner, Modal} from '@themesberg/react-bootstrap';
import { useDispatch,useSelector } from "react-redux";
import { DealAddPopUp } from "../features/userSlice"
import {  toast } from 'react-toastify';
import { useHistory, } from 'react-router-dom';
import Select from 'react-select'
import CurrencyInput from 'react-currency-input-field';
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from 'axios';

var getIpResponse = [];
export default (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [leadSourceData, setleadSourceData] = useState([]);
  const [salutationData, setSalutationData] = useState([]);
  //const [selectedYear, setSelectedYear] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(null);

  const [preferredCurrencyData, setPreferredCurrencyData] = useState(props.data[0].preferredCurrencyData);
  
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const showAddDeal = useSelector((state) => state.dealAddPopUp);
  const handleLeadClose = () => dispatch(DealAddPopUp(false));

  const [defaultValue, setdefaultValue] = useState({
      salutation: '',
      firstname: '',
      lastname: '',
      deal_name: '',
      organization_name: '',
      organization_email: '',
      lead_source: '',
      phone: '',
      deal_amount: '',
      deal_description: '',
      deal_probability: '',
      error_list: [],
  })

  useEffect(() => {
    if(showAddDeal){
      async function fetchData() {
        await getLeadSource();
      }
      fetchData();
    }
    
  }, []); 

  async function getLeadSource() {
    await axios.get('api/deal/create').then(response => {
      if (response.status === 200) {
        setleadSourceData(response.data.data.leadSources)
        setSalutationData(response.data.data.salutationArray);
        setdefaultValue({ ...defaultValue, salutation: '', firstname: '', lastname: '', title: '', organization_name:'', lead_source:'', phone:'', organization_email:'', deal_amount:'', deal_description:'', deal_probability:'', error_list: [] });
      }
    }).then(json => {

    }).catch(error => {
      //setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          //swal("warning", error.response.data.message, "warning");
          toast.warning(error.response.data.message);
        } else if (error.response.status === 300) {
          //swal("error", error.response.data.message, "error");
          toast.error(error.response.data.message);
        }else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else {
         // swal("error", 'Opps something went wrong!', "error");
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }

  async function getLocation(){
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } else {
      //console.log("Not Available");
    }

    await fetch(`https://ipapi.co/json/`)
    .then(response => response.json())
    .then(data =>{
        getIpResponse = data;
      }
    )
  }

  let leadSourceList = [];
  leadSourceData.map((item) => {
    leadSourceList.push({ label: item.title, value: item.title });
  }, this);
  
  let salutationList = [{ label: 'Select...', value: '' }];
  Object.keys(salutationData).map(function (key) {
    salutationList.push({label:salutationData[key],value:key});
  }, this);

  let SaldefaultValue = [];
    const customStyles = {
      menuList: styles => ({
        ...styles,
        //color:'black',
        // backgroundcolor:'$blue',
        paddingTop: 0,
        paddingBottom: 0,
    }),
  }

  const lengthValidation = (fieldLength) => {
    var valid = true;
    if(fieldLength){
      var str = fieldLength.replace(/\s/g,'')
      if(str.length<2){
          valid = false;
      }
    }
    
    return valid ? {
      isValid: true,
    } : {
      isValid: false,
      errorMessage: 'Must be at least 2 characters, no space allow',
    }
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  
  const validationSchema = yup.object().shape({
    firstname:yup.string().required('Enter first name').trim('First name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
    lastname:yup.string().required('Enter last name').trim('Last name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
    organization_name:yup.string().required('Enter company name').trim('Company name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
    organization_email:yup.string().email('Please enter valid email address').required('Enter company email').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
    phone:yup.string().max(25,'Phone must be at most 25 characters'),
    deal_name:yup.string().required('Enter deal name').trim('Deal name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
    deal_amount:yup.string().required('Enter deal amount'),
    deal_description:yup.string().required('Enter deal description').min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters')
      .test('validator-custom-length', function (value) {
        const validation = lengthValidation(value);
        if (!validation.isValid) {
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        }
        else {
          return true;
        }
      }),
    deal_probability:yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter deal probability').positive().max('100', "Please enter less than 100%")
  })

  const handleLeadSourceInput = (e) =>{
    setdefaultValue({ ...defaultValue, ['lead_source']: e.value });
  }

  const handleSalutationInput = (e) =>{
    setdefaultValue({ ...defaultValue, ['salutation']: e.value });
  }
 

  const handleSalutationChange = (selectedSalutation, values) => {
    //setSelectedYear(selectedSalutation);
  };

  const handleOnValueChange = (value) => {
    setdefaultValue({ ...defaultValue, ['deal_amount']: value });
  };

  const handleSubmit = async (values,onSubmitProps) =>{
    const data = {
      salutation: values.salutation,
      first_name: values.firstname,
      last_name: values.lastname,
      title: values.deal_name,
      organization_name: values.organization_name,
      organization_email: values.organization_email,
      lead_source: defaultValue.lead_source,
      phone1: values.phone,
      deal_amount: values.deal_amount,
      deal_description: values.deal_description,
      deal_probability: values.deal_probability,
      latitude: getIpResponse.latitude ? getIpResponse.latitude : latitude,
      longitude: getIpResponse.longitude ? getIpResponse.longitude : longitude,
    }
    
    
    await axios.post("/api/deal/store", data).then(response => {
      if (response.status === 200) {
        dispatch(DealAddPopUp(false))
        toast.success('Deal has been created successfully');
        history.push('/deals');
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 400) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        } else if(error.response.status === 403){
          toast.warning('Forbidden '+error.response.data.message);
          history.push('/deals');    
        } else {
          //swal("warning", error.response.data.message, "warning");
          toast.warning(error.response.data.message);
        }
      }

    });

  }
  
    return (
      <Modal as={Modal.Dialog} centered show={showAddDeal} onHide={handleLeadClose}>
              <Modal.Header>
                <Modal.Title className="h2">Add Deal</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleLeadClose} /><span className="modelclose"></span>
              </Modal.Header>
              <Modal.Body>
                <Formik 
                    initialValues={defaultValue} 
                    validationSchema={validationSchema} 
                    // onSubmit={(values, { setSubmitting }) => {
                    //   handleSubmit(values, setSubmitting(false));
                    // }}
                    onSubmit={handleSubmit}
                  >{props => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                      setFieldValue,
                      setFieldTouched,
                    } = props;
                    return (
                <Form encrypted="multipart/form-data" onSubmit={handleSubmit} >
                <Row>
                    <div className="col-md-12 col-sm-12">
                      <Row className="align-items-center">
                        <Form.Group className="mb-125 firstrow position-relative">
                          
                          <Col sm="12" className="Display">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Salutation</h6>
                          </Form.Label>
                            <Form.Group id="salutation">
                            <Select
                              defaultValue={SaldefaultValue}
                              name="salutation"
                              options={salutationList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                             // onChange={handleSalutationInput}
                             //onChange={setFieldValue}
                              styles={customStyles}
                             onChange={selectedOption => {
                              handleSalutationChange(selectedOption);
                              // handleYearChange(selectedOption, values);
                              values.salutation = selectedOption.value;
                              handleChange("salutation");
                            }}
                            isClearable={false}
                            isSearchable={false}
                             onBlur={setFieldTouched}
                             theme={(theme) => ({
                                             ...theme,
                                             borderRadius: 0,
                                             colors: {
                                                  ...theme.colors,
                                                  primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                             },
                                        })}
                            />
                              <span className="error error4">{defaultValue.error_list.salutation}</span>
                              <span className="error error4">
                                <ErrorMessage name="salutation"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">First name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="name">
                              <Form.Control type="text" name="firstname" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter firstname" className={errors.firstname && touched.firstname ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.first_name}</span>
                              <span className="error error4">
                                <ErrorMessage name="firstname"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Last name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="name">
                              <Form.Control type="text" name="lastname"  minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter lastname" className={errors.lastname && touched.lastname ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.last_name}</span>
                              <span className="error error4">
                                <ErrorMessage name="lastname"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Deal name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="title">
                              <Form.Control type="text" name="deal_name"  minLength={2} maxLength={55} onChange={handleChange} onBlur={handleBlur} placeholder="Enter deal name" className={errors.deal_name && touched.deal_name ? 
                    "input-error" : null} />
                              <span className="error error4">{defaultValue.error_list.title}</span>
                              <span className="error error4">
                                <ErrorMessage name="deal_name"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Company name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="organization_name">
                              <Form.Control type="text" name="organization_name"  minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur}  placeholder="XYZ Company Ltd" className={errors.organization_name && touched.organization_name ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.organization_name}</span>
                              <span className="error error4">
                                <ErrorMessage name="organization_name"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Company email<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="organization_email">
                              <Form.Control type="text" name="organization_email"  minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur}  placeholder="Enter Company email" className={errors.organization_email && touched.organization_email ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.organization_email}</span>
                              <span className="error error4">
                                <ErrorMessage name="organization_email"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Source</h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="lead_source">
                            <Select
                              //defaultValue={selectedStage}
                              name="lead_source"
                              options={leadSourceList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={handleLeadSourceInput}
                              onBlur={handleBlur}
                              styles={customStyles}
                              theme={(theme) => ({
                                             ...theme,
                                             borderRadius: 0,
                                             colors: {
                                                  ...theme.colors,
                                                  primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                             },
                                        })}
                            />
                            <span className="error error4">{defaultValue.error_list.lead_source}</span>
                              <span className="error error4">
                                <ErrorMessage name="lead_source"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Phone</h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="phone">
                              <Form.Control type="text" name="phone"  maxLength={25} onChange={handleChange} onBlur={handleBlur} placeholder="99999 12345" />
                              <span className="error error4">{defaultValue.error_list.phone1}</span>
                              <span className="error error4">
                                <ErrorMessage name="phone"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        <Form.Group className="mb-125 position-relative ">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Amount {preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode+' ' : ' '} 
                          ({preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol:''})<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="deal_amount">
                              {/* <Form.Control type="text" name="deal_amount" onChange={handleChange} onBlur={handleBlur} placeholder="Enter amount" /> */}
                              <CurrencyInput
                                id="deal_amount"
                                name="deal_amount"
                                intlConfig={{
                                  locale: preferredCurrencyData.currencyStringValue ?  preferredCurrencyData.currencyStringValue:'en-IN',
                                  currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode:'INR',
                                }}
                                placeholder="Enter deal amount"
                                className={errors.deal_amount && touched.deal_amount ? 
                                  "form-control input-error" : 'form-control'}
                                //onValueChange={handleOnValueChange}
                                onValueChange={(value, name) => {
                                    //removeStyleButtonOption()
                                    setdefaultValue({ ...defaultValue, ['deal_amount']: value });
                                    values.deal_amount = value;
                                    handleChange(name)
                                }}
                                decimalsLimit={6}
                                value={defaultValue.deal_amount}
                                step={1}
                              />
                              <span className="error error4">{defaultValue.error_list.deal_amount}</span>
                              <span className="error error4">
                                <ErrorMessage name="deal_amount"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Probability (%)<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="deal_probability">
                              <Form.Control type="number" max={100} step={0.01} name="deal_probability" onChange={handleChange} onBlur={handleBlur} placeholder="Enter probability" className={errors.deal_probability && touched.deal_probability ? 
                    "proability errormessage input-error" : 'proability errormessage'}/>
                              <span className="error error4">{defaultValue.error_list.deal_probability}</span>
                              <span className="percetangeicon"></span>
                              <span className="error error4">
                                <ErrorMessage name="deal_probability"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        <Form.Group className="position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Description<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="deal_description">
                              <Form.Control as="textarea"  minLength={2} maxLength={150}  name="deal_description" onChange={handleChange} onBlur={handleBlur} placeholder="Enter description" className={errors.deal_description && touched.deal_description ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.deal_description}</span>
                              <span className="error error4">
                                <ErrorMessage name="deal_description"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                      </Row>
                    </div>
                  </Row>
                  
                 <div className="text-center mt30">
                  {
                    isSubmitting ? 

                      <Button className="d-flex align-items-center popupbtn btnblue justify-content-center text-center btnblue mb-1">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2 text-center">Please wait...</span>
                      </Button>
                      :

                      <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                        </div>
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="button" variant="success" onClick={handleLeadClose} className="popupbtn greycolor d-flex align-items-center justify-content-center">Cancel</Button>
                        </div>
                      </div>
                  }
                </div>
               </Form>
                );
              }}
                </Formik>
              </Modal.Body>

      </Modal>
    );


};
