import React, { useState, useEffect } from "react";
import { Col, Row, Form, Card, Button, Spinner, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link} from 'react-router-dom';
import { Routes } from "../../routes";
import {  toast } from 'react-toastify';
import { DisplayHostName } from "../../features/userSlice";
import { useSelector } from "react-redux";
//import logo from "../../assets/img/color_main_logo.png"
import axios from 'axios';
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

function ForgotPassword() {
  let logoUrl = ''

  //get default host data from redux using url
  var defaultHostData = useSelector(DisplayHostName);
  var displayHost = defaultHostData?.displayHost||''
  
  if(displayHost){
    logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/`+displayHost+`.png`;
  }
  
  const checkFileExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if(response.status === 200){
       logoUrl = logoUrl;
      }
    } catch (error) {
      logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/app.png`; 
    }
  }
  
  useEffect(() => {
    async function fetchData() {
      await checkFileExistence(logoUrl);
    }
    fetchData();
  },[displayHost]);

  const [backMessage, setBackMessage] = useState('');
  const [backErrorMessage, setBackErrorMessage] = useState('');
  const [defaultValue, setDefaultValue] = useState({
    email: '',
    error_list: [],
  })

  const validationSchema = yup.object().shape({
    email:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter your email address').email('Please enter a valid email address'),
  })

  const handleSubmit = async (values,onSubmitProps) =>{
    const data = {
      email: values.email,
    }
    await axios.post("/api/forgot-password", data).then(response => {
      if (response.status === 200) {
        setBackErrorMessage('')
        onSubmitProps.setSubmitting(false)
        onSubmitProps.resetForm({values:''})
        setBackMessage(response.data.message)
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setBackMessage()
        setBackErrorMessage('')
        onSubmitProps.setSubmitting(false)
        onSubmitProps.resetForm({values:''})
        if (error.response.status === 422) {
          toast.warning(error.response.data.message);
          setDefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        }
      }
    });

  }

  return (
    <>
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 common-form">
          <Container>
            <Row className="justify-content-center">
            <div className="title text-center mb40">
              <a href={process.env.REACT_APP_MARKETING_WEB_URL}><img src={logoUrl} className="signlogo" style={{ cursor: 'pointer' }}/></a>
            </div>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white w-100 box-form text-center">
                  <h3 className="mb20">Forgot your Password?</h3>
                  <p className="mb30">Please provide your registered email address and we'll send you a link for reset your password.</p>
                  <Formik 
                    initialValues={defaultValue} 
                    validationSchema={validationSchema} 
                    onSubmit={handleSubmit}
                  >
                    {props => {
                        const {
                          values,
                          touched,
                          errors,
                          //dirty,
                          isSubmitting,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          //handleReset
                        } = props;
                        return (
                            <Form className="position-relative" onSubmit={handleSubmit}>
                              <div className="mb30">
                                <InputGroup id="email">
                                  <Form.Control type="email" value={values.email} name="email" minLength={2} maxLength={50} onChange={e => {
                                    handleChange(e);
                                    setDefaultValue({ ...defaultValue, error_list: ''});
                                    setBackMessage('');
                                    setBackErrorMessage('');
                                  }
                                  } onBlur={handleBlur} placeholder="Email" className={errors.email && touched.email ? 
                              "input-error" : null} />
                                </InputGroup>
                                <p className="text-danger error3">{defaultValue.error_list ? defaultValue.error_list.email:''}</p>
                                <span className="text-danger even">
                                    <p className="text-danger error3">
                                      <ErrorMessage name="email"/>
                                    </p>
                                  </span>
                              </div>
                              {
                                  isSubmitting ? 
                                    <Button variant="primary" className="d-flex align-items-center justify-content-center text-center mb-1 w-100">
                                          <Spinner animation="border" size="sm" />
                                          <span className="ms-2 text-center">Please Wait...</span>
                                    </Button>
                                  :
                                    <Button variant="primary" type="submit" className="w-100 mb-2">
                                      Recover password
                                    </Button>
                              }

                              {
                                backErrorMessage ?
                                  <p className="text-danger forgettxt position-relative">{backErrorMessage}</p>
                                :
                                  ''  
                              }

                              {
                                backMessage ?
                                  <p className="text-success forgettxt position-relative">{backMessage}</p>
                                :
                                  ''  
                              }  
                            </Form>
                  );
                }}
                  </Formik>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-center mt-4 footer-form">
              <span>
                Already have an account?  
                <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                  {` Sign in`}
                </Card.Link>
              </span>
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};
export default ForgotPassword;
