import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Dropdown,
  Form,
  Modal,
} from "@themesberg/react-bootstrap";
import Preloader from "../../components/Preloader1";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import QuoteEmailHistoryList from "../../components/QuoteEmailHistoryList";
import SendQuoteEmail from "../../components/SendQuoteEmail";
import QuoteEditItems from "../../components/QuoteEditItems";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import "react-edit-text/dist/index.css";
import "../../assets/css/custom.css";
import "react-multi-email/style.css";

import { useHistory } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import EasyEdit, { Types } from "react-easy-edit";

var currencyCode = "";
var currencySymbol = "";
const ref = React.createRef();

function EditQuote(props) {
  const history = useHistory();
  const loginUser = useSelector(selectUser);
  const [isEditable, setIsEditable] = useState(true);
  const [showWriteUsDefault, setShowWriteUsDefault] = useState(false);
  const [editQuoteItem, setEditQuoteItem] = useState(false);
  const [emailData, setEmailData] = useState([]);
  
  const user = loginUser?loginUser:[];

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];
  
  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
  
  const quoteID = props.match.params.id;
  const [updateQuoteData, setUpdateQuoteData] = useState(false);

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

  const [subjectErrorMsg, setSubjectErrorMsg] = useState();
  const [dealErrorMsg, setDealErrorMsg] = useState();
  const [accountErrorMsg, setAccountErrorMsg] = useState();
  const [contactErrorMsg, setContactErrorMsg] = useState();

  const [addressErrorMsg, setAddressErrorMsg] = useState();
  const [cityErrorMsg, setcityErrorMsg] = useState();
  const [stateErrorMsg, setStateErrorMsg] = useState();
  const [zipcodeErrorMsg, setZipcodeErrorMsg] = useState();

  const [shippingAddressErrorMsg, setShippingAddressErrorMsg] = useState();
  const [shippingCityErrorMsg, setShippingcityErrorMsg] = useState();
  const [shippingStateErrorMsg, setShippingStateErrorMsg] = useState();
  const [shippingZipcodeErrorMsg, setShippingZipcodeErrorMsg] = useState();

  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState();

  //=========START:CODE FOR EDIT LEAD DATA==========//

  const [loading, setLoading] = useState(false);
  const [dataRecord, setDataRecord] = useState("");
  const [record, setRecord] = useState("");
  const [countries, setCountries] = useState([]);

  const [userList, setUserList] = useState([]);
  const [dealList, setDealList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [quoteStageArrayList, setQuoteStageArrayList] = useState([]);

  const [ErrorInput, setErrorInput] = useState({ error_list: [] });
  const [validUntil, setValidUntil] = useState("");

  //========Const data for Activity (Lead and Deal)
  var calendorFormate = "";

  useEffect(() => {
    async function fetchData() {
      await getDealData();
    }
    fetchData();
  }, [updateQuoteData, editQuoteItem]);

  async function getDealData() {
    setLoading(true);
    await axios
      .get("api/quote/edit/" + quoteID)
      .then((response) => {
        if (response.data.code === 200) {
          //console.log(response.data.data.record,'response---response')
          setDataRecord(response.data.data.record);
          setRecord(response.data.data);

          setUserList(response.data.data.users);
          setDealList(response.data.data.deals);
          setAccountList(response.data.data.accounts);
          setContactList(response.data.data.contacts);
          setCountries(response.data.data.countries);
          setProductList(response.data.data.products);
          setQuoteStageArrayList(response.data.data.quoteStageLists);
          setPreferredCurrencyData(response.data.data.preferredCurrency);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);

          if (response.data.data.record.valid_until) {
            setValidUntil(new Date(response.data.data.record.valid_until));
          }

          if (response.data.data.preferredCurrency.currencyCode) {
            currencyCode = response.data.data.preferredCurrency.currencyCode
            currencySymbol = response.data.data.preferredCurrency.currencySymbol
          }

          setEmailData({fromEmail:response?.data?.data?.fromEmail||'',subject:response?.data?.data?.record?.subject||''})
          
          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy-MM-dd");
              setCalendorFullDateFormate("yyyy-MM-DD");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd-MM-yyyy");
              setCalendorFullDateFormate("DD-MM-yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy-dd-MM");
              setCalendorFullDateFormate("yyyy/DD/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM-dd-yyyy");
              setCalendorFullDateFormate("MM-DD-yyyy");
            }
            setCalendorFullDateFormate(calendorFormate[0]);
          }
        }
        setLoading(false);
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/quotes");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
        history.push("/quotes");
      });
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  const handleSaveData = async (value, name) => {
    const data = {
      id: quoteID,
      subject: dataRecord.subject,
      account_id: dataRecord.account_id,
      valid_until: validUntil
        ? moment(validUntil).format("YYYY-MM-DD HH:mm:ss")
        : "",
      editType: "dataOnly",
      [name]: value,
    };

    if (value) {
      setLoading(true);
      await axios
        .post("api/quote/update", data)
        .then((response) => {
          if (response.status === 200) {
            updateQuoteData
              ? setUpdateQuoteData(false)
              : setUpdateQuoteData(true);
            setErrorInput({ ...ErrorInput, error_list: "" });
            setIsEditable(true);
            setLoading(false);
            setEditQuoteItem(false);
            toast.success("Data updated successfully");
          }
        })
        .then((json) => {})
        .catch((error) => {
          if (error.response) {
            setLoading(false);
            setIsEditable(true);
            if (error.response.status === 400) {
              setErrorInput({
                ...ErrorInput,
                error_list: error.response.data.validation_errors,
              });
            } else if (error.response.status === 300) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error("Oops Something went wrong.");
            }
          }
        });
    } else {
      updateQuoteData ? setUpdateQuoteData(false) : setUpdateQuoteData(true);
      setErrorInput({ ...ErrorInput, error_list: "" });
      setIsEditable(true);
      setLoading(false);
      setEditQuoteItem(false);
    }
  };

  const cancel = () => {
    setIsEditable(true);
    setDescriptionErrorMsg("");
  };

  const handleClick = (e) => {
    setIsEditable(false);
  };

  const customStyles1 = {
    menuList: (styles) => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const CustomSelectDisplay = (props) => {
    const handleSelectCountrySave = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const countrysLists = props.data.countrysLists;
    const selectedCountry = props.data.selectedCountry;
    return (
      <Select
        defaultValue={selectedCountry}
        name="country_id"
        options={countrysLists}
        //className="basic-multi-select leadselect"
        classNamePrefix="select"
        onChange={handleSelectCountrySave}
        // styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#0A8EA0",
            primary: "#0A8EA0",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayCountry = (props) => {
    const val = props;
    return (
      <div>
        {val.value
          ? !isNaN(val.value)
            ? "Please wait..."
            : val.value
          : "Select..."}
      </div>
    );
  };

  const CustomSelectShippingCountyDisplay = (props) => {
    const handleSelectShippingCountrySave = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    
    const countrysLists = props.data.countrysLists;
    const selectedShippingCountry = props.data.selectedShippingCountry;
    return (
      <Select
        defaultValue={selectedShippingCountry}
        name="s_country_id"
        options={countrysLists}
        classNamePrefix="select"
        onChange={handleSelectShippingCountrySave}
        // styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#0A8EA0",
            primary: "#0A8EA0",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayShippingCounty = (props) => {
    const val = props;
    return (
      <div>
        {val.value
          ? !isNaN(val.value)
            ? "Please wait..."
            : val.value
          : "Select..."}
      </div>
    );
  };

  const CustomOwnerSelectDisplay = (props) => {
    const handleSelectOwner = (event) => {
      props.setParentValue(event.value);
    };

    const ownerListArray = props.data.ownerLists;
    const selectedOwner = props.data.selectedOwner;

    return (
      <Select
        defaultValue={selectedOwner}
        name="owner_id"
        options={ownerListArray}
        classNamePrefix="select"
        onChange={handleSelectOwner}
        styles={customStyles1}
        isClearable={false}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#0A8EA0",
            primary: "#0A8EA0",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayOwner = (props) => {
    const val = props;
    return (
      <div>
        {val.value
          ? !isNaN(val.value)
            ? "Please wait..."
            : val.value
          : "Select..."}
      </div>
    );
  };

  const CustomDealSelectDisplay = (props) => {
    const handleSelectDeal = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const dealListArray = props.data.dealLists;
    const selectedDeal = props.data.selectedDeal;

    return (
      <Select
        defaultValue={selectedDeal}
        name="deal_id"
        options={dealListArray}
        classNamePrefix="select"
        onChange={handleSelectDeal}
        styles={customStyles1}
        isClearable={false}
        isSearchable={true}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#0A8EA0",
            primary: "#0A8EA0",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayDeal = (props) => {
    const val = props;
    return (
      <div>
        {val.value
          ? !isNaN(val.value)
            ? "Please wait..."
            : val.value
          : "Select..."}
      </div>
    );
  };

  const CustomContactSelectDisplay = (props) => {
    const handleSelectContact = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const contactListArray = props.data.contactLists;
    const selectedContact = props.data.selectedContact;

    return (
      <Select
        defaultValue={selectedContact}
        name="contact_id"
        options={contactListArray}
        classNamePrefix="select"
        onChange={handleSelectContact}
        styles={customStyles1}
        isClearable={false}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#0A8EA0",
            primary: "#0A8EA0",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayContact = (props) => {
    const val = props;
    return (
      <div>
        {val.value
          ? !isNaN(val.value)
            ? "Please wait..."
            : val.value.charAt(0).toUpperCase() + val.value.slice(1)
          : "Select..."}
      </div>
    );
  };

  const CustomAccountSelectDisplay = (props) => {
    const handleSelectAccount = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const accountListArray = props.data.accountLists;
    const selectedAccount = props.data.selectedAccount;

    return (
      <Select
        defaultValue={selectedAccount}
        name="account_id"
        options={accountListArray}
        classNamePrefix="select"
        onChange={handleSelectAccount}
        styles={customStyles1}
        isClearable={false}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#0A8EA0",
            primary: "#0A8EA0",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayAccount = (props) => {
    const val = props;
    return (
      <div>
        {val.value
          ? !isNaN(val.value)
            ? "Please wait..."
            : val.value
          : "Select..."}
      </div>
    );
  };

  const CustomQuoteStageSelectDisplay = (props) => {
    const handleSelectQuoteStage = (event) => {
      props.setParentValue(event.value);
    };
    setIsEditable(false)
    const quoteStageListArray = props.data.quoteStageLists;
    const selectedQuoteStage = props.data.selectedQuoteStage;

    return (
      <Select
        defaultValue={selectedQuoteStage}
        name="quote_stage"
        options={quoteStageListArray}
        classNamePrefix="select"
        onChange={handleSelectQuoteStage}
        styles={customStyles1}
        isClearable={false}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#0A8EA0",
            primary: "#0A8EA0",
          },
        })}
      />
    );
  };

  const CustomSelectDisplayQuoteStage = (props) => {
    const val = props;
    return (
      <div>
        {val.value
          ? !isNaN(val.value)
            ? "Please wait..."
            : val.value
          : "Select..."}
      </div>
    );
  };

  const CustomValidUntilDateDisplay = (props) => {
    const [startClosingDate, setStartClosingDate] = useState(null);
    setIsEditable(false)
    if (props.value && props.value != null) {
      return (
        <DatePicker
          autocomplete="off"
          //minDate={moment().toDate()}
          selected={validUntil ? validUntil : startClosingDate}
          onChange={(date) => {
            setValidUntil(date);
            //handleValidUntilDateSave(date)
          }}
          dateFormat={calendorDateFormate}
          className="amount_txt"
        />
      );
    } else {
      return (
        <DatePicker
          autocomplete="off"
          //minDate={moment().toDate()}
          selected={validUntil ? validUntil : startClosingDate}
          onChange={(date) => {
            setValidUntil(date);
            //handleValidUntilDateSave(date)
          }}
          dateFormat={calendorDateFormate}
          className="amount_txt"
        />
      );
    }
  };

  const DisplayCustomValidUntil = (props) => {
    const val = props;
    return (
      <DatePicker
        autocomplete="off"
        minDate={moment().toDate()}
        selected={val.value ? val.value : validUntil}
        dateFormat={calendorDateFormate}
        className="amount_txt"
        disabled={true}
      />
    );
  };

  const generatePdfForQuote = async () => {
    setLoading(true);
    await axios
      .get("api/quote-generate/" + quoteID)
      .then((response) => {
        if (response.data.code === 200) {
          if (response.data.data.quoteName && response.data.data.fullPath) {
            setTimeout(() => {
              const linkSource =
                "data:application/pdf;base64, " + response.data.data.fullPath;
              const downloadLink = document.createElement("a");
              //const fileName = "convertedPDFFile.pdf";
              downloadLink.href = linkSource;
              downloadLink.download = response.data.data.quoteName;
              downloadLink.click();
            }, 1000);
          } else {
            toast.warning("Sorry...! filename cannot found.");
          }
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/quotes");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
        history.push("/quotes");
      });
  };

  const clickEditQuoteitem = () => {
    editQuoteItem ? setEditQuoteItem(false) : setEditQuoteItem(true);
  };

  const createClone = () => {
    history.push("/create-clone-quote/" + quoteID);
  };


  const openWriteToUsModal = () => {
    setShowWriteUsDefault(true);
  };

  const closeWriteToUsModal = () => {
    updateQuoteData ? setUpdateQuoteData(false) : setUpdateQuoteData(true);
    setShowWriteUsDefault(false);
  };


  var displayQuoteItems = "";

  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {
    //-----------------For Owner list-----------------------------------
    let selecteOwner = [];
    for (var i = 0, l = userList.length; i < l; i++) {
      if (dataRecord.owner_id === userList[i].id) {
        selecteOwner.push({ label: userList[i].name, value: userList[i].id });
      }
    }

    let ownerLists = [{ label: "Select", value: "" }];
    for (var j = 0, a = userList.length; j < a; j++) {
      ownerLists.push({ label: userList[j].name, value: userList[j].id });
    }

    //-----------------For Deal list-----------------------------------
    let selecteDeal = [];
    for (var k = 0, b = dealList.length; k < b; k++) {
      if (dataRecord.deal_id === dealList[k].id) {
        selecteDeal.push({
          label: dealList[k].deal_name,
          value: dealList[k].id,
        });
      }
    }

    let dealLists = [{ label: "Select", value: "" }];
    for (var m = 0, c = dealList.length; m < c; m++) {
      dealLists.push({ label: dealList[m].deal_name, value: dealList[m].id });
    }

    //-----------------For Account list-----------------------------------
    let selecteAccount = [];
    for (var n = 0, d = accountList.length; n < d; n++) {
      if (dataRecord.account_id === accountList[n].id) {
        selecteAccount.push({
          label: accountList[n].organization_name,
          value: accountList[n].id,
        });
      }
    }

    let accountLists = [{ label: "Select", value: "" }];
    for (var i = 0, l = accountList.length; i < l; i++) {
      accountLists.push({
        label: accountList[i].organization_name,
        value: accountList[i].id,
      });
    }

    //-----------------For Contact list-----------------------------------
    let selecteContact = contactList
      .filter(contact => contact.id === dataRecord.contact_id)
      .map(contact => ({
        label: `${contact.salutation ? contact.salutation + " " : ""}${contact.first_name} ${contact.last_name}`,
        value: contact.id,
      }));

    let contactLists = [
      { label: "Select", value: "" },
      ...contactList.map(contact => ({
        label: `${contact.salutation ? contact.salutation + " " : ""}${contact.first_name} ${contact.last_name}`,
        value: contact.id,
      })),
    ];

    //-----------------For Country list-----------------------------------
    let countrysLists = [
      { label: "Select", value: "" },
      ...countries.map(country => ({
        label: country.country_name,
        value: country.id,
      }))
    ];
    
    let selectedCountry = countries
      .filter(country => dataRecord?.quote_billing?.country_id === country.id)
      .map(country => ({
        label: country.country_name,
        value: country.id,
      }));
    
    let selectedShippingCountry = countries
      .filter(country => dataRecord?.quote_shipping?.country_id === country.id)
      .map(country => ({
        label: country.country_name,
        value: country.id,
      }));

    //------------------For Quote stage list-------------------------------
    let selecteQuoteStage = [];
    Object.keys(quoteStageArrayList).map(function (key) {
      if (dataRecord.quote_stage == key) {
        selecteQuoteStage.push({ label: quoteStageArrayList[key], value: key });
      }
    }, this);

    let quoteStageList = [{ label: "Select", value: "" }];
    Object.keys(quoteStageArrayList).map(function (key) {
      quoteStageList.push({ label: quoteStageArrayList[key], value: key });
    }, this);

    let expName = [];
    let sortName = "";
    let fullTitleName = "";

    if (dataRecord && dataRecord.subject) {
      fullTitleName = dataRecord.subject;
      sortName = dataRecord.subject;
      expName = dataRecord.subject.split(" ");

      if (expName.length == 2 || expName.length > 2) {
        sortName = expName[0].charAt(0) + expName[1].charAt(0);
      } else {
        sortName = expName[0].charAt(0);
      }
    }

    const myObj = {
      style: "currency",
      currency: currencyCode?currencyCode:'INR',
    };

    if (dataRecord.quote_items && dataRecord.quote_items.length) {
      displayQuoteItems = dataRecord.quote_items.map((item, i) => {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>
              <a
                onClick={() => history.push("/edit-product/" + item.product_id)}
              >
                {item?.product?.name || "N/A"}
              </a>
            </td>
            <td>{item.quantity}</td>
            <td>
              {parseFloat(item.amount)
                ? preferredCurrencyData.currencyStringValue
                  ? parseFloat(item.amount)
                      .toLocaleString(
                        preferredCurrencyData.currencyStringValue,
                        myObj
                      )
                      .slice(1)
                  : parseFloat(item.amount).toLocaleString(myObj)
                : "0.00"}
            </td>
            <td>
              {parseFloat(item.list_price) ? preferredCurrencyData.currencyStringValue ? parseFloat(item.list_price).toLocaleString(preferredCurrencyData.currencyStringValue,myObj)
                      .slice(1)
                  : parseFloat(item.list_price).toLocaleString(myObj)
                : "0.00"}
            </td>
            <td>
              {parseFloat(item.tax)
                ? preferredCurrencyData.currencyStringValue
                  ? parseFloat(item.tax)
                      .toLocaleString(
                        preferredCurrencyData.currencyStringValue,
                        myObj
                      )
                      .slice(1)
                  : parseFloat(item.tax).toLocaleString(myObj)
                : "0.00"}
            </td>
            <td>
              {parseFloat(item.total)
                ? preferredCurrencyData.currencyStringValue
                  ? parseFloat(item.total)
                      .toLocaleString(
                        preferredCurrencyData.currencyStringValue,
                        myObj
                      )
                      .slice(1)
                  : parseFloat(item.total).toLocaleString(myObj)
                : "0.00"}
            </td>
          </tr>
        );
      });
    }

    return (
      // <Row>
      <div className="display flex-wrap">
        <Col md={12} className="client contanctlist activity mt30">
          {/*--- client_name_and_convert_lead_section ---*/}
          <div className="client_details display">
            {/*--- client_image_and_deatils_section ---*/}
            <div className="col-md-6 display">
              <div className="client_image display">
                <Stack direction="row" spacing={3}>
                  <Avatar
                    alt={fullTitleName}
                    src={""}
                    sx={{ width: 50, height: 50 }}
                    onError={addDefaultSrc}
                    className="clientimg"
                  >
                    {sortName}
                  </Avatar>
                </Stack>

                <div className="client_name ml20">
                  <div className="display">
                    <h5>{fullTitleName}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 display justify-content-end">
              {/*--- client_update_time_and_date_section ---*/}
              <div className="client_update">
                <div className="client_txt display justify-content-end">
                  <span>Valid Until</span>
                </div>
                <div className="client_date_and_time display justify-content-end">
                  <span>
                    {dataRecord.valid_until
                      ? moment
                          .tz(dataRecord.valid_until, defaultTimeZone)
                          .format(calendorFullDateFormate)
                      : ""}
                  </span>
                </div>
              </div>
              {/*--- converted_button ---*/}

              {/*--- client_action_section ---*/}
              <div className="client_action quote_action text-right">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className=" dropdownborder1 dothover"
                  >
                    <span className="dot"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => generatePdfForQuote()}>
                      Generate PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => openWriteToUsModal()}>
                      Send to Email
                    </Dropdown.Item>
                    {userPermission ? (
                      userPermission.includes("quote_create") ? (
                        <Dropdown.Item onClick={() => createClone()}>
                          Create Clone
                        </Dropdown.Item>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        <div ref={ref} className="w-100">
          <Row>
            <Col md={12} className="mt30">
              <div className="display w-100">
                <Accordion defaultActiveKey="0" className="w-100">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4>Quote Information</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-6 circletrick">
                          <div className="display align-items-start flex-wrap">
                            <div className="col-md-12 circletrick position-relative leadlist">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  Quote Owner
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  onSave={(value) => {
                                    handleSaveData(value, "owner_id");
                                  }}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  editComponent={
                                    <CustomOwnerSelectDisplay
                                      data={{
                                        selectedOwner: selecteOwner,
                                        ownerLists: ownerLists,
                                      }}
                                    />
                                  }
                                  value={
                                    selecteOwner.length > 0
                                      ? selecteOwner[0].label
                                      : ""
                                  }
                                  displayComponent={
                                    <CustomSelectDisplayOwner />
                                  }
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                  placeholder="----"
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.owner_id}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick description">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  Subject
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={dataRecord.subject}
                                  onSave={(value) => {
                                    handleSaveData(value, "subject");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      var splString = value.split(" ");
                                      if (value && value.length > 50) {
                                        setSubjectErrorMsg(
                                          "Only 50 characters are allowed"
                                        );
                                        return false;
                                      } else if (value && value.length < 2) {
                                        setSubjectErrorMsg(
                                          "Enter at least 2 characters."
                                        );
                                        return false;
                                      } else if (
                                        value &&
                                        value.length == 2 &&
                                        value &&
                                        splString.length >= 2
                                      ) {
                                        setSubjectErrorMsg(
                                          "Enter at least 2 characters, space not allow."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      setSubjectErrorMsg("Enter subject");
                                      return false;
                                    }
                                  }}
                                  validationMessage={subjectErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  attributes={{ maxLength: 50 }}
                                  placeholder="----"
                                  instructions=""
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.subject}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative leadlist">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  Quote Stage
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  onSave={(value) => {
                                    handleSaveData(value, "quote_stage");
                                  }}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  editComponent={
                                    <CustomQuoteStageSelectDisplay
                                      data={{
                                        selectedQuoteStage: selecteQuoteStage,
                                        quoteStageLists: quoteStageList,
                                      }}
                                    />
                                  }
                                  value={
                                    selecteQuoteStage.length > 0
                                      ? selecteQuoteStage[0].label
                                      : ""
                                  }
                                  displayComponent={
                                    <CustomSelectDisplayQuoteStage />
                                  }
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                  placeholder="----"
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.quote_stage}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  Valid Until
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  onSave={(value) => {
                                    handleSaveData(
                                      value
                                        ? moment(value).format("YYYY-MM-DD")
                                        : "",
                                      "valid_until"
                                    );
                                  }}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  editComponent={
                                    <CustomValidUntilDateDisplay />
                                  }
                                  value={validUntil ? validUntil : ""}
                                  displayComponent={<DisplayCustomValidUntil />}
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                  placeholder="----"
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.valid_until}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative">
                              <Form.Group className="mb-2 display align-items-start">
                                <Form.Label className="text-right w-17">
                                  Created by
                                </Form.Label>
                                <div className="fontdisabled display flex-wrap">
                                  <p
                                    className="disabledrecord"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {record.selectedData
                                      ? record.selectedData.createdBy[0]
                                        ? record.selectedData.createdBy[0]
                                        : "----"
                                      : "----"}
                                  </p>
                                  <p className="fontsbreak">
                                    {moment
                                      .tz(
                                        dataRecord.created_at,
                                        defaultTimeZone
                                      )
                                      .format(preferredDateFormatData)}
                                  </p>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 circletrick">
                          <div className="display align-items-start flex-wrap">
                            <div className="col-md-12 circletrick">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  Quote Number
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={dataRecord.quote_number}
                                  allowEdit={false}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative leadlist">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  Deal Name
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  onSave={(value) => {
                                    handleSaveData(value, "deal_id");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      return true;
                                    } else {
                                      setDealErrorMsg("Please select deal");
                                      return false;
                                    }
                                  }}
                                  validationMessage={dealErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  editComponent={
                                    <CustomDealSelectDisplay
                                      data={{
                                        selectedDeal: selecteDeal,
                                        dealLists: dealLists,
                                      }}
                                    />
                                  }
                                  value={
                                    selecteDeal.length > 0
                                      ? selecteDeal[0].label
                                      : ""
                                  }
                                  displayComponent={<CustomSelectDisplayDeal />}
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                  placeholder="----"
                                />

                                <span className="error error3">
                                  {ErrorInput.error_list.deal_id}
                                </span>
                              </Form.Group>
                            </div>

                            <div className="col-md-12 circletrick position-relative leadlist">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  Account Name
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  onSave={(value) => {
                                    handleSaveData(value, "account_id");
                                  }}
                                  onValidate={(value) => {
                                    if (value && !isNaN(value)) {
                                      return true;
                                    } else {
                                      setAccountErrorMsg(
                                        "Please select Account"
                                      );
                                      return false;
                                    }
                                  }}
                                  validationMessage={accountErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  editComponent={
                                    <CustomAccountSelectDisplay
                                      data={{
                                        selectedAccount: selecteAccount,
                                        accountLists: accountLists,
                                      }}
                                    />
                                  }
                                  value={
                                    selecteAccount.length > 0
                                      ? selecteAccount[0].label
                                      : ""
                                  }
                                  displayComponent={
                                    <CustomSelectDisplayAccount />
                                  }
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                  placeholder="----"
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.account_id}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative leadheight">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  Contact Name
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  onSave={(value) => {
                                    handleSaveData(value, "contact_id");
                                  }}
                                  onValidate={(value) => {
                                    if (value && !isNaN(value)) {
                                      return true;
                                    } else {
                                      setContactErrorMsg(
                                        "Please select Contact"
                                      );
                                      return false;
                                    }
                                  }}
                                  validationMessage={contactErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  editComponent={
                                    <CustomContactSelectDisplay
                                      data={{
                                        selectedContact: selecteContact,
                                        contactLists: contactLists,
                                      }}
                                    />
                                  }
                                  value={
                                    selecteContact.length > 0
                                      ? selecteContact[0].label
                                      : ""
                                  }
                                  displayComponent={
                                    <CustomSelectDisplayContact />
                                  }
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                  placeholder="----"
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.contact_id}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative">
                              <Form.Group className="mb-2 display align-items-start">
                                <Form.Label className="text-right w-17">
                                  Modified by
                                </Form.Label>
                                <div className="fontdisabled display flex-wrap">
                                  <p
                                    className="disabledrecord"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {record.selectedData
                                      ? record.selectedData.updatedBy[0]
                                        ? record.selectedData.updatedBy[0]
                                        : "----"
                                      : "----"}
                                  </p>
                                  <p className="fontsbreak">
                                    {dataRecord.updated_at
                                      ? moment
                                          .tz(
                                            dataRecord.updated_at,
                                            defaultTimeZone
                                          )
                                          .format(preferredDateFormatData)
                                      : "----"}
                                  </p>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        {/*--- first-row ---*/}
                      </div>
                      <div className="display align-items-center flex-wrap mb25">
                        <div className="col-md-6 circletrick">
                          <h4>Address Information</h4>
                        </div>
                      </div>

                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-6 circletrick">
                          <div className="display align-items-start flex-wrap">
                            <div className="col-md-12 circletrick description">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  billing street
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={
                                    dataRecord.quote_billing
                                      ? dataRecord.quote_billing.address
                                        ? dataRecord.quote_billing.address
                                        : ""
                                      : ""
                                  }
                                  onSave={(value) => {
                                    handleSaveData(value, "address");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      var splString = value.split(" ");
                                      if (value && value.length > 100) {
                                        setAddressErrorMsg(
                                          "Allow 100 characters only."
                                        );
                                        return false;
                                      } else if (value && value.length < 2) {
                                        setAddressErrorMsg(
                                          "Enter at least 2 characters."
                                        );
                                        return false;
                                      } else if (
                                        value &&
                                        value.length == 2 &&
                                        value &&
                                        splString.length >= 2
                                      ) {
                                        setAddressErrorMsg(
                                          "Enter at least 2 characters, space not allow."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  validationMessage={addressErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  instructions=""
                                  attributes={{ maxLength: 100 }}
                                  placeholder="----"
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.address}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative description">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  billing city
                                </Form.Label>

                                <EasyEdit
                                  type={Types.TEXT}
                                  value={
                                    dataRecord.quote_billing
                                      ? dataRecord.quote_billing.city
                                        ? dataRecord.quote_billing.city
                                        : ""
                                      : ""
                                  }
                                  onSave={(value) => {
                                    handleSaveData(value, "city");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      var splString = value.split(" ");
                                      if (value && value.length > 50) {
                                        setcityErrorMsg(
                                          "Only 50 characters are allowed"
                                        );
                                        return false;
                                      } else if (value && value.length < 2) {
                                        setcityErrorMsg(
                                          "Enter at least 2 characters."
                                        );
                                        return false;
                                      } else if (
                                        value &&
                                        value.length == 2 &&
                                        value &&
                                        splString.length >= 2
                                      ) {
                                        setcityErrorMsg(
                                          "Enter at least 2 characters, space not allow."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  validationMessage={cityErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  instructions=""
                                  attributes={{ maxLength: 50 }}
                                  placeholder="----"
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.city}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative description">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  billing state
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={
                                    dataRecord.quote_billing
                                      ? dataRecord.quote_billing.state
                                        ? dataRecord.quote_billing.state
                                        : ""
                                      : ""
                                  }
                                  onSave={(value) => {
                                    handleSaveData(value, "state");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      var splString = value.split(" ");
                                      if (value && value.length > 50) {
                                        setStateErrorMsg(
                                          "Only 50 characters are allowed"
                                        );
                                        return false;
                                      } else if (value && value.length < 2) {
                                        setStateErrorMsg(
                                          "Enter at least 2 characters."
                                        );
                                        return false;
                                      } else if (
                                        value &&
                                        value.length == 2 &&
                                        value &&
                                        splString.length >= 2
                                      ) {
                                        setStateErrorMsg(
                                          "Enter at least 2 characters, space not allow."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  validationMessage={stateErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  instructions=""
                                  attributes={{ maxLength: 50 }}
                                  placeholder="----"
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.state}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  billing code
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={
                                    dataRecord.quote_billing
                                      ? dataRecord.quote_billing.zipcode
                                        ? dataRecord.quote_billing.zipcode
                                        : ""
                                      : ""
                                  }
                                  onSave={(value) => {
                                    handleSaveData(value, "zipcode");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      if (value.length > 10) {
                                        setZipcodeErrorMsg(
                                          "Allow 10 characters only."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  validationMessage={zipcodeErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  attributes={{ maxLength: 10 }}
                                  placeholder="----"
                                  instructions=""
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.zipcode}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative leadlist">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  billing country
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  onSave={(value) => {
                                    handleSaveData(value, "country_id");
                                  }}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  editComponent={
                                    <CustomSelectDisplay
                                      data={{
                                        selectedCountry: selectedCountry,
                                        countrysLists: countrysLists,
                                      }}
                                    />
                                  }
                                  value={
                                    selectedCountry.length > 0
                                      ? selectedCountry[0].label
                                      : ""
                                  }
                                  displayComponent={
                                    <CustomSelectDisplayCountry />
                                  }
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                  placeholder="----"
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.name}
                                </span>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 circletrick">
                          <div className="display align-items-start flex-wrap">
                            <div className="col-md-12 circletrick description">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  shipping street
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={
                                    dataRecord.quote_shipping
                                      ? dataRecord.quote_shipping.address
                                        ? dataRecord.quote_shipping.address
                                        : ""
                                      : ""
                                  }
                                  onSave={(value) => {
                                    handleSaveData(value, "s_address");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      var splString = value.split(" ");
                                      if (value && value.length > 100) {
                                        setShippingAddressErrorMsg(
                                          "Allow 100 characters only."
                                        );
                                        return false;
                                      } else if (value && value.length < 2) {
                                        setShippingAddressErrorMsg(
                                          "Enter at least 2 characters."
                                        );
                                        return false;
                                      } else if (
                                        value &&
                                        value.length == 2 &&
                                        value &&
                                        splString.length >= 2
                                      ) {
                                        setShippingAddressErrorMsg(
                                          "Enter at least 2 characters, space not allow."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  validationMessage={shippingAddressErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  instructions=""
                                  attributes={{ maxLength: 100 }}
                                  placeholder="----"
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.s_address}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick description">
                              <Form.Group className="mb-2 display">
                                <Form.Label className="text-right w-17">
                                  Shippig city
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={
                                    dataRecord.quote_shipping
                                      ? dataRecord.quote_shipping.city
                                        ? dataRecord.quote_shipping.city
                                        : ""
                                      : ""
                                  }
                                  onSave={(value) => {
                                    handleSaveData(value, "s_city");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      var splString = value.split(" ");
                                      if (value && value.length > 50) {
                                        setShippingcityErrorMsg(
                                          "Only 50 characters are allowed"
                                        );
                                        return false;
                                      } else if (value && value.length < 2) {
                                        setShippingcityErrorMsg(
                                          "Enter at least 2 characters."
                                        );
                                        return false;
                                      } else if (
                                        value &&
                                        value.length == 2 &&
                                        value &&
                                        splString.length >= 2
                                      ) {
                                        setShippingcityErrorMsg(
                                          "Enter at least 2 characters, space not allow."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  validationMessage={shippingCityErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  instructions=""
                                  attributes={{ maxLength: 50 }}
                                  placeholder="----"
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.s_city}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative description">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  shipping state
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={
                                    dataRecord.quote_shipping
                                      ? dataRecord.quote_shipping.state
                                        ? dataRecord.quote_shipping.state
                                        : ""
                                      : ""
                                  }
                                  onSave={(value) => {
                                    handleSaveData(value, "s_state");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      var splString = value.split(" ");
                                      if (value && value.length > 50) {
                                        setShippingStateErrorMsg(
                                          "Only 50 characters are allowed"
                                        );
                                        return false;
                                      } else if (value && value.length < 2) {
                                        setShippingStateErrorMsg(
                                          "Enter at least 2 characters."
                                        );
                                        return false;
                                      } else if (
                                        value &&
                                        value.length == 2 &&
                                        value &&
                                        splString.length >= 2
                                      ) {
                                        setShippingStateErrorMsg(
                                          "Enter at least 2 characters, space not allow."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  validationMessage={shippingStateErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  instructions=""
                                  attributes={{ maxLength: 50 }}
                                  placeholder="----"
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.s_state}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  shipping code
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  value={
                                    dataRecord.quote_shipping
                                      ? dataRecord.quote_shipping.zipcode
                                        ? dataRecord.quote_shipping.zipcode
                                        : ""
                                      : ""
                                  }
                                  onSave={(value) => {
                                    handleSaveData(value, "s_zipcode");
                                  }}
                                  onValidate={(value) => {
                                    if (value) {
                                      if (value.length > 10) {
                                        setShippingZipcodeErrorMsg(
                                          "Allow 10 characters only."
                                        );
                                        return false;
                                      }
                                      return true;
                                    } else {
                                      return true;
                                    }
                                  }}
                                  validationMessage={shippingZipcodeErrorMsg}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  attributes={{ maxLength: 10 }}
                                  placeholder="----"
                                  instructions=""
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.s_zipcode}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-md-12 circletrick position-relative leadlist">
                              <Form.Group className="display align-items-start mb-2">
                                <Form.Label className="text-right w-17 mt-1">
                                  shipping country
                                </Form.Label>
                                <EasyEdit
                                  type={Types.TEXT}
                                  onSave={(value) => {
                                    handleSaveData(value, "s_country_id");
                                  }}
                                  onCancel={cancel}
                                  saveButtonLabel={
                                    <FontAwesomeIcon icon={faCheck} />
                                  }
                                  cancelButtonLabel={
                                    <FontAwesomeIcon icon={faTimes} />
                                  }
                                  editComponent={
                                    <CustomSelectShippingCountyDisplay
                                      data={{
                                        selectedShippingCountry:
                                          selectedShippingCountry,
                                        countrysLists: countrysLists,
                                      }}
                                    />
                                  }
                                  value={
                                    selectedShippingCountry.length > 0
                                      ? selectedShippingCountry[0].label
                                      : ""
                                  }
                                  displayComponent={
                                    <CustomSelectDisplayShippingCounty />
                                  }
                                  allowEdit={isEditable}
                                  onFocus={() => handleClick()}
                                  placeholder="----"
                                />
                                <span className="error error3">
                                  {ErrorInput.error_list.s_country_id}
                                </span>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        {/*--- first-row ---*/}
                      </div>

                      <div className="display align-items-start flex-wrap flex-wrap w-100 history quotedlist">
                        <div className="innerdealtable w-100">
                          <div className="col-md-10 text-left">
                            <h5>Quoted Items</h5>
                          </div>
                          <div className="col-md-2 text-right quotebtn">
                            <div className="display justify-content-end me-1">
                              <div
                                onClick={() =>
                                  editQuoteItem ? null : clickEditQuoteitem()
                                }
                                className="createbtn btnbluecolor display align-items-center justify-content-center"
                              >
                                Edit Quote
                              </div>
                            </div>
                          </div>
                        </div>

                        {editQuoteItem ? (
                          <QuoteEditItems
                            data={{
                              record: record.record,
                              preferredCurrency: record.preferredCurrency,
                              preferredDateFormat: record.preferredDateFormat,
                              product: record.product,
                              quoteID: quoteID,
                              editQuoteItem: clickEditQuoteitem,
                            }}
                          />
                        ) : (
                          <div className="contanctlistnig">
                            <table>
                              <tbody>
                                <tr>
                                  <th style={{ width: "5%" }}>S.No</th>
                                  <th style={{ width: "30%" }}>Product Name</th>
                                  <th style={{ width: "5%" }}>Quantity</th>
                                  <th style={{ width: "15%" }}>
                                    Amount (
                                    {preferredCurrencyData.currencySymbol != ""
                                      ? preferredCurrencyData.currencySymbol
                                      : ""}
                                    )
                                  </th>
                                  <th style={{ width: "15%" }}>
                                    List Price (
                                    {preferredCurrencyData.currencySymbol != ""
                                      ? preferredCurrencyData.currencySymbol
                                      : ""}
                                    )
                                  </th>
                                  {/* <th style={{ width: "10%" }}>Discount ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})</th> */}
                                  <th style={{ width: "10%" }}>
                                    Tax (
                                    {preferredCurrencyData.currencySymbol != ""
                                      ? preferredCurrencyData.currencySymbol
                                      : ""}
                                    )
                                  </th>
                                  <th
                                    style={{ width: "20%", textAlign: "right" }}
                                  >
                                    Total (
                                    {preferredCurrencyData.currencySymbol != ""
                                      ? preferredCurrencyData.currencySymbol
                                      : ""}
                                    )
                                  </th>
                                </tr>

                                {displayQuoteItems}
                              </tbody>
                            </table>

                            <div className="display quotedtotal mt30">
                              <div className="col-md-9 col-lg-9 col-xxl-10"></div>
                              <div className="col-md-3 col-lg-3 col-xxl-2">
                                <table>
                                  <tbody>
                                    <tr key="subtotal">
                                      <th
                                        style={{
                                          width: "45%",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        SubTotal
                                      </th>
                                      <td
                                        style={{
                                          width: "55%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {parseFloat(dataRecord.sub_total)
                                          ? preferredCurrencyData.currencyStringValue
                                            ? parseFloat(
                                                dataRecord.sub_total
                                              ).toLocaleString(
                                                preferredCurrencyData.currencyStringValue,
                                                myObj
                                              )
                                            : currencySymbol +
                                              parseFloat(
                                                dataRecord.sub_total
                                              ).toLocaleString(myObj)
                                          : currencySymbol + "0.00"}
                                      </td>
                                    </tr>
                                    <tr key="discountType">
                                      <th
                                        style={{
                                          width: "45%",
                                          whiteSpace: "nowrap",
                                        }}
                                        title={
                                          dataRecord.discount_type ===
                                          "percentage"
                                            ? "Percentage"
                                            : "Flat"
                                        }
                                      >
                                        Discount Type{" "}
                                        {dataRecord.discount_type ===
                                        "percentage"
                                          ? " (%)"
                                          : " (Flat)"}
                                      </th>
                                      <td
                                        style={{
                                          width: "55%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {dataRecord.discount_value}
                                      </td>
                                    </tr>
                                    <tr key="discount">
                                      <th
                                        style={{
                                          width: "45%",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Discount
                                      </th>
                                      <td
                                        style={{
                                          width: "55%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {parseFloat(dataRecord.discount)
                                          ? preferredCurrencyData.currencyStringValue
                                            ? parseFloat(
                                                dataRecord.discount
                                              ).toLocaleString(
                                                preferredCurrencyData.currencyStringValue,
                                                myObj
                                              )
                                            : currencySymbol +
                                              parseFloat(
                                                dataRecord.discount
                                              ).toLocaleString(myObj)
                                          : currencySymbol + "0.00"}
                                      </td>
                                    </tr>
                                    <tr key="taxType">
                                      <th
                                        style={{
                                          width: "45%",
                                          whiteSpace: "nowrap",
                                        }}
                                        title={
                                          dataRecord.tax_type === "percentage"
                                            ? "Percentage"
                                            : "Flat"
                                        }
                                      >
                                        Tax Type{" "}
                                        {dataRecord.tax_type === "percentage"
                                          ? " (%)"
                                          : " (Flat)"}
                                      </th>
                                      <td
                                        style={{
                                          width: "55%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {dataRecord.tax_value}
                                      </td>
                                    </tr>
                                    <tr key="tax">
                                      <th
                                        style={{
                                          width: "45%",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Tax
                                      </th>
                                      <td
                                        style={{
                                          width: "55%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {parseFloat(dataRecord.tax)
                                          ? preferredCurrencyData.currencyStringValue
                                            ? parseFloat(
                                                dataRecord.tax
                                              ).toLocaleString(
                                                preferredCurrencyData.currencyStringValue,
                                                myObj
                                              )
                                            : currencySymbol +
                                              parseFloat(
                                                dataRecord.tax
                                              ).toLocaleString(myObj)
                                          : currencySymbol + "0.00"}
                                      </td>
                                    </tr>
                                    <tr key="adjustment">
                                      <th
                                        style={{
                                          width: "45%",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Adjustment
                                      </th>
                                      <td
                                        style={{
                                          width: "55%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {parseFloat(
                                          dataRecord.adjustment_amount
                                        )
                                          ? preferredCurrencyData.currencyStringValue
                                            ? parseFloat(
                                                dataRecord.adjustment_amount
                                              ).toLocaleString(
                                                preferredCurrencyData.currencyStringValue,
                                                myObj
                                              )
                                            : currencySymbol +
                                              parseFloat(
                                                dataRecord.adjustment_amount
                                              ).toLocaleString(myObj)
                                          : currencySymbol + "0.00"}
                                      </td>
                                    </tr>
                                    <tr key="grandTotal">
                                      <th
                                        style={{
                                          width: "45%",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Grand Total
                                      </th>
                                      <td
                                        style={{
                                          width: "55%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {parseFloat(dataRecord.total)
                                          ? preferredCurrencyData.currencyStringValue
                                            ? parseFloat(
                                                dataRecord.total
                                              ).toLocaleString(
                                                preferredCurrencyData.currencyStringValue,
                                                myObj
                                              )
                                            : currencySymbol +
                                              parseFloat(
                                                dataRecord.total
                                              ).toLocaleString(myObj)
                                          : currencySymbol + "0.00"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="display align-items-start flex-wrap mb25 terms">
                        <div className="col-md-3 circletrick">
                          <h4>Terms and Conditions</h4>
                        </div>
                        <div className="col-md-9 circletrick">
                          <Form.Group className="display align-items-start mb-2">
                            <EasyEdit
                              type={Types.TEXTAREA}
                              value={dataRecord.terms_and_condition}
                              onSave={(value) => {
                                handleSaveData(value, "terms_and_condition");
                              }}
                              onValidate={(value) => {
                                if (value && value.length > 150) {
                                  setDescriptionErrorMsg(
                                    "Allow 150 characters only."
                                  );
                                  return false;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={descriptionErrorMsg}
                              cssClassPrefix="custom-textarea-"
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 150 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick()}
                            />
                            <span className="error error3">
                              {ErrorInput.error_list.terms_and_condition}
                            </span>
                          </Form.Group>
                        </div>

                        {/*--- first-row ---*/}
                      </div>

                      <div className="display align-items-start flex-wrap mb25 terms">
                        <div className="col-md-3 circletrick">
                          <h4>Description Information</h4>
                        </div>
                        <div className="col-md-9 circletrick">
                          <Form.Group className="display align-items-start mb-2">
                            <EasyEdit
                              type={Types.TEXTAREA}
                              value={dataRecord.description}
                              onSave={(value) => {
                                handleSaveData(value, "description");
                              }}
                              onValidate={(value) => {
                                if (value && value.length > 150) {
                                  setDescriptionErrorMsg(
                                    "Allow 150 characters only."
                                  );
                                  return false;
                                } else {
                                  return true;
                                }
                              }}
                              validationMessage={descriptionErrorMsg}
                              cssClassPrefix="custom-textarea-"
                              onCancel={cancel}
                              saveButtonLabel={
                                <FontAwesomeIcon icon={faCheck} />
                              }
                              cancelButtonLabel={
                                <FontAwesomeIcon icon={faTimes} />
                              }
                              attributes={{ maxLength: 150 }}
                              placeholder="----"
                              instructions=""
                              allowEdit={isEditable}
                              onFocus={() => handleClick()}
                            />
                            <span className="error error3">
                              {ErrorInput.error_list.description}
                            </span>
                          </Form.Group>
                        </div>

                        {/*--- first-row ---*/}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>

          {quoteID ? (
            <QuoteEmailHistoryList data={{ quoteID: quoteID }} />
          ) : null}

          {quoteID && showWriteUsDefault && (
            <SendQuoteEmail
              isOpen={showWriteUsDefault}
              onRequestClose={closeWriteToUsModal}
              dataRecord={emailData}
              quoteID={quoteID}
            />
          )}
        </div>
      </div>
    );
  }
}

export default EditQuote;
