import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Button, Dropdown, Card, Form, Spinner, Modal } from '@themesberg/react-bootstrap';
import { useHistory, } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/PreloaderCenter";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import moment from "moment-timezone";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Checkbox from "react-custom-checkbox";
import { DealAddPopUp } from "../../features/userSlice"
import { DealExistAccountAddPopUp } from "../../features/userSlice"
//========Component for DnD=========//
import DNDDealList from "../../components/DNDDealList";
import AddDeal from "../../components/AddDeal";
import AddExistAccountDeal from "../../components/AddExistAccountDeal";
import ExportData from "../../components/ExportData";
//=================================//

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
//var currentPage = 1;

var searchData = '';
var searchInputDataName = '';
var searchInputDataEmail = '';
var searchInputDataFromDate = '';
var searchInputDataToDate = '';
var searchInputDataTitle = '';
var searchInputDataStatus = '';
var searchInputDataPhone = '';
var searchInputDataOrganizationName = '';

var currencyCode = '';
var currencySymbol = '';

function DealList(props) {
  
  const searchParams = new URLSearchParams(document.location.search)
  const location = props.history.location;
  const loginUser = useSelector(selectUser);
  const [leadSourceList, setLeadSourceList] = useState([])
  const dispatch = useDispatch();

  const [isCheck, setIsCheck] = useState([]);
  const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
  const [mainChecked, setMainChecked] = useState(false);

  const user = loginUser?loginUser:[];

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [currentPage, setCurrentPage] = useState(searchParams.size != 0 ? searchParams.get('page') ? searchParams.get('page') : 1 : 1)

  const [listView, setListView] = useState(true);
  const [search, setSearch] = useState(searchParams.get('search') ? searchParams.get('search') : '');
  const [statusArray, setStatusArray] = useState('');
  const [lSourceArray, setLSourceArray] = useState(searchParams.get('search_deal_source') ? searchParams.get('search_deal_source') : '');
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

  const [userLists, setUserLists] = useState([]);
  const [ownerUserId, setOwnerUserId] = useState('');
  const [ownerUpdate, setOwnerUpdate] = useState(false);
  const [changeOwnerArray, setChangeOwnerArray] = useState([]);

  const [showChangeOwner, setShowChangeOwner] = useState(false);

  //used for reloading parent page after model was close
  const showAddDeal = useSelector((state) => state.dealAddPopUp);
  const showExistAccountAddDeal = useSelector((state) => state.dealExistAccountAddPopUp);
  
  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [preferredDateFormatData, setPreferredDateFormatData] = useState('');

  const [searchString, setSearchString] = useState(searchParams.get('search') ? searchParams.get('search') : '');

  //==============START: Handle new search filter option==================//

  const handleSearchInput = (e) => {
    setSearchString(e.target.value);
  }

  const manageSearch = async (e) => {
    e.preventDefault();
    setSearch(searchString)
    setCurrentPage(1);
    if (searchString) {
      await props.history.push('/deals?page=' + 1 + '&search=' + searchString + '&search_deal_source=' + lSourceArray + '&sort_field=' + sortColumn + '&sort_order=' + sortOrder + '&per_page=' + perPage)
    } else {
      await props.history.push('/deals')
    }
  }

  const handleSelectLeadSource = async (event) => {
    setLSourceArray(event.value)
    setCurrentPage(1);
    await props.history.push('/deals?page=' + 1 + '&search=' + searchString + '&search_deal_source=' + event.value + '&sort_field=' + sortColumn + '&sort_order=' + sortOrder + '&per_page=' + perPage)
  }
  //==============END: Handle new search filter option==================//

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [listDeal, setDeal] = useState([]);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState('');
  const [state, setData] = useState({
    users: ''
  });

  const columns = ["name", "title", "phone", 'organization_name', "added_by"];

  const SORT_ASC = "asc"
  const SORT_DESC = "desc"
  const [sortColumn, setSortColumn] = useState()

  const handleSort = async (column) => {
    if (column === sortColumn) {
      sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC
      await props.history.push('/deals?page=' + 1 + '&search=' + searchString + '&search_deal_source=' + lSourceArray + '&sort_field=' + column + '&sort_order=' + sortOrder + '&per_page=' + perPage)
    } else {
      setSortColumn(column)
      sortOrder = SORT_DESC
      await props.history.push('/deals?page=' + 1 + '&search=' + searchString + '&search_deal_source=' + lSourceArray + '&sort_field=' + column + '&sort_order=' + sortOrder + '&per_page=' + perPage)
    }
    getData(currentPage,column);
  }

  const handlePerPage = async (Page) => {
    setLoading(true);
    setCurrentPage(1);
    perPage = Page;
    await props.history.push('/deals?page=' + 1 + '&search=' + searchString + '&search_deal_source=' + lSourceArray + '&sort_field=' + sortColumn + '&sort_order=' + sortOrder + '&per_page=' + perPage)
  }
  //=========END:CODE FOR FOOTER PAGINATION===========//

  var calendorFormate = '';
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await getData(searchParams.size != 0 ? searchParams.get('page') ? searchParams.get('page') : 1 : 1);
    }
    fetchData();
  }, [sortOrder, search, perPage, lSourceArray, listView, showAddDeal, showExistAccountAddDeal,location]);

  const getData = async (currentPage,column) => {
    setLSourceArray(searchParams.get('search_deal_source') ? searchParams.get('search_deal_source') : '')
    setSearchString(searchParams.get('search') ? searchParams.get('search') : '');
    const params = {
      search: searchParams.size != 0 ? search : '',
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_status: statusArray,
      search_deal_source: searchParams.size != 0 ? lSourceArray : '',
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      //sort_field: sortColumn,
      sort_field: column ? column : sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,

    }

    await axios.get(`api/deal?page=${currentPage}`, { params }).then(response => {
      if (response.status === 200) {
        setDeal(response.data.data.records.data);
        setdataCount(response.data.data.records.data.length);
        setData({
          users: response.data.data.records
        });

        setUserLists(response.data.data.users)
        setLeadSourceList(response.data.data.leadSources)
        setPreferredCurrencyData(response.data.data.preferredCurrencyData);
        setPreferredDateFormatData(response.data.data.preDateFormat);
        if (response.data.data.preferredCurrencyData.currencyCode) {
          currencyCode = response.data.data.preferredCurrencyData.currencyCode ? response.data.data.preferredCurrencyData.currencyCode : '';
          currencySymbol = response.data.data.preferredCurrencyData.currencySymbol ? response.data.data.preferredCurrencyData.currencySymbol : '';
        }

        calendorFormate = response.data.data.preDateFormat.split(' ');
        if (calendorFormate) {
          setCalendorFullDateFormate(calendorFormate[0])
        }

        setLoading(false);
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
          history.push('/dashboard');
        } else {
          toast.error(error.response.data.message);
          history.push('/dashboard');
        }
      }
    });
  }

  async function deleteData(id) {
    const data = {
      id: id,
    }

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
  
      if (willDelete) {
        try {
          const response = await axios.delete('api/deal/destroy', { data });
          if (response.status === 200) {
            toast.success(response.data.message);
            getData(currentPage);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error in swal: ", error);
    }
  }

  async function bulkDeleteData(ids) {
    const data = {
      id: ids,
    };
  
    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
  
      if (willDelete) {
        try {
          const response = await axios.delete('api/deal/bulk-destroy', { data });
          if (response.status === 200) {
            toast.success(response.data.message);
            getData(currentPage);
            handleCheckAll(false);
            setIsCheckBoxCheck('');
            setIsCheck([]);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning('Forbidden ' + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
            getData(currentPage);
            handleCheckAll(false);
            setIsCheckBoxCheck('');
            setIsCheck([]);
          }
        }
      }
    } catch (error) {
      console.error("Error in swal: ", error);
    }
  }
  

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  }

  //======Convert Lead to Deal Code=========//

  //==== select_mate
  const customStyles = {
    menuList: styles => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: provided => ({
      ...provided,
      bordercolor: "black",
      borderstyle: "solid",
      '&:hover':
      {
        outline: 'none',
        backgroundcolor: 'none',
        color: '#04182C',
      },
      '&:active':
      {
        outline: 'none',
        backgroundcolor: 'none',
        color: '#ffffff',
      },
      '&:visited':
      {
        outline: 'none',
        backgroundcolor: 'none',
        color: '#ffffff',
      },
      '&:selected':
      {
        outline: 'none',
        backgroundcolor: 'none',
        color: '#ffffff',
      },
      '&:default':
      {
        outline: 'none',
        backgroundcolor: 'none',
        color: '#04182C',
      }
    }),
    control: provided => ({
      ...provided,
      border: "1px solid #d1d7e0",
      borderRadius: "10px",
      color: "#04182C",
      '&:hover':
      {
        outline: 'none',
        color: "#d1d7e0",
      },
      '&:focus':
      {
        outline: 'none',
        color: "#d1d7e0",
      },
      '&:active':
      {
        outline: 'none',
        color: "#d1d7e0",
      },
      '&:default':
      {
        outline: 'none',
        color: "#d1d7e0",
      }
    }),
    singleValue: provided => ({
      ...provided,
      border: "0px solid #04182C",
      color: "#04182C",
      '&:hover':
      {
        outline: 'none',
        border: 'none',
        color: "#04182C",
      },
      '&:focus':
      {
        outline: 'none',
        border: 'none',
        color: "#04182C",
      },
      '&:active':
      {
        outline: 'none',
        border: 'none',
        color: "#04182C",
      }
    }),
  }

  //Export data file for logic
  const expParams = {
    search: searchData,
    search_name: searchInputDataName,
    search_email: searchInputDataEmail,
    search_from_date: searchInputDataFromDate,
    search_to_date: searchInputDataToDate,
    search_title: searchInputDataTitle,
    search_status: searchInputDataStatus,
    search_phone: searchInputDataPhone,
    search_organization_name: searchInputDataOrganizationName,
    sort_field: sortColumn,
    sort_order: sortOrder,
    per_page: perPage,
    page: currentPage,
    selectedIds: isCheck?isCheck:[]

  }
  
  const handleView = async () => {
    listView ? setListView(false) : setListView(true);
  }

  const handleChangeOwner = () => {
    if (isCheck && isCheck.length > 0) {
      setChangeOwnerArray(isCheck)
      setShowChangeOwner(true);
    } else {
      toast.warning('Please select at least one record to change ownership.');
    }
  }

  const handleBulkDelete = () => {
    if (isCheck && isCheck.length > 0) {
      bulkDeleteData(isCheck)
    } else {
      toast.warning('Please select at least one record to Delete.');
    }
  }

  const handleLeadChangeOwner = (event) => {
    setOwnerUserId(event.value);
  }

  const handleChangeOwnerClose = () => {
    setShowChangeOwner(false);

    handleCheckAll(false)
    setIsCheckBoxCheck('');
    setIsCheck([])

  }

  const handleChangeOwnerSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('owner_id', ownerUserId)
    for (var i = 0; i < changeOwnerArray.length; i++) {
      formData.append('deal_id[]', changeOwnerArray[i])
    }

    setOwnerUpdate(true)
    await axios.post("api/deal-change-owner", formData).then(response => {
      setOwnerUpdate(false)
      setShowChangeOwner(false);
      getData(currentPage);

      handleCheckAll(false)
      setIsCheckBoxCheck('');
      setIsCheck([])
      setOwnerUserId()
      toast.success('Owner update successfully');
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setOwnerUpdate(false)
        setOwnerUserId()
        if (error.response.status === 400) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warning(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      }
    });
  }

  //============Start:- Handle check all ================//
  const handleCheckbox = (value, id) => {
    if (value) {
      setIsCheck(isCheck => [...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter(x => x !== id));
    }
  }

  const handleCheckAll = (value) => {
    if (value) {
      setMainChecked(true)
    } else {
      setMainChecked(false)
    }

    setIsCheckBoxCheck(value);
    setIsCheck([]);

    if (value) {
      setIsCheckBoxCheck(value);
      setIsCheck(listDeal.map(li => li.id));
    }
  }
  //============End:- Handle check all ================//

  //==========End: Convert lead to deal code===============//


  function addDeal() {
    dispatch(DealAddPopUp(true));
  }

  function addExistAccountDeal() {
    dispatch(DealExistAccountAddPopUp(true))
  }


  //==============For Lead Status Filter
  let statusList = [
    { label: "All Status", value: "" },
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" }
  ]

  let defaultStatus = [];
  statusList.map((status) => {
    if (statusArray == status.value) {
      defaultStatus.push({ label: status.label, value: status.value });
    }
  }, this);

  //==============For Lead Source Filter
  
  let defaultLeadSource = [];
  if (!lSourceArray) {
    defaultLeadSource = [{ label: 'All Sources', value: '' }];
  }

  if (lSourceArray === '-1') {
    defaultLeadSource.push({ label: 'No Sources', value: '-1' });
  }
  
  leadSourceList.map((item) => {
    if (lSourceArray === item.title) {
      defaultLeadSource.push({ label: item.title, value: item.title });
    }
  }, this);

  let leadSourceListArray = [{ label: 'All Sources', value: '' }];
  leadSourceList.map((item) => {
    leadSourceListArray.push({ label: item.title, value: item.title });
  }, this);

  leadSourceListArray.push({ label: 'No Sources', value: '-1' });

  let defaultUser = [];
  userLists.map((item) => {
    defaultUser.push({ label: item.name, value: item.id });
  }, this);

  var displayUserData = '';

  if (loading) {
    return <Preloader show={true} />;
  } else {
    if (listView) {

      displayUserData = listDeal.map((item, i) => {

        let FFName = ''
        let Fullname = ''
        let expName = ''

        if (item.deal_name) {
          expName = item.deal_name.split(' ');
          if (expName.length >= 2) {
            FFName = expName[0].charAt(0) + expName[1].charAt(0);
            Fullname = item.deal_name
          } else {
            FFName = expName[0].charAt(0);
            Fullname = item.deal_name
          }
        } else {
        }

        var profileImage = ''
        if (item.get_contact) {
          if (item.get_contact.profile_pic) {
            profileImage = item.get_contact.profile_pic
          }
        }

        let num = parseInt(item.deal_amount);
        const myObj = {
          style: "currency",
          currency: currencyCode
        }

        return (
          <div className="list_view bordertop pt16 pb18 deal_view" key={i}>
            {/*--- checkmark_section ---*/}
            <div className="chbox">
              <label className="container">
                <Checkbox
                  icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                  name="deal[]"
                  checked={isCheckBoxCheck}
                  value={item.id}
                  onChange={(value) => {
                    let p = {
                      isTrue: value,
                    };
                    handleCheckbox(value, item.id)
                  }}
                  borderColor="#cccccc"
                  className="checkmark"
                  style={{ cursor: "pointer" }}
                  labelStyle={{ marginLeft: 5, userSelect: "none", 'font-weight': 'normal' }}
                  label=""
                />
                <span className="checkmark-----"></span>
              </label>
            </div>

            {/*--- image_and_name_section ---*/}
            <div className="listdetails dealdetails">
              <Stack direction="row" spacing={3}>
                <Avatar
                  alt={Fullname}
                  src={profileImage ? `${process.env.REACT_APP_API_URL}storage/${item.get_contact ? item.get_contact.profile_pic ? item.get_contact.profile_pic : '' : ''}` : ''}
                  sx={{ width: 76, height: 76 }}
                  onError={addDefaultSrc}
                  className="listimg"
                >
                  {FFName}
                </Avatar>
              </Stack>

              {/*---details_section ---*/}
              <div className="leaddetails dealdetails">
                {
                  userPermission ?
                    (userPermission.includes('deal_edit')) ?
                      <h5 onClick={() => history.push('/edit-deal/' + item.id + '?page=' + currentPage + '&search=' + search + '&search_deal_source=' + lSourceArray + '&sort_field=' + sortColumn + '&sort_order=' + sortOrder + '&per_page=' + perPage)} style={{ cursor: 'pointer',textTransform: 'capitalize' }}>{Fullname}</h5>
                      :
                      <h5>{Fullname}</h5>
                    :
                    <h5>{Fullname}</h5>
                }

                {/*--- lead_inner1_section */}
                <div className="leadinner">
                  <div className="display flex-wrap align-items-start">
                    {/*--- lead_title_section */}
                    <div className="leadtitle display align-items-start">
                      <span>Amount:</span>
                      <h6>{num ? preferredCurrencyData.currencyStringValue ? num.toLocaleString(preferredCurrencyData.currencyStringValue, myObj) : currencySymbol + num.toLocaleString(myObj) : 'N/A'}</h6>
                    </div>
                    {/*--- lead_company_section */}
                    <div className="leadtitle display align-items-start">
                      <span>Stage:</span>
                      <h6>{item.selected_stage_name ? item.selected_stage_name.name ? item.selected_stage_name.name : 'N/A' : 'N/A'}</h6>
                    </div>
                    <div className="leadtitle display align-items-start">
                      <span>Contact Name:</span>
                      <h6 style={{ display: 'block', whiteSpace: 'nowrap', width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.selected_salutation != '' ? item.selected_salutation : ''} {item.get_contact ? item.get_contact.first_name : 'N/A'} {item.get_contact ? item.get_contact.last_name : 'N/A'}</h6>
                    </div>

                  </div>
                </div>

                <div className="leadinner">
                  <div className="display flex-wrap align-items-start">
                    {
                      item.selected_product ?
                        <div className="leadtitle display align-items-start">
                          <span>Product Interested:</span>
                          <h6 style={{ display: 'block', whiteSpace: 'nowrap', width: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }} key={item.id}>{item.selected_product}</h6>
                        </div>
                        :
                        ''
                    }
                    {
                      item.expected_closing_date ?
                        <div className="leadtitle display align-items-start">
                          <span>Expected Closing Date:</span>
                          <h6>{moment.tz(item.expected_closing_date, defaultTimeZone).format(calendorFullDateFormate)}</h6>
                        </div>
                        :
                        ''
                    }
                    {
                      item.closing_date ?
                        <div className="leadtitle display align-items-start">
                          <span>Closing Date:</span>
                          <h6>{moment.tz(item.closing_date, defaultTimeZone).format(calendorFullDateFormate)}</h6>
                        </div>
                        :
                        ''
                    }

                  </div>
                </div>


              </div>

            </div>

            {/*--- owner_section ---*/}
            <div className="listowner">

              <div className="leadinner">
                <div className="display flex-wrap">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display">
                    <span>{item.added_by != null ? item.added_by.name : 'N/A'}</span>
                  </div>
                </div>
              </div>

              <div className="leadinner">
                <div className="display flex-wrap">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display">
                    <span>Last Updated:</span>
                    <h6>{moment.tz(item.updated_at, defaultTimeZone).format(preferredDateFormatData)}</h6>
                  </div>
                </div>
              </div>
            </div>

            {/*--- action_section ---*/}
            <div className="listaction text-right">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <span className="listdot"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    userPermission ?
                      (userPermission.includes('deal_edit')) ?
                        <Dropdown.Item onClick={() => history.push('/edit-deal/' + item.id + '?page=' + currentPage + '&search=' + search + '&search_deal_source=' + lSourceArray + '&sort_field=' + sortColumn + '&sort_order=' + sortOrder + '&per_page=' + perPage)}>Edit</Dropdown.Item>
                        :
                        ''
                      :
                      ''
                  }
                  {
                    userPermission ?
                      (userPermission.includes('deal_delete')) ?
                        <Dropdown.Item onClick={() => { deleteData(item.id) }}>Delete</Dropdown.Item>
                        :
                        ''
                      :
                      ''
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        );
      });

      return (
        <>
          <div className="searchbar mt30">
            <div className="col-md-4">

              <form onSubmit={manageSearch}>
                <input type="text" name="searchData" className="form-control search" value={searchString} placeholder="Search this list...." onChange={handleSearchInput} ></input><span className="listicon" onClick={manageSearch}></span>
              </form>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-6 display justify-content-end">
              <div className="dropdown-section display">

                {
                  userPermission ?
                    (userPermission.includes('deal_create')) ?
                      <Dropdown className="mr15">
                        <Dropdown.Toggle id="dropdown-basic" className="createbtn btnbluecolor">
                          Create New<span className="createicon"></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => addDeal()}>Create Deal</Dropdown.Item>
                          <Dropdown.Item onClick={() => addExistAccountDeal()}>Create Deal from Existing Account</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      :
                      ''
                    :
                    ''
                }
                <Select
                  defaultValue={defaultLeadSource}
                  name="leadSource"
                  options={leadSourceListArray}
                  styles={customStyles}
                  className="basic-multi-select mr15 br10"
                  classNamePrefix="select"
                  onChange={handleSelectLeadSource}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                    },
                  })}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
              <div className="icon-section">
                <Dropdown className="mr15">
                  <Dropdown.Toggle id="dropdown-basic" className="dropdownborder filterhover">
                    <span className="filter"></span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {columns.map((column) => {
                      return (
                        <Dropdown.Item key={column} onClick={(e) => handleSort(column)} style={{ cursor: 'pointer' }} >
                          {column.charAt(0).toUpperCase() + column.slice(1).replace("_", " ")}
                          {["name", "title", "phone", 'organization_name', 'added_by'].includes(column) ? (
                            <span className="arrowupdown">
                              {column === sortColumn ?

                                sortOrder === SORT_ASC ? (
                                  <i className="ms-1 fa fa-angle-up" aria-hidden="true"></i>
                                ) : (
                                  <i className="ms-1 fa fa-angle-down" aria-hidden="true"></i>
                                )
                                :
                                <i className="ms-1 fa fa-angle-up" aria-hidden="true"></i>
                              }


                            </span>
                          ) : null}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <button type="button" className="btn dropdownborder changehover mr15" onClick={() => handleView()}><span className="icon_change"></span></button>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className=" dropdownborder1 dothover">
                    <span className="dot"></span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item onClick={() => exportData()} >Export Deals</Dropdown.Item> */}
                    <Dropdown.Item>
                        <ExportData params={expParams} fileName="Deal" api="deal" handleCheckAll={handleCheckAll}/>
                    </Dropdown.Item>
                    {
                      userPermission ?
                        (userPermission.includes('deal_edit')) ?
                          <Dropdown.Item onClick={() => handleChangeOwner()}>Change Owner</Dropdown.Item>
                          :
                          ''
                        :
                        ''
                    }

                    {
                      userPermission ?
                        (userPermission.includes('deal_delete')) ?
                          <Dropdown.Item onClick={() => handleBulkDelete()}>Delete</Dropdown.Item>
                          :
                          ''
                        :
                        ''
                    }


                  </Dropdown.Menu>
                </Dropdown>

              </div>
            </div>
          </div>
          {/*--- searchbar_section_end ---*/}

          {/*--- list_view_section_start ---*/}
          <div className="display list_view mt30 pb4">
            {/*--- checkmark_section ---*/}
            <div className="chbox">
              <label className="container">

                <Checkbox
                  icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                  name="checkAll"
                  onChange={(value) => {
                    let p = {
                      isTrue: value,
                    };
                    handleCheckAll(value)
                  }}
                  checked={mainChecked}
                  borderColor="#cccccc"
                  className="checkmark"
                  style={{ cursor: "pointer" }}
                  labelStyle={{ marginLeft: 5, userSelect: "none" }}
                  label=""
                />
              </label>
            </div>

            {/*--- details_section ---*/}
            <div className="listdetails">
              <h3>Deal Details</h3>
            </div>
            {/*--- owner_section ---*/}
            <div className="listowner">
              <h3>Owner</h3>
            </div>
            {/*--- action_section ---*/}
            <div className="listaction">
            </div>
          </div>
          {
            displayUserData.length >= 1 ?
              displayUserData
              :
              <span>
                No data Available
              </span>
          }
          {
            displayUserData.length >= 1 ?
              <Row>
                <Col xs={12} xl={12} className="showing">
                  <Card border="light" className="bg-white border-0">
                    <Card.Body className="listcard pe-0">
                      <Row className="align-items-center">
                        <Col md={3} className="ps-2">
                          <h6>
                            Showing <b>{(((state?.users?.per_page ? state?.users?.per_page : 0) * (state?.users?.current_page ? state?.users?.current_page : 0)) - ((state?.users?.per_page ? state?.users?.per_page : 0) - 1))}</b> - <b>{(((state?.users?.per_page ? state?.users?.per_page : 0) * (state?.users?.current_page ? state?.users?.current_page : 0)) - ((state?.users?.per_page ? state?.users?.per_page : 0) - 1) + ((dataCount ? dataCount : 0) - 1))}</b> of <b>{state?.users?.total ? state?.users?.total : 0}</b>
                          </h6>
                        </Col>
                        <Col xl={4} md={4}>
                          <div className="row align-items-center g-0">
                            <div className="col-md-3 col-xl-4"></div>
                            <div className="col-md-2 col-xl-3">
                              <h6 className="text-right me-3">Per page</h6>
                            </div>
                            <div className="col-md-4 col-xl-4">
                              <select className="border-start-0 border-end-0 border-top-0 borderbottom w-50" value={perPage} onChange={(e) => { handlePerPage(e.target.value); handleCheckAll(false) }}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>
                            </div>
                            <div className="col-md-2 col-xl-1"></div>
                          </div>
                        </Col>
                        <Col md={5} className="d-flex justify-content-end">
                          <Pagination
                            activePage={state?.users?.current_page ? state?.users?.current_page : 0}
                            itemsCountPerPage={state?.users?.per_page ? state?.users?.per_page : 0}
                            totalItemsCount={state?.users?.total ? state?.users?.total : 0}
                            onChange={(pageNumber) => {
                              props.history.push('/deals?page=' + pageNumber + '&search=' + searchString + '&search_deal_source=' + lSourceArray + '&sort_field=' + sortColumn + '&sort_order=' + sortOrder + '&per_page=' + perPage)
                              setCurrentPage(pageNumber)
                              getData(pageNumber)
                              handleCheckAll(false)
                            }}
                            pageRangeDisplayed={5}
                            itemClass="page-item mx-1"
                            linkClass="page-link"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              :
              ''
          }

          <Modal as={Modal.Dialog} centered show={showChangeOwner} onHide={handleChangeOwnerClose}>
            <Modal.Header>
              <Modal.Title className="h2">Change Deal Owner</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={handleChangeOwnerClose} /><span className="modelclose"></span>
            </Modal.Header>
            <Modal.Body>
              <Form encrypted="multipart/form-data" onSubmit={handleChangeOwnerSubmit} >
                {/* <Row> */}
                <div className="col-md-12 col-sm-12">
                  <Row className="align-items-center">

                    <Form.Group className="mb-125">
                      <Form.Label column sm="12">
                        <h6 className="fw600">Select User</h6>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Group id="owner_id">
                          <Select
                            name="owner_id"
                            options={defaultUser}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleLeadChangeOwner}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Group>

                  </Row>
                </div>


                <div className="text-left">
                  {
                    ownerUpdate ?

                      <Button className="d-flex align-items-center popupbtn btnblue justify-content-center text-center btnblue mb-1">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2 text-center">Please wait...</span>
                      </Button>
                      :

                      <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="submit" variant="success" className="popupbtn btnblue mr15">Submit</Button>
                        </div>
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="button" variant="success" className="popupbtn greycolor" onClick={handleChangeOwnerClose}>Cancel</Button>
                        </div>
                      </div>
                  }
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          {
            userPermission ?
              (userPermission.includes('deal_create')) ?
                <>
                  <AddDeal data={[{ 'preferredCurrencyData': preferredCurrencyData }]} />

                  <AddExistAccountDeal data={[{ 'preferredCurrencyData': preferredCurrencyData }]} />
                </>
                :
                ''
              :
              ''
          }
        </>
      );
    } else {
      return (
        <>
          <DNDDealList data={[{ 'stage': [], 'list': [] }]} handleView={handleView} />
        </>
      );
    }

  }



};


export default DealList;