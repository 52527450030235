import React, { useEffect, useState, memo } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import { Col } from "@themesberg/react-bootstrap";
import Preloader from "../components/Preloader1";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import "../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import axios from "axios";

var currencyCode = "";
var currencySymbol = "";
var displayUserData = "";
var calendorFormate = "";

function OrderSubscriptionList(props) {
     const history = useHistory();
     const loginUser = useSelector(selectUser);
     const [recordData, setRecordData] = useState([]);

     var hostname = ''
     var onlyHost = window.location.hostname;
     onlyHost = onlyHost.split('.')
     if(onlyHost && onlyHost.length==3){
     onlyHost = onlyHost.slice(1) //remove first element of array
     hostname = onlyHost[0]+'.'+onlyHost[1]
     }else if(onlyHost && onlyHost.length==2){
     hostname = onlyHost[0]+'.'+onlyHost[1]
     }

     // Decrypt
     const user = loginUser?loginUser:[];

     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = defaultData.defaultTimeZone;

     if (user) {
          if (user.preferred_timezone) {
               defaultTimeZone = user.preferred_timezone;
          }
     }

     const [loading, setLoading] = useState(true);
     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
     const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");
     const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

     useEffect(() => {
          console.log();
          async function fetchData() {
               await getData();
          }
          fetchData();
     }, []);

     async function getData() {
          await axios
               .get("api/order/view/" + props.data)
               .then((response) => {
                    if (response.data.code === 200) {
                         setRecordData(response.data.data.record.order_subcription);

                         if(hostname ==='solvcrm.co'){
                              setPreferredCurrencyData({'currencyCode':'USD','currencySymbol':'$'});
                         }else{
                              setPreferredCurrencyData(response.data.data.staticData.preCurrency);
                         }
                         setPreferredDateFormatData(
                              response.data.data.staticData.preDateFormat
                         );

                         calendorFormate =
                              response.data.data.staticData.preDateFormat.split(" ");
                         if (calendorFormate) {
                              if (calendorFormate[0] == "YYYY-MMM-DD") {
                                   setCalendorDateFormate("yyyy/MM/dd");
                                   setCalendorFullDateFormate("yyyy-MMM-DD");
                              } else if (calendorFormate[0] == "DD-MMM-YYYY") {
                                   setCalendorDateFormate("dd/MM/yyyy");
                                   setCalendorFullDateFormate("DD-MMM-yyyy");
                              } else if (calendorFormate[0] == "YYYY-DD-MMM") {
                                   setCalendorDateFormate("yyyy/dd/MM");
                                   setCalendorFullDateFormate("yyyy-DD-MMM");
                              } else if (calendorFormate[0] == "MMM-DD-YYYY") {
                                   setCalendorDateFormate("MM/dd/yyyy");
                                   setCalendorFullDateFormate("MMM-DD-yyyy");
                              }
                         }


                         if (response.data.data.staticData.preCurrency) {
                              if(hostname ==='solvcrm.co'){
                                     currencyCode = 'USD';
                                     currencySymbol = '$';
                              }else{
                                   currencyCode = response.data.data.staticData.preCurrency.currencyCode ? 
                                        response.data.data.staticData.preCurrency.currencyCode
                                   : 
                                   "";
                                   currencySymbol = response.data.data.staticData.preCurrency.currencySymbol ?
                                        response.data.data.staticData.preCurrency.currencySymbol
                                   : 
                                   "";
                              }
                              
                         }

                         setLoading(false);
                    }
               })
               .then((json) => { })
               .catch((error) => {
                    setLoading(false);
                    if (error.response) {
                         if (error.response.status === 401 || error.response.status === 400) {
                              toast.warning(error.response.data.message);
                         } else if (error.response.status === 300) {
                              toast.error(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning("Forbidden " + error.response.data.message);
                         } else {
                              toast.error("Oops Something went wrong.");
                         }
                         history.push("/order");
                    }
               });
     }

     const DownloadPDF = async (id) => {
          setLoading(true)
          await axios.get('api/generate-order-file/' + id).then(response => {
               if (response.data.code === 200) {
                    if (response.data.data.fileName && response.data.data.fullPath) {
                         setTimeout(() => {
                              const linkSource = 'data:application/pdf;base64, '+response.data.data.fullPath
                              const downloadLink = document.createElement("a");
                              //const fileName = "convertedPDFFile.pdf";
                              downloadLink.href = linkSource;
                              downloadLink.download = response.data.data.fileName;
                              downloadLink.click();
                         }, 1000);
                    } else {
                         toast.warning('Sorry...! Filename cannot found.');
                    }
                    setLoading(false)
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setLoading(false);
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/orders');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/orders');
          });
     }

     if (loading) {
          return <Preloader show={loading ? false : true} />;
     } else {
          displayUserData = recordData.map((item, i) => {
               let num = parseFloat(item.paid_amount);
               const myObj = {
                    style: "currency",
                    currency: currencyCode,
               };

               return (
                    <tr key={i}>
                         <td className="dealsname" style={{ textTransform: "capitalize" }}>
                              {item.subscription_type}
                         </td>
                         <td className="dealsname">{item.stripe_subscription_id}</td>
                         <td>
                              {num
                                   ? preferredCurrencyData.currencyStringValue
                                        ? num.toLocaleString(
                                             preferredCurrencyData.currencyStringValue,
                                             myObj
                                        )
                                        : currencySymbol + num.toLocaleString(myObj)
                                   : "N/A"}
                         </td>
                         <td>
                              {item.plan_period_start
                                   ? moment
                                        .tz(item.plan_period_start, defaultTimeZone)
                                        .format(calendorFullDateFormate)
                                   : "N/A"}
                         </td>
                         <td>
                              {item.plan_period_end
                                   ? moment
                                        .tz(item.plan_period_end, defaultTimeZone)
                                        .format(calendorFullDateFormate)
                                   : "N/A"}
                         </td>
                         <td>{item.status ? item.status : "N/A"}</td>
                         <td>
                              {moment
                                   .tz(item.created_at, defaultTimeZone)
                                   .format(calendorFullDateFormate)}
                         </td>
                         <td>
                              {
                                   item.invoice_file ? 
                                        <div className="quotedownload ms-1" onClick={() => DownloadPDF(item.id)} style={{cursor:'pointer'}} title="Click to download invoice"></div>
                                        :
                                        <span></span>
                              }
                              
                         </td>
                    </tr>
               );
          });

          return (
               <Col md={12} className="mt30">
                    <div className="display flex-wrap w-100 history">
                         <div className="innerdealtable w-100">
                              <div className="col-md-8">
                                   <h5>Subscription List</h5>
                              </div>
                         </div>
                         {displayUserData.length ? (
                              <>
                                   <div className="contanctlistnig">
                                        <table>
                                             <tbody>
                                                  <tr>
                                                       <th style={{ width: " 10%" }}>Type</th>
                                                       <th style={{ width: " 25%" }}>Transaction ID</th>
                                                       <th style={{ width: " 15%" }}>Amount</th>
                                                       <th style={{ width: " 10%" }}>Plan Start</th>
                                                       <th style={{ width: " 10%" }}>Plan End</th>
                                                       <th style={{ width: " 10%" }}>Status</th>
                                                       <th style={{ width: " 10%" }}>Created date</th>
                                                       <th style={{ width: " 10%" }}>Action</th>
                                                  </tr>
                                                  {displayUserData}
                                             </tbody>
                                        </table>
                                   </div>
                              </>
                         ) : (
                              <span>No data Available</span>
                         )}
                    </div>
               </Col>
          );
     }
}

export default memo(OrderSubscriptionList);
