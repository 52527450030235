import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form } from "@themesberg/react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import "react-datepicker/dist/react-datepicker.css";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-edit-text/dist/index.css";
import "../../assets/css/custom.css";
import Preloader from "../../components/PreloaderCenter";
import { Workspace } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { login, selectUser } from "../../features/userSlice";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import axios from "axios";
import EasyEdit, { Types } from "react-easy-edit";

function CompanySetting(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(true);

  const [nameErrorMsg, setNameErrorMsg] = useState("");

  const loginUser = useSelector(selectUser);

  const user = loginUser?loginUser:[];

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  const userWorkspaces = useSelector((state) => state.workspace);

  const userWorkspace = userWorkspaces?userWorkspaces:[];
  

  const [loading, setLoading] = useState(true);
  const [dataRecord, setDataRecord] = useState("");
  const [ErrorInput, setErrorInput] = useState({ error_list: [] });
  const [tenantLogo, setTenantLogo] = useState("");
  const [updateDealData, setUpdateDealData] = useState(false);

  const [timeZon, setTimeZon] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [dateFormate, setDateFormate] = useState([]);

  const [UserDataName, setUserDataName] = useState(
    userWorkspace ? (userWorkspace.name ? userWorkspace.name : "") : ""
  );
  const [UserDataPreferredTimezone, setUserDataPreferredTimezone] =
    useState("");
  const [UserDataPreferredCurrency, setUserDataPreferredCurrency] =
    useState("");
  const [UserDataPreferredDateFormat, setUserDataPreferredDateFormat] =
    useState("");

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [updateDealData]);

  const getData = async () => {
    await axios
      .get("api/tenant-profile")
      .then((response) => {
        if (response.data.code === 200) {
          setDataRecord(response.data.data.record);
          setUserDataPreferredTimezone(
            response.data.data.record.preferred_timezone
          );
          setUserDataPreferredCurrency(
            response.data.data.record.preferred_currency
          );
          setUserDataPreferredDateFormat(
            response.data.data.record.preferred_date_format
          );
          setTimeZon(response.data.data.timeZoneArray);
          setCurrency(response.data.data.currency);
          setDateFormate(response.data.data.dateFormateArray);

          if (response.data.data.currentTenant.logo) {
            setTenantLogo(
              `${process.env.REACT_APP_API_URL}storage/${response.data.data.currentTenant.logo}`
            );
          }
          if (response.data.data.record.name) {
            setUserDataName(response.data.data.currentTenant.name);
          }

          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/dashboard");
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
            history.push("/profile");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const handleSelectTimezoneSave = async (event) => {
    setUserDataPreferredTimezone(event.value);
    const data = {
      preferred_timezone: event.value,
    };
    await axios
      .post("/api/update-tenant-profile", data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Data updated successfully");
          updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
          user.preferred_timezone = data.preferred_timezone;
          dispatch(login(user));
          setErrorInput({ ...ErrorInput, error_list: "" });
          setIsEditable(true);
          
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setIsEditable(true);
          if (error.response.status === 400 || error.response.status === 401) {
            setErrorInput({
              ...ErrorInput,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const handleSelectCurrencySave = async (event) => {
    setUserDataPreferredCurrency(event.value);
    const data = {
      preferred_currency: event.value,
    };
    await axios
      .post("/api/update-tenant-profile", data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Data updated successfully");
          updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
          user.preferred_currency = data.preferred_currency;
          dispatch(login(user));
          setErrorInput({ ...ErrorInput, error_list: "" });
          setIsEditable(true);
          
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setIsEditable(true);
          if (error.response.status === 400 || error.response.status === 401) {
            setErrorInput({
              ...ErrorInput,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const handleSelectDateFormateSave = async (event) => {
    setUserDataPreferredDateFormat(event.value);
    const data = {
      preferred_date_format: event.value,
    };
    await axios
      .post("/api/update-tenant-profile", data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Data updated successfully");
          updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
          user.preferred_date_format = data.preferred_date_format;
          dispatch(login(user));
          setErrorInput({ ...ErrorInput, error_list: "" });
          setIsEditable(true);
          
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setIsEditable(true);
          if (error.response.status === 400 || error.response.status === 401) {
            setErrorInput({
              ...ErrorInput,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const handleSaveData = async (value, name) => {
    const data = {
      [name]: value,
    };

    await axios
      .post("api/change-tenant-data", data)
      .then((response) => {
        if (response.status === 200) {
          updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
          dispatch(Workspace(response.data.data.data));
          setErrorInput({ ...ErrorInput, error_list: "" });
          setIsEditable(true);
          toast.success("Data updated successfully");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setIsEditable(true);
          if (error.response.status === 400) {
            setErrorInput({
              ...ErrorInput,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const handleImageInput = async (e) => {
    e.persist();

    const formData = new FormData();
    formData.append("logo", e.target.files[0]);

    await axios
      .post("/api/change-tenant-data", formData)
      .then((response) => {
        if (response.status === 200) {
          updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
          dispatch(Workspace(response.data.data.data));
          setIsEditable(true);
          toast.success("Data updated successfully");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setIsEditable(true);
          if (error.response.status === 400) {
            //setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const cancel = () => {
    setIsEditable(true);

    setNameErrorMsg("");
  };

  const handleClick = (e) => {
    setIsEditable(false);
  };

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    let selectedCurrency = [];
    for (var i = 0, l = currency.length; i < l; i++) {
      if (UserDataPreferredCurrency == currency[i].id) {
        selectedCurrency.push({
          label:
            currency[i].name +
            "-" +
            currency[i].code +
            " ( " +
            currency[i].symbol +
            " )",
          value: currency[i].id,
        });
      }
    }

    let currencyList = [];
    for (var i = 0, l = currency.length; i < l; i++) {
      currencyList.push({
        label:
          currency[i].name +
          "-" +
          currency[i].code +
          " ( " +
          currency[i].symbol +
          " )",
        value: currency[i].id,
      });
    }

    let selectedTimeZone = [];
    Object.keys(timeZon).map(function (key) {
      if (UserDataPreferredTimezone == key) {
        selectedTimeZone.push({ label: timeZon[key], value: key });
      }
    }, this);

    let timeZoneListArray = [];
    Object.keys(timeZon).map(function (key) {
      timeZoneListArray.push({ label: timeZon[key], value: key });
    }, this);

    let selectedDateFormat = [];
    Object.keys(dateFormate).map(function (key) {
      if (UserDataPreferredDateFormat == key) {
        selectedDateFormat.push({ label: dateFormate[key], value: key });
      }
    }, this);

    let dateFormateArray = [];
    Object.keys(dateFormate).map(function (key) {
      dateFormateArray.push({ label: dateFormate[key], value: key });
    }, this);

    let fullName = UserDataName ? UserDataName : "";
    let sortName = "";
    let expName = "";

    if (fullName) {
      expName = fullName.split(" ");

      if (expName.length == 2) {
        sortName = expName[0].charAt(0) + expName[1].charAt(0);
      } else {
        sortName = expName[0].charAt(0);
      }
    }

    const updateLocationSetting = async (e) => {
      const data = {
        location_type: e.currentTarget.id,
        location_value: e.currentTarget.value == "yes" ? "no" : "yes",
      };
      await axios
        .post("/api/update-tenant-profile", data)
        .then((response) => {
          if (response.status === 200) {
            updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
            if (data.location_type == "is_location_traking") {
              user.is_location_traking = data.location_value;
              user.lead_location_traking = "no";
              user.real_time_location_traking = "no";
            } else if (data.location_type == "lead_location_traking") {
              user.is_location_traking = "no";
              user.lead_location_traking = data.location_value;
              user.real_time_location_traking = "no";
            } else if (data.location_type == "real_time_location_traking") {
              user.is_location_traking = "no";
              user.lead_location_traking = "no";
              user.real_time_location_traking = data.location_value;
            }
            dispatch(login(user));
            toast.success("Data updated successfully");
          }
        })
        .then((json) => {})
        .catch((error) => {
          if (error.response) {
            setIsEditable(true);
            if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              toast.error(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error("Oops Something went wrong.");
            }
          }
        });
    };

    return (
      // <Row>
      <div className="display flex-wrap">
        {/*--- profile_information_section ---*/}
        <Col md={12} className="client profile mt30">
          {/*--- client_name_and_convert_lead_section ---*/}
          <div className="client_details display">
            {/*--- client_image_and_deatils_section ---*/}
            <div className="client_image profile_imgae display">
              <label className="custom-file-upload  editprofile">
                <input
                  type="file"
                  id="profile_image"
                  name="profile_image"
                  accept="image/jpeg, image/png, image/jpg"
                  onChange={handleImageInput}
                />
                <Stack direction="row" spacing={3}>
                  <Avatar
                    alt={fullName}
                    src={tenantLogo}
                    sx={{ width: 50, height: 50 }}
                    onError={addDefaultSrc}
                    className="clientimg1"
                  >
                    {sortName}
                  </Avatar>
                </Stack>
              </label>

              <div className="client_name ml20">
                <div className="display flex-wrap">
                  <h5>{fullName}</h5>
                </div>
                <div className="display flex-wrap">
                  <h6>{dataRecord.email}</h6>
                </div>
              </div>
            </div>
            {/*--- converted_button ---*/}
          </div>
        </Col>

        {/*--- Profile_Information_section ---*/}
        <Col md={12} className="mt30">
          <div className="display w-100">
            <Accordion defaultActiveKey="0" className="w-100">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Tenant Information</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="display align-items-start flex-wrap">
                    <div className="col-md-9 circletrick">
                      <Form.Group className="display">
                        <Form.Label className="text-left w-17">Name</Form.Label>
                        <EasyEdit
                          type={Types.TEXT}
                          value={UserDataName}
                          onSave={(value) => {
                            handleSaveData(value, "name");
                          }}
                          onValidate={(value) => {
                            if (value) {
                              var splString = value.split(" ");
                              if (value && value.length > 50) {
                                setNameErrorMsg(
                                  "Only 50 characters are allowed"
                                );
                                return false;
                              } else if (value && value.length < 2) {
                                setNameErrorMsg("Enter at least 2 characters.");
                                return false;
                              } else if (
                                value &&
                                value.length == 2 &&
                                value &&
                                splString.length >= 2
                              ) {
                                setNameErrorMsg(
                                  "Enter at least 2 characters, space not allow."
                                );
                                return false;
                              }
                              return true;
                            } else {
                              setNameErrorMsg("Enter name");
                              return false;
                            }
                          }}
                          validationMessage={nameErrorMsg}
                          onCancel={cancel}
                          saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                          cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                          instructions=""
                          attributes={{ maxLength: 50 }}
                          placeholder="----"
                          allowEdit={isEditable}
                          onFocus={() => handleClick("")}
                        />
                      </Form.Group>
                      <span className="error">
                        {ErrorInput.error_list.name}
                      </span>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>

        <Col md={12} className="mt30">
          <div className="display w-100">
            <Accordion defaultActiveKey="0" className="w-100">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Settings</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="display align-items-start flex-wrap">
                    <div className="col-md-9 circletrick">
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-12 circletrick leadheight">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-left w-17">
                              Preferred timezone
                            </Form.Label>
                            <Select
                              defaultValue={selectedTimeZone}
                              name="preferred_timezone"
                              options={timeZoneListArray}
                              className="basic-multi-select leadselect w70"
                              classNamePrefix="select"
                              onChange={handleSelectTimezoneSave}
                              // styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "var(--primary-color-light)",
                                  primary: "var(--primary-color)",
                                },
                              })}
                              isDisabled={!isEditable}
                              placeholder="----"
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.preferred_timezone}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick leadheight">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-left w-17">
                              Preferred currency
                            </Form.Label>
                            <Select
                              defaultValue={selectedCurrency}
                              name="preferred_currency"
                              options={currencyList}
                              className="basic-multi-select leadselect w70"
                              classNamePrefix="select"
                              onChange={handleSelectCurrencySave}
                              // styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "var(--primary-color-light)",
                                  primary: "var(--primary-color)",
                                },
                              })}
                              isDisabled={!isEditable}
                              placeholder="----"
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.preferred_currency}
                          </span>
                        </div>
                        <div className="col-md-12 circletrick leadheight">
                          <Form.Group className="mb-2 display">
                            <Form.Label className="text-left w-17">
                              Preferred date format
                            </Form.Label>
                            <Select
                              defaultValue={selectedDateFormat}
                              name="preferred_date_format"
                              options={dateFormateArray}
                              className="basic-multi-select leadselect w70"
                              classNamePrefix="select"
                              onChange={handleSelectDateFormateSave}
                              // styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "var(--primary-color-light)",
                                  primary: "var(--primary-color)",
                                },
                              })}
                              isDisabled={!isEditable}
                              placeholder="----"
                            />
                          </Form.Group>
                          <span className="error">
                            {ErrorInput.error_list.preferred_date_format}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>

        {userPermission ? (
          userPermission.includes("location_edit") ? (
            <Col md={12} className="mt30">
              <div className="display w-100">
                <Accordion defaultActiveKey="0" className="w-100">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4>Location Settings</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="display align-items-start flex-wrap">
                        <div className="col-md-9 circletrick">
                          <div className="display align-items-start flex-wrap">
                            <div className="col-md-12 circletrick leadheight">
                              <Form>
                                <fieldset>
                                  <Form.Check
                                    defaultChecked={
                                      user.lead_location_traking == "yes" ||
                                      user.real_time_location_traking == "yes"
                                        ? false
                                        : true
                                    }
                                    type="radio"
                                    defaultValue="no"
                                    label="No Location Tracking"
                                    value={user.is_location_traking}
                                    name="location_traking"
                                    id="is_location_traking"
                                    htmlFor="is_location_traking"
                                    onChange={(e) => updateLocationSetting(e)}
                                  />

                                  <Form.Check
                                    defaultChecked={
                                      user.lead_location_traking == "yes"
                                        ? true
                                        : false
                                    }
                                    type="radio"
                                    defaultValue="no"
                                    label="Location Tracking at Lead"
                                    value={user.lead_location_traking}
                                    name="location_traking"
                                    id="lead_location_traking"
                                    htmlFor="lead_location_traking"
                                    onChange={(e) => updateLocationSetting(e)}
                                  />

                                  <Form.Check
                                    defaultChecked={
                                      user.real_time_location_traking == "yes"
                                        ? true
                                        : false
                                    }
                                    type="radio"
                                    defaultValue="no"
                                    label="Real time Location Tracking"
                                    value={user.real_time_location_traking}
                                    name="location_traking"
                                    id="real_time_location_traking"
                                    htmlFor="real_time_location_traking"
                                    onChange={(e) => updateLocationSetting(e)}
                                  />
                                </fieldset>
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CompanySetting;
