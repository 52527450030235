import { GoogleMap, Marker, useLoadScript, InfoWindow,Circle } from "@react-google-maps/api";

import React, { useState, useMemo, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import "../../assets/css/google-map.css";
import { toast } from 'react-toastify';
import Preloader from "../../components/PreloaderCenter";
import axios from "axios";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";

const UserTrackingList = () => {
  const googles = window.google;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  
  const [loading, setLoading] = useState(true);
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [isfillData, setIsfillData] = useState(false);
  const [userData, setUserData] = useState();
  //const [reload, setReload] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(8);
  const [defaultLatLng, setDefaultLatLng] = useState({'lat':23.003250,'lng':72.663240});
  const markers = [];
  //const [markers, setMarkers] = useState([]);
  //const [pointCenter, setPointCenter] = useState([]);
  
  const [preferredDateFormatData, setPreferredDateFormatData] = useState('');

  const loginUser = useSelector(selectUser);
  const user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
  
  useEffect(() => {
    getUserLocation();
    const interval = setInterval(async()=>{
      await getUserLocation()
    },60000);
    return ()=>clearInterval(interval);
  }, []);

  // setInterval(() => {
  //   setReload(reload+1)
  // }, 60000);
  

  async function getUserLocation(){
    //let i
    await axios.get("api/track-users").then(response => {
      if (response.status === 200) {
        if(response.data.data.dataRecords && response.data.data.dataRecords[0].latitude && response.data.data.dataRecords[0].longitude){
          setDefaultLatLng({'lat':response.data.data.dataRecords[0].latitude,'lng':response.data.data.dataRecords[0].longitude})
        }
        setUserData(response.data.data.dataRecords)
        setPreferredDateFormatData(response.data.data.preferredDateFormat);
        
        // console.log('response',response.data.data.dataRecords)
        // for(i=0;i<response.data.data.dataRecords.length;i++){
        //   setPointCenter([{address: response.data.data.dataRecords[i].user_name , lat: parseFloat(response.data.data.dataRecords[i].latitude), lng: parseFloat(response.data.data.dataRecords[i].longitude), locationTime:moment.tz(response.data.data.dataRecords[i].updated_at, defaultTimeZone ? defaultTimeZone : '').format(preferredDateFormatData) }])  
        // }
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 300) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.error('Forbidden ' + error.response.data.message);
        } else {
          toast.error('Oops Something went wrong.');
        }
      }

    });
  }

  //console.log('markers',markers,'pointer',pointCenter)

  if(userData){
    userData.map(async(location) => {
      if((location.latitude && location.latitude!=0) && (location.longitude && location.longitude!=0)){
        await markers.push({ address: location.user_name , lat: parseFloat(location.latitude), lng: parseFloat(location.longitude), locationTime:moment.tz(location.updated_at, defaultTimeZone ? user.preferred_timezone : defaultTimeZone).format(preferredDateFormatData) });
      }
    }, this);
  }
  

  setTimeout(() => {
      setIsfillData(true)
      setLoading(false)
  }, 2000);

  const center = useMemo(() => ({ lat: defaultLatLng.lat, lng: defaultLatLng.lng }), []);
  
  const onMapLoad = async (map) => {
    setMapRef(map);
    const bounds = new googles.maps.LatLngBounds(center);
    await map.fitBounds(bounds);
    setZoomLevel(zoomLevel)
    return  markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    
  };

  const handleMarkerClick = (id, lat, lng, address, locationTime) => {
    setInfoWindowData({ id, address,locationTime });
    setIsOpen(true);
    setZoomLevel(50)
    return mapRef?.panTo({ lat, lng });
  };
  const [map, setMap] = React.useState(null)
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  
  if (loading) {
    return <Preloader show={true} />;
  } else {

    return (
        <div className="display flex-wrap w-100 reportborder mt30">
          <div className="col-md-12">
            <div className="flex-wrap task align-items-start justify-content-end">
              <div className="innerdealtable w-100">
                <div className="display">
                  <div className="col-md-12">
                    <h4>Location-wise User List</h4>
                    {
                      markers.length ?
                        <React.Fragment>
                            <div className="maping-relative">
                                <div className="maping-absolute">
                                <div className="display w-100">
                                      <Accordion defaultActiveKey="0" className="w-100">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><h4>Active Users</h4></Accordion.Header>
                                                <Accordion.Body>
                                                      <div className="display align-items-start flex-wrap">
                                                          <div className="col-md-12 circletrick">
                                                                <div className="display align-items-start flex-wrap">
                                                                    <div className="col-md-12 circletrick">
                                                                      {  
                                                                        markers.length && markers.map(({ lat, lng, address, locationTime }, ind) => (

                                                                          <div className="userlistbox" key={ind} onClick={() => {
                                                                                            handleMarkerClick(ind, lat, lng, address, locationTime);
                                                                                          }}>
                                                                            <h3>{address}</h3>
                                                                            <p>Last updated: {locationTime}</p>
                                                                          </div> 

                                                                        ))
                                                                      }
                                                                      </div>
                                                                </div>
                                                          </div>
                                                      </div> 
                                                </Accordion.Body>
                                            </Accordion.Item>
                                      </Accordion>
                                  </div>
                                </div>
                            </div>
                        </React.Fragment>
                      :''  
                    }
                    
                    
                  </div>
                </div>
              </div>
              <div style={{ height: '78.8vh', width: '100%' }}>
                {!isLoaded ? 
                  <center><h2>Loading...</h2></center>
                : 

                !isfillData ?

                  <center><h2>Please wait we are finding your user location</h2></center>
                :
                  markers.length?
                    <GoogleMap
                      mapContainerClassName="map-container"
                      onLoad={onMapLoad}
                      center={center}
                      zoom={zoomLevel}
                      onClick={() => setIsOpen(false)}
                      onUnmount={onUnmount}
                    >
                      {markers.map(({ address, lat, lng, locationTime }, ind) => (
                        <Marker
                          key={ind}
                          position={{ lat, lng }}
                          onClick={() => {
                            handleMarkerClick(ind, lat, lng, address, locationTime);
                          }}
                        >
                          <Circle
                            defaultCenter={{
                              lat: parseFloat(lat),
                              lng: parseFloat(lng)
                            }}
                            radius={3000}
                            options={{strokeColor: "#ff0000"}}
                          />

                          {isOpen && infoWindowData?.id === ind && (
                            <InfoWindow
                              onCloseClick={() => {
                                setIsOpen(false);
                              }}
                            > 
                            <div>
                              <h3>{infoWindowData.address}</h3>
                              <span>Updated at : {infoWindowData.locationTime}</span>
                            </div>  
                            </InfoWindow>
                          )}
                        </Marker>
                      ))}
                    </GoogleMap>
                  :
                  <center><h2>Sorry no location found..!</h2></center>
                }
            </div>
            </div>
          </div>
        </div>
    );
  }
};

export default UserTrackingList;