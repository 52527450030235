/* eslint-disable import/no-anonymous-default-export */
import React, { useMemo, useState } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindow,
  Circle,
} from "@react-google-maps/api";
import "../assets/css/google-map.css";
import {
  Col,
  Row,
  Button,
  Form,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";


const ShowActivityLocation = ({
  isOpen,
  onRequestClose,
  latitude,
  longitude,
  visitedtime,
}) => {
  const googles = window.google;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const [mapRef, setMapRef] = useState();
  const [isOpens, setIsOpens] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [isfillData, setIsfillData] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(8);
  const [map, setMap] = React.useState(null);

  const markers = [
    {
      address: visitedtime,
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    },
  ];

  const center = useMemo(() => ({ lat: latitude, lng: longitude }), []);

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new googles.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
    setZoomLevel(zoomLevel);
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpens(true);
    setZoomLevel(50);
  };

  setTimeout(() => {
    setIsfillData(true);
  }, 2000);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Modal as={Modal.Dialog} centered show={isOpen} onHide={onRequestClose}>
      <Modal.Header>
        {/* <Modal.Title className="h2">Add Lead Stage</Modal.Title> */}
        <Button variant="close" aria-label="Close" onClick={onRequestClose} />
        <span className="modelclose"></span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <div className="col-md-12 col-sm-12">
            <div style={{ height: "78.8vh", width: "100%" }}>
              {!isLoaded ? (
                <h1>Loading...</h1>
              ) : !isfillData ? (
                <center>
                  <h2>Please wait we are finding your user location</h2>
                </center>
              ) : (
                <GoogleMap
                  mapContainerClassName="map-container"
                  onLoad={onMapLoad}
                  center={center}
                  zoom={zoomLevel}
                  onClick={() => setIsOpens(false)}
                  onUnmount={onUnmount}
                >
                  {markers.map(({ address, lat, lng }, ind) => (
                    <Marker
                      key={ind}
                      position={{ lat, lng }}
                      onClick={() => {
                        handleMarkerClick(ind, lat, lng, address);
                      }}
                    >
                      <Circle
                        defaultCenter={{
                          lat: parseFloat(lat),
                          lng: parseFloat(lng),
                        }}
                        radius={3000}
                        options={{ strokeColor: "#ff0000" }}
                      />
                      {isOpens && infoWindowData?.id === ind && (
                        <InfoWindow
                          onCloseClick={() => {
                            setIsOpens(false);
                          }}
                        >
                          <h3>{infoWindowData.address}</h3>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                </GoogleMap>
              )}
            </div>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ShowActivityLocation;
