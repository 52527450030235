import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Dropdown, Card } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Preloader from "../../components/PreloaderCenter";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Checkbox from "react-custom-checkbox";
import { ProductAddPopUp } from "../../features/userSlice";
//========Component for DnD=========//
import AddProduct from "../../components/AddProduct";
import ExportData from "../../components/ExportData";
import {capitalize} from "../../utils/utils";
//=================================//

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var searchData = "";
var searchInputDataName = "";
var searchInputDataEmail = "";
var searchInputDataFromDate = "";
var searchInputDataToDate = "";
var searchInputDataTitle = "";
var searchInputDataStatus = "";
var searchInputDataPhone = "";
var searchInputDataOrganizationName = "";

var currencyCode = "";
var currencySymbol = "";

function ProductList(props) {
  const location = props.history.location;
  const loginUser = useSelector(selectUser);
  const dispatch = useDispatch();

  const [isCheck, setIsCheck] = useState([]);
  const [isCheckBoxCheck, setIsCheckBoxCheck] = useState(false);
  const [mainChecked, setMainChecked] = useState(false);

  const user = loginUser?loginUser:[];

  const userPermissions = useSelector((state) => state.permission);

  const userPermission = userPermissions?userPermissions:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  const [search, setSearch] = useState("");
  const [statusArray, setStatusArray] = useState("");
  const [userArray, setUserArray] = useState("");
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();

  const [userLists, setUserLists] = useState([]);

  //used for reloading parent page after model was close
  const showAddProduct = useSelector((state) => state.productAddPopUp);

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [preferredDateFormatData, setPreferredDateFormatData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");

  if (props.location.pathname == "/product") {
    if (props.location.statess) {
      searchData = props.location.statess.search;
    }

    if (props.location.searchData) {
      searchInputDataName = props.location.searchData.search_name;
      searchInputDataEmail = props.location.searchData.search_email;
      searchInputDataFromDate = props.location.searchData.search_from_date;
      searchInputDataToDate = props.location.searchData.search_to_date;
      searchInputDataTitle = props.location.searchData.search_title;
      searchInputDataStatus = props.location.searchData.search_status;
      searchInputDataPhone = props.location.searchData.search_phone;
      searchInputDataOrganizationName =
        props.location.searchData.search_organization_name;
    }
  }

  //==============START: Handle new search filter option==================//
 
  const handleSelectUser = (event) => {
    setUserArray(event.value);
  };
  //==============END: Handle new search filter option==================//

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const columns = ["name", "code", "price", "tax"];

  const SORT_ASC = "asc";
  const SORT_DESC = "desc";
  const [sortColumn, setSortColumn] = useState();

  const handleSort = (column) => {
    if (column === sortColumn) {
      sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC;
      getData();
    } else {
      setSortColumn(column);
      sortOrder = SORT_DESC;
    }
  };

  const handlePerPage = (Page) => {
    setLoading(true);
    //setCurrentPage(1)
    currentPage = 1;
    perPage = Page;
    getData();
  };
  //=========END:CODE FOR FOOTER PAGINATION===========//

  var calendorFormate = "";

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [
    sortColumn,
    sortOrder,
    search,
    statusArray,
    userArray,
    showAddProduct,
    location,
    searchInputDataName,
    searchInputDataEmail,
    searchInputDataFromDate,
    searchInputDataToDate,
    searchInputDataTitle,
    searchInputDataStatus,
    searchInputDataPhone,
    searchInputDataOrganizationName,
  ]);

  const getData = async (currentPage) => {
    const params = {
      search: search,
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_status: statusArray,
      search_user_id: userArray,
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    };
    setLoading(true);
    await axios
      .get(`api/product?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setListData(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });
          setUserLists(response.data.data.users);
          setPreferredCurrencyData(response.data.data.preferredCurrencyData);
          setPreferredDateFormatData(response.data.data.preferredDateFormat);

          if (response.data.data.preferredCurrencyData.currencyCode) {
            currencyCode = response.data.data.preferredCurrencyData.currencyCode
              ? response.data.data.preferredCurrencyData.currencyCode
              : "";
            currencySymbol = response.data.data.preferredCurrencyData
              .currencySymbol
              ? response.data.data.preferredCurrencyData.currencySymbol
              : "";
          }

          calendorFormate = response.data.data.preferredDateFormat.split(" ");
          if (calendorFormate) {
            if (calendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDateFormate("yyyy/MM/dd");
              setCalendorFullDateFormate("yyyy/MM/DD");
            } else if (calendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDateFormate("dd/MM/yyyy");
              setCalendorFullDateFormate("DD/MM/yyyy");
            } else if (calendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDateFormate("yyyy/dd/MM");
              setCalendorFullDateFormate("yyyy/DD/MM");
            } else if (calendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDateFormate("MM/dd/yyyy");
              setCalendorFullDateFormate("MM/DD/yyyy");
            }
          }
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          }
        }
      });
  };


  function deleteData(id) {
    const data = {
      id: id,
    };
    swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async(willDelete) => {
      if (willDelete) {
        await axios
          .delete("api/product/destroy", { data })
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              getData(currentPage);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error(error.response.data.message);
              }
            }
          });
      }
    });
  }

  function bulkDeleteData(ids) {
    const data = {
      id: ids,
    };
    swal({
      title: "Are you sure you want to delete?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async(willDelete) => {
      if (willDelete) {
        await axios
          .delete("api/product/bulk-destroy", { data })
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              getData(currentPage);

              handleCheckAll(false);
              setIsCheckBoxCheck("");
              setIsCheck([]);
            }
          })
          .then((json) => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                toast.warning(error.response.data.message);
              } else if (error.response.status === 403) {
                toast.warning("Forbidden " + error.response.data.message);
              } else {
                toast.error(error.response.data.message);
              }
            }
          });
      }
    });
  }

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  };

  //======Convert Lead to Deal Code=========//

  //Export data file for logic
  const expParams = {
    search: searchData,
    search_name: searchInputDataName,
    search_email: searchInputDataEmail,
    search_from_date: searchInputDataFromDate,
    search_to_date: searchInputDataToDate,
    search_title: searchInputDataTitle,
    search_status: searchInputDataStatus,
    search_phone: searchInputDataPhone,
    search_organization_name: searchInputDataOrganizationName,
    sort_field: sortColumn,
    sort_order: sortOrder,
    per_page: perPage,
    page: currentPage,
    selectedIds: isCheck?isCheck:[],
  };

  const handleBulkDelete = () => {
    if (isCheck && isCheck.length > 0) {
      bulkDeleteData(isCheck);
    } else {
      toast.warning("Please select at least one record for delete.");
    }
  };

  //============Start:- Handle check all ================//
  const handleCheckbox = (value, id) => {
    if (value) {
      setIsCheck((isCheck) => [...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((x) => x !== id));
    }
  };

  const handleCheckAll = (value) => {
    if (value) {
      setMainChecked(true);
    } else {
      setMainChecked(false);
    }

    setIsCheckBoxCheck(value);
    setIsCheck([]);

    if (value) {
      setIsCheckBoxCheck(value);
      setIsCheck(listData.map((li) => li.id));
    }
  };
  //============End:- Handle check all ================//

  function addProduct() {
    dispatch(ProductAddPopUp(true));
  }

  //==============For Lead Status Filter
  let statusList = [
    { label: "All Status", value: "" },
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];

  let defaultStatus = [];
  statusList.map((status) => {
    if (statusArray == status.value) {
      defaultStatus.push({ label: status.label, value: status.value });
    }
  }, this);

  //==============For User Filter =============//

  let defaultUser = [];

  if (!userArray) {
    defaultUser = [{ label: "All Users", value: "" }];
  }

  userLists.map((item) => {
    if (userArray == item.id) {
      defaultUser.push({ label: item.name, value: item.id });
    }
  }, this);

  let userListArray = [{ label: "All Users", value: "" }];
  userLists.map((item) => {
    userListArray.push({ label: item.name, value: item.id });
  }, this);

  var displayUserData = "";

  if (loading) {
    return <Preloader show={true} />;
  } else {
    displayUserData = listData.map((item, i) => {
      let fullName = item.name ? item.name : "";
      let sortName = "";
      let expName = "";

      if (fullName) {
        expName = fullName.split(" ");
        if (expName.length == 2) {
          sortName = expName[0].charAt(0) + expName[1].charAt(0);
        } else {
          sortName = expName[0].charAt(0);
        }
      }

      let num = parseInt(item.price);
      const myObj = {
        style: "currency",
        currency: currencyCode,
      };

      return (
        <div className="list_view bordertop pt16 pb18 deal_view" key={i}>
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="product[]"
                checked={isCheckBoxCheck}
                value={item.id}
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckbox(value, item.id);
                }}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{
                  marginLeft: 5,
                  userSelect: "none",
                  "font-weight": "normal",
                }}
                label=""
              />
              <span className="checkmark-----"></span>
            </label>
          </div>

          {/*--- image_and_name_section ---*/}
          <div className="listdetails dealdetails">
            <Stack direction="row" spacing={3}>
              <Avatar
                alt={fullName}
                src={`${process.env.REACT_APP_API_URL}storage/${
                  item.images
                    ? item.images[0]
                      ? item.images[0].file_url
                      : ""
                    : ""
                }`}
                sx={{ width: 76, height: 76 }}
                onError={addDefaultSrc}
                className="listimg"
              >
                {sortName}
              </Avatar>
            </Stack>

            {/*---details_section ---*/}
            <div className="leaddetails dealdetails">
              {userPermission ? (
                userPermission.includes("product_edit") ? (
                  <h5
                    onClick={() => history.push("/edit-product/" + item.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.name ? capitalize(item.name) : "N/A"}
                  </h5>
                ) : (
                  <h5> {item.name ? capitalize(item.name) : "N/A"}</h5>
                )
              ) : (
                <h5> {item.name ? capitalize(item.name) : "N/A"} </h5>
              )}
              {/*--- lead_inner1_section */}
              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {/*--- lead_title_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Product Code:</span>
                    <h6>{item.product_code ? item.product_code : "N/A"}</h6>
                  </div>
                  <div className="leadtitle display align-items-start">
                    <span>Price:</span>
                    <h6>
                      {num
                        ? preferredCurrencyData.currencyStringValue
                          ? num.toLocaleString(
                              preferredCurrencyData.currencyStringValue,
                              myObj
                            )
                          : currencySymbol + num.toLocaleString(myObj)
                        : "N/A"}
                    </h6>
                  </div>
                  {/*--- lead_company_section */}
                  <div className="leadtitle display align-items-start">
                    <span>Tax:</span>
                    <h6>{item.tax ? item.tax : "N/A"}</h6>
                  </div>
                </div>
              </div>

              <div className="leadinner">
                <div className="display flex-wrap align-items-start">
                  {item.created_at ? (
                    <div className="leadtitle display align-items-start">
                      <span>Crated Date:</span>
                      <h6>
                        {moment
                          .tz(
                            item.created_at,
                            defaultTimeZone ? defaultTimeZone : ""
                          )
                          .format(preferredDateFormatData)}
                      </h6>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {/*--- owner_section ---*/}
          <div className="listowner">
            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>
                    {item.added_by != null ? item.added_by.name : "N/A"}
                  </span>
                </div>
              </div>
            </div>

            <div className="leadinner">
              <div className="display flex-wrap">
                {/*--- lead_title_section */}
                <div className="leadtitle display">
                  <span>Last Updated:</span>
                  <h6>
                    {item.updated_at
                      ? moment
                          .tz(item.updated_at, defaultTimeZone)
                          .format(preferredDateFormatData)
                      : "N/A"}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/*--- action_section ---*/}
          <div className="listaction text-right">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="listdot"></span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {userPermission ? (
                  userPermission.includes("product_edit") ? (
                    <Dropdown.Item
                      onClick={() => history.push("/edit-product/" + item.id)}
                    >
                      Edit
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userPermission ? (
                  userPermission.includes("product_delete") ? (
                    <Dropdown.Item
                      onClick={() => {
                        deleteData(item.id);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="searchbar mt30">
          {/* <div className="col-md-4">
              <form>
                <input type="text" className="form-control seacrh" placeholder="Search this list...." value={searchText} onChange={handleSearch} ></input><span className="listicon"></span>
              </form>
            </div> */}
          <div className="col-md-2"></div>
          <div className="col-md-6 display justify-content-end">
            <div className="dropdown-section display">
              <Dropdown className="mr15">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="createbtn btnbluecolor"
                  onClick={() => addProduct()}
                >
                  Create New<span className="createicon"></span>
                </Dropdown.Toggle>
              </Dropdown>

            <Select
                defaultValue={defaultUser}
                name="user_id"
                options={userListArray}
                styles={''}
                className="basic-multi-select mr15 br10"
                classNamePrefix="select"
                onChange={handleSelectUser}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--primary-color-light)",
                    primary: "var(--primary-color)",
                  },
                })}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            <div className="icon-section">
              <Dropdown className="mr15">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdownborder filterhover"
                >
                  <span className="filter"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {columns.map((column) => {
                    return (
                      <Dropdown.Item
                        key={column}
                        onClick={(e) => handleSort(column)}
                        style={{ cursor: "pointer" }}
                      >
                        {column.charAt(0).toUpperCase() +
                          column.slice(1).replace("_", " ")}
                        {["name", "code", "price", "tax"].includes(column) ? (
                          <span className="arrowupdown">
                            {column === sortColumn ? (
                              sortOrder === SORT_ASC ? (
                                <i
                                  className="ms-1 fa fa-angle-up"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="ms-1 fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              )
                            ) : (
                              <i
                                className="ms-1 fa fa-angle-up"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                        ) : null}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className=" dropdownborder1 dothover"
                >
                  <span className="dot"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item>
                        <ExportData params={expParams} fileName="Product" api="product" handleCheckAll={handleCheckAll}/>
                    </Dropdown.Item>
                  {userPermission ? (
                    userPermission.includes("product_delete") ? (
                      <Dropdown.Item onClick={() => handleBulkDelete()}>
                        Delete
                      </Dropdown.Item>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {/*--- searchbar_section_end ---*/}

        {/*--- list_view_section_start ---*/}
        <div className="display list_view mt30 pb4">
          {/*--- checkmark_section ---*/}
          <div className="chbox">
            <label className="container">
              <Checkbox
                icon={<FontAwesomeIcon icon={faCheck} size="xs" />}
                name="checkAll"
                onChange={(value) => {
                  let p = {
                    isTrue: value,
                  };
                  handleCheckAll(value);
                }}
                checked={mainChecked}
                borderColor="#cccccc"
                className="checkmark"
                style={{ cursor: "pointer" }}
                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                label=""
              />

              {/* <span className="checkmark------"></span> */}
            </label>
          </div>

          {/*--- details_section ---*/}
          <div className="listdetails">
            <h3>Product Details</h3>
          </div>
          {/*--- owner_section ---*/}
          <div className="listowner">
            <h3>Owner</h3>
          </div>
          {/*--- action_section ---*/}
          <div className="listaction"></div>
        </div>

        {displayUserData.length >= 1 ? (
          displayUserData
        ) : (
          <span>No data Available</span>
        )}
        {displayUserData.length >= 1 ? (
          <Row>
            <Col xs={12} xl={12} className="showing">
              <Card border="light" className="bg-white border-0">
                <Card.Body className="listcard pt-0 pb-0 pe-0">
                  <Row className="align-items-center">
                    <Col md={3} className="ps-2">
                      <h6>
                        Showing{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1)}
                        </b>{" "}
                        -{" "}
                        <b>
                          {(state?.users?.per_page
                            ? state?.users?.per_page
                            : 0) *
                            (state?.users?.current_page
                              ? state?.users?.current_page
                              : 0) -
                            ((state?.users?.per_page
                              ? state?.users?.per_page
                              : 0) -
                              1) +
                            ((dataCount ? dataCount : 0) - 1)}
                        </b>{" "}
                        of{" "}
                        <b>{state?.users?.total ? state?.users?.total : 0}</b>
                      </h6>
                    </Col>
                    <Col xl={4} md={4}>
                      <div className="row align-items-center g-0">
                        <div className="col-md-2 col-xl-3"></div>
                        <div className="col-md-3 col-xl-4">
                          <h6 className="text-right me-3">Per page</h6>
                        </div>
                        <div className="col-md-4 col-xl-4">
                          <select
                            className="border-start-0 border-end-0 border-top-0 borderbottom w-50"
                            value={perPage}
                            onChange={(e) => {
                              handlePerPage(e.target.value);
                              handleCheckAll(false);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                        <div className="col-md-3 col-xl-1"></div>
                      </div>
                    </Col>
                    <Col md={5} className="d-flex justify-content-end">
                      <Pagination
                        activePage={
                          state?.users?.current_page
                            ? state?.users?.current_page
                            : 0
                        }
                        itemsCountPerPage={
                          state?.users?.per_page ? state?.users?.per_page : 0
                        }
                        totalItemsCount={
                          state?.users?.total ? state?.users?.total : 0
                        }
                        onChange={(pageNumber) => {
                          currentPage = pageNumber;
                          getData(pageNumber);
                          handleCheckAll(false);
                        }}
                        pageRangeDisplayed={5}
                        itemClass="page-item mx-1"
                        linkClass="page-link"
                        // firstPageText="First Page"
                        // lastPageText="Last Page"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <AddProduct />
      </>
    );
  }
}

export default ProductList;
