/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ContactAddPopUp } from "../features/userSlice";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";

export default (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [salutationData, setSalutationData] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const [getIpResponse, setGetIpResponse] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const showAddContact = useSelector((state) => state.contactAddPopUp);
  const handleContactClose = () => dispatch(ContactAddPopUp(false));

  const [defaultValue, setdefaultValue] = useState({
    salutation: "",
    firstname: "",
    lastname: "",
    title: "",
    organization_name: "",
    lead_source: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    error_list: [],
  });

  useEffect(() => {
    if (showAddContact) {
      async function fetchData() {
        await getCreateData();
        //await getIpData()
      }
      fetchData();
    }
  }, []);

  async function getIpData() {
    await fetch(`https://ipapi.co/json/`)
      .then((response) => response.json())
      .then((data) => setGetIpResponse(data))
      .catch((error) => "");

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } else {
      //console.log("Not Available");
    }
  }

  async function getCreateData() {
    await axios
      .get("api/contact/create")
      .then((response) => {
        if (response.status === 200) {
          setSalutationData(response.data.data.salutationArray);
          setAccountList(response.data.data.accountLists);
          setdefaultValue({
            ...defaultValue,
            salutation: "",
            firstname: "",
            lastname: "",
            title: "",
            organization_name: "",
            lead_source: "",
            phone: "",
            organization_email: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            error_list: [],
          });
        }
      })
      .then((json) => {})
      .catch((error) => {
        //setLoading(false);
        if (error.response) {
          if (error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  let salutationList = [];
  let salutationVarList = Object.keys(salutationData).map(function (key) {
    salutationList.push({ label: salutationData[key], value: key });
  }, this);

  let SaldefaultValue = [];

  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  let accountLists = [];
  for (var i = 0, l = accountList.length; i < l; i++) {
    accountLists.push({
      label: accountList[i].organization_name,
      value: accountList[i].id,
    });
  }

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .trim("First name cannot include space")
      .strict(true)
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter first name"),
    lastname: yup
      .string()
      .trim("Last name cannot include space")
      .strict(true)
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter last name"),
    email: yup
      .string()
      .max(50, "Must be at most 50 characters")
      .email("Please enter valid email address"),
    phone: yup.string().max(25, "Phone must be at most 25 characters"),
    title: yup
      .string()
      .trim("Title cannot include space")
      .strict(true)
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters"),
  });

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      salutation: values.salutation,
      first_name: values.firstname,
      last_name: values.lastname,
      primary_email: values.email,
      phone1: values.phone,
      title: values.title,
      account_id: values.account_id,
      zipcode: getIpResponse
        ? getIpResponse.postal
          ? getIpResponse.postal
          : ""
        : "",
      city: getIpResponse ? (getIpResponse.city ? getIpResponse.city : "") : "",
      state: getIpResponse
        ? getIpResponse.region
          ? getIpResponse.region
          : ""
        : "",
      country: getIpResponse
        ? getIpResponse.country_name
          ? getIpResponse.country_name
          : ""
        : "",
      latitude: getIpResponse.latitude ? getIpResponse.latitude : latitude,
      longitude: getIpResponse.longitude ? getIpResponse.longitude : longitude,
    };

    await axios
      .post("/api/contact/store", data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(ContactAddPopUp(false));
          toast.success("Contact created successfully");
          history.push("/contacts");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400) {
            setdefaultValue({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/contacts");
          } else {
            toast.warning(error.response.data.message);
          }
        }
      });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showAddContact}
      onHide={handleContactClose}
    >
      <Modal.Header>
        <Modal.Title className="h2">Add Contact</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={handleContactClose}
        />
        <span className="modelclose"></span>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            } = props;
            return (
              <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-md-12 col-sm-12">
                    <Row className="align-items-center">
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">Account</h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="account_id">
                            <Select
                              defaultValue={[]}
                              name="account_id"
                              options={accountLists}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              // onChange={handleSalutationInput}
                              //onChange={setFieldValue}
                              onChange={(selectedOption) => {
                                values.account_id = selectedOption.value;
                                handleChange("account_id");
                              }}
                              isClearable={false}
                              isSearchable={true}
                              onBlur={setFieldTouched}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "var(--primary-color-light)",
                                  primary: "var(--primary-color)",
                                },
                              })}
                            />
                            <span className="error error4">
                              {defaultValue.error_list.account_id}
                            </span>
                            <p className="text-danger error4">
                              <ErrorMessage name="account_id" />
                            </p>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">Salutation</h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="name">
                            <Select
                              defaultValue={SaldefaultValue}
                              name="salutation"
                              options={salutationList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              // onChange={handleSalutationInput}
                              //onChange={setFieldValue}
                              onChange={(selectedOption) => {
                                values.salutation = selectedOption.value;
                                handleChange("salutation");
                              }}
                              isClearable={false}
                              isSearchable={false}
                              onBlur={setFieldTouched}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "var(--primary-color-light)",
                                  primary: "var(--primary-color)",
                                },
                              })}
                            />
                            <span className="error error4">
                              {defaultValue.error_list.salutation}
                            </span>
                            <p className="text-danger error4">
                              <ErrorMessage name="salutation" />
                            </p>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">
                            First name<span className="text-danger">*</span>
                          </h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="name">
                            <Form.Control
                              type="text"
                              name="firstname"
                              minLength={2}
                              maxLength={50}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter firstname"
                              className={
                                errors.firstname && touched.firstname
                                  ? "input-error"
                                  : null
                              }
                            />
                            <span className="error error4">
                              {defaultValue.error_list.first_name}
                            </span>
                            <span className="error error4">
                              <ErrorMessage name="firstname" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">
                            Last name<span className="text-danger">*</span>
                          </h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="name">
                            <Form.Control
                              type="text"
                              name="lastname"
                              minLength={2}
                              maxLength={50}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter lastname"
                              className={
                                errors.lastname && touched.lastname
                                  ? "input-error"
                                  : null
                              }
                            />
                            <span className="error error4">
                              {defaultValue.error_list.last_name}
                            </span>
                            <span className="error error4">
                              <ErrorMessage name="lastname" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">Title</h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="title">
                            <Form.Control
                              type="text"
                              name="title"
                              minLength={2}
                              maxLength={50}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter title"
                            />
                            <span className="error error4">
                              {defaultValue.error_list.title}
                            </span>
                            <span className="error error4">
                              <ErrorMessage name="title" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <Form.Group className="mb-125 position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">Email</h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="email">
                            <Form.Control
                              type="text"
                              name="email"
                              minLength={2}
                              maxLength={50}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter Email"
                            />
                            <span className="error error4">
                              {defaultValue.error_list.primary_email}
                            </span>
                            <span className="error error4">
                              <ErrorMessage name="email" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <Form.Group className="position-relative">
                        <Form.Label column sm="12">
                          <h6 className="fw600">Phone</h6>
                        </Form.Label>
                        <Col sm="12">
                          <Form.Group id="phone">
                            <Form.Control
                              type="text"
                              name="phone"
                              maxLength={25}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="99999 12345"
                            />
                            <span className="error error4">
                              {defaultValue.error_list.phone1}
                            </span>
                            <span className="error error4">
                              <ErrorMessage name="phone" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                    </Row>
                  </div>
                </Row>

                <div className="text-center mt30">
                  {isSubmitting ? (
                    <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                      <Spinner animation="border" size="sm" />
                      <span className="ms-2 text-center">Please wait...</span>
                    </Button>
                  ) : (
                    <div
                      className="btn-toolbar"
                      role="toolbar"
                      aria-label="Toolbar with button groups"
                    >
                      <div
                        className="btn-group mr-2"
                        role="group"
                        aria-label="First group"
                      >
                        <Button
                          type="submit"
                          variant="success"
                          className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                        >
                          Create
                        </Button>
                      </div>
                      <div
                        className="btn-group mr-2"
                        role="group"
                        aria-label="First group"
                      >
                        <Button
                          type="button"
                          variant="success"
                          onClick={handleContactClose}
                          className="popupbtn greycolor d-flex align-items-center justify-content-center"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
