/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { Routes } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, selectUser, LoginPermission, Workspace, LoginAccessMsg, NotificationCount, NotificationList, DisplayHostName } from "../features/userSlice";

// pages
import Presentation from "./Presentation";
import DashboardOverview from "./dashboard/DashboardOverview";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import ResendEmailPassword from "./examples/ResendEmailPassword";
import VerifyEmail from "./examples/VerifyEmail";

import TenantInvitation from "./examples/TenantInvitation";

import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

import UserList from "./user/UserList";
import Profile from "./user/Profile";
import EditUser from "./user/EditUser";
import CompanySetting from "./user/CompanySetting";
import Checkout from "./user/Checkout";

import Order from "./order/OrderList";
import ViewOrder from "./order/ViewOrder";

import RoleList from "./role/RoleList";
import AddRole from "./role/AddRole";
import EditRole from "./role/EditRole";

import StageList from "./stage/StageList";
import AddStage from "./stage/AddStage";
import EditStage from "./stage/EditStage";

import ProductList from "./product/ProductList";
import AddProduct from "./product/AddProduct";
import EditProduct from "./product/EditProduct";

import ContactList from "./contact/ContactList";
import AddContact from "./contact/AddContact";
import EditContact from "./contact/EditContact";

import AccountList from "./account/AccountList";
import AddAccount from "./account/AddAccount";
import EditAccount from "./account/EditAccount";

import LeadList from "./lead/LeadList";
import AddLead from "./lead/AddLead";
import EditLead from "./lead/EditLead";
import CloneLead from "./lead/CloneLead";

import DealList from "./deal/DealList";
import AddDeal from "./deal/AddDeal";
import EditDeal from "./deal/EditDeal";
import CloneDeal from "./deal/CloneDeal";

import TaskList from "./task/TaskList";
import EditTask from "./task/EditTask";

import ActivityList from "./activity/ActivityList";
import EditActivity from "./activity/EditActivity";

import ActivityReport from "./activity-report/ActivityList";

import QuoteList from "./quote/QuoteList";
import EditQuote from "./quote/EditQuote";
import CreateQuote from "./quote/CreateQuote";
import CreateCloneQuote from "./quote/CreateCloneQuote";

import ReportList from "./report/ReportList"

import SalesQuotaList from "./report/SalesQuotaList";

import LogActivityList from "./logactivity/ActivityList"

import NotificationViewList from "./notification/NotificationList"

import UserTrackingList from "./dashboard/UserTrackingList"

import SourceList from "./source/SourceList";
import AddSource from "./source/AddSource";
import EditSource from "./source/EditSource";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";


import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const cookies = new Cookies();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data, application/json, application/pdf';
axios.defaults.headers.post['Accept'] = 'application/json';
//axios.defaults.withCredentials = true;

// Interceptors for request
axios.interceptors.request.use(function (config) {
    const token = cookies.get('auth_token');
    const tenant = cookies.get('auth_tenant');

    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers.tenant = tenant ? `${tenant}` : '';

    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.data = transformDataToUrlEncoded(config.data);
    } else {
        config.headers['Content-Type'] = 'application/json,application/pdf';
    }

    return config;
}, error => {
    return Promise.reject(error);
});

function transformDataToUrlEncoded(data) {
    const params = new URLSearchParams();
    for (const key in data) {
        params.append(key, data[key]);
    }
    return params;
}

// Interceptors for responses
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                // case 400:
                //   toast.warning('Bad Request - '+error.response.data.message);
                //   break;
                // case 401:
                //   toast.error('Unauthorized - '+error.response.data.message);
                //   // Handle unauthorized error, maybe redirect to login
                //   break;
                // case 403:
                //   toast.error('Forbidden - '+error.response.data.message);
                //   break;
                // case 404:
                //   toast.error('Not Found - '+error.response.data.message);
                //   break;
                // case 405:
                //   toast.error('Method Not Allowed - '+error.response.data.message);
                //   break;
                case 500:
                    toast.error('Internal Server Error');
                    const history = useHistory();
                    const token = cookies.get('auth_token');
                    if (!token || token === 'undefined') {
                        history.push('/sign-in')
                    } else {
                        history.push('/dashboard')
                    }
                    break;
                default:
                //toast.error('An unexpected error occurred');
            }
        } else if (error.request) {
            //toast.error('No response from server');
        } else {
            //toast.error('Error setting up request');
        }
        return Promise.reject(error);
    }
);


//=============Website under construction Code===================// same code for Globle use in userSlice.js file 
let hostname = '';
let displayHost = '';
const onlyHost = window.location.hostname.split('.');
if (onlyHost.length >= 2) {
    const hostParts = onlyHost.slice(-2);
    hostname = hostParts.join('.');
    displayHost = onlyHost.length === 3 ? hostParts[0] : onlyHost[0];
}

var displayTitle = 'SolvCRM'

const customTitles = {
    'multi-tenant-crm': 'SolvCRM',
    'solvcrm': 'SolvCRM',
    'crm': 'SolvCRM',
    'viraj': 'VirajGroup',
    'xl': 'xl Financial BIZ HUB'
};
displayTitle = customTitles[displayHost] || displayTitle;
document.title = displayTitle;

const RouteWithLoader = ({ component: Component, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    //====START:Code for remove all session data when browser was closed=====//
    useEffect(() => {
        if ((displayHost && displayHost === 'multi-tenant-crm') || (displayHost && displayHost === 'solvcrm') || (displayHost && displayHost === 'final-crm')) {
            import('../scss/volt.scss')
                .then(() => {
                    console.log('Example styles loaded successfully.');
                })
                .catch(error => {
                    console.error('Error loading example styles:', error);
                });
        } else {
            import('../scss/' + displayHost + '-volt.scss')

                .then(() => {
                    console.log('Example styles loaded successfully.');
                })
                .catch(error => {
                    console.error('Error loading example styles:', error);
                });
        }

        //=======START:Update Favicon icon for updated domain=========//
        let link = document.querySelector("link[rel~='icon']");
        if (link && ((displayHost && displayHost !== 'multi-tenant-crm') || (displayHost && displayHost !== 'solvcrm'))) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
            link.href = '/' + displayHost + '-favicon.ico';
        }
        //=======END:Update Favicon icon for updated domain=========//

    }, [hostname]);
    //=======END:Code for remove all session data when browser was closed=====//

    useEffect(() => {
        async function fetchData() {
            await getTimeOutFunc();
        }
        fetchData();
    }, []);

    async function getTimeOutFunc() {
        var expPathname = window.location.pathname.split('/');
        if (cookies.get('auth_token')) {
            const beforeAuthpage = ['sign-in', 'sign-up', 'reset-password', 'invitation', 'verify-email', 'forgot-password'];
            if (beforeAuthpage.includes(expPathname[1])) {
                dispatch(LoginAccessMsg("Oops...! You have already login, Please sign-out to perform this action."));
                //toast.warning('Oops...! You have already login, Please sign-out to perform this action.')
                setTimeout(() => history.push('/dashboard'), 1000);
            }
            history.push('/dashboard')
        }
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }


    return (
        <Route {...props} render={props => (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={true}
                    pauseOnHover={true}
                    progress={true}
                    theme="colored"
                    transition={Slide}
                />
                {
                    loaded ? <Component {...props} /> : ''
                }

            </>
        )} />
    );
};

const RouteWithSidebar = ({ component: Component, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const history = useHistory();

    //========START:WRITE CODE FOR SHOW HIDE SECOND SIDEBAR MENU===========//
    const location = useLocation();
    const { pathname } = location;
    const splitLocationPath = pathname.split("/");
    let setActiveLink = '/';
    let secondActiveLink = '';
    if (splitLocationPath.length >= 2) {
        secondActiveLink = splitLocationPath[1].split("-");
        if (secondActiveLink.length == 1) {
            setActiveLink = setActiveLink + secondActiveLink[0];
        } else {
            setActiveLink = setActiveLink + secondActiveLink[1] + 's';
        }
    }

    useEffect(() => {
        if ((displayHost && displayHost === 'multi-tenant-crm') || (displayHost && displayHost === 'solvcrm')) {
            import('../scss/volt.scss')
                .then(() => {
                    console.log('Example styles loaded successfully.');
                })
                .catch(error => {
                    console.error('Error loading example styles:', error);
                });
        } else {
            import('../scss/' + displayHost + '-volt.scss')
                .then(() => {
                    console.log('Example styles loaded successfully.');
                })
                .catch(error => {
                    console.error('Error loading example styles:', error);
                });
        }

        //=======START:Update Favicon icon for updated domain=========//
        let link = document.querySelector("link[rel~='icon']");
        if (link && ((displayHost && displayHost != 'multi-tenant-crm') || (displayHost && displayHost != 'solvcrm'))) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
            link.href = '/' + displayHost + '-favicon.ico';
        }
        //=======END:Update Favicon icon for updated domain=========//
    }, [displayHost]);

    var pageName = ['/users', '/roles', '/products', '/stages', '/settings', '/sources', '/checkout', '/orders', '/logss']
    var constClassName = 'content'
    if (pageName.includes(setActiveLink)) {
        constClassName = 'content1'
    }
    //========END:WRITE CODE FOR SHOW HIDE SECOND SIDEBAR MENU===========//

    useEffect(() => {
        if (cookies.get('auth_token')) {
            let parts = window.location.host.split(".");

            if (parts.length === 2 && !user) {
                history.push('/')
            }

            if (parts.length >= 3) {
                let subdomain = parts.splice(0, 1);
                async function fetchData() {
                    await getData(subdomain);
                }
                fetchData();
            }
        } else {
            dispatch(logout());
            history.push('/sign-in')
        }

        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);

    }, [pathname]);

    //====Start:Set new session data for user click on new tab on same browser====//
    async function getData(subdomain) {
        await axios.get("/api/user/getLoginUser?sdomain=" + subdomain).then(response => {
            if (response.status === 200) {
                var subdomain = response.data.data.currentTenant.domain;
                let host = window.location.host;
                let protocol = window.location.protocol;
                let parts = host.split(".");

                dispatch(
                    NotificationList(response.data.data.notifications),
                );

                dispatch(
                    NotificationCount(response.data.data.notifications.length),
                );

                if (response.data.data.domainAccess === 'no') {
                    toast.warning('Opps...! You can not access this Workspace.');
                    if (!subdomain) {
                        if (host === 'solvcrm.asaya.in') {
                            subdomain = 'solvcrm';
                        } else {
                            subdomain = 'app';
                        }
                    }
                    parts.splice(0, 1);
                    window.location = protocol + "//" + subdomain + '.' + parts.join(".") + "/dashboard";
                } else {
                    if (response.data.data.domainAccess === 'yes') {
                        if (response.data.data.user) {
                            dispatch(
                                login(response.data.data.user),
                            );
                        } else {
                            dispatch(
                                login([]),
                            );
                        }

                        if (response.data.data.user.packageType !== 'offline') {
                            if (pathname !== '/checkout' && response.data.data.user.subRemainingDays === '-1') {
                                if (!subdomain) {
                                    if (host === 'solvcrm.asaya.in') {
                                        subdomain = 'solvcrm';
                                    } else {
                                        subdomain = 'app';
                                    }
                                }
                                parts.splice(0, 1);
                                window.location = protocol + "//" + subdomain + '.' + parts.join(".") + "/checkout";
                            }
                        }

                        //check if tenant subscription is over 
                        if ((response.data.data.user.type === 3) && (response.data.data.user.role !== 'Admin' || response.data.data.user.role !== 'SuperAdmin') && response.data.data.user.subRemainingDays === '-1') {
                            toast.success('Your subscription will ended, please contact you admin to access workspace.');
                            setTimeout(() => {
                                dispatch(logout());
                            }, 2000);
                        }

                        if (response.data.data.currentTenant) {
                            dispatch(
                                Workspace(response.data.data.currentTenant),
                            );
                        } else {
                            dispatch(
                                Workspace([]),
                            );
                        }

                        if (response.data.data.permissionArray) {
                            dispatch(
                                LoginPermission(response.data.data.permissionArray)
                            );
                        } else {
                            dispatch(
                                LoginPermission([])
                            );
                        }
                    }
                }
            }
        }).then(json => {
            toast.success(json.data.data.message);
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch(logout());
                    toast.warning(error.response.data.message);
                } else if (error.response.status === 403) {
                    toast.error('Forbidden ' + error.response.data.message);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        });
    }

    //====End:Set new session data for user click on new tab on same browser====//

    return (
        <Route {...props} render={props => (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={true}
                    pauseOnHover={true}
                    progress={true}
                    theme="colored"
                    transition={Slide}
                />
                {
                    loaded ?
                        <>
                            <Sidebar />
                            <main className={constClassName}>
                                <Navbar {...props} />
                                <Component {...props} />
                            </main>
                        </>
                        :
                        ''
                }

            </>
        )}
        />
    );
};

export default () => (

    <Router>
        <Switch>
            {/* //===============Before Auth Route==============// */}
            <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
            <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
            <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
            <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
            <RouteWithLoader exact path={Routes.ResendEmailPassword.path} component={ResendEmailPassword} />
            <RouteWithLoader exact path={Routes.VerifyEmail.path} component={VerifyEmail} />
            <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
            <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
            <RouteWithLoader exact path={Routes.TenantInvitation.path} component={TenantInvitation} />
            <RouteWithLoader exact path={Routes.Workspace.path} component={Workspace} />
            <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
            <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />


            {/* //===============After Auth Route==============// */}
            <RouteWithSidebar path={Routes.DashboardOverview.path} component={DashboardOverview} />
            <RouteWithSidebar path={Routes.UserList.path} component={UserList} />
            {/* <RouteWithSidebar path={Routes.AddUser.path} component={AddUser} /> */}
            <RouteWithSidebar path={Routes.EditUser.path} component={EditUser} />
            <RouteWithSidebar path={Routes.Profile.path} component={Profile} />
            <RouteWithSidebar path={Routes.CompanySetting.path} component={CompanySetting} />
            <RouteWithSidebar path={Routes.Checkout.path} component={Checkout} />
            <RouteWithSidebar path={Routes.Order.path} component={Order} />
            <RouteWithSidebar path={Routes.ViewOrder.path} component={ViewOrder} />
            <RouteWithSidebar path={Routes.RoleList.path} component={RoleList} />
            <RouteWithSidebar path={Routes.AddRole.path} component={AddRole} />
            <RouteWithSidebar path={Routes.EditRole.path} component={EditRole} />
            <RouteWithSidebar path={Routes.StageList.path} component={StageList} />
            <RouteWithSidebar path={Routes.AddStage.path} component={AddStage} />
            <RouteWithSidebar path={Routes.EditStage.path} component={EditStage} />
            <RouteWithSidebar path={Routes.ProductList.path} component={ProductList} />
            <RouteWithSidebar path={Routes.AddProduct.path} component={AddProduct} />
            <RouteWithSidebar path={Routes.EditProduct.path} component={EditProduct} />
            <RouteWithSidebar path={Routes.ContactList.path} component={ContactList} />
            <RouteWithSidebar path={Routes.AddContact.path} component={AddContact} />
            <RouteWithSidebar path={Routes.EditContact.path} component={EditContact} />
            <RouteWithSidebar path={Routes.AccountList.path} component={AccountList} />
            <RouteWithSidebar path={Routes.AddAccount.path} component={AddAccount} />
            <RouteWithSidebar path={Routes.EditAccount.path} component={EditAccount} />
            <RouteWithSidebar path={Routes.LeadList.path} component={LeadList} />
            <RouteWithSidebar path={Routes.AddLead.path} component={AddLead} />
            <RouteWithSidebar path={Routes.EditLead.path} component={EditLead} />
            <RouteWithSidebar path={Routes.CloneLead.path} component={CloneLead} />
            <RouteWithSidebar path={Routes.DealList.path} component={DealList} />
            <RouteWithSidebar path={Routes.AddDeal.path} component={AddDeal} />
            <RouteWithSidebar path={Routes.EditDeal.path} component={EditDeal} />
            <RouteWithSidebar path={Routes.CloneDeal.path} component={CloneDeal} />
            <RouteWithSidebar path={Routes.TaskList.path} component={TaskList} />
            <RouteWithSidebar path={Routes.EditTask.path} component={EditTask} />
            <RouteWithSidebar path={Routes.ActivityList.path} component={ActivityList} />
            <RouteWithSidebar path={Routes.EditActivity.path} component={EditActivity} />
            <RouteWithSidebar path={Routes.ActivityReport.path} component={ActivityReport} />
            <RouteWithSidebar path={Routes.SalesQuotaList.path} component={SalesQuotaList} />
            <RouteWithSidebar path={Routes.QuoteList.path} component={QuoteList} />
            <RouteWithSidebar path={Routes.EditQuote.path} component={EditQuote} />
            <RouteWithSidebar path={Routes.CreateQuote.path} component={CreateQuote} />
            <RouteWithSidebar path={Routes.CreateCloneQuote.path} component={CreateCloneQuote} />
            <RouteWithSidebar path={Routes.ReportList.path} component={ReportList} />
            <RouteWithSidebar path={Routes.LogActivityList.path} component={LogActivityList} />
            <RouteWithSidebar path={Routes.NotificationList.path} component={NotificationViewList} />
            <RouteWithSidebar path={Routes.UserTrackingList.path} component={UserTrackingList} />
            <RouteWithSidebar path={Routes.SourceList.path} component={SourceList} />
            <RouteWithSidebar path={Routes.AddSource.path} component={AddSource} />
            <RouteWithSidebar path={Routes.EditSource.path} component={EditSource} />
            <Redirect to={Routes.NotFound.path} />
        </Switch>
    </Router>

);
