/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Col,
    Row,
    Button,
    Dropdown,
    FormCheck,
    Form,
    Modal,
    Spinner,
} from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "react-edit-text/dist/index.css";
import "../assets/css/custom.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Formik, ErrorMessage, useField, useFormikContext } from "formik";
import * as yup from "yup";
import swal from "sweetalert";
import Checkbox from "react-custom-checkbox";
import { Scrollbars } from "react-custom-scrollbars";

var parentId = "";
var parentType = "lead";

export default (props) => {

    if (props.data.type == "deal") {
        parentId = props.data.dealId;
    } else if (props.data.type == "lead") {
        parentId = props.data.leadId;
    }

    if (props.data.type) {
        parentType = props.data.type;
    }

    const [loading, setLoading] = useState(true);

    const [preferredDateFormatData, setPreferredDateFormatData] = useState(
        props.data.preferredDateFormatData
    );

    const [calendorDateFormate, setCalendorDateFormate] = useState(
        props.data.calendorDateFormate
    );
    const [isCheck, setIsCheck] = useState([]);
    const [displayDateFor, setDisplayDateFor] = useState(
        props.data.calendorFullDateFormate
    );

    const loginUser = useSelector(selectUser);

    const [allActivity, setallActivity] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [priorityStatusList, setPriorityStatusList] = useState([]);

    const [reloadPage, setReloadPage] = useState(false);

    var user = loginUser ? loginUser : [];

    const userPermissions = useSelector((state) => state.permission);
    var userPermission = userPermissions ? userPermissions : [];

    var defaultData = useSelector(selectDefaultData);
    var defaultTimeZone = user?.preferred_timezone || defaultData.defaultTimeZone;

    const now = moment().tz(defaultTimeZone);

    useEffect(() => {
        async function fetchData() {
            await getActivity();
        }
        fetchData();
    }, [reloadPage]);

    async function getActivity() {
        await axios
            .get("api/" + parentType + "/activity/" + parentId)
            .then((response) => {
                if (response.data.code === 200) {
                    setallActivity(response.data.data.records);
                    setPriorityList(response.data.data.priority);
                    setPriorityStatusList(response.data.data.priorityStatus);
                }
            })
            .then((json) => { })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.error("Oops Something went wrong.");
                    }
                }
            });
    }

    const handleTackChange = async (value, id) => {
        if (value) {
            setIsCheck((isCheck) => [...isCheck, id]);
        } else {
            setIsCheck(isCheck.filter((x) => x !== id));
        }

        const data = {
            id: id,
            leadId: parentId,
            dealId: parentId,
        };

        await axios
            .post("/api/" + parentType + "-task/action", data)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    getActivity();
                    setReloadPage(reloadPage ? false : true)
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.error("Oops Something went wrong.");
                    }
                }
            });
    };

    const handleEventChange = async (value, id) => {
        if (value) {
            setIsCheck((isCheck) => [...isCheck, id]);
        } else {
            setIsCheck(isCheck.filter((x) => x !== id));
        }

        const data = {
            id: id,
            leadId: parentId,
            dealId: parentId,
        };

        await axios
            .post("/api/" + parentType + "-event/action", data)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    setReloadPage(reloadPage ? false : true)
                    getActivity();
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.error("Oops Something went wrong.");
                    }
                }
            });
    };

    //===================START: Writing code for Add and Edit Task========================//
    const [showTaskDefault, setShowTaskDefault] = useState(false);
    const handleTaskClose = () => setShowTaskDefault(false);
    const currentData = new Date(
        new Date().toLocaleString("en-US", { timeZone: defaultTimeZone })
    );
    const [dueDate, setDueDate] = useState(
        new Date(new Date(currentData).setMinutes(currentData.getMinutes() + 15))
    );
    const [priorityVal, setPriorityVal] = useState("");
    const [taskStatusVal, setTaskStatusVal] = useState("");

    const [taskInput, setTaskInput] = useState({
        name: "",
        description: "",
        end_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        priority: "",
        activity_status: "",
        error_list: [],
    });

    const lengthValidation = (fieldLength) => {
        var valid = true;
        if (fieldLength) {
            var str = fieldLength.replace(/\s/g, "");
            if (str.length < 2) {
                valid = false;
            }
        }

        return valid
            ? {
                isValid: true,
            }
            : {
                isValid: false,
                errorMessage: "Must be at least 2 characters, no space allow",
            };
    };

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .trim("Subject cannot include space")
            .strict(true)
            .min(2, "The name must be at least 2 characters.")
            .max(50, "The name must not be greater than 50 characters.")
            .required("Enter subject"),
        description: yup
            .string()
            .min(2, "The description must be at least 2 characters.")
            .max(150, "The description must not be greater than 150 characters.")
            .required("Enter description")
            .test("validator-custom-length", function (value) {
                const validation = lengthValidation(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                } else {
                    return true;
                }
            }),
        //end_date:yup.string().required('This field is a required'),
    });

    const [submitTask, setSubmitTask] = useState(false);

    let defaultPriorityOptions = [];
    Object.keys(priorityList).map(function (key) {
        if ("low" == key) {
            defaultPriorityOptions.push({ label: priorityList[key], value: key });
        }
    }, this);

    let priorityOptions = [];
    Object.keys(priorityList).map(function (key) {
        priorityOptions.push({ label: priorityList[key], value: key });
    }, this);

    let defaultPriorityStatusOptions = [];
    Object.keys(priorityStatusList).map(function (key) {
        if ("not-started" == key) {
            defaultPriorityStatusOptions.push({
                label: priorityStatusList[key],
                value: key,
            });
        }
    }, this);

    let priorityStatusOptions = [];
    Object.keys(priorityStatusList).map(function (key) {
        priorityStatusOptions.push({ label: priorityStatusList[key], value: key });
    }, this);

    const customStyles = {
        menuList: (styles) => ({
            ...styles,
            color: "black",
            backgroundcolor: "#0A8EA0",
            paddingTop: 0,
            paddingBottom: 0,
        }),
        option: (provided) => ({
            ...provided,
            borderwidth: "0 0 1px 0px",
            bordercolor: "black",
            borderstyle: "solid",
            backgroundcolor: "#0A8EA0",
            "&:hover": {
                outline: "none",
                backgroundcolor: "#0A8EA0",
                color: "white",
            },
            "&:active": {
                outline: "none",
                backgroundcolor: "#0A8EA0",
                color: "white",
            },
            "&:visited": {
                outline: "none",
                backgroundcolor: "#0A8EA0",
                color: "white",
            },
            "&:selected": {
                outline: "none",
                backgroundcolor: "#0A8EA0",
                color: "white",
            },
            "&:default": {
                outline: "none",
                backgroundcolor: "#0A8EA0",
                color: "white",
            },
        }),
        control: (provided) => ({
            ...provided,
            border: "0px solid black",
            "&:hover": {
                outline: "none",
                backgroundcolor: "#0A8EA0",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            border: "0px solid black",
            color: "black",
            "&:hover": {
                outline: "none",
                backgroundcolor: "#0A8EA0",
            },
        }),
    };

    const handleSelectPrioritySave = (event) => {
        if (event.value) {
            setPriorityVal(event.value);
        }
    };

    const handleSelectPriorityStatusSave = (event) => {
        if (event.value) {
            setTaskStatusVal(event.value);
        }
    };

    const handleDueDate = (date) => {
        if (date) {
            setDueDate(date);
        }
    };

    const handleSubmit = async (values) => {
        const data = {
            leadId: parentId,
            dealId: parentId,
            name: values.name,
            description: values.description,
            end_date: dueDate
                ? moment(dueDate).format("YYYY-MM-DD HH:mm:ss")
                : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            priority: priorityVal,
            activity_status: taskStatusVal,
        };

        await axios
            .post("/api/" + parentType + "-task/store", data)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("Task has been created successfully");
                    setTaskInput({
                        name: "",
                        description: "",
                        end_date: "",
                        error_list: [],
                    });
                    setDueDate("");
                    setShowTaskDefault(false);
                    setReloadPage(reloadPage ? false : true)
                    getActivity();
                }
                setSubmitTask(false);
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    setSubmitTask(false);
                    if (error.response.status === 400) {
                        setTaskInput({
                            ...taskInput,
                            error_list: error.response.data.validation_errors,
                        });
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.success(error.response.data.message);
                    }
                }
            });
    };

    //====================Start:Write code for Edit Task===================================

    const [showEditTaskDefault, setShowEditTaskDefault] = useState(false);
    const handleEditTaskClose = () => setShowEditTaskDefault(false);

    const [editTaskId, seteditTaskId] = useState("");
    const [taskData, setTaskData] = useState("");
    const [taskDueDate, setTaskDueDate] = useState(new Date());
    //const [taskEditPriority, setTaskEditPriority] = useState('');
    //const [taskEditStatus, setTaskEditStatus] = useState('');

    const [errorTaskEditInput, setErrorTaskEditInput] = useState({
        error_list: [],
    });

    const [priorityEditList, setPriorityEditList] = useState([]);
    const [priorityStatusEditList, setPriorityStatusEditList] = useState([]);

    const [priorityEditVal, setPriorityEditVal] = useState("");
    const [taskStatusEditVal, setTaskStatusEditVal] = useState("");

    const [taskEditInput, setTaskEditInput] = useState({
        name: "",
        description: "",
        end_date: new Date(),
        priority: "",
        activity_status: "",
        error_list: [],
    });

    async function openTaskEditModel(id) {
        seteditTaskId(id);
        setErrorTaskEditInput({ ...errorTaskEditInput, error_list: "" });
        await axios
            .get(
                "api/" +
                parentType +
                "-task/edit/" +
                id +
                "?" +
                parentType +
                "Id=" +
                parentId
            )
            .then((response) => {
                if (response.data.code === 200) {
                    setTaskData(response.data.data.record);
                    setPriorityEditList(response.data.data.priority);
                    setPriorityStatusEditList(response.data.data.priorityStatus);

                    setTaskEditInput({
                        name: response.data.data.record.name,
                        description: response.data.data.record.description,
                        end_date: new Date(response.data.data.record.end_date),
                        priority: response.data.data.record.priority,
                        activity_status: response.data.data.record.activity_status,
                        error_list: [],
                    });
                    setPriorityEditVal(response.data.data.record.priority);
                    setTaskStatusEditVal(response.data.data.record.activity_status);

                    // setTaskName(response.data.data.record.name);
                    //setTaskDescription(response.data.data.record.description);
                    setTaskDueDate(new Date(moment.utc(response.data.data.record.end_date).tz(defaultTimeZone).format(preferredDateFormatData)));
                    //setTaskEditPriority(response.data.data.record.priority);
                    //setTaskEditStatus(response.data.data.record.activity_status);
                }
                setShowEditTaskDefault(true);
            })
            .then((json) => { })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.error("Oops Something went wrong.");
                    }
                }
            });
    }

    let defaultPriorityEditOptions = [];
    Object.keys(priorityEditList).map(function (key) {
        if (taskData.priority == key) {
            defaultPriorityEditOptions.push({
                label: priorityEditList[key],
                value: key,
            });
        }
    }, this);

    let priorityEditOptions = [];
    Object.keys(priorityEditList).map(function (key) {
        priorityEditOptions.push({ label: priorityEditList[key], value: key });
    }, this);

    let defaultPriorityStatusEditOptions = [];
    Object.keys(priorityStatusEditList).map(function (key) {
        if (taskData.activity_status == key) {
            defaultPriorityStatusEditOptions.push({
                label: priorityStatusEditList[key],
                value: key,
            });
        }
    }, this);

    let priorityStatusEditOptions = [];
    Object.keys(priorityStatusEditList).map(function (key) {
        priorityStatusEditOptions.push({
            label: priorityStatusEditList[key],
            value: key,
        });
    }, this);

    const handleSelectPriorityEditSave = (event) => {
        if (event.value) {
            setPriorityEditVal(event.value);
        }
    };

    const handleSelectPriorityStatusEditSave = (event) => {
        if (event.value) {
            setTaskStatusEditVal(event.value);
        }
    };

    const handleEditTaskSave = async (values) => {
        const data = {
            id: editTaskId,
            leadId: parentId,
            dealId: parentId,
            name: values.name,
            description: values.description,
            end_date: taskDueDate
                ? moment(taskDueDate).format("YYYY-MM-DD HH:mm:ss")
                : "null_date",
            priority: priorityEditVal,
            activity_status: taskStatusEditVal,
        };

        await axios.post("api/" + parentType + "-task/update", data).then((response) => {
            setShowEditTaskDefault(false);
            getActivity();
        }).then((json) => { })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorTaskEditInput({
                            ...errorTaskEditInput,
                            error_list: error.response.data.validation_errors,
                        });
                    } else if (error.response.status === 300) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.error("Oops Something went wrong.");
                    }
                }
            });
    };

    async function deleteTask(leadID, taskID, parentType, getActivity) {
        const data = {
            leadId: leadID,
            dealId: leadID,
            id: taskID,
        };

        try {
            const willDelete = await swal({
                title: "Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });

            if (willDelete) {
                try {
                    const response = await axios.delete(`api/${parentType}-task/destroy`, { data });

                    if (response.status === 200) {
                        toast.success("Task deleted successfully.");
                        setReloadPage(reloadPage ? false : true)
                        getActivity();
                    }
                } catch (error) {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast.warning(error.response.data.message);
                        } else if (error.response.status === 403) {
                            toast.warning("Forbidden " + error.response.data.message);
                        } else {
                            toast.error("Oops Something went wrong.");
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error in swal: ", error);
        }
    }
    //===================END: Writing code for Add and Edit Task========================//

    //================START:Handle Event (Add and Edit) Activity====================//
    const [showEventDefault, setShowEventDefault] = useState(false);
    //const [showTaskDefault, setShowTaskDefault] = useState(false);

    const [allDaySelect, setAllDaySelect] = useState(false);
    const [submitEvent, setSubmitEvent] = useState(false);

    const eventEDate = new Date(Date.now() + 1 * (60 * 60 * 1000));

    const [startDate, setStartDate] = useState(
        new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone }))
    );
    const [endDate, setEndDate] = useState(
        new Date(eventEDate.toLocaleString("en-US", { timeZone: defaultTimeZone }))
    );
    //const [dueDate, setDueDate] = useState(new Date(new Date().toLocaleString("en-US", { timeZone: user.preferred_timezone ? user.preferred_timezone : '', })));

    const [fullStartDate, setFullStartDate] = useState(
        new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone }))
    );
    const [fullEndDate, setFullEndDate] = useState(
        new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone }))
    );

    const handleEventClose = () => setShowEventDefault(false);
    //const handleTaskClose = () => setShowTaskDefault(false);

    const [eventInput, setEventInput] = useState({
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        location: "",
        error_list: [],
    });

    const eventNameRef = useRef();
    const eventDescriptionRef = useRef();
    const eventStartDateRef = useRef();
    const eventEndDateRef = useRef();
    const eventLocationRef = useRef();

    // const [eventNameError, setEventNameError] = useState(false);
    // const [eventDescriptionError, setEventDescriptionError] = useState(false);
    // const [eventStartDateError, setEventStartDateError] = useState(false);
    // const [eventEndDateError, setEventEndDateError] = useState(false);

    const [formEventNameError, setFormEventNameError] = useState(false);
    const [formEventDescriptionError, setFormEventDescriptionError] =
        useState(false);
    const [formEventStartDateError, setFormEventStartDateError] = useState(false);
    const [formEventEndDateError, setFormEventEndDateError] = useState(false);
    const [formEventLocationError, setFormEventLocationError] = useState(false);

    const [formEventFullStartDateError, setFormEventFullStartDateError] =
        useState(false);
    const [formEventFullEndDateError, setFormEventFullEndDateError] =
        useState(false);

    // const setStartDateValue = (e) => {
    //   setStartDate(moment(e).format("YYYY-MM-DD HH:mm:ss"));
    // };

    // const setEndDateValue = (e) => {
    //   setEndDate(moment(e).format("YYYY-MM-DD HH:mm:ss"));
    // };

    const handleEventInput = (e) => {
        e.persist();
        let names = e.target.name;
        let values = e.target.value;

        validateEventFormData(names, values);
        setEventInput({ ...eventInput, [e.target.name]: e.target.value });
    };

    function validateEventFormData(type, value) {
        if (type == "name") {
            if (value == "") {
                setFormEventNameError(true);
                //setEventNameError(true);
            } else if (value.length < 2) {
                setFormEventNameError(false);
                //setEventNameError(true);
                setEventInput(
                    (eventInput.error_list.name = "Must be at least 2 characters")
                );
            } else {
                setFormEventNameError(false);
                if (value != "") {
                    //setEventNameError(false);
                    setEventInput((eventInput.error_list.name = ""));
                }
            }
        }

        if (type == "description") {
            var str = value.replace(/\s/g, "");
            if (value == "") {
                setFormEventDescriptionError(true);
                //setEventDescriptionError(true);
            } else if (str.length < 2) {
                setFormEventDescriptionError(false);
                //setEventDescriptionError(true);
                setEventInput(
                    (eventInput.error_list.description =
                        "Must be at least 2 characters, no space allow")
                );
            } else {
                setFormEventDescriptionError(false);
                if (value != "") {
                    //setEventDescriptionError(false);
                    setEventInput((eventInput.error_list.description = ""));
                }
            }
        }

        if (type == "start_date") {
            if (value == "") {
                setFormEventStartDateError(true);
                //setEventStartDateError(true);
            } else {
                setFormEventDescriptionError(false);
                if (value != "") {
                    //setEventStartDateError(false);
                    setEventInput((eventInput.error_list.start_date = ""));
                }
            }
        }

        if (type == "end_date") {
            if (value == "") {
                setFormEventEndDateError(true);
                //setEventEndDateError(true);
            } else {
                setFormEventDescriptionError(false);
                if (value != "") {
                    //setEventEndDateError(false);
                    setEventInput((eventInput.error_list.end_date = ""));
                }
            }
        }

    }

    const handleCheckbox = (e) => {
        e.persist();

        allDaySelect ? setAllDaySelect(false) : setAllDaySelect(true);

        setFullStartDate(new Date());
        setFullEndDate(new Date());
    };

    const eventSubmit = async (e) => {
        e.preventDefault();

        if (eventInput.description == "") {
            //setEventDescriptionError(true);
            setFormEventDescriptionError(true);
            eventDescriptionRef.current.focus();
        }

        if (eventInput.name == "") {
            //setEventNameError(true);
            setFormEventNameError(true);
            eventNameRef.current.focus();
        }

        if (allDaySelect) {
            if (fullStartDate == null) {
                setFormEventFullStartDateError(true);
                //eventStartDateRef.current.focus();
            }

            if (fullEndDate == null) {
                setFormEventFullEndDateError(true);
                //eventEndDateRef.current.focus();
            }
        } else {
            if (startDate == null) {
                //setEventStartDateError(true);
                setFormEventStartDateError(true);
                //eventStartDateRef.current.focus();
            }

            if (endDate == null) {
                //setEventEndDateError(true);
                setFormEventEndDateError(true);
                //eventEndDateRef.current.focus();
            }
        }

        // if (!eventNameError && !eventDescriptionError && !eventStartDateError && !eventEndDateError && startDate!=null && endDate!=null) {
        if (
            eventInput.name != "" &&
            eventInput.description != "" &&
            startDate != null &&
            endDate != null
        ) {
            const data = {
                leadId: parentId,
                dealId: parentId,
                name: eventInput.name,
                description: eventInput.description,
                allDay: allDaySelect ? "yes" : "no",
                start_date: allDaySelect
                    ? moment(fullStartDate).format("YYYY-MM-DD")
                    : moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                end_date: allDaySelect
                    ? moment(fullEndDate).format("YYYY-MM-DD")
                    : moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
                location: eventInput.location,
            };
            setSubmitEvent(true);
            await axios.post("/api/" + parentType + "-event/store", data).then((response) => {
                if (response.status === 200) {
                    toast.success("Event has been created successfully");
                    setEventInput({
                        name: "",
                        description: "",
                        start_date: "",
                        end_date: "",
                        error_list: [],
                    });
                    setStartDate("");
                    setEndDate("");
                    setShowEventDefault(false);
                    getActivity();
                }
                setSubmitEvent(false);
            })
                .then((json) => { })
                .catch((error) => {
                    if (error.response) {
                        setSubmitEvent(false);
                        if (error.response.status === 400) {
                            setEventInput({
                                ...eventInput,
                                error_list: error.response.data.validation_errors,
                            });
                        } else if (error.response.status === 403) {
                            toast.warning("Forbidden " + error.response.data.message);
                        } else {
                            toast.warning(error.response.data.message);
                        }
                    }
                });
        }
    };

    //=====================FOR Edit Event=========================
    const [showEditEventDefault, setShowEditEventDefault] = useState(false);
    const handleEditEventClose = () => setShowEditEventDefault(false);

    const [allEditDaySelect, setAllEditDaySelect] = useState(false);

    const [editEventId, seteditEventId] = useState("");

    const [eventName, setEventName] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventLocation, setEventLocation] = useState("");

    const [eventStartDate, setEventStartDate] = useState(new Date());
    const [eventEndDate, setEventEndDate] = useState(new Date());

    const [eventFullStartDate, setEventFullStartDate] = useState(new Date());
    const [eventFullEndDate, setEventFullEndDate] = useState(new Date());
    const [checkPer, setCheckPer] = useState(false);

    const [eventEditNameError, setEventEditNameError] = useState(false);
    const [eventEditDescriptionError, setEventEditDescriptionError] =
        useState(false);
    const [eventEditLocationError, setEventEditLocationError] = useState(false);
    const [eventEditStartDateError, setEventEditStartDateError] = useState(false);
    const [eventEditEndDateError, setEventEditEndDateError] = useState(false);

    const [eventEditNameMsg, setEventEditNameMsg] = useState("");
    const [eventEditDescriptionMsg, setEventEditDescriptionMsg] = useState("");
    const [eventEditLocationMsg, setEventEditLocationMsg] = useState("");

    const [eventEditStartDateMsg, setEventEditStartDateMsg] = useState("");
    const [eventEditEndDateMsg, setEventEditEndDateMsg] = useState("");

    const [formEditEventNameError, setFormEditEventNameError] = useState(false);
    const [formEditEventDescriptionError, setFormEditEventDescriptionError] =
        useState(false);
    const [formEditEventStartDateError, setFormEditEventStartDateError] =
        useState(false);
    const [formEditEventEndDateError, setFormEditEventEndDateError] =
        useState(false);
    const [formEditEventLocationError, setFormEditEventLocationError] =
        useState(false);

    const [submitEditEvent, setSubmitEditEvent] = useState(false);
    const [errorEventEditInput, setErrorEventEditInput] = useState({
        error_list: [],
    });

    const eventEditNameRef = useRef();
    const eventEditDescriptionRef = useRef();
    const eventEditStartDateRef = useRef();
    const eventEditEndDateRef = useRef();
    const eventEditLocationRef = useRef();

    const [eventEditInput, setEventEditInput] = useState({
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        location: "",
        error_list: [],
    });

    async function openEventEditModel(id) {
        seteditEventId(id);
        setCheckPer(false);
        setErrorEventEditInput({ ...errorEventEditInput, error_list: "" });
        setEventEditNameError(false);
        setEventEditDescriptionError(false);
        setEventEditEndDateError(false);
        setEventEditStartDateError(false);

        //setAllEditDaySelect(false)
        //setLoading(true);
        await axios.get("api/" + parentType + "-event/edit/" + id + "?" + parentType + "Id=" + parentId)
            .then((response) => {
                if (response.data.code === 200) {
                    setEventName(response.data.data.record.name);
                    setEventDescription(response.data.data.record.description);
                    setEventStartDate(new Date(moment.utc(response.data.data.record.start_date).tz(defaultTimeZone).format(preferredDateFormatData)));
                    setEventEndDate(new Date(moment.utc(response.data.data.record.end_date).tz(defaultTimeZone).format(preferredDateFormatData)));
                    setEventLocation(response.data.data.record.location);

                    setEventEditInput({
                        name: response.data.data.record.name,
                        description: response.data.data.record.description,
                        start_date: new Date(response.data.data.record.start_date),
                        end_date: new Date(response.data.data.record.end_date),
                        location: response.data.data.record.location,
                        error_list: [],
                    });

                    //check if full day event or not
                    if (response.data.data.record.is_fullday == 1) {
                        setCheckPer(true);
                        // var fullDayStartEvent = moment.tz(response.data.data.record.start_date, defaultTimeZone).format(preferredDateFormatData)
                        // var fullDayEndEvent = moment.tz(response.data.data.record.end_date, defaultTimeZone).format(preferredDateFormatData)

                        var fullDayStartEvent = moment.tz(response.data.data.record.start_date, defaultTimeZone);
                        var fullDayEndEvent = moment.tz(response.data.data.record.end_date, defaultTimeZone);

                        setEventFullStartDate(new Date(fullDayStartEvent));
                        setEventFullEndDate(new Date(fullDayEndEvent));

                        setAllEditDaySelect(true);
                    } else {
                        setAllEditDaySelect(false);
                    }
                    setLoading(false);
                }
                setShowEditEventDefault(true);
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    if (error.response.status === 401 || error.response.status === 400) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.error("Oops Something went wrong.");
                    }
                }
            });
    }

    const handleEditEventInput = (e) => {
        e.persist();
        validateEventEditFormData(e.target.name, e.target.value);
        setEventEditInput({ ...eventEditInput, [e.target.name]: e.target.value });
    };

    function validateEventEditFormData(type, value) {
        if (type == "name" && value == "") {
            setFormEditEventNameError(false);
            setEventEditNameError(true);
            setEventEditInput((eventEditInput.error_list.name = "Enter event name"));
        } else {
            setFormEditEventNameError(false);
            if (type == "name" && value != "") {
                setEventEditNameError(false);
                setEventEditInput((eventEditInput.error_list.name = ""));
            }
        }

        if (type == "description" && value == "") {
            setFormEditEventDescriptionError(false);
            setEventEditDescriptionError(true);
            setEventEditInput(
                (eventEditInput.error_list.description = "Enter event description")
            );
        } else {
            setFormEditEventDescriptionError(false);
            if (type == "description" && value != "") {
                setEventEditDescriptionError(false);
                setEventEditInput((eventEditInput.error_list.description = ""));
            }
        }

        if (type == "start_date" && (value == "" || value == null)) {
            setEventEditStartDateError(true);
            setEventEditStartDateMsg("Enter start date");
        } else {
            setEventEditStartDateError(false);
            setEventEditStartDateMsg("");
        }

        if (type == "end_date" && (value == "" || value == null)) {
            setEventEditEndDateError(true);
            setEventEditEndDateMsg("Enter end date");
        } else {
            setEventEditEndDateError(false);
            setEventEditEndDateMsg("");
        }
    }

    const setEventEditStartDateValue = (e) => {
        setEventStartDate(moment(e).format("YYYY-MM-DD HH:mm:ss"));
    };

    const setEventEditEndDateValue = (e) => {
        setEventEndDate(moment(e).format("YYYY-MM-DD HH:mm:ss"));
    };

    const handleEditCheckbox = (e) => {
        e.persist();
        allEditDaySelect ? setAllEditDaySelect(false) : setAllEditDaySelect(true);
        checkPer ? setCheckPer(false) : setCheckPer(true)
    };

    const handleEventSubmit = async (e) => {
        e.preventDefault();

        if (eventEditInput.name == "") {
            setEventEditNameError(true);
            setFormEditEventNameError(true);
            eventEditNameRef.current.focus();
        }

        if (eventEditInput.description == "") {
            //setEventDescriptionError(true);
            setFormEventDescriptionError(true);
            eventEditDescriptionRef.current.focus();
        }

        if (eventEditInput.start_date == null) {
            setEventEditStartDateError(true);
            setFormEditEventStartDateError(true);
        }

        if (eventEditInput.end_date == null) {
            setEventEditEndDateError(true);
            setFormEditEventEndDateError(true);
        }

        if (
            !eventEditNameError &&
            !eventEditDescriptionError &&
            !eventEditStartDateError &&
            !eventEditEndDateError
        ) {
            const data = {
                leadId: parentId,
                dealId: parentId,
                id: editEventId,
                name: eventEditInput.name,
                description: eventEditInput.description,
                location: eventEditInput.location,
                allDay: allEditDaySelect ? "yes" : "no",
                start_date: allEditDaySelect
                    ? moment(eventFullStartDate).format("YYYY-MM-DD")
                    : moment(eventStartDate).format("YYYY-MM-DD HH:mm:ss"),
                end_date: allEditDaySelect
                    ? moment(eventFullEndDate).format("YYYY-MM-DD")
                    : moment(eventEndDate).format("YYYY-MM-DD HH:mm:ss"),
            };
            setSubmitEditEvent(true);
            await axios.post("api/" + parentType + "-event/update", data).then((response) => {
                getActivity();
                setSubmitEditEvent(false);
                setShowEditEventDefault(false);
            })
                .then((json) => { })
                .catch((error) => {
                    if (error.response) {
                        setSubmitEditEvent(false);
                        if (error.response.status === 400) {
                            setErrorEventEditInput({
                                ...errorEventEditInput,
                                error_list: error.response.data.validation_errors,
                            });
                        } else if (error.response.status === 300) {
                            toast.warning(error.response.data.message);
                        } else if (error.response.status === 403) {
                            toast.warning("Forbidden " + error.response.data.message);
                        } else {
                            toast.error("Oops Something went wrong.");
                        }
                    }
                });
        }
    };

    async function deleteEvent(leadID, eventID, parentType, getActivity) {
        const data = {
            leadId: leadID,
            dealId: leadID,
            id: eventID,
        };

        try {
            const willDelete = await swal({
                title: "Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });

            if (willDelete) {
                try {
                    const response = await axios.delete(`api/${parentType}-event/destroy`, { data });

                    if (response.status === 200) {
                        toast.success("Event deleted successfully");
                        setReloadPage(reloadPage ? false : true)
                        getActivity();
                    }
                } catch (error) {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast.warning(error.response.data.message);
                        } else if (error.response.status === 403) {
                            toast.warning("Forbidden " + error.response.data.message);
                        } else {
                            toast.error(error.response.data.message);
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Error in swal: ", error);
        }
    }
    //================END:Handle Event (Add and Edit) Activity====================//

    function showAddEventModel() {
        setAllDaySelect(false);
        setStartDate(
            new Date(
                new Date().toLocaleString("en-US", { timeZone: defaultTimeZone })
            )
        );
        setEndDate(
            new Date(
                eventEDate.toLocaleString("en-US", { timeZone: defaultTimeZone })
            )
        );
        setEventInput({ name: "", description: "", error_list: [] });

        //setEventStartDateError(false);
        setFormEventStartDateError(false);

        setFormEventNameError(false);
        setFormEventDescriptionError(false);

        //setEventEndDateError(false);
        setFormEventEndDateError(false);

        setShowEventDefault(true);
    }

    function showAddTaskModel() {
        setTaskInput({
            name: "",
            description: "",
            priority: "",
            activity_status: "",
            error_list: [],
        });

        setPriorityVal("low");
        setTaskStatusVal("not-started");
        setShowTaskDefault(true);
    }



    //===================START: Writing code for Add and Edit Log a visit======== Log-a-visit ================//
    const [showLogDefault, setShowLogDefault] = useState(false);
    const handleLogClose = () => setShowLogDefault(false);
    const [logAttachmentArray, setLogAttachmentArray] = useState([]);






    const [logDate, setLogDate] = useState(
        new Date(new Date(currentData).setMinutes(currentData.getMinutes() + 15))
    );

    const [logInput, setLogInput] = useState({
        name: "",
        description: "",
        end_date: moment(new Date(new Date(currentData).setMinutes(currentData.getMinutes() + 15))).format("YYYY-MM-DD HH:mm:ss"),
        attachment: [],
        error_list: [],
    });

    function showAddLogModel() {
        setLogDate(new Date(new Date(currentData).setMinutes(currentData.getMinutes() + 15)))
        setShowLogDefault(true);
    }

    const logLengthValidation = (fieldLength) => {
        var valid = true;
        if (fieldLength) {
            var str = fieldLength.replace(/\s/g, "");
            if (str.length < 2) {
                valid = false;
            }
        }

        return valid
            ? {
                isValid: true,
            }
            : {
                isValid: false,
                errorMessage: "Must be at least 2 characters, no space allow",
            };
    };

    const logValidationSchema = yup.object().shape({
        name: yup
            .string()
            .nullable()
            .min(2, "The name must be at least 2 characters.")
            .max(50, "The name must not be greater than 50 characters."),
        //.required("Enter subject"),
        description: yup
            .string()
            .min(2, "The description must be at least 2 characters.")
            .max(150, "The description must not be greater than 150 characters.")
            .required("Enter description")
            .test("validator-custom-length", function (value) {
                const validation = logLengthValidation(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                } else {
                    return true;
                }
            }),
        end_date: yup.string().required('Enter visit date'),
    });

    const [submitLog, setSubmitLog] = useState(false);


    const handleLogDate = (date) => {
        if (date) {
            setLogDate(date);
        }
    };

    const handleLogSubmit = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.append('leadId', parentId)
        formData.append('dealId', parentId)
        formData.append('type', parentType)
        formData.append('name', values.name)
        formData.append('description', values.description)
        formData.append('activity_type', 'Log a Visit')
        formData.append('activity_attachment_type', 'Attachment')
        formData.append('end_date', logDate ? moment(logDate).format("YYYY-MM-DD HH:mm:ss") : '')
        formData.append('latitude', props.data.latitude)
        formData.append('longitude', props.data.longitude)
        for (var i = 0; i < logAttachmentArray.length; i++) {
            formData.append('attachment[]', logAttachmentArray[i])
        }

        await axios.post("/api/" + parentType + "-log-visit/store", formData).then((response) => {
            if (response.status === 200) {
                toast.success("Your Visit log has been added");
                resetForm()
                setLogAttachmentArray([])
                setLogDate("");
                setShowLogDefault(false);
                getActivity();
            }
            setSubmitLog(false);
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    setSubmitLog(false);
                    if (error.response.status === 400) {
                        setLogInput({
                            ...logInput,
                            error_list: error.response.data.validation_errors,
                        });

                        const laravelErrors = error.response.data.validation_errors;
                        Object.keys(laravelErrors).forEach((field) => {
                            setHandleErrorMessages(handleErrorMessages => [...handleErrorMessages, { field: field, message: laravelErrors[field][0] }]);
                        });

                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.success(error.response.data.message);
                    }
                }
            });
    };

    const handleAddLogAttachment = (e) => {
        setLogAttachmentArray([...logAttachmentArray, ...e.target.files]);
    }

    //====================End :Write code for Add or edit Log a visit===================================


    //====================Start :Edit a Log a visit===================================


    const [showEditLogDefault, setShowEditLogDefault] = useState(false);
    const handleEditLogClose = () => setShowEditLogDefault(false);

    const [editLogId, setEditLogId] = useState("");
    const [LogData, setLogData] = useState("");
    const [editLogEndDate, setEditLogEndDate] = useState("");

    const [errorLogEditInput, setErrorLogEditInput] = useState({
        error_list: [],
    });

    const [logEditInput, setLogEditInput] = useState({
        name: "",
        description: "",
        end_date: new Date(),
        attachment: "",
        error_list: [],
    });

    async function openLogEditModel(id) {
        setEditLogId(id);
        setErrorLogEditInput({ ...errorLogEditInput, error_list: "" });
        await axios.get("api/" + parentType + "-log-visit/edit/" + id + "?" + parentType + "Id=" + parentId + "&type=" + parentType).then((response) => {
            if (response.data.code === 200) {
                setLogData(response.data.data.record);
                setLogEditInput({
                    name: response.data.data.record.name,
                    description: response.data.data.record.description,
                    end_date: new Date(response.data.data.record.end_date),
                    error_list: [],
                });

                setEditLogEndDate(new Date(moment.utc(response.data.data.record.end_date).tz(defaultTimeZone).format('YYYY-MM-DD HH:mm:ss')));
            }
            setShowEditLogDefault(true);
        })
            .then((json) => { })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.error("Oops Something went wrong.");
                    }
                }
            });
    }

    //const [errorMessages, setErrorMessages] = useState([]);

    const [handleErrorMessages, setHandleErrorMessages] = useState([]);

    const handleEditLogSave = async (values) => {

        const formData = new FormData();
        formData.append('id', editLogId)
        formData.append('leadId', parentId)
        formData.append('dealId', parentId)
        formData.append('type', parentType)
        formData.append('name', values.name)
        formData.append('description', values.description)
        formData.append('activity_type', 'Log a Visit')
        formData.append('activity_attachment_type', 'Attachment')
        formData.append('end_date', editLogEndDate ? moment(editLogEndDate).format("YYYY-MM-DD HH:mm:ss") : '')
        for (var i = 0; i < logAttachmentArray.length; i++) {
            formData.append('attachment[]', logAttachmentArray[i])
        }

        await axios.post("api/" + parentType + "-log-visit/update", formData).then((response) => {
            if (response.data.code === 200) {

                setShowEditLogDefault(false);
                getActivity();
                setLogAttachmentArray([])
                setHandleErrorMessages([])
            }
        }).then((json) => { })

            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorLogEditInput({
                            ...errorLogEditInput,
                            error_list: error.response.data.validation_errors,
                        });

                        //setErrorMessages(Object.values(error.response.data.validation_errors).flatMap(messages => messages));

                        const laravelErrors = error.response.data.validation_errors;

                        // Object.keys(laravelErrors).forEach((field) => {
                        //   setHandleErrorMessages(field, {
                        //         type: "manual",
                        //         message: laravelErrors[field][0],
                        //     });
                        // });

                        Object.keys(laravelErrors).forEach((field) => {
                            setHandleErrorMessages(handleErrorMessages => [...handleErrorMessages, { field: field, message: laravelErrors[field][0] }]);
                        });

                    } else if (error.response.status === 300) {
                        toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                        toast.warning("Forbidden " + error.response.data.message);
                    } else {
                        toast.error("Oops Something went wrong.");
                    }
                }
            });
    };

    async function deleteLog(leadID, id) {
        const data = {
            leadId: leadID,
            dealId: leadID,
            type: parentType,
            id: id,
        };

        try {
            const willDelete = await swal({
                title: "Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });

            if (willDelete) {
                try {
                    const response = await axios.delete(`api/${parentType}-log-visit/destroy`, { data });
                    if (response.status === 200) {
                        toast.success("Your Visit Log has been deleted");
                        setReloadPage(reloadPage ? false : true)
                        getActivity();
                    }
                } catch (error) {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast.warning(error.response.data.message);
                        } else if (error.response.status === 403) {
                            toast.warning("Forbidden " + error.response.data.message);
                        } else {
                            toast.error("Oops Something went wrong.");
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Swal error: ", error);
        }
    }
    // =========================End: Edit Log a Visit Code===========================//


    async function deleteEditFile(e, fileNameToRemove) {
        const s = logAttachmentArray.filter((item, index) => index !== e);
        setLogAttachmentArray(s);

        //const updatedHandleErrorMessages = handleErrorMessages.filter(item => item.field == fileNameToRemove);
        setHandleErrorMessages([]);

    }


    const deleteLogVisitAttachment = async (activityID, attachmentID) => {
        const data = {
            id: activityID,
            attachment_id: attachmentID,
            type: parentType,
            leadId: parentId,
            dealId: parentId,
        };

        try {
            const willDelete = await swal({
                title: "Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });

            if (willDelete) {
                try {
                    const response = await axios.delete(`api/${parentType}-log-visit-attachment/destroy`, { data });

                    if (response.status === 200) {
                        toast.success("Attachment deleted successfully.");
                        setReloadPage(reloadPage ? false : true)
                        getActivity();
                    }
                } catch (error) {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast.warning(error.response.data.message);
                        } else if (error.response.status === 403) {
                            toast.warning("Forbidden " + error.response.data.message);
                        } else {
                            toast.error("Oops Something went wrong.");
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Error in swal: ", error);
        }
    };













    // =========================Start: Write code for Display Activity===========================//

    //let sortedActivityArray = allActivity.sort((a, b) => new Date(...a.start_date.split('/').reverse()) - new Date(...b.start_date.split('/').reverse()));
    let sortedActivityArray = allActivity;

    let currentDate = new Date(
        new Date().toLocaleString("en-US", { timeZone: defaultTimeZone })
    );
    const addZero = (num) => `${num}`.padStart(2, "0");
    let newDate = `${currentDate.getFullYear()}-${addZero(currentDate.getMonth() + 1)}-${addZero(currentDate.getDate())} ${addZero(currentDate.getHours())}:${addZero(currentDate.getMinutes())}:${addZero(currentDate.getSeconds())}`;
    var ActivityIcon = "";
    var lineClassName = false;
    var arrowIcon = "inactivesicon";
    var displayTax = "";
    var NewdisplayTax = "";
    var isDisplayName = true;
    var removeLineLastEle = true;
    var activityEndDateate = ''

    if (displayDateFor && displayDateFor === "DD-MM-yyyy") {
        setDisplayDateFor("DD-MMM-yyyy");
    } else if (displayDateFor && displayDateFor === "MM-DD-yyyy") {
        setDisplayDateFor("MMM-DD-yyyy");
    } else if (displayDateFor && displayDateFor === "YYYY-MMM-DD") {
        setDisplayDateFor("yyyy-MMM-DD");
    }

    let displayPlanedActivity =
        sortedActivityArray.length &&
        sortedActivityArray.map((item, i) => {
            if (i + 1 === sortedActivityArray.length) {
                removeLineLastEle = false;
            }

            activityEndDateate = item.end_date ? moment.utc(item.end_date).tz(defaultTimeZone).format('YYYY-MM-DD HH:mm:ss') : item.end_date

            if (item.is_fullday === 1 && item.activity_type === 'event') {
                activityEndDateate = activityEndDateate.split(" ");
                activityEndDateate = activityEndDateate[0] + " 23:59:59";
            }

            let setActiveClass =
                "eventborder active-line activeclass display align-items-start";

            const activityIconMap = {
                "event": "activityicon",
                "task": "trickicon",
                "Log a Visit": "log-visit"
            };

            //console.log(activityEndDateate, '>=', newDate ,'&&', item.is_completed)




            if (activityEndDateate >= newDate && (item.is_completed === 0)) {
                setActiveClass =
                    "eventborder inactive-line display align-items-start";
                ActivityIcon = activityIconMap[item.activity_type] || "defaultIcon";
                lineClassName = true;
                displayTax = "Planned";
                arrowIcon = "inactivesicon";
                // } else {
                //   ActivityIcon = activityIconMap[item.activity_type] || "defaultIcon";
                //   displayTax = "Done";
                //   arrowIcon = "active_icon";
                // }



                if (displayTax === NewdisplayTax) {
                    isDisplayName = false;
                } else {
                    isDisplayName = true;
                }

                NewdisplayTax = displayTax;

                return (
                    <>
                        <div key={i} className="removeline">
                            <div className="event_body p2023 notespadding">
                                {isDisplayName ? (
                                    <div className="text-right mt15">
                                        <span className="eventstatus text-right">{displayTax}</span>
                                    </div>
                                ) : (
                                    ""
                                )}

                                <div className="events mt15 display align-items-start">
                                    <div className="col-md-1">
                                        <span className={ActivityIcon}>
                                            {removeLineLastEle ? (
                                                <div className={lineClassName}></div>
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </div>


                                    {

                                        item.activity_type === "event" ?
                                            <div className="col-md-11">
                                                <div className={setActiveClass}>
                                                    <span className={arrowIcon}></span>
                                                    <div className="event_details">
                                                        <div className="display align-items-start w-6">
                                                            {/* <input type="radio" name="name" /> */}
                                                            <Form>
                                                                {item.is_completed == 0 ? (
                                                                    <fieldset title="Click to complete task">
                                                                        <Checkbox
                                                                            icon={
                                                                                <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                            }
                                                                            name="is_completed"
                                                                            onChange={(value) => {
                                                                                let p = {
                                                                                    isTrue: value,
                                                                                };
                                                                                handleEventChange(value, item.id);
                                                                            }}
                                                                            checked={
                                                                                item.is_completed == 1 ? "checked" : ""
                                                                            }
                                                                            borderColor="#cccccc"
                                                                            className="checkmark1"
                                                                            style={{ cursor: "pointer" }}
                                                                            labelStyle={{
                                                                                marginLeft: 5,
                                                                                userSelect: "none",
                                                                            }}
                                                                            label=""
                                                                        />
                                                                    </fieldset>
                                                                ) : (
                                                                    <fieldset title="Task is completed">
                                                                        <Checkbox
                                                                            icon={
                                                                                <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                            }
                                                                            name="is_completed"
                                                                            onChange={(value) => {
                                                                                let p = {
                                                                                    isTrue: value,
                                                                                };
                                                                                handleEventChange(value, item.id);
                                                                            }}
                                                                            checked={
                                                                                item.is_completed == 1 ? "checked" : ""
                                                                            }
                                                                            borderColor="#cccccc"
                                                                            className="checkmark1 checks"
                                                                            style={{ cursor: "pointer" }}
                                                                            labelStyle={{
                                                                                marginLeft: 5,
                                                                                userSelect: "none",
                                                                            }}
                                                                            label=""
                                                                        />
                                                                    </fieldset>
                                                                )}
                                                            </Form>
                                                        </div>
                                                        <div className="display activity-content">
                                                            <h6
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    openEventEditModel(item.id);
                                                                }}
                                                            >
                                                                {item.name}
                                                            </h6>
                                                            <p>{item.description}</p>
                                                            <div className="display">
                                                                <p>{item.priority}</p>
                                                                <p>{item.activity_status}</p>
                                                            </div>
                                                            {item.is_fullday ? (
                                                                <div className="display mt5">
                                                                    {/* <p>{moment.tz(item.end_date, defaultTimeZone).format(displayDateFor)}</p> */}
                                                                    <p>
                                                                        {item.is_completed == 1
                                                                            ?
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(displayDateFor)
                                                                            :
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(displayDateFor)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div className="display mt5">
                                                                    {/* <p>{ moment.tz(item.end_date, defaultTimeZone).format(preferredDateFormatData)}</p> */}
                                                                    <p>

                                                                        {item.is_completed == 1
                                                                            ?
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                            :
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="event_action">
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                id="dropdown-basic"
                                                                className="transparentbg"
                                                            >
                                                                <span className="listdot"></span>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {userPermission ? (
                                                                    userPermission.includes("event_edit") ? (
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                openEventEditModel(item.id);
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </Dropdown.Item>
                                                                    ) : (
                                                                        ""
                                                                    )
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {userPermission ? (
                                                                    userPermission.includes("event_delete") ? (
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                deleteEvent(parentId, item.id, parentType);
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    ) : (
                                                                        ""
                                                                    )
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            item.activity_type == "task" ?
                                                <div className="col-md-11">
                                                    <div className={setActiveClass}>
                                                        <span className={arrowIcon}></span>
                                                        <div className="event_details">
                                                            <div className="display align-items-start w-6">
                                                                {/* <input type="radio" name="name" /> */}
                                                                <Form>
                                                                    {item.is_completed == 0 ? (
                                                                        <fieldset title="Click to complete task">
                                                                            <Checkbox
                                                                                icon={
                                                                                    <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                                }
                                                                                name="is_completed"
                                                                                onChange={(value) => {
                                                                                    let p = {
                                                                                        isTrue: value,
                                                                                    };
                                                                                    handleTackChange(value, item.id);
                                                                                }}
                                                                                checked={
                                                                                    item.is_completed == 1 ? "checked" : ""
                                                                                }
                                                                                borderColor="#cccccc"
                                                                                className="checkmark1"
                                                                                style={{ cursor: "pointer" }}
                                                                                labelStyle={{
                                                                                    marginLeft: 5,
                                                                                    userSelect: "none",
                                                                                }}
                                                                                label=""
                                                                            />
                                                                        </fieldset>
                                                                    ) : (
                                                                        <fieldset title="Task is completed">
                                                                            <Checkbox
                                                                                icon={
                                                                                    <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                                }
                                                                                name="is_completed"
                                                                                onChange={(value) => {
                                                                                    let p = {
                                                                                        isTrue: value,
                                                                                    };
                                                                                    handleTackChange(value, item.id);
                                                                                }}
                                                                                checked={
                                                                                    item.is_completed == 1 ? "checked" : ""
                                                                                }
                                                                                borderColor="#cccccc"
                                                                                className="checkmark1 checks"
                                                                                style={{ cursor: "pointer" }}
                                                                                labelStyle={{
                                                                                    marginLeft: 5,
                                                                                    userSelect: "none",
                                                                                }}
                                                                                label=""
                                                                            />
                                                                        </fieldset>
                                                                    )}
                                                                </Form>
                                                            </div>
                                                            <div className="display activity-content">
                                                                <h6
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        openTaskEditModel(item.id);
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </h6>
                                                                <p>{item.description}</p>
                                                                <div className="display justify-content-between mtb8">
                                                                    <h5 className={"lb10 " + item.priority}>
                                                                        {item.priority
                                                                            ? item.priority.charAt(0).toUpperCase() +
                                                                            item.priority.slice(1).toLowerCase()
                                                                            : "N/A"}
                                                                    </h5>
                                                                    <h5 className={item.activity_status}>
                                                                        {item.activity_status
                                                                            ? item.activity_status.charAt(0).toUpperCase() +
                                                                            item.activity_status.slice(1).toLowerCase()
                                                                            : "N/A"}
                                                                    </h5>
                                                                </div>
                                                                <div className="display mt5">
                                                                    {/* <p>{ moment.tz(item.end_date, defaultTimeZone).format(preferredDateFormatData)}</p> */}
                                                                    <p>
                                                                        {item.is_completed == 1
                                                                            ?
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                            :
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event_action">
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    id="dropdown-basic"
                                                                    className="transparentbg"
                                                                >
                                                                    <span className="listdot"></span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {userPermission ? (
                                                                        userPermission.includes("task_edit") ? (
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    openTaskEditModel(item.id);
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </Dropdown.Item>
                                                                        ) : (
                                                                            ""
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {userPermission ? (
                                                                        userPermission.includes("task_delete") ? (
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    deleteTask(parentId, item.id, parentType);
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </Dropdown.Item>
                                                                        ) : (
                                                                            ""
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                :

                                                item.activity_type == "Log a Visit" ?
                                                    <div className="col-md-11">
                                                        <div className={setActiveClass}>
                                                            <span className={arrowIcon}></span>
                                                            <div className="event_details">
                                                                <div className="display align-items-start w-6">
                                                                    {/* <input type="radio" name="name" /> */}
                                                                    <Form>
                                                                        {item.is_completed == 0 ? (
                                                                            <fieldset title="Click to complete log visit">
                                                                                <Checkbox
                                                                                    icon={
                                                                                        <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                                    }
                                                                                    name="is_completed"
                                                                                    onChange={(value) => {
                                                                                        let p = {
                                                                                            isTrue: value,
                                                                                        };
                                                                                        handleTackChange(value, item.id);
                                                                                    }}
                                                                                    checked={
                                                                                        item.is_completed == 1 ? "checked" : ""
                                                                                    }
                                                                                    borderColor="#cccccc"
                                                                                    className="checkmark1"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    labelStyle={{
                                                                                        marginLeft: 5,
                                                                                        userSelect: "none",
                                                                                    }}
                                                                                    label=""
                                                                                />
                                                                            </fieldset>
                                                                        ) : (
                                                                            <fieldset title="Log visit is completed">
                                                                                <Checkbox
                                                                                    icon={
                                                                                        <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                                    }
                                                                                    name="is_completed"
                                                                                    onChange={(value) => {
                                                                                        let p = {
                                                                                            isTrue: value,
                                                                                        };
                                                                                        handleTackChange(value, item.id);
                                                                                    }}
                                                                                    checked={
                                                                                        item.is_completed == 1 ? "checked" : ""
                                                                                    }
                                                                                    borderColor="#cccccc"
                                                                                    className="checkmark1 checks"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    labelStyle={{
                                                                                        marginLeft: 5,
                                                                                        userSelect: "none",
                                                                                    }}
                                                                                    label=""
                                                                                />
                                                                            </fieldset>
                                                                        )}
                                                                    </Form>
                                                                </div>
                                                                <div className="display activity-content">
                                                                    <h6
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            openLogEditModel(item.id);
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </h6>
                                                                    <p>{item.description}</p>

                                                                    <div className="display mt5">
                                                                        <p>
                                                                            {item.is_completed == 1
                                                                                ?
                                                                                moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                                :
                                                                                moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    {item.attachment && item.attachment.length >= 1 && item.attachment.map((attach_item, j) => (
                                                                        <div className="display mt5" key={j}>
                                                                            <div className="attachment-file" style={{ display: 'flex', alignItems: 'center', justifyItems: 'end' }}>
                                                                                <a target="_blank" href={`${process.env.REACT_APP_API_URL}storage/${attach_item.file_url}`}>
                                                                                    <span> {attach_item.file_url.substr(attach_item.file_url.length - 30)} </span>
                                                                                </a>
                                                                                <span className="deleteicon1" onClick={() => deleteLogVisitAttachment(item.id, attach_item.id)} style={{ backgroundSize: '14px', marginLeft: '5px' }}></span>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="event_action">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle
                                                                        id="dropdown-basic"
                                                                        className="transparentbg"
                                                                    >
                                                                        <span className="listdot"></span>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {userPermission ? (
                                                                            userPermission.includes("task_edit") ? (
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        openLogEditModel(item.id);
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Dropdown.Item>
                                                                            ) : (
                                                                                ""
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {userPermission ? (
                                                                            userPermission.includes("task_delete") ? (
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        deleteLog(parentId, item.id, parentType);
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </Dropdown.Item>
                                                                            ) : (
                                                                                ""
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    ''
                                    }


                                </div>
                            </div>
                        </div>
                    </>
                );

            }
        });


    let displayDoneActivity =
        sortedActivityArray.length &&
        sortedActivityArray.map((item, i) => {
            if (i + 1 === sortedActivityArray.length) {
                removeLineLastEle = false;
            }

            activityEndDateate = item.end_date ? moment.utc(item.end_date).tz(defaultTimeZone).format('YYYY-MM-DD HH:mm:ss') : item.end_date

            if (item.is_fullday === 1 && item.activity_type === 'event') {
                activityEndDateate = activityEndDateate.split(" ");
                activityEndDateate = activityEndDateate[0] + " 23:59:59";
            }

            let setActiveClass =
                "eventborder active-line activeclass display align-items-start";

            const activityIconMap = {
                "event": "activityicon",
                "task": "trickicon",
                "Log a Visit": "log-visit"
            };

            //console.log(activityEndDateate, '>=', newDate ,'&&', item.is_completed)




            if (activityEndDateate >= newDate && (item.is_completed === 0)) {

            } else {
                ActivityIcon = activityIconMap[item.activity_type] || "defaultIcon";
                displayTax = "Done";
                arrowIcon = "active_icon";




                if (displayTax === NewdisplayTax) {
                    isDisplayName = false;
                } else {
                    isDisplayName = true;
                }

                NewdisplayTax = displayTax;

                return (
                    <>
                        <div key={i} className="removeline">
                            <div className="event_body p2023 notespadding">
                                {isDisplayName ? (
                                    <div className="text-right mt15">
                                        <span className="eventstatus text-right">{displayTax}</span>
                                    </div>
                                ) : (
                                    ""
                                )}

                                <div className="events mt15 display align-items-start">
                                    <div className="col-md-1">
                                        <span className={ActivityIcon}>
                                            {removeLineLastEle ? (
                                                <div className={lineClassName}></div>
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </div>


                                    {

                                        item.activity_type === "event" ?
                                            <div className="col-md-11">
                                                <div className={setActiveClass}>
                                                    <span className={arrowIcon}></span>
                                                    <div className="event_details">
                                                        <div className="display align-items-start w-6">
                                                            {/* <input type="radio" name="name" /> */}
                                                            <Form>
                                                                {item.is_completed == 0 ? (
                                                                    <fieldset title="Click to complete task">
                                                                        <Checkbox
                                                                            icon={
                                                                                <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                            }
                                                                            name="is_completed"
                                                                            onChange={(value) => {
                                                                                let p = {
                                                                                    isTrue: value,
                                                                                };
                                                                                handleEventChange(value, item.id);
                                                                            }}
                                                                            checked={
                                                                                item.is_completed == 1 ? "checked" : ""
                                                                            }
                                                                            borderColor="#cccccc"
                                                                            className="checkmark1"
                                                                            style={{ cursor: "pointer" }}
                                                                            labelStyle={{
                                                                                marginLeft: 5,
                                                                                userSelect: "none",
                                                                            }}
                                                                            label=""
                                                                        />
                                                                    </fieldset>
                                                                ) : (
                                                                    <fieldset title="Task is completed">
                                                                        <Checkbox
                                                                            icon={
                                                                                <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                            }
                                                                            name="is_completed"
                                                                            onChange={(value) => {
                                                                                let p = {
                                                                                    isTrue: value,
                                                                                };
                                                                                handleEventChange(value, item.id);
                                                                            }}
                                                                            checked={
                                                                                item.is_completed == 1 ? "checked" : ""
                                                                            }
                                                                            borderColor="#cccccc"
                                                                            className="checkmark1 checks"
                                                                            style={{ cursor: "pointer" }}
                                                                            labelStyle={{
                                                                                marginLeft: 5,
                                                                                userSelect: "none",
                                                                            }}
                                                                            label=""
                                                                        />
                                                                    </fieldset>
                                                                )}
                                                            </Form>
                                                        </div>
                                                        <div className="display activity-content">
                                                            <h6
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    openEventEditModel(item.id);
                                                                }}
                                                            >
                                                                {item.name}
                                                            </h6>
                                                            <p>{item.description}</p>
                                                            <div className="display">
                                                                <p>{item.priority}</p>
                                                                <p>{item.activity_status}</p>
                                                            </div>
                                                            {item.is_fullday ? (
                                                                <div className="display mt5">
                                                                    {/* <p>{moment.tz(item.end_date, defaultTimeZone).format(displayDateFor)}</p> */}
                                                                    <p>
                                                                        {item.is_completed == 1
                                                                            ?
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(displayDateFor)
                                                                            :
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(displayDateFor)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div className="display mt5">
                                                                    {/* <p>{ moment.tz(item.end_date, defaultTimeZone).format(preferredDateFormatData)}</p> */}
                                                                    <p>

                                                                        {item.is_completed == 1
                                                                            ?
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                            :
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="event_action">
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                id="dropdown-basic"
                                                                className="transparentbg"
                                                            >
                                                                <span className="listdot"></span>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {userPermission ? (
                                                                    userPermission.includes("event_edit") ? (
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                openEventEditModel(item.id);
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </Dropdown.Item>
                                                                    ) : (
                                                                        ""
                                                                    )
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {userPermission ? (
                                                                    userPermission.includes("event_delete") ? (
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                deleteEvent(parentId, item.id, parentType);
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    ) : (
                                                                        ""
                                                                    )
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            item.activity_type == "task" ?
                                                <div className="col-md-11">
                                                    <div className={setActiveClass}>
                                                        <span className={arrowIcon}></span>
                                                        <div className="event_details">
                                                            <div className="display align-items-start w-6">
                                                                {/* <input type="radio" name="name" /> */}
                                                                <Form>
                                                                    {item.is_completed == 0 ? (
                                                                        <fieldset title="Click to complete task">
                                                                            <Checkbox
                                                                                icon={
                                                                                    <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                                }
                                                                                name="is_completed"
                                                                                onChange={(value) => {
                                                                                    let p = {
                                                                                        isTrue: value,
                                                                                    };
                                                                                    handleTackChange(value, item.id);
                                                                                }}
                                                                                checked={
                                                                                    item.is_completed == 1 ? "checked" : ""
                                                                                }
                                                                                borderColor="#cccccc"
                                                                                className="checkmark1"
                                                                                style={{ cursor: "pointer" }}
                                                                                labelStyle={{
                                                                                    marginLeft: 5,
                                                                                    userSelect: "none",
                                                                                }}
                                                                                label=""
                                                                            />
                                                                        </fieldset>
                                                                    ) : (
                                                                        <fieldset title="Task is completed">
                                                                            <Checkbox
                                                                                icon={
                                                                                    <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                                }
                                                                                name="is_completed"
                                                                                onChange={(value) => {
                                                                                    let p = {
                                                                                        isTrue: value,
                                                                                    };
                                                                                    handleTackChange(value, item.id);
                                                                                }}
                                                                                checked={
                                                                                    item.is_completed == 1 ? "checked" : ""
                                                                                }
                                                                                borderColor="#cccccc"
                                                                                className="checkmark1 checks"
                                                                                style={{ cursor: "pointer" }}
                                                                                labelStyle={{
                                                                                    marginLeft: 5,
                                                                                    userSelect: "none",
                                                                                }}
                                                                                label=""
                                                                            />
                                                                        </fieldset>
                                                                    )}
                                                                </Form>
                                                            </div>
                                                            <div className="display activity-content">
                                                                <h6
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        openTaskEditModel(item.id);
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </h6>
                                                                <p>{item.description}</p>
                                                                <div className="display justify-content-between mtb8">
                                                                    <h5 className={"lb10 " + item.priority}>
                                                                        {item.priority
                                                                            ? item.priority.charAt(0).toUpperCase() +
                                                                            item.priority.slice(1).toLowerCase()
                                                                            : "N/A"}
                                                                    </h5>
                                                                    <h5 className={item.activity_status}>
                                                                        {item.activity_status
                                                                            ? item.activity_status.charAt(0).toUpperCase() +
                                                                            item.activity_status.slice(1).toLowerCase()
                                                                            : "N/A"}
                                                                    </h5>
                                                                </div>
                                                                <div className="display mt5">
                                                                    {/* <p>{ moment.tz(item.end_date, defaultTimeZone).format(preferredDateFormatData)}</p> */}
                                                                    <p>
                                                                        {item.is_completed == 1
                                                                            ?
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                            :
                                                                            moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event_action">
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    id="dropdown-basic"
                                                                    className="transparentbg"
                                                                >
                                                                    <span className="listdot"></span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {userPermission ? (
                                                                        userPermission.includes("task_edit") ? (
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    openTaskEditModel(item.id);
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </Dropdown.Item>
                                                                        ) : (
                                                                            ""
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {userPermission ? (
                                                                        userPermission.includes("task_delete") ? (
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    deleteTask(parentId, item.id, parentType);
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </Dropdown.Item>
                                                                        ) : (
                                                                            ""
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                :

                                                item.activity_type == "Log a Visit" ?
                                                    <div className="col-md-11">
                                                        <div className={setActiveClass}>
                                                            <span className={arrowIcon}></span>
                                                            <div className="event_details">
                                                                <div className="display align-items-start w-6">
                                                                    {/* <input type="radio" name="name" /> */}
                                                                    <Form>
                                                                        {item.is_completed == 0 ? (
                                                                            <fieldset title="Click to complete log visit">
                                                                                <Checkbox
                                                                                    icon={
                                                                                        <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                                    }
                                                                                    name="is_completed"
                                                                                    onChange={(value) => {
                                                                                        let p = {
                                                                                            isTrue: value,
                                                                                        };
                                                                                        handleTackChange(value, item.id);
                                                                                    }}
                                                                                    checked={
                                                                                        item.is_completed == 1 ? "checked" : ""
                                                                                    }
                                                                                    borderColor="#cccccc"
                                                                                    className="checkmark1"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    labelStyle={{
                                                                                        marginLeft: 5,
                                                                                        userSelect: "none",
                                                                                    }}
                                                                                    label=""
                                                                                />
                                                                            </fieldset>
                                                                        ) : (
                                                                            <fieldset title="Log visit is completed">
                                                                                <Checkbox
                                                                                    icon={
                                                                                        <FontAwesomeIcon icon={faCheck} size="xs" />
                                                                                    }
                                                                                    name="is_completed"
                                                                                    onChange={(value) => {
                                                                                        let p = {
                                                                                            isTrue: value,
                                                                                        };
                                                                                        handleTackChange(value, item.id);
                                                                                    }}
                                                                                    checked={
                                                                                        item.is_completed == 1 ? "checked" : ""
                                                                                    }
                                                                                    borderColor="#cccccc"
                                                                                    className="checkmark1 checks"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    labelStyle={{
                                                                                        marginLeft: 5,
                                                                                        userSelect: "none",
                                                                                    }}
                                                                                    label=""
                                                                                />
                                                                            </fieldset>
                                                                        )}
                                                                    </Form>
                                                                </div>
                                                                <div className="display activity-content">
                                                                    <h6
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            openLogEditModel(item.id);
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </h6>
                                                                    <p>{item.description}</p>

                                                                    <div className="display mt5">
                                                                        <p>
                                                                            {item.is_completed == 1
                                                                                ?
                                                                                moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                                :
                                                                                moment.utc(item.end_date).tz(defaultTimeZone).format(preferredDateFormatData)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    {item.attachment && item.attachment.length >= 1 && item.attachment.map((attach_item, j) => (
                                                                        <div className="display mt5" key={j}>
                                                                            <div className="attachment-file" style={{ display: 'flex', alignItems: 'center', justifyItems: 'end' }}>
                                                                                <a target="_blank" href={`${process.env.REACT_APP_API_URL}storage/${attach_item.file_url}`}>
                                                                                    <span> {attach_item.file_url.substr(attach_item.file_url.length - 30)} </span>
                                                                                </a>
                                                                                <span className="deleteicon1" onClick={() => deleteLogVisitAttachment(item.id, attach_item.id)} style={{ backgroundSize: '14px', marginLeft: '5px' }}></span>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="event_action">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle
                                                                        id="dropdown-basic"
                                                                        className="transparentbg"
                                                                    >
                                                                        <span className="listdot"></span>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {userPermission ? (
                                                                            userPermission.includes("task_edit") ? (
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        openLogEditModel(item.id);
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Dropdown.Item>
                                                                            ) : (
                                                                                ""
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {userPermission ? (
                                                                            userPermission.includes("task_delete") ? (
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        deleteLog(parentId, item.id, parentType);
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </Dropdown.Item>
                                                                            ) : (
                                                                                ""
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    ''
                                    }


                                </div>
                            </div>
                        </div>
                    </>
                );

            }
        });




    // =========================End: Write code for Display Activity===========================//

    const addDefaultSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/default-profile.png`;
    };

    const DatePickerField = ({ ...props }) => {
        const { setFieldValue, setFieldTouched } = useFormikContext();
        const [field] = useField(props);

        return (
            <DatePicker
                {...field}
                {...props}
                //minDate={moment().toDate()}
                selected={(field.value && new Date(field.value)) || null}
                placeholderText="Enter Due date"
                value={field.value}
                onChange={(val) => {
                    setFieldValue(field.name, val);
                }}
                onBlur={(val) => {
                    setFieldValue(field.name, val);
                }}
                //onBlur={() => setFieldTouched(field.name, true, true)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="time"
                dateFormat={calendorDateFormate + " h:mm aa"}
                className="timedate"
            />
        );
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        //return currentDate.getTime() < selectedDate.getTime();
        return currentDate.getTime();
    };

    return (
        <>
            <div className="col-md-4">
                <div className="notes_section sideborader">
                    <div className="notes_header p2022 display acvitybottom">
                        <div className="col-md-8">
                            <h4>Activities</h4>
                        </div>
                        <div className="col-md-4 text-right">
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="transparentbg p-0"
                                >
                                    <span className="editplusicon"></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {userPermission ? (
                                        userPermission.includes("event_create") ? (
                                            <Dropdown.Item onClick={() => showAddEventModel()}>
                                                Event
                                            </Dropdown.Item>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}

                                    {userPermission ? (
                                        userPermission.includes("task_create") ? (
                                            <Dropdown.Item onClick={() => showAddTaskModel()}>
                                                Task
                                            </Dropdown.Item>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}

                                    {userPermission ? (
                                        userPermission.includes("task_create") ? (
                                            <Dropdown.Item onClick={() => showAddLogModel()}>
                                                Log a visit
                                            </Dropdown.Item>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {sortedActivityArray.length ? (
                        <Scrollbars style={{ height: 500 }}>{displayPlanedActivity}{displayDoneActivity}</Scrollbars>
                    ) : (
                        <div className="note-box">
                            <div className="notes-found text-center p-5">
                                <h3>No Activities Found!</h3>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                as={Modal.Dialog}
                centered
                show={showTaskDefault}
                onHide={handleTaskClose}
            >
                <Modal.Header>
                    <Modal.Title className="h2">Add Task</Modal.Title>
                    <Button
                        variant="close"
                        aria-label="Close"
                        onClick={handleTaskClose}
                    />
                    <span className="modelclose"></span>
                </Modal.Header>
                <Modal.Body className="modal-body1">
                    <Formik
                        initialValues={taskInput}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                setFieldValue,
                                setFieldTouched,
                            } = props;
                            return (
                                <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="name">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Subject<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    maxLength={50}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter subject"
                                                    className={
                                                        errors.name && touched.name ? "input-error" : null
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {taskInput.error_list.name}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="name" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="description">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Description<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="2"
                                                    maxLength={150}
                                                    name="description"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter description"
                                                    className={
                                                        errors.description && touched.description
                                                            ? "input-error"
                                                            : null
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {taskInput.error_list.description}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="description" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="end_date">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Due date<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <DatePicker
                                                    autocomplete="off"
                                                    //minDate={moment().toDate()}
                                                    filterTime={filterPassedTime}
                                                    selected={dueDate == "" ? new Date() : dueDate}
                                                    //onChange={(date) => setDueDate(date)}
                                                    onChange={(date) => {
                                                        setFieldValue("end_date", date);
                                                        handleDueDate(date);
                                                    }}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    timeCaption="time"
                                                    dateFormat={calendorDateFormate + " h:mm aa"}
                                                    className="form-control dates"
                                                />
                                                {/* <DatePickerField name="end_date" id='end_date'/> */}
                                            </Form.Group>
                                            <span className="calenderinput"></span>
                                            <span className="error error4">
                                                {taskInput.error_list.end_date}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="end_date" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="priority" column sm="12">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">Priority</h6>
                                                </Form.Label>
                                                <Select
                                                    defaultValue={defaultPriorityOptions}
                                                    name="priority"
                                                    options={priorityOptions}
                                                    className="basic-multi-select bordercolor"
                                                    classNamePrefix="select"
                                                    onChange={handleSelectPrioritySave}
                                                    styles={customStyles}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'var(--primary-color-light)',
                                                            primary: 'var(--primary-color)',
                                                        },
                                                    })}
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {taskInput.error_list.priority}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="priority" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="position-relative">
                                        <Col md={12}>
                                            <Form.Group id="activity_status">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">Status</h6>
                                                </Form.Label>
                                                <Select
                                                    defaultValue={defaultPriorityStatusOptions}
                                                    name="activity_status"
                                                    options={priorityStatusOptions}
                                                    className="basic-multi-select bordercolor"
                                                    classNamePrefix="select"
                                                    onChange={handleSelectPriorityStatusSave}
                                                    styles={customStyles}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'var(--primary-color-light)',
                                                            primary: 'var(--primary-color)',
                                                        },
                                                    })}
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {taskInput.error_list.activity_status}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="activity_status" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt30">
                                        {submitTask ? (
                                            <Button
                                                variant="primary"
                                                className="d-flex popupbtn btnblue align-items-center mb-1"
                                            >
                                                <Spinner animation="border" size="sm" />
                                                <span className="ms-2">Please Wait...</span>
                                            </Button>
                                        ) : (
                                            <div
                                                className="btn-toolbar"
                                                role="toolbar"
                                                aria-label="Toolbar with button groups"
                                            >
                                                <div
                                                    className="btn-group mr-2"
                                                    role="group"
                                                    aria-label="First group"
                                                >
                                                    <Button
                                                        type="submit"
                                                        variant="success"
                                                        className="popupbtn btnblue mr15"
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <div
                                                    className="btn-group mr-2"
                                                    role="group"
                                                    aria-label="First group"
                                                >
                                                    <Button
                                                        type="button"
                                                        onClick={handleTaskClose}
                                                        variant="success"
                                                        className="popupbtn greycolor"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>

            <Modal
                as={Modal.Dialog}
                centered
                show={showEditTaskDefault}
                onHide={handleEditTaskClose}
            >
                <Modal.Header>
                    <Modal.Title className="h2">Edit Task</Modal.Title>
                    <Button
                        variant="close"
                        aria-label="Close"
                        onClick={handleEditTaskClose}
                    />
                    <span className="modelclose"></span>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={taskEditInput}
                        validationSchema={validationSchema}
                        onSubmit={handleEditTaskSave}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                setFieldValue,
                            } = props;

                            return (
                                <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="name">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Subject<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    maxLength={50}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter subject"
                                                    className={
                                                        errors.name && touched.name ? "input-error" : null
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {errorTaskEditInput.error_list.name}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="name" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="description">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Description<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="3"
                                                    maxLength={150}
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter description"
                                                    className={
                                                        errors.description && touched.description
                                                            ? "input-error"
                                                            : null
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {errorTaskEditInput.error_list.description}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="description" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="end_date">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Due date<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <DatePicker
                                                    autocomplete="off"
                                                    //minDate={moment().toDate()}
                                                    filterTime={filterPassedTime}
                                                    selected={
                                                        taskDueDate == "" ? new Date() : taskDueDate
                                                    }
                                                    //onChange={(date) => setTaskDueDate(date)}
                                                    onChange={(date) => {
                                                        setFieldValue("end_date", date);
                                                        setTaskDueDate(date);
                                                    }}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    timeCaption="time"
                                                    dateFormat={calendorDateFormate + " h:mm aa"}
                                                    className="form-control"
                                                />
                                            </Form.Group>
                                            <span className="calenderinput"></span>
                                            <span className="error error4">
                                                {errorTaskEditInput.error_list.end_date}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="end_date" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="priority">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">Priority</h6>
                                                </Form.Label>
                                                <Select
                                                    defaultValue={defaultPriorityEditOptions}
                                                    name="priority"
                                                    options={priorityEditOptions}
                                                    className="basic-multi-select bordercolor"
                                                    classNamePrefix="select"
                                                    onChange={handleSelectPriorityEditSave}
                                                    styles={customStyles}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'var(--primary-color-light)',
                                                            primary: 'var(--primary-color)',
                                                        },
                                                    })}
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {errorTaskEditInput.error_list.priority}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="priority" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="position-relative">
                                        <Col md={12}>
                                            <Form.Group id="activity_status">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">Status</h6>
                                                </Form.Label>
                                                <Select
                                                    defaultValue={defaultPriorityStatusEditOptions}
                                                    name="activity_status"
                                                    options={priorityStatusEditOptions}
                                                    className="basic-multi-select bordercolor"
                                                    classNamePrefix="select"
                                                    onChange={handleSelectPriorityStatusEditSave}
                                                    styles={customStyles}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'var(--primary-color-light)',
                                                            primary: 'var(--primary-color)',
                                                        },
                                                    })}
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {errorTaskEditInput.error_list.activity_status}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="activity_status" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt30">
                                        {submitTask ? (
                                            <Button
                                                variant="primary"
                                                className="d-flex popupbtn btnblue align-items-center mb-1"
                                            >
                                                <Spinner animation="border" size="sm" />
                                                <span className="ms-2">Please Wait...</span>
                                            </Button>
                                        ) : (
                                            <div
                                                className="btn-toolbar"
                                                role="toolbar"
                                                aria-label="Toolbar with button groups"
                                            >
                                                <div
                                                    className="btn-group mr-2"
                                                    role="group"
                                                    aria-label="First group"
                                                >
                                                    <Button
                                                        type="submit"
                                                        variant="success"
                                                        className="popupbtn btnblue mr15"
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <div
                                                    className="btn-group mr-2"
                                                    role="group"
                                                    aria-label="First group"
                                                >
                                                    <Button
                                                        type="button"
                                                        onClick={handleEditTaskClose}
                                                        variant="success"
                                                        className="popupbtn greycolor"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>



            <Modal
                as={Modal.Dialog}
                centered
                show={showEventDefault}
                onHide={handleEventClose}
            >
                <Modal.Header>
                    <Modal.Title className="h2">Create Event</Modal.Title>
                    <Button
                        variant="close"
                        aria-label="Close"
                        onClick={handleEventClose}
                    />
                    <span className="modelclose"></span>
                </Modal.Header>
                <Modal.Body>
                    <Form encrypted="multipart/form-data" onSubmit={eventSubmit}>
                        <Row className="mb-125 position-relative">
                            <Col md={12}>
                                <Form.Group id="name">
                                    <Form.Label column sm="12">
                                        <h6 className="fw600">
                                            Subject<span className="text-danger">*</span>
                                        </h6>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        maxLength={50}
                                        onChange={handleEventInput}
                                        ref={eventNameRef}
                                        onBlur={handleEventInput}
                                        value={eventInput.name}
                                        placeholder="Enter subject"
                                        className={formEventNameError ? "input-error" : null}
                                    />
                                </Form.Group>

                                <span className="error error4">
                                    {!formEventNameError ? eventInput.error_list.name : ""}
                                </span>
                                {formEventNameError ? (
                                    <span className="error error4">Enter event name</span>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="mb-125 position-relative">
                            <Col md={12}>
                                <Form.Group id="description">
                                    <Form.Label column sm="12">
                                        <h6 className="fw600">
                                            Description<span className="text-danger">*</span>
                                        </h6>
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="2"
                                        name="description"
                                        maxLength={150}
                                        onChange={handleEventInput}
                                        onBlur={handleEventInput}
                                        ref={eventDescriptionRef}
                                        value={eventInput.description}
                                        placeholder="Enter event description"
                                        className={formEventDescriptionError ? "input-error" : null}
                                    />
                                </Form.Group>
                                <span className="error error4">
                                    {!formEventDescriptionError
                                        ? eventInput.error_list.description
                                        : ""}
                                </span>
                                {formEventDescriptionError ? (
                                    <span className="error error4">Enter event description</span>
                                ) : null}
                            </Col>
                        </Row>

                        {allDaySelect ? (
                            <Row>
                                <Col md={12} className="mb-125 position-relative">
                                    <Form.Group id="start_date">
                                        <div className="display">
                                            <div className="col-md-6">
                                                <Form.Label column>
                                                    <h6 className="fw600">
                                                        Start date<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                            </div>
                                            <div className="col-md-6">
                                                <FormCheck
                                                    type="checkbox"
                                                    className="display justify-content-end  align-items-center mb-2"
                                                >
                                                    <FormCheck.Input
                                                        name="allday"
                                                        onChange={handleCheckbox}
                                                        value="1"
                                                        className="me-2"
                                                    />
                                                    <FormCheck.Label
                                                        htmlFor={"checkbox" + 1}
                                                        style={{ fontWeight: "normal" }}
                                                        column
                                                    >
                                                        <h6 className="fw600">All day</h6>
                                                    </FormCheck.Label>
                                                </FormCheck>
                                            </div>
                                        </div>
                                        <DatePicker
                                            autocomplete="off"
                                            //minDate={moment().toDate()}
                                            filterTime={filterPassedTime}
                                            selected={fullStartDate}
                                            onChange={(date) => {
                                                setFullStartDate(date);
                                                setFormEventStartDateError(false);
                                                setFormEventEndDateError(false);
                                                setFormEventFullStartDateError(false);
                                            }}
                                            timeCaption="time"
                                            dateFormat={calendorDateFormate}
                                            className={
                                                formEventFullStartDateError
                                                    ? "form-control input-error"
                                                    : "form-control"
                                            }
                                            ref={eventStartDateRef}
                                        />
                                    </Form.Group>
                                    <span className="calenderinput"></span>
                                    <span className="error error4">
                                        {!formEventFullStartDateError
                                            ? eventInput.error_list.start_date
                                            : (eventInput.error_list.start_date = "")}
                                    </span>
                                    {formEventFullStartDateError ? (
                                        <span className="error error4">Enter event start date</span>
                                    ) : null}
                                </Col>
                                <Col md={12} className="mb-125 position-relative">
                                    <Form.Group id="end_date">
                                        <Form.Label column sm="12">
                                            <h6 className="fw600">
                                                End date<span className="text-danger">*</span>
                                            </h6>
                                        </Form.Label>
                                        <DatePicker
                                            autocomplete="off"
                                            //minDate={moment().toDate()}
                                            filterTime={filterPassedTime}
                                            selected={fullEndDate}
                                            onChange={(date) => {
                                                setFullEndDate(date);
                                                setFormEventEndDateError(false);
                                                setFormEventFullEndDateError(false);
                                            }}
                                            timeCaption="time"
                                            dateFormat={calendorDateFormate}
                                            className={
                                                formEventFullEndDateError
                                                    ? "form-control input-error"
                                                    : "form-control"
                                            }
                                            ref={eventEndDateRef}
                                        />
                                    </Form.Group>
                                    <span className="calenderinput"></span>
                                    <span className="error error4">
                                        {!formEventFullEndDateError
                                            ? eventInput.error_list.end_date
                                            : (eventInput.error_list.end_date = "")}
                                    </span>
                                    {formEventFullEndDateError ? (
                                        <span className="error error4">Enter event end date</span>
                                    ) : null}
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col md={12} className="mb-125 position-relative">
                                    <Form.Group id="start_date">
                                        <div className="display">
                                            <div className="col-md-6">
                                                <Form.Label column>
                                                    <h6 className="fw600">
                                                        Start date<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                            </div>
                                            <div className="col-md-6">
                                                <FormCheck
                                                    type="checkbox"
                                                    className="display justify-content-end align-items-center mb-2"
                                                >
                                                    <FormCheck.Input
                                                        name="allday"
                                                        onChange={handleCheckbox}
                                                        value="1"
                                                        className="me-2"
                                                    />
                                                    <FormCheck.Label
                                                        htmlFor={"checkbox" + 1}
                                                        style={{ fontWeight: "normal" }}
                                                        column
                                                    >
                                                        <h6 className="fw600">All day</h6>
                                                    </FormCheck.Label>
                                                </FormCheck>
                                            </div>
                                        </div>
                                        <DatePicker
                                            autocomplete="off"
                                            //minDate={moment().toDate()}
                                            filterTime={filterPassedTime}
                                            selected={startDate}
                                            onChange={(date) => {
                                                setStartDate(date);
                                                setFormEventStartDateError(false);
                                                setFormEventEndDateError(false);
                                                setEndDate(
                                                    date != null
                                                        ? new Date(
                                                            new Date(date).setHours(date.getHours() + 1)
                                                        )
                                                        : null
                                                );
                                            }}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="time"
                                            dateFormat={calendorDateFormate + " h:mm aa"}
                                            className={
                                                formEventStartDateError
                                                    ? "form-control input-error"
                                                    : "form-control"
                                            }
                                            ref={eventStartDateRef}
                                        />
                                    </Form.Group>
                                    <span className="calenderinput"></span>
                                    <span className="error error4">
                                        {!formEventStartDateError
                                            ? eventInput.error_list.start_date
                                            : (eventInput.error_list.start_date = "")}
                                    </span>
                                    {formEventStartDateError ? (
                                        <span className="error error4">Enter event start date</span>
                                    ) : null}
                                </Col>
                                <Col md={12} className="mb-125 position-relative">
                                    <Form.Group id="end_date">
                                        <Form.Label column sm="12">
                                            <h6 className="fw600">
                                                End date<span className="text-danger">*</span>
                                            </h6>
                                        </Form.Label>
                                        <DatePicker
                                            autocomplete="off"
                                            //minDate={moment().toDate()}
                                            filterTime={filterPassedTime}
                                            selected={endDate}
                                            onChange={(date) => {
                                                setEndDate(date);
                                                setFormEventEndDateError(false);
                                            }}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="time"
                                            dateFormat={calendorDateFormate + " h:mm aa"}
                                            className={
                                                formEventEndDateError
                                                    ? "form-control input-error"
                                                    : "form-control"
                                            }
                                            ref={eventEndDateRef}
                                        />
                                    </Form.Group>
                                    <span className="calenderinput"></span>
                                    <span className="error error4">
                                        {!formEventEndDateError
                                            ? eventInput.error_list.end_date
                                            : (eventInput.error_list.end_date = "")}
                                    </span>
                                    {formEventEndDateError ? (
                                        <span className="error error4">Enter event end date</span>
                                    ) : null}
                                </Col>
                            </Row>
                        )}
                        <Row className="maprelate position-relative">
                            <Col md={12}>
                                <Form.Group id="name">
                                    <Form.Label column sm="12">
                                        <h6 className="fw600">Location</h6>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="location"
                                        maxLength={50}
                                        onChange={handleEventInput}
                                        ref={eventLocationRef}
                                        onBlur={handleEventInput}
                                        value={eventInput.location}
                                        placeholder="Enter event location"
                                    />
                                    <span className="mapicon"></span>
                                </Form.Group>

                                <span className="error error4">
                                    {eventInput.error_list.location}
                                </span>
                                {formEventLocationError ? (
                                    <span className="error error4">Enter event location</span>
                                ) : null}
                            </Col>
                        </Row>
                        {/* <Button variant="primary" type="submit" className="d-flex">Submit<span className="rightarrow"></span></Button> */}

                        <div className="text-center mt30">
                            {submitEvent ? (
                                <Button
                                    variant="primary"
                                    className="d-flex align-items-center popupbtn btnblue justify-content-center mb-1"
                                >
                                    <Spinner animation="border" size="sm" />
                                    <span className="ms-2 text-center">Please Wait...</span>
                                </Button>
                            ) : (
                                <div
                                    className="btn-toolbar"
                                    role="toolbar"
                                    aria-label="Toolbar with button groups"
                                >
                                    <div
                                        className="btn-group mr-2"
                                        role="group"
                                        aria-label="First group"
                                    >
                                        <Button
                                            type="submit"
                                            variant="success"
                                            className="popupbtn btnblue mr15"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                    <div
                                        className="btn-group mr-2"
                                        role="group"
                                        aria-label="First group"
                                    >
                                        <Button
                                            type="button"
                                            onClick={handleEventClose}
                                            variant="success"
                                            className="popupbtn greycolor"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                as={Modal.Dialog}
                centered
                show={showEditEventDefault}
                onHide={handleEditEventClose}
            >
                <Modal.Header>
                    <Modal.Title className="h2">Edit Event</Modal.Title>
                    <Button
                        variant="close"
                        aria-label="Close"
                        onClick={handleEditEventClose}
                    />
                    <span className="modelclose"></span>
                </Modal.Header>
                <Modal.Body>
                    <Form encrypted="multipart/form-data" onSubmit={handleEventSubmit}>
                        <Row className="mb-125 position-relative">
                            <Col md={12}>
                                <Form.Group id="name">
                                    <Form.Label column sm="12">
                                        <h6 className="fw600">
                                            Name<span className="text-danger">*</span>
                                        </h6>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        maxLength={50}
                                        onChange={handleEditEventInput}
                                        ref={eventEditNameRef}
                                        onBlur={handleEditEventInput}
                                        value={eventEditInput.name}
                                        placeholder="Enter event name"
                                        className={eventEditNameError ? "input-error" : null}
                                    />
                                </Form.Group>

                                <span className="error error4">
                                    {errorEventEditInput.error_list.name}
                                </span>
                                {eventEditNameError ? (
                                    <span className="error error4">Enter event name</span>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="mb-125 position-relative">
                            <Col md={12}>
                                <Form.Group id="description">
                                    <Form.Label column sm="12">
                                        <h6 className="fw600">
                                            Description<span className="text-danger">*</span>
                                        </h6>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        rows="2"
                                        maxLength={150}
                                        name="description"
                                        onChange={handleEditEventInput}
                                        onBlur={handleEditEventInput}
                                        ref={eventEditDescriptionRef}
                                        value={eventEditInput.description}
                                        placeholder="Enter event description"
                                        className={eventEditDescriptionError ? "input-error" : null}
                                    />
                                </Form.Group>
                                <span className="error error4">
                                    {errorEventEditInput.error_list.description}
                                </span>
                                {eventEditDescriptionError ? (
                                    <span className="error error4">Enter event description</span>
                                ) : null}
                            </Col>
                        </Row>
                        {allEditDaySelect ? (
                            <Row>
                                <Col md={12} className="mb-125 position-relative">
                                    <Form.Group id="start_date">
                                        <div className="display">
                                            <div className="col-md-6">
                                                <Form.Label column>
                                                    <h6 className="fw600">
                                                        Start date<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                            </div>
                                            <div className="col-md-6">
                                                <FormCheck
                                                    type="checkbox"
                                                    className="display justify-content-end align-items-center mb-2"
                                                >
                                                    <FormCheck.Input
                                                        name="allday"
                                                        checked={checkPer}
                                                        onChange={handleEditCheckbox}
                                                        value="1"
                                                        className="me-2"
                                                    />
                                                    <FormCheck.Label
                                                        htmlFor={"checkbox" + 1}
                                                        style={{ fontWeight: "normal" }}
                                                        column
                                                    >
                                                        <h6 className="fw600">All day</h6>
                                                    </FormCheck.Label>
                                                </FormCheck>
                                            </div>
                                        </div>
                                        <DatePicker
                                            autocomplete="off"
                                            //minDate={moment().toDate()}
                                            selected={eventFullStartDate}
                                            filterTime={filterPassedTime}
                                            onChange={(date) => {
                                                setEventFullStartDate(date);
                                                validateEventEditFormData("start_date", date);
                                            }}
                                            timeCaption="time"
                                            dateFormat={calendorDateFormate}
                                            className={
                                                eventEditStartDateError
                                                    ? "form-control input-error"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                    <span className="calenderinput"></span>
                                    <span className="error error4">
                                        {errorEventEditInput.error_list.start_date}
                                    </span>
                                    {eventEditStartDateError ? (
                                        <span className="error error4">Enter event start date</span>
                                    ) : null}
                                </Col>
                                <Col md={12} className="mb-125 position-relative">
                                    <Form.Group id="end_date">
                                        <Form.Label column sm="12">
                                            <h6 className="fw600">
                                                End date<span className="text-danger">*</span>
                                            </h6>
                                        </Form.Label>

                                        <DatePicker
                                            autocomplete="off"
                                            //minDate={moment().toDate()}
                                            filterTime={filterPassedTime}
                                            selected={eventFullEndDate}
                                            onChange={(date) => {
                                                setEventFullEndDate(date);
                                                validateEventEditFormData("end_date", date);
                                            }}
                                            timeCaption="time"
                                            dateFormat={calendorDateFormate}
                                            className={
                                                eventEditEndDateError
                                                    ? "form-control input-error"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                    <span className="calenderinput"></span>
                                    <span className="error error4">
                                        {!eventEditEndDateError
                                            ? errorEventEditInput.error_list.end_date
                                            : (errorEventEditInput.error_list.end_date = "")}
                                    </span>
                                    {eventEditEndDateError ? (
                                        <span className="error error4">Enter event end date</span>
                                    ) : null}
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col md={12} className="mb-125 position-relative">
                                    <Form.Group id="start_date">
                                        <div className="display">
                                            <div className="col-md-6">
                                                <Form.Label column>
                                                    <h6 className="fw600">
                                                        Start date<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                            </div>
                                            <div className="col-md-6">
                                                {/* defaultChecked={checkPer} */}
                                                <FormCheck
                                                    type="checkbox"
                                                    className="display justify-content-end align-items-center mb-2"
                                                >
                                                    <FormCheck.Input
                                                        name="allday"
                                                        checked={checkPer}
                                                        onChange={handleEditCheckbox}
                                                        value="1"
                                                        className="me-2"
                                                    />
                                                    <FormCheck.Label
                                                        htmlFor={"checkbox" + 1}
                                                        style={{ fontWeight: "normal" }}
                                                        column
                                                    >
                                                        <h6 className="fw600">All day</h6>
                                                    </FormCheck.Label>
                                                </FormCheck>
                                            </div>
                                        </div>
                                        <DatePicker
                                            autocomplete="off"
                                            //minDate={moment().toDate()}
                                            filterTime={filterPassedTime}
                                            selected={eventStartDate}
                                            onChange={(date) => {
                                                setEventStartDate(date);
                                                validateEventEditFormData("start_date", date);
                                            }}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="time"
                                            dateFormat={calendorDateFormate + " h:mm aa"}
                                            className={
                                                eventEditStartDateError
                                                    ? "form-control input-error"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                    <span className="calenderinput"></span>
                                    <span className="error error4">
                                        {errorEventEditInput.error_list.start_date}
                                    </span>
                                    {eventEditStartDateError ? (
                                        <span className="error error4">Enter event start date</span>
                                    ) : null}
                                </Col>
                                <Col md={12} className="mb-125 position-relative">
                                    <Form.Group id="end_date">
                                        <Form.Label column sm="12">
                                            <h6 className="fw600">
                                                End date<span className="text-danger">*</span>
                                            </h6>
                                        </Form.Label>

                                        <DatePicker
                                            autocomplete="off"
                                            //minDate={moment().toDate()}
                                            filterTime={filterPassedTime}
                                            selected={eventEndDate}
                                            onChange={(date) => {
                                                setEventEndDate(date);
                                                validateEventEditFormData("end_date", date);
                                            }}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="time"
                                            dateFormat={calendorDateFormate + " h:mm aa"}
                                            className={
                                                eventEditEndDateError
                                                    ? "form-control input-error"
                                                    : "form-control"
                                            }
                                        />
                                    </Form.Group>
                                    <span className="calenderinput"></span>
                                    <span className="error error4">
                                        {!eventEditEndDateError
                                            ? errorEventEditInput.error_list.end_date
                                            : (errorEventEditInput.error_list.end_date = "")}
                                    </span>
                                    {eventEditEndDateError ? (
                                        <span className="error error4">Enter event end date</span>
                                    ) : null}
                                </Col>
                            </Row>
                        )}

                        <Row className="maprelate position-relative">
                            <Col md={12}>
                                <Form.Group id="name">
                                    <Form.Label column sm="12">
                                        <h6 className="fw600">Location</h6>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="location"
                                        maxLength={50}
                                        onChange={handleEditEventInput}
                                        ref={eventEditLocationRef}
                                        onBlur={handleEditEventInput}
                                        value={eventEditInput.location}
                                        placeholder="Enter event location"
                                    />
                                    <span className="mapicon"></span>
                                </Form.Group>

                                <span className="error error4">
                                    {errorEventEditInput.error_list.location}
                                </span>
                                {formEditEventLocationError ? (
                                    <span className="error error4">Enter event location</span>
                                ) : null}
                            </Col>
                        </Row>
                        <div className="text-center mt30">
                            {submitEditEvent ? (
                                <Button
                                    variant="primary"
                                    className="d-flex popupbtn btnblue align-items-center justify-content-center text-center btnblue mb-1"
                                >
                                    <Spinner animation="border" size="sm" />
                                    <span className="ms-2 text-center">Please Wait...</span>
                                </Button>
                            ) : (
                                <div
                                    className="btn-toolbar"
                                    role="toolbar"
                                    aria-label="Toolbar with button groups"
                                >
                                    <div
                                        className="btn-group mr-2"
                                        role="group"
                                        aria-label="First group"
                                    >
                                        <Button
                                            type="submit"
                                            variant="success"
                                            className="popupbtn btnblue mr15"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                    <div
                                        className="btn-group mr-2"
                                        role="group"
                                        aria-label="First group"
                                    >
                                        <Button
                                            type="button"
                                            variant="success"
                                            onClick={handleEditEventClose}
                                            className="popupbtn greycolor"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>



            <Modal
                as={Modal.Dialog}
                centered
                show={showLogDefault}
                onHide={handleLogClose}
            >
                <Modal.Header>
                    <Modal.Title className="h2">Add Log a visit</Modal.Title>
                    <Button
                        variant="close"
                        aria-label="Close"
                        onClick={handleLogClose}
                    />
                    <span className="modelclose"></span>
                </Modal.Header>
                <Modal.Body className="modal-body1">
                    <Formik
                        initialValues={logInput}
                        validationSchema={logValidationSchema}
                        onSubmit={handleLogSubmit}
                        enableReinitialize
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                setFieldValue,
                                setFieldTouched,
                                resetForm,
                            } = props;
                            return (
                                <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="name">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Subject
                                                    </h6>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    maxLength={50}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter subject"
                                                    className={
                                                        errors.name && touched.name ? "input-error" : null
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {logInput.error_list.name}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="name" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="description">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Description<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="2"
                                                    maxLength={150}
                                                    name="description"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter description"
                                                    className={
                                                        errors.description && touched.description
                                                            ? "input-error"
                                                            : null
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {logInput.error_list.description}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="description" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="end_date">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Visit date<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <DatePicker
                                                    autocomplete="off"
                                                    //minDate={moment().toDate()}
                                                    filterTime={filterPassedTime}
                                                    selected={logDate}
                                                    //onChange={(date) => setDueDate(date)}
                                                    onChange={(date) => {
                                                        setFieldValue("end_date", date);
                                                        handleLogDate(date);
                                                    }}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    timeCaption="time"
                                                    dateFormat={calendorDateFormate + " h:mm aa"}
                                                    //className="form-control dates"
                                                    className={
                                                        errors.end_date && touched.end_date
                                                            ? "input-error"
                                                            : 'form-control dates'
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="calenderinput"></span>
                                            <span className="error error4">
                                                {logInput.error_list.end_date}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="end_date" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label column sm="12" className="attachlabel">
                                                    <h6 className="fw600" style={{ paddingBottom: '10px' }}>
                                                        Attachment
                                                    </h6>
                                                    <h6 className="fw600 attachbtn"><span className="attchmenicon mr15"></span>Attachment</h6>
                                                    <Col sm="12">
                                                        <Form.Group className="mb-2" id="attachment">
                                                            <Form.Control type="file" name="attachment[]" title="Allow file type are jpeg,png,jpg,gif,svg,pdf,mp4,mp3,3gpp,x-msvideo,xls,xlsx,doc,docx,ppt,pptx,ods,odt,odp,csv,anytext,plain,ms-office and MAX file size 4MB" className="form-control" multiple onChange={handleAddLogAttachment} />
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Label>

                                            </Form.Group>

                                            {/* <span className="error error4">
                        {logInput.error_list.attachment}
                      </span>
                      <span className="error error4">
                        <ErrorMessage name="attachment" />
                      </span> */}
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        {
                                            logAttachmentArray.length > 0 &&
                                            logAttachmentArray.map((item, index) => {
                                                return (
                                                    <Col md={12} key={index}>
                                                        <div className="col-md-11 display">
                                                            <span className="attachment-file">{item.name}</span>
                                                            <div className="col-md-1 text-right" style={{ paddingTop: '10px' }} onClick={() => deleteEditFile(index, 'attachment.' + index)}>
                                                                <span className="redclose"></span>
                                                                {
                                                                    handleErrorMessages.length >= 1 && handleErrorMessages.map((j, d) => {
                                                                        return (
                                                                            j.field == 'attachment.' + index ?
                                                                                <span className="error error4" style={{ paddingLeft: '5px' }}>
                                                                                    {j.message}
                                                                                </span>
                                                                                :
                                                                                ''
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>

                                    <div className="text-center mt30">
                                        {submitLog ? (
                                            <Button
                                                variant="primary"
                                                className="d-flex popupbtn btnblue align-items-center mb-1"
                                            >
                                                <Spinner animation="border" size="sm" />
                                                <span className="ms-2">Please Wait...</span>
                                            </Button>
                                        ) : (
                                            <div
                                                className="btn-toolbar"
                                                role="toolbar"
                                                aria-label="Toolbar with button groups"
                                            >
                                                <div
                                                    className="btn-group mr-2"
                                                    role="group"
                                                    aria-label="First group"
                                                >
                                                    <Button
                                                        type="submit"
                                                        variant="success"
                                                        className="popupbtn btnblue mr15"
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <div
                                                    className="btn-group mr-2"
                                                    role="group"
                                                    aria-label="First group"
                                                >
                                                    <Button
                                                        type="button"
                                                        onClick={handleLogClose}
                                                        variant="success"
                                                        className="popupbtn greycolor"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>

            <Modal
                as={Modal.Dialog}
                centered
                show={showEditLogDefault}
                onHide={handleEditLogClose}
            >
                <Modal.Header>
                    <Modal.Title className="h2">Edit log visit</Modal.Title>
                    <Button
                        variant="close"
                        aria-label="Close"
                        onClick={handleEditLogClose}
                    />
                    <span className="modelclose"></span>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={logEditInput}
                        validationSchema={logValidationSchema}
                        onSubmit={handleEditLogSave}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                setFieldValue,
                            } = props;

                            return (
                                <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="name">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Subject
                                                    </h6>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    maxLength={50}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter subject"
                                                    className={
                                                        errors.name && touched.name ? "input-error" : null
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {errorLogEditInput.error_list.name}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="name" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="description">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Description<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="3"
                                                    maxLength={150}
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter description"
                                                    className={
                                                        errors.description && touched.description
                                                            ? "input-error"
                                                            : null
                                                    }
                                                />
                                            </Form.Group>
                                            <span className="error error4">
                                                {errorLogEditInput.error_list.description}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="description" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group id="end_date">
                                                <Form.Label column sm="12">
                                                    <h6 className="fw600">
                                                        Visit date<span className="text-danger">*</span>
                                                    </h6>
                                                </Form.Label>
                                                <DatePicker
                                                    autocomplete="off"
                                                    //minDate={moment().toDate()}
                                                    filterTime={filterPassedTime}
                                                    selected={
                                                        editLogEndDate == "" ? new Date() : editLogEndDate
                                                    }
                                                    //onChange={(date) => setTaskDueDate(date)}
                                                    onChange={(date) => {
                                                        setFieldValue("end_date", date);
                                                        setEditLogEndDate(date);
                                                    }}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    timeCaption="time"
                                                    dateFormat={calendorDateFormate + " h:mm aa"}
                                                    className="form-control"
                                                />
                                            </Form.Group>
                                            <span className="calenderinput"></span>
                                            <span className="error error4">
                                                {errorLogEditInput.error_list.end_date}
                                            </span>
                                            <span className="error error4">
                                                <ErrorMessage name="end_date" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label column sm="12" className="attachlabel">
                                                    <h6 className="fw600" style={{ paddingBottom: '10px' }}>
                                                        Attachment
                                                    </h6>
                                                    <h6 className="fw600 attachbtn"><span className="attchmenicon mr15"></span>Attachment</h6>
                                                    <Col sm="12">
                                                        <Form.Group className="mb-2" id="attachment">
                                                            <Form.Control type="file" name="attachment[]" title="Allow file type are jpeg,png,jpg,gif,svg,pdf,mp4,mp3,3gpp,x-msvideo,xls,xlsx,doc,docx,ppt,pptx,ods,odt,odp,csv,anytext,plain,ms-office and MAX file size 4MB" className="form-control" multiple onChange={handleAddLogAttachment} />
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-125 position-relative">
                                        {
                                            logAttachmentArray.length > 0 &&
                                            logAttachmentArray.map((item, index) => {
                                                return (
                                                    <Col md={12} key={index}>
                                                        <div className="col-md-11 display">
                                                            <span className="attachment-file">{item.name}</span>
                                                            <div className="col-md-1 text-right" style={{ paddingTop: '10px' }} onClick={() => deleteEditFile(index, 'attachment.' + index)}>
                                                                <span className="redclose"></span>
                                                                {
                                                                    handleErrorMessages.length >= 1 && handleErrorMessages.map((j, d) => {
                                                                        return (
                                                                            j.field == 'attachment.' + index ?
                                                                                <span className="error error4" style={{ paddingLeft: '5px' }}>
                                                                                    {j.message}ff
                                                                                </span>
                                                                                :
                                                                                ''
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                    {/* <Row className="mb-125 position-relative">
                      <span className="error error4">
                        
                          {errorMessages.map((message, index) => (
                            <Col md={12} key={index}>
                              {message}
                            </Col>
                          ))}
                        
                      </span>
                  </Row> */}
                                    <div className="text-center mt30">
                                        {submitLog ? (
                                            <Button
                                                variant="primary"
                                                className="d-flex popupbtn btnblue align-items-center mb-1"
                                            >
                                                <Spinner animation="border" size="sm" />
                                                <span className="ms-2">Please Wait...</span>
                                            </Button>
                                        ) : (
                                            <div
                                                className="btn-toolbar"
                                                role="toolbar"
                                                aria-label="Toolbar with button groups"
                                            >
                                                <div
                                                    className="btn-group mr-2"
                                                    role="group"
                                                    aria-label="First group"
                                                >
                                                    <Button
                                                        type="submit"
                                                        variant="success"
                                                        className="popupbtn btnblue mr15"
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <div
                                                    className="btn-group mr-2"
                                                    role="group"
                                                    aria-label="First group"
                                                >
                                                    <Button
                                                        type="button"
                                                        onClick={handleEditLogClose}
                                                        variant="success"
                                                        className="popupbtn greycolor"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>

        </>
    );
};
