import React, { useState, useEffect, memo } from "react";
import { Form } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select'
import { ErrorMessage } from "formik";
import axios from 'axios';

let subTotal = 0;
let grandTotal = 0;
let finalDiscountDisplay = 0;
var currencyCode = '';
var currencySymbol = '';

function QuoteCloneItems(props) {
  const [perSignDisplay, setPerSignDisplay] = useState(true);
  const [finalTax, setFinalTax] = useState();
  let selecteProduct = [{ label: 'Select', value: '' }];
  const [QuoteItemArray, SetQuoteItemArray] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [perSignDisplayTax, setPerSignDisplayTax] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [taxInputValue, setTaxInputValue] = useState('');
  const [adjustInputValue, setAdjustInputValue] = useState('');

  const [rows, setRows] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await getQuoteItemData();
    }
    fetchData(); 
  }, []);

  function getQuoteItemData() {
    if(props.data.dataRecord.quote_items){
      while(rows.length > 0) {
        rows.pop();
      }
      const newRows = [...rows];
      for (var i = 0, l = props.data.dataRecord.quote_items.length; i < l; i++) {
        rows.push({ sNo: i+1, productId: props.data.dataRecord.quote_items[i].product_id, quantity: props.data.dataRecord.quote_items[i].quantity, listPrice: props.data.dataRecord.quote_items[i].list_price, amount: props.data.dataRecord.quote_items[i].amount, tax: props.data.dataRecord.quote_items[i].tax, total: props.data.dataRecord.quote_items[i].total,hasError:false });
      } 
      SetQuoteItemArray(rows);
    }else{
      rows.push({ sNo: 1, productId: '', quantity: '', listPrice: '', amount: '', tax: '', total: '',hasError:false });
    }
    //props.data.setFieldValue('QuoteItemArray', rows) 
  }
 
  const handleAddRow = () => {
    const newRow = { sNo: rows.length + 1, productId: '', quantity: '', listPrice: '', amount: '', tax: '', total: '',hasError:false };
    setRows([...rows, newRow]);
  };

  useEffect(()=>{
    if(props.data.productIds){
      const newState = rows.map((x,i) => {
        return i == props.data.productIds ? { ...x, hasError: true } : { ...x, hasError: false};
      });
      setRows(newState);
    }
  },[props.data.productIds]);

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    
    handleCalculation(newRows)
    setRows(newRows);
    if(newRows.length==1 && newRows[index-1]['productId']==''){
      setIsEditable(true)
    }
    props.data.setFieldValue('QuoteItemArray', newRows)
  };

  
  const [finalTotalData, setFinalTotalData] = useState({ 
    finalSubTotal: props.data.dataRecord.sub_total ? props.data.dataRecord.sub_total : '', 
    finalDiscount: props.data.dataRecord.discount ? props.data.dataRecord.discount : '', 
    finalDiscountPer: props.data.dataRecord.discount_value ? props.data.dataRecord.discount_value : '', 
    finalDiscountType:props.data.dataRecord.discount_type ? props.data.dataRecord.discount_type : '',  
    finalTaxDiscount: props.data.dataRecord.tax ? props.data.dataRecord.tax : '',  
    finalAdjustment: props.data.dataRecord.adjustment_amount ? props.data.dataRecord.adjustment_amount : '',  
    finalGrandTotal: props.data.dataRecord.total ? props.data.dataRecord.total : '',  
    finalTaxDiscountType:props.data.dataRecord.tax_type ? props.data.dataRecord.tax_type : '', 
    finalTaxDiscountPer: props.data.dataRecord.tax_value ? props.data.dataRecord.tax_value : '',  
  });

  const handleNumericChange = (e,i) =>{
    var splAmount = e.target.value.split('.')
    e.target.value = splAmount.length==2?splAmount[0]==0?1:splAmount[0]:e.target.value==0?1:e.target.value
  }


  const handleChange = async(event, index, isSelect) => {
    
    const eventValue = event.value;
    const newRows = [...rows];

    if (isSelect) {
      newRows[index]['productId'] = event.value;
      
      const data = {
        product_id: event.value,
      }
      
      if(eventValue){
        //call API to stor temp data for quote item
        await axios.post("/api/quote/add-temp", data).then(response => {
          if (response.status === 200) {
            newRows[index]['productId'] = response.data.data.product.id;
            newRows[index]['quantity'] = 1
            newRows[index]['listPrice'] = response.data.data.product.price ? response.data.data.product.price : ''
            newRows[index]['amount'] = response.data.data.product.price ? response.data.data.product.price : ''
            newRows[index]['discount'] = 0
            newRows[index]['tax'] = response.data.data.product.tax ? response.data.data.product.tax : 0
            newRows[index]['total'] = Number(response.data.data.product.price ? response.data.data.product.price : 0) + Number(response.data.data.product.tax ? response.data.data.product.tax : 0)
            newRows[index]['hasError'] = false;
            handleCalculation(newRows)
            setRows(newRows);
            setIsEditable(false)
          }
        }).then(json => {

        }).catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              //setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
            } else if (error.response.status === 403) {
              toast.warning('Forbidden ' + error.response.data.message);
            } else if (error.response.status === 300) {
              toast.warning(error.response.data.message);
            }else {
              toast.warning(error.response.data.message);
            }
          }
        });
      }else{
          newRows[index]['productId'] ='';
          newRows[index]['quantity'] = ''
          newRows[index]['listPrice'] = ''
          newRows[index]['amount'] = ''
          newRows[index]['discount'] = ''
          newRows[index]['tax'] = ''
          newRows[index]['total'] = '0.00'
          newRows[index]['hasError'] = false;
          handleCalculation(newRows) 
          setRows(newRows);
          if(newRows.length==1){
            setIsEditable(true)
          }
      }
      

    } else {
      const { name, value } = event.target;
      newRows[index][name] = value;
      if(newRows[index]['productId']){
        if (name == 'quantity') {
          var splAmount = value.split('.')
          newRows[index][name]  = splAmount.length==2?splAmount[0]==0?1:splAmount[0]:value
          if(/^[0-9\b]+$/.test(value)){
            if(parseFloat(value)){
              newRows[index]['total'] = (parseFloat(value * newRows[index]['listPrice']) + parseFloat(newRows[index]['tax'])).toFixed(2);
            }else{
              newRows[index]['total'] = (parseFloat(1 * newRows[index]['listPrice']) + parseFloat(newRows[index]['tax'])).toFixed(2);
            }
          }else if (value === '' || isNaN(parseInt(value))) {
            newRows[index]['total'] = (parseFloat(splAmount.length==2?splAmount[0]=='0'?1:splAmount[0]:value * newRows[index]['listPrice']) + parseFloat(newRows[index]['tax'])).toFixed(2);
          }
          //newRows[index]['total'] = parseInt(value * newRows[index]['listPrice']) + parseInt(newRows[index]['tax']);
        } else if (name == 'tax') {
          if (value) {
            newRows[index]['total'] = parseFloat(value) + parseFloat(newRows[index]['quantity'] * newRows[index]['listPrice']);
          } else {
            newRows[index]['total'] = parseFloat(newRows[index]['quantity'] * newRows[index]['listPrice']);
          }
        } else if (name == 'listPrice' && value) {
          if (value) {
            //newRows[index]['amount'] = parseInt(value * newRows[index]['quantity']);
  
            newRows[index]['total'] = parseFloat(value * newRows[index]['quantity']) + parseFloat(newRows[index]['tax']);
          } else {
            newRows[index]['amount'] = 0;
  
            newRows[index]['total'] = 0;
          }
        }
        handleCalculation(newRows)
        setRows(newRows);
      }
      
    }

    props.data.setFieldValue('QuoteItemArray', newRows)
  };

  if(props.data.preferredCurrencyData.currencyCode){
      currencyCode = props.data.preferredCurrencyData.currencyCode ? props.data.preferredCurrencyData.currencyCode: '';
      currencySymbol = props.data.preferredCurrencyData.currencySymbol ? props.data.preferredCurrencyData.currencySymbol: '';
  }

  const myObj = {
      style: "currency",
      currency: currencyCode
  }


  const handleNumericInput = (e) => {
    const { value,name } = e.target;
    const regex = /^[-+]?\d*\.?\d*$/;

    if (regex.test(value) && name==='finalDiscountPer') {
      setInputValue(value);
      handleFinalChanges(e);
    }else if(regex.test(value) && name==='finalTaxDiscountPer'){
      setTaxInputValue(value);
      handleFinalChanges(e);
    }
  };

  const handleKeyDown = (e) => {
    // Prevent the input of non-numeric characters, including + and -
    if (!/[\d.]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
      e.preventDefault();
    }
  };


  const handleAdjustmentNumericInput = (e) =>{
    const { value } = e.target;
    const regex = /^(-)?\d*\.?\d*$/;
    
    if (regex.test(value) || value === '' || value === '-') {
      setAdjustInputValue(value);
      handleFinalChanges(e);
    }
  }

  const handleAdjustKeyDown = (e) =>{
    const { selectionStart, value } = e.target;
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', // Navigation and deletion keys
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.' // Numeric keys and dot
    ];

    // Allow + or - only as the first character
    if (selectionStart === null && (e.key === '-') && (!value)) {
      return;
    }

    // Prevent the input of non-numeric characters
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }

    // Prevent multiple dots
    if (e.key === '.' && value.includes('.')) {
      e.preventDefault();
    }
  }


  const handleFinalChanges = (e) => {
    const { name, value } = e.target;
    //console.log(name, value,'99999999999999999')
    //================START:WRITE LOGIC FOR CLICK ON PER RADIO BUTTON=================//
    if(name==='discount_type' && value==='flat'){
      finalTotalData['finalDiscountType'] = 'flat';
      finalTotalData['finalDiscount'] = finalTotalData['finalDiscountPer']
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0  + finalTotalData['finalAdjustment']);
      setPerSignDisplay(false)
    }else if(name==='discount_type' && value==='percentage'){
      finalTotalData['finalDiscountType'] = 'percentage';
      finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount'])  + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      setPerSignDisplay(true)
    }else if(name!='discount_type' && finalTotalData['finalDiscountType']){
      finalTotalData['finalDiscountType'] = finalTotalData['finalDiscountType'];
    }else{
      finalTotalData['finalDiscountType'] = 'percentage';
      finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount'])  + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      setPerSignDisplay(true)
    }
    //================END:WRITE LOGIC FOR CLICK ON PER RADIO BUTTON=================//

    //================START:WRITE LOGIC FOR CLICK ON TAX PERCENTAGE RADIO BUTTON=================//
    if (name === 'tax_discount_type' && value === 'flat') {
      finalTotalData['finalTaxDiscountType'] = 'flat';
      finalTotalData['finalTaxDiscount'] = finalTotalData['finalTaxDiscountPer']
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      setPerSignDisplayTax(false)
    } else if (name === 'tax_discount_type' && value === 'Percentage') {
      //console.log(finalTotalData['finalSubTotal'],finalTotalData['finalTaxDiscount'],'kkkkk')
      finalTotalData['finalTaxDiscountType'] = 'percentage';
      finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalTaxDiscount']) / 100);
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      setPerSignDisplayTax(true)
    } else if (name != 'tax_discount_type' && finalTotalData['finalTaxDiscountType']) {
      finalTotalData['finalTaxDiscountType'] = finalTotalData['finalTaxDiscountType'];
    } else {
      finalTotalData['finalTaxDiscountType'] = 'percentage';
      finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscount']) / 100);
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      setPerSignDisplayTax(true)
    }
    //================END:WRITE LOGIC FOR CLICK ON TAX PERCENTAGE RADIO BUTTON=================//

    if (name === 'finalDiscountPer') {
      finalTotalData['finalDiscountPer'] = value ? parseFloat(value) : '';
      if (value) {
        if (finalTotalData['finalDiscountType'] === 'percentage') {
          finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
        } else {
          finalTotalData['finalDiscount'] = value ? parseFloat(value) : '';
        }
        //arrange new array for final data
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      } else {
        if (finalTotalData['finalDiscountType'] === 'percentage') {
          finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
        } else {
          finalTotalData['finalDiscount'] = value ? parseFloat(value) : '';
        }
        //arrange new array for final data
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      }
    } else if (name === 'finalTaxDiscountPer') {
      //================START:WRITE LOGIC FOR CLICK ON TAX PERCENTAGE RADIO BUTTON=================//
      finalTotalData['finalTaxDiscountPer'] = value ? parseFloat(value) : '';
      if (value) {
        if (finalTotalData['finalTaxDiscountType'] === 'percentage') {
          finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalTaxDiscountPer']) / 100);
        } else {
          finalTotalData['finalTaxDiscount'] = value ? parseFloat(value) : '';
        }
        //arrange new array for final data
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      } else {
        if (finalTotalData['finalDiscountType'] === 'percentage') {
          finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalTaxDiscountPer']) / 100);
        } else {
          finalTotalData['finalTaxDiscount'] = value ? parseFloat(value) : '';
        }
        //arrange new array for final data
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      }
      //================END:WRITE LOGIC FOR CLICK ON TAX PERCENTAGE RADIO BUTTON=================//
    } else if (name === 'finalTaxDiscount') {
        finalTotalData['finalTaxDiscount'] = value ? parseFloat(value) : '';
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] : 0) + parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalAdjustment'] ? finalTotalData['finalAdjustment'] : 0);
    } else if (name === 'finalAdjustment') {
        finalTotalData['finalAdjustment'] = value ? parseFloat(value) : '';
      if (finalTotalData['finalAdjustment'] > 0) {
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] + finalTotalData['finalAdjustment'] : 0 + finalTotalData['finalAdjustment']);
      } else {
        var slpitdata = value.split('-');
        if (slpitdata.length >= 2) {
          slpitdata = slpitdata[1]
        }
        finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] ? finalTotalData['finalTaxDiscount'] - slpitdata : 0 - slpitdata);
      }
    }

    const newobj = Object.assign({},finalTotalData);
    setFinalTotalData(newobj);

    props.data.setFieldValue('finalData', finalTotalData)
  }


  const handleCalculation = (newRows) => {
    //console.log(newRows,'newRows')
    var itemSubTotal = 0;
    for (var i = 0, l = newRows.length; i < l; i++) {
      itemSubTotal += parseFloat(newRows[i].total);
    }
    subTotal = itemSubTotal;
    grandTotal = itemSubTotal;
    
    finalTotalData['finalSubTotal'] = subTotal
    finalTotalData['finalGrandTotal'] = grandTotal

    if (finalTotalData['finalDiscountPer']) {
      if(finalTotalData['finalDiscountType']==='percentage'){
        finalTotalData['finalDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalDiscountPer']) / 100);
      }else{
        finalTotalData['finalDiscount'] = finalTotalData['finalDiscountPer'];
      }
    }

    if (finalTotalData['finalTaxDiscountPer']) {
      if (finalTotalData['finalTaxDiscountType'] === 'percentage') {
        finalTotalData['finalTaxDiscount'] = parseFloat((finalTotalData['finalSubTotal'] * finalTotalData['finalTaxDiscountPer']) / 100);
      } else {
        finalTotalData['finalTaxDiscount'] = finalTotalData['finalTaxDiscountPer'];
      }
    }

    if (finalTotalData['finalAdjustment']) {
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount']) + parseFloat(finalTotalData['finalAdjustment']);
    } else {
      finalTotalData['finalGrandTotal'] = parseFloat(finalTotalData['finalSubTotal'] - finalTotalData['finalDiscount']) + parseFloat(finalTotalData['finalTaxDiscount'] - finalTotalData['finalAdjustment']);
    }
    //console.log(finalTotalData,'finalTotalData')
    setFinalTotalData(finalTotalData)
    props.data.setFieldValue('finalData', finalTotalData)
    
  }

  //-----------------For Country list-----------------------------------
  const customStyles1 = {
    menuList: styles => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  }

  let productLists = [{ label: 'Select', value: '' }];
  for (var i = 0, l = props.data.productList.length; i < l; i++) {
    productLists.push({ label: props.data.productList[i].name, value: props.data.productList[i].id });
  }

  return (

    <div className="mt-4 history quotetable">
      <table>
        <thead>
          <tr>
            <th key='S.No' style={{ width: "5%" }}>S.No</th>
            <th key='Product Name' style={{ width: "20%" }}>Product Name</th>
            <th key='Quantity' style={{ width: "15%" }}>Quantity</th>
            <th key='Amount' style={{ width: "15%" }}>Amount ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</th>
            <th key='List Price' style={{ width: "15%" }}>List Price ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</th>
            <th key='Tax' style={{ width: "10%" }}>Tax ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</th>
            <th key='Total' style={{ width: "10%" }}>Total ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</th>
            <th key='Action' style={{ width: "10%" }}>Action</th>
            
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) => {
              //if (index == index - 1) {
                for (var i = 0, l = props.data.productList.length; i < l; i++) {
                  if (row.productId == props.data.productList[i].id) {
                    selecteProduct.push({ label: props.data.productList[i].name, value: props.data.productList[i].id });
                  }
                }
             // }

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Select
                      value={productLists.filter(({ value }) => value === row.productId)} 
                      //defaultValue={selecteProduct}
                      //name={`productId[${index}]name`}
                      options={productLists}
                      className={row.hasError ? 'basic-multi-select quoteinput input-select-error':'basic-multi-select quoteinput'}
                      //className='basic-multi-select quoteinput'
                      classNamePrefix="select"
                      menuPosition="fixed"
                      //value={row.productId}
                      // onChange={selectedOption => {
                      //      values.product_id = selectedOption.value;
                      //      handleChange("product_id",index);
                      // }}
                      onChange={(event) => {handleChange(event, index, true)}}
                      styles={customStyles1}
                      isClearable={false}
                      isSearchable={true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                        },
                      })}
                    />
                    {
                      row.hasError ?
                      <span className="error error4">
                        <span>Please select product</span>
                      </span>
                      :
                      null
                    }
                    {/* <input
                        type="textarea"
                        name="productDescription"
                        value={row.productId}
                        placeholder="Product Description"
                        onChange={(event) => handleChange(event, index)}
                    /> */}
                      {/* {
                        errors.productId?.[index]?.name ?
                          <span className="error error4">
                                <ErrorMessage name="productId" />
                          </span>
                        :
                          ''
                      } */}
                        <span className="error error4">
                                <ErrorMessage name="productId" />
                          </span>
                  </td>
                  <td>
                    <input
                      type="number"
                      name="quantity"
                      value={row.quantity}
                      onChange={(event) => handleChange(event, index)}
                      className="form-control"
                      onBlur={handleNumericChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="amount"
                      value={row.amount}
                      className="form-control"
                      onChange={(event) => handleChange(event, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="listPrice"
                      value={row.listPrice}
                      className="form-control"
                      disabled
                    />
                  </td>      
                  <td>
                    <input
                      type="number"
                      name="tax"
                      value={row.tax}
                      className="form-control"
                      onChange={(event) => handleChange(event, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="total"
                      value={row.total}
                      className="form-control"
                      disabled
                    />
                  </td>
                  <td>
                    {
                      index + 1 != 1 ?
                        <div onClick={() => handleRemoveRow(index)}><span className="deleteicon1"></span></div>
                        :
                        ''
                    }
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      <div className="display mt-3">
      <div className="col-md-9 display justify-content-start mt-3">
      <div onClick={() => handleAddRow()}  className="createbtn btnbluecolor">Add New <span className="createicon"></span></div>
      </div>
      <div className="col-md-3">
      <div className="display justify-content-end quotesubtotal">
      <table>
        <tbody>
          <tr key='subtotal'>
            <td>Sub Total ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
              <input
                type="number"
                name="finalSubTotal"
                value={finalTotalData.finalSubTotal ? (finalTotalData.finalSubTotal).toFixed(2):finalTotalData.finalSubTotal}
                disabled
                className="form-control"
              />
            </td>
          </tr>
          {/* <tr>
            <td>
              <Form.Check
                checked={finalTotalData.finalDiscountType === 'percentage' ? true:false}
                type="radio"
                //defaultValue="option1"
                label="Percentage"
                name="discount_type"
                value='Percentage'
                onChange={(e)=> handleFinalChanges(e)}
                //id="radio1"
                htmlFor="radio1"
              />
            </td>
            <td>
            <Form.Check
                checked={finalTotalData.finalDiscountType === 'flat' ? true:false}
                type="radio"
                //defaultValue="option1"
                label="Flat"
                name="discount_type"
                //id="radio1"
                value='flat'
                onChange={(e) => handleFinalChanges(e)}
                htmlFor="radio1"
              />
            </td>
          </tr> */}
          <tr key='discount'>
            <td>Discount ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
            <div className="display mb-2">
            <Form.Check
                checked={finalTotalData.finalDiscountType === 'percentage' ? true:false}
                type="radio"
                //defaultValue="option1"
                label="Percentage"
                name="discount_type"
                value='Percentage'
                onChange={(e)=> handleFinalChanges(e)}
                //id="radio1"
                htmlFor="radio1"
                disabled={isEditable}
              />
              <Form.Check
                checked={finalTotalData.finalDiscountType === 'flat' ? true:false}
                type="radio"
                //defaultValue="option1"
                label="Flat"
                name="discount_type"
                className="flatmargin"
                //id="radio1"
                value='flat'
                onChange={(e) => handleFinalChanges(e)}
                htmlFor="radio1"
                disabled={isEditable}
              />
            </div>
            <div className="discountinline">
              <input
                type="number"
                name="finalDiscountPer"
                max={100} 
                step={0.01}
                //value={finalTotalData.finalDiscountPer}
                //onChange={(e) => handleFinalChanges(e)}
                value={finalTotalData.finalDiscountPer ? finalTotalData.finalDiscountPer : inputValue}
                onChange={handleNumericInput}
                onKeyDown={handleKeyDown}
                className="form-control discountfinal"
                disabled={isEditable}
              />
                {perSignDisplay ?
                  <span className="percetangeicon"></span>
                  :
                  ''
                }
                <input
                type="number"
                name="finalDiscount"
                //value={Math.round((parseFloat(finalTotalData.finalDiscount) + Number.EPSILON) * 100) / 100}
                value={finalTotalData.finalDiscountType === 'percentage' ? finalTotalData.finalDiscount ? (finalTotalData.finalDiscount).toFixed(2):finalTotalData.finalDiscount : finalTotalData.finalDiscount?(finalTotalData.finalDiscount).toFixed(2):finalTotalData.finalDiscount}
                disabled
                className="form-control discountfinal"
              />
            </div>
            </td>
          </tr>
          {/* <tr key='tax'>
            <td>Tax ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
              <input
                type="number"
                name="finalTax"
                value={finalTotalData.finalTax? parseFloat(finalTotalData.finalTax):''}
                step="any"
                onChange={(e) => handleFinalChanges(e)}
                className="form-control"
                disabled={isEditable}
              />
            </td>
          </tr> */}
          <tr key='tax'>
            <td>Tax ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
            <div className="display mb-2">
            <Form.Check
                checked={finalTotalData.finalTaxDiscountType === 'percentage' ? true:false}
                type="radio"
                //defaultValue="option1"
                label="Percentage"
                name="tax_discount_type"
                value='Percentage'
                onChange={(e)=> handleFinalChanges(e)}
                //id="radio1"
                htmlFor="radio1"
                disabled={isEditable}
              />
              <Form.Check
                checked={finalTotalData.finalTaxDiscountType === 'flat' ? true:false}
                type="radio"
                //defaultValue="option1"
                label="Flat"
                name="tax_discount_type"
                className="flatmargin"
                //id="radio1"
                value='flat'
                onChange={(e) => handleFinalChanges(e)}
                htmlFor="radio1"
                disabled={isEditable}
              />
            </div>
            <div className="discountinline">
              <input
                type="number"
                name="finalTaxDiscountPer"
                max={100} 
                step={0.01}
                //value={finalTotalData.finalTaxDiscountPer}
                //onChange={(e) => handleFinalChanges(e)}
                value={finalTotalData.finalTaxDiscountPer ? finalTotalData.finalTaxDiscountPer : taxInputValue}
                onChange={handleNumericInput}
                onKeyDown={handleKeyDown}
                className="form-control discountfinal"
                disabled={isEditable}
              />
                {perSignDisplayTax ?
                  <span className="percetangeicon"></span>
                  :
                  ''
                }
                <input
                  type="number"
                  name="finalTaxDiscount"
                  //value={Math.round((parseFloat(finalTotalData.finalDiscount) + Number.EPSILON) * 100) / 100}
                  //value={finalTotalData.finalTaxDiscountType === 'percentage' ? finalTotalData.finalTaxDiscount : finalTotalData.finalTaxDiscount}
                  value={finalTotalData.finalTaxDiscountType === 'percentage' ? finalTotalData.finalTaxDiscount?(finalTotalData.finalTaxDiscount).toFixed(2):finalTotalData.finalTaxDiscount : finalTotalData.finalTaxDiscount?(finalTotalData.finalTaxDiscount).toFixed(2):finalTotalData.finalTaxDiscount}
                  disabled
                  className="form-control discountfinal"
                />
            </div>
            </td>
          </tr>
          <tr key='adjustment'>
            <td>Adjustment ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
              <input
                type="number"
                name="finalAdjustment"
                step="any"
                //value={finalTotalData.finalAdjustment ? parseFloat(finalTotalData.finalAdjustment):''}
                //onChange={(e) => handleFinalChanges(e)}
                value={finalTotalData.finalAdjustment?parseFloat(finalTotalData.finalAdjustment):adjustInputValue}
                onChange={handleAdjustmentNumericInput}
                onKeyDown={handleAdjustKeyDown}
                className="form-control"
                disabled={isEditable}
              />
            </td>
          </tr>
          <tr key='grandtotal'>
            <td>Grand Total ({props.data.preferredCurrencyData.currencySymbol != '' ? props.data.preferredCurrencyData.currencySymbol : ''})</td>
            <td>
              <input
                type="number"
                name="finalGrandTotal "
                value={Math.round((parseFloat(finalTotalData.finalGrandTotal) + Number.EPSILON) * 100) / 100
              }
                className="form-control"
                disabled
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      </div>
      </div>
    </div>

  )
}

export default memo(QuoteCloneItems);
