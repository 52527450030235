
import React, { useState,useEffect } from "react";
import { Col, Row, Form, Button, Spinner, Container, InputGroup } from '@themesberg/react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Formik, ErrorMessage } from "formik";
import { DisplayHostName } from "../../features/userSlice";
import { useSelector } from "react-redux";
import * as yup from "yup";

function ResetPassword() {
  
  //get default host data from redux using url
  var defaultHostData = useSelector(DisplayHostName);
  //var hostname = defaultHostData?.hostname||''
  var displayHost = defaultHostData?.displayHost||''
  var logoUrl = ''
  
  if(displayHost){
    logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/`+displayHost+`.png`;
  }
  
  const checkFileExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if(response.status === 200){
       logoUrl = logoUrl;
      }
    } catch (error) {
      logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/app.png`; 
    }
  }
  
  
  
  useEffect(() => {
    async function fetchData() {
      await checkFileExistence(logoUrl);
    }
    fetchData();

  },[displayHost]);

  const history = useHistory();

  const pathname = window.location.pathname;
  const segmentToken = pathname.substring(pathname.lastIndexOf('/') + 1);
  const [passwordShown, setPasswordShown] = useState(false);
  const [conPasswordShown, setConPasswordShown] = useState(false);

  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');

  const [defaultValue, setdefaultValue] = useState({
    email: '',
    password: '',
    confirm_password: '',
    error_list: [],
  })

  const validationSchema = yup.object().shape({
    password: yup.string().required('Password cannot be less than 8 characters').min(8).max(20).matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)\+\-\/])(?=.{8,})/,
      "Must Contain one Uppercase, one Lowercase, one Number and one Special Case Character"
    ),
    confirm_password: yup.string().required('Enter your confirm password').oneOf([yup.ref('password'), null], "Password doesn't match")
  })

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConPassword = () => {
    setConPasswordShown(!conPasswordShown);
  };


  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      email: email,
      password: values.password,
      confirm_password: values.confirm_password,
      token: segmentToken,
    }

    await axios.post("/api/reset-password", data).then(response => {
      if (response.status === 200) {
        toast.success(response.data.message);
        window.location.href = "/sign-in"
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
          history.push('/forgot-password');
        } else if (error.response.status === 500) {
          toast.error('Oops Something went wrong.');
          history.push('/forgot-password');
        } else if (error.response.data.validation_errors) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        } else {
          toast.error(error.response.data.message);
          history.push('/forgot-password');
        }

      }
    });
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 common-form">
        <Container>
          <div className="title text-center mb40">
            <a href={process.env.REACT_APP_MARKETING_WEB_URL}><img src={logoUrl} alt="signlogo" className="signlogo" style={{ cursor: 'pointer' }} /></a>
          </div>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white w-100 box-form">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Reset password</h3>
                </div>
                <Formik
                  initialValues={defaultValue}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >{props => {
                  const {
                    //values,
                    touched,
                    errors,
                    //dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    //handleReset
                  } = props;
                  return (
                    <Form className="mt-4" onSubmit={handleSubmit}>
                      <Form.Group id="email" className="mt-5">
                        <Form.Control type="hidden" name="token" value={segmentToken} />
                        <InputGroup>
                          <Form.Control type="email" name="email" onChange={handleChange} value={email} onBlur={handleBlur} placeholder="john@example.com" className={errors.email && touched.email ?
                            "input-error" : null} />
                        </InputGroup>
                        <span className="error">{defaultValue.error_list.email}</span>
                        <p className="text-danger error3">
                          <ErrorMessage name="email" />
                        </p>
                      </Form.Group>
                      <Form.Group id="password" className="mt-5">
                        <InputGroup>
                          <Form.Control type={passwordShown ? "text" : "password"} name="password" onChange={handleChange} onBlur={handleBlur} placeholder="Password" className={errors.password && touched.password ?
                            "input-error" : null} />
                          <span onClick={togglePassword} className={passwordShown ? "password_icon" : "signin_icon"}></span>
                        </InputGroup>
                        <p className="error">{defaultValue.error_list.password}</p>
                        <p className="text-danger error3">
                          <ErrorMessage name="password" />
                        </p>
                      </Form.Group>
                      <Form.Group id="confirmPassword" className="mt-5 mb-5">
                        <InputGroup>
                          <Form.Control type={conPasswordShown ? "text" : "password"} name="confirm_password" onChange={handleChange} onBlur={handleBlur} placeholder="Confirm Password" className={errors.confirm_password && touched.confirm_password ?
                            "input-error" : null} />
                          <span onClick={toggleConPassword} className={conPasswordShown ? "password_icon" : "signin_icon"}></span>
                        </InputGroup>
                        <p className="error">{defaultValue.error_list.confirm_password}</p>
                        <p className="text-danger error3">
                          <ErrorMessage name="confirm_password" />
                        </p>
                      </Form.Group>
                      {
                        isSubmitting ?

                          <Button variant="primary" className="d-flex align-items-center justify-content-center text-center mb-1 w-100">
                            <Spinner animation="border" size="sm" />
                            <span className="ms-2">Please Wait...</span>
                          </Button>
                          :
                          <Button variant="primary" type="submit" className="w-100">
                            Reset password
                          </Button>
                      }
                    </Form>
                  );
                }}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default ResetPassword;
