import React, { useEffect, useState, memo } from "react";
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import Preloader from "./PreloaderCenter";
import { useHistory } from 'react-router-dom';
import moment from "moment-timezone";
import Cookies from 'universal-cookie';
import {  selectUser, LoginAccessMsg, selectDefaultData } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';


var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = 6;
var currentPage = 1;
var calendorFormate = '';
var searchData = '';
var currencyCode = '';
var currencySymbol = '';


function MyThisMonthClosingDeals(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [dealList, setDealList] = useState([]);

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [calendorDateFormate, setCalendorDateFormate] = useState('MMM, DD YYYY');

  const loginUser = useSelector(selectUser);
  const user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  //check msg for nonAuthentication Action pages
  const withOutLoginMsg = useSelector((state) => state.loginAccessMsg);
  if (withOutLoginMsg) {
    toast.warning(withOutLoginMsg);
    dispatch(LoginAccessMsg());
  }
  
  const [stateDeal, setStateDeal] = useState({
    users: ''
  });


  useEffect(() => {
    setTimeout(async () => {
      await getData()
    }, 800);
    
  }, []);


  const getData = async (currentPage) => {

    const params = {
      search: searchData,
      sort_field: '',
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
    }
    await axios.get(`api/dashboard-closing-deal?page=${currentPage}`, { params }).then(response => {
      if (response.status === 200) {
        //console.log('dash',response) 
        setDealList(response.data.data.dealRecords.data)
        setStateDeal({
          users: response.data.data.dealRecords
        });

        props.handleDealCount(response.data.data.dealCount)
        setPreferredCurrencyData(response.data.data.preferredCurrency);

        calendorFormate = response.data.data.preferredDateFormat.split(' ');
        if (calendorFormate) {
          if (calendorFormate[0] == 'YYYY-MMM-DD') {
            setCalendorDateFormate('YYYY MMM, DD');
          } else if (calendorFormate[0] == 'DD-MMM-YYYY') {
            setCalendorDateFormate('DD, MMM YYYY');
          } else if (calendorFormate[0] == 'YYYY-DD-MMM') {
            setCalendorDateFormate('YYYY DD, MMM');
          } else if (calendorFormate[0] == 'MMM-DD-YYYY') {
            setCalendorDateFormate('MMM, DD YYYY');
          }
        }

        if (response.data.data.preferredCurrency.currencyCode) {
          currencyCode = response.data.data.preferredCurrency.currencyCode ? response.data.data.preferredCurrency.currencyCode : '';
          currencySymbol = response.data.data.preferredCurrency.currencySymbol ? response.data.data.preferredCurrency.currencySymbol : '';
        }

      }
      setLoading(false);
    }).then(json => {
      toast.success(json.data.data.message);
    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        }else if (error.response.status === 500) {
          //toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      }
    });
  }

  var dealDataList = '';

  if (loading) {
    return <Preloader show={true} />;
  } else {

    var dealName = 'N/A'
    var stageName = 'N/A'
    dealDataList = dealList.map((item, i) => {
      if(item.deal_name){
        dealName = item.deal_name
      }else if (item.get_contact && item.get_contact.title) {
        dealName = item.get_contact.title
      }

      if (item.get_stage && item.get_stage.name) {
        stageName = item.get_stage.name
      }

      let num = parseInt(item.deal_amount);
      const myObj = {
        style: "currency",
        currency: currencyCode
      }

      return (
        <tr key={item.id} onClick={() => history.push('edit-deal/' + item.id)}>
          <td className="name"><span>{dealName}</span></td>
          <td className="stage"><span>{stageName}</span></td>
          <td className="amounttruncate"><span>{num ? preferredCurrencyData.currencyStringValue ? num.toLocaleString(preferredCurrencyData.currencyStringValue, myObj) : currencySymbol + num.toLocaleString(myObj) : 'N/A'}</span></td>
          <td>{item.closing_date ? moment.tz(item.closing_date, defaultTimeZone).format(calendorDateFormate) : 'N/A'}</td>
        </tr>
      )
    });
      
    return(
      <div className="width1">
          <div className="display flex-wrap task align-items-start justify-content-end">
            <div className="col-md-12">
              <h5 className="tables_title">My This Month’s Closed Deals</h5>
            </div>
            {/* <Scrollbars style={{height: 300 }} className="scrollbar-bx"> */}
            <table>
              <tbody>
              {
                dealDataList.length >= 1 ?

                  <tr key={'j'}>
                    <th style={{width:"35%"}}>Name</th>
                    <th style={{width:"20%"}}>Stage</th>
                    <th style={{width:"20%"}}>Amount</th>
                    <th style={{width:"25%"}}>Close Date</th>
                  </tr>
                  :
                  ''
              }
              {
                dealDataList.length >= 1 ?

                  dealDataList
                  :
                  <tr key={'d'}>
                    <td colSpan={4}><span className="text-center d-flex align-items-center justify-content-center">No Data Available</span></td>
                  </tr>
              }
            </tbody>
            </table>
            {/* </Scrollbars> */}
            {
              dealDataList.length >= 1 ?

                <div className="justify-content-end">
                  {/* <Col md={12}> */}
                    <Pagination
                      activePage={stateDeal?.users?.current_page ? stateDeal?.users?.current_page : 0}
                      itemsCountPerPage={stateDeal?.users?.per_page ? stateDeal?.users?.per_page : 0}
                      totalItemsCount={stateDeal?.users?.total ? stateDeal?.users?.total : 0}
                      onChange={(pageNumber) => {
                        currentPage = (pageNumber);
                        getData(pageNumber)
                      }}
                      pageRangeDisplayed={5}
                      itemClass="page-item"
                      linkClass="page-link"
                    // firstPageText="First Page"
                    // lastPageText="Last Page"
                    />
                  {/* </Col> */}
                </div>


                :
                ''
            }
          </div>
        </div>
    )
   }
}

export default memo(MyThisMonthClosingDeals);
