import React,{Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from '@themesberg/react-bootstrap';

import { Routes } from "../routes";
import NotFoundImage from "../assets/img/illustrations/Opps.svg";

export class ErrorBoundary extends Component{
  constructor(props){
    super(props)
    this.state={
      hasError:false,
    }
  }

  static getDerivedStateFromError(error){
    return{
      hasError:true,
    }
  }

  componentDidCatch(error,errorInfo){
    console.log('displayError',error,errorInfo)
  }

  render(){
    if(this.state.hasError){
      return <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <Card.Link  >
                  <Image src={NotFoundImage} className="img-fluid w-75" />
                </Card.Link>
                <h1 className="text-primary mt-5">
                  Oops...! <span className="fw-bolder">Something went wrong</span>
                </h1>
                <p className="lead my-4">
                  Oops! Looks like you followed a bad link. If you think this is a
                  problem with us, please tell us.
              </p>
                <Button  variant="primary" className="animate-hover" href={Routes.Presentation.path} >
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  Go back home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
    }
    return this.props.children
  }
}