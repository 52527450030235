import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Card, Spinner, Form } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import { toast } from 'react-toastify';
import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import { wait } from "@testing-library/react";

function AddProduct(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [submitEvent, setSubmitEvent] = useState(false);
  const [fileErrorMsg, setFileErrorMsg] = useState('');

  const [DataInput, setDataInput] = useState({
    name: '',
    description: '',
    product_code: '',
    price: '',
    image: '',
    error_list: [],
  });

  const nameRef = useRef();
  const descriptionRef = useRef();
  const productCodeRef = useRef();
  const priceRef = useRef();
  const imageRef = useRef();
  

  const [nameError, setNameError] = useState(false);
  const [priceError, setpriceError] = useState(false);
  const [imageError, setImageError] = useState(false);

  const [formnameError, setFormNameError] = useState(false);
  const [formpriceError, setFormPriceError] = useState(false);
  const [formImageError, setFormImageError] = useState(false);
  const [formimage, setFormImage] = useState([]);

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [productImageArray, setProductImageArray] = useState([]);

  useEffect(() => {

    getData();

  }, []);


  function getData() {
    axios.get('api/product/create').then(response => {
      if (response.status === 200) {
        setPreferredCurrencyData(response.data.data.preferredCurrency);
        setLoading(false);
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 403) {
            // swal("Forbidden", error.response.data.message, "error");
            toast.warning('Forbidden ' + error.response.data.message);
            history.push('/products');
          } else {
            //swal("error", error.response.data.message, "error");
            toast.error('Oops Something went wrong.');
          }
        }

      }
    });
  }

  const handleOnValueChange = (value) => {
    setDataInput({ ...DataInput, ['price']: value });
  };

  const handleImageInput = (e) => {
    e.persist();

    setProductImageArray({ ...productImageArray, file_url: [...e.target.files] });
    setDataInput({ ...DataInput, [e.target.name]: e.target.value });

  }

  const handleInput = (e) => {
    e.persist();

    let names = e.target.name;
    let values = e.target.value;
    validateFormData(names, values);
    setDataInput({ ...DataInput, [e.target.name]: e.target.value });

  }

  function validateFormData(type, value) {

    if (type == 'name' && value == '') {
      setNameError(true);
      setDataInput(DataInput.error_list.name = 'This field is required');
    } else {
      if (type == 'name' && value != '') {
        setNameError(false);
        setDataInput(DataInput.error_list.name = '');
      }
    }


    var numericPattern = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    if (type == 'price' && value != '' && !numericPattern.test(value)) {
      setpriceError(true);
      setDataInput(DataInput.error_list.price = 'Please enter numeric value');
    } else {
      if (type == 'price' && value == '') {
        setpriceError(false);
        setDataInput(DataInput.error_list.price = '');
      } else if (type == 'price' && value != '' && numericPattern.test(value)) {
        setpriceError(false);
        setDataInput(DataInput.error_list.price = '');
      }
    }

  }

  const productSubmit = async (e) => {
    e.preventDefault();

    if (DataInput.name == '') {
      setNameError(true);
      nameRef.current.focus();
    }

    
    var numericPattern1 = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
    if (DataInput.price != '') {
      if (!numericPattern1.test(DataInput.price)) {
        setpriceError(true);
        priceRef.current.focus();
      }
    } else {
      setpriceError(false);
    }

    if (!nameError && !priceError) {
      
      const formData = new FormData();
      formData.append('name', DataInput.name)
      formData.append('description', DataInput.description)
      formData.append('product_code', DataInput.product_code)
      formData.append('price', DataInput.price)

      if(productImageArray.file_url){
        for (var i = 0; i < productImageArray.file_url.length; i++) {
          formData.append('file_url[]', productImageArray.file_url[i])
        }
      }

      setSubmitEvent(true)
      await axios.post("/api/product/store", formData).then(response => {
        if (response.status === 200) {
          toast.success('Product has been added successfully');
          history.push('/products')
        }
      }).then(json => {

      }).catch(error => {
        if (error.response) {
          setSubmitEvent(false)
          if (error.response.status === 400) {
            if (error.response.data.validation_errors) {
              setDataInput({ ...DataInput, error_list: error.response.data.validation_errors });
              var msg = JSON.stringify(error.response.data.message)
              const res = JSON.parse(msg);
              const displayMsg = [];
              Object.entries(res).forEach((entry) => {
                const [key, value] = entry;
                if(`${key}` != 'name'){
                  displayMsg.push(`${value}`);
                }
              });
              setFileErrorMsg(displayMsg)
            } else {
              toast.warning(error.response.data.message);
            }
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning('Forbidden ' + error.response.data.message);
            history.push('/products');
          } else {
            toast.error(error.response.data.message);
          }
        }

      });
    }
  }

  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {

    return (
      <>
        <Row>
          <Col xs={12} xl={12}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Row className="mb-3">
                <Col md={6}>
                </Col>
                <Col md={6} className="text-right">
                  <button className="btn1 me-1" onClick={() => { history.goBack(); }}><span className="backarrow-icon"></span>Back</button>
                </Col>
              </Row>

              <Card.Body className="borderline mb-5">
                <Row>
                  <h5 className="mb-4">Add Product information</h5>
                </Row>
                <Form onSubmit={productSubmit} Encrypted="multipart/form-data">
                  <Row>
                    <div className="col-md-6 col-sm-6 left-form">
                      <Row className="align-items-center">
                        <Form.Group className="form-group mb-4 position-relative">
                          <Form.Label column sm="3" className="py-0">
                            Name<span className="text-danger"> * </span>
                          </Form.Label>
                          <Col sm="9">
                            <Form.Group id="name">
                              <Form.Control type="text" required name="name" onChange={handleInput} ref={nameRef} onBlur={handleInput} value={DataInput.name} placeholder="Enter product name" />
                            </Form.Group>
                            <span className="error error4">{DataInput.error_list.name}</span>
                          </Col>
                        </Form.Group>
                        <Form.Group className="form-group mb-4 position-relative">
                          <Form.Label column sm="3" className="py-0">
                            Product Code
                          </Form.Label>
                          <Col sm="9">
                            <Form.Group id="product_code">
                              <Form.Control type="text" name="product_code" onChange={handleInput} ref={productCodeRef} onBlur={handleInput} value={DataInput.product_code} placeholder="Enter product code" />
                            </Form.Group>
                            <span className="error error4">{DataInput.error_list.product_code}</span>
                          </Col>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label column sm="3" className="py-0">
                            Image
                          </Form.Label>
                          <Col sm="9">
                            <Form.Group id="image">
                              <Form.Control type="file" multiple name="file_url[]" ref={imageRef} onChange={handleImageInput} />
                              <small>Allow file type are : jpg, jpeg, png, gif</small>
                            </Form.Group>
                            <span className="error">{DataInput.error_list.file_url}</span>
                            <ul>
                              { fileErrorMsg ? 
                              
                              fileErrorMsg.map((fileErrorMsg) => (
                                <li key={fileErrorMsg} style={{color:'red'}}>{fileErrorMsg}</li>
                              )) 
                              
                              : '' 
                              
                              }
                            </ul>
                          </Col>
                        </Form.Group>
                      </Row>
                    </div>
                    <div className="col-md-6 col-sm-6 right-form">
                      <Row className="align-items-center">
                        <Form.Group className="form-group mb-4 position-relative">
                          <Form.Label column sm="3" className="py-0">
                            Description
                          </Form.Label>
                          <Col sm="9">
                            <Form.Group id="description">
                              <Form.Control type="text" name="description" onChange={handleInput} ref={descriptionRef} onBlur={handleInput} value={DataInput.description} placeholder="Enter description" />
                            </Form.Group>
                            <span className="error error4">{DataInput.error_list.description}</span>
                          </Col>
                        </Form.Group>
                        <Form.Group className="form-group position-relative">
                          <Form.Label column sm="3" className="py-0">
                            Price - {preferredCurrencyData.currencyCode != '' ? ' ' + preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                            ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                          </Form.Label>
                          <Col sm="9">
                            <Form.Group id="price">
                              <CurrencyInput
                                id="price"
                                name="price"
                                intlConfig={{
                                  locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                                  currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                                }}
                                placeholder="Enter price"
                                className={`form-control`}
                                onValueChange={handleOnValueChange}
                                decimalsLimit={6}
                                value={DataInput.price}
                                step={1}
                              />
                            </Form.Group>
                            <span className="error error4">{DataInput.error_list.price}</span>
                          </Col>
                        </Form.Group>
                      </Row>
                    </div>
                  </Row>
                  {
                    submitEvent ?

                      <Button variant="primary" className="d-flex align-items-center mb-1">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2">Please Wait...</span>
                      </Button>
                      :
                      <Button variant="primary" type="submit" className="d-flex">Submit <span class="rightarrow"></span></Button>
                  }
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default AddProduct;


