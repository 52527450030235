import React, { useState, useCallback, useRef, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { useHistory, } from 'react-router-dom';
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import { Col, Row, Button, Form, Spinner, Modal } from '@themesberg/react-bootstrap';
import Preloader from "../components/PreloaderCenter";
import moment from "moment-timezone";
import {HTML5Backend} from "react-dnd-html5-backend";
import update from "immutability-helper";
import { toast } from 'react-toastify';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import "../assets/css/styles/app.css";
import "../assets/css/styles/app.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import { displayDate } from '../utils/utils';

const classes = {
  board: {
    display: "flex",
    margin: "0 auto",
    maxwidth: "90vw",
    fontFamily: '"Montserrat", sans-serif'
  },
  column: {
    minWidth: 200,
    width: "19vw",
    height: "80vh",
    margin: "30px 35px 0 auto",
    padding: "0 20px",
    background: "rgba(219, 247, 237, 0.7)",
    borderRadius: "15px",
    overflowY: "auto"
  },
  columnHead: {
    textAlign: "left",
    padding: "20px 0px",
    fontSize: "1.375rem",
    fontWeight: "600",
    color: "#222222",
    backgroundColor: "transparent",
    margin: "0px",
    lineHeight: "normal"
  },
  item: {
    padding: "15px",
    margin: "10px 0px 20px",
    fontSize: "0.8em",
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: "15px"
  }
};

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var searchInputDataName = '';
var searchInputDataEmail = '';
var searchInputDataFromDate = '';
var searchInputDataToDate = '';
var searchInputDataTitle = '';
var searchInputDataPhone = '';
var searchInputDataOrganizationName = '';

var currencyCode = '';
var currencySymbol = '';

const Kanban = (props) => {
  const channels = [];
  const tasksList = [];

  const history = useHistory();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [listDeal, setListDeal] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
  const [tasks, setTaskStatus] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [dealStatus, setDealStatus] = useState();

  const [selectStageId, setSelectStageId] = useState();

  //===========START:For close and lost============//

  const [dealID, setDalID] = useState();
  const [dealRecordData, setDealRecordData] = useState('');
  const [dealContactRecord, setDealContactRecord] = useState('');
  const [dealAccountRecord, setDealAccountRecord] = useState('');
  const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');
  const [showWonStatus, setShowWonStatus] = useState(false);
  const handleChangeWonStatusClose = () => {
    setShowWonStatus(false)
  }

  const wonAmountRef = useRef();
  const [formWonAmountError, setFormWonAmountError] = useState(false);
  const [formWonClosingDateError, setFormWonClosingDateError] = useState(false);
  const [dealAmount, setDealAmount] = useState();
  const [dealNote, setDealNote] = useState();

  const [dealWon, setDealWon] = useState({
    note: '',
    deal_amount: '',
    stage_id: '',
    closing_date: new Date(),
    error_list: [],
  });
  const [wonClosingDate, setWonClosingDate] = useState(new Date());
  const [submitWonClose, setSubmitWonClose] = useState(false);
  //===========End:For close and lost============//
  const [searchString, setSearchString] = useState();

  const loginUser = useSelector(selectUser);
  const user = loginUser?loginUser:[];
  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
  
  const handleSearchInput = (e) => {
    setSearchString(e.target.value);
  }

  const manageSearch = (e) => {
    e.preventDefault();
    setSearch(searchString)
  }

  var calendorFormate = '';

  useEffect(() => {
    async function fetchData() {
      await getDealData();
    }
    fetchData();
  }, [isUpdate, search]);


  const getDealData = async (currentPage) => {
    const params = {
      search: search,
      search_name: searchInputDataName,
      search_email: searchInputDataEmail,
      search_from_date: searchInputDataFromDate,
      search_to_date: searchInputDataToDate,
      search_title: searchInputDataTitle,
      search_status: '',
      search_lead_source: '',
      search_phone: searchInputDataPhone,
      search_organization_name: searchInputDataOrganizationName,
      sort_field: '',
      search_canban_list: 'yes',
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,

    }

    await axios.get(`api/deal?page=${currentPage}`, { params }).then(response => {

      if (response.status === 200) {
        setListDeal(response.data.data.records);
        setStageList(response.data.data.leadStage);
        setPreferredCurrencyData(response.data.data.preferredCurrencyData)

        if (response.data.data.preferredCurrencyData.currencyCode) {
          currencyCode = response.data.data.preferredCurrencyData.currencyCode ? response.data.data.preferredCurrencyData.currencyCode : '';
          currencySymbol = response.data.data.preferredCurrencyData.currencySymbol ? response.data.data.preferredCurrencyData.currencySymbol : '';
        }
        
        const halfDate = displayDate(response?.data?.data?.preDateFormat,'half')
        setCalendorFullDateFormate(halfDate);
        
        setLoading(false);
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
          toast.warning(error.response.data.message);
          history.push('/deals');
        } else {
          toast.error(error.response.data.message);
          history.push('/deals');
        }
      }
    });
  }

  stageList.map((t) => {
    channels.push({ sname: t.name, sid: t.id, scount: t.dealCount, samount: t.dealAmount })
  })
  
  //set Kanbar Bord name (Card name)
  listDeal.map((l) => {
    let FFName = ''
    if (l.get_contact) {
      if (l.get_contact.first_name && l.get_contact.last_name) {
        FFName = l.get_contact.first_name.charAt(0) + l.get_contact.last_name.charAt(0)
      } else if (l.get_contact) {
        FFName = l.get_contact.first_name ? l.get_contact.first_name : 'N/A';
      } else if (l.get_contact) {
        FFName = l.get_contact.last_name ? l.get_contact.last_name : 'N/A';
      }
    }

    let displayProductName = ''
    if (l.selected_product) {
      displayProductName = l.selected_product
    }

    tasksList.push({ _id: l.id, title: l.get_contact ? l.get_contact.title ? l.get_contact.title : '' : '', dealName: l.deal_name ? l.deal_name : '', status: l.selected_stage_name ? l.selected_stage_name.name ? l.selected_stage_name.name : '' : '', sortName: FFName, profile_pic: l.profile_pic, first_name: l.get_contact ? l.get_contact.first_name ? l.get_contact.first_name : 'N/A' : 'N/A', last_name: l.get_contact ? l.get_contact.last_name ? l.get_contact.last_name : 'N/A' : 'N/A', selectedProduct: displayProductName, organization_name: l.get_account ? l.get_account.organization_name ? l.get_account.organization_name : 'N/A' : 'N/A',created_at:l.created_at })
  })


  //channels
  const changeTaskStatus =  useCallback(async (id, status, sid) => {
      setSelectStageId(sid)
      setDealStatus(status)
      if (status == 'Closed Won' || status == 'Closed Lost') {
        setDalID(id)
        await axios.get('api/deal/edit/' + id).then(response => {
          if (response.status === 200) {
            setDealContactRecord(response.data.data.record.dealData.ContactData)
            setDealAccountRecord(response.data.data.record.dealData.AccountData)
            setDealRecordData(response.data.data.record.dealData)
            setDealAmount(response.data.data.record.dealData.deal_amount);
            setDealNote(response.data.data.record.dealData.note ? response.data.data.record.dealData.note : '')
            setPreferredCurrencyData(response.data.data.record.dealData.preferredCurrencyData);
            setDealWon({
              note: response.data.data.record.dealData.note,
              deal_amount: response.data.data.record.dealData.deal_amount,
              stage_id: response.data.data.record.dealData.stage_id,
              closing_date: new Date(response.data.data.record.dealData.closing_date),
              error_list: [],
            })
            if (response.data.data.record.dealData.closing_date) {
              setWonClosingDate(new Date(response.data.data.record.dealData.closing_date))
            }

            calendorFormate = response.data.data.record.dealData.preferredDateFormat.split(' ');
            if (calendorFormate) {
              if (calendorFormate[0] == 'YYYY-MMM-DD') {
                setCalendorDateFormate('yyyy/MM/dd');
              } else if (calendorFormate[0] == 'DD-MMM-YYYY') {
                setCalendorDateFormate('dd/MM/yyyy');
              } else if (calendorFormate[0] == 'YYYY-DD-MMM') {
                setCalendorDateFormate('yyyy/dd/MM');
              } else if (calendorFormate[0] == 'MMM-DD-YYYY') {
                setCalendorDateFormate('MM/dd/yyyy');
              }
            }

          }
          setShowWonStatus(true)
        }).then(json => {

        }).catch(error => {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401 || error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 300) {
              toast.error(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning('Forbidden ' + error.response.data.message);
              history.push('/deals');
            } else {
              toast.error('Oops Something went wrong.');
            }
          }
          history.push('/deals');
        });
      } else {
        let task = tasksList.find(task => task._id === id);
        const taskIndex = tasksList.indexOf(task);
        task = { ...task, status };
        let newTasks = update(tasksList, {
          [taskIndex]: { $set: task }
        });

        //==============Start:- Call API for update stage status for lead============//
        const data = {
          id: id,
          stage: sid
        }

        await axios.put('api/deal-stage/mode', data).then(response => {
          if (response.status === 200) {
            toast.success(response.data.message);
            isUpdate ? setIsUpdate(false) : setIsUpdate(true);
          }
        }).then(json => {

        }).catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning('Forbidden ' + error.response.data.message);
            } else if (error.response.status === 300) {
              toast.warning(error.response.data.message);
            }else {
              toast.error(error.response.data.message);
            }
          }
        });
        //==============End:- Call API for update stage status for lead============//

        setTaskStatus(newTasks);
        tasksList.push(newTasks)
      }
    },
    [isUpdate]
  );

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  }

   const handleAmountChange = (value) => {
    setDealAmount(value)
  }
  
  const handleView = async () => {
    //listView ? setListView(false) : setListView(true);
    //window.location.reload(false);
    props.handleView()
  }

  const dealWonSubmit = async (e) => {
    e.preventDefault();
    
    if (dealWon.deal_amount == '') {
      setFormWonAmountError(true);
      wonAmountRef.current.focus();
    }

    if (wonClosingDate == null) {
      setFormWonClosingDateError(true);
    }

    if (dealWon.deal_amount != '' && wonClosingDate != null && dealID != '') {
      const data = {
        id: dealID,
        first_name: dealContactRecord.first_name,
        last_name: dealContactRecord.last_name,
        organization_name: dealAccountRecord.organization_name,
        organization_email: dealAccountRecord.organization_email,
        deal_description: dealRecordData.deal_description,
        deal_probability: dealRecordData.deal_probability,
        deal_amount: dealAmount,
        stage_id: selectStageId,
        note:dealNote,
        contact_id:dealContactRecord.id,
        account_id:dealRecordData.contact_id,
        closing_date: moment(wonClosingDate).format("YYYY-MM-DD"),
      }
      
      setSubmitWonClose(true);
      await axios.post("/api/deal/update", data).then(response => {
        if (response.status === 200) {
          setShowWonStatus(false);
          setSubmitWonClose(false);
          isUpdate ? setIsUpdate(false) : setIsUpdate(true);
        }
      }).then(json => {

      }).catch(error => {
        if (error.response) {
          setSubmitWonClose(false);
          if (error.response.status === 400) {
            setDealWon({ ...dealWon, error_list: error.response.data.validation_errors });
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning('Forbidden ' + error.response.data.message);
          } else {
            toast.error('Oops Something went wrong.');
          }
        }
      });
    }
  }

  const handleDealNoteChange = (e) => {
    e.persist();
    setDealNote(e.target.value );
  }

  let stageLists = [];
  for (var i = 0, l = stageList.length; i < l; i++) {
    stageLists.push({ label: stageList[i].name, value: stageList[i].id });
  }

  let selectedStage = []
  for (var i = 0, l = stageList.length; i < l; i++) {
    if (selectStageId == stageList[i].id) {
      selectedStage.push({ label: stageList[i].name, value: stageList[i].id });
    }
  }

  const myObj = {
    style: "currency",
    currency: currencyCode
  }

  if (loading) {
    return <Preloader show={true} />;
  } else {
    return (
      <>
        <div className="searchbar mt30">
          <div className="col-md-4">
            <form onSubmit={manageSearch}>
              <input type="text" name="searchData" className="form-control search" value={searchString} placeholder="Search this list...." onChange={handleSearchInput} ></input><span className="listicon" onClick={manageSearch}></span>
            </form>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-6 display justify-content-end">
            <div className="icon-section">
              <button type="button" className="btn dropdownborder equalhover" onClick={() => handleView()}><span className="equal"></span></button>
            </div>
          </div>
        </div>
        
        <main style={{ overflowX: 'auto' }}>
          <header>  </header>
          <DndProvider backend={HTML5Backend}>
            <section style={classes.board}  >
              {channels.map(channel => (
                <KanbanColumn
                  key={channel.sname}
                  status={channel.sname}
                  sid={channel.sid}
                  changeTaskStatus={changeTaskStatus}
                >
                  <div className="kanbancoloum">
                    <div className="kanbantitle pt20">{channel.sname}</div>
                    <div className="pricehead">
                      <div className="priceheadleft">
                        <h5>{parseInt(channel.samount) ? preferredCurrencyData.currencyStringValue ? parseInt(channel.samount).toLocaleString(preferredCurrencyData.currencyStringValue, myObj) : currencySymbol + parseInt(channel.samount).toLocaleString(myObj) : 'N/A'}</h5>
                        {/* <h5>{parseInt(channel.samount)}</h5> */}
                      </div>
                      <div className="priceheadright">
                        <span className="badge drakblue mr5"><h6>{channel.scount}</h6></span>
                        <h6>Deal</h6>
                      </div>
                    </div>
                    <div>
                      {tasksList
                        .filter(item => item.status === channel.sname)
                        .map(item => (
                          <KanbanItem key={item._id} id={item._id}>
                            <div style={classes.item}>
                              <div className="divtask">
                                <div className="imgandtxt">
                                  <Stack direction="row" spacing={3} alignItems="center">
                                    <Avatar
                                      alt={item.first_name + ' ' + item.last_name}
                                      src={`${process.env.REACT_APP_API_URL}storage/${item.profile_pic}`}
                                      sx={{ width: 40, height: 40 }}
                                      onError={addDefaultSrc}
                                      className="listimg"
                                    >
                                      {item.sortName}
                                    </Avatar>
                                    <h3 className="short" onClick={() => history.push('/edit-deal/' + item._id)}>{item.first_name + ' ' + item.last_name}</h3>
                                  </Stack>
                                </div>
                                <div className="tasktitle">
                                  <h6 onClick={() => history.push('/edit-deal/' + item._id)}>{item.dealName ? item.dealName : item.title}</h6>
                                </div>
                                {item.selectedProduct ?
                                  <div className="taskproduct">
                                    <h6>Product Intrusted In:</h6>
                                    <span className="ellipsis">{item.selectedProduct ? item.selectedProduct : 'N/A'}</span>
                                  </div>
                                  :
                                  ''
                                }
                                <div className="taskname">
                                  <h6>{item.organization_name ? item.organization_name : 'N/A'}</h6>
                                </div>
                                <div className="taskdate">
                                  <h6>{moment.tz(item.created_at, defaultTimeZone).format(calendorFullDateFormate)}</h6>
                                </div>
                              </div>
                            </div>
                          </KanbanItem>
                        ))}
                    </div>
                  </div>
                </KanbanColumn>
              ))}
            </section>
          </DndProvider>
        </main>

        <Modal as={Modal.Dialog} centered show={showWonStatus} onHide={handleChangeWonStatusClose}>
          <Modal.Header>
            <Modal.Title className="h2">Verify Details</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleChangeWonStatusClose} /><span className="modelclose"></span>
          </Modal.Header>
          <Modal.Body>
            <Form encrypted="multipart/form-data" onSubmit={dealWonSubmit} >
              {/* <Row> */}
              <div className="col-md-12 col-sm-12">
                <Row className="align-items-center">
                  <Form.Group className="mb-125">
                    <Form.Label column sm="12">
                      <h6 className="fw600">Amount -  {preferredCurrencyData.currencyCode != '' ? ' ' + preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                        ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                        <span className="text-danger">*</span></h6>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Group id="deal_amount">
                        <CurrencyInput
                          id="deal_amount"
                          name="deal_amount"

                          intlConfig={{
                            locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                            currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                          }}
                          placeholder="Enter deal amount"
                          className={`form-control`}
                          onValueChange={handleAmountChange}
                          decimalsLimit={6}
                          value={dealAmount}
                          ref={wonAmountRef}
                          step={1}

                        />
                      </Form.Group>
                      <span className="error">{dealWon.error_list.deal_amount}</span>
                      {formWonAmountError ? <span className="error">This field is required.</span> : null}
                    </Col>
                  </Form.Group>
                  {/*--- third_input ---*/}
                  <Form.Group className="mb-125">
                    <Form.Label column sm="12">
                      <h6 className="fw600">Closing Date<span className="text-danger">*</span></h6>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Group id="closing_date">
                        <DatePicker
                          autocomplete="off"
                          //minDate={moment().toDate()}
                          selected={wonClosingDate ? wonClosingDate : ''}
                          onChange={(date) => setWonClosingDate(date)}
                          dateFormat={calendorDateFormate}
                          className="form-control"
                        />
                      </Form.Group>
                      <span className="error">{dealWon.error_list.closing_date}</span>
                      {formWonClosingDateError ? <span className="error">This field is required.</span> : null}
                    </Col>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label column sm="12">
                      <h6 className="fw600">Stage</h6>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Group id="stage_id">
                        <Select
                          defaultValue={selectedStage}
                          name="stage_id"
                          isDisabled={true}
                          options={stageLists}
                          className="basic-multi-select mr20"
                          classNamePrefix="select"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                            },
                          })}
                          isClearable={false}
                          isSearchable={false}
                        />
                      </Form.Group>
                      <span className="error">{dealWon.error_list.deal_probability}</span>
                    </Col>
                  </Form.Group>
                  {
                    dealStatus=='Closed Lost' ?       
                  <Form.Group>
                      <Form.Label column sm="12">
                            <h6 className="fw600">Reason for Lost</h6>
                      </Form.Label>
                      <Col sm="12">
                            <Form.Group id="note">
                                <Form.Control as="textarea" rows="2" name="note" onChange={handleDealNoteChange} value={dealNote} placeholder="Write your reason" />
                            </Form.Group>
                            <span className="error">{dealWon.error_list.note}</span>
                      </Col>
                  </Form.Group>
                  :
                  ''
                  } 
                </Row>
              </div>


              <div className="text-left mt30">
                {
                  submitWonClose ?

                    <Button variant="primary" className="d-flex align-items-center mb-1 text-center popupbtn btnblue">
                      <Spinner animation="border" size="sm" />
                      <span className="ms-2">Please Wait...</span>
                    </Button>

                    :

                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                      <div className="btn-group mr-2" role="group" aria-label="First group">
                        <Button type="submit" variant="success" className="popupbtn btnblue mr15">Submit</Button>
                      </div>
                      <div className="btn-group mr-2" role="group" aria-label="First group">
                        <Button type="button" variant="success" className="popupbtn greycolor" onClick={handleChangeWonStatusClose}>Cancel</Button>
                      </div>
                    </div>
                }
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }

};


export default Kanban;

const KanbanColumn = ({ status, changeTaskStatus, children, sid }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    drop(item) {
      changeTaskStatus(item.id, status, sid);
    }
  });
  drop(ref);
  return <div ref={ref}> {children}</div>;
};

const KanbanItem = ({ id, children }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    //item: { type: "card", id },
    type: 'card',
    item: () => ({id}),
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);
  return (
    <div ref={ref} style={{ opacity }}>
      {children}
    </div>
  );
};
