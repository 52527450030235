import React, { useEffect, useState } from "react";
import { Col, Form,Button,Spinner} from '@themesberg/react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
//import { Formik,ErrorMessage } from "formik";
import '../../assets/css/custom.css';
//import paymentlogo from '../../assets/img/color_main_logo.png';
//import righticon from '../../assets/img/righticon-three-coloum.png';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Preloader from "../../components/PreloaderCenter";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import { useHistory, } from 'react-router-dom';
//import { Button, Spinner } from 'react-bootstrap';
//import swal from 'sweetalert';
import axios from 'axios';
//import * as yup from "yup";
import moment from "moment-timezone";
//import visa from '../../assets/img/visa-icon.png';
//import mastercard from '../../assets/img/master-icon.png';
//import american from '../../assets/img/american-icon.png';
//import rupay from '../../assets/img/rupay-icon.png';
//import discover from '../../assets/img/discover-icon.png';
import Offcanvas from 'react-bootstrap/Offcanvas';

//=========Stripe Payment data============//
import "../../components/stripe/styles.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/stripe/CheckoutForm";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
//=========Stripe Payment data============//
var calendorFormate = '';
var currencyCode = '';
var currencySymbol = '';
var currentPackageName = ''

var hostname = ''
var onlyHost = window.location.hostname;
onlyHost = onlyHost.split('.')
if(onlyHost && onlyHost.length==3){
     onlyHost = onlyHost.slice(1) //remove first element of array
     hostname = onlyHost[0]+'.'+onlyHost[1]
}else if(onlyHost && onlyHost.length==2){
     hostname = onlyHost[0]+'.'+onlyHost[1]
}

function Checkout(props) {
     const history = useHistory();
     //const dispatch = useDispatch();
     //const stripe = useStripe();

     const [payAbleAmount, setPayAbleAmount] = useState(0);
     const [payFor, setPayFor] = useState('existing');
     const [hideUser, setHideUser] = useState(true);
     //const [activeUser, setActiveUser] = useState(1);
     //const [paymentType, setPaymentType] = useState('normal');
     //const [displayRadio, setRisplayRadio] = useState(false);
     //const [totalAmount, setTotalAmount] = useState();
     const [userCountDetail, setUserCountDetail] = useState([]);
     const [isAddUser, setIsAddUser] = useState(true);
     const [addUserCount, setAddUserCount] = useState(1);
     const [priceCalculationResponse, setPriceCalculationResponse] = useState([]);
     
     const [show, setShow] = useState(false);
     const handleClose = () => {setShow(false); setHideUser(false)};
     const handleShow = () => setShow(true);
     
     const loginUser = useSelector(selectUser);
     
     const user = loginUser?loginUser:[];
     
     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
     
     const [defaultValue, setdefaultValue] = useState({
          user_count: '',
          error_list: [],
      })
     

     const [loading, setLoading] = useState(true);
     const [dataRecord, setDataRecord] = useState('');

     //const [packageMessage, setPackageMessage] = useState('');
     const [displayAddBtn, setDisplayAddBtn] = useState(true);
     const [displayReduceBtn, setDisplayReduceBtn] = useState(false);
         

     const [userCount, setUserCount] = useState(1);
     const [subEndDate, setSubEndDate] = useState('');
     const [diplsyPlan, setDiplsyPlan] = useState('Free');
     const [diplsyCardData, setDiplsyCardData] = useState([]);
      
     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     //const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
     const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');
     const [realoadPage, setRealoadPage] = useState(true);
     const [realoadPayNowBtn, setRealoadPayNowBtn] = useState(false);
     const [stripe, setStripe] = useState(null);

      useEffect(() => {
          async function fetchData() {
            await getpaymentData();
          }
          fetchData();

          const loadStripeInstance = async () => {
               const stripeInstance = await stripePromise;
               setStripe(stripeInstance);
          };
     
          loadStripeInstance();

        }, [realoadPage]); 

     async function getpaymentData(){
          await axios.get('api/checkout').then(response => {
               if (response.data.code === 200) {
                    setLoading(false);
                    //console.log(response,'checkout-response')
                    setDataRecord(response.data.data.records)
                    setUserCount(response.data.data.userCountDetail.planUserCount ? response.data.data.userCountDetail.planUserCount:'');
                    setSubEndDate(response.data.data.subEndDate?response.data.data.subEndDate:'')
                    setUserCountDetail(response.data.data.userCountDetail)
                    setdefaultValue({ 
                         user_count: response.data.data.userCountDetail.planUserCount ? response.data.data.userCountDetail.planUserCount:1, 
                         error_list: [] 
                    });

                    setDiplsyCardData(response.data.data.paymentMethod)
                    //setPaymentType(response.data.data.records.payment_type);
                    //setRisplayRadio(response.data.data.records.payment_type=='recurring' ? true:false)
                    //setActiveUser(response.data.data.userCountDetail.addedUserCount);
                    
                    if(hostname ==='solvcrm.co'){
                         setPreferredCurrencyData({'currencyCode':'USD','currencySymbol':'$'});
                    }else{
                         setPreferredCurrencyData(response.data.data.preferredCurrency);
                    }     
                    //setPreferredDateFormatData(response.data.data.preferredDateFormat);

                    calendorFormate = response.data.data.preferredDateFormat.split(' ');
                    if (calendorFormate) {
                         if (calendorFormate[0] === 'YYYY-MMM-DD') {
                         setCalendorDateFormate('YYYY-MMM-DD');
                         } else if (calendorFormate[0] === 'DD-MMM-YYYY') {
                         setCalendorDateFormate('DD-MMM-YYYY');
                         } else if (calendorFormate[0] === 'YYYY-DD-MMM') {
                         setCalendorDateFormate('YYYY-DD-MMM');
                         } else if (calendorFormate[0] === 'MMM-DD-YYYY') {
                         setCalendorDateFormate('MMM-DD-YYYY');
                         }
                         setCalendorDateFormate(calendorFormate[0]);
                    }

                    var cheCnt = response.data.data.userCountDetail.planUserCount?response.data.data.userCountDetail.planUserCount:'';

                    if(response.data.data.records.package){
                         if(response.data.data.records.package.package_type==='free'){
                              setDiplsyPlan('Free')
                              //setTotalAmount('Free')
                              //setPackageMessage('Currently you are using '+response.data.data.records.package.duration+' '+response.data.data.records.package.duration_type+'s free trial package')
                         }else{
                              //var calAmount = response.data.data.records.package.price*cheCnt
                              //setTotalAmount(calAmount)
                              setDiplsyPlan(response.data.data.records.package.price)
                              //setPackageMessage(cheCnt==1 ? 'Currently you have '+cheCnt+' User': 'Currently you have '+cheCnt+' Users'+ ' package')
                         }
                    }else{
                         //setPackageMessage('Currently you have no any package')     
                    }
                    
                    
                    if (response.data.data.preferredCurrency.currencyCode) {
                         if(hostname ==='solvcrm.co'){
                              currencyCode = 'USD';
                              currencySymbol = '$';
                         }else{
                              currencyCode = response.data.data.preferredCurrency.currencyCode ? response.data.data.preferredCurrency.currencyCode : '';
                              currencySymbol = response.data.data.preferredCurrency.currencySymbol ? response.data.data.preferredCurrency.currencySymbol : '';
                         }
                    }
                    
                    setPayAbleAmount('')
                    setPayFor('existing');
                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setLoading(false);
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/dashboard');
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                         history.push('/dashboard');
                    } else {
                         toast.error('Opps.. Something went wrong.');
                    }
               }
          });
     }   
     
     const handlePayablePriceBlureEvent = async () => {
          setLoading(true)
          var UpdateCount = isAddUser ? parseInt(userCount+addUserCount):parseInt(userCount-addUserCount)

          //console.log(isAddUser,userCount,addUserCount)

          var data = {'user_count':UpdateCount}
          await axios.post("/api/checkout-price", data).then(response => {
               //console.log(response,'checkout-price')
               if (response.status === 200) {
                    setPriceCalculationResponse(response.data.data)
                    setPayAbleAmount(response.data.data.payableAmount)
                    //setActiveUser(response.data.data.tenantUserCount);
                    setHideUser(false)
                    setdefaultValue({ 
                         user_count: response.data.data.user_count, 
                         error_list: [] 
                    });  
               }
               setLoading(false)
          }).then(json => {
          
          }).catch(error => {
               if (error.response) {
                    if(error.response && error.response.data && error.response.data.data){
                         setdefaultValue({ 
                              user_count: error.response.data.data, 
                              error_list: [] 
                         });
                    }
                    setPayAbleAmount(0)
                    setLoading(false)
                    if (error.response.status === 400) {
                         setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
                    } else if(error.response.status === 403){
                         toast.warning('Forbidden '+error.response.data.message);
                         history.push('/checkout');    
                    } else {
                         toast.warning(error.response.data.message);
                    }
               }
          
          });     
          
     }

     const handlePaymentRes = (res) =>{
          setLoading(false);
          setRealoadPage(false)
          setRealoadPayNowBtn(false)
          setHideUser(true)
          setAddUserCount(1)
          setShow(false);
          setRealoadPage(false)
          if(res.response){
               toast.success(res.message);  
          }else{
               toast.error(res.message); 
          }
     }

     const extraData = {
                    'payFor':payFor,
                    'userCount':defaultValue.user_count, 
                    'packageID':7, 
                    'userAction':isAddUser?'add':'remove', 
                    'paymentMode':'recurring'  
               }
     
     if(dataRecord.package && dataRecord.package.name){
          currentPackageName = dataRecord.package.name;
     }     
     
     //var colorMessage = '#0A8EA8'
     var MessageText = subEndDate ? 'Ends on '+moment.tz(subEndDate, defaultTimeZone ? defaultTimeZone : '').format(calendorDateFormate) : 'N/A'
     if(user.subRemainingDays=='-1'){
          //colorMessage = '#E0114F'
          MessageText = subEndDate ? 'Expired '+moment.tz(subEndDate, defaultTimeZone ? defaultTimeZone : '').format(calendorDateFormate) : 'N/A'
     }

     let Fullname = dataRecord ? dataRecord.added_by.name ? dataRecord.added_by.name : '' : '';
     let FFName = ''
     let expName = ''

     if (Fullname) {
          expName = Fullname.split(' ');

          if (expName.length == 2) {
               FFName = expName[0].charAt(0) + expName[1].charAt(0);
          } else {
               FFName = expName[0].charAt(0);
          }
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }

     const addUserFunction = async (type) =>{
          if(type==='add'){
               setAddUserCount(addUserCount+1)   
          }else if(type==='reduce' && addUserCount!==1){
               setAddUserCount(addUserCount-1)
          }     
     }

     const showHideAddUserBtn = () =>{
          setAddUserCount(1) 
          setHideUser(true)   
          if(isAddUser){
               setIsAddUser(false)
               setDisplayAddBtn(false)
               setDisplayReduceBtn(true)
          }else{
               setIsAddUser(true)
               setDisplayAddBtn(true)
               setDisplayReduceBtn(false)
          }
          
     }

     const handleReducePlanRequest = async() =>{
          const data = ({
               amount: payAbleAmount,
               extraData:extraData,
           })
           setRealoadPayNowBtn(true)
           //console.log('handleReducePlanRequest',data)
          await axios.post("/api/order-pay", data).then(async(response) => {
               //console.log('order-pay-response',response)
               if(response.status === 200) {
                    if(response.data.data.paymentIntentResponse=='success'){
                         setLoading(false);
                         toast.success('Your payment has been confirm');
                         setAddUserCount(1)
                         setShow(false);
                         setRealoadPayNowBtn(false)
                         setRealoadPage(false)
                         setHideUser(true)
                         setAddUserCount(1)
                         setIsAddUser(true)
                    }else{
                         storePaymentDetail(response)
                    }
               }
             }).then(json => {
             
             }).catch(error => {
               if (error.response) {
                    toast.error(error.response.data.message);
                    setLoading(false);
                    setRealoadPage(false)
                    setRealoadPayNowBtn(false)
                    setHideUser(true)
                    setAddUserCount(1)
                    setShow(false);
               }
             });
     }


     const storePaymentDetail = async (responseData) => {
          if(responseData.data.data){
            //const CardElement = '4242424242424242'//elements.getElement(CardNumberElement);
            if(responseData.data.data.paymentIntentResponse['isSubscription']){//recurring payment
              stripe.confirmCardPayment(responseData.data.data.paymentIntentResponse['clientSecret']).then(async (result) => {
                  if(result.error) {
                    setLoading(false);
                    setRealoadPage(false)
                    setRealoadPayNowBtn(false)
                    setHideUser(true)
                    setAddUserCount(1)
                    toast.error(result.error.message);
                    setShow(false);
                  } else {
                      // Successful subscription payment
                      // Post the transaction info to the server-side script and redirect to the payment status page
                      const requestData = {
                        'paymentIntent':result.paymentIntent,
                        'subscriptionId':responseData.data.data.paymentIntentResponse['subscriptionId'],
                        'clientSecret':responseData.data.data.paymentIntentResponse['clientSecret'],
                        'customerId':responseData.data.data.paymentIntentResponse['customerId'],
                        'orderId':responseData.data.data.paymentIntentResponse['orderId'],
                        'isUpdate':responseData.data.data.paymentIntentResponse['isUpdate'],
                        'requestUserCount':responseData.data.data.paymentIntentResponse['requestUserCount']?responseData.data.data.paymentIntentResponse['requestUserCount']:'',
                        'amount':responseData.data.data.paymentIntentResponse['amount'],
                        'subscriptionType':responseData.data.data.paymentIntentResponse['subscriptionType'],
                      }
      
                      await axios.post("/api/recurring-payment-confirm", requestData).then(async(response) => {
                        if (response.status === 200) {
                          setLoading(false);
                          setRealoadPayNowBtn(false)
                          setRealoadPage(false)
                          setHideUser(true)
                          setAddUserCount(1)
                          toast.success('Your payment has been confirm');
                          setShow(false);
                        }
                      }).then(json => {
                      
                      }).catch(error => {
                         if (error.response) {
                              setLoading(false);
                              setRealoadPayNowBtn(false)
                              setRealoadPage(false)
                              setHideUser(true)
                              setAddUserCount(1)
                              toast.error(error.response.data.message);
                              setShow(false);
                          }
                      });
                  }
              });
            }else{//normal payment
              stripe.confirmCardPayment(responseData.data.data.paymentIntentResponse['clientSecret']).then(async (result) => {
                  if(result.error) {
                    setLoading(false);
                    setRealoadPage(false)
                    setRealoadPayNowBtn(false)
                    setHideUser(true)
                    setAddUserCount(1)
                    toast.error(result.error.message);
                    setShow(false);
                  } else {
                      // Successful subscription payment
                      // Post the transaction info to the server-side script and redirect to the payment status page
                    await axios.post("/api/payment-confirm", result.paymentIntent).then(async(response) => {
                         if (response.status === 200) {
                              setLoading(false);
                              setRealoadPayNowBtn(false)
                              setRealoadPage(false)
                              setHideUser(true)
                              setAddUserCount(1)
                              toast.success('Your payment has been confirm');
                              setShow(false);
                         }
                      }).then(json => {
                      
                      }).catch(error => {
                         if (error.response) {
                              setLoading(false);
                              setRealoadPayNowBtn(false)
                              setRealoadPage(false)
                              setHideUser(true)
                              setAddUserCount(1)
                              toast.error(error.response.data.message);
                              setShow(false);
                          }
                      });
                  }
              });
              
            }
          }else{
               setLoading(false);
               setRealoadPayNowBtn(false)
               setRealoadPage(false)
               setHideUser(true)
               setAddUserCount(1)
               toast.success('Your payment fail!');
               setShow(false);
          }
          
     }




     if (loading) {
          return <Preloader show={true} />;
     } else {
          
          return (
               // <Row>
               <>
               <div className="display flex-wrap">

                    {/*--- profile_information_section ---*/}
                    <Col md={12} className="client profile checkout-profile mt30">
                         {/*--- client_name_and_convert_lead_section ---*/}
                         <div className="client_details display justify-content-between">
                              {/*--- client_image_and_deatils_section ---*/}
                              <div className="col-md-6 display">
                              <div className="client_image deal_image display">
                              <label className="custom-file-upload" style={{cursor:'default'}}>
                              <Stack direction="row" spacing={3}>
                              <Avatar
                                   alt={Fullname}
                                   src={`${process.env.REACT_APP_API_URL}`}
                                   sx={{ width: 50, height: 50 }}
                                   onError={addDefaultSrc}
                                   className="clientimg"
                              >
                                   {FFName}
                              </Avatar>
                              </Stack>
                              </label>
                              <div className="client_name ml20">
                                        <div className="display flex-wrap">
                                        <h5>{Fullname}</h5>
                                   </div>
                                   <div className="display flex-wrap">
                                        <h6>{dataRecord.added_by ? dataRecord.added_by.email ? dataRecord.added_by.email : 'N/A' : 'N/A'}</h6>
                                   </div>
                              </div>
                              </div>
                              </div>
                              {/* <div className="col-md-4 display message justify-content-end">
                              <h6>{packageMessage}</h6>
                              </div> */}
                              <div className="col-md-6 display justify-content-end">
                              <Button type="button" onClick={()=>history.push('/users')} variant="success" className="popupbtn greycolor d-flex align-items-center justify-content-center">Modify Users</Button>
                              </div>
                         </div>
                    </Col>

                    {/*--- current-active-plan-section ---*/}
                    <Col md={12} className="mt30">
                        <div className="d-flex align-items-start justify-content-between w-100 customizeplan threecoloum">
                              <div className="col-md-6">
                              <h2>Current Active Plan</h2>
                              </div>
                              <div className="col-md-4 text-center">
                                   <h2>{ diplsyPlan !== 'Free' ? userCount > 1 ? userCount+ ' Users':userCount+' User': 'Free'} 
                                        {
                                             diplsyPlan === 'Free' ?
                                                  <p>
                                                       { userCountDetail &&  userCountDetail.addedUserCount > 1 ?
                                                            userCountDetail.addedUserCount + ' Users Added'
                                                       :
                                                            userCountDetail.addedUserCount +' User Added'
                                                       }
                                                  </p>
                                             :
                                             ''
               
                                        }
                                        
                                   </h2>
                              </div>
                              <div className="col-md-2 text-end">
                              <h2>{MessageText}</h2>
                              </div>
                         </div>
                    </Col>

                     {/*--- current-active-plan-two-cta-section ---*/}
                     {
                         diplsyPlan !== 'Free' ? 
                              <Col md={12} className="mt30">
                                   <div className="d-flex align-items-start  w-100 customizeplanborder">
                                        <div className="col-md-2">
                                             <button type="button" className={displayAddBtn ? `btn btn-primary createbtn btnbluecolor reducebtn`:`btn btn-primary createbtn reducebtn`} onClick={()=>showHideAddUserBtn()}>Add Users</button>
                                        </div>
                                        <div className="col-md-2">
                                             <button type="button" className={displayReduceBtn ? `btn btn-primary createbtn btnbluecolor reducebtn`:`btn btn-primary createbtn reducebtn`} onClick={()=>showHideAddUserBtn()}>Reduce Users</button>
                                        </div>
                                        <div className="col-md-8">
                                        </div>
                                   </div>
                              </Col>
                         :
                         ''
                     }
                     
                     
                    {/*--- add-user-section ---*/}
                    <Col md={12} className="mt30">
                         <div style={{display:isAddUser?'block':'none'}}>
                                 <div className="display w-100 align-items-start mt20">
                                        <div className="col-md-8">
                                             <div className="customizeplan">
                                                  <div classsname="d-flex align-items-center">
                                                  <div className="col-md-12 text-left">
                                                       <div className="display">
                                                            <div className="col-md-12">
                                                            <h5>Customize Your Plan</h5>
                                                            </div>
                                                       </div>
                                                       <div className="display mt20 customizefrom">
                                                            <div className="col-md-9">
                                                            <form>
                                                            <div className="display">
                                                                 <div className="col-md-5">
                                                                 <label>Add Users to the Current Plan</label>
                                                                 </div>
                                                                 <div className="col-md-4 display">
                                                                 <button type="button" className="plusminusbtn" onClick={()=>addUserFunction('reduce')}>&#8722;</button>
                                                                      <input type="text" placeholder="1" className="form-control" value={addUserCount}/>
                                                                 <button type="button" className="plusminusbtn" onClick={()=>addUserFunction('add')}>&#43;</button>
                                                                 </div>
                                                                 <div className="col-md-3"></div>
                                                            </div>
                                                            </form>
                                                                 
                                                            </div>
                                                            <div className="col-md-3 display justify-content-end">
                                                            <button disabled={realoadPayNowBtn?true:false} type="button" className="btn btn-primary createbtn btnbluecolor userbtn" onClick={()=>handlePayablePriceBlureEvent()}>Calculate</button>
                                                            </div>
                                                       </div>
                                                       <div className="display mt20">
                                                            <div className="col-md-12">
                                                            <p>When you add users to your current active plan, you will be billed on a pro rated basis on the number of users you add for the current billing cycle. After the end of the current billing cycle, you will be billed based on the total no of users (existing + added) in your plan from the next billing cycle.</p>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  </div>
                                             </div>
                                        </div>

                                        <div className="col-md-4 payment-information-section">
                                             <div className="paymentinformation">
                                        <h2>Payment Information</h2>
                                        <div className="payment-method display mt20">
                                        <div className="col-md-9 justify-content-between">
                                             <h3>No of Users added</h3>
                                        </div>
                                        <div className="col-md-3 text-end">
                                             {/* <h3><b>{hideUser ? 'N/A':addUserCount+' + ('+userCountDetail.addedUserCount+')'}</b></h3> */}
                                             <h3><b>{hideUser ? 'N/A':addUserCount}</b></h3>
                                        </div>
                                        </div>
                                        <div className="payment-method display mt15 mb15">
                                        <div className="col-md-9 justify-content-between">
                                             <h3>Pro rated Cost per User
                                                  {hideUser ? 
                                                       ''
                                                       :
                                                       <span className="infoclass">
                                                                 <div className="infohover">
                                                                      <div className="infohovermethod display">
                                                                           <div className="col-md-9 justify-content-between">
                                                                                <h3>No of Days left in the Plan</h3>
                                                                           </div>
                                                                           <div className="col-md-3 text-end">
                                                                                <h3>
                                                                                <b>{priceCalculationResponse && priceCalculationResponse.numberOfDaysLeft ? priceCalculationResponse.numberOfDaysLeft:'N/A'}</b></h3>
                                                                           </div>
                                                                      </div>  
                                                                      <div className="infohovermethod display mt20">
                                                                           <div className="col-md-9 justify-content-between">
                                                                                <h3>Pro rated Cost per user/day</h3>
                                                                           </div>
                                                                           <div className="col-md-3 text-end">
                                                                                <h3>
                                                                                     <small>  
                                                                                          {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                                                          ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''}) 
                                                                                     </small>
                                                                                     <b>{priceCalculationResponse && priceCalculationResponse.costPerUserPerDay ? ' '+(Math.round(priceCalculationResponse.costPerUserPerDay*100)/100).toFixed(2):'N/A'}</b>
                                                                                </h3>
                                                                           </div>
                                                                      </div>  
                                                                      <div className="infohovermethod display mt15 borderwhite">
                                                                           <div className="col-md-9 justify-content-between">
                                                                                <h3>
                                                                                     {
                                                                                          priceCalculationResponse && priceCalculationResponse.numberOfDaysLeft ?
                                                                                          
                                                                                          priceCalculationResponse.numberOfDaysLeft>1 ?
                                                                                               'Total pro rated cost per user for '+priceCalculationResponse.numberOfDaysLeft+' days'
                                                                                               :
                                                                                               'Total pro rated cost per user for '+priceCalculationResponse.numberOfDaysLeft+' day'
                                                                                          :
                                                                                          'N/A'
                                                                                     }
                                                                                     
                                                                                </h3>
                                                                           </div>
                                                                           <div className="col-md-3 text-end">
                                                                                <h3><small>  
                                                                                {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                                                ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                                                                                </small>
                                                                                <b>{priceCalculationResponse && priceCalculationResponse.costPerUserPerDay && priceCalculationResponse.numberOfDaysLeft ? ' '+((Math.round(priceCalculationResponse.costPerUserPerDay*100)/100)*(priceCalculationResponse.numberOfDaysLeft)).toFixed(2):' N/A'}</b></h3>
                                                                           </div>
                                                                           </div>  
                                                                 </div>     
                                                       </span>
                                                  }
                                                                                               
                                             </h3>
                                        </div>
                                        <div className="col-md-3 text-end">
                                             <h3> {
                                                  hideUser ? 'N/A'
                                                  :
                                                       <>
                                                       <small>  
                                                       {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                       ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                                                       </small>
                                                       <b>
                                                            {priceCalculationResponse && priceCalculationResponse.payableAmountForOneUser ? ' '+(Math.round(priceCalculationResponse.payableAmountForOneUser * 100) / 100).toFixed(2):' N/A'}
                                                       </b>
                                                       </>
                                             }
                                                  
                                             </h3>
                                        </div>
                                        </div>
                                        <div className="payment-method display mt15 bordertop">
                                        <div className="col-md-6 justify-content-between">
                                             <h3>Total</h3>
                                        </div>
                                        <div className="col-md-6 text-end">
                                             <h3>
                                                  { hideUser ? 'N/A' 
                                                       :
                                                       <>
                                                       <small> 
                                                            {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                            ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''}) 
                                                       </small>
                                                       <b>
                                                            {payAbleAmount?' '+(Math.round(payAbleAmount * 100) / 100).toFixed(2):' N/A'}
                                                       </b>
                                                       </>
                                                  }
                                                  
                                             </h3>
                                        </div>
                                        </div>
                                             </div>
                                        </div>
                                   </div>
                         </div>

                         <div style={{display:isAddUser?'none':'block'}}>
                         <div className="display w-100 align-items-start mt20">
                                   <div className="col-md-8">
                                        <div className="customizeplan">
                                             <div classsname="d-flex align-items-center">
                                             <div className="col-md-12 text-left">
                                                  <div className="display">
                                                       <div className="col-md-12">
                                                       <h5>Customize Your Plan</h5>
                                                       </div>
                                                  </div>
                                                  <div className="display mt20 customizefrom">
                                                       <div className="col-md-9">
                                                       <form>
                                                       <div className="display">
                                                            <div className="col-md-5">
                                                            <label>Reduce Users to the Current Plan</label>
                                                            </div>
                                                            <div className="col-md-4 display">
                                                            <button type="button" className="plusminusbtn" onClick={()=>addUserFunction('reduce')}>&#8722;</button>
                                                            <input type="text" placeholder="10" className="form-control" value={addUserCount}/>
                                                            <button type="button" className="plusminusbtn" onClick={()=>addUserFunction('add')}>&#43;</button>
                                                            </div>
                                                            <div className="col-md-3"></div>
                                                       </div>
                                                       </form>
                                                            
                                                       </div>
                                                       <div className="col-md-3 display justify-content-end">
                                                       <button type="button" className="btn btn-primary createbtn btnbluecolor userbtn" onClick={()=>handlePayablePriceBlureEvent()}>Calculate</button>
                                                       </div>
                                                  </div>
                                                  <div className="display mt20">
                                                       <div className="col-md-12">
                                                       <p>When you Reduce users From your current active plan, the Users will be removed from the plan You will be Billed based on the total on of users (existing - Reduced) in your plan from the next billing cycle.</p>
                                                       </div>
                                                  </div>
                                             </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="col-md-4 payment-information-section">
                                   <div className="paymentinformation">
                                        <h2>Payment Information</h2>
                                        <div className="payment-method display mt20">
                                        <div className="col-md-9 justify-content-between">
                                             <h3>No of Users Left in Plan</h3>
                                        </div>
                                        <div className="col-md-3 text-end">
                                             <h3><b>{hideUser ? 'N/A':priceCalculationResponse && priceCalculationResponse.user_count ? priceCalculationResponse.user_count : 'N/A'}</b></h3>
                                        </div>
                                        </div>
                                        <div className="payment-method display mt15 mb15">
                                        <div className="col-md-9 justify-content-between">
                                             <h3>Cost Per user/month
                                                  {/* {hideUser ? 
                                                       'N/A'
                                                       :
                                                       <span className="infoclass">
                                                            <div className="infohover">
                                                                 <div className="infohovermethod display">
                                                                      <div className="col-md-9 justify-content-between">
                                                                           <h3>No of Users to Reduce</h3>
                                                                      </div>
                                                                      <div className="col-md-3 text-end">
                                                                           <h3><b>{hideUser ? 'N/A':addUserCount}</b></h3>
                                                                      </div>
                                                                 </div>  
                                                                 <div className="infohovermethod display mt20">
                                                                      <div className="col-md-9 justify-content-between">
                                                                           <h3>Cost Per User/month For Current Billing Cycle</h3>
                                                                      </div>
                                                                      <div className="col-md-3 text-end">
                                                                           <h3>
                                                                                <small>  
                                                                                     {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                                                     ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''}) 
                                                                                </small>
                                                                                <b>{priceCalculationResponse && priceCalculationResponse.costPerUserPerDay ? ' '+(Math.round(priceCalculationResponse.costPerUserPerDay*100)/100).toFixed(2):'N/A'}</b>
                                                                           </h3>
                                                                      </div>
                                                                 </div>  
                                                                 <div className="infohovermethod display mt15 borderwhite">
                                                                      <div className="col-md-9 justify-content-between">
                                                                           <h3>Revised Payment For Next  billing Cycle</h3>
                                                                      </div>
                                                                      <div className="col-md-3 text-end">
                                                                           <h3><b>$ 5</b></h3>
                                                                      </div>
                                                                      </div>  
                                                            </div>     
                                                       </span>
                                                  
                                                  } */}
                                                                                               
                                             </h3>
                                        </div>
                                        <div className="col-md-3 text-end">
                                        <h3> {
                                                  hideUser ? 'N/A'
                                                  :
                                                  <>
                                                       <small>  
                                                       {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                       ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''}) 
                                                       </small>
                                                       <b>
                                                       {priceCalculationResponse && priceCalculationResponse.costPerUser ? ' '+(Math.round(priceCalculationResponse.costPerUser * 100) / 100).toFixed(2):' N/A'}
                                                  </b>
                                                  </>
                                             }
                                                  
                                             </h3>
                                        </div>
                                        </div>
                                        <div className="payment-method display mt15 bordertop">
                                        <div className="col-md-9 justify-content-between">
                                             <h3>Revised Payment For Next billing Cycle</h3>
                                        </div>
                                        <div className="col-md-3 text-end">
                                        <h3>
                                                  { hideUser ? 'N/A' 
                                                       :
                                                       <>
                                                       <small>
                                                            {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                            ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''}) 
                                                            </small>
                                                       <b>
                                                            {payAbleAmount?' '+(Math.round(payAbleAmount * 100) / 100).toFixed(2):' N/A'}
                                                       </b>
                                                       </>
                                                  }
                                                  
                                             </h3>
                                        </div>
                                        </div>
                                   </div>
                                   <div className="reducepaybtn mt-2 d-flex justify-content-end" title={hideUser?'Click on calculate button to enable submit button':'Click on submit button to submit your reduce plan request.'}>
                                   {
                                        hideUser?
                                             <button type="button" disabled={hideUser?true:false} className="btn btn-primary createbtn btnbluecolor userbtn d-block w-100">Submit </button>
                                             :
                                             realoadPayNowBtn ?
                                                  <Button variant="primary" disabled className="btn btn-primary createbtn btnbluecolor userbtn d-block w-100">
                                                       <Spinner animation="border" size="sm" />
                                                       <span className="ms-2">Please wait...</span>
                                                  </Button>
                                                  :
                                                  <button type="button" disabled={hideUser?true:false} onClick={()=>handleReducePlanRequest()} className="btn btn-primary createbtn btnbluecolor userbtn d-block w-100">Submit</button>
                                   }
                                   </div>
                                   
                                   </div>
                                   
                         </div>
                         </div>
                    </Col>

                    {
                         isAddUser ?

                         <Col md={8}>
                              <div className="billpayment customizeplan mt30">
                              <div className="display flex-wrap">
                                
                              <div className="col-md-12">
                                   <h5>{diplsyPlan !=='Free' ? 'Last Billed Payment Method':'Payment Method'}</h5>
                              </div>

                              {diplsyPlan !=='Free'?  
                              <>
                              <div className="col-md-12 customizeplan mt20 paymentimg">
                                   <div className="display">
                                        <div className="col-md-12 display">
                                        <div className="col-md-5 display align-items-center">
                                        {/* <img src={visa} alt="Logo"/> */}
                                        <h2>[{diplsyCardData && diplsyCardData.brand?diplsyCardData.brand:'N/A'}]</h2>
                                        <h2 style={{paddingLeft:'10px'}}>
                                             {diplsyCardData && diplsyCardData.last4 ? 
                                                  '**** **** **** '+diplsyCardData.last4
                                                  :
                                                  '**** **** **** ****'
                                             }
                                             
                                        </h2>
                                        </div>
                                        <div className="col-md-4">
                                        <h2>Expires <span>
                                             {diplsyCardData && diplsyCardData.exp_month && diplsyCardData.exp_year
                                                  ?
                                                  diplsyCardData.exp_month+'/'+diplsyCardData.exp_year
                                                  :
                                                  'N/A'
                                             }
                                             </span>
                                        </h2>
                                        </div>
                                        <div className="col-md-3">
                                        {/* <form>
                                             <div className="display">
                                                  <div className="col-md-5 text-end">
                                                       <label for="cvv">CVV</label>
                                                  </div>
                                                  <div className="col-md-4 display">
                                                       <input type="text" id="cvv" className="form-control"/>
                                                  </div>
                                             </div>
                                        </form> */}
                                        </div>
                                        </div>    
                                   </div>
                              </div>
                              </> 
                              :
                              ''
                                   }         
                              <div className="col-md-12 customizeplan10 mt20">
                              <button type="button" className="btn btn-primary text-black bg-white d-block w-100 cardbtn" disabled={hideUser?true:false} onClick={()=>{handleShow(true); setHideUser(true)}} data-bs-toggle="offcanvas" data-bs-target="#demo">{diplsyPlan !=='Free' ? 'Update Card':'Add New Card'}</button>
                              </div>
                              {diplsyPlan !=='Free'? 
                              <>
                              <div className="col-md-12 mt15">
                              <p>This payment method will be used for current and futuure billings</p>
                              </div>
                              <div className="col-md-12 mt20">
                                   {hideUser?
                                        <button type="button" disabled={hideUser?true:false} className="btn btn-primary createbtn btnbluecolor userbtn d-block w-100" alt="Click on Calculate button to enable paynow button" title="Click on Calculate button to enable paynow button">Pay Now </button>
                                        :
                                        realoadPayNowBtn ?
                                             <Button variant="primary" disabled className="btn btn-primary createbtn btnbluecolor userbtn d-block w-100">
                                                  <Spinner animation="border" size="sm" />
                                                  <span className="ms-2">Please wait...</span>
                                             </Button>
                                             :
                                             <button type="button" disabled={hideUser?true:false} onClick={()=>handleReducePlanRequest()} className="btn btn-primary createbtn btnbluecolor userbtn d-block w-100">Pay Now {preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''}{(Math.round(payAbleAmount * 100) / 100).toFixed(2)}</button>
                                   }
                                   
                              </div>
                              </>
                              :
                              ''
                              }
                              </div>
                         </div>
                         </Col>
                    :
                    ''
                    }                                   
                    
               </div>


               <Offcanvas show={show} onHide={handleClose}  placement='end' className="checkoutcanvas">
                    <Offcanvas.Header closeButton className="p3525">
                         <Offcanvas.Title>
                    </Offcanvas.Title>
                    </Offcanvas.Header>
                    
                    <Offcanvas.Body className="plr380 text-left">
                    <div className="row s-box">
                         <React.Fragment>
                              <div className="col-md-12 order-md-1">
                                   <Elements stripe={stripePromise}>
                                        <CheckoutForm amount={(Math.round(payAbleAmount * 100) / 100).toFixed(2)} handlePaymentRes={handlePaymentRes} extraData={extraData} />
                                   </Elements>
                              </div>
                         </React.Fragment>
                    </div> 
                    </Offcanvas.Body>
                        
               </Offcanvas>
      </>
          );
     }

};

export default Checkout;
