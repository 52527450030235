import React, { useEffect, useState, useRef, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';

import "react-datepicker/dist/react-datepicker.css";
import { faImage, faFileAudio, faPaperclip, faCheck, faFileAlt, faTimes, faFilePdf, faFileCsv, faFileExcel, faFileImage, faFileWord, faFileDownload, faUpload, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Card, Form, Image, FormCheck, Spinner, InputGroup, ButtonGroup, Modal } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import QuoteCloneItems from "../../components/QuoteCloneItems";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Checkbox from "react-custom-checkbox";
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import { Link, useHistory, } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';

let selectProduct = [];

function CloneLead(props) {
     const [quoteID, setQuoteID] = useState(props.match.params.id);
     const history = useHistory();
     const loginUser = useSelector(selectUser);

     const user = loginUser?loginUser:[];

     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

     const [salutation, setSalutation] = useState([]);
     const [leadSourceArray, setLeadSourceArray] = useState([]);
     const [productArray, setProductArray] = useState([]);
     const [countryArray, setCountryArray] = useState([]);
     const [selectProductArray, setSelectProductArray] = useState([]);

     //=========START:CODE FOR EDIT LEAD DATA==========//
     const [loading, setLoading] = useState(true);
     const [dataRecord, setDataRecord] = useState('');
     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     const [defaultValue, setdefaultValue] = useState({
          salutation: '',
          first_name: '',
          last_name: '',
          title: '',
          primary_email: '',
          phone1: '',
          lead_source: '',
          product_id: '',

          organization_name: '',
          organization_email: '',
          organization_phone1: '',
          organization_website: '',
          department: '',
          description: '',
          facebook_url: '',
          linkedin_url: '',
          twitter_url: '',

          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: '',
          country_id: '',
          error_list: [],
     })

     const validationSchema = yup.object().shape({
          first_name: yup.string().required('Enter first name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          last_name: yup.string().required('Enter last name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          organization_name: yup.string().required('Enter company name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),

          title: yup.string().nullable().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          primary_email: yup.string().nullable().email('Please enter valid email address').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          phone1: yup.string().nullable().max(25, 'Must be at most 25 characters'),
          organization_email: yup.string().nullable().email('Please enter valid email address').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          organization_phone1: yup.string().nullable().max(25, 'Must be at most 25 characters'),
          department: yup.string().nullable().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          description: yup.string().nullable().min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters'),
          organization_website: yup.string().nullable().url('Enter valid website url').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 255 characters'),
          facebook_url: yup.string().nullable().url('Enter valid facebook url').min(2, 'Must be at least 2 characters').max(255, 'Must be at most 255 characters'),
          linkedin_url: yup.string().nullable().url('Enter valid linkedin url').min(2, 'Must be at least 2 characters').max(255, 'Must be at most 255 characters'),
          instagram_url: yup.string().nullable().url('Enter valid instagram url').min(2, 'Must be at least 2 characters').max(255, 'Must be at most 255 characters'),
          twitter_url: yup.string().nullable().url('Enter valid twitter url').min(2, 'Must be at least 2 characters').max(255, 'Must be at most 255 characters'),

          address1: yup.string().nullable().min(2, 'Must be at least 2 characters').max(100, 'Must be at most 100 characters'),
          address2: yup.string().nullable().min(2, 'Must be at least 2 characters').max(100, 'Must be at most 100 characters'),
          city: yup.string().nullable().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          state: yup.string().nullable().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
          zipcode: yup.string().nullable().min(2, 'Must be at least 2 characters').max(10, 'Must be at most 10 characters'),
     })

     //========Const data for Activity (Lead and Deal)
     var calendorFormate = '';

     useEffect(() => {
          async function fetchData() {
               await getLeadData();
          }
          fetchData();
     }, []);


     function getLeadData() {
          axios.get('api/lead/edit/' + quoteID).then(response => {
               if (response.data.code === 200) {
                    //console.log(response,'response-response')
                    setDataRecord(response.data.data.record)
                    setSalutation(response.data.data.salutationArray);
                    setLeadSourceArray(response.data.data.LeadSource);
                    setProductArray(response.data.data.products);
                    setCountryArray(response.data.data.countries);
                    setSelectProductArray(response.data.data.selected_product)

                    setdefaultValue({
                         salutation: response.data.data.record.salutation,
                         first_name: response.data.data.record.first_name,
                         last_name: response.data.data.record.last_name,
                         title: response.data.data.record.title,
                         primary_email: response.data.data.record.primary_email,
                         phone1: response.data.data.record.phone1,
                         lead_source: response.data.data.record.lead_source,
                         product_id: response.data.data.selected_product,

                         organization_name: response.data.data.record.organization_name,
                         organization_email: response.data.data.record.organization_email,
                         organization_phone1: response.data.data.record.organization_phone1,
                         organization_website: response.data.data.record.organization_website,
                         department: response.data.data.record.department,
                         description: response.data.data.record.description,
                         facebook_url: response.data.data.record.facebook_url,
                         linkedin_url: response.data.data.record.linkedin_url,
                         twitter_url: response.data.data.record.twitter_url,
                         instagram_url: response.data.data.record.instagram_url,

                         address1: response.data.data.record.address1,
                         address2: response.data.data.record.address2,
                         city: response.data.data.record.city,
                         state: response.data.data.record.state,
                         zipcode: response.data.data.record.zipcode,
                         country_id: response.data.data.record.country_id,
                         error_list: [],
                    })

                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/leads');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/leads');
          });
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }



     const handleSelectProductSave = (e) => {
          selectProduct = Array.isArray(e) ? e.map(x => x.value) : [];
     };



     const handleSubmit = (values, onSubmitProps) => {

          const data = {
               salutation: values.salutation,
               first_name: values.first_name,
               last_name: values.last_name,
               title: values.title,
               primary_email: values.primary_email,
               phone1: values.phone1,
               lead_source: values.lead_source,
               product_id: selectProduct,

               organization_name: values.organization_name,
               organization_email: values.organization_email,
               organization_phone1: values.organization_phone1,
               organization_website: values.organization_website,
               department: values.department,
               description: values.description,
               facebook_url: values.facebook_url,
               linkedin_url: values.linkedin_url,
               instagram_url: values.instagram_url,
               twitter_url: values.twitter_url,

               address1: values.address1,
               address2: values.address2,
               city: values.city,
               state: values.state,
               zipcode: values.zipcode,
               country_id: values.country_id,
          }


          axios.post("/api/lead/store", data).then(response => {
               if (response.status === 200) {
                    toast.success('Lead has been created successfully');
                    history.push('/leads');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    onSubmitProps.setSubmitting(false)
                    if (error.response.status === 400) {
                         setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/leads');
                    } else {
                         toast.warning(error.response.data.message);
                    }
               }

          });

     }


     const customStyles1 = {
          menuList: styles => ({
               ...styles,
               // color: 'black',
               // backgroundcolor: '$blue',
               paddingTop: 0,
               paddingBottom: 0,
          }),
     }

     if (loading) {
          return <Preloader show={loading ? false : true} />;
     } else {

          //-----------------For Country list-----------------------------------
          let selectedCountry = [];
          for (var i = 0, l = countryArray.length; i < l; i++) {
               if (dataRecord.country_id == countryArray[i].id) {
                    selectedCountry.push({ label: countryArray[i].country_name, value: countryArray[i].id });
               }
          }

          let countrysLists = [{ label: 'Select', value: '' }];
          for (var i = 0, l = countryArray.length; i < l; i++) {
               countrysLists.push({ label: countryArray[i].country_name, value: countryArray[i].id });
          }

          let selectedSalutation = [];
          Object.keys(salutation).map(function (key) {
               if (dataRecord.salutation == key) {
                    selectedSalutation.push({ label: salutation[key], value: key });
               }
          }, this);

          let salutationList = [{ label: 'Select...', value: '' }];
          Object.keys(salutation).map(function (key) {
               salutationList.push({ label: salutation[key], value: key });
          }, this);

          let selectedLeadSource = [];
          leadSourceArray.map((item) => {
               if (dataRecord.lead_source === item.title) {
                    selectedLeadSource.push({ label: item.title, value: item.title });
               }
          }, this);
          // Object.keys(leadSourceArray).map(function (key) {
          //      if (dataRecord.lead_source == key) {
          //           selectedLeadSource.push({ label: leadSourceArray[key], value: key });
          //      }
          // }, this);

          let leadSourceList = [];
          leadSourceArray.map((item) => {
               leadSourceList.push({ label: item.title, value: item.title });
          }, this);
          // Object.keys(leadSourceArray).map(function (key) {
          //      leadSourceList.push({ label: leadSourceArray[key], value: key });
          // }, this);

          let productLists = [];
          for (var i = 0, l = productArray.length; i < l; i++) {
               productLists.push({ label: productArray[i].name, value: productArray[i].id });
          }

          let selectedProduct = [];
          for (var i = 0, l = productArray.length; i < l; i++) {
               if (selectProductArray.includes(productArray[i].id)) {
                    selectedProduct.push({ label: productArray[i].name, value: productArray[i].id });
               }

          }

          return (

               // <Row>
               <div className="display flex-wrap">
                    <Col md={12} className="mt30">
                         <div className="display w-100">
                              <Accordion defaultActiveKey="0" className="w-100">
                                   <Accordion.Item eventKey="0">
                                        <Accordion.Header><h4>Create Lead Information</h4></Accordion.Header>
                                        <Accordion.Body>
                                             <Formik
                                                  enableReinitialize={true}
                                                  initialValues={defaultValue}
                                                  validationSchema={validationSchema}
                                                  onSubmit={handleSubmit}
                                             >{props => {
                                                  const {
                                                       values,
                                                       touched,
                                                       errors,
                                                       dirty,
                                                       isSubmitting,
                                                       handleChange,
                                                       handleBlur,
                                                       handleSubmit,
                                                       handleReset,
                                                       setFieldValue,
                                                       setFieldTouched,
                                                  } = props;
                                                  return (
                                                       <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                                                            <div className="display align-items-start flex-wrap">
                                                                 <div className="col-md-6 circletrick">
                                                                      <div className="display align-items-start flex-wrap">
                                                                           <div className="col-md-12 circletrick position-relative leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Salutation</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="salutation">
                                                                                               <Select
                                                                                                    defaultValue={selectedSalutation}
                                                                                                    name="salutation"
                                                                                                    options={salutationList}
                                                                                                    className="basic-multi-select"
                                                                                                    classNamePrefix="select"
                                                                                                    menuPosition="fixed"
                                                                                                    onChange={selectedOption => {
                                                                                                         values.salutation = selectedOption.value;
                                                                                                         handleChange("salutation");
                                                                                                    }}
                                                                                                    onBlur={setFieldTouched}
                                                                                                    styles={customStyles1}
                                                                                                    isClearable={false}
                                                                                                    isSearchable={false}
                                                                                                    theme={(theme) => ({
                                                                                                         ...theme,
                                                                                                         borderRadius: 0,
                                                                                                         colors: {
                                                                                                              ...theme.colors,
                                                                                                              primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                                                                                         },
                                                                                                    })}

                                                                                               />
                                                                                               <span className="error">{defaultValue.error_list.salutation}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="salutation" />
                                                                                               </span>
                                                                                          </Form.Group>
                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">First Name<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="first_name">
                                                                                               <Form.Control type="text" name="first_name" value={values.first_name} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter first name" className={errors.first_name && touched.first_name ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.first_name}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="first_name" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Last Name<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="last_name">
                                                                                               <Form.Control type="text" name="last_name" value={values.last_name} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter last name" className={errors.last_name && touched.last_name ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.last_name}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="last_name" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Title</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="title">
                                                                                               <Form.Control type="text" name="title" value={values.title} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter title" className={errors.title && touched.title ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.title}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="title" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Email</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="primary_email">
                                                                                               <Form.Control type="text" name="primary_email" value={values.primary_email} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter primary email" className={errors.primary_email && touched.primary_email ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.primary_email}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="primary_email" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Mobile</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="phone1">
                                                                                               <Form.Control type="text" name="phone1" value={values.phone1} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter mobile" className={errors.phone1 && touched.phone1 ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.phone1}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="phone1" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick position-relative leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Lead Source</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="lead_source">
                                                                                               <Select
                                                                                                    defaultValue={selectedLeadSource}
                                                                                                    name="lead_source"
                                                                                                    options={leadSourceList}
                                                                                                    className="basic-multi-select"
                                                                                                    classNamePrefix="select"
                                                                                                    menuPosition="fixed"
                                                                                                    onChange={selectedOption => {
                                                                                                         values.lead_source = selectedOption.value;
                                                                                                         handleChange("lead_source");
                                                                                                    }}
                                                                                                    onBlur={setFieldTouched}
                                                                                                    styles={customStyles1}
                                                                                                    isClearable={false}
                                                                                                    isSearchable={false}
                                                                                                    theme={(theme) => ({
                                                                                                         ...theme,
                                                                                                         borderRadius: 0,
                                                                                                         colors: {
                                                                                                              ...theme.colors,
                                                                                                              primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                                                                                         },
                                                                                                    })}

                                                                                               />
                                                                                               <span className="error">{defaultValue.error_list.lead_source}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="lead_source" />
                                                                                               </span>
                                                                                          </Form.Group>
                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>

                                                                           <div className="col-md-12 circletrick quoteform circletrick1">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-24 mt8">Product Interested</Form.Label>

                                                                                     {/* <Col sm="6">
                                                                                     <Form.Group id="owner_id"> */}
                                                                                     <Select
                                                                                          defaultValue={selectedProduct}
                                                                                          isMulti
                                                                                          name="product_id"
                                                                                          options={productLists}
                                                                                          className="basic-multi-select"
                                                                                          classNamePrefix="select"
                                                                                          // onChange={selectedOption => {
                                                                                          //      values.product_id = selectedOption.value;
                                                                                          //      handleChange("product_id");
                                                                                          // }}
                                                                                          onChange={handleSelectProductSave}
                                                                                          onBlur={setFieldTouched}
                                                                                          styles={customStyles1}
                                                                                          theme={(theme) => ({
                                                                                               ...theme,
                                                                                               borderRadius: 0,
                                                                                               colors: {
                                                                                                    ...theme.colors,
                                                                                                    primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                                                                               },
                                                                                          })}
                                                                                     />
                                                                                     <span className="error">{defaultValue.error_list.product_id}</span>
                                                                                     <span className="error error4">
                                                                                          <ErrorMessage name="product_id" />
                                                                                     </span>
                                                                                     {/* </Form.Group>
                                                                                </Col> */}
                                                                                </Form.Group>

                                                                           </div>

                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Description</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="description">
                                                                                               <Form.Control as="textarea" name="description" value={values.description} minLength={2} maxLength={150} onChange={handleChange} onBlur={handleBlur} placeholder="Enter description" className={errors.description && touched.description ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.description}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="description" />
                                                                                               </span>
                                                                                          </Form.Group>
                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>




                                                                      </div>
                                                                 </div>
                                                                 <div className="col-md-6 circletrick">
                                                                      <div className="display align-items-start flex-wrap">
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Company Name<span className="text-danger">*</span></Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="organization_name">
                                                                                               <Form.Control type="text" name="organization_name" value={values.organization_name} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Company name" className={errors.organization_name && touched.organization_name ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.organization_name}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="organization_name" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Company Email</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="organization_email">
                                                                                               <Form.Control type="text" name="organization_email" value={values.organization_email} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Company email" className={errors.organization_email && touched.organization_email ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.organization_email}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="organization_email" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Company Phone</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="organization_email">
                                                                                               <Form.Control type="text" name="organization_phone1" value={values.organization_phone1} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Company phone" className={errors.organization_phone1 && touched.organization_phone1 ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.organization_phone1}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="organization_phone1" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Company Website</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="organization_website">
                                                                                               <Form.Control type="text" name="organization_website" value={values.organization_website} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Company website" className={errors.organization_website && touched.organization_website ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.organization_website}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="organization_website" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Department</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="department">
                                                                                               <Form.Control type="text" name="department" value={values.department} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter department" className={errors.department && touched.department ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.department}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="department" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Facebook</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="facebook_url">
                                                                                               <Form.Control type="text" name="facebook_url" value={values.facebook_url} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Company facebook url" className={errors.facebook_url && touched.facebook_url ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.facebook_url}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="facebook_url" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Linkedin</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="linkedin_url">
                                                                                               <Form.Control type="text" name="linkedin_url" value={values.linkedin_url} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Company linkedin url" className={errors.linkedin_url && touched.linkedin_url ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.linkedin_url}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="linkedin_url" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Instagram</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="instagram_url">
                                                                                               <Form.Control type="text" name="instagram_url" value={values.instagram_url} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Company instagram url" className={errors.instagram_url && touched.instagram_url ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.instagram_url}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="instagram_url" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Twitter</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="twitter_url">
                                                                                               <Form.Control type="text" name="twitter_url" value={values.twitter_url} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Company twitter url" className={errors.twitter_url && touched.twitter_url ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.twitter_url}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="twitter_url" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>



                                                                      </div>
                                                                 </div>



                                                            </div>

                                                            <div className="display align-items-start flex-wrap mb25">
                                                                 <div className="col-md-12">
                                                                      <h4>Address Information</h4>
                                                                 </div>
                                                            </div>



                                                            <div className="display align-items-start flex-wrap">


                                                                 <div className="col-md-6 circletrick">
                                                                      <div className="display align-items-start flex-wrap">
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Address</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="address1">
                                                                                               <Form.Control as="textarea" name="address1" value={values.address1} minLength={2} maxLength={100} onChange={handleChange} onBlur={handleBlur} placeholder="Enter address1" className={errors.address1 && touched.address1 ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.address1}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="address1" />
                                                                                               </span>
                                                                                          </Form.Group>
                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">City</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="city">
                                                                                               <Form.Control type="text" name="city" value={values.city} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter city" className={errors.city && touched.city ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.city}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="city" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Zipcode</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="zipcode">
                                                                                               <Form.Control type="text" name="zipcode" value={values.zipcode} minLength={2} maxLength={10} onChange={handleChange} onBlur={handleBlur} placeholder="Enter last name" className={errors.zipcode && touched.zipcode ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.zipcode}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="zipcode" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>






                                                                      </div>
                                                                 </div>

                                                                 <div className="col-md-6 circletrick">
                                                                      <div className="display align-items-start flex-wrap">
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Address2</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="address2">
                                                                                               <Form.Control as="textarea" name="address2" value={values.address2} minLength={2} maxLength={100} onChange={handleChange} onBlur={handleBlur} placeholder="Enter address2" className={errors.address2 && touched.address2 ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.address2}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="address2" />
                                                                                               </span>
                                                                                          </Form.Group>
                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">State</Form.Label>
                                                                                     <Col sm="6">
                                                                                          <Form.Group id="state">
                                                                                               <Form.Control type="text" name="state" value={values.state} minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter state" className={errors.state && touched.state ?
                                                                                                    "input-error" : null} />
                                                                                               <span className="error">{defaultValue.error_list.state}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="state" />
                                                                                               </span>
                                                                                          </Form.Group>

                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>
                                                                           <div className="col-md-12 circletrick quoteform leadlist">
                                                                                <Form.Group className="mb-125 display">
                                                                                     <Form.Label className="text-right w-17">Country</Form.Label>

                                                                                     <Col sm="6">
                                                                                          <Form.Group id="country_id">
                                                                                               <Select
                                                                                                    //value={countrysLists.filter(({ value }) => value === (dataRecord.country_id && dataRecord.country_id))} 
                                                                                                    defaultValue={selectedCountry}
                                                                                                    name="country_id"
                                                                                                    options={countrysLists}
                                                                                                    className="basic-multi-select"
                                                                                                    classNamePrefix="select"
                                                                                                    menuPosition="fixed"
                                                                                                    onChange={selectedOption => {
                                                                                                         values.country_id = selectedOption.value;
                                                                                                         handleChange("country_id");
                                                                                                    }}
                                                                                                    onBlur={setFieldTouched}
                                                                                                    styles={customStyles1}
                                                                                                    //isClearable={true}
                                                                                                    //isSearchable={true}
                                                                                                    theme={(theme) => ({
                                                                                                         ...theme,
                                                                                                         borderRadius: 0,
                                                                                                         colors: {
                                                                                                              ...theme.colors,
                                                                                                              primary25: 'var(--primary-color-light)',
                                                                                                              primary: 'var(--primary-color)',
                                                                                                         },
                                                                                                    })}

                                                                                               />
                                                                                               <span className="error">{defaultValue.error_list.country_id}</span>
                                                                                               <span className="error error4">
                                                                                                    <ErrorMessage name="country_id" />
                                                                                               </span>
                                                                                          </Form.Group>
                                                                                     </Col>
                                                                                </Form.Group>
                                                                           </div>




                                                                      </div>
                                                                 </div>

                                                            </div>


                                                            <div className="display align-items-center">
                                                                 <div className="text-center mt30">
                                                                      {
                                                                           isSubmitting ?

                                                                                <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                                                                                     <Spinner animation="border" size="sm" />
                                                                                     <span className="ms-2 text-center">Please wait...</span>
                                                                                </Button>
                                                                                :

                                                                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                                                     <div className="btn-group mr-2" role="group" aria-label="First group">
                                                                                          <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                                                                                     </div>
                                                                                </div>
                                                                      }
                                                                 </div>
                                                            </div>


                                                       </Form>
                                                  );
                                             }}
                                             </Formik>
                                        </Accordion.Body>
                                   </Accordion.Item>
                              </Accordion>
                         </div>
                    </Col>
               </div>

          );

     }


};

export default CloneLead;