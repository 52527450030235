
import React, { useState,useEffect } from "react";
import { Col, Row, Form, Card, Button, Spinner, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { DisplayHostName } from "../../features/userSlice";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Routes } from "../../routes";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import axios from 'axios';

function Signup() {
  let logoUrl = ''

  //get default host data from redux using url
  var defaultHostData = useSelector(DisplayHostName);
  //var hostname = defaultHostData?.hostname||''
  var displayHost = defaultHostData?.displayHost||''
  
  if(displayHost){
    logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/`+displayHost+`.png`;
  }
  
  const checkFileExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if(response.status === 200){
       logoUrl = logoUrl;
      }
    } catch (error) {
      logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/app.png`; 
    }
  }
  
  useEffect(() => {
    async function fetchData() {
      await checkFileExistence(logoUrl);
    }
    fetchData();

  },[displayHost]);

  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const [conPasswordShown, setConPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConPassword = () => {
    setConPasswordShown(!conPasswordShown);
  };


  const [defaultValue, setdefaultValue] = useState({
    name: '',
    email: '',
    password: '',
    confirm_password: '',
    error_list: [],
    userTimeZone:'',
  })

  const validationSchema = yup.object().shape({
    name: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter your organization name'),
    email: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter your organization email').email('Please enter a valid email address'),
    password: yup.string().required('Password cannot be less than 8 characters').min(8).max(20).matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)\+\-\/])(?=.{8,})/,
      "Must Contain one Uppercase, one Lowercase, one Number and one Special Case Character"
    ),
    confirm_password: yup.string().required('Enter your confirm password').oneOf([yup.ref('password'), null], "Password doesn't match")
  })

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password,
      terms: values.terms,
      userTimeZone:Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    await axios.post("/api/register", data).then(response => {
      if (response.status === 200) {
        toast.success(response.data.message);
        history.push('/sign-in');
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
          history.push('/sign-up');
        } else if (error.response.status === 500) {
          toast.error('Oops Something went wrong.');
          history.push('/sign-up');
        }else if(error.response.status === 422 || error.response.data.validation_errors) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        } else {
          toast.error(error.response.data.message);
          history.push('/sign-up');
        }
      }
    });
  }

  return (
    <>
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 common-form">
          <Container>
            <div className="title text-center mb40">
              <a href={process.env.REACT_APP_MARKETING_WEB_URL}><img src={logoUrl} className="signlogo" style={{ cursor: 'pointer' }} /></a>
            </div>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white w-100 box-form">
                  <div className="text-center text-md-center mt-md-0">
                    <h3 className="mb-0">Create an Account</h3>
                  </div>
                  <Formik
                    initialValues={defaultValue}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >{props => {
                    const {
                      //values,
                      touched,
                      errors,
                      //dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      //handleReset
                    } = props;
                    return (
                      <Form className="position-relative" onSubmit={handleSubmit}>
                        <Form.Group id="name" className="mt40 even">
                          <InputGroup>
                            <Form.Control type="text" name="name" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Organization Name" className={errors.name && touched.name ?
                              "input-error" : null} />
                          </InputGroup>
                          <span className="error error3 text-center">{defaultValue.error_list.name}</span>
                          <p className="text-danger error3">
                            <ErrorMessage name="name" />
                          </p>
                        </Form.Group>
                        <Form.Group id="email" className="mt40 even">
                          <InputGroup>
                            <Form.Control type="email" name="email" minLength={2} maxLength={50} onChange={e => {
                              handleChange(e);
                              setdefaultValue({ ...defaultValue, error_list: '' });
                            }
                            } onBlur={handleBlur} placeholder="Email" className={errors.email && touched.email ?
                              "input-error" : null} />
                          </InputGroup>
                          <span className="error error3 text-center">{defaultValue.error_list.email}</span>
                          <p className="text-danger error3">
                            <ErrorMessage name="email" />
                          </p>
                        </Form.Group>
                        <Form.Group id="password" className="mt40 even">
                          <InputGroup>
                            <Form.Control type={passwordShown ? "text" : "password"} name="password" minLength={8} maxLength={20} onChange={handleChange} onBlur={handleBlur} placeholder="Password" className={errors.password && touched.password ?
                              "input-error" : null} />
                            <span onClick={togglePassword} className={passwordShown ? "password_icon" : "signin_icon"}></span>
                          </InputGroup>
                          <span className="error error3 text-center">{defaultValue.error_list.password}</span>
                          <p className="text-danger error3">
                            <ErrorMessage name="password" />
                          </p>
                        </Form.Group>
                        <Form.Group id="confirmPassword" className="mt40 mb40 even">
                          <InputGroup>
                            <Form.Control type={conPasswordShown ? "text" : "password"} name="confirm_password" onChange={handleChange} onBlur={handleBlur} placeholder="Confirm Password" className={errors.confirm_password && touched.confirm_password ?
                              "input-error" : null} />
                            <span onClick={toggleConPassword} className={conPasswordShown ? "password_icon" : "signin_icon"}></span>
                          </InputGroup>
                          <span className="error error3 text-center">{defaultValue.error_list.confirm_password}</span>
                          <p className="text-danger error3">
                            <ErrorMessage name="confirm_password" />
                          </p>
                        </Form.Group>
                        {
                          isSubmitting ?
                            <Button variant="primary" className="d-flex align-items-center justify-content-center text-center mb-1 w-100">
                              <Spinner animation="border" size="sm" />
                              <span className="ms-2 text-center">Please Wait...</span>
                            </Button>
                            :
                            <Button variant="primary" type="submit" className="w-100">
                              Sign up
                            </Button>
                        }
                      </Form>
                    );
                  }}
                  </Formik>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-center align-items-center mt-4 footer-form">
              <span>
                Already have an Account?
                <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                  {` Sign In Here `}
                </Card.Link>
              </span>
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Signup;

