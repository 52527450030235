import React, { useEffect, useState } from "react";
import { Col, Row, Form, FormCheck } from "@themesberg/react-bootstrap";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import Preloader from "../../components/PreloaderCenter";
import { toast } from "react-toastify";
import "react-edit-text/dist/index.css";
import "../../assets/css/custom.css";
import EasyEdit, { Types } from "react-easy-edit";
import { useDispatch } from "react-redux";
import { LoginPermission } from "../../features/userSlice";
import { Alert, Badge, Tooltip, OverlayTrigger } from "react-bootstrap";

import { useHistory } from "react-router-dom";

import axios from "axios";
var editvalueData = 1;

function EditRole(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userID, setUserID] = useState(props.match.params.id);
  const [isEditable, setIsEditable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [UserDataName, setUserDataName] = useState("");
  const [UserDataPermissions, setUserDataPermissions] = useState([]);

  const [ErrorInput, setErrorInput] = useState({ error_list: [] });

  const [nameErrorMsg, setNameErrorMsg] = useState("");

  const [permissionList, setPermissionsList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, []);

  async function getData() {
    await axios
      .get("api/role/edit/" + userID)
      .then((response) => {
        if (response.data.code === 200) {
          setUserDataName(response.data.data.record.name);
          setUserDataPermissions(response.data.data.recordPermissions);
          editvalueData = response.data.data.record.is_editable;
          setPermissionsList(response.data.data.permissionArray);
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/roles");
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
            history.push("/roles");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const handleSaveData = async (value, name, previousValue) => {
    const data = {
      id: userID,
      permissions: UserDataPermissions,
      [name]: value,
    };

    await axios
      .post("api/role/update", data)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setUserDataName(response.data.data.record.name);
          setIsEditable(true);
          setErrorInput({ ...ErrorInput, error_list: "" });
          toast.success("Data updated successfully");
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setIsEditable(true);
          if (error.response.status === 400) {
            setErrorInput({
              ...ErrorInput,
              error_list: error.response.data.validation_errors,
            });
            if (error.response.data.validation_errors["name"]) {
              setUserDataName(previousValue);
            }
          } else if (error.response.status === 300) {
            //swal("warning", error.response.data.message, "warning");
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/roles");
          } else {
            // swal("error", 'Opps something went wrong!', "error");
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  const cancel = () => {
    setIsEditable(true);

    setNameErrorMsg("");
  };

  const handleClick = (e) => {
    setIsEditable(false);
  };

  const handleCheckbox = async (e) => {
    e.persist();

    const isChecked = e.target.checked;
    var permissionData = UserDataPermissions;
    var perType = "";

    if (isChecked) {
      //setPermissionsArray(permissionsArray => [...permissionsArray, e.target.value]);
      //permissionData.push(parseInt(e.target.value));
      UserDataPermissions.push(parseInt(e.target.value));
      setUserDataPermissions(permissionData);
      perType = "add";
    } else {
      //setPermissionsArray(permissionsArray.filter(x => x !== e.target.value));
      //permissionData.pop(e.target.value)
      permissionData = permissionData.filter(
        (x) => x !== parseInt(e.target.value)
      );
      setUserDataPermissions(permissionData);
      perType = "remove";
    }

    const data = {
      id: userID,
      name: UserDataName,
      permissions: permissionData,
      permissionId: e.target.value,
      permissionType: perType,
    };
    setLoading(true);
    await axios
      .post("/api/role/update", data)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setUserDataPermissions(response.data.data.permissions);
          if (response.data.data.permissionArray) {
            dispatch(LoginPermission(response.data.data.permissionArray));
          }
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 400) {
            setErrorInput({
              ...ErrorInput,
              error_list: error.response.data.validation_errors,
            });
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/roles");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    let permissionLists = Object.entries(permissionList).map(([key, value]) => {
      return (
        <tr key={key}>
          <td className="permission" style={{ textTransform: "capitalize" }}>
            {key}
          </td>

          {value.map(function (keys) {
            let checkPer =
              UserDataPermissions.indexOf(keys.id) > -1 ? true : false;
            return (
              <td className="permission" key={keys.id}>
                <FormCheck
                  type="checkbox"
                  className="container d-flex align-items-center justify-content-center"
                >
                  <FormCheck.Input
                    name="permissions[]"
                    defaultChecked={checkPer}
                    onChange={handleCheckbox}
                    value={keys.id}
                    className="me-2"
                  />
                </FormCheck>
              </td>
            );
          })}
        </tr>
      );
    }, this);

    return (
      <Row>
        <Col md={12} className="mt30">
          <div className="display w-100">
            <Accordion defaultActiveKey="0" className="w-100">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Role Information</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="display flex-wrap">
                    <div className="col-md-9 circletrick">
                      <Form.Group className="mb-2 display">
                        <Form.Label className="text-left w-17">Name</Form.Label>
                        {editvalueData ? (
                          <EasyEdit
                            type={Types.TEXT}
                            value={UserDataName}
                            onSave={(value) => {
                              handleSaveData(value, "name", UserDataName);
                            }}
                            onValidate={(value) => {
                              var regEx = /^[a-z0-9\s]+$/i;
                              if (value) {
                                var splString = value.split(" ");
                                if (value.length > 50) {
                                  setNameErrorMsg(
                                    "Must be at most 50 characters."
                                  );
                                  return false;
                                } else if (!value.match(regEx)) {
                                  setNameErrorMsg(
                                    "Only alphanumric are allowed"
                                  );
                                  return false;
                                } else if (value && value.length < 2) {
                                  setNameErrorMsg(
                                    "Enter at least 2 characters."
                                  );
                                  return false;
                                } else if (
                                  value &&
                                  value.length == 2 &&
                                  value &&
                                  splString.length >= 2
                                ) {
                                  setNameErrorMsg(
                                    "Enter at least 2 characters, space not allow."
                                  );
                                  return false;
                                }
                                return true;
                              } else {
                                setNameErrorMsg("Enter role name.");
                                return false;
                              }
                            }}
                            validationMessage={nameErrorMsg}
                            onCancel={cancel}
                            saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                            cancelButtonLabel={
                              <FontAwesomeIcon icon={faTimes} />
                            }
                            attributes={{ maxLength: 50 }}
                            placeholder="----"
                            instructions=""
                            allowEdit={isEditable}
                            onFocus={() => handleClick("")}
                          />
                        ) : (
                          <span>{UserDataName}</span>
                        )}
                      </Form.Group>
                      <span className="error">
                        {ErrorInput.error_list.name}
                      </span>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>

        <Col md={12} className="mt30">
          <div className="display w-100">
            <div className="col-md-12">
              <div className="notes_section sideborader">
                <div className="notes_header p2022 display acvitybottom">
                  <h4>Permission</h4>
                  {!editvalueData ? (
                    <div style={{ paddingLeft: "10px", paddingTop: "15px" }}>
                      <React.Fragment>
                        <Alert variant="warning">
                          {/* Make sure before change this permissions, once you remove this permission you cannot access that module, specially for User and Role.. */}
                          <OverlayTrigger
                            key="example"
                            overlay={
                              <Tooltip id="top" className="m-0">
                                Make sure before change this permission, once
                                you remove this permission you cannot access
                                that module, specially for User and Role..
                              </Tooltip>
                            }
                          >
                            <Badge
                              bg="warning"
                              text="dark"
                              className="badge-lg"
                            >
                              Warning!{" "}
                            </Badge>
                          </OverlayTrigger>
                          <span style={{ paddingLeft: "10px" }}>
                            By editing the permission, you might break the
                            system permissions functionality. Please ensure
                            you're absolutely certain before proceeding.
                          </span>
                        </Alert>
                      </React.Fragment>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="notes_body notes_bottom">
                  <div className="display flex-wrap">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <h6>Select Permission</h6>
                          </td>
                          <td className="text-center">
                            <h6>Create</h6>
                          </td>
                          <td className="text-center">
                            <h6>Delete</h6>
                          </td>
                          <td className="text-center">
                            <h6>Edit</h6>
                          </td>
                          <td className="text-center">
                            <h6>List</h6>
                          </td>
                          <td className="text-center">
                            <h6>View</h6>
                          </td>
                        </tr>
                        {permissionLists}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default EditRole;
