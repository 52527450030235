/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Button, Modal} from '@themesberg/react-bootstrap';
import {  toast } from 'react-toastify';
import axios from "axios";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

const WriteUs = ({isOpen,onRequestClose}) => {
  const [attachmentArrayList, setAttachmentArrayList] = useState([]);
  const [defaultValue, setdefaultValue] = useState({
    subject: '',
    description: '',
    attachment: null,
    error_list: [],
  })

  const validationSchema = yup.object().shape({
    subject:yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter your subject'),
    description:yup.string().min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters').required('Enter your description'),
  })

  function deleteFile(e) {
    const s = attachmentArrayList.filter((item, index) => index !== e);
    setTimeout(() => setAttachmentArrayList(s), 100);
  }

  const handleNoteImageInput = (e) => {
    e.persist();
    setAttachmentArrayList([...attachmentArrayList, ...e.target.files]);
  }

  const handleSubmit = (values,onSubmitProps) =>{
    const formData = new FormData();
    formData.append('subject', values.subject)
    formData.append('description', values.description)
    
    for (var i = 0; i < attachmentArrayList.length; i++) {
      formData.append('attachment[]', attachmentArrayList[i])
    }

    axios.post("/api/write-us-help", formData).then(response => {
      if (response.status === 200) {
        toast.success(response.data.message);
        onRequestClose()
        setAttachmentArrayList([])
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 400) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
          toast.warning(error.response.data.message);
        } else if(error.response.status === 403){
          toast.warning('Forbidden '+error.response.data.message);
        } else {
          toast.warning(error.response.data.message);
        }
      }
    });
  }
  
  return (
    <Modal
      as={Modal.Dialog}
      centered="false"
      animation="false"
      right
      show={isOpen}
      className="model1 writetous"
    >
      <Modal.Header>
        <Modal.Title className="h2 modal-title h4">Write to us</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={onRequestClose} />
        <span className="modelclose"></span>
      </Modal.Header>
      <Modal.Body className="modelbodyes">
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              // eslint-disable-next-line no-unused-vars
              setErrors,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12} className="mb-125 position-relative">
                    <Form.Group id="email">
                      <Form.Label column sm="12">
                        <h6 className="fw600">
                          Subject<span className="text-danger">*</span>
                        </h6>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="subject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter subject"
                        className={
                          errors.subject && touched.subject
                            ? "input-error"
                            : null
                        }
                      />
                      <span className="error error3 ml1">
                        {defaultValue.error_list.subject}
                      </span>
                      <span className="error error3 ml1">
                        <ErrorMessage name="subject" />
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-125 position-relative">
                    <Form.Group id="email">
                      <Form.Label column sm="12">
                        <h6 className="fw600">
                          Description<span className="text-danger">*</span>
                        </h6>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Group id="description">
                          <Form.Control
                            as="textarea"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter description"
                            className={
                              errors.description && touched.description
                                ? "input-error"
                                : null
                            }
                          />
                          <span className="error error3 ml1">
                            {defaultValue.error_list.description}
                          </span>
                          <span className="error error3 ml1">
                            <ErrorMessage name="description" />
                          </span>
                        </Form.Group>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group>
                  <Col md="12">
                    <Form.Group id="uploaded-file">
                      <div className="form-group preview">
                        {attachmentArrayList.length > 0 &&
                          attachmentArrayList.map((item, index) => {
                            return (
                              <div
                                key={item}
                                className="display flex-wrap justify-content-between mb26"
                              >
                                <div className="col-md-11 display">
                                  <span>
                                    <h6 className="fileturn">{item.name}</h6>
                                  </span>
                                </div>
                                <div
                                  className="col-md-1 text-right"
                                  onClick={() => deleteFile(index)}
                                >
                                  <span className="redclose"></span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <span className="error">
                  {defaultValue.error_list.attachment}
                </span>
                <span className="error">
                  <ErrorMessage name="attachment" />
                </span>

                <div className="display mt-3 justify-content-end">
                  <div className="col-md-5 display attchment1">
                    <div className="file">
                      <label for="input-file" className="attchment"></label>
                      <Form.Control
                        id="input-file"
                        type="file"
                        name="attachment[]"
                        accept="image/jpeg,image/png,image/jpeg"
                        title="Allow file type are jpeg,png,jpg, and MAX file size 4MB"
                        className="form-control"
                        multiple
                        onChange={handleNoteImageInput}
                      />
                    </div>
                    <span>Add Attachment</span>
                  </div>
                  <div className="col-md-7">
                    {isSubmitting ? (
                      <Button className="d-flex align-items-center justify-content-center text-center popupbtn btnblue mb-1">
                        <span className="ms-2 text-center">
                          Please wait.....
                        </span>
                      </Button>
                    ) : (
                      <div
                        className="btn-toolbar justify-content-end"
                        role="toolbar"
                        aria-label="Toolbar with button groups"
                      >
                        <div
                          className="btn-group mr-2"
                          role="group"
                          aria-label="First group"
                        >
                          <Button
                            type="submit"
                            variant="success"
                            className="popupbtn btnblue mr15"
                          >
                            Submit
                          </Button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="First group"
                        >
                          <Button
                            type="button"
                            variant="success"
                            className="popupbtn greycolor"
                            onClick={onRequestClose}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default WriteUs;
