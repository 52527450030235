import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from 'react-currency-input-field';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Form, Spinner, Modal } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ActivityList from "../../components/ActivityList"
import EditCustomFields from "../../components/EditCustomFields"
import NoteList from "../../components/NoteList"
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import swal from 'sweetalert';
import axios from 'axios';
import EasyEdit, { Types } from "react-easy-edit";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

var currentPage = 1;

function EditLead(props) {
     const history = useHistory();
     const loginUser = useSelector(selectUser);
     const [isEditable, setIsEditable] = useState(true)
     const [isEditableProduct, setIsEditableProduct] = useState(false)

     const user = loginUser?loginUser:[];

     const userPermissions = useSelector((state) => state.permission);

     const userPermission = userPermissions?userPermissions:[];
     
     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
     
     const [leadID, setleadID] = useState(props.match.params.id);
     const [updateLeadData, setUpdateLeadData] = useState(false);
     const [preDateFormate, setPreDateFormate] = useState('');

     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
     const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');
     const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
     const [displayDateFormate, setDisplayDateFormate] = useState('yyyy-MM-dd');

     const [firstNameErrorMsg, setFirstNameErrorMsg] = useState();
     const [lastNameErrorMsg, setLastNameErrorMsg] = useState();
     const [titleErrorMsg, setTitleErrorMsg] = useState();
     const [orgPhone1ErrorMsg, setOrgPhone1ErrorMsg] = useState();
     const [phone1ErrorMsg, setPhone1ErrorMsg] = useState();
     const [orgNameErrorMsg, setOrgNameErrorMsg] = useState();
     const [orgEmailErrorMsg, setOrgEmailErrorMsg] = useState();
     const [departmentErrorMsg, setDepartmentErrorMsg] = useState();
     const [descriptionErrorMsg, setDescriptionErrorMsg] = useState();
     const [orgWebsiteErrorMsg, setOrgWebsiteErrorMsg] = useState();
     const [primaryEmailErrorMsg, setprimaryEmailErrorMsg] = useState();
     const [facebookErrorMsg, setFacebookErrorMsg] = useState();
     const [twitterErrorMsg, setTwitterErrorMsg] = useState();
     const [linkedinErrorMsg, setLinkedinErrorMsg] = useState();
     const [instagramErrorMsg, setInstagramErrorMsg] = useState();
     const [address1ErrorMsg, setAddress1ErrorMsg] = useState();
     const [address2ErrorMsg, setAddress2ErrorMsg] = useState();
     const [cityErrorMsg, setCityErrorMsg] = useState();
     const [stateErrorMsg, setStateErrorMsg] = useState();
     const [zipcodeErrorMsg, setZipcodeErrorMsg] = useState();

     //=========START:CODE FOR EDIT LEAD DATA==========//

     const [loading, setLoading] = useState(true);
     const [leadRecord, setLeadRecord] = useState('');
     const [ContactDatasalutation, setContactDatasalutation] = useState('');
     const [ContactDataProduct, setContactDataProduct] = useState([]);
     const [ContactDataCountryId, setContactDataCountryId] = useState('');
     const [ContactDatastage_id, setContactDataStage_id] = useState('');
     const [ContactDatalead_source, setContactDatalead_source] = useState('');

     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     const [expectedClosingDateError, setExpectedClosingDateError] = useState(false);


     const [countries, setCountry] = useState([]);
     const [salutation, setSalutation] = useState([]);
     const [leadSourceArray, setLeadSourceArray] = useState([]);
     const [productArray, setProductArray] = useState([]);
     const [leadStageArray, setLeadStageArray] = useState([]);
     const [productIdFill, setProductIdFill] = useState([]);
     const [displayCountryName, setdisplayCountryName] = useState('');
     const [displaySalutionName, setdisplaySalutionName] = useState('');
     const [displayProductName, setdisplayProductName] = useState('');
     const [displayStageName, setDisplayStageName] = useState('');

     const [getIpResponse, setGetIpResponse] = useState([]);
     const [latitude, setLatitude] = useState('');
     const [longitude, setLongitude] = useState('');

     const [defaultValue, setdefaultValue] = useState({
          deal_name: '',
          deal_description: '',
          deal_amount: '',
          deal_probability: '',
          expected_closing_date: '',
          error_list: [],
     })
     //========Const data for Activity (Lead and Deal)
     var calendorFormate = '';

     useEffect(() => {
          fetch(`https://ipapi.co/json/`)
               .then(response => response.json())
               .then(data =>
                    setGetIpResponse(data)
               )
               .catch(error => '');

          if ("geolocation" in navigator) {
               navigator.geolocation.getCurrentPosition(function (position) {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
               });
          } else {
               //console.log("Not Available");
          }
          getLead();
     }, [updateLeadData]);


     async function getLead() {
          await axios.get('api/lead/edit/' + leadID).then(response => {
               if (response.data.code === 200) {
                    setLeadRecord(response.data.data.record);
                    setPreDateFormate(response.data.data.preferredDateFormat);

                    setContactDatasalutation(response.data.data.record.salutation);

                    setContactDataCountryId(response.data.data.record.country_id);
                    setContactDataProduct(response.data.data.selected_product);
                    setContactDataStage_id(response.data.data.record.stage_id);

                    setdisplayCountryName(response.data.data.selectedData.Countryname);
                    setdisplaySalutionName(response.data.data.selectedData.SalutationName);
                    setDisplayStageName(response.data.data.selectedData.StageName);

                    setContactDatalead_source(response.data.data.record.lead_source);

                    setPreferredCurrencyData(response.data.data.preferredCurrency);
                    setPreferredDateFormatData(response.data.data.preferredDateFormat);

                    calendorFormate = response.data.data.preferredDateFormat.split(' ');
                    
                    if (calendorFormate) {
                         if (calendorFormate[0] == 'YYYY-MMM-DD' || calendorFormate[0] == 'YYYY-MM-DD') {
                              setCalendorDateFormate('yyyy/MM/dd');
                              setCalendorFullDateFormate('yyyy-MMM-DD');
                              setDisplayDateFormate('yyyy-MMM-dd')
                              setPreferredDateFormatData('YYYY-MMM-DD h:mm a')
                              if(calendorFormate[0] == 'YYYY-MM-DD'){
                                   setDisplayDateFormate('yyyy-MM-dd')
                                   setPreferredDateFormatData('YYYY-MM-DD h:mm a')
                              }
                              
                         } else if (calendorFormate[0] == 'DD-MMM-YYYY' || calendorFormate[0] == 'DD-MM-YYYY') {
                              setCalendorDateFormate('dd/MM/yyyy');
                              setCalendorFullDateFormate('DD-MMM-yyyy');
                              setDisplayDateFormate('dd-MMM-yyyy')
                              setPreferredDateFormatData('DD-MMM-YYYY h:mm a')
                              if(calendorFormate[0] == 'DD-MM-YYYY'){
                                   setDisplayDateFormate('dd-MM-yyyy')
                                   setPreferredDateFormatData('DD-MM-YYYY h:mm a')
                              }
                              
                         } else if (calendorFormate[0] == 'YYYY-DD-MMM' || calendorFormate[0] == 'YYYY-DD-MM') {
                              setCalendorDateFormate('yyyy/dd/MM');
                              setCalendorFullDateFormate('yyyy-DD-MMM');
                              setDisplayDateFormate('yyyy-dd-MMM')
                              setPreferredDateFormatData('YYYY-DD-MMM h:mm a') 
                              if(calendorFormate[0] == 'YYYY-DD-MM'){
                                   setDisplayDateFormate('yyyy-dd-MM')
                                   setPreferredDateFormatData('YYYY-DD-MM h:mm a') 
                              }
                              
                         } else if (calendorFormate[0] == 'MMM-DD-YYYY' || calendorFormate[0] == 'MM-DD-YYYY') {
                              setCalendorDateFormate('MM/dd/yyyy');
                              setCalendorFullDateFormate('MMM-DD-yyyy');
                              setDisplayDateFormate('MMM-dd-yyyy')
                              setPreferredDateFormatData('MMM-DD-YYYY h:mm a')
                              if(calendorFormate[0] == 'MM-DD-YYYY'){
                                   setDisplayDateFormate('MM-dd-yyyy')
                                   setPreferredDateFormatData('MM-DD-YYYY h:mm a')
                              }
                              
                         }
                         setCalendorFullDateFormate(calendorFormate[0])
                    }

                    setLeadSourceArray(response.data.data.LeadSource);
                    setCountry(response.data.data.countries);
                    setSalutation(response.data.data.salutationArray);
                    setProductArray(response.data.data.products);
                    setLeadStageArray(response.data.data.leadStage);

                    let selectedProducts = [];
                    for (var i = 0, l = response.data.data.selectedData.ProductName.length; i < l; i++) {
                         if (i + 1 < response.data.data.selectedData.ProductName.length) {
                              selectedProducts.push(response.data.data.selectedData.ProductName[i] + ' | ');
                         } else {
                              selectedProducts.push(response.data.data.selectedData.ProductName[i]);
                         }
                    }
                    setdisplayProductName(selectedProducts)
                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/leads');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/leads');
          });
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }



     const handleSaveData = async (value, name,type=null) => {
          const formData = new FormData();
          formData.append('id', leadID)
          formData.append('first_name', leadRecord.first_name)
          formData.append('last_name', leadRecord.last_name)
          formData.append('organization_name', leadRecord.organization_name)
          formData.append('product_id[]', ContactDataProduct)
          formData.append('lead_source', leadRecord.lead_source)
          formData.append('country_id', leadRecord.country_id?leadRecord.country_id:'')
          formData.append('salutation', leadRecord.salutation)
          if(type==='file'){
               for (var i = 0; i < value.length; i++) {
                    formData.append(name+'[]', value[i])
               }
          }else{
               formData.append([name], value)
          }
          

          // const data = {
          //      id: leadID,
          //      first_name: leadRecord.first_name,
          //      last_name: leadRecord.last_name,
          //      organization_name: leadRecord.organization_name,
          //      product_id: ContactDataProduct,
          //      lead_source: leadRecord.lead_source,
          //      country_id: leadRecord.country_id,
          //      salutation: leadRecord.salutation,
          //      [name]: value,
          // }
          
          if(value){
               await axios.post("api/lead/update", formData ).then(response => {
                    if (response.status === 200) {
                         updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         setIsEditable(true)
                         setIsEditableProduct(false)
                         toast.success('Data updated successfully');
                    }
               }).then(json => {
     
               }).catch(error => {
                    if (error.response) {
                         setIsEditable(true)
                         setIsEditableProduct(false)
                         if (error.response.status === 400) {
                              setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                              // const laravelErrors = error.response.data.validation_errors;
                              // Object.keys(laravelErrors).forEach((field) => {
                              //      toast.warning(laravelErrors[field][0]);
                              // });
                         } else if (error.response.status === 300) {
                              toast.warning(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden ' + error.response.data.message);
                         } else {
                              toast.error('Oops Something went wrong.');
                         }
                    }
     
               });
          }else{
               updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
               setErrorInput({ ...ErrorInput, error_list: '' });
               setIsEditable(true)
               setIsEditableProduct(false)    
          }
     };



     let selectProduct = [];
     const handleSelectProductSave = async (e) => {
          setProductIdFill(Array.isArray(e) ? e.map(x => x.value) : []);
          selectProduct = Array.isArray(e) ? e.map(x => x.value) : [];

          const data = {
               product_id: selectProduct,
               id: leadID,
               first_name: leadRecord.first_name,
               last_name: leadRecord.last_name,
               organization_name: leadRecord.organization_name,
          }

          await axios.post("/api/lead/update", data).then(response => {
               if (response.status === 200) {
                    setIsEditable(true)
                    setIsEditableProduct(false)
                    updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    toast.success('Data updated successfully');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setIsEditable(true)
                    setIsEditableProduct(false)
                    if (error.response.status === 400) {
                         setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                         toast.error('Oops Something went wrong.')
                    }
               }
          });
     };

     const handleSelectStageSave = (event) => {
          if (event.value) {
               setContactDataStage_id(event.value)
          }
     };

     async function updateStageData(stage_id) {
          const data = {
               stage_id: stage_id,
               id: leadID,
               first_name: leadRecord.first_name,
               last_name: leadRecord.last_name,
               product_id: ContactDataProduct,
               organization_name: leadRecord.organization_name,
          }

          await axios.post("/api/lead/update", data).then(response => {
               if (response.status === 200) {
                    updateLeadData ? setUpdateLeadData(false) : setUpdateLeadData(true);
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    toast.success('Data updated successfully');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    if (error.response.status === 400) {
                         setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                         toast.error('Oops Something went wrong.')
                    }
               }
          });
     }

     const cancel = () => {
          setIsEditable(true)
          setIsEditableProduct(false)

          setFirstNameErrorMsg('')
          setLastNameErrorMsg('')
          setTitleErrorMsg('')
          setOrgPhone1ErrorMsg('')
          setPhone1ErrorMsg('')
          setDepartmentErrorMsg('')
          setAddress1ErrorMsg('')
          setAddress2ErrorMsg('')
          setDescriptionErrorMsg('')
          setOrgNameErrorMsg('')
          setOrgEmailErrorMsg('')
          setOrgWebsiteErrorMsg('')
          setprimaryEmailErrorMsg('')
          setFacebookErrorMsg('')
          setTwitterErrorMsg('')
          setLinkedinErrorMsg('')
          setInstagramErrorMsg('')
          setCityErrorMsg('')
          setStateErrorMsg('')
          setZipcodeErrorMsg('')

     };


     const handleClick = (e) => {
          setIsEditable(false)
          setIsEditableProduct(true)
          if (e && e === 'product') {
               setIsEditableProduct(false)
          }
     }

     const handleProductBlurClick = (e) => {
          if (e && e === 'product') {
               setIsEditable(true)
               setIsEditableProduct(false)
          }
     }

     //=======Start:CODE FOR CONVERT DEAL=======//

     const [showConvertDeal, setShowConvertDeal] = useState(false);
     const handleCovertClose = () => setShowConvertDeal(false);

     const [convertDeal, setConvertDeal] = useState(false);

     const [expectedClosingDate, setExpectedClosingDate] = useState(new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone })));


     const [dealInput, setDealInput] = useState({
          deal_description: '',
          deal_amount: '',
          deal_probability: '',
          expected_closing_date: '',
          error_list: [],
     });

     const lengthValidation = (fieldLength) => {
          var valid = true;
          if (fieldLength) {
               var str = fieldLength.replace(/\s/g, '')
               if (str.length < 2) {
                    valid = false;
               }
          }

          return valid ? {
               isValid: true,
          } : {
               isValid: false,
               errorMessage: 'Must be at least 2 characters, no space allow',
          }
     }

     const validationSchema = yup.object().shape({
          deal_name: yup.string().trim('Deal name cannot include space').strict(true).required('Enter deal name').max(50, 'Must be at most 50 characters'),
          deal_amount: yup.string().required('Enter deal amount'),
          deal_description: yup.string().required('Enter deal description').min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters')
               .test('validator-custom-length', function (value) {
                    const validation = lengthValidation(value);
                    if (!validation.isValid) {
                         return this.createError({
                              path: this.path,
                              message: validation.errorMessage,
                         });
                    }
                    else {
                         return true;
                    }
               }),
          deal_probability: yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter deal probability').positive().max('100', "Please enter less than 100%")
     })


     const dealDescriptionRef = useRef();
     const dealAmountRef = useRef();
     const dealProbabilityRef = useRef();


     const [dealDescriptionError, setDealDescriptionError] = useState(false);
     const [dealAmountError, setDealAmountError] = useState(false);
     const [dealProbabilityError, setDealProbabilityError] = useState(false);

     const [formDealDescriptionError, setFormDealDescriptionError] = useState(false);
     const [formDealAmountError, setFormDealAmountError] = useState(false);
     const [formDealProbabilityError, setFormDealProbabilityError] = useState(false);
     const [formDealClosingDateError, setFormDealClosingDateError] = useState(false);
     const [formDealProbabilityLimitError, setFormDealProbabilityLimitError] = useState();


     function validateDealFormData(type, value) {

          if (type === 'deal_description') {
               if (value === '') {
                    setFormDealDescriptionError(true);
                    setDealDescriptionError(true);
               } else {
                    setFormDealDescriptionError(false);
                    if (value != '') {
                         setDealDescriptionError(false);
                         setDealInput(dealInput.error_list.deal_description = '');
                    }
               }
          }

          if (type === 'deal_amount') {
               if (value === '') {
                    setFormDealAmountError(true);
                    setDealAmountError(true);
               } else {
                    setFormDealAmountError(false);
                    if (value != '') {
                         setDealAmountError(false);
                         setFormDealProbabilityLimitError('')
                         setDealInput(dealInput.error_list.deal_amount = '');
                    }
               }
          }

          if (type === 'deal_probability') {
               if (value === '') {
                    setFormDealProbabilityError(true);
                    setDealProbabilityError(true);
                    setFormDealProbabilityLimitError('')
               } else if (value != '' && value > 100) {
                    setDealProbabilityError(true);
                    setFormDealProbabilityLimitError('Please enter less than 100%')
               } else {
                    setFormDealProbabilityError(false);
                    if (value != '') {
                         setDealProbabilityError(false);
                         setDealInput(dealInput.error_list.deal_probability = '');
                    }
               }
          }
     }

     const handleSubmit = async (values) => {
          const data = {
               lead_id: leadID,
               deal_name: values.deal_name,
               deal_description: values.deal_description,
               deal_amount: values.deal_amount,
               deal_probability: values.deal_probability,
               lead_source: ContactDatalead_source,
               stage_id: ContactDatastage_id,
               expected_closing_date: moment(expectedClosingDate).format("YYYY-MM-DD"),
               latitude: getIpResponse.latitude ? getIpResponse.latitude : latitude,
               longitude: getIpResponse.longitude ? getIpResponse.longitude : longitude,
          }

          if (!expectedClosingDateError) {
               setConvertDeal(true);
               await axios.post("/api/deal/convert", data).then(response => {
                    if (response.status === 200) {
                         toast.success('Converted successfully.');
                         setDealInput({ deal_description: '', deal_amount: '', deal_probability: '', expected_closing_date: '', error_list: [] });
                         setdefaultValue({ deal_description: '', deal_amount: '', deal_probability: '', expected_closing_date: '', error_list: [] });
                         setShowConvertDeal(false);
                         setConvertDeal(false);
                    }
                    history.push('/deals');
               }).then(json => {

               }).catch(error => {
                    if (error.response) {
                         setConvertDeal(false);
                         if (error.response.status === 400) {
                              setDealInput({ ...dealInput, error_list: error.response.data.validation_errors });
                              setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
                              if (error.response.data.message.stage_id) {
                                   toast.warning(error.response.data.message.stage_id[0] ? 'Please select stage before convert in to deal' : 'Please select stage');
                              }
                         } else if (error.response.status === 300) {
                              toast.error(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden ' + error.response.data.message);
                         } else {
                              toast.error('Oops Something went wrong.');
                         }
                    }

               });
          }
     }


     //=======END:CODE FOR CONVERT DEAL=======//
     
     async function deleteLead(id) {
          const data = { id: id };
        
          try {
            const willDelete = await swal({
              title: "Are you sure you want to delete?",
              text: "Once deleted, you will not be able to recover this data!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
        
            if (willDelete) {
              try {
                const response = await axios.delete('api/lead/destroy', { data });
        
                if (response.status === 200) {
                  toast.success(response.data.message);
                  history.push('/leads');
                }
              } catch (error) {
                if (error.response) {
                  if (error.response.status === 400) {
                    toast.warning(error.response.data.message);
                  } else if (error.response.status === 403) {
                    toast.warning('Forbidden ' + error.response.data.message);
                  } else {
                    toast.error(error.response.data.message);
                  }
                }
              }
            }
          } catch (error) {
            toast.error("Error in swal: ", error);
          }
        }

     function showConvertModel() {
          setDealInput({
               deal_description: '',
               deal_amount: '',
               deal_probability: '',
               expected_closing_date: '',
               error_list: [],
          });

          setdefaultValue({
               deal_name: leadRecord.organization_name + '-deal',
               deal_description: '',
               deal_amount: '',
               deal_probability: '',
               expected_closing_date: '',
               error_list: [],
          })

          setFormDealAmountError(false)
          setFormDealDescriptionError(false)
          setFormDealProbabilityError(false)
          setShowConvertDeal(true)
          setFormDealProbabilityLimitError('')
     }


     const customStyles1 = {
          menuList: styles => ({
               ...styles,
               paddingTop: 0,
               paddingBottom: 0,
          }),
     }

     const CustomSelectDisplay = props => {

          const handleSelectCountrySave = (event) => {
               props.setParentValue(event.value);
          };
          setIsEditable(false)
          
          const countrysLists = props.data.countrysLists;
          const selectedCountry = props.data.selectedCountry;
          return (
               <Select
                    defaultValue={selectedCountry}
                    name="country_id"
                    options={countrysLists}
                    classNamePrefix="select"
                    onChange={handleSelectCountrySave}
                    theme={(theme) => ({
                         ...theme,
                         borderRadius: 0,
                         colors: {
                              ...theme.colors,
                              primary25: 'var(--primary-color-light)',
                              primary: 'var(--primary-color)',
                         },
                    })}
                    
               />
          );
     };

     const CustomSelectDisplayCountry = props => {
          const val = props;
          return (
               <div>
                    {val.value ? !isNaN(val.value) ? 'Please wait...' : val.value : 'Select...'}
               </div>
          );

     }


     const CustomLeadSelectDisplay = props => {
          const handleSelectLeadSave = (event) => {
               props.setParentValue(event.value);
          };
          setIsEditable(false)
          const leadLists = props.data.leadLists;
          const selectedLead = props.data.selectedLead;

          return (
               <Select
                    defaultValue={selectedLead}
                    name="lead_source"
                    options={leadLists}
                    classNamePrefix="select"
                    onChange={handleSelectLeadSave}
                    theme={(theme) => ({
                         ...theme,
                         borderRadius: 0,
                         colors: {
                              ...theme.colors,
                              primary25: 'var(--primary-color-light)',
                              primary: 'var(--primary-color)',
                         },
                    })}
               />
          );
     };

     const CustomSelectDisplayLead = props => {
          const val = props;
          return (
               <div>
                    {val.value ? val.value : 'Please select'}
               </div>
          );
     }


     const CustomSalutationSelectDisplay = props => {

          const handleSelectSalutationSave = (event) => {
               props.setParentValue(event.value);
          };
          setIsEditable(false)
          
          const salutationList = props.data.salutationList;
          const selectedSalutation = props.data.selectedSalutation;

          return (
               <Select
                    defaultValue={selectedSalutation}
                    name="salutation"
                    options={salutationList}
                    classNamePrefix="select"
                    onChange={handleSelectSalutationSave}
                    styles={customStyles1}
                    isClearable={false}
                    isSearchable={false}
                    theme={(theme) => ({
                         ...theme,
                         borderRadius: 0,
                         colors: {
                              ...theme.colors,
                              primary25: 'var(--primary-color-light)',
                              primary: 'var(--primary-color)',
                         },
                    })}
               />
          );
     };

     const CustomSelectDisplaySalutation = props => {
          const val = props;
          return (
               <div>
                    {val.value ? val.value : 'Select...'}
               </div>
          );
     }

     const handleDynamicFieldChange = (dynamicFieldData,type=null) => {
          const [key, value] = Object.entries(dynamicFieldData)[0];
          handleSaveData(value,key,type)
     }

     const HtmlTooltip = styled(({ className, ...props }) => (
          <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
          },
        }));

     if (loading) {
          return <Preloader show={loading ? false : true} />;
     } else {

          let countrysLists = [{ label: 'Select', value: '' }];
          for (var i = 0, l = countries.length; i < l; i++) {
               countrysLists.push({ label: countries[i].country_name, value: countries[i].id });
          }

          let selectedCountry = [];
          for (var i = 0, l = countries.length; i < l; i++) {
               if (ContactDataCountryId === countries[i].id) {
                    selectedCountry.push({ label: countries[i].country_name, value: countries[i].id });
               }
          }

          let stageLists = [];
          for (var i = 0, l = leadStageArray.length; i < l; i++) {
               stageLists.push({ label: leadStageArray[i].name, value: leadStageArray[i].id });
          }

          let selectedStage = [];
          for (var i = 0, l = leadStageArray.length; i < l; i++) {
               if (ContactDatastage_id === leadStageArray[i].id) {
                    selectedStage.push({ label: leadStageArray[i].name, value: leadStageArray[i].id });
               }
          }


          let productLists = [];
          for (var i = 0, l = productArray.length; i < l; i++) {
               productLists.push({ label: productArray[i].name, value: productArray[i].id });
          }

          let selectedProduct = [];
          for (var i = 0, l = productArray.length; i < l; i++) {
               if (ContactDataProduct.includes(productArray[i].id)) {
                    selectedProduct.push({ label: productArray[i].name, value: productArray[i].id });
               }

          }

          let selectedSalutation = [];
          Object.keys(salutation).map(function (key) {
               if (ContactDatasalutation === key) {
                    selectedSalutation.push({ label: salutation[key], value: key });
               }
          }, this);

          let salutationList = [{ label: 'Select...', value: '' }];
          Object.keys(salutation).map(function (key) {
               salutationList.push({ label: salutation[key], value: key });
          }, this);


          let selectedLeadSource = [];
          leadSourceArray.map((item) => {
          if (ContactDatalead_source === item.title) {
               selectedLeadSource.push({ label: item.title, value: item.title });
          }
          }, this);
          
          let leadSourceList = [];
          leadSourceArray.map((item) => {
               leadSourceList.push({ label: item.title, value: item.title });
          }, this);
          
          let FFName = ''
          let Fullname = ''

          if (leadRecord) {
               if (leadRecord.first_name && leadRecord.last_name) {
                    FFName = leadRecord.first_name.charAt(0) + leadRecord.last_name.charAt(0);
                    Fullname = leadRecord.first_name + ' ' + leadRecord.last_name;
               } else if (leadRecord.first_name && !leadRecord.last_name) {
                    FFName = leadRecord.first_name.charAt(0);
                    Fullname = leadRecord.first_name;
               } else if (!leadRecord.first_name && leadRecord.last_name) {
                    FFName = leadRecord.last_name.charAt(0);
                    Fullname = leadRecord.last_name;
               }
          }


          return (

               // <Row>
               <div className="display flex-wrap">
                    {/*--- client_information_section ---*/}
                    <Col md={12} className="client mt30">
                         {/*--- client_name_and_convert_lead_section ---*/}
                         <div className="client_details leaddetails display">
                              {/*--- client_image_and_deatils_section ---*/}
                              <div className="col-md-6 display">
                                   <div className="client_image display">
                                        <Stack direction="row" spacing={3}>
                                             <Avatar
                                                  alt={Fullname}
                                                  src={`${process.env.REACT_APP_API_URL}`}
                                                  sx={{ width: 50, height: 50 }}
                                                  onError={addDefaultSrc}
                                                  className="clientimg"
                                             >
                                                  {FFName}
                                             </Avatar>
                                        </Stack>
                                   </div>
                                   <div className="client_name ml20">
                                        <div className="display">
                                             <h5>{Fullname}</h5>
                                        </div>
                                        <div className="display">
                                             <span>Company:</span>
                                             <h6>{leadRecord.organization_name}</h6>
                                        </div>
                                   </div>
                              </div>
                              <div className="col-md-6 display justify-content-end">
                                   {/*--- client_update_time_and_date_section ---*/}
                                   <div className="client_update">
                                        <div className="client_txt display justify-content-end">
                                             <span>Last update</span>
                                        </div>
                                        <div className="client_date_and_time display justify-content-end">
                                             {/* <span>July, 03 2022 | 10:13am</span> */}
                                             <span>{moment.tz(leadRecord.updated_at, defaultTimeZone).format(preferredDateFormatData)}</span>
                                        </div>
                                   </div>
                                   {/*--- converted_button ---*/}

                                   {
                                        userPermission ?
                                             (userPermission.includes('lead_edit')) ?
                                                  <div className="converted_btn display">
                                                       <Dropdown>
                                                            <Dropdown.Toggle id="dropdown-basic" className="createbtn btn btn-primary display btndark" onClick={() => { showConvertModel() }}>
                                                                 Convert <span className="details_dollar_icon"></span>
                                                            </Dropdown.Toggle>
                                                       </Dropdown>
                                                  </div>
                                                  :
                                                  ''
                                             :
                                             ''
                                   }

                                   {/*--- client_action_section ---*/}
                                   <div className="client_action text-right">

                                        <Dropdown>
                                             <Dropdown.Toggle id="dropdown-basic" className=" dropdownborder1 dothover">
                                                  <span className="dot"></span>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                  {
                                                       userPermission ?
                                                            (userPermission.includes('lead_delete')) ?
                                                                 <Dropdown.Item onClick={() => { deleteLead(leadID) }}>Delete</Dropdown.Item>
                                                                 :
                                                                 ''
                                                            :
                                                            ''
                                                  }
                                                  {
                                                       userPermission ?
                                                            (userPermission.includes('lead_create')) ?
                                                                 <Dropdown.Item onClick={() => history.push('/clone-lead/' + leadID)}>Clone Lead</Dropdown.Item>
                                                                 :
                                                                 ''
                                                            :
                                                            ''
                                                  }


                                             </Dropdown.Menu>
                                        </Dropdown>
                                   </div>
                              </div>
                         </div>
                    </Col>

                    {/*--- breadcrmub_and_button_section ---*/}
                    <Col md={12} className="navigation mt20">
                         <div className="display">
                              <div className="col-md-6 display pl25">
                                   <h3>Current Lead Status:</h3>
                                   <span><h3>{displayStageName}</h3></span>
                              </div>
                              <div className="col-md-6 text-right display justify-content-end">

                                   <Select
                                        defaultValue={selectedStage}
                                        name="stage_id"
                                        options={stageLists}
                                        className="basic-multi-select mr20"
                                        classNamePrefix="select"
                                        onChange={handleSelectStageSave}
                                        styles={customStyles1}
                                        theme={(theme) => ({
                                             ...theme,
                                             borderRadius: 0,
                                             colors: {
                                                  ...theme.colors,
                                                  primary25: 'var(--primary-color-light)',
                                                  primary: 'var(--primary-color)',
                                             },
                                        })}
                                        isClearable={false}
                                        isSearchable={false}
                                   />
                                   <button type="button" className="btn btn-primary createbtn btnbluecolor" onClick={() => updateStageData(ContactDatastage_id)}>Set as a Current <span className="tickicon"></span></button>

                              </div>
                         </div>
                    </Col>

                    {/*--- Lead_information_section ---*/}
                    <Col md={12} className="mt30">
                         <div className="display w-100">
                              <Accordion defaultActiveKey="0" className="w-100">
                                   <Accordion.Item eventKey="0">
                                        <Accordion.Header><h4>Lead Information</h4></Accordion.Header>
                                        <Accordion.Body>
                                             <div className="display align-items-start flex-wrap">
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick leadheight">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Salutation</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           onSave={(value) => { handleSaveData(value, 'salutation') }}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           editComponent={
                                                                                <CustomSalutationSelectDisplay data={{ 'selectedSalutation': selectedSalutation, 'salutationList': salutationList }} />
                                                                           }
                                                                           value={selectedSalutation.length > 0 ? selectedSalutation[0].label : ''}
                                                                           displayComponent={<CustomSelectDisplaySalutation />}
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />



                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.salutation}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">First name</Form.Label>
                                                                      <EasyEdit
                                                                           className={'firstcoloum'}
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.first_name}
                                                                           onSave={(value) => { handleSaveData(value, 'first_name') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 50) {
                                                                                          setFirstNameErrorMsg('Allow 50 characters only');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setFirstNameErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setFirstNameErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     setFirstNameErrorMsg('Enter first name');
                                                                                     return false
                                                                                }
                                                                           }}
                                                                           validationMessage={firstNameErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50}}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}

                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.first_name}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Last name</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.last_name}
                                                                           onSave={(value) => { handleSaveData(value, 'last_name') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 50) {
                                                                                          setLastNameErrorMsg('Allow 50 characters only');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setLastNameErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setLastNameErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     setLastNameErrorMsg('Enter last name');
                                                                                     return false
                                                                                }
                                                                           }}
                                                                           validationMessage={lastNameErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.last_name}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Title</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.title}
                                                                           onSave={(value) => { handleSaveData(value, 'title') }}
                                                                           onValidate={value => {
                                                                                var splString = value.split(' ');
                                                                                if (value && value.length > 50) {
                                                                                     setTitleErrorMsg('Allow 50 characters only')
                                                                                     return false
                                                                                } else if (value.length < 2) {
                                                                                     setTitleErrorMsg('Enter at least 2 characters');
                                                                                     return false
                                                                                } else if (value.length === 2 && splString.length >= 2) {
                                                                                     setTitleErrorMsg('Enter at least 2 characters, space not allow');
                                                                                     return false
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={titleErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50}}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.title}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Email</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.primary_email}
                                                                           onSave={(value) => { handleSaveData(value, 'primary_email') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 50) {
                                                                                          setprimaryEmailErrorMsg('Allow 50 characters only');
                                                                                          return false
                                                                                     } else if (value && !(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value))) {
                                                                                          setprimaryEmailErrorMsg('Please enter valid email address');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setprimaryEmailErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setprimaryEmailErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={primaryEmailErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50}}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.primary_email}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Mobile</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.phone1}
                                                                           onSave={(value) => { handleSaveData(value, 'phone1') }}
                                                                           onValidate={value => {
                                                                                if (value && value.length > 25) {
                                                                                     setPhone1ErrorMsg('Allow 25 characters only')
                                                                                     return false
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}

                                                                           validationMessage={phone1ErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 15 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.phone1}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick position-relative leadlist">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Source</Form.Label>

                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           onSave={(value) => { handleSaveData(value, 'lead_source') }}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           editComponent={
                                                                                <CustomLeadSelectDisplay data={{ 'selectedLead': selectedLeadSource, 'leadLists': leadSourceList }} />
                                                                           }
                                                                           value={selectedLeadSource.length > 0 ? selectedLeadSource[0].label : ''}
                                                                           displayComponent={<CustomSelectDisplayLead />}
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                           placeholder='----'
                                                                      />

                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.lead_source}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick ">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Department</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.department}
                                                                           onSave={(value) => { handleSaveData(value, 'department') }}
                                                                           onValidate={value => {
                                                                                var splString = value.split(' ');
                                                                                if (value && value.length > 50) {
                                                                                     setDepartmentErrorMsg('Allow 50 characters only')
                                                                                     return false
                                                                                } else if (value.length < 2) {
                                                                                     setDepartmentErrorMsg('Enter at least 2 characters');
                                                                                     return false
                                                                                } else if (value.length === 2 && splString.length >= 2) {
                                                                                     setDepartmentErrorMsg('Enter at least 2 characters, space not allow');
                                                                                     return false
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={departmentErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 50}}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.department}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick circletrick1">
                                                                 <Form.Group className="mb-2 display align-items-start">
                                                                      <Form.Label className="text-right w-24 mt8">Product interested</Form.Label>

                                                                      <Select
                                                                           defaultValue={selectedProduct}
                                                                           isMulti
                                                                           name="product_id"
                                                                           options={productLists}
                                                                           className="basic-multi-select producttxt"
                                                                           classNamePrefix="select"
                                                                           onChange={handleSelectProductSave}
                                                                           styles={customStyles1}
                                                                           theme={(theme) => ({
                                                                                ...theme,
                                                                                borderRadius: 0,
                                                                                colors: {
                                                                                     ...theme.colors,
                                                                                     primary25: 'var(--primary-color-light)',
                                                                                     primary: 'var(--primary-color)',
                                                                                },
                                                                           })}
                                                                           placeholder='----'
                                                                           isDisabled={isEditableProduct}
                                                                           onFocus={() => handleClick('product')}
                                                                           onBlur={() => handleProductBlurClick('product')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.product_id}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick description-input position-relative">
                                                                 <Form.Group className="display align-items-start">
                                                                      <Form.Label className="text-right w-17 mt-1">Description</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXTAREA}
                                                                           value={leadRecord.description}
                                                                           onSave={(value) => { handleSaveData(value, 'description') }}
                                                                           onValidate={value => {
                                                                                var splString = value.split(' ');
                                                                                if (value && value.length > 150) {
                                                                                     setDescriptionErrorMsg('Allow 150 characters only')
                                                                                     return false
                                                                                } else if (value.length < 2) {
                                                                                     setDescriptionErrorMsg('Enter at least 2 characters');
                                                                                     return false
                                                                                } else if (value.length === 2 && splString.length >= 2) {
                                                                                     setDescriptionErrorMsg('Enter at least 2 characters, space not allow');
                                                                                     return false
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={descriptionErrorMsg}
                                                                           onCancel={cancel}
                                                                           cssClassPrefix="custom-textarea-"
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 150 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.description}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Company name</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.organization_name}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_name') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 50) {
                                                                                          setOrgNameErrorMsg('Allow 50 characters only');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setOrgNameErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setOrgNameErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     setOrgNameErrorMsg('Enter organization name');
                                                                                     return false
                                                                                }
                                                                           }}
                                                                           validationMessage={orgNameErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.organization_name}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Company email</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.organization_email}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_email') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 50) {
                                                                                          setOrgEmailErrorMsg('Allow 50 characters only');
                                                                                          return false
                                                                                     } else if (value && !(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value))) {
                                                                                          setOrgEmailErrorMsg('Please enter valid email address');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setOrgEmailErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setOrgEmailErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={orgEmailErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.organization_email}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Company phone</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.organization_phone1}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_phone1') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     if (value && value.length > 25) {
                                                                                          setOrgPhone1ErrorMsg('Allow 25 characters only')
                                                                                          return false
                                                                                     } else {
                                                                                          return true
                                                                                     }

                                                                                } else {
                                                                                     setOrgPhone1ErrorMsg('Enter Organization phone');
                                                                                     return false
                                                                                }


                                                                           }}
                                                                           validationMessage={orgPhone1ErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 15 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.organization_phone1}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">
                                                                      <HtmlTooltip
                                                                           title={
                                                                                <React.Fragment>
                                                                                     <em><a href={leadRecord.organization_website} rel="noopener" target="_blank">{leadRecord.organization_website}</a></em>
                                                                                </React.Fragment>
                                                                           }
                                                                           >
                                                                                Company website
                                                                       </HtmlTooltip>  
                                                                       </Form.Label>  
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.organization_website}
                                                                           onSave={(value) => { handleSaveData(value, 'organization_website') }}
                                                                           onValidate={value => {
                                                                                var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 50) {
                                                                                          setOrgWebsiteErrorMsg('Allow 50 characters only');
                                                                                          return false
                                                                                     } else if (value && !(httpRegex.test(value))) {
                                                                                          setOrgWebsiteErrorMsg('Please enter valid website');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setOrgWebsiteErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setOrgWebsiteErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }

                                                                           }}
                                                                           validationMessage={orgWebsiteErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                      
                                                                 </Form.Group>
                                                                
                                                                 <span className="error">{ErrorInput.error_list.organization_website}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Facebook</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.facebook_url}
                                                                           onSave={(value) => { handleSaveData(value, 'facebook_url') }}
                                                                           onValidate={value => {
                                                                                const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 255) {
                                                                                          setFacebookErrorMsg('Allow 255 characters only');
                                                                                          return false
                                                                                     } else if (value && !(regex.test(value))) {
                                                                                          setFacebookErrorMsg('Please enter valid url');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setFacebookErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setFacebookErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }


                                                                           }}
                                                                           validationMessage={facebookErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 255 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.facebook_url}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Linkedin</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.linkedin_url}
                                                                           onSave={(value) => { handleSaveData(value, 'linkedin_url') }}
                                                                           onValidate={value => {
                                                                                const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 255) {
                                                                                          setLinkedinErrorMsg('Allow 255 characters only');
                                                                                          return false
                                                                                     } else if (value && !(regex.test(value))) {
                                                                                          setLinkedinErrorMsg('Please enter valid url');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setLinkedinErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setLinkedinErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={linkedinErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 255}}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.linkedin_url}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Instagram</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.instagram_url}
                                                                           onSave={(value) => { handleSaveData(value, 'instagram_url') }}
                                                                           onValidate={value => {
                                                                                const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 255) {
                                                                                          setInstagramErrorMsg('Allow 255 characters only');
                                                                                          return false
                                                                                     } else if (value && !(regex.test(value))) {
                                                                                          setInstagramErrorMsg('Please enter valid url');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setInstagramErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setInstagramErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={instagramErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 255 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.instagram_url}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Twitter</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.twitter_url}
                                                                           onSave={(value) => { handleSaveData(value, 'twitter_url') }}
                                                                           onValidate={value => {
                                                                                const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 255) {
                                                                                          setTwitterErrorMsg('Allow 255 characters only');
                                                                                          return false
                                                                                     } else if (value && !(regex.test(value))) {
                                                                                          setTwitterErrorMsg('Please enter valid url');
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setTwitterErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setTwitterErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={twitterErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 255 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.twitter_url}</span>
                                                            </div>
                                                            <div className="col-md-12"></div>
                                                       </div>
                                                  </div>
                                                  <div className="col-md-6 circletrick">    
                                                       <div className="display align-items-start flex-wrap">                     
                                                            <EditCustomFields onDynamicFieldChange={handleDynamicFieldChange} dynamicFields={leadRecord} handleClick={handleClick} cancel={cancel} displayAt='both' displayFieldType='both' errorInput={ErrorInput}/>                         
                                                       </div>
                                                  </div>
                                             </div>

                                             <div className="display align-items-start flex-wrap mb25">
                                                  <div className="col-md-12">
                                                       <h4>Address Information</h4>
                                                  </div>
                                             </div>

                                             <div className="display align-items-start flex-wrap">
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick description-input position-relative">
                                                                 <Form.Group className="mb-2 display align-items-start">
                                                                      <Form.Label className="text-right w-17 mt-1">Address</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXTAREA}
                                                                           value={leadRecord.address1}
                                                                           onSave={(value) => { handleSaveData(value, 'address1') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 100) {
                                                                                          setAddress1ErrorMsg('Allow 100 characters only')
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setAddress1ErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setAddress1ErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={address1ErrorMsg}
                                                                           onCancel={cancel}
                                                                           cssClassPrefix="custom-textarea-"
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 100 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.address1}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">City</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.city}
                                                                           onSave={(value) => { handleSaveData(value, 'city') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 50) {
                                                                                          setCityErrorMsg('Allow 50 characters only')
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setCityErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setCityErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={cityErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.city}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="display mb-2">
                                                                      <Form.Label className="text-right w-17">Zip code</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.zipcode}
                                                                           onSave={(value) => { handleSaveData(value, 'zipcode') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 10) {
                                                                                          setZipcodeErrorMsg('Allow 10 characters only')
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setZipcodeErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setZipcodeErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={zipcodeErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 10 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.zipcode}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick description-input position-relative">
                                                                 <Form.Group className="mb-2 display align-items-start">
                                                                      <Form.Label className="text-right w-17 mt-1">Address2</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXTAREA}
                                                                           value={leadRecord.address2}
                                                                           onSave={(value) => { handleSaveData(value, 'address2') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 100) {
                                                                                          setAddress2ErrorMsg('Allow 100 characters only')
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setAddress2ErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setAddress2ErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={address2ErrorMsg}
                                                                           onCancel={cancel}
                                                                           cssClassPrefix="custom-textarea-"
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 100}}
                                                                           allowEdit={isEditable}
                                                                           placeholder='----'
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.address2}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">State</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={leadRecord.state}
                                                                           onSave={(value) => { handleSaveData(value, 'state') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 50) {
                                                                                          setStateErrorMsg('Allow 50 characters only')
                                                                                          return false
                                                                                     } else if (value.length < 2) {
                                                                                          setStateErrorMsg('Enter at least 2 characters');
                                                                                          return false
                                                                                     } else if (value.length === 2 && splString.length >= 2) {
                                                                                          setStateErrorMsg('Enter at least 2 characters, space not allow');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={stateErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.state}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick leadheight">
                                                                 <Form.Group className="display mb-2">
                                                                      <Form.Label className="text-right w-17">Country</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           onSave={(value) => { handleSaveData(value, 'country_id') }}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           editComponent={
                                                                                <CustomSelectDisplay data={{ 'selectedCountry': selectedCountry, 'countrysLists': countrysLists }} />
                                                                           }
                                                                           value={selectedCountry.length > 0 ? selectedCountry[0].label : ''}
                                                                           displayComponent={<CustomSelectDisplayCountry />}
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                           placeholder='----'
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.country_id}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </Accordion.Body>
                                   </Accordion.Item>
                              </Accordion>
                         </div>
                    </Col>

                    {/*--- Notes_and_activity_section ---*/}
                    <Col md={12} className="mt30">
                         <div className="row align-items-start">
                              <NoteList data={{ 'type': 'lead', 'leadId': leadID, 'preferredDateFormatData': preferredDateFormatData }} />
                              {/*--- Activity_section ---*/}
                              {
                                   userPermission ?
                                        (userPermission.includes('event_list', 'task_list')) ?
                                             <ActivityList data={{ 'type': 'lead', 'leadId': leadID, 'preferredDateFormatData': preferredDateFormatData, 'preDateFormate': preDateFormate, 'calendorDateFormate': displayDateFormate, 'calendorFullDateFormate': calendorFullDateFormate, 'latitude':latitude, 'longitude':longitude }} />
                                             :
                                             ''
                                        :
                                        ''
                              }

                         </div>
                    </Col>

                    <Modal as={Modal.Dialog} centered show={showConvertDeal} onHide={handleCovertClose}>
                         <Modal.Header>
                              <Modal.Title className="h2">Convert your lead to deal</Modal.Title>
                              <Button variant="close" aria-label="Close" onClick={handleCovertClose} /><span className="modelclose"></span>
                         </Modal.Header>
                         <Modal.Body>
                              <Formik
                                   initialValues={defaultValue}
                                   validationSchema={validationSchema}
                                   onSubmit={(values, { setSubmitting }) => {
                                        handleSubmit(values, setSubmitting(false));
                                   }}
                              >{props => {
                                   const {
                                        values,
                                        touched,
                                        errors,
                                        //dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        //handleReset,
                                        //setFieldValue,
                                        //setFieldTouched,
                                   } = props;
                                   return (
                                        <Form encrypted="multipart/form-data" onSubmit={handleSubmit} >
                                             <Row>
                                                  <div className="col-md-12 col-sm-12">
                                                       <Row className="align-items-center">

                                                            <Form.Group className="mb-125 position-relative">
                                                                 <Form.Label column sm="12">
                                                                      <h6 className="fw600">Contact person</h6>
                                                                 </Form.Label>
                                                                 <Col sm="12">
                                                                      <Form.Group id="name">
                                                                           <Form.Control type="text" readOnly disabled value={leadRecord.first_name + ' ' + leadRecord.last_name} />
                                                                      </Form.Group>
                                                                 </Col>
                                                            </Form.Group>
                                                            <Form.Group className="mb-125 position-relative">
                                                                 <Form.Label column sm="12">
                                                                      <h6 className="fw600">Company name</h6>
                                                                 </Form.Label>
                                                                 <Col sm="12">
                                                                      <Form.Group id="company_name">
                                                                           <Form.Control type="text" readOnly disabled value={leadRecord.organization_name} />
                                                                      </Form.Group>
                                                                 </Col>
                                                            </Form.Group>
                                                            <Form.Group className="mb-125 position-relative">
                                                                 <Form.Label column sm="12">
                                                                      <h6 className="fw600">Deal name<span className="text-danger">*</span></h6>
                                                                 </Form.Label>
                                                                 <Col sm="12">
                                                                      <Form.Group id="deal_name">
                                                                           <Form.Control type="text" name="deal_name" value={values.deal_name} minLength={2} maxLength={55} onChange={handleChange} onBlur={handleBlur} placeholder="Enter deal name" className={errors.deal_name && touched.deal_name ?
                                                                                "input-error" : null} />
                                                                           <span className="error error4">{defaultValue.error_list.deal_name}</span>
                                                                           <span className="error error4">
                                                                                <ErrorMessage name="deal_name" />
                                                                           </span>
                                                                      </Form.Group>
                                                                 </Col>
                                                            </Form.Group>
                                                            <Form.Group className="mb-125 position-relative ">
                                                                 <Form.Label column sm="12">
                                                                      <h6 className="fw600">Amount {preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode + ' ' : ' '}
                                                                           ({preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : ''})<span className="text-danger">*</span></h6>
                                                                 </Form.Label>
                                                                 <Col sm="12">
                                                                      <Form.Group id="deal_amount">
                                                                           <CurrencyInput
                                                                                id="deal_amount"
                                                                                name="deal_amount"
                                                                                intlConfig={{
                                                                                     locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                                                                                     currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                                                                                }}
                                                                                placeholder="Enter deal amount"
                                                                                className={errors.deal_amount && touched.deal_amount ?
                                                                                     "form-control input-error" : 'form-control'}
                                                                                onValueChange={(value, name) => {
                                                                                     setdefaultValue({ ...defaultValue, ['deal_amount']: value });
                                                                                     values.deal_amount = value;
                                                                                     handleChange(name)
                                                                                }}
                                                                                decimalsLimit={6}
                                                                                value={defaultValue.deal_amount}
                                                                                step={1}
                                                                           />
                                                                           <span className="error error4">{defaultValue.error_list.deal_amount}</span>
                                                                           <span className="error error4">
                                                                                <ErrorMessage name="deal_amount" />
                                                                           </span>
                                                                      </Form.Group>
                                                                 </Col>
                                                            </Form.Group>
                                                            <Form.Group className="mb-125 position-relative">
                                                                 <Form.Label column sm="12">
                                                                      <h6 className="fw600">Probability (%)<span className="text-danger">*</span></h6>
                                                                 </Form.Label>
                                                                 <Col sm="12">
                                                                      <Form.Group id="deal_probability">
                                                                           <Form.Control type="number" max={100} step={0.01} name="deal_probability" onChange={handleChange} onBlur={handleBlur} placeholder="Enter probability" className={errors.deal_probability && touched.deal_probability ?
                                                                                "proability errormessage input-error" : 'proability errormessage'} />
                                                                           <span className="error error4">{defaultValue.error_list.deal_probability}</span>
                                                                           <span class="percetangeicon"></span>
                                                                           <span className="error error4">
                                                                                <ErrorMessage name="deal_probability" />
                                                                           </span>
                                                                      </Form.Group>

                                                                 </Col>
                                                            </Form.Group>
                                                            <Form.Group className="mb-125 position-relative">
                                                                 <Form.Label column sm="12">
                                                                      <h6 className="fw600">Expected closing date</h6>
                                                                 </Form.Label>
                                                                 <Col sm="12">
                                                                      <Form.Group id="expected_closing_date">
                                                                           <DatePicker
                                                                                autocomplete="off"
                                                                                selected={expectedClosingDate == "" ? new Date() : !expectedClosingDateError ? expectedClosingDate : ''}
                                                                                onChange={(date) => {
                                                                                     if (date) {
                                                                                          setExpectedClosingDate(date)
                                                                                          setExpectedClosingDateError(false)
                                                                                     } else {
                                                                                          setExpectedClosingDateError(true)
                                                                                     }
                                                                                }}
                                                                                dateFormat={calendorDateFormate}
                                                                                className="form-control"
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="calenderinput"></span>
                                                                      <span className="error error4">{defaultValue.error_list.expected_closing_date}</span>
                                                                      {/* {formDealClosingDateError ? <span className="error error4">Enter deal closing date.</span> : null} */}
                                                                      <span className="error error4">
                                                                           {expectedClosingDateError ? 'Enter expected closing date' : ''}
                                                                      </span>
                                                                 </Col>
                                                            </Form.Group>
                                                            <Form.Group className="position-relative">
                                                                 <Form.Label column sm="12">
                                                                      <h6 className="fw600">Description<span className="text-danger">*</span></h6>
                                                                 </Form.Label>
                                                                 <Col sm="12">
                                                                      <Form.Group id="deal_description">
                                                                           <Form.Control as="textarea" minLength={2} maxLength={150} name="deal_description" onChange={handleChange} onBlur={handleBlur} placeholder="Enter description" className={errors.deal_description && touched.deal_description ?
                                                                                "input-error" : null} />
                                                                           <span className="error error4">{defaultValue.error_list.deal_description}</span>
                                                                           <span className="error error4">
                                                                                <ErrorMessage name="deal_description" />
                                                                           </span>
                                                                      </Form.Group>

                                                                 </Col>
                                                            </Form.Group>
                                                       </Row>
                                                  </div>
                                             </Row>


                                             <div className="text-center mt30">
                                                  {
                                                       isSubmitting ?

                                                            <Button className="d-flex align-items-center popupbtn btnblue justify-content-center text-center btnblue mb-1">
                                                                 <Spinner animation="border" size="sm" />
                                                                 <span className="ms-2 text-center">Please wait...</span>
                                                            </Button>
                                                            :

                                                            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                                 <div className="btn-group mr-2" role="group" aria-label="First group">
                                                                      <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                                                                 </div>
                                                                 <div className="btn-group mr-2" role="group" aria-label="First group">
                                                                      <Button type="button" variant="success" onClick={handleCovertClose} className="popupbtn greycolor d-flex align-items-center justify-content-center">Cancel</Button>
                                                                 </div>
                                                            </div>
                                                  }
                                             </div>
                                        </Form>
                                   );
                              }}
                              </Formik>
                         </Modal.Body>

                    </Modal>
               </div>
          );

     }


};

export default EditLead;
