/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table, Button, Dropdown,DropdownButton, Card, Form, Image, Spinner, InputGroup, ButtonGroup, Modal} from '@themesberg/react-bootstrap';
import { useDispatch,useSelector } from "react-redux";
import { ProductAddPopUp } from "../features/userSlice"
import {  toast } from 'react-toastify';
import { useHistory, } from 'react-router-dom';
import Preloader from "./Preloader";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';


export default () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  const showAddProduct = useSelector((state) => state.productAddPopUp);
  const handleAccountClose = () => dispatch(ProductAddPopUp(false));

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [productImageArray, setProductImageArray] = useState([]);

  const [defaultValue, setdefaultValue] = useState({
      name: '',
      product_code: '',
      tax: '',
      price: '',
      file_url:'',
      description:'',
      error_list: [],
  })

  useEffect(() => {
    async function fetchData() {
      await getCreateData();
    }
    fetchData();
  }, [showAddProduct]); 


  async function getCreateData() {
    await axios.get('api/product/create').then(response => {
      if (response.status === 200) {
        setPreferredCurrencyData(response.data.data.preferredCurrency);
        setdefaultValue({ ...defaultValue, name: '', product_code: '', tax: '', price: '', file_url:'', description:'', error_list: [] });
        setLoading(false);
      }
    }).then(() => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        //setdefaultValue({ ...defaultValue, error_list: '' });
        if (error.response.status === 400) {
          toast.warning(error.response.data.message);
        } else if (error.response.status === 300) {
          toast.error(error.response.data.message);
        }else if (error.response.status === 403) {
          toast.warning('Forbidden ' + error.response.data.message);
        } else {
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }

  const lengthValidation = (fieldLength) => {
    var valid = true;
    if(fieldLength){
      var str = fieldLength.replace(/\s/g,'')
      if(str.length<2){
          valid = false;
      }
    }
    
    return valid ? {
      isValid: true,
    } : {
      isValid: false,
      errorMessage: 'Must be at least 2 characters, no space allow',
    }
  }
  

  const validationSchema = yup.object().shape({
    name:yup.string()
            .trim('Name cannot include space').strict(true)
            .required('Enter product name')
            .min(2, 'Must be at least 2 characters')
            .max(50, 'Must be at most 50 characters'),
    product_code:yup.string()
            .trim('Code cannot include space').strict(true)
            .required('Enter product code')
            .min(2, 'Must be at least 2 characters')
            .max(15, 'Must be at most 15 characters'),
    //tax:yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter product tax').positive().min(1, 'Must be at least 2 characters'),
    // tax:yup.number('Only numeric value allow')
    //         .typeError('The value must be a number')
    //         .integer('The value must be a number')
    //         .positive('The value must be a positive')
    //         .required('Enter product tax'),
    // tax: yup.string().number('Only numeric value allow')
    //         .transform((value) => (isNaN(value) ? undefined : value))
    //         .required('Enter tax value').positive('Enter numeric value only')
    //         .min('0', "Please enter tax"),
    //         //.max('100', "Please enter less than 100%"),
    tax: yup.number()
    .typeError('Tax must be a number')
    .min(0, 'Tax cannot be negative')
    .integer('Tax must be an integer')
    .required('Tax is required'),
    price:yup.string()
            .required('Enter product price')
            .min(1, 'Must be at least 1 characters')
            .max(10, 'Must be at most 10 characters'),
    description:yup.string().min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters')
    .test('validator-custom-length', function (value) {
      const validation = lengthValidation(value);
      if (!validation.isValid) {
        return this.createError({
          path: this.path,
          message: validation.errorMessage,
        });
      }
      else {
        return true;
      }
    }),
    file_url:yup.mixed().nullable(),
    
  })

  
  const handleSubmit = (values,onSubmitProps) =>{
    
    const formData = new FormData();
    formData.append('name', values.name)
    formData.append('description', values.description)
    formData.append('product_code', values.product_code)
    formData.append('price', values.price)
    formData.append('tax', values.tax)
    formData.append('file_url', values.file_url[0] ? values.file_url[0] : '')

    if(productImageArray){
        //formData.append('file_url[]', productImageArray.file_url[0])
    }
    
    axios.post("/api/product/store", formData).then(response => {
      if (response.status === 200) {
        dispatch(ProductAddPopUp(false))
        toast.success('Product created successfully');
        history.push('/products');
      }
    }).then(() => {

    }).catch(error => {
      if (error.response) {
        onSubmitProps.setSubmitting(false)
        if (error.response.status === 400) {
          setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
        } else if(error.response.status === 403){
          toast.warning('Forbidden '+error.response.data.message);
          history.push('/products');    
        } else {
          toast.warning(error.response.data.message);
        }
      }

    });

  }

  
  
  return (
      <Modal as={Modal.Dialog} centered show={showAddProduct} onHide={handleAccountClose}>
              <Modal.Header>
                <Modal.Title className="h2">Add Product</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleAccountClose} /><span className="modelclose"></span>
              </Modal.Header>
              <Modal.Body>
              <Formik 
                    initialValues={defaultValue} 
                    validationSchema={validationSchema} 
                    onSubmit={handleSubmit}
                    // validate={(values) => {
                    //     let errors = {};  
                    //     if(values.price!='')
                    //       return errors.price='';
                    //   }
                    // }
                  >{props => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    } = props;
                    return (
                <Form encrypted="multipart/form-data" onSubmit={handleSubmit} >
                  <Row>
                    <div className="col-md-12 col-sm-12">
                      <Row className="align-items-center">
                      
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Name<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="name">
                              <Form.Control type="text" name="name" minLength={2} maxLength={50} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Product name" className={errors.name && touched.name ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.name}</span>
                              <span className="error error4">
                                <ErrorMessage name="name"/>
                              </span>
                            </Form.Group>
                            
                          </Col>
                        </Form.Group>
                        
                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Product code<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="product_code">
                              <Form.Control type="text" maxLength={15} name="product_code" onChange={handleChange} onBlur={handleBlur} placeholder="Enter product code" className={errors.product_code && touched.product_code ? 
                    "input-error" : null}/>
                              <span className="error error4">{defaultValue.error_list.product_code}</span>
                              <span className="error error4">
                                <ErrorMessage name="product_code"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Image</h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="file_url">
                              {/* <Form.Control style={{'display':'block'}} type="file" accept="image/*" name="file_url" onChange={handleImageInput} /> */}
                              <Form.Control style={{'display':'block'}} type="file" accept="image/*" name="file_url" onChange={(event) => {
                                  setFieldValue("file_url", event.target.files);
                                  setdefaultValue({ ...defaultValue, error_list:[] });
                                }} />
                              <small>Allow file type are : jpg, jpeg, png, gif</small>
                              <span className="error5 error">{defaultValue.error_list.file_url}</span>
                              <span className="error error4">
                                <ErrorMessage name="file_url"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Description</h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="description">
                              <Form.Control as="textarea" maxLength={150} name="description" onChange={handleChange} onBlur={handleBlur} placeholder="Enter Product Description" />
                              <span className="error error4">{defaultValue.error_list.description}</span>
                              <span className="error error4">
                                <ErrorMessage name="description"/>
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Price&nbsp;{preferredCurrencyData.currencyCode != '' ? ' ' + preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                            ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="price">
                            <CurrencyInput
                                id="price"
                                name="price"
                                intlConfig={{
                                  locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                                  currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                                }}
                                placeholder="Enter price"
                                className={errors.price && touched.price ? 
                                  "form-control input-error" : 'form-control'}
                                //onValueChange={handleOnValueChange}
                                onValueChange={(value, name) => {
                                    //removeStyleButtonOption()
                                    setdefaultValue({ ...defaultValue, 'price': value });
                                    values.price = value;
                                    handleChange(name);
                                }}
                                onBlur={handleBlur}
                                decimalsLimit={6}
                                value={defaultValue.price}
                                step={1}
                              />
                              <span className="error error4">{defaultValue.error_list.price}</span>
                              <span className="error error4">
                                <ErrorMessage name="price"/>
                                {/* <ErrorMessage name="price" render={msg => <div>{msg}</div>} /> */}
                              </span>
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        <Form.Group className="mb-125 position-relative">
                          <Form.Label column sm="12">
                            <h6 className="fw600">Tax<span className="text-danger">*</span></h6>
                          </Form.Label>
                          <Col sm="12">
                            <Form.Group id="tax">
                              <Form.Control type="text" name="tax" onChange={handleChange} onBlur={handleBlur} placeholder="Enter tax" className={errors.tax && touched.tax ? 
                    "input-error" : null}/>

                              <span className="error error4">{defaultValue.error_list.tax}</span>
                              <span className="error error4">
                                <ErrorMessage name="tax"/>
                              </span>
                            </Form.Group>
                            <span style={{display:touched.tax && errors.tax ? 'none':'block'}}>Note: if you don't want to add Tax than simply add zero</span>
                          </Col>
                        </Form.Group>
                        
                      </Row>
                    </div>
                  </Row>


                  <div className="text-center mt30">
                  {
                    isSubmitting ? 

                      <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2 text-center">Please wait...</span>
                      </Button>
                      :

                      <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="submit" variant="success" className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center">Create</Button>
                        </div>
                        <div className="btn-group mr-2" role="group" aria-label="First group">
                          <Button type="button" variant="success" onClick={handleAccountClose} className="popupbtn greycolor d-flex align-items-center justify-content-center">Cancel</Button>
                        </div>
                      </div>
                  }
                </div>
                </Form>
                );
              }}
                </Formik>
              </Modal.Body>

      </Modal>
    );


};
