import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Button, Form, Spinner, InputGroup } from '@themesberg/react-bootstrap';
import moment from "moment-timezone";
import Accordion from 'react-bootstrap/Accordion';
import "react-datepicker/dist/react-datepicker.css";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import righticon from '../../assets/img/righticon-three-coloum.png';
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';
import Preloader from "../../components/PreloaderCenter";
import { useDispatch, useSelector } from "react-redux";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { login, selectUser,selectDefaultData } from "../../features/userSlice";
import Select from 'react-select'
import validator from 'validator'
import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import EasyEdit, { Types } from "react-easy-edit";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

var errorImage = 0;
var calendorFormate = '';
var currencyCode = '';
var currencySymbol = '';
var currentPackageName = ''

function UserProfile(props) {
     const history = useHistory();
     const dispatch = useDispatch();

     const loginUser = useSelector(selectUser);
     const [isEditable, setIsEditable] = useState(true)
     const [isSubmittingPassword, setIsSubmittingPassword] = useState(false)

     const [passwordShown, setPasswordShown] = useState(false);
     const [conPasswordShown, setConPasswordShown] = useState(false);

     const user = loginUser?loginUser:[];

     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = defaultData.defaultTimeZone
   
     if (user) {
       if (user.preferred_timezone) {
         defaultTimeZone = user.preferred_timezone;
       }
     }

     const [loading, setLoading] = useState(true);
     const [dataList, setDataList] = useState([]);
     const [dataRecord, setDataRecord] = useState('');
     const [UserDataEmail, setUserDataEmail] = useState('');
     const [UserDataName, setUserDataName] = useState('');
     const [UserDataSecondryEmail, setUserDataSecondryEmail] = useState('');
     const [UserDataProfileImage, setUserDataProfileImage] = useState('');
     const [UserDataAddress1, setUserDataAddress1] = useState('');
     const [UserDataAddress2, setUserDataAddress2] = useState('');
     const [UserDataCity, setUserDataCity] = useState('');
     const [UserDataState, setUserDataState] = useState('');
     const [UserDataCountryId, setDataCountryId] = useState('');
     const [UserDataZipcode, setUserDataZipcode] = useState('');
     const [UserDataPhone1, setUserDataPhone1] = useState('');
     const [UserDataPhone2, setUserDataPhone2] = useState('');

     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     const [nameErrorMsg, setNameErrorMsg] = useState('');
     const [secondryEmailErrorMsg, setSecondryEmailErrorMsg] = useState('');
     const [address1ValidationErrorMsg, setAddress1ValidationErrorMsg] = useState('');
     const [address2ValidationErrorMsg, setAddress2ValidationErrorMsg] = useState('');
     const [phone1ValidationErrorMsg, setPhone1ValidationErrorMsg] = useState('');
     const [phone2ValidationErrorMsg, setPhone2ValidationErrorMsg] = useState('');
     const [cityValidationErrorMsg, setCityValidationErrorMsg] = useState('');
     const [stateValidationErrorMsg, setStateValidationErrorMsg] = useState('');
     const [zipCodeValidationErrorMsg, setZipCodeValidationErrorMsg] = useState('');
     
     const [updateDealData, setUpdateDealData] = useState(false);

     const [countries, setCountry] = useState([]);
     const [ddCountryName, setDDCountryName] = useState('');

     const [UserPasswordInput, setUserPasswordInput] = useState({
          old_password: '',
          password: '',
          password_confirm: '',
          error_list: [],
     });

     const oldPasswordRef = useRef();
     const passwordRef = useRef();
     const confirmPasswordRef = useRef();

     const [formOldPasswordError, setFormOldPasswordError] = useState(false);
     const [formpasswordError, setFormPasswordError] = useState(false);
     const [formconfirmPasswordError, setFormConfirmPasswordError] = useState(false);

     const [oldPasswordError, setOldPasswordError] = useState(false);
     const [passwordError, setPasswordError] = useState(false);
     const [confirmPasswordError, setConfirmPasswordError] = useState(false);

     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
     const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');


     useEffect(() => {
          async function fetchData() {
               await getData();
          }
          fetchData();
     }, [updateDealData]);


     const getData = async() =>{
          await axios.get('api/profile').then(response => {
               if (response.data.code === 200) {
                    setDataList(response.data.data)
                    setDataRecord(response.data.data.record)
                    setUserDataName(response.data.data.record.name);
                    setUserDataEmail(response.data.data.record.email);
                    setUserDataSecondryEmail(response.data.data.record.secondry_email);
                    setUserDataProfileImage(response.data.data.record.profile_image);
                    setUserDataAddress1(response.data.data.record.address1);
                    setUserDataAddress2(response.data.data.record.address2);
                    setUserDataCity(response.data.data.record.city);
                    setUserDataState(response.data.data.record.state);
                    setDataCountryId(response.data.data.record.country_id);
                    setUserDataZipcode(response.data.data.record.zipcode);
                    setUserDataPhone1(response.data.data.record.phone1);
                    setUserDataPhone2(response.data.data.record.phone2);

                    setDDCountryName(response.data.data.record.countryName ? response.data.data.record.countryName : '')

                    setCountry(response.data.data.countries);

                    setPreferredCurrencyData(response.data.data.preferredCurrency);
                    setPreferredDateFormatData(response.data.data.preferredDateFormat);

                    calendorFormate = response.data.data.preferredDateFormat.split(' ');
                    setCalendorDateFormate(calendorFormate[0])
                    
                    if (response.data.data.preferredCurrency.currencyCode) {
                         currencyCode = response.data.data.preferredCurrency.currencyCode ? response.data.data.preferredCurrency.currencyCode : '';
                         currencySymbol = response.data.data.preferredCurrency.currencySymbol ? response.data.data.preferredCurrency.currencySymbol : '';
                    }

                    if(response.data.data.currentTenant && response.data.data.currentTenant.package && response.data.data.currentTenant.package.name){
                         currentPackageName = response.data.data.currentTenant.package.name;
                    } 

                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                         //swal("warning", error.response.data.message, "warning");
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.warning(error.response.data.message);
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
               history.push('/dashboard');
          });
     }
     
     const handleSaveData = async(value, name) => {
          const data = {
               [name]: value
          }

          if(value){
               if(name==='country_id' && isNaN(value)){
                    updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    setIsEditable(true)
               }else{
                    await axios.post("api/update-profile", data).then(response => {
                         if (response.status === 200) {
                              updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                              if (name == 'name') {
                                   user.name = value;
                                   dispatch(
                                        login(user)
                                   );
                              }
          
                              setErrorInput({ ...ErrorInput, error_list: '' });
                              setIsEditable(true)
                              toast.success('Data updated successfully');
                         }
                    }).then(json => {
          
                    }).catch(error => {
                         if (error.response) {
                              setIsEditable(true)
                              if (error.response.status === 400) {
                                   setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                              } else if (error.response.status === 403) {
                                   toast.warning('Forbidden ' + error.response.data.message);
                              } else if (error.response.status === 300) {
                                   toast.warning(error.response.data.message);
                              } else {
                                   toast.error('Oops Something went wrong.');
                              }
                         }
          
                    });
               }
          }else{
               updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
               setErrorInput({ ...ErrorInput, error_list: '' });
               setIsEditable(true)
          }
          
     };


     const handleImageInput = async(e) => {
          e.persist();

          const formData = new FormData();
          formData.append('profile_image', e.target.files[0])

          await axios.post("/api/update-profile", formData).then(response => {
               if (response.status === 200) {
                    updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                    if (response.data.data.userData.profile_image) {
                         user.profile_image = response.data.data.userData.profile_image;
                         dispatch(
                              login(user)
                         );
                    }
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    setIsEditable(true)
                    toast.success('Data updated successfully');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    let errorMessage = ''
                    setIsEditable(true)
                    if (error.response.status === 400) {
                         setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         if (error.response.data.validation_errors.profile_image[0] == 'The profile image must not be greater than 4096 kilobytes.') {
                              errorMessage = 'The profile image must not be greater than 4MB.';
                         } else {
                              errorMessage = error.response.data.validation_errors.profile_image[0]
                         }
                         toast.error(errorMessage);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.warning(error.response.data.message);
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }

          });


     }


     const cancel = () => {
          setIsEditable(true)
          setAddress1ValidationErrorMsg('')

          setNameErrorMsg('');
          setSecondryEmailErrorMsg('');
          setAddress1ValidationErrorMsg('');
          setAddress2ValidationErrorMsg('');
          setPhone1ValidationErrorMsg('');
          setPhone2ValidationErrorMsg('');
          setCityValidationErrorMsg('');
          setStateValidationErrorMsg('');
          setZipCodeValidationErrorMsg('');
     };


     const handleClick = (e) => {
          setIsEditable(false)
     }


     const handleUserPasswordInput = (e) => {
          e.persist();

          let names = e.target.name;
          let values = e.target.value;
          validatePasswordFormData(names, values);
          setUserPasswordInput({ ...UserPasswordInput, [e.target.name]: e.target.value });
     }

     function validatePasswordFormData(type, value) {
          if (type == 'old_password' && value == '') {
               setFormOldPasswordError(false);
               setOldPasswordError(true);
               setUserPasswordInput(UserPasswordInput.error_list.old_password = 'Enter your old password');
          } else {
               setFormOldPasswordError(false);
               if (type == 'old_password' && value != '') {
                    setOldPasswordError(false);
                    setUserPasswordInput(UserPasswordInput.error_list.old_password = '');
               }
          }

          if (type == 'password' && value == '') {
               setFormPasswordError(false);
               setPasswordError(true);
               setUserPasswordInput(UserPasswordInput.error_list.password = 'Password cannot be less than 8 characters');
          } else if (type == 'password' && value != '') {
               setFormPasswordError(false);
               if (validator.isStrongPassword(value, {
                    minLength: 8, minLowercase: 1,
                    minUppercase: 1, minNumbers: 1, minSymbols: 1
               }) && value.length <= 20) {
                    setPasswordError(false);
                    setUserPasswordInput(UserPasswordInput.error_list.password = '');
               } else {
                    setPasswordError(true);
                    setUserPasswordInput(UserPasswordInput.error_list.password = 'Must Contain one Uppercase, one Lowercase, one Number and one Special Case Character');
               }
          }

          if (type == 'password_confirm' && value == '') {
               setFormConfirmPasswordError(false);
               setConfirmPasswordError(true);
               setUserPasswordInput(UserPasswordInput.error_list.password_confirm = 'Enter your confirm password');
          } else if (type == 'password_confirm' && UserPasswordInput.password == value) {
               setFormConfirmPasswordError(false);
               setConfirmPasswordError(false);
               setUserPasswordInput(UserPasswordInput.error_list.password_confirm = '');
          } else if (type == 'password_confirm') {
               setFormConfirmPasswordError(false);
               setConfirmPasswordError(true);
               setUserPasswordInput(UserPasswordInput.error_list.password_confirm = "Password doesn't match");
          }
     }


     const userPasswordSubmit = async(e) => {
          e.preventDefault();

          if (UserPasswordInput.old_password == '') {
               setOldPasswordError(true);
               setFormOldPasswordError(true);
               oldPasswordRef.current.focus();
          }

          if (UserPasswordInput.password == '') {
               setPasswordError(true);
               setFormPasswordError(true);
               passwordRef.current.focus();
          }

          if (UserPasswordInput.confirm_password == '') {
               setConfirmPasswordError(true);
               setFormConfirmPasswordError(true);
               confirmPasswordRef.current.focus();
          }

          //setLoading(true);
          const data = {
               old_password: UserPasswordInput.old_password,
               password: UserPasswordInput.password,
               password_confirm: UserPasswordInput.password_confirm,
          }

          setIsSubmittingPassword(true)

          if (!oldPasswordError && !passwordError && !confirmPasswordError) {
               await axios.post("/api/change-password", data).then(response => {
                    setLoading(false);
                    if (response.status === 200) {
                         setUserPasswordInput({ old_password: '', password: '', password_confirm: '', error_list: [] });
                         toast.success('Your password has been updated successfully');
                         setIsSubmittingPassword(false)
                    }
               }).then(json => {
                    setLoading(false);
               }).catch(error => {
                    setLoading(false);
                    if (error.response) {
                         setIsSubmittingPassword(false)
                         if (error.response.status === 400 || error.response.status === 401) {
                              setUserPasswordInput({ ...UserPasswordInput, error_list: error.response.data.validation_errors });
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden ' + error.response.data.message);
                         } else {
                              toast.warning(error.response.data.message);
                         }
                    }

               });
          }
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }

     const CustomSelectDisplay = props => {
          const handleSelectCountrySave = (event) => {
               props.setParentValue(event.value);
          };
          setIsEditable(false)
          const countrysLists = props.data.countrysLists;
          const selectedCountry = props.data.selectedCountry;
          return (
               <Select
                    defaultValue={selectedCountry}
                    name="country_id"
                    options={countrysLists}
                    classNamePrefix="select"
                    onChange={handleSelectCountrySave}
                    theme={(theme) => ({
                         ...theme,
                         borderRadius: 0,
                         colors: {
                              ...theme.colors,
                              primary25: 'var(--primary-color-light)',
                              primary: 'var(--primary-color)',
                         },
                    })}
               />
          );
     };

     const CustomSelectDisplayCountry = props => {
          const val = props;
          return (
               <div>
                    {/* {val.value ? val.value : 'Select...'} */}
                    {val.value ? !isNaN(val.value) ? 'Please wait...' : val.value : 'Select...'}
               </div>
          );

     }

     const togglePassword = () => {
          setPasswordShown(!passwordShown);
     };

     const toggleConPassword = () => {
          setConPasswordShown(!conPasswordShown);
     };


     const [defaultValue, setdefaultValue] = useState({
          old_password: '',
          password: '',
          confirm_password: '',
          error_list: [],
     })

     const validationSchema = yup.object().shape({
          old_password: yup.string().min(8, 'Must be at least 8 characters').max(20, 'Must be at most 20 characters').required('Enter your password'),
          password: yup.string().required('Password cannot be less than 8 characters').min(8).max(20).matches(
               /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)\+\-\/])(?=.{8,})/,
               "Must Contain one Uppercase, one Lowercase, one Number and one Special Case Character"
          ),
          confirm_password: yup.string().required('Enter your confirm password').oneOf([yup.ref('password'), null], "Password doesn't match")
     })

     const handleSubmit = async(values, onSubmitProps) => {
          const data = {
               old_password: values.old_password,
               password: values.password,
               password_confirm: values.confirm_password,
          }

          await axios.post("/api/change-password", data).then(response => {
               setLoading(false);
               if (response.status === 200) {
                    setdefaultValue({ old_password: '', password: '', password_confirm: '', error_list: [] });
                    toast.success('Your password has been updated successfully');
               }
          }).then(json => {
               setLoading(false);
          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    onSubmitProps.setSubmitting(false)
                    if (error.response.status === 400 || error.response.status === 401) {
                         setdefaultValue({ ...defaultValue, error_list: error.response.data.validation_errors });
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                         toast.warning(error.response.data.message);
                    }
               }

          });
     }

     const cancelSubscription = async () => {
          swal({
            title: "Are you sure you want to cancel subscription?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then(async (willDelete) => {
              if (willDelete) {
               await axios.get('api/cancel-subscription').then(response => {
                  if (response.status === 200) {
                    toast.success(response.data.message);
                  }
                }).then(json => {
      
                }).catch(error => {
                  if (error.response) {
                    if (error.response.status === 400) {
                      toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                      toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                      toast.error(error.response.data.message);
                    }
                  }
                });
              }
            });
        }

        const deleteAccount = async () => {
          swal({
            title: "Are you sure you want to delete your Account?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then(async (willDelete) => {
              if (willDelete) {
               await axios.delete('api/delete-account').then(response => {
                  if (response.status === 200) {
                    toast.success(response.data.message);
                    history.push('/login');
                  }
                }).then(json => {
      
                }).catch(error => {
                  if (error.response) {
                    if (error.response.status === 400) {
                      toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                      toast.warning('Forbidden ' + error.response.data.message);
                    } else {
                      toast.error(error.response.data.message);
                    }
                  }
                });
              }
            });
        }


     if (loading) {
          return <Preloader show={true} />;
     } else {

          let countrysLists = [];
          for (var i = 0, l = countries.length; i < l; i++) {
               countrysLists.push({ label: countries[i].country_name, value: countries[i].id });
          }

          let selectedCountry = [];
          for (var i = 0, l = countries.length; i < l; i++) {
               if (UserDataCountryId == countries[i].id) {
                    selectedCountry.push({ label: countries[i].country_name, value: countries[i].id });
               }
          }

          let fullName = dataRecord ? dataRecord.name ? dataRecord.name : '' : '';
          let sortName = ''
          let expName = ''

          if (fullName) {
               expName = fullName.split(' ');

               if (expName.length == 2) {
                    sortName = expName[0].charAt(0) + expName[1].charAt(0);
               } else {
                    sortName = expName[0].charAt(0);
               }
          }

          return (
               // <Row>
               <div className="display flex-wrap">
                    {/*--- profile_information_section ---*/}
                    <Col md={12} className="client profile mt30">
                         {/*--- client_name_and_convert_lead_section ---*/}
                         <div className="client_details display justify-content-between">
                              {/*--- client_image_and_deatils_section ---*/}
                              <div className="client_image profile_imgae display">
                                   <label className="custom-file-upload editprofile">
                                        <input type="file" id="profile_image" name="profile_image" accept="image/jpeg, image/png, image/jpg" onChange={handleImageInput} />
                                        <Stack direction="row" spacing={3}>
                                             <Avatar
                                                  alt={fullName}
                                                  src={dataRecord.profile_image ? `${process.env.REACT_APP_API_URL}storage/${dataRecord.profile_image}` : ''}
                                                  sx={{ width: 50, height: 50 }}
                                                  onError={addDefaultSrc}
                                                  className="clientimg1"
                                             >
                                                  {sortName}
                                             </Avatar>
                                        </Stack>
                                   </label>

                                   <div className="client_name ml20">
                                        <div className="display flex-wrap">
                                             <h5>{fullName}</h5>
                                        </div>
                                        <div className="display flex-wrap">
                                             <h6>{dataRecord.email}</h6>
                                        </div>
                                   </div>
                              </div>
                              {/*--- client_update_time_and_date_section ---*/}
                              <div className="profile_btn">
                                   <h6>{dataRecord.selected_role ? dataRecord.selected_role : 'N/A'}</h6>
                              </div>

                              {/* <div>
                                   <h6><Button type="botton" variant="success" className="popupbtn cancelbtn" onClick={()=>deleteAccount()}>Delete Account</Button></h6>
                              </div> */}
                              {/*--- converted_button ---*/}
                         </div>
                    </Col>

                    {/*--- Profile_Information_section ---*/}
                    <Col md={12} className="mt30">
                         <div className="display w-100">
                              <Accordion defaultActiveKey="0" className="w-100">
                                   <Accordion.Item eventKey="0">
                                        <Accordion.Header><h4>Profile Information</h4></Accordion.Header>
                                        <Accordion.Body>
                                             <div className="display align-items-start flex-wrap">
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Name</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.name}
                                                                           onSave={(value) => { handleSaveData(value, 'name') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value.length > 50) {
                                                                                          setNameErrorMsg('Only 50 characters are allowed');
                                                                                          return false
                                                                                     } else if (!(/^[A-Za-z ]{2,29}$/i.test(value))) {
                                                                                          setNameErrorMsg('Only alphabets are allowed');
                                                                                          return false
                                                                                     } else if (value && value.length < 2) {
                                                                                          setNameErrorMsg('Enter at least 2 characters.');
                                                                                          return false
                                                                                     } else if (((value && value.length == 2) && (value && splString.length >= 2))) {
                                                                                          setNameErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     setNameErrorMsg('Enter your name.');
                                                                                     return false
                                                                                }
                                                                           }}
                                                                           validationMessage={nameErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.first_name}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Secondary email</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.secondry_email}
                                                                           onSave={(value) => { handleSaveData(value, 'secondry_email') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     if (value.length > 50) {
                                                                                          setSecondryEmailErrorMsg('Only 50 characters are allowed');
                                                                                          return false
                                                                                     } else if (value && !(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value))) {
                                                                                          setSecondryEmailErrorMsg('Enter valid email address');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={secondryEmailErrorMsg}

                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.secondry_email}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Email</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.email}
                                                                           onSave={''}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           instructions=""
                                                                           allowEdit={false}
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.email}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                             <div className="display align-items-start flex-wrap mb25">
                                                  <div className="col-md-12">
                                                       <h4>Address Information</h4>
                                                  </div>
                                             </div>

                                             <div className="display align-items-start flex-wrap">
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick description-input position-relative">
                                                                 <Form.Group className="mb-2 display align-items-start">
                                                                      <Form.Label className="text-right w-17">Address1</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXTAREA}
                                                                           value={dataRecord.address1}
                                                                           onSave={(value) => { handleSaveData(value, 'address1') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 100) {
                                                                                          setAddress1ValidationErrorMsg('Allow 100 characters only.')
                                                                                          return false
                                                                                     } else if (value && value.length < 2) {
                                                                                          setAddress1ValidationErrorMsg('Enter at least 2 characters.');
                                                                                          return false
                                                                                     } else if (((value && value.length == 2) && (value && splString.length >= 2))) {
                                                                                          setAddress1ValidationErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                          return false
                                                                                     } else if (value.replace(/\s+/g, '').length < 2) {
                                                                                          setAddress1ValidationErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     setAddress1ValidationErrorMsg('Enter address 1');
                                                                                     return false
                                                                                }

                                                                           }}

                                                                           validationMessage={address1ValidationErrorMsg}
                                                                           onCancel={cancel}
                                                                           cssClassPrefix="custom-textarea-"
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 100 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error error3">{ErrorInput.error_list.address1}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Phone1</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.phone1}
                                                                           onSave={(value) => { handleSaveData(value, 'phone1') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     // if (isNaN(value)) {
                                                                                     //      setPhone1ValidationErrorMsg('Enter numeric value only.');
                                                                                     //      return false
                                                                                     // } else if (value.length != 10) {
                                                                                     //      setPhone1ValidationErrorMsg('Enter 10 digit only.');
                                                                                     //      return false
                                                                                     // } else if (value && !(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value))) {
                                                                                     //      setPhone1ValidationErrorMsg('Enter valid phone number.');
                                                                                     //      return false
                                                                                     // }
                                                                                     if (value && value.length > 25) {
                                                                                          setPhone1ValidationErrorMsg('Allow 25 characters only.')
                                                                                          return false
                                                                                     } else {
                                                                                          return true
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     setPhone1ValidationErrorMsg('Enter phone number');
                                                                                     return false
                                                                                }

                                                                           }}
                                                                           validationMessage={phone1ValidationErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 25 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.phone1}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">City</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.city}
                                                                           onSave={(value) => { handleSaveData(value, 'city') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 50) {
                                                                                          setCityValidationErrorMsg('Only 50 characters are allowed')
                                                                                          return false
                                                                                     } else if (value && value.length < 2) {
                                                                                          setCityValidationErrorMsg('Enter at least 2 characters.');
                                                                                          return false
                                                                                     } else if (((value && value.length == 2) && (value && splString.length >= 2))) {
                                                                                          setCityValidationErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={cityValidationErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.city}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Zip code</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.zipcode}
                                                                           onSave={(value) => { handleSaveData(value, 'zipcode') }}
                                                                           onValidate={value => {
                                                                                if (value && value.length >= 11) {
                                                                                     setZipCodeValidationErrorMsg('Cannot allow more than 10 characters.')
                                                                                     return false
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={zipCodeValidationErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 10 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.zipcode}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="col-md-6 circletrick">
                                                       <div className="display align-items-start flex-wrap">
                                                            <div className="col-md-12 circletrick description-input position-relative">
                                                                 <Form.Group className="mb-2 display align-items-start">
                                                                      <Form.Label className="text-right w-17">Address2</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXTAREA}
                                                                           value={dataRecord.address2}
                                                                           onSave={(value) => { handleSaveData(value, 'address2') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 100) {
                                                                                          setAddress2ValidationErrorMsg('Allow 100 characters only.')
                                                                                          return false
                                                                                     } else if (value && value.length < 2) {
                                                                                          setAddress2ValidationErrorMsg('Enter at least 2 characters.');
                                                                                          return false
                                                                                     } else if (((value && value.length == 2) && (value && splString.length >= 2))) {
                                                                                          setAddress2ValidationErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                          return false
                                                                                     } else if (value.replace(/\s+/g, '').length < 2) {
                                                                                          setAddress2ValidationErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }

                                                                           }}
                                                                           validationMessage={address2ValidationErrorMsg}
                                                                           onCancel={cancel}
                                                                           cssClassPrefix="custom-textarea-"
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 100 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.address2}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Phone2</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.phone2}
                                                                           onSave={(value) => { handleSaveData(value, 'phone2') }}
                                                                           onValidate={value => {
                                                                                // if (value) {
                                                                                //      if (isNaN(value)) {
                                                                                //           setPhone2ValidationErrorMsg('Enter numeric value only.');
                                                                                //           return false
                                                                                //      } else if (value.length != 10) {
                                                                                //           setPhone2ValidationErrorMsg('Enter 10 digit only.');
                                                                                //           return false
                                                                                //      } else if (value && !(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value))) {
                                                                                //           setPhone2ValidationErrorMsg('Enter valid phone number.');
                                                                                //           return false
                                                                                //      }
                                                                                //      return true
                                                                                // }
                                                                                if (value && value.length > 25) {
                                                                                     setPhone2ValidationErrorMsg('Allow 25 characters only.')
                                                                                     return false
                                                                                } else {
                                                                                     return true
                                                                                }
                                                                           }}
                                                                           validationMessage={phone2ValidationErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 25 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.phone2}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">State</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           value={dataRecord.state}
                                                                           onSave={(value) => { handleSaveData(value, 'state') }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     var splString = value.split(' ');
                                                                                     if (value && value.length > 50) {
                                                                                          setStateValidationErrorMsg('Only 50 characters are allowed')
                                                                                          return false
                                                                                     } else if (value && value.length < 2) {
                                                                                          setStateValidationErrorMsg('Enter at least 2 characters.');
                                                                                          return false
                                                                                     } else if (((value && value.length == 2) && (value && splString.length >= 2))) {
                                                                                          setStateValidationErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                          return false
                                                                                     }
                                                                                     return true
                                                                                } else {
                                                                                     return true
                                                                                }

                                                                           }}
                                                                           validationMessage={stateValidationErrorMsg}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           attributes={{ maxLength: 50 }}
                                                                           placeholder='----'
                                                                           instructions=""
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                      />
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.state}</span>
                                                            </div>
                                                            <div className="col-md-12 circletrick leadlist">
                                                                 <Form.Group className="mb-2 display">
                                                                      <Form.Label className="text-right w-17">Country</Form.Label>
                                                                      <EasyEdit
                                                                           type={Types.TEXT}
                                                                           onSave={(value) => { handleSaveData(value, 'country_id') }}
                                                                           onCancel={cancel}
                                                                           saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                           cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                           editComponent={
                                                                                <CustomSelectDisplay data={{ 'selectedCountry': selectedCountry, 'countrysLists': countrysLists }} />
                                                                           }
                                                                           value={selectedCountry.length > 0 ? selectedCountry[0].label : ''}
                                                                           displayComponent={<CustomSelectDisplayCountry />}
                                                                           allowEdit={isEditable}
                                                                           onFocus={() => handleClick('')}
                                                                           placeholder='----'
                                                                      />
                                                                      
                                                                 </Form.Group>
                                                                 <span className="error">{ErrorInput.error_list.country_id}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </Accordion.Body>
                                   </Accordion.Item>
                              </Accordion>
                         </div>
                    </Col>

                    {/*--- password_section ---*/}
                    

                    <Col xs={12} className="mt30">
                         <div className="display flex-wrap w-100 history">

                              <div className="innerdealtable w-100">
                                   <div className="col-md-8">
                                        <h5>Change Password</h5>
                                   </div>
                                   <div className="col-md-4">
                                   </div>
                              </div>
                              <div className="contanctlistnig formbtn">
                                   <Formik
                                        initialValues={defaultValue}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                   >{props => {
                                        const {
                                             values,
                                             touched,
                                             errors,
                                             dirty,
                                             isSubmitting,
                                             handleChange,
                                             handleBlur,
                                             handleSubmit,
                                             handleReset
                                        } = props;
                                        return (
                                             <Form className="position-relative" onSubmit={handleSubmit}>
                                                  <Row>
                                                       <div className="col-md-12 col-sm-12">
                                                            <Row className="align-items-center">
                                                                 <div className="col-lg-6 col-md-7 col-xl-7">
                                                                      <Form.Group className="d-flex profile1 mb-4">
                                                                           <Form.Label column sm="2" className="text-left w-17 form-label">
                                                                                Old password<span className="text-danger">*</span>
                                                                           </Form.Label>
                                                                           <Col sm="6" className="position-relative">
                                                                                <Form.Group id="name" className="even">
                                                                                     <InputGroup>
                                                                                          <Form.Control type="password" name="old_password" minLength={8} maxLength={20} onChange={handleChange} onBlur={handleBlur} placeholder="Enter your old password" className={errors.old_password && touched.old_password ?
                                                                                               "input-error" : null} />
                                                                                     </InputGroup>
                                                                                     <span className="error error3 text-center">{defaultValue.error_list.old_password}</span>
                                                                                     <p className="text-danger error3">
                                                                                          <ErrorMessage name="old_password" />
                                                                                     </p>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                      <Form.Group className={errors.password && errors.password.length > 50 ? 'd-flex profile1 mb-5' : 'd-flex profile1 mb-4'}>
                                                                           <Form.Label column sm="2" className="text-left w-17 form-label">
                                                                                New password<span className="text-danger">*</span>
                                                                           </Form.Label>
                                                                           <Col sm="6" className="position-relative">
                                                                                <Form.Group id="password" className="even">
                                                                                     <InputGroup>
                                                                                          <Form.Control type={passwordShown ? "text" : "password"} name="password" minLength={8} maxLength={20} onChange={handleChange} onBlur={handleBlur} placeholder="Password" className={errors.password && touched.password ?
                                                                                               "input-error" : null} />
                                                                                          <span onClick={togglePassword} className={passwordShown ? "password_icon" : "signin_icon"}></span>
                                                                                     </InputGroup>
                                                                                     <span className="error error3 text-center">{defaultValue.error_list.password}</span>
                                                                                     <p className="text-danger error3">
                                                                                          <ErrorMessage name="password" />
                                                                                     </p>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                      <Form.Group className="d-flex profile1 mb-4">
                                                                           <Form.Label column sm="2" className="text-left w-17 form-label">
                                                                                Confirm Password<span className="text-danger">*</span>
                                                                           </Form.Label>
                                                                           <Col sm="6" className="position-relative">
                                                                                <Form.Group id="confirmPassword" className="even">
                                                                                     <InputGroup>
                                                                                          <Form.Control type={conPasswordShown ? "text" : "password"} name="confirm_password" onChange={handleChange} onBlur={handleBlur} placeholder="Confirm Password" className={errors.confirm_password && touched.confirm_password ?
                                                                                               "input-error" : null} />
                                                                                          <span onClick={toggleConPassword} className={conPasswordShown ? "password_icon" : "signin_icon"}></span>
                                                                                     </InputGroup>
                                                                                     <span className="error error3 text-center">{defaultValue.error_list.confirm_password}</span>
                                                                                     <p className="text-danger error3">
                                                                                          <ErrorMessage name="confirm_password" />
                                                                                     </p>
                                                                                </Form.Group>
                                                                           </Col>
                                                                      </Form.Group>
                                                                      <Form.Group className="d-flex profile1 mb-4">
                                                                           <Form.Label column sm="2" className="text-right w-17 form-label">

                                                                           </Form.Label>
                                                                           <Col sm="2">
                                                                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                                                     {
                                                                                          isSubmitting ?

                                                                                               <Button variant="primary" className="d-flex align-items-center justify-content-center text-center mb-1 w-100">
                                                                                                    <Spinner animation="border" size="sm" />
                                                                                                    <span className="ms-2 text-center">Please Wait...</span>
                                                                                               </Button>
                                                                                               :
                                                                                               <Button variant="primary" type="submit" className="w-100  popupbtn btnblue mr15 d-flex align-items-center justify-content-center">
                                                                                                    Submit
                                                                                               </Button>
                                                                                     }
                                                                                </div>
                                                                           </Col>
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-lg-6 col-md-5 col-xl-5"></div>
                                                            </Row>
                                                       </div>
                                                  </Row>
                                             </Form>
                                        );
                                   }}
                                   </Formik>
                              </div>

                         </div>
                    </Col>

                    {
                         dataList.currentTenant && dataList.currentTenant.payment_type=='recurring' ?
                              <Col xs={12} className="mt30">
                              <div className="display subscription flex-wrap w-100 history align-items-center">

                                   <div className="innerdealtable w-100">
                                        <div className="col-md-8">
                                             <h5>Your Subscription</h5>
                                        </div>
                                        <div className="col-md-4 text-right">
                                             <h6>{dataList.currentTenant.subscribsion_end ? moment.tz(dataList.currentTenant.subscribsion_end, defaultTimeZone ? defaultTimeZone : '').format(calendorDateFormate):'N/A'}</h6>
                                        </div>
                                   </div>
                              
                                   <div className="display align-items-start justify-content-between w-100">
                                   <div className="col-md-6">
                                   <div className="customizeplan paymentinformation">
                                        <div classsname="d-flex align-items-center">
                                        <div className="col-md-12 text-left">
                                             <div className="logo-two-coloum">
                                             <div className="row align-items-center justify-content-between">
                                                  <div className="col-md-6">
                                                  <h5>{currentPackageName}</h5>
                                                  </div>     
                                                  <div className="col-md-6 text-right">
                                                  <h5>{
                                                       dataList.currentTenant && dataList.currentTenant.package && dataList.currentTenant.package.package_type !== 'offline' ? 
                                                            '('+dataList.currentTenant.package.duration+' '+dataList.currentTenant.package.duration_type+'/'+dataList.currentTenant.package.price+' '+preferredCurrencyData.currencySymbol+')'
                                                       :
                                                       dataList.currentTenant.package.number_of_user>1?
                                                            '('+dataList.currentTenant.package.duration+' '+dataList.currentTenant.package.duration_type+' / '+dataList.currentTenant.package.number_of_user+' users)'
                                                            :
                                                            '('+dataList.currentTenant.package.duration+' '+dataList.currentTenant.package.duration_type+' / '+dataList.currentTenant.package.number_of_user+' user)'
                                                       } 
                                                       </h5>
                                                  </div>  
                                             </div>   
                                             </div>
                                             <div className="date-three-coloum logo-two-subscription-coloum">
                                                  <div className="row align-items-center justify-content-between">
                                                       <div className="col-md-4">
                                                            <p>Start {currentPackageName}</p>
                                                            <h6>{dataList.currentTenant.subscribsion_start ? moment.tz(dataList.currentTenant.subscribsion_start, defaultTimeZone ? defaultTimeZone : '').format(calendorDateFormate):'N/A'}</h6>
                                                       </div>
                                                       <div className="col-md-5">
                                                       <img src={righticon} alt="solvios-logo"/>
                                                       </div>
                                                       <div className="col-md-3 text-right pe-3 ps-0">
                                                            <p>End {currentPackageName}</p>
                                                            <h6>{dataList.currentTenant.subscribsion_end ? moment.tz(dataList.currentTenant.subscribsion_end, defaultTimeZone ? defaultTimeZone : '').format(calendorDateFormate):'N/A'}</h6>
                                                       </div>
                                                  </div>
                                             </div>
                                             {
                                                dataList.currentTenant && dataList.currentTenant.package && dataList.currentTenant.package.package_type !== 'offline' ? 

                                                <div className="subscription-payment mt30">
                                                  <div className="display align-items-center justify-content-between">
                                                       <div className="col-md-6">
                                                            <h6>Next Subscription Payment</h6>
                                                       </div>
                                                       <div className="col-md-6 text-right">
                                                       {
                                                            dataList.currentTenant.is_subscription_delete=='yes' ?
                                                            <h6>{'N/A'}</h6>
                                                            :
                                                            <h6>
                                                                 <small>
                                                                      {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                                      ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                                                                 </small>
                                                                 {dataList.currentTenant && dataList.currentTenant.subscription_amount ? ' '+dataList.currentTenant.subscription_amount:' N/A '} 
                                                                 
                                                            </h6>
                                                       }     
                                                       
                                                       </div>
                                                  </div>
                                             </div>

                                             :
                                             ''
                                             }
                                             
                                        </div>
                                        </div>
                                   </div>
                                   
                                   </div>
                                   <div className="col-md-6 d-flex justify-content-end">
                                   <div className="customizeplan paymentinformation">
                                        {
                                             dataList.currentTenant && dataList.currentTenant.package && dataList.currentTenant.package.package_type !== 'offline' ? 
                                                  dataList.currentTenant.is_subscription_delete=='yes' ?
                                                  <Button type="botton" variant="success" className="popupbtn cancelbtn" title="Your Subscription Cancellation request was accepted">Subscription Canceled</Button>
                                                  :
                                                  <Button type="botton" variant="success" className="popupbtn cancelbtn" onClick={()=>cancelSubscription()}>Cancel Subscription</Button>
                                             :
                                             ''
                                        }
                                        
                                   </div>
                                   </div>
                                   </div>

                              </div>
                              </Col>
                         :
                         ''
                    }               
                    


                    {/* </Row> */}
               </div>
          );
     }

};

export default UserProfile;
