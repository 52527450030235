/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import Preloader from "../components/PreloaderCenter";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import Pagination from "react-js-pagination";
import 'react-edit-text/dist/index.css';
import '../assets/css/custom.css';
import { useHistory, } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { displayDate } from '../utils/utils';


var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;
var dealId = '';
var quoteID = '';

export default (props) => {
     quoteID = props.data.quoteID
     const history = useHistory();
     const loginUser = useSelector(selectUser);
     const dispatch = useDispatch();
     const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
     const [files, setFiles] = useState(null);

     const [addContactData, setAddContactData] = useState(false)
     const [updateContactData, setUpdateContactData] = useState(false)
     
     // Decrypt
     const user = loginUser?loginUser:[];

     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

     const [loadingContact, setLoadingContact] = useState(true);
     const [listQuoteHistoryData, setListQuoteHistoryData] = useState([]);

     //=========START:CODE FOR FOOTER PAGINATION===========//
     const [dataCount, setdataCount] = useState('');
     const [state, setData] = useState({
          users: ''
     });

     const SORT_ASC = "asc"
     const SORT_DESC = "desc"
     const [sortColumn, setSortColumn] = useState()
     //sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC

     //=========END:CODE FOR FOOTER PAGINATION===========//

     useEffect(() => {
          async function fetchData() {
               await getContactData();
          }
          fetchData();
     }, [updateContactData, addContactData]);

     const getContactData = async (currentPage) => {

          const params = {
               sort_field: sortColumn,
               sort_order: SORT_DESC,
               per_page: perPage,
               page: currentPage,
               quote_id: quoteID
          }

          setLoadingContact(true);
          await axios.get(`api/quote-email-history?page=${currentPage}`, { params }).then(response => {
               if (response.status === 200) {
                    setListQuoteHistoryData(response.data.data.records.data);
                    setdataCount(response.data.data.records.data.length);
                    setData({
                         users: response.data.data.records
                    });

                    const calendorFormate = displayDate(response?.data?.data?.preferredDateFormat,'half');
                    setCalendorFullDateFormate(calendorFormate?calendorFormate:'');
                    
                    setLoadingContact(false);
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setLoadingContact(false);
                    if (error.response.status === 400 || error.response.status === 401) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden' + error.response.data.message);
                         history.push('/dashboard');
                    } else {
                         toast.error(error.response.data.message);
                         history.push('/dashboard');
                    }
               }
          });
     }

     const downloadNormal = async (id,fileType) => {
          if (id) {
               setLoadingContact(true);
               await axios.get('api/download-pdf?id='+id+'&fileType='+fileType).then(response => {
                    if (response.status === 200) {
                         if(response.data.data.fileName && response.data.data.fullPath){
                              const linkSource = 'data:application/pdf;base64, '+response.data.data.fullPath
                              const downloadLink = document.createElement("a");
                              downloadLink.href = linkSource;
                              downloadLink.download = response.data.data.fileName;
                              downloadLink.click();
                         }else{
                              toast.warning('Sorry...! Uploaded file path are not properly');    
                         }
                         setLoadingContact(false);
                    }
               }).then(json => {
     
               }).catch(error => {
                    if (error.response) {
                         setLoadingContact(false);
                         if (error.response.status === 400 || error.response.status === 401) {
                              toast.warning(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden' + error.response.data.message);
                              history.push('/dashboard');
                         } else {
                              toast.error(error.response.data.message);
                              history.push('/dashboard');
                         }
                    }
               });
          } else {
               toast.warning('Sorry...! No data available for download file.');
          }
     }

     const downloadZip = async (id,fileType) => {
          if (id) {
               setLoadingContact(true);
               await axios.get('api/download-pdf?id='+id+'&fileType='+fileType).then(response => {
                    if (response.status === 200) {
                         if(response.data.data.fileName && response.data.data.fullPath){
                               try {
                                   const zip = new JSZip();

                                   const remoteZips = response.data.data.fullPath.map(async (file,i) => {
                                        const extension = await file.split('fileName=')
                                        
                                        if(extension && extension.length==2){
                                             
                                             const data = zip.file(extension[1], extension[0], {base64: true});
                                        
                                             return data;
                                        }
                                        
                                   });
                                   
                                   Promise.all(remoteZips)
                                     .then(() => {
                                       zip.generateAsync({ type: "blob" }).then((content) => {
                                         // give the zip file a name
                                         saveAs(content, response.data.data.fileName);
                                       });
                                       setLoadingContact(false);
                                     })
                                     .catch(() => {
                                        setLoadingContact(false);
                                     });
                             
                                     setLoadingContact(false);
                              } catch (error) {
                                   setLoadingContact(false);
                              }
                         }else{
                              toast.warning('Sorry...! uploaded file path are not properly');    
                         }
                         setLoadingContact(false);
                    }
               }).then(json => {
     
               }).catch(error => {
                    if (error.response) {
                         setLoadingContact(false);
                         if (error.response.status === 400 || error.response.status === 401) {
                              toast.warning(error.response.data.message);
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden' + error.response.data.message);
                              history.push('/dashboard');
                         } else {
                              toast.error(error.response.data.message);
                              history.push('/dashboard');
                         }
                    }
               });
          } else {
               toast.warning('Sorry...! No data available for download file.');
          }
     }

     var displayUserData = '';

     if (loadingContact) {
          return <Preloader show={loadingContact ? true : false} />;
     } else {
          displayUserData = listQuoteHistoryData.map((item, i) => {
               var pdfFileName = 'Download';
               var expFile = item.attachment.split('/attachment/')
               if(expFile.length==2){
                    pdfFileName = expFile[1]
               }
               return (
                    <tr key={i}>
                         <td className="dealsname"><b>{item.subject ? item.subject : 'N/A'}</b></td>
                         <td className="tools" data-tooltip={item.displayTooltip}>{item.subject_with_email_count ? item.subject_with_email_count : 'N/A'}</td>
                         <td>{moment.tz(item.created_at, defaultTimeZone).format(calendorFullDateFormate)}</td>
                         <td>{item.added_by ? item.added_by.name ? item.added_by.name : 'N/A' : 'N/A'}</td>
                         {
                              item.attachFileName.length > 1 ?
                                   <td style={{ cursor: 'pointer' }} className="display align-items-center" onClick={() => downloadZip(item.id,'zip')}>Download .zip<div className="quotedownload ms-1"></div></td>
                                   /* <td style={{ cursor: 'pointer' }} onClick={() => downloadZip(item.quote_bulk_attachment)}>Download Zip<div className="quotedownload"></div></td> */
                                   :
                                   // <td style={{ cursor: 'pointer' }} onClick={() => downloadNormal(item.attachment)} className="tools" data-tooltip={item.attachFileList}>Download</td>
                                   <td style={{ cursor: 'pointer' }} className="display align-items-center" onClick={() => downloadNormal(item.id,'pdf')}>{pdfFileName} <div className="quotedownload ms-1"></div></td>
                         }

                         <td>
                         </td>
                    </tr>
               );
          });

          return (
               <Col md={12} className="mt30">
                    <div className="display flex-wrap w-100 history">
                         <div className="innerdealtable w-100">
                              <div className="col-md-8">
                                   <h5>Emails</h5>
                              </div>
                         </div>

                         {
                              displayUserData.length ?

                                   <>
                                        <div className="contanctlistnig">
                                             <table>
                                                  <tbody>
                                                       <tr>
                                                            <th style={{ width: "25%" }}>Subject</th>
                                                            <th style={{ width: "35%" }}>Recipient</th>
                                                            <th style={{ width: "10%" }}>Date</th>
                                                            <th style={{ width: "15%" }}>SentBy</th>
                                                            <th style={{ width: "15%" }}>Action</th>
                                                       </tr>
                                                       {displayUserData}
                                                  </tbody>
                                             </table>
                                        </div>

                                        {
                                             displayUserData.length >= 1 ?

                                                  <Col xs={12} xl={12} className="showing">
                                                       <Card border="light" className="bg-white border-0">
                                                            <Card.Body className="listcard">
                                                                 <Row className="align-items-center">
                                                                      <Col md={6} className="ps-2">
                                                                           <h6>
                                                                                Showing <b>{(((state?.users?.per_page ? state?.users?.per_page : 0) * (state?.users?.current_page ? state?.users?.current_page : 0)) - ((state?.users?.per_page ? state?.users?.per_page : 0) - 1))}</b> - <b>{(((state?.users?.per_page ? state?.users?.per_page : 0) * (state?.users?.current_page ? state?.users?.current_page : 0)) - ((state?.users?.per_page ? state?.users?.per_page : 0) - 1) + ((dataCount ? dataCount : 0) - 1))}</b> of <b>{state?.users?.total ? state?.users?.total : 0}</b>
                                                                           </h6>
                                                                      </Col>
                                                                      <Col md={6} className="d-flex justify-content-end">
                                                                           <Pagination
                                                                                activePage={state?.users?.current_page ? state?.users?.current_page : 0}
                                                                                itemsCountPerPage={state?.users?.per_page ? state?.users?.per_page : 0}
                                                                                totalItemsCount={state?.users?.total ? state?.users?.total : 0}


                                                                                onChange={(pageNumber) => {
                                                                                     currentPage = (pageNumber);
                                                                                     getContactData(pageNumber)
                                                                                }}
                                                                                pageRangeDisplayed={5}
                                                                                itemClass="page-item mx-1"
                                                                                linkClass="page-link"
                                                                           />
                                                                      </Col>
                                                                 </Row>
                                                            </Card.Body>
                                                       </Card>
                                                  </Col>

                                                  :

                                                  ''
                                        }

                                   </>
                                   :
                                   <span>
                                        No data Available
                                   </span>
                         }

                    </div>
               </Col>
          );


     }




};
