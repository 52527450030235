import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Spinner, Container } from '@themesberg/react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DisplayHostName } from "../../features/userSlice";
import { useSelector } from "react-redux";
import axios from 'axios';

function ResendEmailassword() {

  var logoUrl = ''
  
  //get default host data from redux using url
  var defaultHostData = useSelector(DisplayHostName);
  //var hostname = defaultHostData?.hostname||''
  var displayHost = defaultHostData?.displayHost||''

  if(displayHost){
    logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/`+displayHost+`.png`;
  }
  
  const checkFileExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if(response.status === 200){
       logoUrl = logoUrl;
      }
    } catch (error) {
      logoUrl = `${process.env.REACT_APP_API_URL}assets/logo/app.png`; 
    }
  }
  
  
  
  useEffect(() => {
    async function fetchData() {
      await checkFileExistence(logoUrl);
    }
    fetchData();

  },[displayHost]);

  const history = useHistory();
  const [submitEvent, setSubmitEvent] = useState(false);

  const search = useLocation().search;
  const hash = new URLSearchParams(search).get('hash');

  const resendEmailSubmit = async (e) => {
    e.preventDefault();
    const data = {
      hash: '',
    }
    setSubmitEvent(true)
    await axios.post("/api/email/verification-notification").then(response => {
      if (response.status === 200) {
        toast.success(response.data.message);
        history.push('/dashboard');
      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setSubmitEvent(false)
        if (error.response.status === 401) {
          toast.warning(error.response.data.message);
          history.push('/resend-email-password');
        } else {
          if (error.response.status === 500) {
            toast.error('Mail sending failed');
            history.push('/resend-email-password');
          }
        }
      }
    });
  }


  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 common-form">
        <Container>
          <Row className="justify-content-center">
            <div className="title text-center mb40">
              <a href={process.env.REACT_APP_MARKETING_WEB_URL}><img src={logoUrl} alt="signlogo" className="signlogo" style={{ cursor: 'pointer' }} /></a>
            </div>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white w-100 box-form text-center">
                <h3>Resend verification link?</h3>
                <p className="mb-5">If you want to re-send email verification link please click below button.</p>
                <Form onSubmit={resendEmailSubmit}>
                  {
                    submitEvent ?

                      <Button variant="primary" className="d-flex align-items-center mb-1 w-100">
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2">Please Wait...</span>
                      </Button>
                      :
                      <Button variant="primary" type="submit" className="w-100">
                        Recover password
                      </Button>
                  }


                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResendEmailassword;
