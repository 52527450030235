/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Card,
  Form,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import Preloader from "../components/PreloaderCenter";
import { useSelector } from "react-redux";
//import { selectUser, selectDefaultData } from "../features/userSlice";
import Pagination from "react-js-pagination";
import "react-edit-text/dist/index.css";
import "../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import swal from "sweetalert";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var accountId = "";
var currentPage=1

export default (props) => {
  accountId = props.data.accountId;
  const history = useHistory();
  //const loginUser = useSelector(selectUser);
  const userPermissions = useSelector((state) => state.permission);
  //var defaultData = useSelector(selectDefaultData);

  const [addContactData, setAddContactData] = useState(false);
  const [updateContactData, setUpdateContactData] = useState(false);
  
  //var user = loginUser?loginUser:[];
  var userPermission = userPermissions?userPermissions:[];
  //var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
  
  const [loadingContact, setLoadingContact] = useState(true);
  const [listContactData, setListContactData] = useState([]);

  //==============START: EDIT CONTACT================//
  const [salutation, setSalutation] = useState([]);
  const [editContactId, setEditContactId] = useState();

  const [defaultAddValue, setDefaultAddValue] = useState({
    salutation: "",
    first_name: "",
    last_name: "",
    title: "",
    phone: "",
    primary_email: "",
    error_list: [],
  });
  const [showAddContact, setShowAddContact] = useState(false);
  const handleAddContactClose = () => {
    setShowAddContact(false);
  };

  const [defaultValue, setdefaultValue] = useState({
    salutation: "",
    first_name: "",
    last_name: "",
    title: "",
    phone: "",
    primary_email: "",
    error_list: [],
  });

  const [showEditContact, setShowEditContact] = useState(false);
  const handleEditContactClose = () => {
    setShowEditContact(false);
  };
  //==============START: EDIT CONTACT================//

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const SORT_ASC = "asc";
  const SORT_DESC = "desc";
  const [sortColumn, setSortColumn] = useState();
  //sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC

  //=========END:CODE FOR FOOTER PAGINATION===========//

  useEffect(() => {
    async function fetchData() {
      await getContactData();
    }
    fetchData();
  }, [updateContactData, addContactData]);

  const getContactData = async (currentPage) => {
    const params = {
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
      account_id: accountId,
    };

    //setLoadingContact(true);
    await axios
      .get(`api/contact?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setListContactData(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });
          setLoadingContact(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoadingContact(false);
          if (error.response.status === 400 || error.response.status === 401) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden" + error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          }
        }
      });
  };

  //============START: CODE FOR ADD CONTACT=================//
  async function addContact() {
    //setLoadingContact(true)
    setShowAddContact(true);
    await axios
      .get("api/contact/create")
      .then((response) => {
        if (response.status === 200) {
          setSalutation(response.data.data.salutationArray);
          setLoadingContact(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoadingContact(false);
          if (error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }
  //============END: CODE FOR ADD CONTACT=================//

  //============START: CODE FOR EDIT CONTACT=================//
  async function editContact(id) {
    //setEditContactId(id)
    setLoadingContact(true);
    await axios
      .get("api/contact/edit/" + id)
      .then((response) => {
        if (response.status === 200) {
          setSalutation(response.data.data.salutationArray);
          setdefaultValue({
            salutation: response.data.data.record.salutation
              ? response.data.data.record.salutation
              : "",
            first_name: response.data.data.record.first_name
              ? response.data.data.record.first_name
              : "",
            last_name: response.data.data.record.last_name
              ? response.data.data.record.last_name
              : "",
            title: response.data.data.record.title
              ? response.data.data.record.title
              : "",
            primary_email: response.data.data.record.primary_email
              ? response.data.data.record.primary_email
              : "",
            phone: response.data.data.record.phone1
              ? response.data.data.record.phone1
              : "",
            error_list: [],
          });
          setLoadingContact(false);
          setShowEditContact(true);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoadingContact(false);
          if (error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      // color: 'black',
      // backgroundcolor: '$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const validationSchema = yup.object().shape({
    first_name: yup
      .string()
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter first name"),
    last_name: yup
      .string()
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter last name"),
    primary_email: yup
      .string()
      .max(50, "Must be at most 50 characters")
      .email("Please enter valid email address"),
    phone: yup.string().max(25, "Phone must be at most 25 characters"),
    title: yup.string().max(50, "Must be at most 50 characters"),
  });

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      id: editContactId,
      salutation: values.salutation,
      first_name: values.first_name,
      last_name: values.last_name,
      primary_email: values.primary_email,
      phone1: values.phone,
      title: values.title,
    };
    setLoadingContact(true);
    await axios
      .post("api/contact/update", data)
      .then((response) => {
        if (response.status === 200) {
          updateContactData
            ? setUpdateContactData(false)
            : setUpdateContactData(true);
          setLoadingContact(false);
          setShowEditContact(false);
          toast.success("Contact update successfully");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoadingContact(false);
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400) {
            setdefaultValue({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.warning(error.response.data.message);
          }
        }
      });
  };

  const handleAddSubmit = async (values, onSubmitProps) => {
    const data = {
      salutation: values.salutation,
      first_name: values.first_name,
      last_name: values.last_name,
      primary_email: values.primary_email,
      phone1: values.phone,
      title: values.title,
      account_id: accountId,
    };

    setLoadingContact(true);
    await axios
      .post("/api/contact/store", data)
      .then((response) => {
        if (response.status === 200) {
          setLoadingContact(false);
          setShowAddContact(false);
          toast.success("Contact created successfully");
          addContactData ? setAddContactData(false) : setAddContactData(true);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoadingContact(false);
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400) {
            setdefaultValue({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.warning(error.response.data.message);
          }
        }
      });
  };

  let selectedSalutation = [];
  Object.keys(salutation).map(function (key) {
    if (defaultValue.salutation == key) {
      selectedSalutation.push({ label: salutation[key], value: key });
    }
  }, this);

  let salutationList = [{ label: "Select...", value: "" }];
  Object.keys(salutation).map(function (key) {
    salutationList.push({ label: salutation[key], value: key });
  }, this);

  async function deleteRecord(id) {
    const data = {
      id: id,
    };

    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        try {
          const response = await axios.delete("api/contact/destroy", { data });

          if (response.status === 200) {
            updateContactData
              ? setUpdateContactData(false)
              : setUpdateContactData(true);
            toast.success(response.data.message);
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  //============END: CODE FOR EDIT CONTACT=================//

  var displayUserData = "";

  if (loadingContact) {
    return <Preloader show={loadingContact ? true : false} />;
  } else {
    displayUserData = listContactData.map((item, i) => {
      let Fullname = "";

      if (item.first_name && item.last_name) {
        Fullname = item.first_name + " " + item.last_name;
      } else if (item.first_name && !item.last_name) {
        Fullname = item.first_name;
      } else if (!item.first_name && item.last_name) {
        Fullname = item.last_name;
      }

      return (
        <tr key={i}>
          <td
            className="dealsname"
            onClick={() => history.push("/edit-contact/" + item.id)}
            style={{ cursor: "pointer" }}
          >
            <span style={{ textTransform: "capitalize" }}>
              {item.salutation ? item.salutation + ". " : ""}
            </span>{" "}
            {Fullname}
          </td>
          <td>{item.title ? item.title : "N/A"}</td>
          <td>{item.phone1 ? item.phone1 : "N/A"}</td>
          <td>{item.primary_email ? item.primary_email : "N/A"}</td>
          <td>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="listdot"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {userPermission ? (
                  userPermission.includes("contact_edit") ? (
                    <Dropdown.Item
                      onClick={() => {
                        editContact(item.id);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userPermission ? (
                  userPermission.includes("contact_delete") ? (
                    <Dropdown.Item
                      onClick={() => {
                        deleteRecord(item.id);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });

    return (
      <Col md={12} className="mt30">
        <div className="display flex-wrap w-100 history">
          <div className="innerdealtable w-100">
            <div className="col-md-8">
              <h5>Contacts</h5>
            </div>
            {userPermission ? (
              userPermission.includes("contact_create") ? (
                <div className="col-md-4 text-right">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="transparentbg p-0"
                    >
                      <span
                        className="editplusicon"
                        onClick={() => addContact()}
                      ></span>
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          {displayUserData.length ? (
            <>
              <div className="contanctlistnig">
                <table>
                  <tbody>
                    <tr>
                      <th style={{ width: "25%" }}>Contact Name</th>
                      <th style={{ width: "25%" }}>Title</th>
                      <th style={{ width: "20%" }}>Phone</th>
                      <th style={{ width: "20%" }}>Email</th>
                      <th style={{ width: "10%" }}></th>
                    </tr>
                    {displayUserData}
                  </tbody>
                </table>
              </div>
              {displayUserData.length >= 1 ? (
                <Col xs={12} xl={12} className="showing">
                  <Card border="light" className="bg-white border-0">
                    <Card.Body className="listcard">
                      <Row className="align-items-center">
                        <Col md={6} className="ps-2">
                          <h6>
                            Showing{" "}
                            <b>
                              {(state?.users?.per_page
                                ? state?.users?.per_page
                                : 0) *
                                (state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0) -
                                ((state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) -
                                  1)}
                            </b>{" "}
                            -{" "}
                            <b>
                              {(state?.users?.per_page
                                ? state?.users?.per_page
                                : 0) *
                                (state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0) -
                                ((state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) -
                                  1) +
                                ((dataCount ? dataCount : 0) - 1)}
                            </b>{" "}
                            of{" "}
                            <b>
                              {state?.users?.total ? state?.users?.total : 0}
                            </b>
                          </h6>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                          <Pagination
                            activePage={
                              state?.users?.current_page
                                ? state?.users?.current_page
                                : 0
                            }
                            itemsCountPerPage={
                              state?.users?.per_page
                                ? state?.users?.per_page
                                : 0
                            }
                            totalItemsCount={
                              state?.users?.total ? state?.users?.total : 0
                            }
                            onChange={(pageNumber) => {
                              currentPage = pageNumber;
                              getContactData(pageNumber);
                            }}
                            pageRangeDisplayed={5}
                            itemClass="page-item mx-1"
                            linkClass="page-link"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </>
          ) : (
            <span>No data Available</span>
          )}
        </div>

        <Modal
          as={Modal.Dialog}
          centered
          show={showAddContact}
          onHide={handleAddContactClose}
        >
          <Modal.Header>
            <Modal.Title className="h2">Add Contact</Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={handleAddContactClose}
            />
            <span className="modelclose"></span>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={defaultAddValue}
              validationSchema={validationSchema}
              onSubmit={handleAddSubmit}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  setFieldTouched,
                } = props;
                return (
                  <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                    <Row>
                      <div className="col-md-12 col-sm-12">
                        <Row className="align-items-center">
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Salutation</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="name">
                                <Select
                                  defaultValue={selectedSalutation}
                                  name="salutation"
                                  options={salutationList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(selectedOption) => {
                                    values.salutation = selectedOption.value;
                                    handleChange("salutation");
                                  }}
                                  isClearable={false}
                                  isSearchable={false}
                                  onBlur={setFieldTouched}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "var(--primary-color-light)",
                                      primary: "var(--primary-color)",
                                    },
                                  })}
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.salutation}
                                </span>
                                <p className="text-danger error4">
                                  <ErrorMessage name="salutation" />
                                </p>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                First name<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="name">
                                <Form.Control
                                  type="text"
                                  name="first_name"
                                  minLength={2}
                                  maxLength={50}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter firstname"
                                  className={
                                    errors.first_name && touched.first_name
                                      ? "input-error"
                                      : null
                                  }
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.first_name}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="first_name" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Last name<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="name">
                                <Form.Control
                                  type="text"
                                  name="last_name"
                                  minLength={2}
                                  maxLength={50}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter lastname"
                                  className={
                                    errors.last_name && touched.last_name
                                      ? "input-error"
                                      : null
                                  }
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.last_name}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="last_name" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Title</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="title">
                                <Form.Control
                                  type="text"
                                  name="title"
                                  minLength={2}
                                  maxLength={50}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter title"
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.title}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="title" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Email</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="email">
                                <Form.Control
                                  type="text"
                                  name="primary_email"
                                  minLength={2}
                                  maxLength={50}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter Email"
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.primary_email}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="primary_email" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Phone</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="phone">
                                <Form.Control
                                  type="text"
                                  name="phone"
                                  maxLength={25}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="99999 12345"
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.phone1}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="phone" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                        </Row>
                      </div>
                    </Row>

                    <div className="text-center mt30">
                      {isSubmitting ? (
                        <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                          <Spinner animation="border" size="sm" />
                          <span className="ms-2 text-center">
                            Please wait...
                          </span>
                        </Button>
                      ) : (
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="submit"
                              variant="success"
                              className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                            >
                              Create
                            </Button>
                          </div>
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="button"
                              variant="success"
                              onClick={handleAddContactClose}
                              className="popupbtn greycolor d-flex align-items-center justify-content-center"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal
          as={Modal.Dialog}
          centered
          show={showEditContact}
          onHide={handleEditContactClose}
        >
          <Modal.Header>
            <Modal.Title className="h2">Edit Contact</Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={handleEditContactClose}
            />
            <span className="modelclose"></span>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={defaultValue}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  setFieldTouched,
                } = props;
                return (
                  <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                    <Row>
                      <div className="col-md-12 col-sm-12">
                        <Row className="align-items-center">
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Salutation</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="name">
                                <Select
                                  defaultValue={selectedSalutation}
                                  name="salutation"
                                  options={salutationList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(selectedOption) => {
                                    values.salutation = selectedOption.value;
                                    handleChange("salutation");
                                  }}
                                  isClearable={false}
                                  isSearchable={false}
                                  onBlur={setFieldTouched}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "var(--primary-color-light)",
                                      primary: "var(--primary-color)",
                                    },
                                  })}
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.salutation}
                                </span>
                                <p className="text-danger error4">
                                  <ErrorMessage name="salutation" />
                                </p>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                First name<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="name">
                                <Form.Control
                                  type="text"
                                  name="first_name"
                                  minLength={2}
                                  maxLength={50}
                                  value={values.first_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter firstname"
                                  className={
                                    errors.firstname && touched.firstname
                                      ? "input-error"
                                      : null
                                  }
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.first_name}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="first_name" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Last name<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="name">
                                <Form.Control
                                  type="text"
                                  name="last_name"
                                  minLength={2}
                                  maxLength={50}
                                  value={values.last_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter lastname"
                                  className={
                                    errors.lastname && touched.lastname
                                      ? "input-error"
                                      : null
                                  }
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.last_name}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="last_name" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Title</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="title">
                                <Form.Control
                                  type="text"
                                  name="title"
                                  minLength={2}
                                  maxLength={50}
                                  value={values.title}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter title"
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.title}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="title" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Email</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="email">
                                <Form.Control
                                  type="text"
                                  name="primary_email"
                                  minLength={2}
                                  maxLength={50}
                                  value={values.primary_email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter Email"
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.primary_email}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="primary_email" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Phone</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="phone">
                                <Form.Control
                                  type="text"
                                  name="phone"
                                  maxLength={25}
                                  value={values.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="99999 12345"
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.phone1}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="phone" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                        </Row>
                      </div>
                    </Row>

                    <div className="text-center mt30">
                      {isSubmitting ? (
                        <Button className="d-flex align-items-center justify-content-center popupbtn text-center btnblue mb-1">
                          <Spinner animation="border" size="sm" />
                          <span className="ms-2 text-center">
                            Please wait...
                          </span>
                        </Button>
                      ) : (
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="submit"
                              variant="success"
                              className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                            >
                              update
                            </Button>
                          </div>
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="button"
                              variant="success"
                              onClick={handleEditContactClose}
                              className="popupbtn greycolor d-flex align-items-center justify-content-center"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      </Col>
    );
  }
};
