/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState, memo } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import Preloader from "./PreloaderCenter";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import "../assets/css/custom.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";

var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var calendorFormate = "";
var currentPage = 1;

function SalesQuotaList(props) {
  const history = useHistory();
  const userID = props.userID;
  const [loading, setLoading] = useState(true);
  const [salesQuotaDataList, setSalesQuotaDataList] = useState([]);
  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });
  const loginUser = useSelector(selectUser);

  // Decrypt
  const user = loginUser?loginUser:[];

  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  useEffect(() => {
    async function fetchData() {
      await getSalesQuotaData();
    }
    fetchData();
  }, [userID,props.endDate]);

  async function getSalesQuotaData(currentPage) {
    const params = {
      per_page: perPage,
      page: currentPage,
      user_id: userID,
      start_date: props.startDate ? moment(props.startDate).format("YYYY-MM-DD") : '',
      end_date: props.endDate ? moment(props.endDate).format("YYYY-MM-DD") : '',
    };

    await axios
      .get(`api/sales-quota?page=${currentPage}`, { params })
      .then((response) => {
        if (response.data.code === 200) {
          setSalesQuotaDataList(response.data.data.records.data);
          setPreferredCurrencyData(response.data.data.preferredCurrency);
          setCurrencyCode(response.data.data.preferredCurrency.currencyCode);
          setCurrencySymbol(
            response.data.data.preferredCurrency.currencySymbol
          );
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });
          calendorFormate = response.data.data.preDateFormat.split(" ");
          if (calendorFormate) {
            setCalendorFullDateFormate(calendorFormate[0]);
          }
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const myObj = {
    style: "currency",
    currency: currencyCode ? currencyCode : "INR",
  };

  if (loading) {
    return <Preloader show={true} />;
  } else {
    return (
      <div className="display flex-wrap w-100 history">
        <div className="innerdealtable w-100">
          <div className="col-md-8">
            <h5>Sales Quota List</h5>
          </div>
        </div>
        {salesQuotaDataList.length ? (
          <>
            <div className="contanctlistnig">
              <table>
                <tbody>
                  <tr>
                    <th style={{ width: "20%" }}>User</th>
                    <th style={{ width: "30%" }}>Quota Subject</th>
                    <th style={{ width: "20%" }}>Targeted Amount</th>
                    <th style={{ width: "20%" }}>Achieved Amount</th>
                    <th style={{ width: "10%" }}>Date</th>
                  </tr>
                  {salesQuotaDataList.map((item, i) => {
                    let num = parseInt(item.target_amount);
                    let achievedAmount = parseInt(item.achieved_amount);

                    return (
                      <tr key={i}>
                        <td className="dealsname">
                          {item?.added_by?.name || 'N/A'}
                        </td>
                        <td className="dealsname" onClick={() => history.push('/edit-quote/'+item.quote_id)}>
                          {item.subject}
                        </td>
                        <td>
                          {num
                            ? preferredCurrencyData.currencyStringValue
                              ? num.toLocaleString(
                                  preferredCurrencyData.currencyStringValue,
                                  myObj
                                )
                              : currencySymbol + num.toLocaleString(myObj)
                            : "N/A"}
                        </td>
                        <td>
                          {achievedAmount
                            ? preferredCurrencyData.currencyStringValue
                              ? achievedAmount.toLocaleString(
                                  preferredCurrencyData.currencyStringValue,
                                  myObj
                                )
                              : currencySymbol +
                                achievedAmount.toLocaleString(myObj)
                            : "N/A"}
                        </td>
                        <td>
                          {moment
                            .tz(item.date, defaultTimeZone)
                            .format(calendorFullDateFormate)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {salesQuotaDataList.length >= 1 ? (
              <div className="contanctlistnig">
                <Col xs={12} xl={12}>
                  <Card border="light" className="bg-white border-0">
                    <Card.Body className="listcard pe-0 ps-0">
                      <Row className="align-items-center">
                        <Col md={4}>
                          <h6>
                            Showing{" "}
                            <b>
                              {(state?.users?.per_page
                                ? state?.users?.per_page
                                : 0) *
                                (state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0) -
                                ((state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) -
                                  1)}
                            </b>{" "}
                            -{" "}
                            <b>
                              {(state?.users?.per_page
                                ? state?.users?.per_page
                                : 0) *
                                (state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0) -
                                ((state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) -
                                  1) +
                                ((dataCount ? dataCount : 0) - 1)}
                            </b>{" "}
                            of{" "}
                            <b>{state?.users?.total ? state?.users?.total : 0}</b>
                          </h6>
                        </Col>
                        <Col xl={4} md={4}>
                          <div className="row align-items-center g-0">
                            <div className="col-md-3 col-xl-4"></div>

                            <div className="col-md-2 col-xl-1"></div>
                          </div>
                        </Col>
                        <Col md={4} className="d-flex justify-content-end">
                          <Pagination
                            activePage={
                              state?.users?.current_page
                                ? state?.users?.current_page
                                : 0
                            }
                            itemsCountPerPage={
                              state?.users?.per_page ? state?.users?.per_page : 0
                            }
                            totalItemsCount={
                              state?.users?.total ? state?.users?.total : 0
                            }
                            onChange={(pageNumber) => {
                              currentPage = pageNumber;
                              getSalesQuotaData(pageNumber);
                            }}
                            pageRangeDisplayed={5}
                            itemClass="page-item mx-1"
                            linkClass="page-link"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <span>No data Available</span>
        )}
      </div>
    );
}
};

export default memo(SalesQuotaList);