import axios from 'axios';


//const API_ENDPOINT = 'http://multi-tenant-crm.com';

export const stripePaymentMethodHandler = async (data, cb) => {
  const { amount, result,extra } = data;
  console.log(data)
  if (result.error) {
    cb(result);
  } else {
    const paymentResponse = await stripePayment({
      payment_method_id: result.paymentMethod.id,
      name: result.paymentMethod.billing_details.name,
      email: result.paymentMethod.billing_details.email,
      amount: amount,
      extraData:extra,
    });
    cb(paymentResponse);
  }
}

const stripePayment = async data => {
    const res = await axios.post("/api/order-pay", data).then(response => {
      return response;
    }).then(json => {
    }).catch(error => {
      return error;
    });
    return res;
}