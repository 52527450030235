/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Card,
  Form,
  Spinner,
  Modal,
} from "@themesberg/react-bootstrap";
import Preloader from "../components/Preloader1";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../features/userSlice";
import Pagination from "react-js-pagination";
import "react-edit-text/dist/index.css";
import "../assets/css/custom.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import axios from "axios";

var sortOrder = process.env.REACT_APP_DEFAULT_SORT;
var perPage = process.env.REACT_APP_DEFAULT_PAGINATION;
var currentPage = 1;

var currencyCode = "";
var currencySymbol = "";
var accountID = "";

var accountList = [];
var selectedAccount = [];

var contactList = [];
var selectedContact = [];

export default (props) => {
  accountID = props.data.accountId;
  const history = useHistory();
  const loginUser = useSelector(selectUser);
  const userPermissions = useSelector((state) => state.permission);
  var defaultData = useSelector(selectDefaultData);
  
  const [leadSourceData, setleadSourceData] = useState([]);
  //const [salutationData, setSalutationData] = useState([]);
  const [preferredCurrencyData, setPreferredCurrencyData] = useState("");
  const [calendorDateFormate, setCalendorDateFormate] = useState("yyyy/MM/dd");

  const [showAddDeal, setShowAddDeal] = useState(false);
  const handleLeadClose = () => {
    setShowAddDeal(false);
  };

  const [defaultValue, setdefaultValue] = useState({
    account_id: "",
    contact_id: "",
    deal_name: "",
    lead_source: "",
    phone: "",
    deal_amount: "",
    deal_description: "",
    deal_probability: "",
    error_list: [],
  });

  //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = yup.object().shape({
    contact_id: yup.string().required("Please select Contact"),
    phone: yup.string().max(25, "Phone must be at most 25 characters"),
    //deal_name: yup.string().required('Enter deal name').min(2, 'Must be at least 2 characters').max(55, 'Must be at most 55 characters'),
    deal_name: yup
      .string()
      .required("Enter deal name")
      .trim("Deal name cannot include space")
      .strict(true)
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters"),
    deal_amount: yup.string().required("Enter deal amount"),
    deal_description: yup.string().required("Enter deal description"),
    deal_probability: yup
      .number("Only numeric value allow")
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("Enter deal probability")
      .positive()
      .max("100", "Please enter less than 100%"),
  });

  // Decrypt
  var user = loginUser?loginUser:[];
  var userPermission = userPermissions?userPermissions:[];
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
  
  const [loadingDeal, setLoadingDeal] = useState(true);
  const [listDeal, setDeal] = useState([]);

  const [calendorDealDateFormate, setCalendorDealDateFormate] =
    useState("yyyy/MM/dd");

  const [preferredDealCurrencyData, setPreferredDealCurrencyData] = useState(
    []
  );
  const [preferredDealDateFormatData, setPreferredDealDateFormatData] =
    useState("");

  const [calendorDealFullDateFormate, setCalendorDealFullDateFormate] =
    useState();

  const [expectedClosingDate, setExpectedClosingDate] = useState(
    new Date(new Date().toLocaleString("en-US", { timeZone: defaultTimeZone }))
  );

  const [expectedClosingDateError, setExpectedClosingDateError] =
    useState(false);

  //=========START:CODE FOR FOOTER PAGINATION===========//
  const [dataCount, setdataCount] = useState("");
  const [state, setData] = useState({
    users: "",
  });

  const SORT_ASC = "asc";
  const SORT_DESC = "desc";
  const [sortColumn, setSortColumn] = useState();
  //sortOrder = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC

  //=========END:CODE FOR FOOTER PAGINATION===========//

  var dealCalendorFormate = "";

  useEffect(() => {
    async function fetchData() {
      await getDealData();
    }
    fetchData();
  }, [showAddDeal]);

  const getDealData = async (currentPage) => {
    const params = {
      sort_field: sortColumn,
      sort_order: sortOrder,
      per_page: perPage,
      page: currentPage,
      account_id: accountID,
    };
    //setLoadingDeal(true);
    await axios
      .get(`api/deal?page=${currentPage}`, { params })
      .then((response) => {
        if (response.status === 200) {
          setDeal(response.data.data.records.data);
          setdataCount(response.data.data.records.data.length);
          setData({
            users: response.data.data.records,
          });

          setPreferredDealCurrencyData(
            response.data.data.preferredCurrencyData
          );
          setPreferredDealDateFormatData(response.data.data.preDateFormat);

          if (response.data.data.preferredCurrencyData.currencyCode) {
            currencyCode = response.data.data.preferredCurrencyData.currencyCode
              ? response.data.data.preferredCurrencyData.currencyCode
              : "";
            currencySymbol = response.data.data.preferredCurrencyData
              .currencySymbol
              ? response.data.data.preferredCurrencyData.currencySymbol
              : "";
          }

          dealCalendorFormate = response.data.data.preDateFormat.split(" ");
          if (dealCalendorFormate) {
            if (dealCalendorFormate[0] == "YYYY-MMM-DD") {
              setCalendorDealDateFormate("yyyy/MM/dd");
              setCalendorDealFullDateFormate("yyyy-MMM-DD");
            } else if (dealCalendorFormate[0] == "DD-MMM-YYYY") {
              setCalendorDealDateFormate("dd/MM/yyyy");
              setCalendorDealFullDateFormate("DD-MMM-yyyy");
            } else if (dealCalendorFormate[0] == "YYYY-DD-MMM") {
              setCalendorDealDateFormate("yyyy/dd/MM");
              setCalendorDealFullDateFormate("yyyy-DD-MMM");
            } else if (dealCalendorFormate[0] == "MMM-DD-YYYY") {
              setCalendorDealDateFormate("MM/dd/yyyy");
              setCalendorDealFullDateFormate("MMM-DD-yyyy");
            }
            setCalendorDealFullDateFormate(dealCalendorFormate[0]);
          }
          setLoadingDeal(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoadingDeal(false);
          if (error.response.status === 400 || error.response.status === 401) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden" + error.response.data.message);
            history.push("/dashboard");
          } else {
            toast.error(error.response.data.message);
            history.push("/dashboard");
          }
        }
      });
  };

  async function addDeal() {
    // setLoadingDeal(true)
    await axios
      .get("api/deal/create?accountId=" + accountID)
      .then((response) => {
        if (response.status === 200) {
          setleadSourceData(response.data.data.leadSources);
          setPreferredCurrencyData(response.data.data.preferredCurrencyData);

          setdefaultValue({
            account_id: accountID,
            contact_id: "",
            deal_name: "",
            lead_source: "",
            phone: "",
            deal_amount: "",
            deal_description: "",
            deal_probability: "",

            error_list: [],
          });

          for (
            var i = 0, l = response.data.data.selectAccountData.length;
            i < l;
            i++
          ) {
            accountList.push({
              label: response.data.data.selectAccountData[i].organization_name,
              value: response.data.data.selectAccountData[i].id,
            });
          }

          for (
            var i = 0, l = response.data.data.selectAccountData.length;
            i < l;
            i++
          ) {
            if (
              accountID.includes(response.data.data.selectAccountData[i].id)
            ) {
              selectedAccount.push({
                label:
                  response.data.data.selectAccountData[i].organization_name,
                value: response.data.data.selectAccountData[i].id,
              });
            }
          }

          var displayname = "";
          contactList = [];
          for (
            var i = 0, l = response.data.data.selectContactData.length;
            i < l;
            i++
          ) {
            if (
              response.data.data.selectContactData[i].first_name &&
              response.data.data.selectContactData[i].last_name
            ) {
              displayname =
                response.data.data.selectContactData[i].first_name +
                " " +
                response.data.data.selectContactData[i].last_name;
            } else if (
              response.data.data.selectContactData[i].first_name &&
              !response.data.data.selectContactData[i].last_name
            ) {
              displayname = response.data.data.selectContactData[i].first_name;
            } else if (
              !response.data.data.selectContactData[i].first_name &&
              response.data.data.selectContactData[i].last_name
            ) {
              displayname = response.data.data.selectContactData[i].last_name;
            }

            if (response.data.data.selectContactData[i].salutation) {
              displayname =
                response.data.data.selectContactData[i].salutation +
                ". " +
                displayname;
            }

            contactList.push({
              label: displayname,
              value: response.data.data.selectContactData[i].id,
            });
          }

          setLoadingDeal(false);
          setShowAddDeal(true);
          setExpectedClosingDate(
            new Date(
              new Date().toLocaleString("en-US", {
                timeZone: defaultTimeZone ? defaultTimeZone : "",
              })
            )
          );
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          setLoadingDeal(false);
          if (error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const handleSubmit = async (values, onSubmitProps) => {
    const data = {
      account_id: values.account_id,
      contact_id: values.contact_id,
      deal_amount: values.deal_amount,
      deal_description: values.deal_description,
      deal_name: values.deal_name,
      deal_probability: values.deal_probability,
      lead_source: values.lead_source,
      expected_closing_date: moment(expectedClosingDate).format("YYYY-MM-DD"),
      phone1: values.phone,
      added_from: "account",
    };

    if (!expectedClosingDateError) {
      await axios
        .post("/api/deal/store", data)
        .then((response) => {
          if (response.status === 200) {
            setShowAddDeal(false);
            toast.success("Deal has been created successfully");
          }
        })
        .then((json) => {})
        .catch((error) => {
          if (error.response) {
            onSubmitProps.setSubmitting(false);
            if (error.response.status === 400) {
              setdefaultValue({
                ...defaultValue,
                error_list: error.response.data.validation_errors,
              });
            } else if (error.response.status === 403) {
              toast.warning("Forbidden " + error.response.data.message);
            } else {
              toast.warning(error.response.data.message);
            }
          }
        });
    }
  };

  let leadSourceList = [];
  leadSourceData.map((item) => {
    leadSourceList.push({ label: item.title, value: item.title });
  }, this);

  const customStyles = {
    menuList: (styles) => ({
      ...styles,
      //color:'black',
      // backgroundcolor:'$blue',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  var displayUserData = "";
  var ScrollbarsHeight = 150;
  if (loadingDeal) {
    return <Preloader show={loadingDeal ? false : true} />;
  } else {
    displayUserData = listDeal.map((item, i) => {
      let fNameDeal = item.get_contact ? item.get_contact.first_name : "N/A";
      let lNameDeal = item.get_contact ? item.get_contact.last_name : "N/A";

      let FFNameDeal = fNameDeal.charAt(0) + lNameDeal.charAt(0);

      let displayProductName = "";
      if (item.selected_product) {
        if (item.selected_product.length > 3) {
          for (var i = 0; i < 3; i++) {
            displayProductName += item.selected_product[i].name + ", ";
          }
        } else {
          for (var i = 0, l = item.selected_product.length; i < l; i++) {
            displayProductName += item.selected_product[i].name + ", ";
          }
        }
      }

      let num = parseInt(item.deal_amount);
      const myObj = {
        style: "currency",
        currency: currencyCode,
      };

      let dealName = "N/A";
      if (item.deal_name) {
        dealName = item.deal_name;
      } else {
        if (item.get_contact && item.get_contact.title) {
          dealName = item.get_contact.title;
        }
      }

      return (
        <tr key={i}>
          <td className="dealsname">
            <span
              onClick={() => history.push("/edit-deal/" + item.id)}
              style={{ cursor: "pointer" }}
            >
              {dealName}
            </span>
          </td>
          <td>
            {num
              ? preferredDealCurrencyData.currencyStringValue
                ? num.toLocaleString(
                    preferredDealCurrencyData.currencyStringValue,
                    myObj
                  )
                : currencySymbol + num.toLocaleString(myObj)
              : "N/A"}
          </td>
          <td>
            {item.selected_stage_name
              ? item.selected_stage_name.name
                ? item.selected_stage_name.name
                : "N/A"
              : "N/A"}
          </td>
          <td>{item.deal_probability ? item.deal_probability : "N/A"}</td>
          <td>
            {item.expected_closing_date
              ? moment
                  .tz(item.expected_closing_date, defaultTimeZone)
                  .format(calendorDealFullDateFormate)
              : "N/A"}
          </td>
          <td>
            {item.closing_date
              ? moment
                  .tz(item.closing_date, defaultTimeZone)
                  .format(calendorDealFullDateFormate)
              : "N/A"}
          </td>
          <td>
            {moment
              .tz(item.created_at, defaultTimeZone)
              .format(preferredDealDateFormatData)}
          </td>
        </tr>
      );
    });

    if (listDeal && listDeal.length > 2) {
      ScrollbarsHeight = 300;
    }

    return (
      <Col md={12} className="mt30">
        <div className="display flex-wrap w-100 history">
          <div className="innerdealtable w-100">
            <div className="col-md-8">
              <h5>Deals</h5>
            </div>
            {userPermission ? (
              userPermission.includes("deal_create") ? (
                <div className="col-md-4 text-right">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="transparentbg p-0"
                    >
                      <span
                        className="editplusicon"
                        onClick={() => addDeal()}
                      ></span>
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          {displayUserData.length ? (
            <>
              {/* <Scrollbars style={{ height: ScrollbarsHeight }} className="scrollbar-bx"> */}
              <div className="contanctlistnig">
                <table>
                  <tbody>
                    <tr>
                      <th style={{ width: " 20%" }}>Deal Name</th>
                      <th style={{ width: " 15%" }}>Amount</th>
                      <th style={{ width: " 15%" }}>Stage</th>
                      <th style={{ width: " 5%" }}>Probability (%)</th>
                      <th style={{ width: " 10%" }}>Expected Closing Date</th>
                      <th style={{ width: " 10%" }}>Closing Date</th>
                      <th style={{ width: " 15%" }}>Created Date</th>
                    </tr>
                    {displayUserData}
                  </tbody>
                </table>
              </div>
              {/* </Scrollbars> */}

              {displayUserData.length >= 1 ? (
                <Col xs={12} xl={12} className="showing">
                  <Card border="light" className="bg-white border-0">
                    <Card.Body className="listcard">
                      <Row className="align-items-center">
                        <Col md={6} className="ps-2">
                          <h6>
                            Showing{" "}
                            <b>
                              {(state?.users?.per_page
                                ? state?.users?.per_page
                                : 0) *
                                (state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0) -
                                ((state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) -
                                  1)}
                            </b>{" "}
                            -{" "}
                            <b>
                              {(state?.users?.per_page
                                ? state?.users?.per_page
                                : 0) *
                                (state?.users?.current_page
                                  ? state?.users?.current_page
                                  : 0) -
                                ((state?.users?.per_page
                                  ? state?.users?.per_page
                                  : 0) -
                                  1) +
                                ((dataCount ? dataCount : 0) - 1)}
                            </b>{" "}
                            of{" "}
                            <b>
                              {state?.users?.total ? state?.users?.total : 0}
                            </b>
                          </h6>
                        </Col>

                        <Col md={6} className="d-flex justify-content-end">
                          <Pagination
                            activePage={
                              state?.users?.current_page
                                ? state?.users?.current_page
                                : 0
                            }
                            itemsCountPerPage={
                              state?.users?.per_page
                                ? state?.users?.per_page
                                : 0
                            }
                            totalItemsCount={
                              state?.users?.total ? state?.users?.total : 0
                            }
                            onChange={(pageNumber) => {
                              currentPage = pageNumber;
                              getDealData(pageNumber);
                            }}
                            pageRangeDisplayed={5}
                            itemClass="page-item mx-1"
                            linkClass="page-link"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </>
          ) : (
            <span>No data Available</span>
          )}
        </div>

        <Modal
          as={Modal.Dialog}
          centered
          show={showAddDeal}
          onHide={handleLeadClose}
        >
          <Modal.Header>
            <Modal.Title className="h2">Add Deal</Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={handleLeadClose}
            />
            <span className="modelclose"></span>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={defaultValue}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting(false));
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                  setFieldTouched,
                } = props;
                return (
                  <Form encrypted="multipart/form-data" onSubmit={handleSubmit}>
                    <Row>
                      <div className="col-md-12 col-sm-12">
                        <Row className="align-items-center">
                          <Form.Group className="mb-125 firstrow position-relative">
                            <Col sm="12" className="Display">
                              <Form.Label column sm="12">
                                <h6 className="fw600">Account</h6>
                              </Form.Label>
                              <Form.Group id="account_id">
                                <Select
                                  defaultValue={accountList}
                                  name="account_id"
                                  options={selectedAccount}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  styles={customStyles}
                                  onChange={(selectedOption) => {
                                    values.account_id = selectedOption.value;
                                    handleChange("account_id");
                                  }}
                                  isDisabled
                                  isClearable={false}
                                  isSearchable={false}
                                  onBlur={setFieldTouched}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "var(--primary-color-light)",
                                      primary: "var(--primary-color)",
                                    },
                                  })}
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.account_id}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="account_id" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 firstrow position-relative">
                            <Col sm="12" className="Display">
                              <Form.Label column sm="12">
                                <h6 className="fw600">
                                  Contact<span className="text-danger">*</span>
                                </h6>
                              </Form.Label>
                              <Form.Group id="contact_id">
                                <Select
                                  defaultValue={selectedContact}
                                  name="contact_id"
                                  options={contactList}
                                  className={
                                    errors.contact_id && touched.contact_id
                                      ? "input-select-error basic-multi-select"
                                      : "basic-multi-select"
                                  }
                                  classNamePrefix="select"
                                  styles={customStyles}
                                  onChange={(selectedOption) => {
                                    values.contact_id = selectedOption.value;
                                    handleChange("contact_id");
                                  }}
                                  isClearable={false}
                                  isSearchable={true}
                                  onBlur={setFieldTouched}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "var(--primary-color-light)",
                                      primary: "var(--primary-color)",
                                    },
                                  })}
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.contact_id}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="contact_id" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>

                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Deal name<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="title">
                                <Form.Control
                                  type="text"
                                  name="deal_name"
                                  minLength={2}
                                  maxLength={50}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter deal name"
                                  className={
                                    errors.deal_name && touched.deal_name
                                      ? "input-error"
                                      : null
                                  }
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.deal_name}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="deal_name" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>

                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Lead source</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="lead_source">
                                <Select
                                  //defaultValue={selectedStage}
                                  name="lead_source"
                                  options={leadSourceList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  //onChange={handleLeadSourceInput}
                                  onChange={(selectedOption) => {
                                    values.lead_source = selectedOption.value;
                                    handleChange("lead_source");
                                  }}
                                  onBlur={handleBlur}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "var(--primary-color-light)",
                                      primary: "var(--primary-color)",
                                    },
                                  })}
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.lead_source}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="lead_source" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>

                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Phone</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="phone">
                                <Form.Control
                                  type="text"
                                  name="phone"
                                  maxLength={25}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="99999 12345"
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.phone1}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="phone" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>

                          <Form.Group className="mb-125 position-relative ">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Amount{" "}
                                {preferredCurrencyData.currencyCode
                                  ? preferredCurrencyData.currencyCode + " "
                                  : " "}
                                (
                                {preferredCurrencyData.currencySymbol
                                  ? preferredCurrencyData.currencySymbol
                                  : ""}
                                )<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="deal_amount">
                                <CurrencyInput
                                  id="deal_amount"
                                  name="deal_amount"
                                  intlConfig={{
                                    locale:
                                      preferredCurrencyData.currencyStringValue
                                        ? preferredCurrencyData.currencyStringValue
                                        : "en-IN",
                                    currency: preferredCurrencyData.currencyCode
                                      ? preferredCurrencyData.currencyCode
                                      : "INR",
                                  }}
                                  placeholder="Enter deal amount"
                                  className={
                                    errors.deal_amount && touched.deal_amount
                                      ? "form-control input-error"
                                      : "form-control"
                                  }
                                  onValueChange={(value, name) => {
                                    setdefaultValue({
                                      ...defaultValue,
                                      ["deal_amount"]: value,
                                    });
                                    values.deal_amount = value;
                                    handleChange(name);
                                  }}
                                  decimalsLimit={6}
                                  value={defaultValue.deal_amount}
                                  step={1}
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.deal_amount}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="deal_amount" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>

                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Probability (%)
                                <span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="deal_probability">
                                <Form.Control
                                  type="number"
                                  max={100}
                                  step={0.01}
                                  name="deal_probability"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter probability"
                                  className={
                                    errors.deal_probability &&
                                    touched.deal_probability
                                      ? "proability errormessage input-error"
                                      : "proability errormessage"
                                  }
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.deal_probability}
                                </span>
                                <span className="percetangeicon"></span>
                                <span className="error error4">
                                  <ErrorMessage name="deal_probability" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <Form.Group className="mb-125 position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Expected closing date</h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="expected_closing_date">
                                <DatePicker
                                  autocomplete="off"
                                  selected={
                                    expectedClosingDate == ""
                                      ? new Date()
                                      : !expectedClosingDateError
                                      ? expectedClosingDate
                                      : ""
                                  }
                                  onChange={(date) => {
                                    if (date) {
                                      setExpectedClosingDate(date);
                                      setExpectedClosingDateError(false);
                                    } else {
                                      setExpectedClosingDateError(true);
                                    }
                                  }}
                                  dateFormat={calendorDateFormate}
                                  className="form-control"
                                />
                              </Form.Group>
                              <span className="calenderinput"></span>
                              <span className="error error4">
                                {defaultValue.error_list.expected_closing_date}
                              </span>
                              <span className="error error4">
                                {expectedClosingDateError
                                  ? "Enter expected closing date"
                                  : ""}
                              </span>
                            </Col>
                          </Form.Group>
                          <Form.Group className="position-relative">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Description
                                <span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group id="deal_description">
                                <Form.Control
                                  as="textarea"
                                  minLength={2}
                                  maxLength={150}
                                  name="deal_description"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter description"
                                  className={
                                    errors.deal_description &&
                                    touched.deal_description
                                      ? "input-error"
                                      : null
                                  }
                                />
                                <span className="error error4">
                                  {defaultValue.error_list.deal_description}
                                </span>
                                <span className="error error4">
                                  <ErrorMessage name="deal_description" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                        </Row>
                      </div>
                    </Row>

                    <div className="text-center mt30">
                      {isSubmitting ? (
                        <Button className="d-flex align-items-center popupbtn btnblue justify-content-center text-center btnblue mb-1">
                          <Spinner animation="border" size="sm" />
                          <span className="ms-2 text-center">
                            Please wait...
                          </span>
                        </Button>
                      ) : (
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="submit"
                              variant="success"
                              className="popupbtn btnblue mr15 d-flex align-items-center justify-content-center"
                            >
                              Create
                            </Button>
                          </div>
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <Button
                              type="button"
                              variant="success"
                              onClick={handleLeadClose}
                              className="popupbtn greycolor d-flex align-items-center justify-content-center"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      </Col>
    );
  }
};
