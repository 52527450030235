import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Dropdown, Form, InputGroup, Modal, Spinner } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import moment from "moment-timezone";
import 'react-edit-text/dist/index.css';
import '../assets/css/custom.css';
import { useHistory, } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from "react-redux";
import { selectUser,selectDefaultData  } from "../features/userSlice";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import Fancybox from "./Fancybox"

var fileType = '';
var fileExtentionType = '';
var parentId = ''
var parentType = 'lead'
var isSubmitting = true
var displayErrorMsg = '';


const NoteListComp = (props) => {


  if (props.data.type == 'deal') {
    parentId = props.data.dealId;
  } else if (props.data.type == 'lead') {
    parentId = props.data.leadId;
  }

  if (props.data.type) {
    parentType = props.data.type;
  }

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [allNotes, setallNotes] = useState([]);
  const [preferredDateFormatData, setPreferredDateFormatData] = useState(props.data.preferredDateFormatData);

  const loginUser = useSelector(selectUser);

  const user = loginUser?loginUser:[];

  const userPermissions = useSelector(state =>
      state.permission
  )

  const userPermission = userPermissions?userPermissions:[];
  
  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;


  useEffect(() => {
    async function fetchData() {
      await getNotes();
    }
    fetchData();
  }, []);

  async function getNotes() {
    await axios.get('api/' + parentType + '-note?' + parentType + 'Id=' + parentId).then(response => {
      if (response.data.code === 200) {
        setallNotes(response.data.data.records);
      }
    }).then(json => {

    }).catch(error => {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 400) {
          toast.warning(error.response.data.message);
        }else if (error.response.status === 403) {
          //swal("error", error.response.data.message, "error");
          toast.error('Forbidden '+ error.response.data.message);
        } else {
          toast.error('Oops Something went wrong.');
        }
      }
    });
  }


  //==================================START:ADD and EDIT LEAD NOTE================================//

  const [showNoteDefault, setShowNoteDefault] = useState(false);
  const handleNoteClose = () => setShowNoteDefault(false);

  const [defaultNoteTypeFile, setDefaultNoteTypeFile] = useState('');
  const [defaultNoteTypeFileMsg, setDefaultNoteTypeFileMsg] = useState('');

  const [submitNote, setSubmitNote] = useState(false);

  const [addNoteAttachmentFileType, setAddNoteAttachmentFileType] = useState(false);

  const [notes, setNotes] = useState("");
  const [description, setDescription] = useState("");
  const [blobAzureUrl, setBblobAzureUrl] = useState("");

  const [fileSizeMsg, setFileSizeMsg] = useState('');
  const [fileTypeMsg, setFileTypeMsg] = useState('');

  const [errorOnFile, setErrorOnFile] = useState([]);

  const [backendFileMsg, setBackendFileMsg] = useState('');

  const [noteInput, setNoteInput] = useState({
    notes: '',
    description: '',
    file_url: [],
    error_list: [],
  });

  const noteNotesRef = useRef();
  const noteDescriptionRef = useRef();
  const noteblobAzureUrlRef = useRef();
  const [noteImageArray, setNoteImageArray] = useState([]);

  const [noteNotesError, setNoteNotesError] = useState(false);
  const [noteDescriptionError, setNoteDescriptionError] = useState(false);
  const [noteBlobAzureUrlError, setNoteBlobAzureUrlError] = useState(false);

  const [formNoteNotesError, setFormNoteNotesError] = useState(false);
  const [formNoteDescriptionError, setFormNoteDescriptionError] = useState(false);
  const [formNoteBlobAzureUrlError, setFormNoteBlobAzureUrlError] = useState(false);
  const [fileUploadErrorMsg, setFileUploadErrorMsg] = useState([]);


  const openNotePopup = (e) => {
    setDefaultNoteTypeFile('');
    setDefaultNoteTypeFileMsg('');
    setFileUploadErrorMsg('');
    setNoteInput({ notes: '', description: '', file_url: [], error_list: [] });
    setShowNoteDefault(true);
    setNoteImageArray([]);
    setFileSizeMsg('')
    setFileTypeMsg('')
    setBackendFileMsg('')
    setAddNoteAttachmentFileType(false)
  }


  const handleNoteInput = (e) => {
    e.persist();
    setBackendFileMsg('')
    setNoteInput({ ...noteInput, [e.target.name]: e.target.value });
  }

  const handleNoteImageInput = (e) => {
    e.persist();
    setBackendFileMsg('')
    let ImagesArray = e.target.files;
    setNoteImageArray([...noteImageArray, ...e.target.files]);
    e.target.value=''
  }

  
  function deleteFile(e) {
    const s = noteImageArray.filter((item, index) => index !== e);
    setErrorOnFile(s)
    setNoteImageArray(s);
    setAddNoteAttachmentFileType(false);
    setFileSizeMsg('')
    setFileTypeMsg('')
    isSubmitting = false
    setBackendFileMsg('')
  }

  
  const noteSubmit = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('leadId', parentId)
    formData.append('dealId', parentId)
    formData.append('notes', noteInput.notes)
    formData.append('description', noteInput.description)
    formData.append('type', 'Attachment')
    
    var expfileType = '';
    for (var i = 0; i < noteImageArray.length; i++) {
      formData.append('file_url[]', noteImageArray[i])
      
      expfileType = noteImageArray[i].name.split('.')
      if(expfileType && expfileType.length>1){
        expfileType = expfileType.pop()
      }
      
      if((noteImageArray[i].size/1024) > 4096){

        setErrorOnFile([...errorOnFile, noteImageArray[i].name]);

        setAddNoteAttachmentFileType(true);
        setFileSizeMsg('Sorry! Maximum allowed size for file is 4MB')
        isSubmitting = false
        break
      }
      
      if (["jpeg", "png", "jpg", "gif", "svg","xls", "xlsx","pdf","doc", "docx", "ppt", "pptx", "ods", "odt", "odp", "csv", "anytext", "plain","mp4", "mp3", "3gpp", "x-msvideo"].includes(expfileType)){
        isSubmitting = true
      }else{
        setErrorOnFile([...errorOnFile, noteImageArray[i].name]);
        isSubmitting = false
        setAddNoteAttachmentFileType(true);
        setFileTypeMsg('File format are not supported')
        break
      }

    }
    
   
    if(!addNoteAttachmentFileType && isSubmitting){
      setSubmitNote(true);
      await axios.post('/api/' + parentType + '-note/store', formData).then(response => {
        if (response.status === 200) {
          setSubmitNote(false);
          setNoteImageArray([]);
          setErrorOnFile([])
          toast.success('Note created successfully');
          setNoteInput({ notes: '', description: '', file_url: [], error_list: [] });
          setShowNoteDefault(false);
          getNotes();
        }
      }).then(json => {

      }).catch(error => {
        if (error.response) {
          setSubmitNote(false);
          setErrorOnFile([])
          if (error.response.status === 400) {
            setNoteInput({ ...noteInput, error_list: error.response.data.validation_errors });
            //setFileUploadErrorMsg('Sorry! Maximum allowed size for file is 4MB');
            setNoteImageArray([]);
          } else if (error.response.status === 403) {
            toast.error('Forbidden '+ error.response.data.message);
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          }else if (error.response.status === 401) {
            setBackendFileMsg(error.response.data.message)
          }else {
            toast.warning(error.response.data.message);
          }
        }
      });
    }
    

    
    
  }

  //======================================FOR: EDIT NOTES========================================
  const [showEditNoteDefault, setShowEditNoteDefault] = useState(false);
  const handleEditNoteClose = () => setShowEditNoteDefault(false);

  const [editNoteId, seteditNoteId] = useState();
  const [invalidNoteImage, setInvalidNoteImage] = useState();
  const [noteEditImageArray, setNoteEditImageArray] = useState([]);
  const [noteNotes, setNoteNotes] = useState();
  const [noteType, setNoteType] = useState();
  const [noteDescription, setNoteDescription] = useState();
  const [noteBlobAzureUrl, setNoteBlobAzureUrl] = useState();
  const [fileUploadEditErrorMsg, setFileUploadEditErrorMsg] = useState([]);
  const [noteEditNotesError, setNoteEditNotesError] = useState(false);
  const [noteEditDescriptionError, setNoteEditDescriptionError] = useState(false);
  const [noteEditBlobAzureUrlError, setNoteEditBlobAzureUrlError] = useState(false);

  const [noteEditNotesMsg, setNoteEditNotesMsg] = useState('');
  const [noteEditDescriptionMsg, setNoteEditDescriptionMsg] = useState('');
  const [noteEditBlobAzureUrlMsg, setNoteEditBlobAzureUrlMsg] = useState('');

  const [imageNoteUrl, setimageNoteUrl] = useState([]);
  const [defaultNoteEditTypeFileMsg, setDefaultNoteEditTypeFileMsg] = useState('');

  const [errorNoteEditInput, setErrorNoteEditInput] = useState({ error_list: [] });

  const [submitEditNote, setSubmitEditNote] = useState(false)

  const [editNoteInput, setEditNoteInput] = useState({
    notes: '',
    description: '',
    file_url: [],
    error_list: [],
  });

  async function openNoteEditModel(id) {

    setDefaultNoteTypeFile('');
    setDefaultNoteTypeFileMsg('');
    setFileUploadErrorMsg('');
    setFileSizeMsg('')
    setFileTypeMsg('')
    setBackendFileMsg('')
    
    seteditNoteId(id);
    setInvalidNoteImage('');

    setDefaultNoteTypeFile('');
    setFileUploadEditErrorMsg('');
    setNoteEditImageArray([]);

    //hide validation msg when opne new popup model
    errorNoteEditInput.error_list.notes = '';
    errorNoteEditInput.error_list.description = '';
    setNoteEditNotesError(false);
    setNoteEditDescriptionError(false);

    await axios.get('api/' + parentType + '-note/edit/' + id + '?' + parentType + 'Id=' + parentId).then(response => {
      if (response.data.code === 200) {
        setNoteType(response.data.data.record.type);
        setNoteNotes(response.data.data.record.notes);
        setNoteDescription(response.data.data.record.description);
        setNoteBlobAzureUrl(response.data.data.record.blob_azure_url);
        if (response.data.data.record.type == 'Image') {
          setDefaultNoteEditTypeFileMsg('JPG, GIF or PNG. Max size of 4MB');
        } else if (response.data.data.record.type == 'Attachment') {
          setDefaultNoteEditTypeFileMsg('pdf,xlsx,doc,docx,ppt,pptx,csv,text,excel up to 4MB');
        } else if (response.data.data.record.type == 'Voice') {
          setDefaultNoteEditTypeFileMsg('Audio, mp3, mp4 up to 4MB');
        }

        setEditNoteInput({
          notes: response.data.data.record.notes,
          description: response.data.data.record.description,
          file_url: [],
          error_list: [],
        })
      }
      setShowEditNoteDefault(true);

    }).then(json => {

    }).catch(error => {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 400) {
          toast.warning(error.response.data.message);
        }else if (error.response.status === 403) {
          //swal("error", error.response.data.message, "error");
          toast.error('Forbidden '+ error.response.data.message);
        } else if (error.response.status === 401) {
          toast.warning(error.response.data.message);
        } else {
          toast.error('Oops Something went wrong.');
        }
      }
    });

  }

  const handleEditNoteInput = (e) => {
    e.persist();
    setBackendFileMsg('')
    setEditNoteInput({ ...editNoteInput, [e.target.name]: e.target.value });
  }

  const handleEditNoteImageInput = (e) => {
    e.persist();
    setFileUploadEditErrorMsg('');
    setBackendFileMsg('')
    let ImagesArray = e.target.files;
    setNoteEditImageArray([...noteEditImageArray, ...e.target.files]);
    e.target.value=''
  }

  function deleteEditFile(e) {
    const s = noteEditImageArray.filter((item, index) => index !== e);
    setNoteEditImageArray(s);

    setAddNoteAttachmentFileType(false);
    isSubmitting = false

    setFileTypeMsg('')
    setFileSizeMsg('')
    setBackendFileMsg('')

  }

  const handleNoteEditSubmit = async (e) => {
   
    e.preventDefault();
    const formData = new FormData();
    formData.append('leadId', parentId)
    formData.append('dealId', parentId)
    formData.append('id', editNoteId)
    formData.append('notes', editNoteInput.notes)
    formData.append('description', editNoteInput.description)
    formData.append('type', 'Attachment')

    var expfileType = '';
    for (var i = 0; i < noteEditImageArray.length; i++) {
      formData.append('file_url[]', noteEditImageArray[i])

      expfileType = noteEditImageArray[i].name.split('.')
      if(expfileType && expfileType.length>1){
        expfileType = expfileType.pop()
      }
      
      if((noteEditImageArray[i].size/1024) > 4989){
        setErrorOnFile([...errorOnFile, noteEditImageArray[i].name]);
        setAddNoteAttachmentFileType(true);
        setFileSizeMsg('Sorry! Maximum allowed size for file is 4MB')
        isSubmitting = false
        break
      }
      
      if (["jpeg", "png", "jpg", "gif", "svg","xls", "xlsx","pdf","doc", "docx", "ppt", "pptx", "ods", "odt", "odp", "csv", "anytext", "plain","mp4", "mp3", "3gpp", "x-msvideo"].includes(expfileType)){
        isSubmitting = true
      }else{
        setErrorOnFile([...errorOnFile, noteEditImageArray[i].name]);
        isSubmitting = false
        setAddNoteAttachmentFileType(true);
        setFileTypeMsg('File format are not supported')
        break
      }

    }

    if(!addNoteAttachmentFileType && isSubmitting){
      setSubmitEditNote(true);
      await axios.post('/api/' + parentType + '-note/update', formData).then(response => {
        if (response.status === 200) {
          setSubmitEditNote(false);
          setNoteEditImageArray([]);
          toast.success('Note updated successfully');
          setEditNoteInput({ notes: '', description: '', file_url: [], error_list: [] });
          setShowEditNoteDefault(false);
          setErrorOnFile([])
          getNotes();
          setBackendFileMsg('')
        }
      }).then(json => {

      }).catch(error => {
        if (error.response) {
          setSubmitEditNote(false);
          setErrorOnFile([])
          setNoteEditImageArray([]);
          if (error.response.status === 400) {
            setEditNoteInput({ ...editNoteInput, error_list: error.response.data.validation_errors });
            setFileUploadEditErrorMsg('Sorry! Maximum allowed size for file is 4MB');
          } else if (error.response.status === 403) {
            //swal("error", error.response.data.message, "error");
            toast.error('Forbidden '+ error.response.data.message);
          }else if (error.response.status === 300) {
            setBackendFileMsg(error.response.data.message)
          }else {
            toast.warning(error.response.data.message);
          }
        }
      });
    }
    
  }

  async function deleteNote(leadID, noteID) {
    const data = {
      leadId: leadID,
      dealId: leadID,
      id: noteID
    }
  
    try {
      const willDelete = await swal({
        title: "Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
  
      if (willDelete) {
        try {
          const response = await axios.delete('api/' + parentType + '-note/destroy', { data });
  
          if (response.status === 200) {
            toast.success('Note Deleted Successfully.');
            getNotes();
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              toast.warning(error.response.data.message);
            } else if (error.response.status === 403) {
              toast.warning('Forbidden ' + error.response.data.message);
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      }
    } catch (error) {
      toast.error("Error in swal: ", error);
    }
  }

  
  const handleDownloadClick = (url) => {
    window.location.href = url
  }
  //==================================END:ADD and EDIT LEAD NOTE================================//  

  const addDefaultSrc = (e) => {
    e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
  }

  let displayNote = allNotes.length > 0 && allNotes.map((item, i) => {
    return (
      <div className="notes_body notes_bottom" key={i}>
        <div className="notes_txt_section section1 display align-items-start">
          <div className="notes_icon">
            <span className="usericon"></span>
          </div>
          <div className="notes_txt">
            <div className="note_paragraph">
              {
                item.notes ? 
                <h6>{item.notes}</h6>
                :
                ''
              }
              {
                item.description ? 
                <p>{item.description}</p>
                :
                ''
              }
            </div>
            <div className="notes_img display flex-wrap">

              {
                item.attachment.length > 0 ?

                  item.attachment.map((itemAttachment, j) => {
                    var displayFileName = '';
                    var extDisplayFileName = '';
                    var fileExtentionType = '';
                    var fileType = '';
                    var fileName = itemAttachment.file_url;
                    var boxClassName = 'emptydiv widthimg';

                    fileType = fileName.split('.')
                    if (fileType.length == 2) {
                      fileExtentionType = fileType.pop()
                    }

                    extDisplayFileName = fileName.split('/notes/')
                    if (extDisplayFileName.length == 2) {
                      displayFileName = extDisplayFileName.pop()
                    }

                    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtentionType)) {
                      boxClassName = 'examplesimg widthimg'
                    }

                    return (
                      <>
                        <a href="#" className="displayimg">
                          <span className={boxClassName}>
                            <div className="widthimghover" key={j}>
                              {
                                boxClassName == 'emptydiv widthimg' ?
                                  <>
                                  <h6><span> {displayFileName} </span></h6>
                                  <div className="overly-bx">
                                    <a target="_blank" href={`${process.env.REACT_APP_API_URL}storage/${itemAttachment.file_url}`}>
                                      <span className="downloadicon"> </span>
                                    </a>
                                    </div>
                                    <div className="overly-img"></div>
                                  </>
                                  :
                                  <>
                                    <div className="overly-bx">


                                      <Fancybox options={{ infinite: false }}>
                                        <p>
                                          <span
                                            data-fancybox="gallery"
                                            data-src={`${process.env.REACT_APP_API_URL}storage/${itemAttachment.file_url}`}
                                            className="zoomicon">
                                          </span>
                                        </p>
                                      </Fancybox>

                                    </div>
                                    <div className="overly-img">
                                      <img onError={addDefaultSrc} src={`${process.env.REACT_APP_API_URL}storage/${itemAttachment.file_url}`} alt={item.notes} className="rounded" />
                                    </div>
                                  </>
                              }
                              {/* <span className="zoomicon">
                                  <ImageViewer>
                                        <img onError={addDefaultSrc} src={`${process.env.REACT_APP_API_URL}storage/${itemAttachment.file_url}`}  alt={item.notes} className="rounded" />
                                  </ImageViewer>
                                  </span>
                                  <span className="downloadicon" onClick={() => handleDownloadClick(`${process.env.REACT_APP_API_URL}storage/${itemAttachment.file_url}`)}></span> */}

                            </div>

                            <p>{displayFileName}</p>
                          </span>
                        </a>
                      </>
                    )
                  }, this)

                  :

                  ''
              }
            </div>
            <div className="notes_date display">
              <p className="display"><span className="watchicon"></span>{moment.tz(item.created_at, defaultTimeZone).format(preferredDateFormatData)}</p>
            </div>
          </div>
          <div className="notes_action text-right">
          {
                userPermission ?
                      (userPermission.includes('lead_edit','deal_edit')) ?
                      <span className="editicon" onClick={() => { openNoteEditModel(item.id) }} style={{ cursor: 'pointer' }}></span>
                      :
                      ''
                :
                ''
            }
            {
                userPermission ?
                      (userPermission.includes('lead_delete','deal_delete')) ?
                      <span className="deleteicon" onClick={() => { deleteNote(parentId, item.id) }} style={{ cursor: 'pointer' }}></span>
                      :
                      ''
                :
                ''
            }
            
            
          </div>
        </div>
      </div>
    )
  }, this);

  return (
    <>

      <div className="col-md-8">
        <div className="notes_section section1 sideborader sideplus">
          <div className="notes_header p2022 display acvitybottom">
            <div className="col-md-8">
              <h4>Notes</h4>
            </div>
            {
                userPermission ?
                      (userPermission.includes('lead_create','deal_create')) ?
                      <div className="col-md-4 text-right">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="transparentbg p-0">
                              <span className="editplusicon" onClick={() => openNotePopup()}></span>
                            </Dropdown.Toggle>
                            {/* <Dropdown.Menu>
                              <Dropdown.Item onClick={() => openNotePopup()}>Add Note</Dropdown.Item>
                            </Dropdown.Menu> */}
                          </Dropdown>
                        </div>
                      :
                      ''
                :
                ''
            }

            
          </div>
          
          
          {
            displayNote.length > 0 ?
            
              // <Scrollbars style={{height: 500 }} className="scrollbar-bx">
                displayNote
              // </Scrollbars>
            
            :
            
              <div className="note-box">
                <div className="notes-found text-center p-5">
                  <h3>No Notes Found!</h3>
                </div>
              </div>
          }
          
        </div>
      </div>


      <Modal centered show={showNoteDefault} onHide={handleNoteClose}>
        <Modal.Header>
          <Modal.Title className="h2">Add Note</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleNoteClose} /><span className="modelclose"></span>
        </Modal.Header>
        <Modal.Body>
          <Form encrypted="multipart/form-data" onSubmit={noteSubmit} >
            <Row>
              <div className="col-12">
                <Row className="align-items-start">
                  <Form.Group className="mb-125">
                    <Form.Label column sm="12">
                      <h6 className="fw600">Title</h6>
                    </Form.Label>
                    <Col sm="12" className="position-relative">
                      <Form.Group id="title">
                        <InputGroup>
                          <Form.Control type="text" name="notes" maxLength={50} onChange={handleNoteInput} ref={noteNotesRef} onBlur={handleNoteInput} value={noteInput.notes} placeholder="Title goes here" />
                        </InputGroup>
                      </Form.Group>
                      <span className="error error3">{noteInput.error_list.notes}</span>
                      {formNoteNotesError ? <span className="error2">Enter note title</span> : null}
                    </Col>
                  </Form.Group>
                  <Form.Group className="mb-125">
                    <Form.Label column sm="12">
                      <h6 className="fw600">Notes</h6>
                    </Form.Label>
                    <Col sm="12" className="yellowtxt position-relative">
                      <Form.Group id="title">
                        <InputGroup>
                          <Form.Control as="textarea" rows={5} name="description" maxLength={1000} onChange={handleNoteInput} ref={noteDescriptionRef} onBlur={handleNoteInput} value={noteInput.description} placeholder="Notes goes here" />
                        </InputGroup>
                      </Form.Group>
                      <span className="error error3">{noteInput.error_list.description}</span>
                      {formNoteDescriptionError ? <span className="error2">Enter note description</span> : null}
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column sm="12" className="attachlabel">
                      <h6 className="fw600 attachbtn"><span className="attchmenicon mr15"></span>Attachment</h6>
                      <Col sm="12">
                        <Form.Group className="mb-2" id="file_url-image">
                          <Form.Control type="file" name="file_url[]" title="Allow file type are jpeg,png,jpg,gif,svg,pdf,mp4,mp3,3gpp,x-msvideo,xls,xlsx,doc,docx,ppt,pptx,ods,odt,odp,csv,anytext,plain,ms-office and MAX file size 4MB" className="form-control" multiple onChange={handleNoteImageInput} />
                          <span >{defaultNoteTypeFileMsg ? defaultNoteTypeFileMsg : ''}</span>
                        </Form.Group>
                        <span className="error">{fileUploadErrorMsg ? fileUploadErrorMsg : ''}</span>
                        <span className="error">{fileTypeMsg ? fileTypeMsg : ''}</span>
                        <span className="error">{fileSizeMsg ? fileSizeMsg : ''}</span>
                        <span className="error">{backendFileMsg ? backendFileMsg : ''}</span>
                        <span className="error">{noteInput.error_list.file_url}</span>
                      </Col>
                    </Form.Label>
                    
                  </Form.Group>

                  <Form.Group>
                    <Col md="12">
                      <Form.Group id="uploaded-file">
                        <div className="form-group preview">
                          {
                            noteImageArray.length > 0 &&
                            noteImageArray.map((item, index) => {
                              var errorColor = 'black'

                              fileType = item.name.split('.')
                              if (fileType.length == 2) {
                                fileExtentionType = fileType.pop()
                              }
                              var iconClassName = '';
                              var imgArray = ["jpeg", "png", "jpg", "gif", "svg"];
                              var docArray = ["xls", "xlsx", "doc", "docx", "ppt", "pptx", "ods", "odt", "odp", "csv", "anytext", "plain"];
                              var pdfArray = ["pdf"];
                              var audioArray = ["mp4", "mp3",  "3gpp", "x-msvideo"];
                              

                              if(errorOnFile.includes(item.name)){
                                errorColor = 'red'
                              }

                              if (imgArray.includes(fileExtentionType)) {
                                iconClassName = 'jpgimg';
                              } else if (pdfArray.includes(fileExtentionType)) {
                                iconClassName = 'pdfimg';
                              } else if (docArray.includes(fileExtentionType)) {
                                iconClassName = 'docimg';
                              } else if (audioArray.includes(fileExtentionType)) {
                                iconClassName = 'audioimg';
                              } else {
                                iconClassName = 'docimg';
                              }

                              if(addNoteAttachmentFileType && fileSizeMsg){
                                displayErrorMsg = fileSizeMsg;
                              }else if(addNoteAttachmentFileType && fileTypeMsg){
                                displayErrorMsg = fileTypeMsg;
                              }else if(addNoteAttachmentFileType && fileSizeMsg && fileTypeMsg){
                                displayErrorMsg = fileSizeMsg+' Or '+fileTypeMsg;
                              }

                              return (
                                <div key={index} className="display flex-wrap justify-content-between mb26">
                                  <div className="col-md-11 display">
                                    <span className={iconClassName}></span> <span><h6 className="fileturn" style={{color:errorColor}}>{item.name}</h6></span>
                                  </div>
                                  <div className="col-md-1 text-right" onClick={() => deleteFile(index)}>
                                    <span className="redclose"></span>
                                  </div>
                                  {/* <button type="button" onClick={() => deleteFile(index)}>
                                  <h6 className="fw600">delete</h6><span className="redclose" onClick={deleteFile(index)}></span>
                                  </button> */}
                                </div>
                              );
                            })}
                        </div>
                        {/* {
                          addNoteAttachmentFileType ? 
                          // <span className="error">File format are not supported Or file size are more than 4MB</span>
                            <span className="error">{displayErrorMsg}</span> 
                          :
                          null
                        } */}
                      </Form.Group>
                    </Col>
                  </Form.Group>
                </Row>
              </div>
            </Row>

            <div>
              {
                submitNote ?

                  <Button variant="primary" className="d-flex align-items-center popupbtn btnblue text-center mb-1 mt30">
                    <Spinner animation="border" size="sm" />
                    <span className="ms-2 text-center">Please Wait...</span>
                  </Button>
                  :
                  <div className="btn-toolbar mt30" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                      <Button type="submit" variant="success" className="popupbtn btnblue mr15">Submit</Button>
                    </div>
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                      <Button type="button" variant="success" className="popupbtn greycolor" onClick={handleNoteClose}>Cancel</Button>
                    </div>
                  </div>
              }
            </div>
          </Form>
        </Modal.Body>
      </Modal>


      <Modal centered show={showEditNoteDefault} onHide={handleEditNoteClose}>
        <Modal.Header>
          <Modal.Title className="h2">Edit Note</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleEditNoteClose} /><span className="modelclose"></span>
        </Modal.Header>
        <Modal.Body>
          <Form encrypted="multipart/form-data" onSubmit={handleNoteEditSubmit} >
            <Row>
              <div className="col-12">
                <Row className="align-items-start">
                  <Form.Group className="mb-125">
                    <Form.Label column sm="12">
                      <h6 className="fw600">Title</h6>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Group id="title">
                        <InputGroup>
                          <Form.Control type="text" name="notes" maxLength={50} onChange={handleEditNoteInput} ref={noteNotesRef} onBlur={handleEditNoteInput} value={editNoteInput.notes} placeholder="Enter note title" />
                        </InputGroup>
                      </Form.Group>
                      <span className="error2">{editNoteInput.error_list.notes}</span>
                      {formNoteNotesError ? <span className="error2">Enter note title</span> : null}
                    </Col>
                  </Form.Group>
                  <Form.Group className="mb-125">
                    <Form.Label column sm="12">
                      <h6 className="fw600">Notes</h6>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Group id="title">
                        <InputGroup>
                          <Form.Control as="textarea" rows={5} name="description" maxLength={1000} onChange={handleEditNoteInput} ref={noteDescriptionRef} onBlur={handleEditNoteInput} value={editNoteInput.description} placeholder="Enter description" />
                        </InputGroup>
                      </Form.Group>
                      <span className="error2">{editNoteInput.error_list.description}</span>
                      {formNoteDescriptionError ? <span className="error2">Enter note description</span> : null}
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column sm="12" className="attachlabel">
                      <h6 className="fw600 attachbtn"><span className="attchmenicon mr15"></span>Attachment</h6>
                      <Col sm="12">
                        <Form.Group className="mb-2" id="file_url-image">
                          <Form.Control type="file" name="file_url[]" title="Allow file type are jpeg,png,jpg,gif,svg,pdf,mp4,mp3,3gpp,x-msvideo,xls,xlsx,doc,docx,ppt,pptx,ods,odt,odp,csv,anytext,plain,ms-office and MAX file size 4MB" className="form-control" multiple onChange={handleEditNoteImageInput} />
                          <span >{defaultNoteTypeFileMsg}</span>
                        </Form.Group>
                        <span className="error">{fileUploadEditErrorMsg}</span>
                        <span className="error">{fileTypeMsg ? fileTypeMsg : ''}</span>
                        <span className="error">{fileSizeMsg ? fileSizeMsg : ''}</span>
                        <span className="error">{backendFileMsg ? backendFileMsg : ''}</span>
                      </Col>
                    </Form.Label>
                  </Form.Group>

                  <Form.Group className="d-flex align-items-start">
                    <Col md="12">
                      <Form.Group id="uploaded-file">
                        <div className="form-group preview">
                          {
                            noteEditImageArray.length > 0 &&
                            noteEditImageArray.map((item, index) => {
                              var errorColor = 'black'

                              fileType = item.name.split('.')
                              if (fileType.length == 2) {
                                fileExtentionType = fileType.pop()
                              }

                              var iconClassName = '';
                              var imgArray = ["jpeg", "png", "jpg", "gif", "svg"];
                              var docArray = ["xls", "xlsx", "doc", "docx", "ppt", "pptx", "ods", "odt", "odp", "csv", "anytext", "plain"];
                              var pdfArray = ["pdf"];
                              var audioArray = ["mp4", "mp3", "3gpp", "x-msvideo"];

                              if(errorOnFile.includes(item.name)){
                                errorColor = 'red'
                              }

                              if (imgArray.includes(fileExtentionType)) {
                                iconClassName = 'jpgimg';
                              } else if (pdfArray.includes(fileExtentionType)) {
                                iconClassName = 'pdfimg';
                              } else if (docArray.includes(fileExtentionType)) {
                                iconClassName = 'docimg';
                              } else if (audioArray.includes(fileExtentionType)) {
                                iconClassName = 'audioimg';
                              } else {
                                iconClassName = 'docimg';
                              }

                              return (
                                <div key={index} className="display flex-wrap justify-content-between mb26">
                                  <div className="col-md-11 display">
                                    <span className={iconClassName}></span> <span><h6 className="fileturn" style={{color:errorColor}}>{item.name}</h6></span>
                                  </div>
                                  <div className="col-md-1 text-right" onClick={() => deleteEditFile(index)}>
                                    <span className="redclose"></span>
                                  </div>
                                  {/* <button type="button" onClick={() => deleteFile(index)}>
                                  <h6 className="fw600">delete</h6><span className="redclose" onClick={deleteFile(index)}></span>
                                  </button> */}
                                </div>
                              );
                            })}
                        </div>
                        {/* {
                          addNoteAttachmentFileType ? 
                          <span className="error">File format are not supported Or file size are more than 4MB</span>
                          :
                          null
                        } */}
                      </Form.Group>
                    </Col>
                  </Form.Group>
                </Row>
              </div>
            </Row>

            <div>
              {
                submitEditNote ?

                  <Button variant="primary" className="d-flex popupbtn btnblue align-items-center mb-1 mt40">
                    <Spinner animation="border" size="sm" />
                    <span className="ms-2">Please Wait...</span>
                  </Button>
                  :
                  <div className="btn-toolbar mt40" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                      <Button type="submit" variant="success" className="popupbtn btnblue mr15">Submit</Button>
                    </div>
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                      <Button type="button" variant="success" className="popupbtn greycolor" onClick={handleEditNoteClose}>Cancel</Button>
                    </div>
                  </div>
              }
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  );


};

export default NoteListComp;
