/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Button, Modal} from '@themesberg/react-bootstrap';
import {  toast } from 'react-toastify';
import axios from "axios";
import { Formik, ErrorMessage  } from "formik";
import * as yup from "yup";
import TextEditor from "./TextEditor";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";

const SendQuoteEmail = ({isOpen,onRequestClose,dataRecord,quoteID}) => {
  const [loading, setLoading] = useState(false);
  const [attachmentArrayList, setAttachmentArrayList] = useState([]);
  const [displayCc, setDisplayCc] = useState("none");
  const [displayBcc, setDisplayBcc] = useState("none");
  const [emails, setEmails] = React.useState([]);
  const [ccEmails, setCcEmails] = React.useState([]);
  const [bccEmails, setBccEmails] = React.useState([]);
  const [focused, setFocused] = React.useState(false);
  const [quoteName, setquoteName] = useState();
  const [ErrorInput, setErrorInput] = useState({ error_list: [] });

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [quoteID]); 


  const getData = async()=>{
    setEmails([]);
    setCcEmails([]);
    setBccEmails([]);

    await axios
      .get("api/quote-generate/" + quoteID)
      .then((response) => {
        if (response.data.code === 200) {
          setquoteName(response.data.data.quoteName);
          setLoading(false);
        }
      })
      .then((json) => {})
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 400) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 300) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
      setAttachmentArrayList([]);
  }

  const [defaultValue, setdefaultValue] = useState({
    fromEmail: dataRecord?.fromEmail||'',
    toEmails: [],
    ccEmails: [],
    bccEmails: [],
    subject: dataRecord?.subject||'',
    description: "",
    attachment: null,
    error_list: [],

  });

  const validationSchema = yup.object().shape({
    toEmails: yup
      .array()
      .min(1, "Please enter email")
      .required("Please enter email"),
    //ccEmails: yup.array().of(yup.string().email('Invalid email format')),
    //bccEmails: yup.array().of(yup.string().email('Invalid email format')),  
    subject: yup
      .string()
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      .required("Enter your subject"),
    description: yup
      .string()
      .min(2, "Must be at least 2 characters")
      .max(500, "Must be at most 500 characters")
      .required("Enter your message"),
  });

  function deleteFile(e) {
    const s = attachmentArrayList.filter((item, index) => index !== e);
    setTimeout(() => setAttachmentArrayList(s), 100);
  }

  const handleNoteImageInput = (e) => {
    e.persist();
    setAttachmentArrayList([...attachmentArrayList, ...e.target.files]);
  }

  const handleSubmit = async(values, onSubmitProps) => {
    const formData = new FormData();
    formData.append("subject", values.subject);
    formData.append("description", values.description);
    formData.append("default_attachment", quoteName);
    for (var i = 0; i < attachmentArrayList.length; i++) {
      formData.append("attachment[]", attachmentArrayList[i]);
    }
    for (var j = 0; j < values.toEmails.length; j++) {
      formData.append("toEmails[]", values.toEmails[j]);
    }
    for (var k = 0; k < values.ccEmails.length; k++) {
      formData.append("ccEmails[]", values.ccEmails[k]);
    }
    for (var l = 0; l < values.bccEmails.length; l++) {
      formData.append("bccEmails[]", values.bccEmails[l]);
    }
    formData.append("quote_id", quoteID);
    
    await axios
      .post("api/quote-history/store", formData)
      .then((response) => {
        if (response.status === 200) {
          setErrorInput({ ...ErrorInput, error_list: "" });
          //setIsEditable(true);
          onRequestClose()
          toast.success("Quote send successfully");
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          //setIsEditable(true);
          onSubmitProps.setSubmitting(false);
          if (error.response.status === 400) {
            setErrorInput({
              ...ErrorInput,
              error_list: error.response.data.validation_errors,
            });
            setErrorInput({
              ...defaultValue,
              error_list: error.response.data.validation_errors,
            });
            toast.error(
              error.response.data.message == "File format are not supported"
                ? "File format are not supported, Allow file type are PDF,DOC,Excel"
                : error.response.data.message
            );
          } else if (error.response.status === 300) {
            toast.warning(error.response.data.message);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  };
  
  return (
    <Modal
            as={Modal.Dialog}
            right
            show={isOpen}
            className="model1 writetous emailpopup"
          >
            <Modal.Header>
              <Modal.Title className="h2 modal-title h4">
                {dataRecord.subject ? dataRecord.subject : "Send Quote"}
              </Modal.Title>
              <Button
                variant="close"
                aria-label="Close"
                onClick={onRequestClose}
              />
              <span className="modelclose"></span>
            </Modal.Header>
            <Modal.Body className="modelbodyes">
              <Formik
                initialValues={defaultValue}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                //validate={validate} // Pass the validate function as a prop
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldTouched,
                  } = props;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={12} className="mb-125 position-relative">
                          <Form.Group id="fromEmail">
                            <Form.Label column sm="12">
                              <h6 className="fw600">From</h6>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="fromEmail"
                              readOnly
                              value={values.fromEmail}
                              placeholder="Enter subject"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-125 position-relative">
                          <Form.Group id="toEmails">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                To<div className="text-danger starspan">*</div>{" "}
                                <span>
                                  <p
                                    style={{
                                      cursor: "pointer",
                                      display:
                                        displayCc == "block" ? "none" : "block",
                                    }}
                                    onClick={() => setDisplayCc("block")}
                                    className="me-2"
                                  >
                                    Cc
                                  </p>{" "}
                                  <p
                                    style={{
                                      cursor: "pointer",
                                      display:
                                        displayBcc == "block"
                                          ? "none"
                                          : "block",
                                    }}
                                    onClick={() => setDisplayBcc("block")}
                                  >
                                    Bcc
                                  </p>
                                </span>
                              </h6>
                            </Form.Label>
                            <ReactMultiEmail
                              placeholder="Enter To email"
                              emails={emails}
                              onChange={(_emails) => {
                                setEmails(_emails);
                                setFieldValue("toEmails", _emails);
                              }}
                              className={
                                errors.toEmails && touched.toEmails
                                  ? "input-error"
                                  : null
                              }
                              autoFocus={true}
                              onFocus={() => setFocused(true)}
                              onBlur={() => setFocused(false)}
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span
                                      data-tag-handle
                                      onClick={() => removeEmail(index)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <span className="error error3 ml1">
                              {defaultValue.error_list.toEmails}
                            </span>
                            <span className="error error3 ml1">
                              <ErrorMessage name="toEmails" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ display: displayCc }}>
                        <Col md={12} className="mb-125 position-relative">
                          <Form.Group id="ccEmails">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Cc</h6>
                            </Form.Label>
                            <ReactMultiEmail
                              placeholder="Enter cc email"
                              emails={ccEmails}
                              onChange={(_emails) => {
                                setCcEmails(_emails);
                                setFieldValue("ccEmails", _emails);
                                setFieldTouched("ccEmails", true); // Add this line
                              }}
                              className={
                                errors.ccEmails && touched.ccEmails
                                  ? "input-error"
                                  : null
                              }
                              autoFocus={true}
                              onFocus={() => setFocused(true)}
                              onBlur={() => setFocused(false)}
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span
                                      data-tag-handle
                                      onClick={() => removeEmail(index)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <span className="error error3 ml1">
                              {defaultValue.error_list.ccEmails}
                            </span>
                            {errors.ccEmails && touched.ccEmails && (
                              <span className="error error3 ml1">
                                {errors.ccEmails}
                              </span>
                            )}
                            <span className="error error3 ml1">
                              <ErrorMessage name="ccEmails" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ display: displayBcc }}>
                        <Col md={12} className="mb-125 position-relative">
                          <Form.Group id="bccEmails">
                            <Form.Label column sm="12">
                              <h6 className="fw600">Bcc</h6>
                            </Form.Label>
                            <ReactMultiEmail
                              placeholder="Enter Bcc email"
                              emails={bccEmails}
                              onChange={(_emails) => {
                                setBccEmails(_emails);
                                setFieldValue("bccEmails", _emails);
                                setFieldTouched("bccEmails", true); // Add this line
                              }}
                              autoFocus={true}
                              onFocus={() => setFocused(true)}
                              onBlur={() => setFocused(false)}
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span
                                      data-tag-handle
                                      onClick={() => removeEmail(index)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <span className="error error3 ml1">
                              {defaultValue.error_list.bccEmails}
                            </span>
                            <span className="error error3 ml1">
                              <ErrorMessage name="bccEmails" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-125 position-relative">
                          <Form.Group id="subject">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Subject<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="subject"
                              onChange={handleChange}
                              value={values.subject}
                              onBlur={handleBlur}
                              placeholder="Enter subject"
                              className={
                                errors.subject && touched.subject
                                  ? "input-error"
                                  : null
                              }
                            />
                            <span className="error error3 ml1">
                              {defaultValue.error_list.subject}
                            </span>
                            <span className="error error3 ml1">
                              <ErrorMessage name="subject" />
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-125 position-relative">
                          <Form.Group id="description">
                            <Form.Label column sm="12">
                              <h6 className="fw600">
                                Message<span className="text-danger">*</span>
                              </h6>
                            </Form.Label>
                            <Col sm="12">
                              <Form.Group
                                id="description"
                                className="messagetxt"
                              >
                                <TextEditor
                                  setFieldValue={(val) =>
                                    setFieldValue("description", val)
                                  }
                                  value={values.message}
                                  wrapperClassName={
                                    errors.description && touched.description
                                      ? "custom-wrapper input-editor-error"
                                      : "custom-wrapper"
                                  }
                                />
                                <span className="error error3 ml1">
                                  {defaultValue.error_list.description}
                                </span>
                                <span className="error error3 ml1">
                                  <ErrorMessage name="description" />
                                </span>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group>
                        <Col md="12">
                          <Form.Group id="uploaded-file">
                            <div className="form-group preview">
                              <div
                                key={56}
                                className="display flex-wrap justify-content-between mb26"
                              >
                                <div className="col-md-11 display">
                                  <span>
                                    <h6 className="fileturn">
                                      {quoteName
                                        ? quoteName
                                        : dataRecord.quote_number + ".pdf"}
                                    </h6>
                                  </span>
                                </div>
                              </div>
                              {attachmentArrayList.length > 0 &&
                                attachmentArrayList.map((item, index) => {
                                  return (
                                    <div
                                      key={item}
                                      className="display flex-wrap justify-content-between mb26"
                                    >
                                      <div className="col-md-11 display">
                                        <span>
                                          <h6 className="fileturn">
                                            {item.name}
                                          </h6>
                                        </span>
                                      </div>
                                      <div
                                        className="col-md-1 text-right"
                                        onClick={() => deleteFile(index)}
                                      >
                                        <span className="redclose"></span>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </Form.Group>
                        </Col>
                      </Form.Group>
                      <span className="error">
                        {defaultValue.error_list.attachment}
                      </span>
                      <span className="error">
                        <ErrorMessage name="attachment" />
                      </span>

                      <div className="display mt-3 justify-content-end">
                        <div className="col-md-5 display attchment1">
                          <div className="file">
                            <label
                              for="input-file"
                              className="attchment"
                            ></label>
                            <Form.Control
                              id="input-file"
                              type="file"
                              name="attachment[]"
                              accept="application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/comma-separated-values, text/csv, application/csv"
                              title="Allow file type are pdf,doc,excel and MAX file size are uo to 4MB"
                              className="form-control"
                              multiple
                              onChange={handleNoteImageInput}
                            />
                          </div>
                          <span>Add Attachment</span>
                        </div>
                        <div className="col-md-7">
                          {isSubmitting ? (
                            <div
                              className="btn-toolbar justify-content-end"
                              role="toolbar"
                              aria-label="Toolbar with button groups"
                            >
                              <div
                                className="btn-group mr-2"
                                role="group"
                                aria-label="First group"
                              >
                                <Button
                                  type="submit"
                                  variant="success"
                                  className="popupbtn btnblue mr15"
                                >
                                  <span className="ms-2">Please wait.....</span>
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn-toolbar justify-content-end"
                              role="toolbar"
                              aria-label="Toolbar with button groups"
                            >
                              <div
                                className="btn-group mr-2"
                                role="group"
                                aria-label="First group"
                              >
                                <Button
                                  type="submit"
                                  variant="success"
                                  className="popupbtn btnblue mr15"
                                >
                                  Submit
                                </Button>
                              </div>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="First group"
                              >
                                <Button
                                  type="button"
                                  variant="success"
                                  className="popupbtn greycolor"
                                  onClick={onRequestClose}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Modal.Body>
    </Modal>
  );
};

export default SendQuoteEmail;
