import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Spinner, Card, Form } from '@themesberg/react-bootstrap';
import Select from 'react-select'
import moment from "moment-timezone";
import DatePicker from 'react-datepicker';
import Preloader from "../../components/Preloader";
import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import validator from 'validator'
import {  toast } from 'react-toastify';
import { useSelector } from "react-redux";
import CurrencyInput from 'react-currency-input-field';
import { selectUser,selectDefaultData  } from "../../features/userSlice";
import axios from 'axios';

var getIpResponse = [];

function AddDeal() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const loginUser = useSelector(selectUser);

  const user = loginUser?loginUser:[];
  
  var defaultData = useSelector(selectDefaultData);
  var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;

  //const [dealData, setDealData] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [salutationData, setSalutationData] = useState([]);
  const [leadSourceData, setleadSourceData] = useState([]);
  const [leadStageData, setLeadStageData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectProductArray, setSelectProductArray] = useState([]);
  const [submitEvent, setSubmitEvent] = useState(false);

  const [DealInput, setDealInput] = useState({
    salutation: '',
    first_name: '',
    last_name: '',
    title: '',
    department: '',
    description: '',
    profile_pic: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country_id: '',
    zipcode: '',
    phone1: '',
    phone2: '',
    primary_email: '',
    secondry_email: '',
    facebook_url: '',
    twitter_url: '',
    linkedin_url: '',
    instagram_url: '',
    organization_name: '',
    organization_email: '',
    organization_phone1: '',
    organization_phone2: '',
    organization_website: '',
    lead_source: '',
    stage_id: '',
    product_id: '',
    deal_description: '',
    deal_amount: '',
    deal_probability: '',
    closing_date: '',
    error_list: [],
  });

  const salutationRef = useRef();
  const first_nameRef = useRef();
  const last_nameRef = useRef();
  const primary_emailRef = useRef();
  const secondry_emaildRef = useRef();
  const titleRef = useRef();
  const profile_picRef = useRef();
  const departmentRef = useRef();
  const descriptionRef = useRef();
  const address1Ref = useRef();
  const address2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const country_idRef = useRef();
  const zipcodeRef = useRef();
  const phone1Ref = useRef();
  const phone2Ref = useRef();
  const facebook_urlRef = useRef();
  const twitter_urlRef = useRef();
  const linkedin_urlRef = useRef();
  const instagram_urlRef = useRef();
  const organization_nameRef = useRef();
  const organization_emailRef = useRef();
  const organization_phone1Ref = useRef();
  const organization_phone2Ref = useRef();
  const organization_websiteRef = useRef();
  const lead_sourceRef = useRef();
  const stage_idRef = useRef();
  const product_idRef = useRef();

  const deal_descriptionRef = useRef();
  const deal_amountRef = useRef();
  const deal_probabilityRef = useRef();
  const closingDateRef = useRef();

  const [formsalutationError, setFormSalutationError] = useState(false);
  const [formfirst_nameError, setFormFirst_nameError] = useState(false);
  const [formlast_nameError, setFormLast_nameError] = useState(false);
  const [formtitleError, setFormTitleError] = useState(false);
  const [formprimary_emailError, setFormPrimary_emailError] = useState(false);
  const [formsecondry_emailError, setFormSecondry_emailError] = useState(false);
  const [formdepartmentError, setFormDepartmentError] = useState(false);
  const [formdescriptionError, setFormDescriptionError] = useState(false);
  const [formprofile_picError, setFormProfile_picError] = useState(false);
  const [formaddress1Error, setFormAddress1Error] = useState(false);
  const [formaddress2Error, setFormAddress2Error] = useState(false);
  const [formcityError, setFormCityError] = useState(false);
  const [formstateError, setFormStateError] = useState(false);
  const [formcountry_idError, setFormCountry_idError] = useState(false);
  const [formzipcodeError, setFormzipcodeError] = useState(false);
  const [formphone1Error, setFormPhone1Error] = useState(false);
  const [formphone2Error, setFormPhone2Error] = useState(false);
  const [formfacebook_urlError, setFormFacebook_urlError] = useState(false);
  const [formtwitter_urlError, setFormTwitter_urlError] = useState(false);
  const [formlinkedin_urlError, setFormLinkedin_urlError] = useState(false);
  const [forminstagram_urlError, setFormInstagram_urlError] = useState(false);
  const [formorganization_nameError, setFormOrganization_nameError] = useState(false);
  const [formorganization_emailError, setFormOrganization_emailError] = useState(false);
  const [formorganization_phone1Error, setFormOrganization_phone1Error] = useState(false);
  const [formorganization_phone2Error, setFormOrganization_phone2Error] = useState(false);
  const [formorganization_websiteError, setFormOrganization_websiteError] = useState(false);

  const [formlead_sourceError, setFormlead_sourceError] = useState(false);
  const [formstage_idError, setFormstage_idError] = useState(false);
  const [formproduct_idError, setFormproduct_idError] = useState(false);

  const [formdeal_descriptionError, setFormdeal_descriptionError] = useState(false);
  const [formdeal_amountError, setFormdeal_amountError] = useState(false);
  const [formdeal_probabilityError, setFormdeal_probabilityError] = useState(false);
  const [formclosingDateError, setFormclosingDateError] = useState(false);



  const [salutationError, setSalutationError] = useState(false);
  const [first_nameError, setFirst_nameError] = useState(false);
  const [last_nameError, setLast_nameError] = useState(false);
  const [primary_emailError, setPrimary_emailError] = useState(false);
  const [secondry_emailError, setSecondry_emailError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [profile_picError, setProfile_picError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [address2Error, setAddress2Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [country_idError, setCountry_idError] = useState(false);
  const [zipcodeError, setzipcodeError] = useState(false);
  const [phone1Error, setPhone1Error] = useState(false);
  const [phone2Error, setPhone2Error] = useState(false);
  const [facebook_urlError, setFacebook_urlError] = useState(false);
  const [twitter_urlError, setTwitter_urlError] = useState(false);
  const [linkedin_urlError, setLinkedin_urlError] = useState(false);
  const [instagram_urlError, setInstagram_urlError] = useState(false);
  const [profileImageArray, setProfileImageArray] = useState([]);
  const [organization_nameError, setOrganization_nameError] = useState(false);
  const [organization_emailError, setOrganization_emailError] = useState(false);
  const [organization_phone1Error, setOrganization_phone1Error] = useState(false);
  const [organization_phone2Error, setOrganization_phone2Error] = useState(false);
  const [organization_websiteError, setOrganization_websiteError] = useState(false);
  const [lead_sourceError, setLead_sourceError] = useState(false);
  const [stage_idError, setStage_idError] = useState(false);
  const [product_idError, setProduct_idError] = useState(false);

  const [deal_descriptionError, setDeal_descriptionError] = useState(false);
  const [deal_amountError, setDeal_amountError] = useState(false);
  const [deal_probabilityError, setDeal_probabilityError] = useState(false);
  const [closingDateError, setClosingDateError] = useState(false);

  const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
  
  const [getIpResCountryCode, setGetIpResCountryCode] = useState('');
  const [getIpResCountryName, setGetIpResCountryName] = useState('');
  const [getCountryIDFromIP, setGetCountryIDFromIP] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');
  const [closingDate, setClosingDate] = useState(new Date(new Date().toLocaleString("en-US",{timeZone: defaultTimeZone})));


  useEffect(() => {

    getData();

  }, []);


  function getData() {
    var countryIPId = '';
    getLocation();
    axios.get('api/deal/create').then(response => {
      if (response.status === 200) {
        //setDealData(response.data.data);
        setCountryData(response.data.data.countries);
        setSalutationData(response.data.data.salutationArray);
        setleadSourceData(response.data.data.leadSources);
        setLeadStageData(response.data.data.leadStage);
        setProductData(response.data.data.products);
        setPreferredCurrencyData(response.data.data.preferredCurrencyData)
        setLoading(false);

        setFormSalutationError(false);
        setSalutationError(true);

        //set country from IP API response
        for (var i = 0, l = response.data.data.countries.length; i < l; i++) {
          if (getIpResponse.country_code == response.data.data.countries[i].country_code || getIpResponse.country_name == response.data.data.countries[i].country_name) {
            countryIPId =  response.data.data.countries[i].id;
          }
        }
        setDealInput({ ...DealInput, 'city': getIpResponse.city,  'state': getIpResponse.region, 'zipcode': getIpResponse.postal, 'country_id':countryIPId});

      }
    }).then(json => {

    }).catch(error => {
      if (error.response) {
        setLoading(false);
        if (error.response.status === 403) {
         // swal("Forbidden", error.response.data.message, "error");
          toast.warning('Forbidden '+error.response.data.message);
          history.push('/deals');    
        } else {
          //swal("error", error.response.data.message, "error");
          toast.error(error.response.data.message);
        }
      }
    });
  }

  function getLocation(){
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } else {
      //console.log("Not Available");
    }

    fetch(`https://ipapi.co/json/`)
    .then(response => response.json())
    .then(data =>{
        //setGetIpResponse(data);
        getIpResponse = data;
        //console.log(data)
        setGetIpResCountryName(data.country_name);
        setGetIpResCountryCode(data.country_code);
      }
    )
  }

  const handleOnValueChange = (value) => {
    setDealInput({ ...DealInput, ['deal_amount']: value });
  };

  const handleInput = (e) => {
    e.persist();
    let names = e.target.name;
    let values = e.target.value;
    validateFormData(names, values);
    setDealInput({ ...DealInput, [e.target.name]: e.target.value });
  }

  const handleSalutationInput = (e) =>{
    validateFormData('salutation', e.value);
    setDealInput({ ...DealInput, ['salutation']: e.value });
  }

  const handleStageInput = (e) =>{
    validateFormData('stage_id', e.value);
    setDealInput({ ...DealInput, ['stage_id']: e.value });
  }

  const handleLeadSourceInput = (e) =>{
    validateFormData('lead_source', e.value);
    setDealInput({ ...DealInput, ['lead_source']: e.value });
  }

  const handleCountryInput = (e) =>{
    validateFormData('country_id', e.value);
    setDealInput({ ...DealInput, ['country_id']: e.value });
  }

  const customStyles = {
    menuList: styles => ({
      ...styles,
      color:'black',
      backgroundcolor:'$blue',
      paddingTop: 0,
      paddingBottom: 0,
  }),
   option: provided => ({
      ...provided,
      borderwidth:"0 0 1px 0px",
      bordercolor:"black",
      borderstyle:"solid",
      '&:hover':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:active':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:visited':
      {
         outline: 'none',
         backgroundcolor:'none',
         color: 'white',
      },
      '&:selected':
      {
        outline: 'none',
        backgroundcolor:'none',
        color: 'white',
      },
      '&:default':
      {
        outline: 'none',
        backgroundcolor:'none',
        color: 'white',
      }
    }),
    control: provided => ({
      ...provided,
      border: "0px solid black",  
      '&:hover':
      {
         outline: 'none',
         backgroundcolor:'none',
      }
    }),
    singleValue: provided => ({
         ...provided,
        border: "0px solid black",   
        color:"black",
         '&:hover':
         {
            outline: 'none',
            backgroundcolor:'none',
         }
    }), 
  }

  let selectProduct = [];
  const handleChange = (e) => {
    selectProduct = Array.isArray(e) ? e.map(x => x.value) : [];
    console.log(selectProduct);
    
    // var options = e.target.options;
    // var value = [];
    // for (var i = 0, l = options.length; i < l; i++) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    // }
     setSelectProductArray(selectProduct);
  }

  const handleImageInput = (e) => {
    e.persist();
    let name = e.target.name;
    let value = e.target.value;
    const imageFile = e.target.files[0];
    setProfileImageArray(e.target.files[0]);
    setDealInput.profile_pic = imageFile;
    validateFormData(name, imageFile);
    setDealInput({ ...DealInput, [e.target.name]: e.target.value });

  }


  function validateFormData(type, value) {

    if (type == 'deal_description' && value == '') {
      setFormdeal_descriptionError(false);
      setDeal_descriptionError(true);
      setDealInput(DealInput.error_list.deal_description = 'This field is required');
    } else {
      setFormdeal_descriptionError(false);
      if (type == 'deal_description' && value != '') {
        setDeal_descriptionError(false);
        setDealInput(DealInput.error_list.deal_description = '');
      }
    }

    // if (type == 'deal_amount' && value == '') {
    //   setFormdeal_amountError(false);
    //   setDeal_amountError(true);
    //   setDealInput(DealInput.error_list.deal_amount = 'This field is required');
    // } else {
    //   setFormdeal_amountError(false);
    //   if (type == 'deal_amount' && value != '') {
    //     setDeal_amountError(false);
    //     setDealInput(DealInput.error_list.deal_amount = '');
    //   }
    // }

    if (type == 'deal_probability' && value == '') {
      setFormdeal_probabilityError(false);
      setDeal_probabilityError(true);
      setDealInput(DealInput.error_list.deal_probability = 'This field is required');
    } else {
      setFormdeal_probabilityError(false);
      if (type == 'deal_probability' && value != '') {
        setDeal_probabilityError(false);
        setDealInput(DealInput.error_list.deal_probability = '');
      }
    }

    if (type == 'salutation' && value == '') {
      setFormSalutationError(false);
      setSalutationError(true);
      setDealInput(DealInput.error_list.salutation = 'This field is required');
    } else {
      setFormSalutationError(false);
      if (type == 'salutation' && value != '') {
        setSalutationError(false);
        setDealInput(DealInput.error_list.salutation = '');
      }
    }

    if (type == 'first_name' && value == '') {
      setFormFirst_nameError(false);
      setFirst_nameError(true);
      setDealInput(DealInput.error_list.first_name = 'This field is required');
    } else {
      setFormFirst_nameError(false);
      if (type == 'first_name' && value != '') {
        setFirst_nameError(false);
        setDealInput(DealInput.error_list.first_name = '');
      }
    }

    if (type == 'last_name' && value == '') {
      setFormLast_nameError(false);
      setLast_nameError(true);
      setDealInput(DealInput.error_list.last_name = 'This field is required');
    } else {
      setFormLast_nameError(false);
      if (type == 'last_name' && value != '') {
        setLast_nameError(false);
        setDealInput(DealInput.error_list.last_name = '');
      }
    }

    if (type == 'primary_email' && validator.isEmail(value)) {
      setFormPrimary_emailError(false);
      setPrimary_emailError(false);
      setDealInput(DealInput.error_list.primary_email = '');
    } else {
      setFormPrimary_emailError(false);
      if (type == 'primary_email' && value == '') {
        setPrimary_emailError(true);
        setDealInput(DealInput.error_list.primary_email = 'This field is required');
      } else if (type == 'primary_email') {
        setPrimary_emailError(true);
        setDealInput(DealInput.error_list.primary_email = 'Enter a valid e-mail address');
      }
    }

    if (type == 'secondry_email' && !validator.isEmail(value) && value != '') {
      setFormSecondry_emailError(false)
      setSecondry_emailError(false);
      setDealInput(DealInput.error_list.secondry_email = 'Enter a valid e-mail address');
    } else if (type == 'secondry_email') {
      setFormSecondry_emailError(false)
      setSecondry_emailError(true);
      setDealInput(DealInput.error_list.secondry_email = '');
    }

    if (type == 'stage_id' && value == '') {
      setFormstage_idError(false);
      setStage_idError(true);
      setDealInput(DealInput.error_list.stage_id = 'This field is required');
    } else {
      setFormstage_idError(false);
      if (type == 'stage_id' && value != '') {
        setStage_idError(false);
        setDealInput(DealInput.error_list.stage_id = '');
      }
    }

    if (type == 'lead_source' && value == '') {
      setFormlead_sourceError(false);
      setLead_sourceError(true);
      setDealInput(DealInput.error_list.lead_source = 'This field is required');
    } else {
      setFormlead_sourceError(false);
      if (type == 'lead_source' && value != '') {
        setLead_sourceError(false);
        setDealInput(DealInput.error_list.lead_source = '');
      }
    }

    if (type == 'title' && value == '') {
      setFormTitleError(false);
      setTitleError(true);
      setDealInput(DealInput.error_list.title = 'This field is required');
    } else {
      setFormTitleError(false);
      if (type == 'title' && value != '') {
        setTitleError(false);
        setDealInput(DealInput.error_list.title = '');
      }
    }

    if (type == 'department' && value == '') {
      setFormDepartmentError(false);
      setDepartmentError(true);
      setDealInput(DealInput.error_list.department = 'This field is required');
    } else {
      setFormDepartmentError(false);
      if (type == 'department' && value != '') {
        setDepartmentError(false);
        setDealInput(DealInput.error_list.department = '');
      }
    }

    if (type == 'profile_pic' && !value.name.match(/\.(jpg|jpeg|png|gif)$/) && value != '') {
      setFormProfile_picError(false);
      setProfile_picError(true);
      setDealInput(DealInput.error_list.profile_pic = 'Please select valid image.');
    } else if (type == 'profile_pic' && value.size / 1024 > 2048) {
      setFormProfile_picError(false);
      setProfile_picError(true);
      setDealInput(DealInput.error_list.profile_pic = 'Please select image size less then 2MB.');
    } else {
      setFormProfile_picError(false);
      if (type == 'profile_pic' && profileImageArray) {
        setProfile_picError(false);
        setDealInput(DealInput.error_list.profile_pic = '');
      }
    }

    if (type == 'address1' && value == '') {
      setFormAddress1Error(false);
      setAddress1Error(true);
      setDealInput(DealInput.error_list.address1 = 'This field is required');
    } else {
      setFormAddress1Error(false);
      if (type == 'address1' && value != '') {
        setAddress1Error(false);
        setDealInput(DealInput.error_list.address1 = '');
      }
    }

    var phonePattern = new RegExp(/^[0-9\b]+$/);
    if (type == 'phone1' && value == '') {
      setFormPhone1Error(false);
      setPhone1Error(true);
      setDealInput(DealInput.error_list.phone1 = 'This field is required');
    } else if (type == 'phone1' && !phonePattern.test(value)) {
      setFormPhone1Error(false);
      setPhone1Error(true);
      setDealInput(DealInput.error_list.phone1 = 'Please enter only number');
    } else if (type == 'phone1' && value.length != 10) {
      setFormPhone1Error(false);
      setPhone1Error(true);
      setDealInput(DealInput.error_list.phone1 = 'Please enter valid phone number');
    } else {
      setFormPhone1Error(false);
      if (type == 'phone1' && value != '') {
        setPhone1Error(false);
        setDealInput(DealInput.error_list.phone1 = '');
      }
    }

    if (type == 'phone2' && !phonePattern.test(value) && value != '') {
      setFormPhone2Error(false);
      setPhone2Error(true);
      setDealInput(DealInput.error_list.phone2 = 'Please enter only number');
    } else if (type == 'phone2' && value.length != 10 && value != '') {
      setFormPhone2Error(false);
      setPhone2Error(true);
      setDealInput(DealInput.error_list.phone2 = 'Please enter 10 digit phone number');
    } else {
      setFormPhone2Error(false);
      if (type == 'phone2' && value != '') {
        setPhone2Error(false);
        setDealInput(DealInput.error_list.phone2 = '');
      }
    }

    if (type == 'city' && value == '') {
      setFormCityError(false);
      setCityError(true);
      setDealInput(DealInput.error_list.city = 'This field is required');
    } else {
      setFormCityError(false);
      if (type == 'city' && value != '') {
        setCityError(false);
        setDealInput(DealInput.error_list.city = '');
      }
    }

    if (type == 'state' && value == '') {
      setFormStateError(false);
      setStateError(true);
      setDealInput(DealInput.error_list.state = 'This field is required');
    } else {
      setFormStateError(false);
      if (type == 'state' && value != '') {
        setStateError(false);
        setDealInput(DealInput.error_list.state = '');
      }
    }

    if (type == 'country_id' && value == '') {
      setFormCountry_idError(false);
      setCountry_idError(true);
      setDealInput(DealInput.error_list.country_id = 'This field is required');
    } else {
      setFormCountry_idError(false);
      if (type == 'country_id' && value != '') {
        setCountry_idError(false);
        setDealInput(DealInput.error_list.country_id = '');
      }
    }

    if (type == 'zipcode' && value == '') {
      setFormzipcodeError(false);
      setzipcodeError(true);
      setDealInput(DealInput.error_list.zipcode = 'This field is required');
    } else if (type == 'zipcode' && !phonePattern.test(value) && value != '') {
      setFormzipcodeError(false);
      setzipcodeError(true);
      setDealInput(DealInput.error_list.zipcode = 'Please enter only number');
    } else if (type == 'zipcode' && value.length != 6) {
      setFormzipcodeError(false);
      setzipcodeError(true);
      setDealInput(DealInput.error_list.zipcode = 'Please enter at least 6 number');
    } else {
      setFormzipcodeError(false);
      if (type == 'zipcode' && value != '') {
        setzipcodeError(false);
        setDealInput(DealInput.error_list.zipcode = '');
      }
    }

    if (type == 'organization_name' && value == '') {
      setFormOrganization_nameError(false);
      setOrganization_nameError(true);
      setDealInput(DealInput.error_list.organization_name = 'This field is required');
    } else {
      setFormOrganization_nameError(false);
      if (type == 'organization_name' && value != '') {
        setOrganization_nameError(false);
        setDealInput(DealInput.error_list.organization_name = '');
      }
    }

    var phonePattern = new RegExp(/^[0-9\b]+$/);
    if (type == 'organization_phone1' && value == '') {
      setFormOrganization_phone1Error(false);
      setOrganization_phone1Error(true);
      setDealInput(DealInput.error_list.organization_phone1 = 'This field is required');
    } else if (type == 'organization_phone1' && !phonePattern.test(value)) {
      setFormOrganization_phone1Error(false);
      setOrganization_phone1Error(true);
      setDealInput(DealInput.error_list.organization_phone1 = 'Please enter only number');
    } else if (type == 'organization_phone1' && value.length != 10) {
      setFormOrganization_phone1Error(false);
      setOrganization_phone1Error(true);
      setDealInput(DealInput.error_list.organization_phone1 = 'Please enter valid phone number');
    } else {
      setFormOrganization_phone1Error(false);
      if (type == 'organization_phone1' && value != '') {
        setOrganization_phone1Error(false);
        setDealInput(DealInput.error_list.organization_phone1 = '');
      }
    }

    var phon2Pattern = new RegExp(/^[0-9\b]+$/);
    if (type == 'organization_phone2' && !phon2Pattern.test(value) && value != '') {
      setFormOrganization_phone2Error(false);
      setOrganization_phone2Error(true);
      setDealInput(DealInput.error_list.organization_phone2 = 'Please enter only number');
    } else if (type == 'organization_phone2' && value.length != 10 && value != '') {
      setFormOrganization_phone2Error(false);
      setOrganization_phone2Error(true);
      setDealInput(DealInput.error_list.organization_phone2 = 'Please enter 10 digit phone number');
    } else {
      setFormOrganization_phone2Error(false);
      if (type == 'organization_phone2' && value != '') {
        setOrganization_phone2Error(false);
        setDealInput(DealInput.error_list.organization_phone2 = '');
      }
    }

    // if (type=='organization_email' && !validator.isEmail(value) && value!='') {
    //   setFormOrganization_emailError(false)
    //   setOrganization_emailError(false);
    //   setDealInput(DealInput.error_list.organization_email =  'Enter a valid e-mail address');
    // }else if(type=='organization_email'){
    //   setFormOrganization_emailError(false)
    //   setOrganization_emailError(true);
    //   setDealInput(DealInput.error_list.organization_email = '');
    // }

    if (type == 'organization_email' && validator.isEmail(value)) {
      setFormOrganization_emailError(false);
      setOrganization_emailError(false);
      setDealInput(DealInput.error_list.organization_email = '');
    } else {
      setFormOrganization_emailError(false);
      if (type == 'organization_email' && value == '') {
        setOrganization_emailError(true);
        setDealInput(DealInput.error_list.organization_email = 'This field is required');
      } else if (type == 'organization_email') {
        setOrganization_emailError(true);
        setDealInput(DealInput.error_list.organization_email = 'Enter a valid e-mail address');
      }
    }

  }

  const dealSubmit = (e) => {
    e.preventDefault();

    if (DealInput.deal_description == '') {
      setDeal_descriptionError(true);
      setFormdeal_descriptionError(true);
      deal_descriptionRef.current.focus();
    }

    if (DealInput.deal_amount == '') {
      setDeal_amountError(true);
      setFormdeal_amountError(true);
      deal_amountRef.current.focus();
    }

    if (DealInput.deal_probability == '') {
      setDeal_probabilityError(true);
      setFormdeal_descriptionError(true);
      deal_probabilityRef.current.focus();
    }

    if (DealInput.salutation == '') {
      setSalutationError(true);
      setFormSalutationError(true);
      salutationRef.current.focus();
    }

    if (DealInput.first_name == '') {
      setFirst_nameError(true);
      setFormFirst_nameError(true);
      first_nameRef.current.focus();
    }

    if (DealInput.last_name == '') {
      setLast_nameError(true);
      setFormLast_nameError(true);
      last_nameRef.current.focus();
    }

    if (DealInput.primary_email == '') {
      setPrimary_emailError(true);
      setFormPrimary_emailError(true);
      primary_emailRef.current.focus();
    }

    if (DealInput.department == '') {
      setDepartmentError(true);
      setFormDepartmentError(true);
      departmentRef.current.focus();
    }

    if (DealInput.address1 == '') {
      setAddress1Error(true);
      setFormAddress1Error(true);
      address1Ref.current.focus();
    }

    if (DealInput.phone1 == '') {
      setPhone1Error(true);
      setFormPhone1Error(true);
      phone1Ref.current.focus();
    }

    if (DealInput.state == '') {
      setStateError(true);
      setFormStateError(true);
      stateRef.current.focus();
    }

    if (DealInput.city == '') {
      setCityError(true);
      setFormCityError(true);
      cityRef.current.focus();
    }

    if (DealInput.country_id == '') {
      setCountry_idError(true);
      setFormCountry_idError(true);
      country_idRef.current.focus();
    }

    if (DealInput.zipcode == '') {
      setzipcodeError(true);
      setFormzipcodeError(true);
      zipcodeRef.current.focus();
    }
    
    if (DealInput.stage_id == '') {
      setStage_idError(true);
      setFormstage_idError(true);
      stage_idRef.current.focus();
    }

    // if (DealInput.lead_source == '') {
    //   setLead_sourceError(true);
    //   setFormlead_sourceError(true);
    //   lead_sourceRef.current.focus();
    // }

    if (DealInput.organization_name == '') {
      setOrganization_nameError(true);
      setFormOrganization_nameError(true);
      organization_nameRef.current.focus();
    }

    if (DealInput.organization_email == '') {
      setOrganization_emailError(true);
      setFormOrganization_emailError(true);
      organization_emailRef.current.focus();
    }

    if (DealInput.organization_phone1 == '') {
      setOrganization_phone1Error(true);
      setFormOrganization_phone1Error(true);
      organization_phone1Ref.current.focus();
    }

    const formData = new FormData();
    formData.append('deal_description', DealInput.deal_description)
    formData.append('deal_amount', DealInput.deal_amount)
    formData.append('deal_probability', DealInput.deal_probability)
    formData.append('salutation', DealInput.salutation)
    formData.append('first_name', DealInput.first_name)
    formData.append('last_name', DealInput.last_name)
    formData.append('title', DealInput.title)
    formData.append('department', DealInput.department)
    formData.append('description', DealInput.description)
    formData.append('primary_email', DealInput.primary_email)
    formData.append('secondry_email', DealInput.secondry_email)
    formData.append('profile_pic', profileImageArray)
    formData.append('address1', DealInput.address1)
    formData.append('address2', DealInput.address2)
    formData.append('city', DealInput.city)
    formData.append('state', DealInput.state)
    formData.append('country_id', DealInput.country_id)
    formData.append('zipcode', DealInput.zipcode)
    formData.append('phone1', DealInput.phone1)
    formData.append('phone2', DealInput.phone2)
    formData.append('facebook_url', DealInput.facebook_url)
    formData.append('twitter_url', DealInput.twitter_url)
    formData.append('linkedin_url', DealInput.linkedin_url)
    formData.append('instagram_url', DealInput.instagram_url)
    formData.append('organization_name', DealInput.organization_name)
    formData.append('organization_email', DealInput.organization_email)
    formData.append('organization_phone1', DealInput.organization_phone1)
    formData.append('organization_phone2', DealInput.organization_phone2)
    formData.append('organization_website', DealInput.organization_website)
    formData.append('lead_source', DealInput.lead_source)
    formData.append('stage_id', DealInput.stage_id)
    formData.append('closing_date', moment(closingDate).format("YYYY-MM-DD"))
    formData.append('latitude', getIpResponse.latitude ? getIpResponse.latitude : latitude)
    formData.append('longitude', getIpResponse.longitude ? getIpResponse.longitude : longitude)

    for (var i = 0; i < selectProductArray.length; i++) {
      formData.append('product_id[]', selectProductArray[i])
    }
    
    setTimeout(() => {}, 100);

    if (!deal_descriptionError && !deal_amountError && !deal_probabilityError && !salutationError && !first_nameError && !last_nameError && !primary_emailError && !address1Error
      && !address2Error && !cityError && !stateError && !country_idError && !zipcodeError && !phone1Error && !organization_nameError
      && !organization_phone1Error && !organization_phone2Error && !departmentError && !stage_idError) {
      setSubmitEvent(true);
      axios.post("/api/deal/store", formData).then(response => {
        if (response.status === 200) {
          //swal("success", 'Deal has been added successfully', "success");
          toast.success('Deal has been created successfully');
          history.push('/deals');
        }
      }).then(json => {

      }).catch(error => {
        if (error.response) {
          setSubmitEvent(false);
          if (error.response.status === 400) {
            setDealInput({ ...DealInput, error_list: error.response.data.validation_errors });
          }else if (error.response.status === 403) {
            //swal("error", error.response.data.message, "error");
            toast.warning('Forbidden '+ error.response.data.message);
          } else {
            //swal("warning", error.response.data.message, "warning");
            toast.warning(error.response.data.message);
          }
        }

      });
    }

  }

  if (loading) {
    return <Preloader show={loading ? false : true} />;
  } else {

    // let salutationList = Object.keys(salutationData).map(function (key) {
    //   return (
    //     <option value={key} key={key} >{salutationData[key]}</option>
    //   )
    // }, this);
    let defaultSalutation = [{ label: 'Please select', value: '' }];

    let salutationList = [];
    salutationList.push({ label: 'Please select', value: '' });
    let salutationVarList = Object.keys(salutationData).map(function (key) {
      salutationList.push({label:salutationData[key],value:key});
    }, this);

    // let leadSourceList = Object.keys(leadSourceData).map(function (key) {
    //   return (
    //     <option value={key} key={key}>{leadSourceData[key]}</option>
    //   )
    // }, this);
    
    let defaultSource = [{ label: 'Please select', value: '' }];
    
    let leadSourceList = [];
    leadSourceList.push({ label: 'Please select', value: '' });
    leadSourceData.map((item) => {
      leadSourceList.push({ label: item.title, value: item.title });
    }, this);
    // let leadSourceVarList = Object.keys(leadSourceData).map(function (key) {
    //   leadSourceList.push({label:leadSourceData[key],value:key});
    // }, this);

    // let stageList = leadStageData.length > 0 && leadStageData.map((item, i) => {
    //   return (
    //     <option key={i} value={item.id} style={{textTransform:'capitalize'}}>{item.name}</option>
    //   )
    // }, this);

    let defaultStage = [{ label: 'Please select', value: '' }];
    
    let stageList = [];
    stageList.push({ label: 'Please select', value: '' });
    for (var i = 0, l = leadStageData.length; i < l; i++) {
      stageList.push({ label: leadStageData[i].name, value: leadStageData[i].id });
    }

    let productList = productData.length > 0 && productData.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name}</option>
      )
    }, this);

    // let countryList = countryData.length > 0 && countryData.map((item, i) => {
    //   return (
    //     <option key={i} value={item.id}>{item.country_name}</option>
    //   )
    // }, this);

    let selectedCountry = [];
    for (var i = 0, l = countryData.length; i < l; i++) {
         if (getIpResCountryCode == countryData[i].country_code || getIpResCountryName == countryData[i].country_name) {
              selectedCountry.push({ label: countryData[i].country_name, value: countryData[i].id });
         }
    }

    let countryList = [];
    for (var i = 0, l = countryData.length; i < l; i++) {
      countryList.push({ label: countryData[i].country_name, value: countryData[i].id });
    }

    let productLists = [];
    for (var i = 0, l = productData.length; i < l; i++) {
      productLists.push({ label: productData[i].name, value: productData[i].id });
    }

    return (
      <>
        <Row>
          <Col xs={12} xl={12}>
            <Card border="light" className="bg-white shadow-sm">
            <Row className="mb-3">
                  <Col md={6}>
                  </Col>
                  <Col md={6} className="text-right">
                    <button className="btn1 me-1" onClick={() => { history.goBack(); }}><span className="backarrow-icon"></span>Back</button>
                  </Col>
                </Row>

                <Card.Body className="borderline mb-4">
                <Row>
                <h5 className="mb-4">General Information</h5>
                </Row>
                <Form onSubmit={dealSubmit} encrypted="multipart/form-data">
                  <Row>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3">
                            Salutation<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                  <Form.Group id="salutation">
                  <Select
                          defaultValue=""
                          placeholder="Please select"
                          required
                          name="salutation"
                          options={salutationList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleSalutationInput}
                          ref={salutationRef}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#0074E1',
                              primary: '#0074E1',
                            },
                          })}
                        />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.salutation}</span>
                      {formsalutationError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                         Last Name<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                  <Form.Group id="last_name">
                  <Form.Control required type="text" name="last_name" onChange={handleInput} ref={last_nameRef} onBlur={handleInput} value={DealInput.last_name} placeholder="Enter your last name" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.last_name}</span>
                      {formlast_nameError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                        Phone 1<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="phone1">
                        <Form.Control required type="text" name="phone1" value={DealInput.phone1} ref={phone1Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter your phone 1" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.phone1}</span>
                      {formphone1Error ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Department<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="department">
                        <Form.Control required type="text" name="department" value={DealInput.department} ref={departmentRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter department" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.department}</span>
                      {formdepartmentError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Product
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="product_id">
                        <Select
                          defaultValue=""
                          isMulti
                          name="product_id"
                          options={productLists}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleChange}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#0074E1',
                              primary: '#0074E1',
                            },
                          })}
                        />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.product_id}</span>
                      {formproduct_idError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                        Description
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="description">
                        <Form.Control type="text" name="description" value={DealInput.description} ref={descriptionRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter description" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.description}</span>
                      {formdescriptionError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  <div className="col-md-6 col-sm-6 right-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                         First Name<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                  <Form.Group id="first_name">
                  <Form.Control required type="text" name="first_name" onChange={handleInput} ref={first_nameRef} onBlur={handleInput} value={DealInput.first_name} placeholder="Enter your first name" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.first_name}</span>
                      {formfirst_nameError ? <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback> : <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                         Primary Email<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="primary_email">
                        <Form.Control required type="email" name="primary_email" value={DealInput.primary_email} ref={primary_emailRef} onBlur={handleInput} onChange={handleInput} placeholder="Enter primary email" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.primary_email}</span>
                      {formprimary_emailError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Title<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="title">
                        <Form.Control type="text" required name="title" value={DealInput.title} ref={titleRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter title" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.title}</span>
                      {formtitleError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Image
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="profile-image">
                        <Form.Control type="file" name="profile_pic" ref={profile_picRef} onChange={handleImageInput} />
                        <small>Allow file type are : jpg, jpeg, png, gif</small>
                  </Form.Group>
                  <span className="error">{DealInput.error_list.profile_pic}</span>
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                       Stage<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="stage_id">
                        <Select
                            defaultValue=""
                            placeholder="Please select"
                            required
                            name="stage_id"
                            options={stageList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleStageInput}
                            ref={stage_idRef}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#0074E1',
                                primary: '#0074E1',
                              },
                            })}
                        />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.stage_id}</span>
                      {formstage_idError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  </Row>
                  <Row>
                  </Row>
                </Form>
                </Card.Body>

                <Card.Body className="borderline mb-5">
                <Form onSubmit={dealSubmit} encrypted="multipart/form-data">
                  <h5 className="mb-4">Deal Information</h5>
                  <Row>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3">
                       Amount - {preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode+' ' : ' '} 
                          ({preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol:''})
                          <span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                  <Form.Group id="deal_amount">
                  <CurrencyInput
                            id="deal_amount"
                            name="deal_amount"
                            intlConfig={{
                              locale: preferredCurrencyData.currencyStringValue ?  preferredCurrencyData.currencyStringValue:'en-IN',
                              currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode:'INR',
                            }}
                            placeholder="Enter deal amount"
                            className={`form-control`}
                            onValueChange={handleOnValueChange}
                            decimalsLimit={6}
                            value={DealInput.deal_amount}
                            step={1}
                          />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.deal_amount}</span>
                      {formdeal_amountError ? <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback> : <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Deal Source
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="lead_source">
                  <Select
                            defaultValue=""
                            placeholder="Please select"
                            name="lead_source"
                            options={leadSourceList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleLeadSourceInput}
                            ref={lead_sourceRef}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#0074E1',
                                primary: '#0074E1',
                              },
                            })}
                        />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.lead_source}</span>
                      {formlead_sourceError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                       Description<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                  <Form.Group id="deal_description">
                  <Form.Control required type="text" name="deal_description" onChange={handleInput} ref={deal_descriptionRef} onBlur={handleInput} value={DealInput.deal_description} placeholder="Enter deal description" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.deal_description}</span>
                      {formdeal_descriptionError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  <div className="col-md-6 col-sm-6 right-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Probability<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                  <Form.Group id="deal_amount">
                  <Form.Control required type="text" name="deal_probability" onChange={handleInput} ref={deal_probabilityRef} onBlur={handleInput} value={DealInput.deal_probability} placeholder="Enter deal probability. ex.(50%)" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.deal_probability}</span>
                      {formdeal_probabilityError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                       Expected close date
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="lead_source">
                  <DatePicker
                            autocomplete="off"
                            minDate={moment().toDate()}
                            selected={closingDate}
                            onChange={(date) => setClosingDate(date)}
                            dateFormat={calendorDateFormate}
                            className="timedate tp-2  form-control"
                        />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.closing_date}</span>
                      {formclosingDateError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  </Row>
                  </Form>
                  </Card.Body>
                  
                  <Card.Body className="borderline mb-5">
                  <Form onSubmit={dealSubmit} encrypted="multipart/form-data">
                  <h5 className="mb-4">Address Information</h5>
                  <Row>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Address 1<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="address1">
                  <Form.Control required type="text" name="address1" value={DealInput.address1} ref={address1Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter your address 1" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.address1}</span>
                      {formaddress1Error ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Secondry Email
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="secondry_email">
                        <Form.Control type="email" name="secondry_email" value={DealInput.secondry_email} ref={secondry_emaildRef} onBlur={handleInput} onChange={handleInput} placeholder="Enter secondry email" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.secondry_email}</span>
                      {formsecondry_emailError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       City<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="city">
                        <Form.Control required type="text" name="city" value={DealInput.city} ref={cityRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your city" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.city}</span>
                      {formcityError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                       ZIP Code<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="zip">
                        <Form.Control required type="text" name="zipcode" value={DealInput.zipcode} ref={zipcodeRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your zipcode" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.zipcode}</span>
                      {formzipcodeError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  <div className="col-md-6 col-sm-6 right-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Address 2
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="address2">
                        <Form.Control type="text" name="address2" value={DealInput.address2} ref={address2Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter your address 2" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.address2}</span>
                      {formaddress2Error ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Phone 2
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="phone2">
                        <Form.Control type="text" name="phone2" value={DealInput.phone2} ref={phone2Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter your phone 2" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.phone2}</span>
                      {formphone2Error ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       State<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="state">
                        <Form.Control required type="text" name="state" value={DealInput.state} ref={stateRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your state" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.state}</span>
                      {formstateError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group> 
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                       Select Country<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="country">
                        <Select
                            defaultValue={selectedCountry}
                            name="country_id"
                            options={countryList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleCountryInput}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#0074E1',
                                primary: '#0074E1',
                              },
                            })}
                        />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.country_id}</span>
                      {formcountry_idError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  </Row>
                  </Form>
                  </Card.Body>

                  <Card.Body className="borderline mb-5">
                  <Form onSubmit={dealSubmit} encrypted="multipart/form-data">
                  <h5 className="mb-4">Organization Information</h5>
                  <Row>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Organization Name<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="organization_name">
                        <Form.Control type="text" required name="organization_name" value={DealInput.organization_name} ref={organization_nameRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter organization name" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.organization_name}</span>
                      {formorganization_nameError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group> 
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Organization Phone1<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="organization_phone1">
                        <Form.Control type="text" required name="organization_phone1" value={DealInput.organization_phone1} ref={organization_phone1Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter organization phone1" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.organization_phone1}</span>
                      {formorganization_phone1Error ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                       Organization Website
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="organization_website">
                        <Form.Control type="url" name="organization_website" value={DealInput.organization_website} ref={organization_websiteRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter organization website" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.organization_website}</span>
                      {formorganization_websiteError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group> 
                  </Row>
                  </div>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Organization Email<span className="text-danger"> * </span>
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="organization_email">
                        <Form.Control type="email" required name="organization_email" value={DealInput.organization_email} ref={organization_emailRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter organization email" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.organization_email}</span>
                      {formorganization_emailError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group> 
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Organization Phone2
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="organization_phone2">
                        <Form.Control type="text" name="organization_phone2" value={DealInput.organization_phone2} ref={organization_phone2Ref} onChange={handleInput} onBlur={handleInput} placeholder="Enter organization phone2" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.organization_phone2}</span>
                      {formorganization_phone2Error ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group> 
                  </Row>
                  </div>  
                  </Row>
                 
                    </Form>
                    </Card.Body>
                  
                  <Card.Body className="borderline mb-5">
                  <Form onSubmit={dealSubmit} encrypted="multipart/form-data">
                  <h5 className="mb-4">Social Media Information</h5>
                  <Row>
                  <div className="col-md-6 col-sm-6 left-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Facebook URL
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="facebook_url">
                        <Form.Control type="text" name="facebook_url" value={DealInput.facebook_url} ref={facebook_urlRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your facebook url" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.facebook_url}</span>
                      {formfacebook_urlError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group> 
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                       Linkedin URL
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="linkedin_url">
                        <Form.Control type="text" name="linkedin_url" value={DealInput.linkedin_url} ref={linkedin_urlRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your linkedin url" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.linkedin_url}</span>
                      {formlinkedin_urlError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group> 
                  </Row>
                  </div>
                  <div className="col-md-6 col-sm-6 right-form">
                  <Row className="align-items-center">
                  <Form.Group className="d-flex profile1 mb-4">
                       <Form.Label column sm="3" >
                       Twitter URL
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="twitter_url">
                        <Form.Control type="text" name="twitter_url" value={DealInput.twitter_url} ref={twitter_urlRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your twitter url" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.twitter_url}</span>
                      {formtwitter_urlError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group> 
                  <Form.Group className="d-flex profile1">
                       <Form.Label column sm="3" >
                       Instagram URL
                        </Form.Label>
                        <Col sm="9">
                        <Form.Group id="instagram_url">
                        <Form.Control type="text" name="instagram_url" value={DealInput.instagram_url} ref={instagram_urlRef} onChange={handleInput} onBlur={handleInput} placeholder="Enter your instagram url" />
                  </Form.Group>
                  <span className="error">{DealInput.error_list.instagram_url}</span>
                      {forminstagram_urlError ? <span className="error">This field is required</span> : ''}
                       </Col>
                  </Form.Group>
                  </Row>
                  </div>
                  </Row>
                  </Form>
                  </Card.Body>
                  
                  <Card.Body className="listcard1">
                  <Form onSubmit={dealSubmit} encrypted="multipart/form-data">
                  <div>
                    {
                        submitEvent ? 

                        <Button variant="primary" className="d-flex align-items-center mb-1">
                              <Spinner animation="border" size="sm" />
                              <span className="ms-2">Please Wait...</span>
                        </Button>
                        :
                        <Button variant="primary" type="submit" className="d-flex">Submit <span class="rightarrow"></span></Button>
                    }

                    
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>


        </Row>
      </>
    );
  }
}

export default AddDeal;


