/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table, Button, Modal } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default (props) => {
  const history = useHistory();
  const [list, setList] = useState([]);

  const showAddDealStage = props.openDealStagePositionPopup;

  useEffect(() => {
    async function fetchData() {
      await getCreateData();
    }
    fetchData();
  }, [showAddDealStage]);

  async function getCreateData() {
    await axios
      .get("api/stage?list=popup&type=deal")
      .then((response) => {
        if (response.status === 200) {
          setList(response.data.data.records);
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/stages");
          } else {
            toast.error("Oops Something went wrong.");
          }
        }
      });
  }

  const handleDealStagePosition = () => {
    props.handleDealStagePosition();
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const finalList = [];

  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);
    copyListItems.map((item) => {
      finalList.push(item.name);
    });
    handleSubmit(finalList);
  };

  const handleSubmit = async (values) => {
    const data = {
      finalList: values,
      is_used: "deal",
      is_position_change: true,
    };

    await axios
      .post("/api/stage/update", data)
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .then((json) => {})
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.validation_errors);
          } else if (error.response.status === 403) {
            toast.warning("Forbidden " + error.response.data.message);
            history.push("/stages");
          } else {
            toast.warning(error.response.data.message);
          }
        }
      });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showAddDealStage}
      onHide={handleDealStagePosition}
    >
      <Modal.Header>
        <Modal.Title className="h2">Change Deal Stage Position</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={handleDealStagePosition}
        />
        <span className="modelclose"></span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} className="mb-4 leadsTable-cl">
            <Table
              responsive
              className="align-items-center table-flush rolewidth"
              style={{ marginBottom: "0px" }}
            >
              <thead>
                <tr key={"x"}>
                  <th> Name</th>
                  <th> Drag</th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((item, index) => (
                    <tr
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragEnd={drop}
                      key={index}
                      draggable
                    >
                      <th> {item.name}</th>
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faGripVertical} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
