import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';

import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from 'react-currency-input-field';
import { faCheck,  faTimes} from '@fortawesome/free-solid-svg-icons';
import { Col, Dropdown, Form } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import { useSelector } from "react-redux";
import { selectUser, selectDefaultData } from "../../features/userSlice";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import 'react-edit-text/dist/index.css';
import '../../assets/css/custom.css';

import { useHistory, } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import EasyEdit, { Types } from "react-easy-edit";

function EditProduct(props) {
     const history = useHistory();
     const loginUser = useSelector(selectUser);
     const [isEditable, setIsEditable] = useState(true)

     const user = loginUser?loginUser:[];

     const userPermissions = useSelector((state) => state.permission);

     const userPermission = userPermissions?userPermissions:[];

     var defaultData = useSelector(selectDefaultData);
     var defaultTimeZone = user?.preferred_timezone||defaultData.defaultTimeZone;
     
     const [productID, setProductID] = useState(props.match.params.id);
     const [updateDealData, setUpdateDealData] = useState(false);

     const [preferredCurrencyData, setPreferredCurrencyData] = useState([]);
     const [preferredDateFormatData, setPreferredDateFormatData] = useState('');
     const [calendorDateFormate, setCalendorDateFormate] = useState('yyyy/MM/dd');
     const [calendorFullDateFormate, setCalendorFullDateFormate] = useState();
     const [productPrice, setProductPrice] = useState();
     const [uploadFile, setUploadFile] = useState();


     const [nameErrorMsg, setNameErrorMsg] = useState();
     const [codeErrorMsg, setCodeErrorMsg] = useState();
     const [descriptionErrorMsg, setDescriptionErrorMsg] = useState();
     const [taxErrorMsg, setTaxErrorMsg] = useState();
     //=========START:CODE FOR EDIT LEAD DATA==========//

     const [loading, setLoading] = useState(true);
     const [recordData, setRecordData] = useState('');
     const [ErrorInput, setErrorInput] = useState({ error_list: [] });

     //========Const data for Activity (Lead and Deal)
     var calendorFormate = '';

     useEffect(() => {
          async function fetchData() {
               await getProduct();
          }
          fetchData();

     }, [updateDealData]);


     async function getProduct() {
          await axios.get('api/product/edit/' + productID).then(response => {
               if (response.data.code === 200) {
                    setRecordData(response.data.data.record);
                    setProductPrice(response.data.data.record.price);

                    setPreferredCurrencyData(response.data.data.preferredCurrency);
                    setPreferredDateFormatData(response.data.data.preferredDateFormat);

                    calendorFormate = response.data.data.record.preferredDateFormat.split(' ');
                    if (calendorFormate) {
                         if (calendorFormate[0] == 'YYYY-MMM-DD') {
                              setCalendorDateFormate('yyyy/MM/dd');
                              setCalendorFullDateFormate('yyyy/MM/DD');
                         } else if (calendorFormate[0] == 'DD-MMM-YYYY') {
                              setCalendorDateFormate('dd/MM/yyyy');
                              setCalendorFullDateFormate('DD/MM/yyyy');
                         } else if (calendorFormate[0] == 'YYYY-DD-MMM') {
                              setCalendorDateFormate('yyyy/dd/MM');
                              setCalendorFullDateFormate('yyyy/DD/MM');
                         } else if (calendorFormate[0] == 'MMM-DD-YYYY') {
                              setCalendorDateFormate('MM/dd/yyyy');
                              setCalendorFullDateFormate('MM/DD/yyyy');
                         }
                    }

                    setLoading(false);
               }

          }).then(json => {

          }).catch(error => {
               setLoading(false);
               if (error.response) {
                    if (error.response.status === 401 || error.response.status === 400) {
                         toast.warning(error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                         history.push('/products');
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }
          });
     }

     const addDefaultSrc = (e) => {
          e.target.src = `${process.env.REACT_APP_API_URL}assets/no-image.png`;
     }

     const handleSaveData = async (value, name) => {
          const data = {
               id: productID,
               name: recordData.name,
               [name]: value,
          }

          if(value){
               await axios.post("api/product/update", data).then(response => {
                    if (response.status === 200) {
                         updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                         setErrorInput({ ...ErrorInput, error_list: '' });
                         setIsEditable(true)
                         toast.success('Data updated successfully');
                    }
               }).then(json => {

               }).catch(error => {
                    if (error.response) {
                         setIsEditable(true)
                         if (error.response.status === 400) {
                              setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         } else if (error.response.status === 403) {
                              toast.warning('Forbidden ' + error.response.data.message);
                         } else if (error.response.status === 300) {
                              toast.warning(error.response.data.message);
                         } else {
                              toast.error('Oops Something went wrong.');
                         }
                    }

               });
          }else{
               updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
               setErrorInput({ ...ErrorInput, error_list: '' });
               setIsEditable(true)    
          }
     };



     const handleOnValueChange = (value) => {
          if (value && value != 'undefined') {
               setProductPrice(value);
               const data = {
                    id: productID,
                    price: value,
                    name: recordData.name,
               }
               setTimeout(() => {
                    axios.post("/api/product/update", data).then(response => {
                         if (response.status === 200) {
                              updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                              setErrorInput({ ...ErrorInput, error_list: '' });
                              //toast.success('Data Updated Successfully');
                         }
                    }).then(json => {

                    }).catch(error => {
                         if (error.response) {
                              setErrorInput({ ...ErrorInput, error_list: '' });
                              if (error.response.status === 400) {
                                   setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                              } else if (error.response.status === 403) {
                                   toast.warning('Forbidden ' + error.response.data.message);
                              } else {
                                   //toast.error(error.response.data.message);
                              }
                         }
                    });
               }, 100);


          }
     }

     const handleImageInput = (e) => {
          e.persist();

          const formData = new FormData();
          formData.append('id', productID)
          formData.append('name', recordData.name)
          formData.append('file_url', e.target.files[0])

          axios.post("/api/product/update", formData).then(response => {
               if (response.status === 200) {
                    updateDealData ? setUpdateDealData(false) : setUpdateDealData(true);
                    setErrorInput({ ...ErrorInput, error_list: '' });
                    setIsEditable(true)
                    toast.success('Data updated successfully');
               }
          }).then(json => {

          }).catch(error => {
               if (error.response) {
                    setIsEditable(true)
                    if (error.response.status === 400) {
                         setErrorInput({ ...ErrorInput, error_list: error.response.data.validation_errors });
                         if (error.response.data.message.file_url[0]) {
                              toast.warning(error.response.data.message.file_url[0]);
                         }
                    } else if (error.response.status === 403) {
                         toast.warning('Forbidden ' + error.response.data.message);
                    } else if (error.response.status === 300) {
                         toast.warning(error.response.data.message);
                    } else {
                         toast.error('Oops Something went wrong.');
                    }
               }

          });


     }

     const cancel = () => {
          setIsEditable(true)
          setDescriptionErrorMsg('')

          setNameErrorMsg('');
          setCodeErrorMsg('');
          setDescriptionErrorMsg('');
          setTaxErrorMsg('');
     };


     const handleClick = (e) => {
          setIsEditable(false)
     }


     function deleteRecord(id) {
          const data = {
               id: id,
          }

          swal({
               title: "Are you sure you want to delete?",
               text: "Once deleted, you will not be able to recover this data!",
               icon: "warning",
               buttons: true,
               dangerMode: true,
          })
          .then((willDelete) => {
               if (willDelete) {
                    axios.delete('api/product/destroy', { data }).then(response => {
                         if (response.status === 200) {
                              toast.success(response.data.message);
                              history.push('/products')
                         }
                    }).then(json => {

                    }).catch(error => {
                         if (error.response) {
                              if (error.response.status === 400) {
                                   toast.warning(error.response.data.message);
                              } else if (error.response.status === 403) {
                                   toast.warning('Forbidden ' + error.response.data.message);
                              } else {
                                   toast.error(error.response.data.message);
                              }
                         }
                    });
               }
          });
     }

     const CustomAmountDisplay = props => {

          const handleDateValueChange = (event) => {
               if (event && event != 'undefined') {
                    props.setParentValue(event);
               }
          };
          setIsEditable(false)
          const DateLocale = props.data.locale;
          const selectedCurrency = props.data.currency;

          return (
               <CurrencyInput
                    id="price"
                    name="price"
                    intlConfig={{
                         locale: DateLocale ? DateLocale : 'en-IN',
                         currency: selectedCurrency ? selectedCurrency : 'INR',
                    }}
                    defaultValue={productPrice}
                    placeholder="Enter Price"
                    className="amount_txt"
                    onValueChange={handleDateValueChange}
                    decimalsLimit={6}
                    step={1}
               />
          );
     };

     const CustomDisplayDate = props => {
          const val = props;
          const DateLocale = props.data.locale;
          const selectedCurrency = props.data.currency;
          return (
               <div>
                    <CurrencyInput
                         intlConfig={{
                              locale: DateLocale ? DateLocale : 'en-IN',
                              currency: selectedCurrency ? selectedCurrency : 'INR',
                         }}
                         defaultValue={val.value ? val.value : productPrice}
                         placeholder="Enter Price"
                         className="amount_txt"
                         decimalsLimit={6}
                         step={1}
                    />
               </div>
          );
     }



     if (loading) {
          return <Preloader show={loading ? false : true} />;
     } else {
          let FFName = recordData.name ? recordData.name : '';
          let fullName = ''

          if (FFName) {
               fullName = FFName
               let expName = FFName.split(' ');
               if (expName.length == 2) {
                    FFName = expName[0].charAt(0) + expName[1].charAt(0);
               } else {
                    FFName = expName[0].charAt(0);
               }
          }

          let imageUrl = '';
          if (recordData.images && recordData.images[0] && recordData.images[0].file_url) {
               imageUrl = process.env.REACT_APP_API_URL + 'storage/' + recordData.images[0].file_url;
          }

          return (
               <>
                    {/* <Row> */}
                    <div className="display flex-wrap">
                         {/*--- client_information_section ---*/}
                         <Col md={12} className="client activity mt30">
                              {/*--- client_name_and_convert_lead_section ---*/}
                              <div className="client_details display justify-content-between">
                                   {/*--- client_image_and_deatils_section ---*/}
                                   <div className="client_image1 display">
                                        <label className="custom-file-upload editprofile">
                                             <input type="file" id="file_url" name="file_url" accept="image/jpeg, image/png, image/jpg" onChange={handleImageInput} />
                                             <Stack direction="row" spacing={3}>
                                                  <Avatar
                                                       alt={fullName}
                                                       src={imageUrl}
                                                       sx={{ width: 50, height: 50 }}
                                                       onError={addDefaultSrc}
                                                       className="clientimg1"
                                                  >
                                                       {FFName}
                                                  </Avatar>
                                             </Stack>
                                        </label>
                                        <div className="client_name ml20">
                                             <div className="display">
                                                  <h5>{fullName}</h5>
                                             </div>
                                        </div>
                                   </div>
                                   {/*--- client_update_time_and_date_section ---*/}
                                   <div className="joint display">
                                        <div className="client_update1">
                                             <div className="client_txt display justify-content-end">
                                                  <span>Last update</span>
                                             </div>
                                             <div className="client_date_and_time display justify-content-end">
                                                  {/* <span>July, 03 2022 | 10:13am</span> */}
                                                  <span>{moment.tz(recordData.updated_at, defaultTimeZone).format(preferredDateFormatData)}</span>
                                             </div>
                                        </div>
                                        <div className="client_action1 text-right ps-3">
                                        {
                                             userPermission ?
                                             (userPermission.includes('product_delete')) ?
                                                  <Dropdown>
                                                       <Dropdown.Toggle id="dropdown-basic" className=" dropdownborder1 dothover">
                                                            <span className="dot"></span>
                                                       </Dropdown.Toggle>
                                                       
                                                       <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => { deleteRecord(productID) }}>Delete</Dropdown.Item>
                                                       </Dropdown.Menu>
                                                  </Dropdown>
                                                  :
                                                  ''
                                             :
                                             ''
                                        }
                                             
                                        </div>
                                   </div>
                                   {/*--- client_action_section ---*/}

                              </div>
                         </Col>



                         <Col md={12} className="mt30">
                              <div className="display w-100">
                                   <Accordion defaultActiveKey="0" className="w-100">
                                        <Accordion.Item eventKey="0">
                                             <Accordion.Header><h4>Product Information</h4></Accordion.Header>
                                             <Accordion.Body>
                                                  <div className="display align-items-start flex-wrap">
                                                       <div className="col-md-6 circletrick">
                                                            <div className="display align-items-start flex-wrap">
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Product name</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                value={recordData.name}
                                                                                onSave={(value) => { handleSaveData(value, 'name') }}
                                                                                onValidate={value => {
                                                                                     if (value) {
                                                                                          var splString = value.split(' ');
                                                                                          if (value && value.length>50) {
                                                                                               setNameErrorMsg('Only 50 characters are allowed')
                                                                                               return false    
                                                                                          }else if(value && value.length<2){
                                                                                               setNameErrorMsg('Enter at least 2 characters.');
                                                                                               return false
                                                                                          }else if(((value && value.length==2) && (value && splString.length>=2))){
                                                                                               setNameErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                               return false
                                                                                          } 
                                                                                          return true
                                                                                     }else{
                                                                                          setNameErrorMsg('Enter product name');
                                                                                          return false
                                                                                     }

                                                                                }}
                                                                                validationMessage={nameErrorMsg}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                instructions=""
                                                                                attributes={{ maxLength: 50 }}
                                                                                placeholder='----'
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick('')}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.name}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Product code</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                value={recordData.product_code}
                                                                                onSave={(value) => { handleSaveData(value, 'product_code') }}
                                                                                onValidate={value => {
                                                                                     if (value) {
                                                                                          var splString = value.split(' ');
                                                                                          if (value && value.length>15) {
                                                                                               setCodeErrorMsg('Allow 15 characters only.')
                                                                                               return false    
                                                                                          }else if(value && value.length<2){
                                                                                               setCodeErrorMsg('Enter at least 2 characters.');
                                                                                               return false
                                                                                          }else if(((value && value.length==2) && (value && splString.length>=2))){
                                                                                               setCodeErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                               return false
                                                                                          } 
                                                                                          return true
                                                                                     }else{
                                                                                          return true
                                                                                     }

                                                                                }}
                                                                                validationMessage={codeErrorMsg}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                attributes={{ maxLength: 15 }}
                                                                                placeholder='----'
                                                                                instructions=""
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick('')}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.product_code}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick ">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Tax</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                value={recordData.tax}
                                                                                onSave={(value) => { handleSaveData(value, 'tax') }}
                                                                                onValidate={value => {
                                                                                     // if (value) {
                                                                                     //      if (value.length >= 11) {
                                                                                     //           setTaxErrorMsg('Must be at most 10 characters.');
                                                                                     //           return false
                                                                                     //      }
                                                                                     //      return true
                                                                                     // }
                                                                                     // return true
                                                                                     if (value) {
                                                                                          console.log(value,Math.sign(value))
                                                                                          if (isNaN(value)) {
                                                                                               setTaxErrorMsg('Please enter numeric value only.');
                                                                                               return false
                                                                                          } else if (value.length > 10) {
                                                                                               setTaxErrorMsg('Allow 10 characters only.');
                                                                                               return false
                                                                                          } else if(value.length<1){
                                                                                               setTaxErrorMsg('Enter at least 1 characters.');
                                                                                               return false
                                                                                          } else if (value != 0  && (Math.sign(value) === 0 || Math.sign(value) === -1)){
                                                                                               setTaxErrorMsg('Entered value must be positive');
                                                                                               return false
                                                                                          }
                                                                                          return true
                                                                                     }else{
                                                                                          setTaxErrorMsg('Enter product tax');
                                                                                          return false
                                                                                     }
                                                                                }}
                                                                                validationMessage={taxErrorMsg}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                attributes={{ maxLength: 10 }}
                                                                                placeholder='----'
                                                                                instructions=""
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick('')}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.tax}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="display align-items-start">
                                                                           <Form.Label className="text-right w-17">Modified by</Form.Label>
                                                                           <div className="fontdisabled display flex-wrap">
                                                                                <p className="disabledrecord">{recordData.Username ? recordData.Username.name : 'N/A'}</p>
                                                                                <p className="fontsbreak">{moment.tz(recordData.updated_at, defaultTimeZone).format(preferredDateFormatData)}</p>
                                                                           </div>
                                                                      </Form.Group>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="col-md-6 circletrick">
                                                            <div className="display align-items-start flex-wrap">
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display">
                                                                           <Form.Label className="text-right w-17">Unit price &nbsp;
                                                                                {preferredCurrencyData.currencyCode != '' ? preferredCurrencyData.currencyCode + ' ' : '' + ' '}
                                                                                ({preferredCurrencyData.currencySymbol != '' ? preferredCurrencyData.currencySymbol : ''})
                                                                           </Form.Label>



                                                                           <EasyEdit
                                                                                type={Types.TEXT}
                                                                                onSave={(value) => { handleSaveData(value, 'price') }}
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                editComponent={
                                                                                     <CustomAmountDisplay data={{ 'locale': preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN', 'currency': preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR' }} />
                                                                                }
                                                                                value={productPrice}
                                                                                displayComponent={<CustomDisplayDate data={{ 'locale': preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN', 'currency': preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR' }} />}
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick('')}
                                                                                placeholder='----'
                                                                           />
                                                                           {/* <CurrencyInput
                                                                           id="price"
                                                                           name="price"
                                                                           intlConfig={{
                                                                                locale: preferredCurrencyData.currencyStringValue ? preferredCurrencyData.currencyStringValue : 'en-IN',
                                                                                currency: preferredCurrencyData.currencyCode ? preferredCurrencyData.currencyCode : 'INR',
                                                                           }}
                                                                           onValidate={value => {
                                                                                if (value) {
                                                                                     return true
                                                                                } else {
                                                                                     return false
                                                                                }
                                                                           }}
                                                                           validationMessage={'This field is required'}
                                                                           placeholder="Enter price"
                                                                           className="amount_txt"
                                                                           onValueChange={handleOnValueChange}
                                                                           decimalsLimit={6}
                                                                           value={productPrice}
                                                                           step={1}
                                                                           disabled={!isEditable}
                                                                      /> */}
                                                                           <div className="input-group-append amount-after">
                                                                                <span className="pencil_icon_amount"></span>
                                                                           </div>

                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.price}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="mb-2 display align-items-start">
                                                                           <Form.Label className="text-right w-17 mt-1">Description</Form.Label>
                                                                           <EasyEdit
                                                                                type={Types.TEXTAREA}
                                                                                value={recordData.description}
                                                                                onSave={(value) => { handleSaveData(value, 'description') }}
                                                                                onValidate={value => {
                                                                                     if (value) {
                                                                                          var splString = value.split(' ');
                                                                                          if (value && value.length>150) {
                                                                                               setDescriptionErrorMsg('Allow 150 characters only.')
                                                                                               return false    
                                                                                          }else if(value && value.length<2){
                                                                                               setDescriptionErrorMsg('Enter at least 2 characters.');
                                                                                               return false
                                                                                          }else if(((value && value.length==2) && (value && splString.length>=2))){
                                                                                               setDescriptionErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                               return false
                                                                                          } else if(value.replace(/\s+/g, '').length<2) {
                                                                                               setDescriptionErrorMsg('Enter at least 2 characters, space not allow.');
                                                                                               return false
                                                                                          }
                                                                                          return true
                                                                                     }else{
                                                                                          return true
                                                                                     }

                                                                                }}
                                                                                validationMessage={descriptionErrorMsg}
                                                                                cssClassPrefix="custom-textarea-"
                                                                                onCancel={cancel}
                                                                                saveButtonLabel={<FontAwesomeIcon icon={faCheck} />}
                                                                                cancelButtonLabel={<FontAwesomeIcon icon={faTimes} />}
                                                                                instructions=""
                                                                                attributes={{ maxLength: 150 }}
                                                                                placeholder='----'
                                                                                allowEdit={isEditable}
                                                                                onFocus={() => handleClick('')}
                                                                           />
                                                                      </Form.Group>
                                                                      <span className="error">{ErrorInput.error_list.description}</span>
                                                                 </div>
                                                                 <div className="col-md-12 circletrick">
                                                                      <Form.Group className="display align-items-start">
                                                                           <Form.Label className="text-right w-17">Created by</Form.Label>
                                                                           <div className="fontdisabled display flex-wrap">
                                                                                <p className="disabledrecord">{recordData.Username ? recordData.Username.name : 'N/A'}</p>
                                                                                <p className="fontsbreak">{moment.tz(recordData.created_at, defaultTimeZone).format(preferredDateFormatData)}</p>
                                                                           </div>
                                                                      </Form.Group>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </Accordion.Body>
                                        </Accordion.Item>
                                   </Accordion>
                              </div>
                         </Col>
                         {/* </Row> */}
                    </div>
               </>
          );
     }
};

export default EditProduct;
